import React from 'react';
import MuiButton from '@material-ui/core/Button';
import MuiEditIcon from '@material-ui/icons/Edit';
import MuiIconButton from '@material-ui/core/IconButton';
import MuiEditIconMobile from '@material-ui/icons/EditRounded';

export interface EditActionButtonProps {
  disabled?: boolean;
  isMobile: boolean;
  onClick: () => void;
  label?: string;
  className?: string;
}

const EditActionButton = (props: EditActionButtonProps) => {
  const {
    onClick,
    isMobile,
    className = '',
    disabled = false,
    label = 'Dashboard aanpassen'
  } = props;

  return (
    <>
      {!isMobile &&
        <MuiButton
          color={'primary'}
          onClick={onClick}
          aria-label={label}
          disabled={disabled}
          className={className}
          startIcon={<MuiEditIcon />}
        >
          {!isMobile && label}
        </MuiButton>
      }
      {!!isMobile &&
        <MuiIconButton
          color={'primary'}
          onClick={onClick}
          aria-label={label}
          disabled={disabled}
          className={className}
        >
          <MuiEditIconMobile />
        </MuiIconButton>
      }
    </>
  );
};

export default EditActionButton;