import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ComponentBaseProps } from '../ComponentBaseProps';

export interface SimpleTextBlockThemeProps { }

export interface SimpleTextContentItem {
  title?: string;
  paragraphs: string | string[];
}
export interface SimpleTextBlockProps extends ComponentBaseProps<SimpleTextBlockThemeProps, {}> {
  content: SimpleTextContentItem | SimpleTextContentItem[];
}

const StyledWrapper = styled.div`
  font-size: 0.8rem;
  position: relative;
  flex: 1;

  a {
    /* TODO: palette */
    color:#2b4899;  
  }
` as React.ComponentType<React.HTMLProps<HTMLDivElement>>;

const processContent = async (content: SimpleTextContentItem[]) => {
  const result = [] as { title?: string; paragraphs: string[]; }[];

  for (let i = 0; i < content.length; i++) {
    const { paragraphs: originalParagraphs, ...rest } = content[i];

    const paragraphs = !!originalParagraphs ?
      Array.isArray(originalParagraphs) ? originalParagraphs : [originalParagraphs] :
      [];

    const processedParagraphs = await Promise.all(paragraphs.map((p) => p));
    result.push({ paragraphs: processedParagraphs, ...rest });
  }

  return result;
};

const SimpleTextBlock = (props: SimpleTextBlockProps) => {
  const { content: originalContent, oeCode } = props;
  const [processedContent, setProcessedContent] = useState<{ title?: string; paragraphs: string[]; }[]>([]);

   useEffect(() => {
     const content = !!originalContent ?
       Array.isArray(originalContent) ? originalContent : [originalContent] :
       [];

    if (!!content) {
      // NOTE!: if content changes quickly (which it won't in a simpletextblock?) this could cause timing issues.
      // need to clear previous 'processContent' before starting a new one.
      processContent(content).then((result) => setProcessedContent(result));
    }
  }, [originalContent, oeCode]);

  return (
    <StyledWrapper>
      {
        processedContent.map(({ title, paragraphs }, index: number) => {
          return (
            <div key={index}>
              {!!title && <h3> {title} </h3>}
              {
                paragraphs.map((paragraph, i) => {

                  return <p key={`paragraph-${i}`} dangerouslySetInnerHTML={{ __html: paragraph }} />
                })
              }
            </div>
          )
        })
      }

    </StyledWrapper>
  )
}

export default SimpleTextBlock;