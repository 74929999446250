export const unitcomparePath = '/exporteren';
export const dashboardPagePath = '/kpis/:templatePath';
export const customDashboardPagePath = '/dashboards/:templatePath';
export const customDashboardOverviewPagePath = '/dashboards/overzicht';

export const eigenOverzichtTemplate: API_GET.Template = {
  id: -1,
  helpId: -1,
  shortName: '',
  enabled: true,
  isCustom: true,
  createdById: -1,
  isOverview: true,
  cleanTiles: true,
  path: 'overzicht',
  name: 'Dashboards',
  placeholderText: null,
  organisationUnit: null,
  iconName: 'queue_play_next',
 };