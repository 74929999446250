import React from 'react';
import MuiIconButton, { IconButtonProps as MuiIconButtonProps } from '@material-ui/core/IconButton';
import MuiIcon from '@material-ui/core/Icon';


import styled from 'styled-components';

const StyledControlWrapper = styled.div`
  position: sticky;
  right: 32px;
  display: inline-block;

` as React.ComponentType<React.HTMLProps<HTMLDivElement>>;

const StyledMuiIconButton = styled(({ hidden, ...iconProps }) => <MuiIconButton {...iconProps} />)`
  && {
    visibility: ${({ hidden }) => hidden ? 'hidden' : null};
  }
`as React.ComponentType<{ hidden: boolean } & MuiIconButtonProps>;

export interface UnitCompareRowControlProps {
  isEditable: boolean;
  disableMoveDown: boolean;
  disableMoveUp: boolean;
  onMoveUpClick: (ev: React.MouseEvent<Element, MouseEvent>) => void;
  onDeleteClick: (ev: React.MouseEvent<Element, MouseEvent>) => void;
  onMoveDownClick: (ev: React.MouseEvent<Element, MouseEvent>) => void;
}

const UnitCompareRowControl = (props: UnitCompareRowControlProps) => {

  const {
    isEditable,
    disableMoveDown,
    disableMoveUp,
    onMoveUpClick,
    onMoveDownClick,
    onDeleteClick
  } = props;

  return (
    <StyledControlWrapper>
      <div className={'unitCompareRowControl'}>
        {!disableMoveUp &&
          <StyledMuiIconButton
            color={'secondary'}
            onClick={onMoveUpClick}
            title={'Verplaats naar boven'}
            aria-label={'Verplaats naar boven'}
            hidden={!isEditable}
          >
            <MuiIcon fontSize={'small'}>arrow_upward</MuiIcon>
          </StyledMuiIconButton>
        }
        {!disableMoveDown &&
          <StyledMuiIconButton
            color={'secondary'}
            onClick={onMoveDownClick}
            title={'Verplaats naar onderen'}
            hidden={!isEditable}
          >
            <MuiIcon fontSize={'small'}>arrow_downward</MuiIcon>
          </StyledMuiIconButton>
        }
        <StyledMuiIconButton
          color={'secondary'}
          title={'Verwijderen'}
          hidden={!isEditable}
          onClick={onDeleteClick}
        >
          <MuiIcon fontSize={'small'}>close</MuiIcon>
        </StyledMuiIconButton>
      </div>
    </StyledControlWrapper>
  );
}

export default UnitCompareRowControl;