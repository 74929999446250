import { TransformFunction, TransformOptionsBase } from '../transform-utils';
import { calcNumberOfRows, calcNumberOfColumns, padLeftWithZeros } from '../transform-utils';
import * as allFormatters from '../../../utils/formatters';

interface RD {
  waarde: number;
  label_1: string;
  label_2?: string;
  label_3?: string;
  label_4: string;
  eenheid: string;
}

export interface ColumnChartStackedRawData {
  [key: string]: RD;
}

export interface ColumnChartStackedXThreshold {
  label: string;
  columnKey: string;
  columnIndex: number;
}

export interface ColumnChartStackedTransformOptions extends TransformOptionsBase {
  reverse?: boolean;
  /**
   * Set the key to use for the x axis.
   * @default label_1
   */
  xLabelKey?: 'label_1' | 'label_2' | 'label_3';
  /**
  * Append the threshold value to the xLabel (if there is a threshold).
  * For example xLabel is 'Augustus' and threshold is '2020', xLabel will be Augustus 2020
  * @default true
  */
  appendThresholdToXLabel?: boolean;
}


export interface ColumnChartStackedDataModel {
  key: string;
  label: string;
  xLabel: string;
  /**
   * Keys that correspond to the keys in rawValues and formattedValues.
   */
  valueKeys: string[];
  shortXLabel?: string;
  rawValues: { [key: string]: number };
  formattedValues: { [key: string]: string };
}

export interface ColumnChartStackedColumnDataModel {
  columnsModel: ColumnChartStackedDataModel[];
  xThreshold?: ColumnChartStackedXThreshold[];
}


const findXThreshold = (columns: ColumnChartStackedDataModel[]): ColumnChartStackedXThreshold[] => {
  let previousLabel = '';
  const xTresholdArr: ColumnChartStackedXThreshold[] = [];

  columns.forEach((column, index) => {
    if (column.label !== previousLabel && index !== 0) {
      xTresholdArr.push({
        columnIndex: index,
        columnKey: column.key,
        label: column.label
      })
    }

    previousLabel = column.label;

  });

  return xTresholdArr;
}

const columnChartStackedTransformer: TransformFunction<ColumnChartStackedColumnDataModel, ColumnChartStackedTransformOptions> = (
  rawData: ColumnChartStackedRawData,
  onError,
  options
) => {

  const keys = Object.keys(rawData);
  const guard = !keys.some((key) => rawData[key].waarde !== null);

  if (guard) {
    onError('nodata');
    return null;
  }

  const {
    reverse = false,
    formatters = {},
    xLabelKey = 'label_2',
    formattersOptions = {},
    appendThresholdToXLabel = true
  } = options || {} as ColumnChartStackedTransformOptions;



  const valueFormatterName = formatters['value'];
  const valueFormatter = (allFormatters as any)[valueFormatterName];
  const valueFormatterOptions = formattersOptions['value'];

  const xLabelFormatterName: string = formatters['xLabel'];
  const xLabelFormatter = (allFormatters as any)[xLabelFormatterName];
  const xLabelFormatterOptions = formattersOptions['xLabel'];

  const shortXLabelFormatterName: string = formatters['shortXLabel'];
  const shortXLabelFormatter = (allFormatters as any)[shortXLabelFormatterName];
  const shortXFormatterOptions = formattersOptions['shortXLabel'];

  const numberOfRows = calcNumberOfRows(keys);
  const numberOfColumns = calcNumberOfColumns(keys);

  const chartModel: ColumnChartStackedDataModel[] = [];

  for (let rowNumber = 0; rowNumber < numberOfRows; rowNumber++) {
    const formattedValues: { [key: string]: string } = {};
    const rawValues: { [key: string]: number } = {};
    const valueKeys: string[] = [];
    let label = '',
      xLabel = '',
      columnKey = '',
      shortXLabel = '';


    for (let colNumber = 0; colNumber < numberOfColumns; colNumber++) {
      let row = padLeftWithZeros(rowNumber, 2);
      let col = padLeftWithZeros(colNumber, 2);

      let key = `val${row}_${col}`;
      const rawDataItem = rawData[key];
      const { label_1, label_4, waarde, eenheid } = rawDataItem;

      const valueKey = label_4;

      //TODO: hoe kan label de threshold (2019 2020 etc.) zijn? of verkeerde benamingen of verkeerd vulling.
      label = label_1;
      columnKey = `${rowNumber}_${rawDataItem[xLabelKey]}`;

      rawValues[valueKey] = rawDataItem.waarde;
      formattedValues[valueKey] = valueFormatter ?
        valueFormatter(waarde, valueFormatterOptions) :
        allFormatters.formatValue(waarde, eenheid as ValueUnit, valueFormatterOptions);

      xLabel = xLabelFormatter ? xLabelFormatter(rawDataItem[xLabelKey] as string, xLabelFormatterOptions) : rawDataItem[xLabelKey] as string;
      if (!!label && appendThresholdToXLabel) {
        xLabel = `${xLabel} ${label}`;
      }

      shortXLabel = xLabelFormatter ? shortXLabelFormatter(rawDataItem[xLabelKey] as string, shortXFormatterOptions) : rawDataItem[xLabelKey] as string;
      valueKeys.push(valueKey);
    }

    chartModel.push({
      rawValues,
      label: label,
      xLabel: xLabel,
      key: columnKey,
      formattedValues,
      valueKeys: valueKeys,
      shortXLabel: shortXLabel,
    } as ColumnChartStackedDataModel)
  }

  let columnCharDataModel: ColumnChartStackedDataModel[] = [];
  columnCharDataModel = reverse ? chartModel.reverse() : chartModel;

  const xThreshold = findXThreshold(columnCharDataModel);

  return { columnsModel: columnCharDataModel, xThreshold };
};

export default columnChartStackedTransformer;