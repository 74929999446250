import { formatValue } from '../../../utils';
import { TransformFunction, TransformOptionsBase } from '../transform-utils';
import { calcNumberOfColumns, calcNumberOfRows, padLeftWithZeros } from '../transform-utils';
import * as allFormatters from '../../../utils/formatters';

interface RD {
  waarde: number;
  label_1?: string;
  label_2?: string;
  label_3?: string;
  label_4: string;
  eenheid: string;
}

type RDLabelKey = 'label_1' | 'label_2' | 'label_3' | 'label_4';

interface TableLeveledRawData {
  [key: string]: RD;
}

export interface TableLeveledCellData {
  label: string;
  rawValue: number | string | null;
  formattedValue: string;
}

export interface TableLeveledRowData {
  level: number;
  columns: TableLeveledCellData[];
}

export interface TableLeveledDataModel {
  rows: TableLeveledRowData[];
}

export interface TableLeveledTransformOptions extends TransformOptionsBase {
  /**
   * @default label_4
   */
  labelKey?: RDLabelKey;
  /**
   * If the rows should be reversed.
   */
  reverse?: boolean;
  /**
   * Properties from rawData (key) to convert to a column. The value will be used as header for the column.
   */
  convertToColumnKeys?: { [key in RDLabelKey]?: string };
  /**
   * Add tekst to a header by column index.
   */
  appendHeader?: { [key: string]: string };
}

export const tableLeveledTransformer: TransformFunction<TableLeveledDataModel, TableLeveledTransformOptions> = (
  rawData: TableLeveledRawData,
  onError,
  options
) => {

  const keys = Object.keys(rawData);
  const guard = !keys.some((key) => rawData[key].waarde !== null);

  if (guard) {
    onError('nodata');
    return null;
  }

  const {
    appendHeader,
    formatters = {},
    reverse = false,
    labelKey = 'label_4',
    formattersOptions = {},
  } = options || {} as TableLeveledTransformOptions;

  const numberOfRows = calcNumberOfRows(keys);
  const numberOfColumns = calcNumberOfColumns(keys);

  const rows: TableLeveledRowData[] = [];

  for (let rowNumber = 0; rowNumber < numberOfRows; rowNumber++) {

    let colObjects: RD[] = [];
    for (let colNumber = 0; colNumber < numberOfColumns; colNumber++) {

      let row = padLeftWithZeros(rowNumber, 2);
      let col = padLeftWithZeros(colNumber, 2);

      let key = `val${row}_${col}`;
      const item = rawData[key];

      colObjects.push(item);
    }

    let row: TableLeveledRowData = {
      level: 0,
      columns: []
    };
    colObjects.forEach((colObject, i) => {
      const { waarde, eenheid, label_2, label_3 } = colObject;
      const valueFormatterName = formatters[`${i + 1}`];
      const valueFormatterOptions = formattersOptions[`${i + 1}`];
      const valueFormatter = (allFormatters as any)[valueFormatterName];

      if (row.level === 0) {
        if (label_3) { row.level = 3; }
        else if (label_2) { row.level = 2; }
        else { row.level = 1; }
      }

      let label = colObject[labelKey] as string; // || labels['label_4'];

      if(!!appendHeader && !!appendHeader[`${i}`]) {
        label = `${label} ${appendHeader[`${i}`]}`;
      }

      row.columns.push({
        label: label,
        rawValue: waarde,
        formattedValue: valueFormatter ? valueFormatter(waarde, valueFormatterOptions) : formatValue(waarde, eenheid as ValueUnit, valueFormatterOptions)
      });
    });

    row.columns.unshift({
      label: '',
      rawValue: (colObjects[0] as any)[`label_${row.level}`],
      formattedValue: (colObjects[0] as any)[`label_${row.level}`]
    });

    if (row.columns.some((col) => col.rawValue != null)) {
      rows.push(row);
    }
  }

  let result = reverse ? rows.reverse() : rows;

  return {
    rows: result
  };
}

export default tableLeveledTransformer;