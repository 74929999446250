import { TransformFunction, TransformOptionsBase } from '../transform-utils';
import { calcNumberOfRows, calcNumberOfColumns, padLeftWithZeros } from '../transform-utils';
import * as allFormatters from '../../../utils/formatters';

interface RD {
  waarde: number;
  label_1: string;
  label_2?: string;
  label_3?: string;
  label_4: string;
  eenheid: string;
}

export interface ColumnChartDivergingRawData {
  [key: string]: RD;
}

export interface ColumnChartDivergingXThreshold {
  label: string;
  columnKey: string;
  columnIndex: number;
}

export interface ColumnChartDivergingTransformOptions extends TransformOptionsBase {
  reverse?: boolean;
  /**
   * Set the key to use for the x axis.
   * @default label_1
   */
  xLabelKey?: 'label_1' | 'label_2' | 'label_3';
}


export interface ColumnChartDivergingDataModel {
  key: string;
  label: string;
  xLabel: string;
  /**
   * Keys that correspond to the keys in rawValues and formattedValues.
   */
  valueKeys: string[];
  shortXLabel?: string;
  rawValues: { [key: string]: number };
  formattedValues: { [key: string]: string };
}

export interface ColumnChartRemainderColumnDataModel {
  columnsModel: ColumnChartDivergingDataModel[];
  xThreshold?: ColumnChartDivergingXThreshold[];
}


const findXThreshold = (columns: ColumnChartDivergingDataModel[]): ColumnChartDivergingXThreshold[] => {
  let previousLabel = '';
  const xTresholdArr: ColumnChartDivergingXThreshold[] = [];

  columns.forEach((column, index) => {
    if(column.label !== previousLabel && index !== 0) {
      xTresholdArr.push({
        columnIndex: index,
        columnKey: column.key,
        label: column.label
      })
    }

    previousLabel = column.label;

  });

  return xTresholdArr;
}

const columnChartDivergingTransformer: TransformFunction<ColumnChartRemainderColumnDataModel, ColumnChartDivergingTransformOptions> = (
  rawData: ColumnChartDivergingRawData,
  onError,
  options
) => {

  const keys = Object.keys(rawData);
  const guard = !keys.some((key) => rawData[key].waarde !== null);

  if (guard) {
    onError('nodata');
    return null;
  }

  const {
    reverse = false,
    formatters = {},
    formattersOptions = {},
    xLabelKey = 'label_2',
  } = options || {} as ColumnChartDivergingTransformOptions;



  const valueFormatterName = formatters['value'];
  const valueFormatter = (allFormatters as any)[valueFormatterName];
  const valueFormatterOptions = formattersOptions['value'];

  const xLabelFormatterName: string = formatters['xLabel'];
  const xLabelFormatter = (allFormatters as any)[xLabelFormatterName];
  const xLabelFormatterOptions = formattersOptions['xLabel'];

  const shortXLabelFormatterName: string = formatters['shortXLabel'];
  const shortXLabelFormatter = (allFormatters as any)[shortXLabelFormatterName];
  const shortXFormatterOptions = formattersOptions['shortXLabel'];
  const numberOfRows = calcNumberOfRows(keys);
  const numberOfColumns = calcNumberOfColumns(keys);

  const chartModel: ColumnChartDivergingDataModel[] = [];

  for (let rowNumber = 0; rowNumber < numberOfRows; rowNumber++) {
    const formattedValues: { [key: string]: string } = {};
    const rawValues: { [key: string]: number } = {};
    const valueKeys: string[] = [];
    let xLabel:string = '', label:string = '', shortXLabel:string = '', chartItemObjectKey:string = '';

    for (let colNumber = 0; colNumber < numberOfColumns; colNumber++) {
      let row = padLeftWithZeros(rowNumber, 2);
      let col = padLeftWithZeros(colNumber, 2);

      let key = `val${row}_${col}`;
      const item = rawData[key];
      const { label_1, label_4, waarde, eenheid } = item;
      const label4 = label_4.toLowerCase();

      label = label_1;
      chartItemObjectKey = `${rowNumber}_${item[xLabelKey]}`;

      rawValues[label4] = item.waarde;
      formattedValues[label4] =  valueFormatter ?
        valueFormatter(waarde, valueFormatterOptions) :
        allFormatters.formatValue(waarde, eenheid as ValueUnit, valueFormatterOptions);

      xLabel = xLabelFormatter ? xLabelFormatter(item[xLabelKey] as string, xLabelFormatterOptions) : item[xLabelKey] as string;
      shortXLabel = xLabelFormatter ? shortXLabelFormatter(item[xLabelKey] as string, shortXFormatterOptions) : item[xLabelKey] as string;
      valueKeys.push(label4);
    }

    chartModel.push({
      key: chartItemObjectKey,
      label: label,
      xLabel: xLabel,
      shortXLabel: shortXLabel,
      valueKeys: valueKeys,
      rawValues,
      formattedValues
    } as ColumnChartDivergingDataModel)
  }

  let columnCharDataModel: ColumnChartDivergingDataModel[] = [];
    columnCharDataModel = reverse ? chartModel.reverse() : chartModel;

  const xThreshold = findXThreshold(columnCharDataModel);

  return { columnsModel: columnCharDataModel, xThreshold};
};

export default columnChartDivergingTransformer;