import * as allFormatters from '../../../utils/formatters';
import { calcNumberOfRows, calcNumberOfColumns, padLeftWithZeros, TransformFunction, TransformOptionsBase } from '../transform-utils';

interface RD {
  waarde: number;
  label_1: string;
  label_2?: string;
  label_3?: string;
  label_4: string;
  eenheid: string;
}

export interface BarChartDivergingRawData {
  [key: string]: RD;
}

export interface BarChartDivergingTransformOptions extends TransformOptionsBase {
  reverse?: boolean;
  /**
   * Set the key to use for the y axis.
   * @default label_1
   */
  yLabelKey?: 'label_1' | 'label_2' | 'label_3';
}

export interface BarChartDivergingModel {
  key: string;
  label: string;
  valueKey: string;
  rawValue: number;
  formattedValue: string;
}


export interface BarChartDivergingDataModel {
  key: string;
  label: string;
  valueKey: string;
  rawValue: number;
  formattedValue: string;
  primaryValues?: BarChartDivergingModel;
  secondaryValues?: BarChartDivergingModel;
}

export interface BarChartDivergingBarDataModel {
  barsModel: BarChartDivergingDataModel [];
}

const barChartDivergingTransformer: TransformFunction<BarChartDivergingBarDataModel, BarChartDivergingTransformOptions> = (
  rawData: BarChartDivergingRawData,
  onError,
  options
) => {

  const keys = Object.keys(rawData);
  const guard = !keys.some((key) => rawData[key].waarde !== null);

  if (guard) {
    onError('nodata');
    return null;
  }

  const {
    reverse = false,
    formatters = {},
    formattersOptions = {},
    yLabelKey = 'label_1',
  } = options || {} as BarChartDivergingTransformOptions;

  const valueFormatterName = formatters['value'];
  const valueFormatter = (allFormatters as any)[valueFormatterName];
  const valueFormatterOptions = formattersOptions['value'];

  const chartDataModel: BarChartDivergingDataModel[] = [];
  const numberOfRows = calcNumberOfRows(keys);
  const numberOfCols = calcNumberOfColumns(keys);

  const formattedValue = (value: number, unit: string) => {
    return valueFormatter ?
      valueFormatter(value, valueFormatterOptions) :
      allFormatters.formatValue(value, unit as ValueUnit, valueFormatterOptions);
  }

  for (let rowNumber = 0; rowNumber < numberOfRows; rowNumber++) {
    let row = padLeftWithZeros(rowNumber, 2);
    let chartModel: BarChartDivergingDataModel =  { key: '', label: '',formattedValue:'', valueKey: '', rawValue: 0}

    for (let colNumber = 0; colNumber < numberOfCols; colNumber++) {
      let col = padLeftWithZeros(colNumber, 2);
      let value = rawData[`val${row}_${col}`];

      const model = {
        key: `${value[yLabelKey]}_${row}_${col}`,
        label: value.label_4,
        formattedValue: formattedValue(value.waarde, value.eenheid),
        valueKey: value[yLabelKey] as string,
        rawValue: value.waarde
      }

      if(colNumber === 0) chartModel = model;
      if(colNumber === 1) chartModel['primaryValues'] = model;
      if(colNumber === 2) chartModel['secondaryValues'] = model;
    }
    chartDataModel.push(chartModel)
  }

  let barCharDataModel: BarChartDivergingDataModel[] = [];
    barCharDataModel = reverse ? chartDataModel.reverse() : chartDataModel;

    return { barsModel: barCharDataModel };
};

export default barChartDivergingTransformer;