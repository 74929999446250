import MuiTable, { TableProps as MuiTableProps } from '@material-ui/core/Table';
import MuiTableBody from '@material-ui/core/TableBody';
import { TreeDataItem, useCheckBoxTreeHandlers } from 'ppd-library/components/organisms/CheckBoxTree';
import { Tile, TileProps } from 'ppd-library/components/organisms/Tile';
import React, { RefObject, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { BreadCrumbs } from '../components/layout/PageHeader';
import { UnitTreePopOver } from '../components/UnitTreePopOver';
import GenerateOutput from '../utils/generateCsv';
import { httpGetTileData, httpGet, httpPost, httpPut, httpDelete } from '../utils/http';
import UnitCompareFab from './unit-compare/UnitCompareFab';
import { UnitCompareDataRow, UnitCompareRow } from './unit-compare/UnitCompareRow';
import { UnitCompareStickyHeader } from './unit-compare/UnitCompareStickyHeader';
import { UnitCompareTileHeader } from './unit-compare/UnitCompareTileHeader';
import { TileTreePopOver } from '../components/TileTreePopOver';
import * as H from 'history';
import { TileTreeExtraTreeItemData } from '../components/TileTreePopOver/TileTreePopOverUtils';
import { unitCompareTransformer } from './unit-compare';
import { asyncTryCatch } from '../utils/errorHandling';
import { getQueryParams } from './dashboardpage/utils';

// eslint-disable-next-line
const { API_URL, SHOW_IDS, DATA_URL, USE_MOCK_DATA } = ppd.appConfig;

export interface UnitComparePageProps {
  history: H.History;
  isMobile: boolean;
  isTablet: boolean;
  account: API_GET.Account;
  staticTemplates: API_GET.Template[];
  helpActive: boolean;
  onLoadingChange: (loading: boolean) => void;
  // TODO: onClearAll via een (global) reducer o.i.d.
  onClearAll: (onConfirm: () => void) => void;
  isIos: boolean;
}

interface RawData {
  // key: oeCode
  [key: string]: {
    // key: dataKey
    [key: string]: { [key: string]: any } | null;
  };
}
interface TransformedData {
  // key: oeCode
  [key: string]: {
    // key: dataKey
    // item array represent a column (oeCode) 
    [key: string]: {
      label: string,
      rawValue: number;
      formattedValue: string;
    }[]
  }
}

export interface TileRow {
  id: number;
  position: number;
  label: string;
  dataKey: string;
  viewType: string;
  themeName: string;
  // themeIconName: string;
  componentName: string;
  dataRows: UnitCompareDataRow[];
}

interface TransformedTileDataRow  {
  label: string,
  rawValue: number;// | string | null;
  formattedValue: string;
};

type LocalUnitCompareSettings = Omit<API_GET.UnitCompareSettings, 'rows' | 'columns'>;

const StyledTile = styled(Tile)`
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;

    && {
      .MuiCardContent-root {
        padding: 0;
        transform: translateY(-0.91rem);
        overflow: visible;
      }
    }
` as React.ComponentType<TileProps>;



const StyledMuiTable = styled(MuiTable)`
    && {
      table-layout: fixed;
      width: 100%;
    }
` as React.ComponentType<MuiTableProps>;

// TODO: dit is ongeveer hetzelfde als voor dashboardpage. maak component van.
const StyledHeader = styled(({ isMobile, ...headerProps }) => <header {...headerProps} />)`
  left: 0;
  right: 0;
  top: 3.5rem;
  display: flex;
  position: fixed;
  min-height: 3.5rem;
  align-items: center;
  background: #FAFAFA;
  justify-content: stretch;
  padding-left: ${({ isMobile }) => isMobile ? ' 0.5rem' : ' 8.5rem'};
  padding-right: ${({ isMobile }) => isMobile ? ' 0.5rem' : ' 4rem'};
`as React.ComponentType<{ isMobile: boolean; } & React.HTMLProps<HTMLDivElement>>;

const StyledHeaderContent = styled(({ isMobilePhone, ...divProps }) => <div {...divProps} />)`
    flex: 1;
    display: flex;
    justify-content: space-between;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    height: ${({ isMobilePhone }) => isMobilePhone ? '4rem' : '3.5rem'};
  `as React.ComponentType<{ isMobilePhone: boolean } & React.HTMLProps<HTMLDivElement>>;

const StyledUnitCompareContainer = styled(({ isTablet, ...divProps }) => <div {...divProps} />)`
    margin-bottom: -6rem;
    margin-right: ${({ isTablet }) => isTablet ? '-0.5rem' : '-4rem'};

    .MuiCardHeader-root .MuiCardHeader-action {
      z-index: 2;
    }

`as React.ComponentType<{ isTablet: boolean } & React.HTMLProps<HTMLDivElement>>;

const StyledHeaderContentSection = styled.div`
  && {
    display: flex;
    align-items: center;
  }
`;

const StyledContentWrapper = styled(({ isTablet, innerRef, ...divProps }) => <div ref={innerRef} {...divProps} />)`
    overflow: auto;
    position: relative;
    margin-top: 0;
    overflow-x: ${({ isTablet }) => isTablet ? 'visible' : 'hidden'};
    height: ${({ isTablet }) => `calc(100vh - ${isTablet ? '160px' : '184px'})`}; //ONTWIKKELING:  Desktop waarschijnlijk 170px
  `as React.ComponentType<{ isTablet?: boolean; innerRef?: RefObject<HTMLDivElement> } & React.HTMLProps<HTMLDivElement>>;


const StyledNoTileTextRow = styled.tr`
  background: #FAFAFA;
`;

const getAllRawData = async (columns: API_GET.UnitCompareColumn[], rows: API_GET.UnitCompareRow[]) => {
  const promises = [] as Promise<{ oeCode: string, dataKey: string, result: { [key: string]: any } | null }>[];

  columns.forEach((column) => {
    const { organisationUnit } = column;
    const { code: oeCode } = organisationUnit;

    rows.forEach((row) => {
      const { dataKey } = row;
      const { value: dataKeyValue } = dataKey;

      const promise = getRawTileRowDataSingleColumn(column, row).then((result) => {
        return {
          oeCode,
          dataKey: dataKeyValue,
          result
        };
      });

      promises.push(promise);
    });
  });

  const resultPromise = Promise.all(promises).then((promiseResults) => {
    // oeCode --> dataKey --> data
    const rawData = {} as RawData;
    promiseResults.forEach(({ oeCode, dataKey, result }) => {
      rawData[oeCode] = rawData[oeCode] || {};
      rawData[oeCode][dataKey] = result;

    });

    return rawData;
  });

  return resultPromise;
};

/**
 * fetch the data for one column/oe of an entire tile-row (a tile-row encases multiple datarows).
 */
const getRawTileRowDataSingleColumn = async (column: API_GET.UnitCompareColumn, row: API_GET.UnitCompareRow) => {
  const { organisationUnit } = column;
  const { code: oeCode } = organisationUnit;
  const { dataKey } = row;
  const { value: dataKeyValue } = dataKey;

  try {
    const rawColumnData = await httpGetTileData(DATA_URL, oeCode, dataKeyValue, USE_MOCK_DATA);
    return rawColumnData;
  } catch (error) {
    // TODO: wanneer en waar de error afvangen?
    console.log(`Kan data niet ophalen voor ${oeCode} ${dataKeyValue}`);
    return null;
  }
}

/**
 * returns all transformed columns for all 'tilerows' (multiple data rows):
 * {
 *  [oeCode]: { (column)
 *    [dataKey] (tileRow): [
 *      { (dataRow)
 *        label,
 *        rawValue,
 *        formattedValue
 *      }
 *    ]
 *  }
 * }
 */
const transformAllData = (rawData: any, unitCompareRows: API_GET.UnitCompareRow[], unitCompareColumns: API_GET.UnitCompareColumn[]) => {
  let transformedData = {} as TransformedData;
  let emptyTileRowData = [] as TransformedTileDataRow[];

  unitCompareColumns.forEach((column) => {
    const { organisationUnit } = column;
    const { code: oeCode } = organisationUnit;
    // allRawColumnData is all data for one column/oe : { [datakey1]: {valxx: ..}, [datakey2]: {valyy: ..} }
    const allRawColumnData = rawData[oeCode];
    transformedData[oeCode] = transformedData[oeCode] || {};

    unitCompareRows.forEach((row) => {
      const { dataKey } = row;
      const { value: dataKeyValue } = dataKey;

      // rawTileRowData is the data for one specific tile and column/oe combination : { valxx: .., valxy: .. }
      const rawTileRowData = allRawColumnData[dataKeyValue];
      // transformedTileRowData is the transformed data for one specific tile and column/oe combination
      let transformedTileRowData = [] as TransformedTileDataRow[];

      const onError = (errorType: any) => {
        console.log(`error type ${errorType} transforming ${dataKeyValue} for ${oeCode}`);
      };
      try {
        const transformResult = unitCompareTransformer(rawTileRowData, onError);
        if (!!transformResult) {
          transformedTileRowData = transformResult.column;
          emptyTileRowData = [{...transformResult.column[0], formattedValue: '-'}];
        }

        transformedData[oeCode][dataKeyValue] = transformedTileRowData;
      } catch (error) {
        //TODO: to bad, continue?
        console.log(`error transforming ${row.dataKey} for ${oeCode}`);
        transformedData[oeCode][dataKeyValue] = emptyTileRowData;
      }
    });
  });


  return transformedData;
}

const createTileRows = (allTransformedData: TransformedData, unitCompareRows: API_GET.UnitCompareRow[], unitCompareColumns: API_GET.UnitCompareColumn[]) => {
  const unitCompareRowsByOrder = [...unitCompareRows].sort((a, b) => a.position - b.position);
  const unitCompareColumnsByOrder = [...unitCompareColumns].sort((a, b) => a.position - b.position);
  
  const tileRows = unitCompareRowsByOrder.map((unitCompareRow/*, index*/) => {
    const { dataKey, viewType, ...unitCompareRowRestProperties } = unitCompareRow;
    const { value: dataKeyValue } = dataKey;

    let dataRows = [] as UnitCompareDataRow[];

    const labels: string[] = [];
    unitCompareColumnsByOrder.forEach((unitCompareColumn, columnIndex) => {
      const { organisationUnit } = unitCompareColumn;
      const { code: oeCode } = organisationUnit;
      const allFormattedColumnData = allTransformedData[oeCode] || {};

      // pivot vertical/column data to horizontal/row data
      const formattedTileRowData = allFormattedColumnData[dataKeyValue] || [];

      // first column is in charge of rows shown (in for example top 5 or top 10)
      if(columnIndex === 0) {
        formattedTileRowData.forEach((cell, rowIndex) => {
          labels[rowIndex] = cell.label;
          dataRows[rowIndex] = { } as UnitCompareDataRow;
        });
      }

      formattedTileRowData.forEach((cell) => { 
        const rowIndex = labels.findIndex((label) => label === cell.label);

        if(rowIndex !== -1) {
          dataRows[rowIndex][oeCode] = cell;
        }
      });
    });

    dataRows = dataRows.map((row, rowIndex) => {
      return {
        ...row,
        labelColumn: { label: labels[rowIndex] }
      };
    });

    return {
      ...unitCompareRowRestProperties,
      viewType: viewType.name,
      dataKey: dataKeyValue,
      // rowNumber: index,
      dataRows
    } as TileRow;
  });

  return tileRows;
}

const getCheckedUnitIds = (columns: API_GET.UnitCompareColumn[]) => {
  return columns.map(({ organisationUnit }) => organisationUnit.code);
};

const getCheckedTileIds = (rows: API_GET.UnitCompareRow[]) => {
  return rows.map((row) => `view-${row.viewId}`);
}

const excludedTemplates = [] as string[];
const excludeTemplate = (template: API_GET.Template) => {
  const { name, isOverview } = template;
  const lower = name.toLowerCase();

  return !!isOverview || excludedTemplates.some((excluded) => lower === excluded);
};

// TODO: filteren op title is niet de beste oplossing, doen op id?...
const excludedTiles = ['ziekteverzuimvenster'] as string[]; //NOTE!: lowercase
const excludeTile = (tile: API_GET.Tile) => {
  const { title } = tile;
  const lower = title.toLowerCase();

  return lower.startsWith('exporteren') || excludedTiles.some((excluded) => lower === excluded);
};

const excludedViewTypes: API.ViewTypeName[] = ['mapview', 'textview', 'bartrendview'];
const excludeView = (view: API_GET.View) => {
  const { viewType } = view;
  const lower = viewType.name.toLowerCase();

  return excludedViewTypes.some((excluded) => lower === excluded);
};

const generateTempId = () => -Math.floor(Math.random() * 1000000);

const UnitComparePage = (props: UnitComparePageProps) => {
  const firstColumnWidth = 300;
  const fixedColumnWidth = 200;
  const scrollAmount = fixedColumnWidth; // * 2;
  const { history, onLoadingChange, isMobile, isTablet, onClearAll, isIos, account, staticTemplates } = props;

  const urlQueryParamValues = getQueryParams(history.location);
  const showDevIds = SHOW_IDS || urlQueryParamValues['show_ids'] === 'true';

  const [unitCompareSettings, setUnitCompareSettings] = useState<LocalUnitCompareSettings | null>(null);
  const [unitCompareRows, setUnitCompareRows] = useState<API_GET.UnitCompareRow[]>([]);
  const [unitCompareColumns, setUnitCompareColumns] = useState<API_GET.UnitCompareColumn[]>([]);
  const serverStateUnitCompareColumnsRef = useRef<API_GET.UnitCompareColumn[]>([]);
  const serverStateUnitCompareRowsRef = useRef<API_GET.UnitCompareRow[]>([]);

  const [isLoading, setIsLoading] = useState(true);
  const [tileRows, setTileRows] = useState<TileRow[]>([]);
  const [unitInputValue, setUnitInputValue] = useState('');
  const [isUnitPopOverOpen, setIsUnitPopOverOpen] = useState(false);
  const {
    checkedIds: unitPopOverCheckedIds,
    expandedIds: unitPopOverExpandedIds,
    setCheckedIds: setUnitPopOverCheckedIds,
    setExpandedIds: setUnitPopOverExpandedIds,
    handleNodeExpandChange: handleUnitPopOverNEC,
    handleNodeCheckedChange: handleUnitPopOverNCC,
    handleMultipleNodeCheckedChange: handleUnitPopOverMNCC
  } = useCheckBoxTreeHandlers(getCheckedUnitIds(unitCompareColumns), ['NP00000']);

  const [tileInputValue, setTileInputValue] = useState('');
  const [isTilePopOverOpen, setIsTilePopOverOpen] = useState(false);
  const {
    checkedIds: tilePopOverCheckedIds,
    expandedIds: tilePopOverExpandedIds,
    setCheckedIds: setTilePopOverCheckedIds,
    setExpandedIds: setTilePopOverExpandedIds,
    handleNodeExpandChange: handleTilePopOverNEC,
    handleNodeCheckedChange: handleTilePopOverNCC,
    handleMultipleNodeCheckedChange: handleTilePopOverMNCC
  } = useCheckBoxTreeHandlers(getCheckedTileIds(unitCompareRows), []);

  const refTableContainer = useRef<HTMLDivElement>(null);
  const refTable = useRef<HTMLDivElement>(null);
  const refTableBody = useRef<HTMLDivElement>(null);

  interface LocalLoadingType {
    rowPosition: boolean;
    pinnedColumn: boolean;
    deleteColumn: boolean;
    deleteRowsBulk: boolean;
    columnPosition: boolean;
    columnsAndRows: boolean;
    unitCompareRows: boolean;
    unitCompareSettings: boolean;
    unitCompareColumnsBulk: boolean;
    unitCompareSettingsReset: boolean;
    unitCompareColumnsBulkDelete: boolean;
  };
  const localLoadingRef = useRef<LocalLoadingType>(
    {
      rowPosition: false,
      deleteColumn: false,
      pinnedColumn: false,
      deleteRowsBulk: false,
      columnPosition: false,
      columnsAndRows: false,
      unitCompareRows: false,
      unitCompareSettings: false,
      unitCompareColumnsBulk: false,
      unitCompareSettingsReset: false,
      unitCompareColumnsBulkDelete: false,
    });
  const localOnLoadingChange = (type: keyof LocalLoadingType) => {
    localLoadingRef.current[type] = !localLoadingRef.current[type];
    const localLoading = localLoadingRef.current;

    const allFalse = Object.keys(localLoading).reduce((previous, key) => {
      return previous && localLoadingRef.current[key as keyof LocalLoadingType] === false;
    }, true);

    if(allFalse) {
      onLoadingChange(false);
      setIsLoading(false);
    } else {
      onLoadingChange(true);
      setIsLoading(true);
    }
  };

  useEffect(() => {
    const getUnitCompareSettings = async () => {
      localOnLoadingChange('unitCompareSettings');

      const getResult = await asyncTryCatch(httpGet<API_GET.UnitCompareSettings | undefined | null>(`${API_URL}/accounts/${account.id}/unitcomparesettings`));
      if (getResult.isError) {
        // TODO: show notification
        localOnLoadingChange('unitCompareSettings');
        throw new Error(getResult.value as any);
      } else {
        const UCS = getResult.value;

        if(!UCS) {
          // POST nieuwe settings? of moeten we defaultsettings maken aan de server kant.
        } else {
          const { rows, columns, ...rest } = UCS as API_GET.UnitCompareSettings;
          
          setUnitCompareSettings({...rest});

          localUpdateRowsAndPopOver(rows);
          localUpdateColumnsAndPopOver(columns);

          serverStateUnitCompareColumnsRef.current = columns;
        }
      }

      localOnLoadingChange('unitCompareSettings');
    };

    getUnitCompareSettings();
  
  // eslint-disable-next-line
  }, []);

  // when columns or rows change, fetch 'all' rawData
  useEffect(() => {

    localOnLoadingChange('columnsAndRows');
      // NOTE: we assume here that the data will be cached in http.ts, but what if it is not (anymore)?
    getAllRawData(unitCompareColumns, unitCompareRows).then((result) => {
      // TODO: moet het transformen en rows maken ook async?
      const transformedData = transformAllData(result, unitCompareRows, unitCompareColumns);
      const rows = createTileRows(transformedData, unitCompareRows, unitCompareColumns);
      setTileRows(rows);

    }).finally(() => {
      localOnLoadingChange('columnsAndRows');
    });

    // eslint-disable-next-line
  }, [unitCompareColumns, unitCompareRows]);


  // Drag & scroll ~ ~ ~ ~ ~
  // TODO: Voor de desktop momentum toevoegen bij het releasen
  useEffect(() => {
    const container = refTableContainer.current as HTMLTableElement;
    const tableBody = refTableBody.current as HTMLTableElement;
    let isDown = false;
    let startX = 0;
    let scrollLeft = 0;

    // TODO: mouse functie's en event koppelingen even in aparte file.
    const mousemove = (e: MouseEvent) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - container.offsetLeft;
      const walk = x - startX;
      container.scrollLeft = scrollLeft - walk;
    }

    const mousedown = (e: MouseEvent) => {
      isDown = true;
      startX = e.pageX - container.offsetLeft;
      scrollLeft = container.scrollLeft;
    }

    const mouseUpAndLeave = () => isDown = false;

    // "mousemove" alleen op de table body ivm ongewenst gedrag bij drag & drop in de table header
    const
      mouseover = () => {
        container.addEventListener('mousemove', mousemove, true);
        container.addEventListener('mousedown', mousedown, true);
      },
      mouseout = () => {
        container.removeEventListener('mousemove', mousemove, true);
        container.removeEventListener('mousedown', mousedown, true);
      }
    tableBody.addEventListener('mouseover', mouseover, true);
    tableBody.addEventListener('mouseout', mouseout, true);

    container.addEventListener('mouseup', mouseUpAndLeave, true);
    container.addEventListener('mouseleave', mouseUpAndLeave);

    return () => {
      container.removeEventListener('mousemove', mousemove, true);
      container.removeEventListener('mousedown', mousedown, true);
      container.removeEventListener('mouseup', mouseUpAndLeave);
      container.removeEventListener('mouseleave', mouseUpAndLeave, true);
      tableBody.removeEventListener('mouseover', mouseover, true);
      tableBody.removeEventListener('mouseout', mouseout, true);
    }
  }, []);

  const handleClearAll = () => {
    onClearAll(handleClearAllConfirm);
  };

  const handleClearAllConfirm = () => {
    localUpdateColumnsAndPopOver([]);
    localUpdateRowsAndPopOver([]);
    setTilePopOverExpandedIds([]);
    setUnitPopOverExpandedIds(['NP00000']);

    resetAndSaveUnitCompareSettings();
  };

  const handleUnitPopOverClose = async () => {
    setIsUnitPopOverOpen(false);
    
    syncUnitCompareColumnsState(unitCompareColumns);
  };

  const handleOpenUnitPopOver = () => {
    setIsUnitPopOverOpen(true);
  };

  const handleTilePopOverClose = () => {
    setIsTilePopOverOpen(false);

    syncUnitCompareRowsState(unitCompareRows);
  };

  const handleOpenTilePopOver = () => {
    setIsTilePopOverOpen(true);
  };

  const handleUnitPopOverNodeCheckedChange = (item: TreeDataItem) => {
    handleUnitPopOverNCC(item);

    if (unitCompareColumns.some(({ organisationUnit }) => organisationUnit.code === item.id)) {
      let newColumns = unitCompareColumns.filter(({ organisationUnit }) => organisationUnit.code !== item.id);
      localUpdateColumnsAndPopOver(newColumns);
    } else {
      const newColumn =
      {
        id: generateTempId(),
        organisationUnit: {
          id: -1,
          level: -1,
          code: item.id,
          name: item.label,
        },
        isPinned: false,
        position: Math.max(0, ...unitCompareColumns.map(({ position }) => position)) + 1
      };
      let newColumns = [...unitCompareColumns, newColumn];
      localUpdateColumnsAndPopOver(newColumns);
    }
  };

  /**
   * A datakey may exist of a concatenation of multiple datakeys, comma delimited.
   * If so, the componentProperties.dataTransformProperties.useRawDataIndex should define which key should be used (defaults to 0)
   * 
   * @param dataKeys the dataKeys of a view 
   * @param componentProperties componentProperties that may or may not contain dataTransformProperties.useRawDataIndex (default 0)
   */
  const findActiveDataKey = (dataKeys: API.DataKey[], componentProperties: API_GET.ViewComponentProperties) => {
    // TODO: hardcoded oe key gebruikt
    const dataKey = dataKeys.find(({ type }) => type.name === 'oe') as API.DataKey;
    const dataKeyValue = !!dataKey ? dataKey.value : '';
    
   if(dataKeyValue.indexOf(',') !== -1) {
     const { dataTransformProperties } = componentProperties;
     const { useRawDataIndex } = dataTransformProperties || { useRawDataIndex: 0 };
     const keys = dataKeyValue.split(',');

     return keys[useRawDataIndex];
   }

   return dataKeyValue;
 };

  const handleTilePopOverNodeCheckedChange = (item: TreeDataItem) => {
    const checked = handleTilePopOverNCC(item);

    const { extraTreeItemData = {} } = item;
    const {
      template,
      templateTile,
      tileTab,
      tabView
    } = extraTreeItemData as TileTreeExtraTreeItemData;

    const { id: templateId } = template as API_GET.TemplateDetail;
    const { tileId, tile } = templateTile as API_GET.TemplateTileDetail;
    const { title: tileTitle } = tile;
    const { id: tabId, title: tabTitle } = tileTab as API_GET.TabDetail;
    const { id: viewId, viewType, dataKeys, component } = tabView as API_GET.View;
    const { name: componentName, properties: componentProperties} = component;

    const { name: themeName,  iconName: themeIconName} = staticTemplates.find(({id}) => id === templateId) || { name: '', iconName: '' };
 
    if(checked) {
      // TODO: hardcoded oe key gebruikt
      const oeDataKey = dataKeys.find(({ type }) => type.name === 'oe') as API.DataKey;
      const activeDataKeyValue = findActiveDataKey(dataKeys, componentProperties);

      const dataKey: API.DataKey = {
        id: -1,
        type: oeDataKey.type,
        value: activeDataKeyValue
      };

      const row: API_GET.UnitCompareRow = {
        tabId,
        tileId,
        viewId,
        templateId,
        dataKey,
        viewType,
        themeName,
        themeIconName,
        componentName,
        id: generateTempId(),
        label: !!tabTitle ? `${tileTitle} - ${tabTitle}`: tileTitle,
        position: Math.max(0, ...unitCompareRows.map(({ position }) => position)) + 1,
      };

      localUpdateRowsAndPopOver([...unitCompareRows, row]);

    } else {
      let newRows = [...unitCompareRows];
      newRows = newRows.filter((existingRow) => {
        if (templateId === existingRow.templateId &&
          tileId === existingRow.tileId &&
          tabId === existingRow.tabId &&
          viewId === existingRow.viewId) {
          return false;
        }

        return true;
      });
      localUpdateRowsAndPopOver(newRows);
    }
  };

  const handleUnitPopOverMultipleNodeCheckedChange = (items: TreeDataItem[], checked: boolean | undefined) => {
    handleUnitPopOverMNCC(items, checked);

    if (typeof checked !== 'undefined') {
      if (checked) {
        const lastOrder = Math.max(0, ...unitCompareColumns.map(({ position }) => position));
        // filter any oe that already exists because there may only be one column per oe.
        const createItems = items.filter(({ id }) => !unitCompareColumns.some(({ organisationUnit }) => organisationUnit.code === id));
        
        const newColumns = createItems.map((item, i) => {
          return {
            id: generateTempId(),
            organisationUnit: { 
              id: -1,
              level: -1,
              code: item.id,
              name: item.label,
            },
            isPinned: false,
            position: lastOrder + (i + 1)
          };
        });

        let unitCompareColumnsClone = [...unitCompareColumns, ...newColumns];
        localUpdateColumnsAndPopOver(unitCompareColumnsClone);
      } else {
        const removeColumns = unitCompareColumns.filter(({ organisationUnit }) => items.some(({ id }) => organisationUnit.code === id));
        const unitCompareColumnsClone = unitCompareColumns.filter(({ id }) => !removeColumns.some(({ id: removeId }) => removeId === id));
        localUpdateColumnsAndPopOver(unitCompareColumnsClone);
      }
    } else {
      // TODO: gebeurt nooit maar toch afhandelen?
    }
  };
 
  const handleTilePopOverMultipleNodeCheckedChange = (items: TreeDataItem[], checked: boolean | undefined) => {
    // NOTE: can only check one view at a time, so this function will never be called...
    handleTilePopOverMNCC(items, checked);
};

  const localUpdateColumnsAndPopOver = (newColumns: API_GET.UnitCompareColumn[]) => {
    setUnitCompareColumns(newColumns);
    setUnitPopOverCheckedIds(getCheckedUnitIds(newColumns));
  }

  /**
   * Update the server columns by deleting and creating bulk columns
   */
  const syncUnitCompareColumnsState = async (localColumns: API_GET.UnitCompareColumn[]) => {
    // vergelijk met ref server state
    const serverColumns = serverStateUnitCompareColumnsRef.current;
    const columnsToDelete: API_GET.UnitCompareColumn[] = [];
    const columnsToCreate: API_GET.UnitCompareColumn[] = [];

    // NOTE!: need to use id and not organisationUnit.code, 
    // because position can be changed by removing and adding the same column again.
    // By using id, we can process that as two seperate actions (delete old and create new column).
    localColumns.forEach((localColumn) => {
      if (localColumn.id < 0) {
        columnsToCreate.push(localColumn);
      }
    });

    serverColumns.forEach((serverColumn) => {
      const localColumn = localColumns.find(({ id }) => id === serverColumn.id);

      if (!localColumn) {
        columnsToDelete.push(serverColumn);
      }
    });

    let deletePromise = Promise.resolve();
    if (columnsToDelete.length > 0) {
      deletePromise = bulkDeleteUnitCompareColumn(columnsToDelete.map(({ id }) => id));
    }

    let savePromise = Promise.resolve(serverColumns.filter(({ id }) => !columnsToDelete.some(({ id: deleteId }) => id === deleteId)));
    if (columnsToCreate.length > 0) {
      savePromise = bulkSaveUnitCompareColumn(
        columnsToCreate.map(({ position, organisationUnit }) => {
          return {
            position,
            organisationUnitCode: organisationUnit.code,
            organisationUnitName: organisationUnit.name
          };
        })
      );
    }

    const [, newServerState] = await Promise.all([deletePromise, savePromise]);

    serverStateUnitCompareColumnsRef.current = newServerState;
  };

   /**
   * Update the server rows by deleting and creating bulk columns
   */
  const syncUnitCompareRowsState = async (localRows: API_GET.UnitCompareRow[]) => {
    const serverRows = serverStateUnitCompareRowsRef.current;
    const rowsToDelete: API_GET.UnitCompareRow[] = [];
    const rowsToCreate: API_GET.UnitCompareRow[] = [];

    localRows.forEach((localRow) => {
      if (localRow.id < 0) {
        rowsToCreate.push(localRow);
      }
    });

    serverRows.forEach((serverRow) => {
      const localRow = localRows.find(({ id }) => id === serverRow.id);

      if (!localRow) {
        rowsToDelete.push(serverRow);
      }
    });

    let deletePromise = Promise.resolve();
    let savePromise = Promise.resolve(serverRows.filter(({ id }) => !rowsToDelete.some(({ id: deleteId }) => id === deleteId)));
    if (rowsToDelete.length > 0) {
      deletePromise = bulkDeleteUnitCompareRow(rowsToDelete.map(({ id }) => id));
    }

    if (rowsToCreate.length > 0) {
      savePromise = bulkSaveUnitCompareRow(rowsToCreate);
    }

    const [, newServerState] = await Promise.all([deletePromise, savePromise]);

    serverStateUnitCompareRowsRef.current = newServerState;
  };

  /**
   * 
   * @returns The complete and synced state of the unitcompare columns
   */
  const bulkSaveUnitCompareColumn = async (newColumns: { organisationUnitCode: string; organisationUnitName: string, position: number; }[]) => {
    localOnLoadingChange('unitCompareColumnsBulk');
    const body = newColumns.map((column) => {
      const { organisationUnitCode, position } = column;

      return {
        position,
        organisationUnitCode: organisationUnitCode,
        unitCompareSettingsId: (unitCompareSettings as API_GET.UnitCompareSettings).id
      } as API_POST.UnitCompareColumn;
    });

    const postResult = await asyncTryCatch(httpPost<API_POST.UnitCompareColumn[], API_GET.UnitCompareColumn[]>(
      `${API_URL}/unitcomparecolumns_bulk`,
      body
    ));

    localOnLoadingChange('unitCompareColumnsBulk');
    if (postResult.isError) {
      // TODO: show notification
      throw new Error((postResult.value as Error).message);
    } else {
      const resultColumns = postResult.value as API_GET.UnitCompareColumn[];
      let unitCompareColumnsClone = unitCompareColumns.filter((column) => {
        // filter out the temp columns
        const { organisationUnit } = column;
        const { code } = organisationUnit;
        const resultColumn = resultColumns.find(({ organisationUnit: resultOU }) => code === resultOU.code);

        return !resultColumn;
      });

      unitCompareColumnsClone = [...unitCompareColumnsClone, ...resultColumns];

      localUpdateColumnsAndPopOver(unitCompareColumnsClone);

      return unitCompareColumnsClone;
    }
  };

  const bulkDeleteUnitCompareColumn = async (ids: number[]) => {
    localOnLoadingChange('unitCompareColumnsBulkDelete');

    const deleteResult = await asyncTryCatch(httpPost<number[], void>(
      `${API_URL}/unitcomparecolumns/delete_bulk`,
      ids
    ));

    localOnLoadingChange('unitCompareColumnsBulkDelete');
    if (deleteResult.isError) {
      // TODO: show notification
      throw new Error((deleteResult.value as Error).message);
    } else {
      // nothing...
    }
  };

  const resetAndSaveUnitCompareSettings = async () => {
    localOnLoadingChange('unitCompareSettingsReset');

    const {id} = unitCompareSettings as API_GET.UnitCompareSettings;
    const deleteResult = await asyncTryCatch(httpDelete(`${API_URL}/unitcomparesettings/${id}`));

    if (deleteResult.isError) {
      localOnLoadingChange('unitCompareSettingsReset');
      // TODO: show notification
      throw new Error((deleteResult.value as Error).message);
    } else {
      const getResult = await asyncTryCatch(httpGet<API_GET.UnitCompareSettings>(`${API_URL}/accounts/${account.id}/unitcomparesettings`));

      localOnLoadingChange('unitCompareSettingsReset');

      if (getResult.isError) {
        // TODO: show notification
        throw new Error((deleteResult.value as Error).message);
      } else {
        const resultSettings = getResult.value as API_GET.UnitCompareSettings;
        const { id, pinnedColumnId, accountId} = resultSettings;

        setUnitCompareSettings({ id, accountId, pinnedColumnId });
        serverStateUnitCompareColumnsRef.current = [];
        serverStateUnitCompareRowsRef.current = [];
      }
    }
  };

  const localUpdateRowsAndPopOver = (newRows: (API_GET.UnitCompareRow)[]) => {
    setUnitCompareRows(newRows);
    setTilePopOverCheckedIds(getCheckedTileIds(newRows));
  }

  /**
   * 
   * @param newRows API_POST.UnitCompareRow[] will be constructed from newRows
   */
  const bulkSaveUnitCompareRow = async (newRows: API_GET.UnitCompareRow[]) => {
    localOnLoadingChange('unitCompareRows');

    const body: API_POST.UnitCompareRow[] = newRows.map((tempRow) => {
      const { dataKey: localDataKey, viewType: localViewType, id, ...rest } = tempRow;
      return {
        ...rest,
        dataKey: localDataKey.value,
        viewType: localViewType.name,
        unitCompareSettingsId: (unitCompareSettings as API_GET.UnitCompareSettings).id,
      };
    });

    const postResult = await asyncTryCatch(httpPost<API_POST.UnitCompareRow[], API_GET.UnitCompareRow[]>(
      `${API_URL}/unitcomparerows_bulk`,
      body
    ));

    localOnLoadingChange('unitCompareRows');

    if(postResult.isError) {
      // TODO: show notification
      throw new Error((postResult.value as Error).message);
    } else {
      const resultRows = postResult.value as API_GET.UnitCompareRow[];
      let unitCompareRowsClone = unitCompareRows.filter(({ id }) => {
        return id > 0;
      });

      unitCompareRowsClone = [...unitCompareRowsClone, ...resultRows];

      localUpdateRowsAndPopOver(unitCompareRowsClone);

      return unitCompareRowsClone;
    }
  }

  const savePinnedColumn = async (pinnedColumnId: number | null) => { 
    localOnLoadingChange('pinnedColumn');
    const { id } = unitCompareSettings as LocalUnitCompareSettings;
    const putResult = await asyncTryCatch(httpPut<API_PUT.UnitCompareSettings, API_GET.UnitCompareSettings>(
      `${API_URL}/unitcomparesettings/${id}/setpinnedcolumn`,
      { pinnedColumnId }
    ));

    localOnLoadingChange('pinnedColumn');

    if(putResult.isError) {
       // TODO: show notification
       throw new Error((putResult.value as Error).message);
    } else {
      //TODO: ... nothing to do? officialy we shoud overwrite our local state with the result..
    }
  };

  // TODO: deze kan ook bulk put
  const updateUnitCompareRowPosition = async (rowId: number, position: number) => {
    localOnLoadingChange('rowPosition');
    const putResult = await asyncTryCatch(httpPut<API_PUT.UnitCompareRow, API_GET.UnitCompareRow>(
      `${API_URL}/unitcomparerows/${rowId}`,
      { position }
    ));

    localOnLoadingChange('rowPosition');
    if(putResult.isError) {
      // TODO: show notification
      throw new Error((putResult.value as Error).message);
   } else {
     //TODO: ... nothing to do? officialy we shoud overwrite our local state with the result..
   }
  };

  const bulkUpdateUnitCompareColumnPositions = async (columns: API_GET.UnitCompareColumn[]) => {
    localOnLoadingChange('columnPosition');
    const body = columns.map(({ id, position }) => ({id, position} as API_PUT.UnitCompareColumn ));

    const putResult = await asyncTryCatch(httpPut<API_PUT.UnitCompareColumn[], API_GET.UnitCompareColumn[]>(
      `${API_URL}/unitcomparecolumns_bulk`,
      body
    ));
    localOnLoadingChange('columnPosition');

    if(putResult.isError) {
      // TODO: show notification
      throw new Error((putResult.value as Error).message);
   } else {
     serverStateUnitCompareColumnsRef.current = columns;
   }
  }

  const bulkDeleteUnitCompareRow = async (ids: number[]) => {
    localOnLoadingChange('deleteRowsBulk');
    const deleteResult = await asyncTryCatch(httpPost<number[], void>(
      `${API_URL}/unitcomparerows/delete_bulk`,
      ids
    ));
    
    localOnLoadingChange('deleteRowsBulk');
    if(deleteResult.isError) {
      // TODO: show notification
      throw new Error((deleteResult.value as Error).message);
   } else {
     //nothing...
   }
  };

  const handleMoveRowUpClick = (ev: React.MouseEvent<Element, MouseEvent>, previousRowId: number, rowId: number) => {
    ev.stopPropagation();

    handleSwapRows(previousRowId, rowId);
  };

  const handleMoveRowDownClick = (ev: React.MouseEvent<Element, MouseEvent>, rowId: number, nextRowId: number) => {
    ev.stopPropagation();

    handleSwapRows(rowId, nextRowId);
  };

  const handleDeleteRowClick = (ev: React.MouseEvent<Element, MouseEvent>, rowId: number) => {
    ev.stopPropagation();

    const filteredRows =  unitCompareRows.filter((row) => row.id !== rowId);
    localUpdateRowsAndPopOver(filteredRows);

    syncUnitCompareRowsState(filteredRows);
  };

  const handleSwapRows = (rowIdA: number, rowIdB: number) => {
    const rowA = unitCompareRows.find((row) => row.id === rowIdA) as API_GET.UnitCompareRow;
    const rowB = unitCompareRows.find((row) => row.id === rowIdB) as API_GET.UnitCompareRow;

    const newPositionRowA = rowB.position;
    const newPositionRowB = rowA.position;

    const newUnitCompareRows = unitCompareRows.map((row) => {
      if(row.id === rowIdA) {
        return { ...row, position: newPositionRowA}
      }
      if(row.id === rowIdB) {
        return { ...row, position: newPositionRowB}
      }

      return row;
    });

    localUpdateRowsAndPopOver(newUnitCompareRows);
    updateUnitCompareRowPosition(rowA.id, newPositionRowA);
    updateUnitCompareRowPosition(rowB.id, newPositionRowB);
  }

  const handleExportToExcelClick = (tileRows: TileRow[]) => {

    if (tileRows[0].dataRows.length < 1) {
      alert('\r\nDownloaden mislukt.\r\nZorg dat de eerst gekozen afdeling tegels heeft met meetwaarden.');
      return;
    };

    GenerateOutput(tileRows, unitCompareColumns, {
      reportTitle: 'DaaS export',
      showHeaders: true,
      generateCSV: true
    })
  }

  const getSpeechBubbleText = (unitCompareColumns: API_GET.UnitCompareColumn[] = [], tileRows: TileRow[]) => {

    const hasColumns = unitCompareColumns.length > 0;
    const hasRows = tileRows.length > 0;
    let text = null;

    if(!hasColumns && !hasRows && !isLoading) {
      text = [
      'Gebruik de knop met de pen om een afdeling of tegel toe te voegen.',
      ]
    }
    if(hasColumns && !hasRows && !isLoading) {
      text = ['Voeg nu een tegel toe.']
    }
    if(!hasColumns && hasRows && !isLoading) {
      text = ['Voeg nu een afdeling toe.']
    }
    return text;
  }


  const handleDeleteColumnClick = (column: API_GET.UnitCompareColumn) => {
    const { organisationUnit } = column;
    const { code } = organisationUnit;
    const filteredUnitCompareColumns = unitCompareColumns.filter(({ organisationUnit: ou }) => ou.code !== code);

    localUpdateColumnsAndPopOver(filteredUnitCompareColumns);

    syncUnitCompareColumnsState(filteredUnitCompareColumns);
  }

  const handlePinColumnClick = (column: API_GET.UnitCompareColumn, columnIndex: number) => {
    const { pinnedColumnId } = unitCompareSettings as LocalUnitCompareSettings;
    const { id: columnId } = column;

    let settingsClone = {...unitCompareSettings} as LocalUnitCompareSettings;
    let columnsClone = [...unitCompareColumns];

    if(columnId === pinnedColumnId) {
      // remove pinnedColumn
      settingsClone.pinnedColumnId = null;
      columnsClone[columnIndex] = {...columnsClone[columnIndex], isPinned: false };
    } else {
      // set new pinned column
      settingsClone.pinnedColumnId = columnId;
      columnsClone = columnsClone.map((columnClone) => {
        return {
          ...columnClone,
          isPinned: columnClone.id === columnId
        }
      });
    }

    setUnitCompareSettings(settingsClone);
    localUpdateColumnsAndPopOver(columnsClone);

    savePinnedColumn(settingsClone.pinnedColumnId);
  }

  const handleDropColumn = (droppedColumn: API_GET.UnitCompareColumn, droppedOnColumn: API_GET.UnitCompareColumn) => {
    // droppedColumn wil get the position to the left of droppedOnColumn

    const unitCompareColumnsClone = unitCompareColumns
    // set the droppedColumn position equal to droppedOnColumn position
    .map((column) => {
      return {
        ...column,
        position: column.id === droppedColumn.id ? droppedOnColumn.position : column.position
      };
    })
    // sort the columns, and when the positions are equal to eachother, put droppedColumn in front of droppedOnColumn
    .sort((a, b) => {
      if(a.position !== b.position) {
        return a.position - b.position;
      } else {
        // we are comparing droppedColumn and droppedOnColumn
        return a.id === droppedColumn.id ? -1 : 1;
      }
    })
      // map through the sorted columns again and assign the positions equal to index + 1
    .map((column, index) => {
      return {
        ...column,
        position: index + 1
      }
    });

    localUpdateColumnsAndPopOver(unitCompareColumnsClone);
    bulkUpdateUnitCompareColumnPositions(unitCompareColumnsClone);
  }

  // sort columns by order before rendering
  const unitCompareColumnsByOrder = [...unitCompareColumns].sort((a, b) => a.position - b.position);

    // sort rows by order before rendering
  const tileRowsSortedByOrder = [...tileRows].sort((a, b) => a.position - b.position);

  return (
    <StyledUnitCompareContainer isTablet={isTablet}>
      <StyledHeader isMobile={isMobile}>
        <StyledHeaderContent isMobilePhone={isMobile}>
          <StyledHeaderContentSection>
            <BreadCrumbs isMobilePhone={false}
              items={[{ iconName: 'compare_arrows', text: 'Exporteren', path: '/exporteren' }]}
            />
          </StyledHeaderContentSection>
        </StyledHeaderContent>
      </StyledHeader>
      <StyledTile
        hideHeaderDivider={true}
        header={
          <>
            <UnitCompareTileHeader
              isMobile={isMobile}
              tileRows={tileRows}
              onExportToExcelClick={() => handleExportToExcelClick(tileRows)}
            />
            <UnitCompareFab
              isDisabled={isLoading}
              onClearAll={handleClearAll}
              onOpenUnitTreePopOver={handleOpenUnitPopOver}
              onOpenTileTreePopOver={handleOpenTilePopOver}
              speechBubbleText={getSpeechBubbleText(unitCompareColumns, tileRows)}
              unitTreePopOver={
                <UnitTreePopOver
                  isOpen={isUnitPopOverOpen}
                  inputValue={unitInputValue}
                  headerText={'OEs selecteren'}
                  onClose={handleUnitPopOverClose}
                  checkedIds={unitPopOverCheckedIds}
                  expandedIds={unitPopOverExpandedIds}
                  anchorElement={window.document.body}
                  onClear={() => setUnitInputValue('')}
                  onNodeExpandChange={handleUnitPopOverNEC}
                  onInputChange={(value) => setUnitInputValue(value)}
                  onNodeCheckedChange={handleUnitPopOverNodeCheckedChange}
                  onMultipleNodeCheckedChange={handleUnitPopOverMultipleNodeCheckedChange}
                  theme={{headerBackground:  '#e0e0e0'}}
                // anchorElement={fabElementRef.current as HTMLButtonElement}
                />
              }
              tileTreePopOver={
                <TileTreePopOver
                  preLoad={true}
                  maxFilterLevel={2}
                  showDevIds={showDevIds}
                  excludeTile={excludeTile}
                  excludeView={excludeView}
                  isOpen={isTilePopOverOpen}
                  inputValue={tileInputValue}
                  headerText={'Tegels selecteren'}
                  onClose={handleTilePopOverClose}
                  excludeTemplate={excludeTemplate}
                  checkedIds={tilePopOverCheckedIds}
                  expandedIds={tilePopOverExpandedIds}
                  anchorElement={window.document.body}
                  placeholder={'Zoeken op tegel titel'}
                  onClear={() => setTileInputValue('')}
                  onNodeExpandChange={handleTilePopOverNEC}
                  onInputChange={(value) => setTileInputValue(value)}
                  onNodeCheckedChange={handleTilePopOverNodeCheckedChange}
                  theme={{ notExpandableStyle: 'none', headerBackground: '#e0e0e0'}}
                  onMultipleNodeCheckedChange={handleTilePopOverMultipleNodeCheckedChange}
                // anchorElement={fabElementRef.current as HTMLButtonElement}}
                />
              }
            />
          </>
        }
        content={
        <>
          <StyledContentWrapper isTablet={isTablet} innerRef={refTableContainer as RefObject<HTMLDivElement>}>
            <StyledMuiTable stickyHeader={true} innerRef={refTable as RefObject<HTMLTableElement>}>
              <UnitCompareStickyHeader
                isIos={isIos}
                refTable={refTable}
                isEditable={!isLoading}
                scrollAmount={scrollAmount}
                onDropColumn={handleDropColumn}
                columns={unitCompareColumnsByOrder}
                refTableContainer={refTableContainer}
                onPinColumnClick={handlePinColumnClick}
                onDeleteColumnClick={handleDeleteColumnClick}
              />
              <colgroup>
                <col width={`${firstColumnWidth}px`} />
                {unitCompareColumnsByOrder.map((_, index) =>
                  <col key={index} width={`${fixedColumnWidth}px`} />
                )}
                <col />
              </colgroup>
              <MuiTableBody ref={refTableBody as RefObject<HTMLTableElement>}>{
                tileRowsSortedByOrder.length > 0
                  ? tileRowsSortedByOrder.map((row, index) => {
                    const hasPreviousRow = index > 0;
                    const hasNextRow = index < tileRowsSortedByOrder.length - 1;

                    const previousRowId = hasPreviousRow ? tileRowsSortedByOrder[index - 1].id : null;
                    const nextRowId = hasNextRow ? tileRowsSortedByOrder[index + 1].id : null;

                    return (
                      <UnitCompareRow
                        id={row.id}
                        isIos={isIos}
                        refTable={refTable}
                        key={`row-${row.id}`}
                        tileLabel={row.label}
                        viewType={row.viewType}
                        dataRows={row.dataRows}
                        isEditable={!isLoading}
                        themeName={row.themeName}
                        isOddRow={index % 2 === 0}
                        disableMoveDown={!hasNextRow}
                        disableMoveUp={!hasPreviousRow}
                        refContainer={refTableContainer}
                        columns={unitCompareColumnsByOrder}
                        onDeleteClick={(ev) => handleDeleteRowClick(ev, row.id)}
                        onMoveUpClick={(ev) => hasPreviousRow ?
                          handleMoveRowUpClick(ev, previousRowId as number, row.id) :
                          null
                        }
                        onMoveDownClick={(ev) => hasNextRow ?
                          handleMoveRowDownClick(ev, row.id, nextRowId as number) :
                          null
                        }
                      />
                    );
                  })
                  : <StyledNoTileTextRow>
                    <td></td>
                    {/* <StyledNoTileTextCell colSpan={unitCompareColumns.length}>
                      <StyledNoTileTextHolder><MuiKeyboardArrowRightIcon />Voeg een tegel toe</StyledNoTileTextHolder>
                    </StyledNoTileTextCell> */}
                  </StyledNoTileTextRow>
              }
              </MuiTableBody>
            </StyledMuiTable>
          </StyledContentWrapper>
        </>
        }
      />
    </StyledUnitCompareContainer>
  );
};

export default UnitComparePage;