import { TransformFunction, TransformOptionsBase } from '../transform-utils';
import * as allFormatters from '../../../utils/formatters';

interface RD {
  waarde: number;
  label_1: string;
  label_2?: string;
  label_3?: string;
  label_4: string;
  eenheid: string;
}

export interface ColumnChartRemainderRawData {
  [key: string]: RD;
}

export interface ColumnChartRemainderXThreshold {
  label: string;
  columnKey: string;
  columnIndex: number;
}
export interface ColumnChartRemainderYThreshold {
  label: string;
  rawValue: number;
  formattedValue: string;
}


export interface ColumnChartRemainderTransformOptions extends TransformOptionsBase {
  reverse?: boolean;
  /**
   * Set the key to use for the x axis.
   * @default label_1
   */
  xLabelKey?: 'label_1' | 'label_2' | 'label_3';
  yThreshold?: ColumnChartRemainderYThreshold;
  /**
  * Append the threshold value to the xLabel (if there is a threshold).
  * For example xLabel is 'Augustus' and threshold is '2020', xLabel will be Augustus 2020
  * @default true
  */
  appendThresholdToXLabel?: boolean;
}

export interface ColumnChartRemainderModel {
  key: string;
  label: string;
  xLabel: string;
  /**
   * Keys that correspond to the keys in rawValues and formattedValues.
   */
  valueKeys: string[];
  shortXLabel?: string;
  rawValues: { [key: string]: number };
  formattedValues: { [key: string]: string };
}

export interface ColumnChartRemainderDataModel {
  columns: ColumnChartRemainderModel[];
  xThreshold?: ColumnChartRemainderXThreshold[];
  yThreshold?: ColumnChartRemainderYThreshold;
}

const findXThreshold = (columns: ColumnChartRemainderModel[]): ColumnChartRemainderXThreshold[] => {
  let previousLabel = '';
  const xTresholdArr: ColumnChartRemainderXThreshold[] = [];

  columns.forEach((column, index) => {
    const currentLabel = column.valueKeys[0];
    if (currentLabel !== previousLabel && index !== 0) {
      xTresholdArr.push({
        columnIndex: index,
        columnKey: column.key,
        label: currentLabel,
      })
    }

    previousLabel = currentLabel;

  });
  return xTresholdArr;
}

const columnChartRemainderTransformer: TransformFunction<ColumnChartRemainderDataModel, ColumnChartRemainderTransformOptions> = (
  rawData: ColumnChartRemainderRawData,
  onError,
  options
) => {
  const keys = Object.keys(rawData);
  const guard = !keys.some((key) => rawData[key].waarde !== null);

  if (guard) {
    onError('nodata');
    return null;
  }

  const {
    reverse = false,
    formatters = {},
    formattersOptions = {},
    xLabelKey = 'label_2',
    appendThresholdToXLabel = true,
    // TODO: yThreshold mag geen default waarde hebben
    yThreshold = { label: 'norm', rawValue: 0.0, formattedValue: '0.0' }
  } = options || {} as ColumnChartRemainderTransformOptions;

  const valueFormatterName = formatters['value'];
  const valueFormatter = (allFormatters as any)[valueFormatterName];
  const valueFormatterOptions = formattersOptions['value'];

  const xLabelFormatterName: string = formatters['xLabel'];
  const xLabelFormatter = (allFormatters as any)[xLabelFormatterName];
  const xLabelFormatterOptions = formattersOptions['xLabel'];

  const labelFormatterName: string = formatters['label'];
  const labelFormatter = (allFormatters as any)[labelFormatterName];
  const labelFormatterOptions = formattersOptions['label'];

  const shortXLabelFormatterName: string = formatters['shortXLabel'];
  const shortXLabelFormatter = (allFormatters as any)[shortXLabelFormatterName];
  const shortXFormatterOptions = formattersOptions['shortXLabel'];

  let columnModels: ColumnChartRemainderModel[] = [];

  keys.forEach((rawDataKey: string, index: number) => {
    const rawDataItem = rawData[rawDataKey];
    const { label_1, label_4, waarde, eenheid } = rawDataItem;

    let xLabel = xLabelFormatter ? xLabelFormatter(rawDataItem[xLabelKey] as string, xLabelFormatterOptions) : rawDataItem[xLabelKey] as string;
    if (!!label_1 && appendThresholdToXLabel) {
      xLabel = `${xLabel} ${label_1}`;
    }

    columnModels.push({
      xLabel: xLabel,
      key: `${rawDataItem[xLabelKey]}-${index}`,
      label: labelFormatter ? labelFormatter(label_4, labelFormatterOptions) : label_4,
      shortXLabel: xLabelFormatter ? shortXLabelFormatter(rawDataItem[xLabelKey] as string, shortXFormatterOptions) : rawDataItem[xLabelKey] as string,
      valueKeys: [label_1/*, yThresholdKey*/],
      rawValues: {
        [label_1]: waarde,
        // [yThresholdKey]: yThresholdValue
      },
      formattedValues: {
        [label_1]: valueFormatter ?
          valueFormatter(waarde, valueFormatterOptions) :
          allFormatters.formatValue(waarde, eenheid as ValueUnit, valueFormatterOptions),
        // [yThresholdKey]: valueFormatter ?
        //   valueFormatter(yThresholdValue, valueFormatterOptions) :
        //   allFormatters.formatValue(yThresholdValue, eenheid as ValueUnit, valueFormatterOptions)
      }
    });
  });

  columnModels = reverse ? columnModels.reverse() : columnModels;

  let xThreshold = findXThreshold(columnModels);

  return { columns: columnModels, xThreshold, yThreshold };
};

export default columnChartRemainderTransformer;