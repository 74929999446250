import { Reducer } from 'react';
import { useEffect, useReducer } from 'react';

import { httpGetTileData } from '../../../utils/http';
import { transform, TransformErrorType, TransformFunction } from '../../content/transform-utils';

interface State<T> {
  rawData: any;
  isLoading: boolean;
  transformedData: T | null;
  error: TransformErrorType | null;
}

type Action<T> =
  { type: 'loading' } |
  { type: 'raw', payload: any } |
  { type: 'error', payload: TransformErrorType | null } |
  { type: 'transformed', payload: { rawData: any, transformedData: T | null } };

const reducer = <T>(state: State<T>, action: Action<T>) => {
  switch (action.type) {
    case 'loading': {

      if (state.isLoading === true) {
        // No need to set if we are already loading
        return state;
      }

      return { ...state, isLoading: true };
    }
    case 'raw': {
      return { ...state, rawData: action.payload };
    }
    case 'transformed': {
      return { ...state, isLoading: false, error: null, rawData: action.payload.rawData, transformedData: action.payload.transformedData };
    }
    case 'error': {
      return { ...state, isLoading: false, error: action.payload, rawData: null, transformedData: null };
    }
    default: {
      return state;
    }
  }
};

/**
 * The dataManager hook:
 * - Triggers when any of its parameters changes.
 * - Fetches the rawData with oeCode, dataKey and dataUrl.
 * - Transforms the rawData to transformedData with the transformFunction and transformOptions.
 * - Uses a reducer to:
 *  - Keep track of loading state
 *  - Keep track of raw and transformed data state
 *  - Keep track of error state
 */
const useDataManager = <TransformedDataType, TransformOptionsType>(
  oeCode: string,
  dataKey: string,
  dataUrl: string,
  useMockData: boolean,
  transformFunction: TransformFunction<TransformedDataType, TransformOptionsType>,
  transformOptions?: TransformOptionsType
) => {

  const [state, dispatch] = useReducer<Reducer<State<TransformedDataType>, Action<TransformedDataType>>>(reducer, {
    error: null,
    rawData: null,
    isLoading: true,
    transformedData: null
  });

  useEffect(() => {
    let timeoutId: number;

    const getData = async () => {
      dispatch({ type: 'loading' });

      let rawData: any = null;

      try {
        if (dataKey === 'blank') {
          // TODO: moet dit op een andere plek?
          rawData = {};
        }
        else {
          const keys = dataKey.split(',');
          if (keys.length === 1) {
            rawData = await httpGetTileData(dataUrl, oeCode, dataKey, useMockData);
          } else if (keys.length > 1) {
            // execute fetching parallel
            const promises = keys.map((key) => httpGetTileData(dataUrl, oeCode, key, useMockData));
            const allResults = await Promise.all(promises);
            rawData = allResults;
          }
        }
      } catch (error) {
        rawData = null;
        dispatch({ type: 'error', payload: error });
      }

      if (!!rawData) {
        const transformedData = transform(
          transformFunction,
          (error) => dispatch({ type: 'error', payload: error }),
          rawData,
          transformOptions
        );

        if (!!transformedData) {
          timeoutId = setTimeout(() => {
            dispatch({ type: 'transformed', payload: { rawData, transformedData } });
          }, 300);
        }
      } else {
        dispatch({ type: 'error', payload: 'nodata' })
      };
    }

    // if (dataUrl && oeCode && dataKey) {
    getData();
    // }

    return () => clearTimeout(timeoutId);
  }, [oeCode, dataKey, dataUrl, useMockData, transformFunction, transformOptions]);

  return { ...state };
};

export default useDataManager;