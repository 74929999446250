import { Tooltip } from 'ppd-library/charts/organisms/Tooltip';
import { scales } from 'ppd-library/charts/chartUtils';
import { AxisBottom } from 'ppd-library/charts/organisms/Axis';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import Chart from 'ppd-library/charts/Chart';
import { HelpTooltipButton } from '../../help/HelpTooltipButton';
import { ComponentBaseProps } from '../ComponentBaseProps';
import { DistributionChartBarDataModel, DistributionChartItem } from './distributionChartTransformer';
import * as allFormatters from '../../../utils/formatters';
import { BarHorizontal } from 'ppd-library/charts/molecules/BarHorizontal';

import { getSVGMousePosition, useTooltipPosition, TooltipTemplate } from '../tooltip-utils';
import muiTheme from '../../../constants/theme';
import { useLegend, Legend } from '../legend-utils';

export interface StackedColorProp {
  [key: string]: string;
}
export interface StackedValuesProp {
  [key: string]: string;
}
export interface BarColorsProp {
  [key: string]: string;
}
export interface DistributionChartThemeProps {
  leftBarColor?: string;
  rightBarColor?: string;
}
export interface DistributionChartProps extends ComponentBaseProps<DistributionChartThemeProps, DistributionChartBarDataModel> {
  showTooltip?: boolean;
  barsPadding?: number;
  leftXAxis?: { minValue: number; maxValue: number; };
  rightXAxis?: { minValue: number; maxValue: number; };
}
const StyledWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const StyledChartHolder = styled.div``;

const StyledValue = styled.text`
  font-size: 0.8rem;
  fill: ${muiTheme.palette.primary.main};
  transition: transform 1000ms, opacity 1200ms 600ms;
`;

const DistributionChart = (props: DistributionChartProps) => {
  const {
    id,
    data,
    help,
    leftXAxis,
    rightXAxis,
    tooltipOptions,
    width = 500,
    formatters = {},
    showHelp = false,
    barsPadding = 0.1,
    legendOptions = {},
    showTooltip = true,
    themeProperties,
    formattersOptions = {},
    height: originalHeight = 450,
  } = props;
  const { margins = { left: 0, right: 0, top: 0, bottom: 20 } } = props;

  const {
    leftBarColor = 'gray',
    rightBarColor = muiTheme.palette.primary.main,
  } = themeProperties || {} as DistributionChartThemeProps;

  const { groupsModel } = data;
  const ticks = groupsModel.map((group) => group.groupLabel);

  const xScaleFormatterName = formatters['xScaleTick'];
  const xScaleFormatter = (allFormatters as any)[xScaleFormatterName];
  const xScaleFormatterOptions = formattersOptions['xScaleTick'];

  const getMaxValueFromData = (groupsModel: DistributionChartItem[]): number => {
    const rawPrimaryValues = groupsModel.map(({ primaryValue }) => primaryValue.rawValue);
    const rawSecondaryValues = groupsModel.map(({ secondaryValue }) => secondaryValue.rawValue);
    const maximumValue =  Math.max(...rawPrimaryValues, ...rawSecondaryValues);

    return maximumValue;
 }

 const colorsObj = {
    [groupsModel[0] ? groupsModel[0].primaryValue.label : 'left']: leftBarColor,
    [groupsModel[0] ? groupsModel[0].secondaryValue.label : 'right']: rightBarColor
  };

  const maximumValue = getMaxValueFromData(groupsModel);

  const valueLabelwidth = 60;
  const categoryLabelWidth = 68;
  const leftSideWidth = ((width / 100) * 50) - (categoryLabelWidth / 2);
  const rightSideWidth = ((width / 100) * 50) - (categoryLabelWidth / 2);

  const xScale = (reversed: boolean = false) => {
    const maxValue = leftXAxis ? leftXAxis.maxValue : maximumValue;
    return scales.numericXScale(reversed ? maxValue : 0, reversed ? 0 : maxValue, leftSideWidth - valueLabelwidth, 0, margins.left).nice();
  }

  const xScaleLeft = xScale();
  const xScaleLeftReversed = xScale(true);
  const maxValue = Math.ceil(maximumValue);

  const xScaleRight = scales.numericXScale(0, rightXAxis ? rightXAxis.maxValue : maxValue, rightSideWidth - valueLabelwidth, 0, margins.left).nice();

  const legendElementRef = React.createRef<HTMLDivElement>();
  const { legendHeight } = useLegend(originalHeight, legendElementRef);
  const height = originalHeight - legendHeight;

  const yScale = scales.stringYScale(ticks, height, margins.top, margins.bottom, barsPadding);
  const colorScale = scales.colorScale(colorsObj);

  let tooltipLeaveTimeoutId = 0;
  const handleMouseMove = (event: React.MouseEvent<Element, MouseEvent>, index: number, data: DistributionChartItem) => {
    clearTimeout(tooltipLeaveTimeoutId);
    const { x, y } = getSVGMousePosition(event as React.MouseEvent<SVGElement, MouseEvent>);
    setTooltipPosition({ visible: true, x, y, index, data });
  };

  const handleMouseLeave = () => {
    clearTimeout(tooltipLeaveTimeoutId);
    tooltipLeaveTimeoutId = setTimeout(() => {
      setTooltipPosition({ ...tooltipPosition, visible: false });
    }, 250);
  };

  const { tooltipPosition, setTooltipPosition } = useTooltipPosition<DistributionChartItem>();
  const [initiated, setInitiated] = useState(false);

  useEffect(() => {
    setInitiated(true);
  }, []);


  return (
    <StyledWrapper>
      <StyledChartHolder>
        <Chart height={height} width={width}>

          {groupsModel.map((groupData: DistributionChartItem, groupindex: number) => {
            const { key, groupLabel, primaryValue, secondaryValue } = groupData;
            const { rawValue: primaryRawValue, formattedValue: primaryFormattedValue, label: primaryLabel } = primaryValue;
            const { rawValue: secondayRawValue, formattedValue: secondaryFormattedValue, label: secondaryLabel } = secondaryValue;
            const textYPosition: number = yScale(groupLabel) as number + (yScale.bandwidth() / 1.5);

            return (
              <g key={key}
                onMouseMove={(ev) => handleMouseMove(ev, groupindex, groupData)}
                onMouseLeave={handleMouseLeave}
              >
                <StyledValue
                  transform={`translate(${initiated ? leftSideWidth - xScaleLeft(primaryRawValue) - 24 : leftSideWidth}, ${textYPosition})`}
                  textAnchor={'middle'}
                  opacity={initiated ? 1 : 0}
                >
                  {primaryFormattedValue}
                </StyledValue>
                <StyledValue
                  transform={`translate(${initiated ? (leftSideWidth + categoryLabelWidth) + xScaleRight(secondayRawValue) + 8 : rightSideWidth} , ${textYPosition})`}
                  opacity={initiated ? 1 : 0}
                >
                  {secondaryFormattedValue}
                </StyledValue>
                <text
                  transform={`translate(${leftSideWidth + categoryLabelWidth / 2}, ${textYPosition})`}
                  textAnchor={'middle'}>{groupLabel}
                </text>
                <g
                  transform={`translate(${leftSideWidth}, 0)`}
                >
                  <BarHorizontal
                    key={`${id}-${key}`}
                    fill={colorScale(primaryLabel)}
                    width={initiated ? xScaleLeft(primaryRawValue) : 0}
                    x={initiated ? -xScaleLeft(primaryRawValue) : 0}
                    y={yScale(groupLabel) as number}
                    id={`${id}-bar-vertical-${groupindex}`}
                    height={yScale.bandwidth()}
                  />
                </g>
                <g
                  transform={`translate(${leftSideWidth + categoryLabelWidth}, 0)`}>
                  <BarHorizontal
                    key={`${id}-${key}`}
                    fill={colorScale(secondaryLabel)}
                    width={initiated ? xScaleRight(secondayRawValue) : 0}
                    x={0}
                    y={yScale(groupLabel) as number}
                    id={`${id}-bar-vertical-${groupindex}`}
                    height={yScale.bandwidth()}
                  />
                </g>
              </g>
            )
          }
          )}

          <g transform={`translate(${valueLabelwidth}, ${-(margins.bottom + 20)})`}>
            <AxisBottom
              scale={xScaleLeftReversed}
              id={`${id}-axis-bottom-left`}
              y={0}
              chartHeight={height}
              numberOfTicks={rightXAxis ? undefined : maxValue > 5 ? maxValue / 2 : maxValue }
              tickFormat={(value) => {
                const isWholeNumber = (value as number) % 1 === 0;
                if (!!xScaleFormatter) {
                  return isWholeNumber ? value === 0 ? 0 : xScaleFormatter(value, xScaleFormatterOptions) : '';
                }
                return isWholeNumber ? value : '';
              }}
              theme={{ colors: { tick: '#909090' } }}
            />
          </g>

          <g transform={`translate(${leftSideWidth + categoryLabelWidth},  ${-(margins.bottom + 20)})`}>
            <AxisBottom
              scale={xScaleRight}
              id={`${id}-axis-bottom-right`}
              y={0}
              chartHeight={height}
              numberOfTicks={rightXAxis ? undefined : maxValue > 5 ? maxValue / 2 : maxValue }
              tickFormat={(value) => {
                if (!!xScaleFormatter) {
                  return value === 0 ? 0 : xScaleFormatter(value, xScaleFormatterOptions);
                }
                return value;
              }}
              theme={{ colors: { tick: '#909090' } }}
            />
          </g>

        </Chart>
        <Tooltip
          top={tooltipPosition.y}
          left={tooltipPosition.x}
          visible={showTooltip && tooltipPosition.visible}
          theme={{
            transition: false
          }}
        >
          {() => {
            const {
              groupLabel,
              primaryValue,
              secondaryValue
            } = tooltipPosition.data as DistributionChartItem;
            return (
              <TooltipTemplate
                title={groupLabel}
                tooltipOptions={tooltipOptions}
                values={[{
                  label: primaryValue.label,
                  formattedValue: primaryValue.formattedValue,
                  colors: [colorScale(primaryValue.label)]
                },
                {
                  label: secondaryValue.label,
                  formattedValue: secondaryValue.formattedValue,
                  colors: [colorScale(secondaryValue.label)]
                }]}

              />
            );
          }}
        </Tooltip>

        <Legend
          ref={legendElementRef}
          legendOptions={{ orientation: 'horizontal', ...legendOptions }}
          values={[
            {
              label: groupsModel[0].primaryValue.label,
              colors: [colorScale(groupsModel[0].primaryValue.label)]
            },
            {
              label: groupsModel[0].secondaryValue.label,
              colors: [colorScale(groupsModel[0].secondaryValue.label)]
            }
          ]}
          afterLegendContent={
            <>
              {!!showHelp && !!help && help.some(({ position }) => position === 99) &&
                <HelpTooltipButton
                  anchorType={'iconButton'}
                  helpId={(help.find(({ position }) => position === 99) as { helpId: number }).helpId}
                />

              }
            </>
          }
        />
      </StyledChartHolder>
    </StyledWrapper>
  )
};

export default DistributionChart;