import MuiTable from '@material-ui/core/Table';
import MuiTableBody from '@material-ui/core/TableBody';
import MuiTableCell, { TableCellProps as MuiTableCellProps } from '@material-ui/core/TableCell';
import MuiTableHead from '@material-ui/core/TableHead';
import MuiTableRow from '@material-ui/core/TableRow';
import React from 'react';
import styled from 'styled-components';
import { pxToRem } from '../../../utils/converters';

import muiTheme from '../../../constants/theme';
import { ComponentBaseProps } from '../ComponentBaseProps';
import { TableLeveledCellData, TableLeveledDataModel } from './tableLeveledTransformer';
import { HelpTooltipButton } from '../../help/HelpTooltipButton';

export interface TableLeveledThemeProps {
  /**
   * If true, the last row of the table will be styled as a total of the rows above.
   * @default true
   */
  showTotalRow?: boolean;
}
export interface TableLeveledProps extends ComponentBaseProps<TableLeveledThemeProps, TableLeveledDataModel> {

}

const StyledWrapper = styled.div`
  flex: 1;
  height: 100%;
  overflow-x: hidden;
  position: relative;
` as React.ComponentType<React.HTMLProps<HTMLDivElement>>;

const StyledMuiTableHeader = styled(MuiTableCell)`
  && {
    /* white-space: nowrap; */
  }
` as React.ComponentType<MuiTableCellProps>;

// TODO: util functie?
const isNumericColumn = (rows: TableLeveledCellData[][], colIndex: number) => {
  return rows.some((row) => row[colIndex] && !isNaN(row[colIndex].rawValue as number));
}

const LeveledTableCellValue = styled(({ level, ...spanProps }) => <span {...spanProps} />)`
  font-weight: ${({ level }) => level === 1 ? 900 : null}; /* NOTE!: 900 arial but 500 roboto*/
  
  padding-left: ${({ level }) => level > 1 ? `${pxToRem(muiTheme.spacing(level - 1) * 1.5)}` : null};
` as React.ComponentType<{ level: number; } & React.HTMLProps<HTMLSpanElement>>;

const StyledMuiTableCell = styled(({ isTotalRow, ...muiTableCellProps }) => <MuiTableCell {...muiTableCellProps} />)`
  && {
    border-top: ${({ isTotalRow }) => isTotalRow ? `${pxToRem(3)} solid ${muiTheme.palette.secondary.light}` : null};
    border-bottom: ${({ isTotalRow }) => isTotalRow ? 0 : null};
  }
` as React.ComponentType<{ isTotalRow: boolean; } & MuiTableCellProps>;

const TableLeveled = (props: TableLeveledProps) => {
  const { /*width, height,*/
    help,
    data,
    showHelp,
    themeProperties,
  } = props;
  const { rows } = data;
  const { showTotalRow = true } = themeProperties || {} as TableLeveledThemeProps;
  const headers = rows[0] ? rows[0].columns.map(({ label }) => label) : [];

  let rowHelpPosition = 0;

  return (
    <StyledWrapper>
      <MuiTable style={{ marginTop: showHelp ? '32px' : undefined }} size={'small'}>
        {headers.length > 0 && headers.some((header) => !!header) &&
          <MuiTableHead>
            <MuiTableRow>
              {
                headers.map((header, i) => {
                  const allColumns = rows.map(({ columns }) => columns);
                  const align = isNumericColumn(allColumns, i) ? 'right' : 'inherit';
                  return (
                    <StyledMuiTableHeader align={align} key={`${header}-${i}`}>{header}</StyledMuiTableHeader>
                  );
                })
              }
            </MuiTableRow>
          </MuiTableHead>
        }
        <MuiTableBody>
          {
            rows.map((row, i) => (
              <MuiTableRow key={`row-${i}`}>
                {row.columns.map(({ label, rawValue, formattedValue }, j) => {
                  const isLastRow = rows.length - 1 === i;

                  if (row.level === 1 && j === 0) {
                    rowHelpPosition++;
                  }

                  // show help when:
                  // - showHelp
                  // - and there is help tekst 
                  // - and we are in the first column
                  // - and rowLevel === 1 
                  // - and there is help with the same helpPosition as the current row
                  const showHelpTooltipButton = showHelp && !!help && help.length > 0 && j === 0 && row.level === 1 && help.some(({ position }) => position === rowHelpPosition);
                  const helpItem = !!help ? help.find(({ position }) => position === rowHelpPosition) : null;

                  return (
                    <StyledMuiTableCell
                      isTotalRow={showTotalRow && isLastRow}
                      align={!isNaN(rawValue as number) ? 'right' : 'inherit'} key={`${label || j}-${i}`}
                    >
                      <LeveledTableCellValue level={row.level}>{formattedValue}</LeveledTableCellValue>

                      {showHelpTooltipButton &&
                        <HelpTooltipButton
                          anchorType={'iconButton'}
                          placement={'bottom-start'}
                          helpId={!!helpItem ? helpItem.helpId : -1}
                        />
                      }
                    </StyledMuiTableCell>
                  );
                })}
              </MuiTableRow>
            ))
          }
        </MuiTableBody>
      </MuiTable>
    </StyledWrapper>
  )
}

export default TableLeveled;