import React, { useState, useEffect } from 'react';

import { CircleAnimated } from 'ppd-library/charts/molecules/CircleAnimated';
import Chart from 'ppd-library/charts/Chart';
import { Label } from 'ppd-library/charts/atoms/Label';
import { Line } from 'ppd-library/charts//atoms/Line';
import { HelpTooltipButton, HelpTooltipButtonProps } from '../../help/HelpTooltipButton';

import styled from 'styled-components';
import { ComponentBaseProps } from '../ComponentBaseProps';
import { TimeLineDumbbellDataModel, TimeLineDumbbellChartDataModel } from './timeLineDumbbellTransformer';
import muiTheme from '../../../constants/theme';


export interface StackedColorProp {
  [key: string]: string;
}
export interface StackedValuesProp {
  [key: string]: string;
}

export interface TimeLineDumbbellThemeProps {
  lineColor?: string;
  circleFill?: string;
  circleFontColor?: string;
  circleFontSize?: string;
  labelFill?: string;
  labelTextColor?: string;
  labelStrokeColor?: string;
  labelFontSize?: string;
  labelPadding?: number | number[];
}
export interface TimeLineDumbbellProps extends ComponentBaseProps<TimeLineDumbbellThemeProps, TimeLineDumbbellChartDataModel> {
  showTooltip?: boolean;
  showThreshold?: boolean;
  circleRadius?: number;
  timeLabelHeight?: number;
  maxTimeLength?: number;
  dumbbellValuePrefix?: string;
  animate?: boolean;
}

const StyledWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const StyledChartHolder = styled.div``;

const StyledHelpTooltipButton = styled(({ top, left, ...helpTooltipButtonProps }) => <HelpTooltipButton {...helpTooltipButtonProps} />)`
  && { 
    position: absolute;
    left: ${({ left }) => left}px;
    margin-top: ${({ top }) => top}px;
  }
`as React.ComponentType<{ top: number; left: number; } & HelpTooltipButtonProps>;



// TODO: gebruik een yScale?
// tel alle getallen bij elkaar op, dan heb je de maxdomain die je kunt verdelen over de range
// wel rekening houden met circlediameter
const calculateYPositions = (circleRadius: number, model: TimeLineDumbbellDataModel[], availableSpace: number, timeLabelHeight: number, maxTimeLength: number): { circle: number[], label: number[] } => {
  const circleDiameter = circleRadius * 2;
  const rawValues: number[] = [];
  const circleYValues: number[] = [];
  const labelYValues: number[] = [];

  // Set the maximum time value/step in the time label
  model.forEach((item: TimeLineDumbbellDataModel) => {
    const { secondaryValues } = item;
    let value = secondaryValues.rawValue;

    if (value > maxTimeLength) {
      value = maxTimeLength;
    }

    if (value != null) rawValues.push(value);
  });

  // Sum of all raw values
  const totalRawValue: number = rawValues.reduce((pre: number, cur: number) => pre + cur, 0);

  // One vertical pixels unit between 2 circles
  const verticalStepValue: number = availableSpace / totalRawValue;

  // Array with vertical step sizes
  const valuesArr: number[] = rawValues.map((value: number) => value * verticalStepValue)

  // Calculates the distance between circles
  valuesArr.reduce((pre: number, cur: number, index: number) => {
    circleYValues.push((pre + cur) + (circleDiameter * (index + 1)));
    return pre + cur;
  }, 0);

  // Calculates the distance between time labels
  valuesArr.reduce((pre: number, cur: number, index: number) => {
    labelYValues.push((pre + cur) + (circleDiameter * (index + 1)) - ((valuesArr[index] / 2) + (timeLabelHeight / 2)));
    return pre + cur;
  }, 0);

  return ({
    circle: circleYValues,
    label: labelYValues
  }
  );
}

const TimeLineDumbbell = (props: TimeLineDumbbellProps) => {
  const {
    id,
    data,
    help,
    showHelp,
    width = 500,
    height = 450,
    animate = false,
    timeLabelHeight = 14,
    maxTimeLength = 4,
    dumbbellValuePrefix = '',
    themeProperties
  } = props;

  let { circleRadius = 20 } = props;

  const { margins = { left: 20, right: 0, top: 0, bottom: 0 } } = props;
  const {
    lineColor = 'gray',
    circleFill = '#2b4899',
    circleFontColor = '#fff',
    circleFontSize = '0.8rem',
    labelFill = '#fff',
    labelTextColor = 'gray',
    labelStrokeColor = 'gray',
    labelFontSize = '0.8rem',
    labelPadding = [2, 4, 2, 4]

  } = themeProperties || {} as TimeLineDumbbellThemeProps;

  const { chartModel } = data;
  const itemCount = chartModel.length;

  const [initiated, setInitiated] = useState(false);

  useEffect(() => {
    setInitiated(true);
  }, []);

  circleRadius = animate ? initiated ? circleRadius : 0 : circleRadius;

  // Remaining vertical space (pixels) to use for timelabel placement.
  const availableSpace: number = height - (itemCount * circleRadius * 2);

  const yPositions = calculateYPositions(circleRadius, chartModel, availableSpace, timeLabelHeight, maxTimeLength);

  return (
    <StyledWrapper>
      <StyledChartHolder>
        {!!showHelp && !!help && help.some(({ position }) => position === 1) &&
          <StyledHelpTooltipButton
            top={7}
            left={-10}
            anchorType={'iconButton'}
            placement={'right-start'}
            helpId={(help.find(({ position }) => position === 1) as { helpId: number }).helpId}
          />
        }
        {!!showHelp && !!help && help.some(({ position }) => position === 2) &&
          <StyledHelpTooltipButton
            top={yPositions.label[0] - 7}
            left={-10}
            anchorType={'iconButton'}
            placement={'right-start'}
            helpId={(help.find(({ position }) => position === 2) as { helpId: number }).helpId}
          />
        }
        <Chart height={height} width={width}>
          <g transform={`translate(${margins.left}, 0)`}>
            <Line
              width={1}
              color={lineColor}
              id={`${id}-line-time-linedumbbell`}
              to={{ x: circleRadius, y: height }}
              from={{ x: circleRadius, y: margins.top }}
            />
          </g>
          <g transform={`translate(${margins.left}, 0)`} id={`${id}-line-vertical-time-linedumbbell`}>
            {
              chartModel.map((item: TimeLineDumbbellDataModel, index: number) => {
                const { primaryValues } = item;
                const { key, formattedValue } = primaryValues;
                const circleYPos = index === 0 ? 0 : yPositions.circle[index - 1];

                return (
                  <g key={key}>
                    <CircleAnimated
                      strokeWidth={0}
                      x={circleRadius}
                      animationSpeed={500}
                      radius={circleRadius}
                      textSize={circleFontSize}
                      textColor={circleFontColor}
                      backgroundColor={circleFill}
                      id={`${id}-circle-time-linedumbbell-${key}`}
                      y={initiated ? (circleYPos + circleRadius) : 0}
                      text={`${dumbbellValuePrefix}${formattedValue}`}
                    />
                    <Label
                      padding={0}
                      fill={'#fff'}
                      animate={true}
                      x={circleRadius * 2.5}
                      text={primaryValues.label}
                      color={muiTheme.palette.secondary.main}
                      y={initiated ? (circleYPos + circleRadius / 2) : 0}
                    />
                  </g>
                );
              })
            }
            <CircleAnimated
              strokeWidth={0}
              x={circleRadius}
              animationSpeed={500}
              radius={circleRadius / 3}
              textSize={circleFontSize}
              textColor={circleFontColor}
              backgroundColor={circleFill}
              id={`${id}-circle-time-linedumbbell-final`}
              y={initiated ? (height - circleRadius / 3) : 0}
            />
          </g>
          <g transform={`translate(0, 0)`} id={`${id}-time-linedumbbell-timelabels`}>
            {
              chartModel.map((item: TimeLineDumbbellDataModel, index: number) => {
                const { primaryValues, secondaryValues } = item;
                const { key } = primaryValues;
                const labelYPos = (chartModel.length - 1) === index ? 0 : yPositions.label[index];
                return index < (chartModel.length - 1) ? (
                  <Label
                    x={4} /* TODO: calculate x position by label width */
                    key={key}
                    yText={13} // TODO: dit veranderd als font-size veranderd
                    y={labelYPos}
                    animate={true}
                    strokeWidth={1}
                    fill={labelFill}
                    color={labelTextColor}
                    padding={labelPadding}
                    fontSize={labelFontSize}
                    dominantBaseLine={'auto'} // <- NO IE
                    strokeColor={labelStrokeColor}
                    text={`${secondaryValues.formattedValue} ${secondaryValues.label}`}
                  />
                ) : null;
              })
            }
          </g>
        </Chart>
      </StyledChartHolder>
    </StyledWrapper>
  )
};

export default TimeLineDumbbell;
