import MuiButton from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MuiSnackbar, { SnackbarProps as MuiSnackbarProps } from '@material-ui/core/Snackbar';
import CheckCircleOutlined from '@material-ui/icons/CheckCircleOutlined';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import WarningIcon from '@material-ui/icons/ReportProblemOutlined';
import React, { useRef } from 'react';
import styled from 'styled-components';

export type Severity = 'error' | 'warning' | 'info' | 'success';

export interface NotificationBarProps {
  open: boolean;
  message?: string | null;
  severity?: Severity;
  confirm?: boolean;
  confirmCancelButtonText?: string;
  confirmApproveButtonText?: string;
  confirmCallback?:() => void;
  onClose?: () => void;
}
// export type severityValue = {
//   [key in keyof Severity]: string
// }

export interface SeverityValues {
  [key: string]: {
    color: string;
    icon: JSX.Element
  }
}

const severityValues: SeverityValues = {
  error: { color: '#E44D4D', icon: <WarningIcon fontSize='small' /> }, // red 300
  warning: { color: '#D99100', icon: <InfoIcon fontSize='small' /> }, // amber 700
  info: { color: '#0072D6', icon: <InfoIcon fontSize='small' /> }, // lightBlue main
  success: { color: '#008000', icon: <CheckCircleOutlined fontSize='small' /> }// green main
}

const StyledMuiSnackbar = styled(({ color, ...snackbarProps }) => <MuiSnackbar {...snackbarProps} />)`
  &&& {
    z-index: 1500;
    .MuiSnackbarContent-root {
      background-color: ${({ color }) => color};
    }
  }
` as React.ComponentType<{ color: string; } & MuiSnackbarProps>;

const StyledMessageHolder = styled.div`
  && {
    display: flex;
    align-items: center;
  }
`;
const StyledParagraph = styled.div`
  && {
    margin: 0;
    padding: 0 1rem;
  }
`;

const StyledCancelButton = styled(MuiButton)`
  && {
    color: #fff;
    margin-right: 20px;
    &:hover {
      background-color: transparent;
      // color: #fff;
    }
  }
`;
const StyledApproveButton = styled(MuiButton)`
  && {
    background-color: #2b4899;
    color: #fff;
    border: none;
    &:hover {
      background-color: #2b4899;
    }
  }
`;

const NotificationBar = (props: NotificationBarProps) => {
  const {
    open,
    message,
    severity = 'info',
    confirm = false,
    confirmCancelButtonText = 'Annuleren',
    confirmApproveButtonText = 'ok',
    confirmCallback = () => {},
    onClose = () => {}
  } = props;


  const values = useRef({ severity, message, confirm, confirmCancelButtonText, confirmApproveButtonText });

  if (open) values.current = { severity, message, confirm, confirmCancelButtonText, confirmApproveButtonText };

  const state = values.current;

  return (
    <StyledMuiSnackbar
      color={severityValues[state.severity].color}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={open}
      // autoHideDuration={8000}
      message={
        <StyledMessageHolder>
          {severityValues[state.severity].icon}
          <StyledParagraph dangerouslySetInnerHTML={{ __html: state.message } as any} />
        </StyledMessageHolder>}
      action={<>
        {!state.confirm &&
          <IconButton size='small' aria-label='close' color='inherit' onClick={onClose}>
            <CloseIcon fontSize='small' />
          </IconButton>
        }
        {!!state.confirm && <>
          <StyledCancelButton color={'primary'} onClick={onClose}>
            {state.confirmCancelButtonText}
          </StyledCancelButton>
          <StyledApproveButton variant={'outlined'} onClick={confirmCallback}>
            {state.confirmApproveButtonText}
          </StyledApproveButton>
        </>
        }
      </>
      }
    />
  );
};

export default NotificationBar;