import { TransformFunction, padLeftWithZeros, calcNumberOfRows } from '../transform-utils';
import * as allFormatters from '../../../utils/formatters';
import { ColumnChartDeviationGroupXThreshold, ColumnChartDeviationGroupModel, ColumnChartDeviationTransformOptions, ColumnChartDeviationDataModel } from './columnChartDeviationListTransformer';
interface RD {
  waarde: number;
  label_1: string;
  label_2?: string;
  label_3?: string;
  label_4: string;
  eenheid: string;
}

export interface ColumnChartDeviationGroupRawData {
  [key: string]: RD;
}

export interface ColumnChartDeviationGroupTransformOptions extends ColumnChartDeviationTransformOptions {
  /**
   * Set the key to use as label in the mouse-over
   * @default label_4
   */
  valueLabelKey?: 'label_1' | 'label_2' | 'label_3' | 'label_4';
  /**
 * Append the threshold value to the xLabel (if there is a threshold).
 * For example xLabel is 'Augustus' and threshold is '2020', xLabel will be Augustus 2020
 * @default true
 */
  appendThresholdToXLabel?: boolean;
}

const findXThreshold = (xThresholdKeys: string[], groupModels: ColumnChartDeviationGroupModel[]) => {

  let xThreshold: ColumnChartDeviationGroupXThreshold | undefined = undefined;
  let currentThresholdLabel = '';
  xThresholdKeys.forEach((xThresholdKey, index) => {

    if (currentThresholdLabel !== xThresholdKey) {
      currentThresholdLabel = xThresholdKey;
      xThreshold = {
        groupIndex: index,
        groupKey: groupModels[index].key,
        label: currentThresholdLabel
      };
    }
  });

  return xThreshold;
};

const columnChartDeviationGroupTransformer: TransformFunction<ColumnChartDeviationDataModel, ColumnChartDeviationGroupTransformOptions> = (
  rawData: ColumnChartDeviationGroupRawData,
  onError,
  options
) => {

  const keys = Object.keys(rawData);
  const guard = !keys.some((key) => rawData[key].waarde !== null);

  if (guard) {
    onError('nodata');
    return null;
  }

  const {
    reverse = false,
    formatters = {},
    formattersOptions = {},
    xLabelKey = 'label_2',
    valueLabelKey = 'label_4',
    appendThresholdToXLabel = true
  } = options || {} as ColumnChartDeviationGroupTransformOptions;

  const valueFormatterName = formatters['value'];
  const valueFormatter = (allFormatters as any)[valueFormatterName];
  const valueFormatterOptions = formattersOptions['value'];

  const xLabelFormatterName: string = formatters['xLabel'];
  const xLabelFormatter = (allFormatters as any)[xLabelFormatterName];
  const xLabelFormatterOptions = formattersOptions['xLabel'];

  const shortXLabelFormatterName: string = formatters['shortXLabel'];
  const shortXLabelFormatter = (allFormatters as any)[shortXLabelFormatterName];
  const shortXFormatterOptions = formattersOptions['shortXLabel'];

  const numberOfRows = calcNumberOfRows(keys);
  // currently always 2 columns
  const numberOfColumns = 2;
  let groupModels: ColumnChartDeviationGroupModel[] = [];
  const xThresholdKeys: string[] = [];

  for (let rowNumber = 0; rowNumber < numberOfRows; rowNumber++) {
    let groupModel: ColumnChartDeviationGroupModel = groupModels[rowNumber] || {};

    for (let colNumber = 0; colNumber < numberOfColumns; colNumber++) {
      let row = padLeftWithZeros(rowNumber, 2);
      let col = padLeftWithZeros(colNumber, 2);

      let key = `val${row}_${col}`;
      const rawDataItem = rawData[key];
      const { waarde, label_1, eenheid } = rawDataItem;

      let xLabel = xLabelFormatter ? xLabelFormatter(rawDataItem[xLabelKey] as string, xLabelFormatterOptions) : rawDataItem[xLabelKey] as string;
      if (!!label_1 && appendThresholdToXLabel) {
        xLabel = `${xLabel} ${label_1}`;
      }

      if (colNumber === 0) {
        groupModel.key = `${rawDataItem[xLabelKey]}-${rowNumber}`;
        groupModel.label = ''; // Not used
        groupModel.xLabel = xLabel;
        groupModel.shortXLabel = xLabelFormatter ? shortXLabelFormatter(rawDataItem[xLabelKey] as string, shortXFormatterOptions) : rawDataItem[xLabelKey] as string;
        // the keys that are used in rawValues and formattedValues
        groupModel.valueKeys = [rawDataItem[valueLabelKey] as string];
        // This will for example result in: { "Nieuw": 1234, "Afgestoten": 4567 }
        groupModel.rawValues = {
          [rawDataItem[valueLabelKey] as string]: waarde
        };

        // This will for example result in: { "Nieuw": 1.234, "Afgestoten": 4.567 }
        groupModel.formattedValues = {
          [rawDataItem[valueLabelKey] as string]: valueFormatter ?
            valueFormatter(waarde, valueFormatterOptions) :
            allFormatters.formatValue(waarde, eenheid as ValueUnit, valueFormatterOptions)
        };

        reverse ? xThresholdKeys.unshift(label_1) : xThresholdKeys.push(label_1);
        // else add rawDataItem value and formatted value to the groupModel
      } else {

        groupModel.rawValues[rawDataItem[valueLabelKey] as string] = waarde;
        groupModel.formattedValues[rawDataItem[valueLabelKey] as string] = valueFormatter ? valueFormatter(waarde, valueFormatterOptions) : allFormatters.formatValue(waarde, eenheid as ValueUnit, valueFormatterOptions);

        groupModel.valueKeys.push(rawDataItem[valueLabelKey] as string);
      }
    }

    groupModels[rowNumber] = groupModel;
  }

  groupModels = reverse ? groupModels.reverse() : groupModels;
  const xThreshold = findXThreshold(xThresholdKeys, groupModels);

  return { groups: groupModels, xThreshold, isGroupModel: true };
};

export default columnChartDeviationGroupTransformer;