import MuiDivider, { DividerProps as MuiDividerProps } from '@material-ui/core/Divider';
import MuiTypography, { TypographyProps as MuiTypographyProps } from '@material-ui/core/Typography';
import { Chart } from 'ppd-library/charts';
import { scales } from 'ppd-library/charts/chartUtils';
import { BarHorizontal, BarHorizontalProps } from 'ppd-library/charts/molecules/BarHorizontal';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import muiTheme from '../../../constants/theme';
import { HelpTooltipButton, HelpTooltipButtonProps } from '../../help/HelpTooltipButton';
import { ComponentBaseProps } from '../ComponentBaseProps';
import { BarChartWaterfallDataModel, BarChartWaterfallDataGroup } from './barChartWaterfallTransformer';

import { Tooltip } from 'ppd-library/charts/organisms/Tooltip';
import { getSVGMousePosition, useTooltipPosition, TooltipTemplate } from '../tooltip-utils';

export interface BarChartWaterfallThemeProps {
  barHeight?: number; // TODO: is dit niet afhankelijk van de chart hoogte?
  /**
   * @default orange
   */
  barColor?: string;
  /**
   * @default #2b4899
   */
  mainBarColor?: string;
  /**
   * @default hidden
   */
  secondaryValueLabel?: 'visible' | 'hidden' | 'header';
  /**
   * @default header
   */
  tertiaryValueLabel?: 'visible' | 'hidden' | 'header';
  /**
   * @default false
   */
  centerVertical?: boolean;
}

export interface BarChartWaterfallProps extends ComponentBaseProps<BarChartWaterfallThemeProps, BarChartWaterfallDataModel> {
  showTooltip?: boolean;
}

const StyledWrapper = styled(({ center, ...divProps }) => <div {...divProps} />)`
  height: 100%; 
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: ${({ center }) => center ? 'center' : 'normal'};
  ` as React.ComponentType<{ center: boolean; } & React.HTMLProps<HTMLDivElement>>;

const StyledMuiTypography = styled(({ width, lineHeight, marginTop, marginBottom, ...muiTypographyProps }) => <MuiTypography {...muiTypographyProps} />)`
  && {
    width: ${({ width }) => width}px;
    line-height: ${({ lineHeight }) => lineHeight}px;
    margin-top: ${({ marginTop = 0 }) => marginTop}px;
    margin-bottom: ${({ marginBottom = 0 }) => marginBottom}px;
  }
  ` as React.ComponentType<{ width: number; lineHeight: number; marginTop?: number; marginBottom?: number; } & MuiTypographyProps>;

const StyledContentWrapper = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  justify-content: space-between;
` as React.ComponentType<React.HTMLProps<HTMLDivElement>>;

const StyledRowWrapper = styled(({ marginTop, marginBottom, paddingTop, ...divProps }) => <div {...divProps} />)`
  position: relative;
  padding-top: ${({ paddingTop }) => paddingTop};
  margin-top: ${({ marginTop }) => marginTop}px;
  margin-bottom: ${({ marginBottom }) => marginBottom}px;
` as React.ComponentType<{ marginTop: number; marginBottom: number; paddingTop: string; } & React.HTMLProps<HTMLDivElement>>;

const StyledValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`as React.ComponentType<React.HTMLProps<HTMLDivElement>>;

const StyledMuiDivider = styled(MuiDivider)`
  && {
    margin: 0.4rem 0;
  }
  ` as React.ComponentType<MuiDividerProps>;

const StyledBarHorizontal = styled(({ delay, ...barHorizontalProps }) => <BarHorizontal {...barHorizontalProps} />)`
  && {
    transition-delay: ${({ delay }) => delay}ms;
  }
` as React.ComponentType<{ delay: number; } & BarHorizontalProps>;

const StyledHelpTooltipButton = styled(({ marginLeft, ...buttonProps }) => <HelpTooltipButton {...buttonProps} />)`
  && { 
    position: absolute;
    left: ${({ marginLeft }) => marginLeft}px;
  }
` as React.ComponentType<{ marginLeft: number; } & HelpTooltipButtonProps>;

const BarChartWaterfall = (props: BarChartWaterfallProps) => {
  const {
    id,
    data,
    help,
    showHelp,
    width = 400,
    // height = 34,
    tooltipOptions,
    showTooltip = true,
    themeProperties
  } = props;

  const { groups } = data;
  const { margins = { left: 140, top: 0, right: !!groups[0].tertiaryValue ? 120 : 60, bottom: 0 } } = props;
  const chartWidth = width - margins.left - margins.right;

  const {
    barHeight = 16,
    barColor = 'orange',
    centerVertical = false,
    tertiaryValueLabel = 'header',
    secondaryValueLabel = 'hidden',
    mainBarColor = muiTheme.palette.primary.main
  } = themeProperties || {} as BarChartWaterfallThemeProps;

  const height = barHeight * 2 + 2;

  const rawValues = groups.map(({ mainValue }) => mainValue.rawValue);
  const maxValue = Math.max(...rawValues);

  const barHorizontalMargin = muiTheme.spacing(1);
  const xScale = scales.numericXScale(0, maxValue, chartWidth, barHorizontalMargin, barHorizontalMargin);


  const handleMouseMove = (event: React.MouseEvent<Element, MouseEvent>, index: number, data: BarChartWaterfallDataGroup) => {
    const { x, y } = getSVGMousePosition(event as React.MouseEvent<SVGElement, MouseEvent>);
    setTooltipPosition({ visible: true, x, y, index, data });
  };

  const handleMouseLeave = () => {
    setTooltipPosition({ ...tooltipPosition, visible: false });
  };

  const { tooltipPosition, setTooltipPosition } = useTooltipPosition<BarChartWaterfallDataGroup>();

  const [initiated, setInitiated] = useState(false);
  useEffect(() => {
    const timeOutId = setTimeout(() => setInitiated(true), 0);

    return () => clearTimeout(timeOutId);
  }, []);

  return (
    <StyledWrapper center={centerVertical}>
      <div>
        {
          groups.map((group, groupIndex: number) => {
            const {
              mainValue,
              secondaryValue,
              tertiaryValue
            } = group;

            const key = `${mainValue.label}`;
            const cumulative = groups
              .slice(1, groupIndex)
              .reduce((total: number, { mainValue }) => total + mainValue.rawValue, 0);

            const showSecondaryValueLabel = secondaryValueLabel === 'visible' || (secondaryValueLabel === 'header' && groupIndex === 0);
            const secondaryValueLabelMarginBottom = secondaryValueLabel === 'header' ? barHeight / 2 : 0;
            const secondaryValueLabelMarginTop = secondaryValueLabel === 'header' ? -barHeight * 1.5 : 0;

            const showTertiaryValueLabel = tertiaryValueLabel === 'visible' || (tertiaryValueLabel === 'header' && groupIndex === 0);
            const tertiaryValueLabelMarginBottom = tertiaryValueLabel === 'header' ? barHeight / 2 : 0;
            const tertiaryValueLabelMarginTop = tertiaryValueLabel === 'header' ? -barHeight * 1.5 : 0;

            const paddingTop =
              (showSecondaryValueLabel || showTertiaryValueLabel) &&
                (secondaryValueLabel === 'header' || tertiaryValueLabel === 'header') ? '1rem' : '0';

            return (
              <StyledRowWrapper
                key={key}
                paddingTop={paddingTop}
                marginTop={margins.top}
                marginBottom={margins.bottom}
              >
                <StyledContentWrapper>
                  {!!showHelp && !!help && help.some(({ position }) => position === groupIndex + 1) &&
                    <StyledHelpTooltipButton
                      marginLeft={margins.left}
                      anchorType={'iconButton'}
                      helpId={(help.find(({ position }) => position === groupIndex + 1) as { helpId: number }).helpId}
                    />
                  }
                  <StyledValueWrapper>
                    <StyledMuiTypography
                      align={'right'}
                      component={'span'}
                      color={'secondary'}
                      variant={'caption'}
                      width={margins.left}
                      lineHeight={barHeight}
                    >
                      {mainValue.label}
                    </StyledMuiTypography>
                    <StyledMuiTypography
                      align={'right'}
                      color={'primary'}
                      component={'span'}
                      variant={'caption'}
                      width={margins.left}
                      lineHeight={barHeight}
                    >
                      {mainValue.formattedValue}
                    </StyledMuiTypography>
                  </StyledValueWrapper>
                  <Chart
                    height={height}
                    width={chartWidth}
                  >
                    <g
                      onMouseMove={(ev) => handleMouseMove(ev, groupIndex, group)}
                      onMouseLeave={handleMouseLeave}
                    >
                      <StyledBarHorizontal
                        height={barHeight}
                        delay={groupIndex * 200}
                        y={(height - barHeight) / 2}
                        id={`${id}-${key}-horizontal-bar`}
                        key={`${id}-${key}-horizontal-bar`}
                        x={initiated ? xScale(cumulative) : 0}
                        fill={groupIndex === 0 ? mainBarColor : barColor}
                        width={initiated ? xScale(mainValue.rawValue) - barHorizontalMargin : 0}
                      />
                    </g>
                  </Chart>
                  {!!secondaryValue &&
                    <StyledValueWrapper>
                      {showSecondaryValueLabel &&
                        <StyledMuiTypography
                          align={'right'}
                          component={'span'}
                          color={'secondary'}
                          variant={'caption'}
                          lineHeight={barHeight}
                          marginTop={secondaryValueLabelMarginTop}
                          marginBottom={secondaryValueLabelMarginBottom}
                          width={tertiaryValue ? margins.right / 2 : margins.right}
                        >
                          {secondaryValue.label}
                        </StyledMuiTypography>
                      }
                      <StyledMuiTypography
                        align={'right'}
                        color={'primary'}
                        component={'span'}
                        variant={'caption'}
                        lineHeight={barHeight}
                        width={tertiaryValue ? margins.right / 2 : margins.right}
                      >
                        {secondaryValue.formattedValue}
                      </StyledMuiTypography>
                    </StyledValueWrapper>
                  }
                  {!!tertiaryValue &&
                    <StyledValueWrapper>
                      {showTertiaryValueLabel &&
                        <StyledMuiTypography
                          align={'right'}
                          component={'span'}
                          color={'secondary'}
                          variant={'caption'}
                          lineHeight={barHeight}
                          width={margins.right / 2}
                          marginTop={tertiaryValueLabelMarginTop}
                          marginBottom={tertiaryValueLabelMarginBottom}
                        >
                          {tertiaryValue.label}
                        </StyledMuiTypography>
                      }
                      <StyledMuiTypography
                        align={'right'}
                        component={'span'}
                        color={'secondary'}
                        variant={'caption'}
                        lineHeight={barHeight}
                        width={margins.right / 2}
                      >
                        {tertiaryValue.formattedValue}
                      </StyledMuiTypography>
                    </StyledValueWrapper>
                  }
                  {tooltipPosition.visible && tooltipPosition.index === groupIndex &&
                    <Tooltip
                      top={tooltipPosition.y}
                      left={tooltipPosition.x}
                      calculateOffsetPosition={false}
                      visible={showTooltip && tooltipPosition.visible}
                      theme={{
                        transition: false
                      }}
                    >
                      {() => {
                        const {
                          mainValue,
                          // secondaryValue,
                          // tertiaryValue

                        } = tooltipPosition.data as BarChartWaterfallDataGroup;
                        const valueKeys = [{
                          label: mainValue.label,
                          formattedValue: mainValue.formattedValue,
                          color: groupIndex === 0 ? mainBarColor : barColor
                        }];


                        if (!!secondaryValue) {
                          valueKeys.push({
                            label: secondaryValue.label,
                            formattedValue: secondaryValue.formattedValue,
                            color: barColor
                          })
                        }
                        if (!!tertiaryValue) {
                          valueKeys.push({
                            label: tertiaryValue.label,
                            formattedValue: tertiaryValue.formattedValue,
                            color: barColor
                          })
                        }

                        return (
                          <TooltipTemplate
                            // title={mainValue.label}
                            tooltipOptions={tooltipOptions}
                            values={
                              valueKeys.map(({ label, formattedValue, color }) => {
                                return {
                                  label: label,
                                  formattedValue: formattedValue,
                                  colors: [color]
                                }
                              })
                            }
                          />
                        );
                      }}
                    </Tooltip>
                  }
                </StyledContentWrapper>
                {
                  groupIndex < groups.length && <StyledMuiDivider />
                }
              </StyledRowWrapper>
            )
          })
        }
      </div>
    </StyledWrapper>
  )
};

export default BarChartWaterfall;
