export interface UnitCompareScrollToProps {
  scrollAmount: number;
  elementToScroll: HTMLElement;
  offsetLeft: number;
  offsetTop: number;
  direction: 'forward' | 'backward';
}

// Meer easing: https://easings.net/ & https://gist.github.com/gre/1650294
const EasingFunctions: any = {
  // easeInOutQuad: (t: number) => t < .5 ? 2 * t * t : -1 + (4 - 2 * t) * t,
  // easeInOutCubic: (t: number) => t < .5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1,
  // easeInOutQuart: (t: number) => t < .5 ? 8 * t * t * t * t : 1 - 8 * (--t) * t * t * t,
  // easeInOutQuint: (t: number) => t < .5 ? 16 * t * t * t * t * t : 1 + 16 * (--t) * t * t * t * t,
  easeOutExpo: (x: number) => x === 1 ? 1 : 1 - Math.pow(2, -10 * x)
}

const scrollTime: number = 600;

const UnitCompareScrollTo = (props: UnitCompareScrollToProps) => {
  const { scrollAmount, elementToScroll, direction, offsetLeft, offsetTop } = props;

  // Horizontale positie wordt bepaald aan de hand van de linker offset van de tabel
  // inclusief toevoeging of aftrek van de opgegeven scroll waarde (scrollAmount)
  const val: number = direction === 'forward' ? offsetLeft + scrollAmount : offsetLeft - scrollAmount;

  function scrollTo(xPosition: number, duration: number, easingFunction: (t: number) => number, cb?: any) {
    const start = Date.now();

    if (offsetLeft === xPosition) {
      cb();
      return; /* Prevent scrolling to the X position if already there */
    }

    const min = (a: number, b: number) => a < b ? a : b;
    const scroll = () => {
      const currentTime = Date.now(),
        time = min(1, ((currentTime - start) / duration)),
        easedT = easingFunction(time);

      elementToScroll.scrollTo((easedT * (xPosition - offsetLeft)) + offsetLeft, offsetTop);

      if (time < 1) {
        requestAnimationFrame(scroll);
      }
      else if (cb) {
        cb();
      };
    }

    requestAnimationFrame(scroll);
  }

  scrollTo(val, scrollTime, EasingFunctions.easeOutExpo);
}

export default UnitCompareScrollTo;