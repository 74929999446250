import { TransformFunction } from '../transform-utils';
import * as allFormatters from '../../../utils/formatters';
import doubleStackedLabeledBarTransformer, { DoubleStackedLabeledBarDataModel, DoubleStackedLabeledBarTransformOptions, DoubleStackedLabeledBarRawData } from '../DoubleStackedLabeledBar/doubleStackedLabeledBarTransformer';

interface RD {
  waarde: number;
  label_4: string;
  eenheid: string;
}

export interface DoubleStackedLabeledBarMainRawData {
  val00_00: RD;
  val00_01: RD;
  val00_02: RD;
  val00_03: RD;
}

export interface DoubleStackedLabeledBarMainDataModel extends DoubleStackedLabeledBarDataModel {
  mainValue: {
    label: string;
    rawValue: number;
    formattedValue: string;
  }
}

export interface DoubleStackedLabeledBarMainTransformOptions extends DoubleStackedLabeledBarTransformOptions {
}

const doubleStackedLabeledBarMainTransformer: TransformFunction<DoubleStackedLabeledBarMainDataModel, DoubleStackedLabeledBarMainTransformOptions> = (
  rawData: DoubleStackedLabeledBarMainRawData,
  onError,
  options
) => {

  const { val00_00, val00_01, val00_02, val00_03 } = rawData;
  const { formatters = {}, formattersOptions = {} } = options || {} as DoubleStackedLabeledBarMainTransformOptions;

  let result: DoubleStackedLabeledBarMainDataModel = {
    mainValue: { label: '', rawValue: 0, formattedValue: '' },
    primaryValue: { label: '', rawValue: 0, formattedValue: '' },
    secondaryValue: { label: '', rawValue: 0, formattedValue: '' },
    thresholdValue: { label: '', rawValue: 0, formattedValue: '' }
  };
  let guard = true;

  if (val00_00.waarde != null) {
    guard = false;
    const { label_4, waarde, eenheid } = val00_00;
    const mainValueFormatterName = formatters['mainValue'];
    const MainValueFormatterOptions = formattersOptions['mainValue'];
    const mainValueFormatter = (allFormatters as any)[mainValueFormatterName];

    result.mainValue.label = label_4;
    result.mainValue.rawValue = waarde;
    result.mainValue.formattedValue = mainValueFormatter ? mainValueFormatter(waarde, MainValueFormatterOptions) : allFormatters.formatValue(waarde, eenheid as ValueUnit, MainValueFormatterOptions);
  }

  if (guard) {
    onError('nodata');
    return null;
  }

  const doubleStackedLabeledBarRawData: DoubleStackedLabeledBarRawData = { val00_00: val00_01, val00_01: val00_02, val00_02: val00_03 };
  const doubleStackedLabeledBarResult = doubleStackedLabeledBarTransformer(doubleStackedLabeledBarRawData, onError, options);

  if (!doubleStackedLabeledBarResult) {
    return null;
  } else {
    result = { ...result, ...doubleStackedLabeledBarResult };
  }

  return result;
};

export default doubleStackedLabeledBarMainTransformer;