import { TreeDataItem } from 'ppd-library/components/organisms/CheckBoxTree';
import {
  SearchableCheckBoxTreePopOver,
  SearchableCheckBoxTreePopOverProps,
} from 'ppd-library/components/organisms/SearchableCheckBoxTreePopOver';
import React, { useEffect, useState } from 'react';

import { httpGetNavigationData } from '../../utils/http';
import { convertNavigationDataToTreeDataItems } from './UnitTreePopOverUtils';


export interface UnitTreePopOverProps extends Omit<SearchableCheckBoxTreePopOverProps, 'treeData'> {

}

// eslint-disable-next-line
const { DATA_URL } = ppd.appConfig;
const minInputValue = 3;
/**
 * Component fetches its own unit data and converts it to a tree.
 */
const UnitTreePopOver = (props: UnitTreePopOverProps) => {
  const {
    isOpen,
    ...searchableCheckBoxTreeProps
  } = props;

  const [treeData, setTreeData] = useState<TreeDataItem[]>([]);

  // TODO: een hook van maken?
  useEffect(() => {
    const getNavigationData = async () => {
      const navData = await httpGetNavigationData(DATA_URL);
      const navTreeData = convertNavigationDataToTreeDataItems(navData);

      setTreeData(navTreeData);
    };

    // only do this once and only when the popover is opened.
    if (isOpen && (!treeData || treeData.length === 0)) {
      getNavigationData();
    }
    //eslint-disable-next-line
  }, [isOpen]);

  return (
    <SearchableCheckBoxTreePopOver
      {...searchableCheckBoxTreeProps}
      isOpen={isOpen}
      treeData={treeData}
      minLengthInputValue={minInputValue}
      placeholder={'Zoeken op afdeling'}
    />
  );

};

export default UnitTreePopOver;