import MuiDivider, { DividerProps as MuiDividerProps } from '@material-ui/core/Divider';
import MuiTypography, { TypographyProps as MuiTypographyProps } from '@material-ui/core/Typography';
import React from 'react';
import styled from 'styled-components';

import { pxToRem } from '../../../utils/converters';
import { ComponentBaseProps } from '../ComponentBaseProps';
import { Legend, useLegend } from '../legend-utils';
import { ParetoChart } from '../ParetoChart';
import { ParetoChartGroupedRowDataModel } from './paretoChartGroupedRowTransformer';
import { HelpTooltipButton } from '../../help/HelpTooltipButton';

const dividerHeight: number = 8;
// TODO: Dit moet gewoon offsetLeft worden zoals op andere plekken. en via de view meegegeven worden.
const labelCellWidth: number = 240;
const valueCellWidth: number = 80;

export interface ParetoChartGroupedRowThemeProps {
  /**
   * Color that is used when no or not enough barColors are supplied.
   * @default #2b4899
   */
  barColor?: string;
  /**
   * A color for every bar.
   * @default ['#2b4899','orange','gray']
   */
  barColors?: string[];
}

export interface ParetoChartGroupedRowProps extends ComponentBaseProps<ParetoChartGroupedRowThemeProps, ParetoChartGroupedRowDataModel> {
  rowHeight?: number;
  showTooltip?: boolean;
}

const StyledWrapper = styled.div`

` as React.ComponentType<React.HTMLProps<HTMLDivElement>>;

const StyledContentWrapper = styled.div`
  position: relative;
`as React.ComponentType<React.HTMLProps<HTMLDivElement>>;

const StyledValueWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`as React.ComponentType<React.HTMLProps<HTMLDivElement>>;

const StyledMuiTypography = styled(({ width, ...muiTypographyProps }) => <MuiTypography {...muiTypographyProps} />)`
  && {
    width: ${({ width }) => pxToRem(width)};
  }
`as React.ComponentType<{ width: number; } & MuiTypographyProps>;

const StyledMuiDivider = styled(MuiDivider)` && {
  margin: ${() => pxToRem(dividerHeight / 2)} 0;
}
`as React.ComponentType<MuiDividerProps>;

const ParetoChartGroupedRow = (props: ParetoChartGroupedRowProps) => {
  const {
    id,
    data,
    help,
    showHelp,
    rowHeight,
    width = 300,
    formatters = {},
    showTooltip = true,
    legendOptions = {},
    themeProperties,
    formattersOptions = {},
    height: originalHeight = 400,
    // margins = { left: 140, right: 0, top: 20, bottom: 28 },
  } = props;

  const { rowData } = data;

  const {
    barColor = '#2b4899',
    barColors = rowData[0].bars.length === 3 ? ['#2b4899', 'orange', 'gray'] : rowData[0].bars.map(() => barColor)
  } = themeProperties || {} as ParetoChartGroupedRowThemeProps;

  const legendElementRef = React.createRef<HTMLDivElement>();
  const { legendHeight } = useLegend(originalHeight, legendElementRef);
  const height = originalHeight - legendHeight;

  return (
    <StyledWrapper>
      {
        rowData.map((row: any, index: number) => {
          const { group = {} } = row;
          return (
            <StyledContentWrapper key={`${id}-${index}`} style={{ position: 'relative' }}>
              <StyledValueWrapper>
                <StyledMuiTypography
                  align={'left'}
                  component={'span'}
                  color={'secondary'}
                  variant={'body2'}
                  width={labelCellWidth}
                >
                  {group.label}
                </StyledMuiTypography>
                <StyledMuiTypography
                  align={'right'}
                  color={'primary'}
                  component={'span'}
                  variant={'body2'}
                  width={valueCellWidth}
                >
                  {group.formattedValue}
                </StyledMuiTypography>
                <div>
                  <ParetoChart
                    id={`${id}-${index}`}
                    data={row}
                    height={!!rowHeight ? rowHeight : (height / rowData.length) - dividerHeight}
                    width={width - (labelCellWidth + valueCellWidth)}
                    themeProperties={{ barColors }}
                    showTooltip={showTooltip}
                    formatters={formatters}
                    formattersOptions={formattersOptions}
                  />
                </div>
              </StyledValueWrapper>
              {
                rowData.length !== index + 1 && <StyledMuiDivider />
              }

            </StyledContentWrapper>
          )
        })
      }
      <Legend
        ref={legendElementRef}
        legendOptions={{ orientation: 'horizontal', ...legendOptions }}
        values={Object.keys(data.rowData[0].bars).map((key: any) => {
          return {
            label: data.rowData[0].bars[key].secondaryValue.label,
            colors: [barColors[key]]
          };
        })}
        afterLegendContent={
          <>
            {!!showHelp && !!help && help.some(({ position }) => position === 99) &&
              <HelpTooltipButton
                anchorType={'iconButton'}
                helpId={(help.find(({ position }) => position === 99) as { helpId: number }).helpId}
              />

            }
          </>
        }
      />
    </StyledWrapper>
  )
};

export default ParetoChartGroupedRow;