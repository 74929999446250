import React from 'react';
import styled from 'styled-components';
import { pxToRem } from '../../../utils/converters';

export const StyledDashboardPageWrapper = styled(({ isMobile, menuOpenWidth, menuOpen, menuOpenSpeed, ...divProps }) => <div {...divProps} />)`
  padding-top: 0.3rem;
  transition: ${({ menuOpenSpeed }) => `transform ${menuOpenSpeed}ms ease-in-out;`};
  transform: ${({ menuOpenWidth, menuOpen, isMobile }) => !isMobile && `translateX(${menuOpen ? pxToRem(menuOpenWidth - 100) : 0})`}; // TODO: waar komt - 100 vandaan en waarom pxToRem?
`as React.ComponentType<{ isMobile: boolean; menuOpenWidth: number; menuOpen: boolean; menuOpenSpeed: number; } & React.HTMLProps<HTMLDivElement>>;

// export default StyledDashboardPageWrapper;