import Button, { ButtonProps as MuiButtonProps } from '@material-ui/core/Button';
import MuiTableCell, { TableCellProps as MuiMuiTableCellProps } from '@material-ui/core/TableCell';
import MuiTableHead from '@material-ui/core/TableHead';
import MuiTableRow, { TableRowProps as MuiMuiTableRowProps } from '@material-ui/core/TableRow';
import MuiNavigateNextIcon from '@material-ui/icons/NavigateNext';
import React, { RefObject, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import UnitCompareScrollTo from '../UnitCompareScrollTo';
import { UnitCompareStickyHeaderCell } from './';

interface UnitCompareStickyHeaderProps {
  isIos: boolean;
  scrollAmount: number;
  refTable: RefObject<HTMLElement>;
  columns: API_GET.UnitCompareColumn[];
  refTableContainer: RefObject<HTMLElement>;
  onDeleteColumnClick: (column: API_GET.UnitCompareColumn) => void;
  onPinColumnClick: (column: API_GET.UnitCompareColumn, columnIndex: number) => void;
  onDropColumn: (droppingColumn: API_GET.UnitCompareColumn, droppedOnColumn: API_GET.UnitCompareColumn) => void;
  /**
   * @default true
   */
  isEditable?: boolean;
}

const StyledMuiTableRow = styled(({ isIos, ...tableRowProps }) => <MuiTableRow {...tableRowProps} />)`
  && {
    background-color: #fff;
    .MuiTableCell-root {
      width: ${({ isIos }) => isIos ? '200px' : null};
    }
  }
`as React.ComponentType<{ isIos: boolean; } & MuiMuiTableRowProps>;

const StyledMuiTableOverlayCell = styled(MuiTableCell)`
 &&& {
   background-color: #fff;
   border-bottom: 1px solid #ccc;
   z-index: 2;
   padding: 0;
   height: 57px;
   width: 299px;
 }
`as React.ComponentType<MuiMuiTableCellProps>;

const StyledMuiTableCellButtonHolder = styled(MuiTableCell)`
 &&& {
  line-height: 0;
  height: 0;
  padding: 0;
  border: 0;
  position: relative;
  z-index: 1040;
 }
`as React.ComponentType<MuiMuiTableCellProps>;

const StyledScrollButton = styled(Button)`
  &&{
    border-radius: 0;
    border-color: #ccc;
    border-bottom: 0;
    background: #fff;
    position: fixed;
    margin-top: -59px;
    height: 57px;

   &:hover {
    background: #fff;
    border-color: #ccc;
    border-bottom: 0;
   }
    &.Mui-disabled {
      border: 0;
    }
  }
  `;

const StyledScrollBackwardButton = styled(({ isActive, ...thProps }) => <StyledScrollButton {...thProps} />)`
  && {
    left: 236px;
    box-shadow: ${({ isActive }) => isActive ? '11px 0px 15px -8px rgba(0,0,0,0.14)' : null};
    .MuiSvgIcon-root {
      transform: rotate(180deg);
    }
  }
`as React.ComponentType<{ isActive: boolean } & MuiButtonProps>;


const StyledScrollForwardButton = styled(({ isActive, ...thProps }) => <StyledScrollButton {...thProps} />)`
  &&{
    right: 0;
    box-shadow: ${({ isActive }) => isActive ? '-11px 0px 15px -8px rgba(0,0,0,0.14)' : null};
  }
`as React.ComponentType<{ isActive: boolean } & MuiButtonProps>;

const StyledMuiLastTableCell = styled(({ isLastCell, ...tableCellProps }) => <MuiTableCell {...tableCellProps} />)`
  &&& {
    background-color: #fff;
    border-bottom: 1px solid #ccc;
    font-family: 'regular';
    padding: 0;
    height: 57px;
    width: ${({ isLastCell }) => isLastCell ? '45px' : '100%'};

  }
`as React.ComponentType<{ isLastCell?: boolean } & MuiMuiTableCellProps>;


const getRefWidth = (ref: RefObject<any>) => (ref as RefObject<any>).current.getBoundingClientRect().width;

const UnitCompareStickyHeader = (props: UnitCompareStickyHeaderProps) => {

  const {
    isIos,
    columns,
    refTable,
    scrollAmount,
    onDropColumn,
    onPinColumnClick,
    refTableContainer,
    onDeleteColumnClick,
    isEditable = true
  } = props;

  const [headerCellIndex, setHeaderCellIndex] = useState<number>(-1);
  const [dragEnterElementId, setDragEnterElementId] = useState<number>(-1);
  const [draggedElementIndex, setdraggedElementIndex] = useState<number>(-1);
  const [offsetAfterScroll, setOffsetAfterScroll] = useState<number[]>([0, 0]);
  const [showNavButtons, setShowNavButtons] = useState<boolean[]>([false, false]);
  
  const leftOffset = useRef<number>(0);
  const refTableHeader = useRef<HTMLElement>(null);
  const dragStartColumnRef = useRef<API_GET.UnitCompareColumn | null>(null);

  useEffect(() => {
    const tableWidth = getRefWidth(refTable);
    const containerWidth = getRefWidth(refTableContainer);

    setNavigationButtonsVisibility(tableWidth, containerWidth, leftOffset.current);
    // eslint-disable-next-line
  }, [columns]);


  useEffect(() => {
    const tableContainer = (refTableContainer as RefObject<any>).current;
    tableContainer.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    return () => {
      tableContainer.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line
  }, []);

  const handleResize = () => {
    const containerWidth = getRefWidth(refTableContainer);
    const tableWidth = getRefWidth(refTable);

    setNavigationButtonsVisibility(tableWidth, containerWidth, leftOffset.current);
  }


  const handleScroll = (e: any) => {
    const scrollLeft = e.target.scrollLeft;
    const containerWidth = getRefWidth(refTableContainer);
    const tableWidth = getRefWidth(refTable);

    leftOffset.current = scrollLeft;
    setOffsetAfterScroll([scrollLeft, e.target.scrollTop]);
    setNavigationButtonsVisibility(tableWidth, containerWidth, scrollLeft);
  }

  const setNavigationButtonsVisibility = (tableWidth: number, containerWidth: number, scrollLeft: number) => {
    if (scrollLeft < 5 && ((containerWidth + scrollLeft) < tableWidth)) {
      setShowNavButtons([false, true]);
    }
    if (scrollLeft < 5 && ((containerWidth + scrollLeft) >= tableWidth)) {
      setShowNavButtons([false, false]);
    }
    else if (scrollLeft > 0 && ((containerWidth + scrollLeft) < tableWidth)) {
      setShowNavButtons([true, true]);
    }
    else if (scrollLeft > 0 && ((containerWidth + scrollLeft) >= tableWidth)) {
      setShowNavButtons([true, false]);
    }
  }

  const handleHeaderCellEnter = (index: number) => {
    if (!isEditable) {
      setHeaderCellIndex(-1);
    } else {
      setHeaderCellIndex(index);
    }
  };

  const handleHeaderCellLeave = () => {
    setHeaderCellIndex(-1);
  };

  const handleNavigationButtonClick = (e: React.MouseEvent<Element, MouseEvent>, direction: 'forward' | 'backward') => {
    e.preventDefault();

    const [offsetLeft, offsetTop] = offsetAfterScroll;

    UnitCompareScrollTo({
      direction,
      offsetTop,
      offsetLeft,
      scrollAmount,
      elementToScroll: refTableContainer.current as HTMLElement,
    });

  };

  const handleDragStart = (column: API_GET.UnitCompareColumn) => {
    dragStartColumnRef.current = column;
  };

  const handleDragEnd = () => setdraggedElementIndex(-1);

  const handleDragOver = (ev: React.DragEvent<HTMLElement>, index: number) => {
    ev.stopPropagation();
    ev.preventDefault();
    setdraggedElementIndex(index);
  };

  const handleDragLeave = () => {
    setDragEnterElementId(-1);
    setdraggedElementIndex(-1);
  };

  
  const handleDrop = (dropColumn: API_GET.UnitCompareColumn, index: number) => {
    if (!!dragStartColumnRef.current) {
      const { organisationUnit: newOrganisationUnit } = dropColumn;
      const { organisationUnit: currentOrganisationUnit } = dragStartColumnRef.current;

      if(newOrganisationUnit.code === currentOrganisationUnit.code) return;
    }
    
    onDropColumn(dragStartColumnRef.current as API_GET.UnitCompareColumn, dropColumn);

    // focus op de gedropte cell
   setTimeout(() => setHeaderCellIndex(index), 150);
  };
  const [showLeftNavigationButton, showRightNavigationButton] = showNavButtons;

  return (
    <MuiTableHead ref={refTableHeader}>
      <StyledMuiTableRow isIos={isIos}>
        <StyledMuiTableOverlayCell />
        {columns.map((column, index: number) => {

          const { organisationUnit } = column;
          const { code: oeCode } = organisationUnit;

          return (
            <UnitCompareStickyHeaderCell
              key={oeCode}
              column={column}
              isEditable={isEditable}
              onDragEnd={handleDragEnd}
              isPinned={column.isPinned}
              onDragLeave={handleDragLeave}
              selected={headerCellIndex === index}
              onDrop={() => handleDrop(column, index)}
              isDragging={draggedElementIndex === index}
              onDragStart={() => handleDragStart(column)}
              isDraggedOver={dragEnterElementId === index}
              onDragOver={(e) => handleDragOver(e, index)}
              onMouseLeave={() => handleHeaderCellLeave()}
              onMouseEnter={() => handleHeaderCellEnter(index)}
              onDeleteClick={() => onDeleteColumnClick(column)}
              onPinClick={() => onPinColumnClick(column, index)}
            />
          );
        })}
        {/* the last cell makes sure that the columns have fixed widths */}
        <StyledMuiLastTableCell isLastCell={columns.length > 0 && showLeftNavigationButton}>
          {/* {columns.length < 1 &&
            <>
              <StyledIconHolder><MuiStarIcon fontSize='small' /></StyledIconHolder>
              <StyledTextHolder> Voeg een Organisatie Eenheid toe...</StyledTextHolder>
            </>
          } */}
        </StyledMuiLastTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow isIos={isIos}>
        <StyledMuiTableCellButtonHolder colSpan={100}>
          <StyledScrollBackwardButton
            isActive={showLeftNavigationButton}
            disabled={!showLeftNavigationButton}
            onClick={(ev) => handleNavigationButtonClick(ev, 'backward')}
            variant={'outlined'}
            title={'Naar links scrollen'}
            color={'primary'}
            size={'small'}
          >
            <MuiNavigateNextIcon fontSize={'large'} />
          </StyledScrollBackwardButton>
          <StyledScrollForwardButton
            isActive={showRightNavigationButton}
            disabled={!showRightNavigationButton}
            onClick={(ev: any) => handleNavigationButtonClick(ev, 'forward')}
            variant={'outlined'}
            title={'Naar rechts scrollen'}
            color={'primary'}
            size={'small'}
          >
            <MuiNavigateNextIcon fontSize={'large'} />
          </StyledScrollForwardButton>
        </StyledMuiTableCellButtonHolder>
      </StyledMuiTableRow>
    </MuiTableHead>
  );
}

export default UnitCompareStickyHeader;