import { TransformFunction, TransformOptionsBase } from '../transform-utils';
import * as allFormatters from '../../../utils/formatters';

interface RD {
    waarde: number;
    label_4: string;
    eenheid: string;
}

export interface BarSlideRawData {
    val00_00: RD;
    val00_01: RD;
}

export interface BarSlideDataModel {
    firstValue: {
        label: string;
        rawValue: number;
        formattedValue: string;
    }
    secondValue: {
        label: string;
        rawValue: number;
        formattedValue: string;
    }
}

export interface BarSlideTransformOptions extends TransformOptionsBase {
}

const barSlideMainTransformer: TransformFunction<BarSlideDataModel, BarSlideTransformOptions> = (
    rawData: BarSlideRawData,
    onError,
    options
) => {

    const { val00_00, val00_01 } = rawData;
    const { formatters = {} } = options || {} as BarSlideTransformOptions;

    let result: BarSlideDataModel = {
        firstValue: { label: '', rawValue: 0, formattedValue: '' },
        secondValue: { label: '', rawValue: 0, formattedValue: '' },
    };
    let guard = true;

    if (val00_00.waarde != null) {
        guard = false;
        const { waarde, label_4, eenheid } = val00_00;
        const barValueFormatterName = formatters['barValue'];
        const barValueFormatter = (allFormatters as any)[barValueFormatterName];

        result.firstValue.label = label_4;
        result.firstValue.rawValue = waarde;

        result.firstValue.formattedValue = barValueFormatter ? barValueFormatter(waarde) : allFormatters.formatValue(waarde, eenheid as ValueUnit);
    }

    if (val00_01.waarde != null) {
        guard = false;
        const { waarde, label_4, eenheid } = val00_01;
        const barValueFormatterName = formatters['barValue'];
        const barValueFormatter = (allFormatters as any)[barValueFormatterName];

        result.secondValue.label = label_4;
        result.secondValue.rawValue = waarde;
        result.secondValue.formattedValue = barValueFormatter ? barValueFormatter(waarde) : allFormatters.formatValue(waarde, eenheid as ValueUnit);
    }

    if (guard) {
        onError('nodata');
        return null;
    }

    return result;
};

export default barSlideMainTransformer;