import { TransformFunction, TransformOptionsBase, calcNumberOfRows, padLeftWithZeros } from '../transform-utils';
import * as allFormatters from '../../../utils/formatters';

interface RD {
  waarde: number;
  label_1: string;
  label_4: string;
  eenheid: string;
}

export interface StackedLinedLabeledBarDoubleRawData {
  [key: string]: RD;
}

export interface BarDataModel {
  label: string | null;
  rawValue: number;
  formattedValue: string;
}

export interface StackedLinedLabeledBarDoubleDataModel {
  topBarModel: DoubleStackedLinedLabeledBarsDataModel;
  bottomBarModel: DoubleStackedLinedLabeledBarsDataModel;
}

export interface DoubleStackedLinedLabeledBarsDataModel {
  [key: string]: {
    primaryValue: BarDataModel;
    secondaryValue: BarDataModel | null;
  }
}

export interface DoubleStackedLinedLabeledSecondBarDataModel {
  [key: string]: BarDataModel
}

export interface StackedLinedLabeledBarDoubleTransformOptions extends TransformOptionsBase {

}

const stackedLinedLabeledBarDoubleTransformer: TransformFunction<StackedLinedLabeledBarDoubleDataModel, StackedLinedLabeledBarDoubleTransformOptions> = (
  rawData: StackedLinedLabeledBarDoubleRawData,
  onError,
  options
) => {
  const keys = Object.keys(rawData);
  const guard = !keys.some((key) => rawData[key].waarde !== null);

  if (guard) {
    onError('nodata');
    return null;
  }

  const {
    formatters = {},
    formattersOptions = {},
  } = options || {} as StackedLinedLabeledBarDoubleTransformOptions;

  const valueFormatterName = formatters['value'];
  const valueFormatter = (allFormatters as any)[valueFormatterName];
  const valueFormatterOptions = formattersOptions['value'];

  const numberOfRows = calcNumberOfRows(keys);
  const result: DoubleStackedLinedLabeledBarsDataModel = {};
  const resultSecondBar: DoubleStackedLinedLabeledBarsDataModel = {};

  for (let rowNumber = 0; rowNumber < numberOfRows; rowNumber++) {
    const row = padLeftWithZeros(rowNumber, 2);

    // TODO: Dit gaat dus over 2 kolommen per bar.. in de regel percentage en aantal.
    const key00 = rawData[`val${row}_00`];
    const key01 = rawData[`val${row}_01`];
    const key02 = rawData[`val${row}_02`];
    const key03 = rawData[`val${row}_03`];

    result[key00.label_1] = {
      primaryValue: {
        label: key00.label_4,
        rawValue: key00.waarde,
        formattedValue: valueFormatter ? valueFormatter(key00.waarde, valueFormatterOptions) : allFormatters.formatValue(key00.waarde, key00.eenheid as ValueUnit, valueFormatterOptions)
      },
      secondaryValue: {
        label: key01.label_4,
        rawValue: key01.waarde,
        formattedValue: valueFormatter ? valueFormatter(key01.waarde, valueFormatterOptions) : allFormatters.formatValue(key01.waarde, key01.eenheid as ValueUnit, valueFormatterOptions)
      }
    }

    // TODO: Make second bar optional...?
    if (!!key02) {
      resultSecondBar[key02.label_1] = {
        primaryValue: {
          label: key02.label_4,
          rawValue: key02.waarde,
          formattedValue: valueFormatter ? valueFormatter(key02.waarde, valueFormatterOptions) : allFormatters.formatValue(key02.waarde, key02.eenheid as ValueUnit, valueFormatterOptions)
        },
        secondaryValue: key03
          ? {
            label: key03.label_4,
            rawValue: key03.waarde,
            formattedValue: valueFormatter ? valueFormatter(key03.waarde, valueFormatterOptions) : allFormatters.formatValue(key03.waarde, key03.eenheid as ValueUnit, valueFormatterOptions)
          }
          : null
      }
    }
  }

  return {
    topBarModel: result,
    bottomBarModel: resultSecondBar
  };
};

export default stackedLinedLabeledBarDoubleTransformer;