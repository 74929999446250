import Chart from 'ppd-library/charts/Chart';
import { scales } from 'ppd-library/charts/chartUtils';
import { LineThresholdVertical } from 'ppd-library/charts/molecules/LineThreshold';
import { BarHorizontalStacked } from 'ppd-library/charts/organisms/BarHorizontalStacked';
import React, { useEffect, useState, ReactNode } from 'react';
import styled from 'styled-components';

import { HelpTooltipButton, HelpTooltipButtonProps } from '../../help/HelpTooltipButton';
import { ComponentBaseProps } from '../ComponentBaseProps';
import { createLabel, defaultChartLabelStyle } from '../componentUtils';
import { ContentRow } from '../ContentRow';
import { DoubleStackedLabeledBarDataModel } from './doubleStackedLabeledBarTransformer';
import { Bar } from 'ppd-library/charts/atoms/Bar';

export interface DoubleStackedLabeledBarThemeProps {
  barColors?: string[];
  barHeight?: number;
  trackColor?: string;
  lineColor?: string;
  labelTextColor?: string;
}
interface DoubleStackedLabeledBarProps extends ComponentBaseProps<DoubleStackedLabeledBarThemeProps, DoubleStackedLabeledBarDataModel> {
  renderAbove?: ReactNode;
  primaryLabel?: ReactNode;
  /**
   * @default true
   */
  doubleRowHeight?: boolean;
}

interface primaryValueLabelProps {
  label: string;
  formattedValue: string;
  hideLabel?: boolean | 'description';
}

const StyledWrapper = styled.div`
  position: relative;
  flex: 1;
`;

const StyledChartHolder = styled.div`

`;

const StyledTspan = styled.tspan`
  fill: #2b4899;
  font-size: 2.5rem;
  font-weight: 500;
`;

const StyledSuffixTSpan = styled.tspan`
 font-size: 1.5rem;
`;

const PrimaryValueLabel = (props: primaryValueLabelProps) => {
  const { label, hideLabel = false, formattedValue } = props;
  let description = hideLabel === 'description' ? '' : `${label}: `;

  let split = [formattedValue];
  if (formattedValue.indexOf(' ') !== -1) {
    split = formattedValue.split(' ');
  }

  return (
    <tspan dy={20} style={defaultChartLabelStyle}>
      {description}
      <StyledTspan y={24}>
        {split.map((splitValue, index) =>
          <React.Fragment key={index}>
            {index === 0 && splitValue}
            {index !== 0 && <StyledSuffixTSpan> {splitValue}</StyledSuffixTSpan>}
          </React.Fragment>)
        }
      </StyledTspan>
    </tspan>
  );
}

const StyledHelpTooltipButton = styled(({ top, left, ...helpTooltipButtonProps }) => <HelpTooltipButton {...helpTooltipButtonProps} />)`
  && { 
    position: absolute;
    left: ${({ left }) => left}px;
    margin-top: ${({ top }) => top}px;
  }
`as React.ComponentType<{ top: number; left: number; } & HelpTooltipButtonProps>;

// TODO: hernoemen naar BarSlideStacked
// TODO: generiek maken met aantal stacked bars en labels?
const DoubleStackedLabeledBar = (props: DoubleStackedLabeledBarProps) => {
  const {
    id,
    data,
    help,
    showHelp,
    renderAbove,
    width = 400,
    // height = 200,
    primaryLabel,
    doubleRowHeight = true,
    margins = { left: 0, right: 0, top: 60, bottom: 60 },
    hideLabel = {},
    themeProperties
  } = props;

  const {
    barHeight = 24,
    lineColor = '#636363',
    trackColor = '#EEEEEE',
    labelTextColor = '#636363',
    barColors = ['#2b4899', 'orange']
  } = themeProperties || {} as DoubleStackedLabeledBarThemeProps;

  const { primaryValue, secondaryValue, thresholdValue } = data;

  const maxValue = Math.max(primaryValue.rawValue, secondaryValue.rawValue, thresholdValue.rawValue);
  const xScale = scales.numericXScale(0, maxValue, width, margins.left, margins.right);
  const colorScale = scales.colorScale({ [primaryValue.label]: barColors[0], [secondaryValue.label]: barColors[1] });

  const [initiated, setInitiated] = useState(false);
  useEffect(() => {
    setInitiated(true);
  }, []);

  return (
    <StyledWrapper>
      {!!renderAbove && renderAbove}
      <ContentRow double={doubleRowHeight}>
        {!!showHelp && !!help && help.some(({ position }) => position === 2) &&
          <StyledHelpTooltipButton
            top={-barHeight}
            anchorType={'iconButton'}
            left={xScale(primaryValue.rawValue) - 32}
            helpId={(help.find(({ position }) => position === 2) as { helpId: number }).helpId}
          />
        }
        {!!showHelp && !!help && help.some(({ position }) => position === 3) &&
          <StyledHelpTooltipButton
            top={barHeight}
            anchorType={'iconButton'}
            left={xScale(primaryValue.rawValue + secondaryValue.rawValue) - 32}
            helpId={(help.find(({ position }) => position === 3) as { helpId: number }).helpId}
          />
        }
        <StyledChartHolder>
          <Chart
            width={width}
            height={140}
            preserveAspectRatio={'none'}
          >
            <Bar
              y={58}
              x={xScale(0)}
              fill={trackColor}
              height={barHeight}
              id={`${id}-bar-track`}
              width={xScale(maxValue)}
            />
            <BarHorizontalStacked
              y={58}
              xScale={xScale}
              height={barHeight}
              colorScale={colorScale}
              keys={[primaryValue.label, secondaryValue.label]}
              id={`${id}-bar-horizontal-stacked`}
              data={{
                key: `${id}-bar-horizontal-stacked`,
                [primaryValue.label]: initiated ? primaryValue.rawValue : 0,
                [secondaryValue.label]: initiated ? secondaryValue.rawValue : 0
              }}
            />
            <LineThresholdVertical
              y={22}
              showCircle={false}
              lineHeight={barHeight * 1.5}
              x={initiated ? xScale(primaryValue.rawValue) - 1 : 0}
              labelPos={[primaryValue.rawValue > (maxValue / 2) ? 'left' : 'right', 'top']}
              labelDominantBaseline={'auto'}
              label={
                <>
                  {!!primaryLabel && primaryLabel}
                  {!primaryLabel &&
                    <PrimaryValueLabel
                      label={primaryValue.label}
                      hideLabel={hideLabel['primaryValue']}
                      formattedValue={primaryValue.formattedValue}
                    />
                  }
                </>
              }
              theme={{
                lineColor: lineColor,
                textColor: labelTextColor,
                backgroundColor: 'transparent'
              }}
            />
            <LineThresholdVertical
              labelPadding={4}
              labelDominantBaseline={0}
              y={82}
              yText={12}
              showCircle={false}
              lineHeight={barHeight * 1.2}
              label={createLabel('secondaryValue', secondaryValue, hideLabel)}
              x={initiated ? xScale(primaryValue.rawValue + secondaryValue.rawValue) - 1 : 0}
              labelPos={[(primaryValue.rawValue + secondaryValue.rawValue) > (maxValue / 2) ? 'left' : 'right', 'bottom']}
              theme={{
                lineColor: lineColor,
                textColor: labelTextColor,
                backgroundColor: 'transparent',
                textSize: '0.8rem',
              }}
            />
            <LineThresholdVertical
              labelDominantBaseline={0}
              y={0}
              yText={12}
              lineHeight={140}
              showCircle={false}
              x={xScale(maxValue) - 1}
              labelPos={['left', 'top']}
              id={`${id}-line-threshold-vertical`}
              label={createLabel('thresholdValue', thresholdValue, hideLabel)}
              theme={{
                lineColor: lineColor,
                textColor: labelTextColor,
                backgroundColor: 'transparent',
                textSize: '0.8rem'
              }}
            />
          </Chart>
        </StyledChartHolder>
      </ContentRow>
    </StyledWrapper>
  )
};

export default DoubleStackedLabeledBar;
