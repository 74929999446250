import { UnitCompareDataRow } from '../pages/unit-compare/UnitCompareRow';

// TODO: huh wat is dit?
declare function escape(s: string): string;

export type TransformedObjectData = {
  // headers: THeader[];
  // rows: TileDataRow[];
};





export type Props = {
  reportTitle: string;
  showHeaders: boolean;
  generateCSV: boolean;
  generateXls?: boolean;
  unitValueOwnColumn?: boolean;
};

const GenerateOutput = (jsonData: TransformedObjectData, unitCompareColumns: API_GET.UnitCompareColumn[], options: Props) => {
  const { reportTitle, showHeaders, generateCSV, generateXls, unitValueOwnColumn = true } = options;
  const columnDelimiter = ' ;';
  const lineDelimiter = '\r\n';

  if (generateCSV) {
    CreateCsv(jsonData, unitCompareColumns, reportTitle, showHeaders);
  }

  if (generateXls) { };

  // CSV ///////////////////////////////////////////////////////////////
  function CreateCsv(jsonData: any, unitCompareColumns: API_GET.UnitCompareColumn[], reportTitle: string, showLabel: boolean) {

    if (jsonData[0].dataRows.length < 1) return;

    // If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    const tableData = typeof jsonData !== 'object' ? JSON.parse(jsonData) : jsonData;

    const headers: string[] = Object.keys(tableData[0].dataRows[0]).filter((columnKey: string) => columnKey !== 'labelColumn') || ['-'];

    let csv: string = '';

    if (reportTitle) {
      csv += reportTitle + `${lineDelimiter}\n`;
    }
    // Label/Header
    if (showLabel) {
      let row = ` ${columnDelimiter}`;

      if(!!unitValueOwnColumn) {
        row += ` ${columnDelimiter}`;
      }

      headers.forEach((oe: string) => {
        const ucc = unitCompareColumns.find(( { organisationUnit } ) => organisationUnit.code === oe ) as API_GET.UnitCompareColumn;
        row += ` ${ucc.organisationUnit.name || ''}${columnDelimiter}`;
      });

      csv += `${row.slice(0, -1)}${lineDelimiter}`;
    }

    // Body rows
    tableData.forEach((tileData: any) => {
      const  {label: tileTitle, themeName, dataRows} = tileData;

      if (tileTitle) {
        csv += `${lineDelimiter}${tileTitle} (${themeName})`;
      }


      dataRows.forEach((row: UnitCompareDataRow) => {
        let dataRow = lineDelimiter;
        Object.keys(row)
          .filter((row: string) => row !== 'labelColumn')
          .forEach((columnKey, index: number) => {
            const rawValue = (row[columnKey].formattedValue as string).replace(/^(-)|[^0-9.,]+/g, '$1');
            const unit = unitValueOwnColumn ? '' : `(${row[columnKey].valueUnit})`;

            if(index === 0) {
              const label = row.labelColumn.label ? `${row.labelColumn.label} ${unit}` : '';
              dataRow += `  ${label} ${columnDelimiter}`;

              if(!!unitValueOwnColumn) {
                dataRow += ` ${row[columnKey].valueUnit} ${columnDelimiter}`;
              }
            }
            dataRow += `${rawValue || ''} ${columnDelimiter}`;
        })

       csv += dataRow;
      });

      csv += lineDelimiter;
    });

    downloadCsv(csv);
  };


  //  Creates and destroys a link to a new created .csv file and downloads it. 
  function downloadCsv(csv: string) {
    if (!csv) {
      console.error('Invalid data');
      return;
    }
    const today = new Date();
    const date = `${today.getDate()}.${today.getMonth() + 1}.${today.getFullYear()}`;
    const uri = `data:text/csv;charset=utf-8,${escape(csv)}`;
    const fileExtension = '.csv';
    const fileName = `ppd-csv-${date}${fileExtension}`;

    const blob = new Blob([csv], { type: 'text/csv' });
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveBlob(blob, fileName);
    }

    const link = document.createElement('a');
      link.href = uri;
      link.className = 'visually-hidden';
      link.download = `${fileName}`;

    document.body.appendChild(link);

    link.click(); 

    document.body.removeChild(link);

  }
};

export default GenerateOutput;