import { ValueBlockSimple } from 'ppd-library/components/molecules/ValueBlockSimple';
import React from 'react';
import styled from 'styled-components';

import { ThreeValuesDataModel } from './threeValuesTransformer';
import { ComponentBaseProps } from '../ComponentBaseProps';
import { HelpTooltipButton } from '../../help/HelpTooltipButton';

export interface ThreeValuesThemeProps { }

export interface ThreeValuesProps extends ComponentBaseProps<ThreeValuesThemeProps, ThreeValuesDataModel> {
  noMainValue?: boolean;
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  && {  
    justify-content: center;
    width: 100%;
  }
` as React.ComponentType<React.HTMLProps<HTMLDivElement>>;

const StyledSecondaryValueWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  position: relative;
  flex-direction: column;
`;

const StyledPrimaryValueWrapper = styled.div<{ hidden: boolean }>`
  flex: 1;
  width: 100%;
  display: flex;
  min-width: 100%;
  position: relative;
  margin-bottom: 2.5rem;
  visibility: ${({ hidden }) => hidden ? 'hidden' : 'visible'};
`;

const ThreeValues = (props: ThreeValuesProps) => {
  const { data, noMainValue = false, help, showHelp, /*themeProperties*/ } = props;
  const { secondaryValues, primaryValue } = data;
  // const {} = themeProperties || { } as ThreeValuesThemeProps;

  return (
    <StyledWrapper>
      <StyledPrimaryValueWrapper hidden={noMainValue}>
        <ValueBlockSimple
          valueSize={'large'}
          valueVariant={'h2'}
          label={primaryValue.label}
          value={primaryValue.formattedValue}
          afterValueContent={
            <>
              {!!showHelp && !!help && help.some(({ position }) => position === 1) &&
                <HelpTooltipButton
                  anchorType={'iconButton'}
                  helpId={(help.find(({ position }) => position === 1) as { helpId: number }).helpId}
                />
              }
            </>
          }
        />
      </StyledPrimaryValueWrapper>
      <StyledSecondaryValueWrapper>
        <ValueBlockSimple
          valueSize={'small'}
          valueVariant={'h2'}
          label={secondaryValues[0].label}
          value={secondaryValues[0].formattedValue}
          afterValueContent={
            <>
              {!!showHelp && !!help && help.some(({ position }) => position === 2) &&
                <HelpTooltipButton
                  anchorType={'iconButton'}
                  helpId={(help.find(({ position }) => position === 2) as { helpId: number }).helpId}
                />
              }
            </>
          }
        />
      </StyledSecondaryValueWrapper>
      <StyledSecondaryValueWrapper>
        <ValueBlockSimple
          valueSize={'small'}
          valueVariant={'h2'}
          label={secondaryValues[1].label}
          value={secondaryValues[1].formattedValue}
          afterValueContent={
            <>
              {!!showHelp && !!help && help.some(({ position }) => position === 3) &&
                <HelpTooltipButton
                  anchorType={'iconButton'}
                  helpId={(help.find(({ position }) => position === 3) as { helpId: number }).helpId}
                />
              }
            </>
          }
        />
      </StyledSecondaryValueWrapper>
    </StyledWrapper>
  )
}

export default ThreeValues;
