import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

interface PinnedSVGIconProps extends SvgIconProps { }

const PinnedSVGIcon = (props: PinnedSVGIconProps) => {
  return (
    <SvgIcon {...props}>
        <path d='M2 22l8-5-3-3z'></path>
        <path d='M14.8 2c-0.5 0.8-0.8 1.8-0.8 2.8 0 0.1 0 0.1 0 0.2l-4.1 4.1c-0.5 0-0.9-0.1-1.4-0.1-1.3 0-2.5 0.4-3.5 1l9 9c0.6-1 1-2.2 1-3.5 0-0.5-0.1-0.9-0.1-1.4l4.1-4.1c0.1 0 0.1 0 0.2 0 1 0 2-0.3 2.8-0.8l-7.2-7.2z'></path>
    </SvgIcon>
  );
};

export default PinnedSVGIcon;
