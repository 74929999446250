import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

interface PercentageSVGIconProps extends SvgIconProps { }

const PercentageSVGIcon = (props: PercentageSVGIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d="M18.5 3.5L20.5 5.5L5.5 20.5L3.5 18.5L18.5 3.5M7 4C8.66 4 10 5.34 10 7C10 8.66 8.66 10 7 10C5.34 10 4 8.66 4 7C4 5.34 5.34 4 7 4M17 14C18.66 14 20 15.34 20 17C20 18.66 18.66 20 17 20C15.34 20 14 18.66 14 17C14 15.34 15.34 14 17 14M7 6C6.45 6 6 6.45 6 7C6 7.55 6.45 8 7 8C7.55 8 8 7.55 8 7C8 6.45 7.55 6 7 6M17 16C16.45 16 16 16.45 16 17C16 17.55 16.45 18 17 18C17.55 18 18 17.55 18 17C18 16.45 17.55 16 17 16Z" />
    </SvgIcon>
  );
};

export default PercentageSVGIcon;