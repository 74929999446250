import Chart from 'ppd-library/charts/Chart';
import { scales } from 'ppd-library/charts/chartUtils';
import { LineConnected } from 'ppd-library/charts/molecules/LineConnected';
import { AxisBottom as HorizontalAxis } from 'ppd-library/charts/organisms/Axis';
import { BarHorizontalStacked } from 'ppd-library/charts/organisms/BarHorizontalStacked';
import React, { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';

import {
  LineThresholdVertical,
} from 'ppd-library/charts/molecules/LineThreshold';

import { reduceObjectByKey } from '../../../utils';
import { ComponentBaseProps } from '../ComponentBaseProps';
import { StackedLinedLabeledBarDataModel } from './stackedLinedLabeledBarTransformer';

import { Tooltip } from 'ppd-library/charts//organisms/Tooltip';
import { getSVGMousePosition, useTooltipPosition, TooltipTemplate } from '../tooltip-utils';

import { Legend, useLegend } from '../legend-utils';

export interface StackedLinedLabeledBarThemeProps {
  barColors?: string[];
  lineColor?: string;
  axisColors?: [string, string];
  xTresholdLineColor?: string,
  xTresholdBackgroundColor?: string
}
interface StackedLinedLabeledBarProps extends ComponentBaseProps<StackedLinedLabeledBarThemeProps, StackedLinedLabeledBarDataModel> {
  primaryLabel?: ReactNode;
  barHeight?: number;
  barYPosition?: number;
  showThreshold?: boolean;
  showTooltip?: boolean;
}

export interface Color {
  [key: string]: string
}

const StyledWrapper = styled.div`
  position: relative;
  flex: 1;
`;

const StyledChartHolder = styled.div``;

const StyledLabel = styled.tspan`
  fill: #2b4899;
  font-size: 0.8rem;
`;

const StackedLinedLabeledBar = (props: StackedLinedLabeledBarProps) => {
  const {
    id,
    data,
    // help,
    // showHelp,
    width = 400,
    height: originalHeight = 200,
    barHeight = 24,
    barYPosition = 8,
    tooltipOptions,
    showThreshold = true,
    showTooltip = true,
    margins = { left: 0, right: 0, top: 60, bottom: 60 },
    themeProperties,
    legendOptions = {},
  } = props;

  const {
    barColors = ['#2b4899', '#266295', 'orange', '#80A3C1', 'gray', '#DFE8F0'],
    lineColor = '#636363',
    axisColors = ['#212121', '#0072D6'],
    xTresholdLineColor = 'orange',
    xTresholdBackgroundColor = 'transparent'
  } = themeProperties || {} as StackedLinedLabeledBarThemeProps;

  const { barModel, xThreshold } = data;

  const barKeys = Object.keys(barModel);
  const barRawValues = barKeys.map((key) => barModel[key].primaryValue.rawValue);
  const barMaxValue = barRawValues.reduce((a: number, b: number) => a + b);

  const barKeyValuePairs: any = {};
  barKeys.forEach((key: string) => barKeyValuePairs[key] = barModel[key].primaryValue.rawValue);

  // Create color scale object.
  const colorScaleValues: Color = {};
  barKeys.map((key: string, index: number) => colorScaleValues[key] = barColors[index] ? barColors[index] : '#000000');

  const xScaleTop = scales.numericXScale(0, barMaxValue, width, margins.left, margins.right);
  const colorScale = scales.colorScale(colorScaleValues);

  const handleMouseMove = (event: React.MouseEvent<Element, MouseEvent>, index: number, data: StackedLinedLabeledBarDataModel) => {
    const { x, y } = getSVGMousePosition(event as React.MouseEvent<SVGElement, MouseEvent>);
    setTooltipPosition({ visible: true, x, y, index, data });
  };

  const handleMouseLeave = () => {
    setTooltipPosition({ ...tooltipPosition, visible: false });
  };

  const { tooltipPosition, setTooltipPosition } = useTooltipPosition<StackedLinedLabeledBarDataModel>();
  const legendElementRef = React.createRef<HTMLDivElement>();
  const { legendHeight } = useLegend(originalHeight, legendElementRef);
  const height = originalHeight - legendHeight;


  const [initiated, setInitiated] = useState(false);
  useEffect(() => {
    setInitiated(true);
  }, []);

  // TODO: Make 1 function with generics instead of any
  // DoubleStackedLinedLabeledbarDataModel
  // DoubleStackedLinedLabeledSecondBarDataModel
  function getBarData(initiated: boolean, barModel: any) {
    const dataObj: any = {};
    const barKeys = Object.keys(barModel);

    barKeys.forEach((key: string) => dataObj[key] = initiated
      ? barModel[key].primaryValue
        ? barModel[key].primaryValue.rawValue
        : barModel[key].rawValue
      : 0
    );
    return dataObj;
  }

  const getFixedPosition = (index: number) => 100 / barKeys.length * index;
  const fixedTicks = barKeys.map((_key, index) => getFixedPosition(index));
  const xFixedScale = scales.numericXScale(0, 100, width, margins.left, margins.right);

  return (
    <StyledWrapper id={`${id}-stacked-lined-labeled-bar`}>
      <StyledChartHolder>
        <Chart
          width={width}
          height={height}
          preserveAspectRatio={'none'}
        >
          <g transform={`translate(0, ${barYPosition})`}>
            <HorizontalAxis
              y={0}
              scale={xFixedScale}
              id={`${id}-horizontal-axis-bar-1`}
              tickValues={fixedTicks}
              tickTextAnchor={'start'}
              chartHeight={0}
              tickFormat={(_, index) => `${barModel[barKeys[index]].primaryValue.formattedValue}`}
              theme={{ colors: { tick: axisColors[0] } }}
            />
            <HorizontalAxis
              scale={xFixedScale}
              id={`${id}-horizontal-axis-bar-2`}
              tickValues={fixedTicks}
              tickTextAnchor={'start'}
              chartHeight={0}
              y={16}
              tickFormat={(_, index) => `${barModel[barKeys[index]].secondaryValue.formattedValue}`}
              theme={{ colors: { tick: axisColors[1] } }}
            />
            <BarHorizontalStacked
              y={72}
              xScale={xScaleTop}
              height={barHeight}
              colorScale={colorScale}
              keys={barKeys}
              id={`${id}-bar-horizontal-stacked-top`}
              data={{
                key: `${id}-bar-horizontal-stacked-top`,
                ...getBarData(initiated, barModel)
              }}
              mouseMove={(ev) => handleMouseMove(ev, 0, data)}
              mouseLeave={handleMouseLeave}
            />
            <g transform={'translate(1, 70)'}>
              {
                barKeys.map((key, index) => (
                  <LineConnected
                    key={key}
                    id={`poly-${key}-top`}
                    animationDelay={1000}
                    color={lineColor}
                    fromPoint={{ x: xScaleTop(reduceObjectByKey(barKeyValuePairs, barKeys, key, false)), y: 0 }}
                    toPoints={[
                      { x: xScaleTop(reduceObjectByKey(barKeyValuePairs, barKeys, key, false)), y: -6 },
                      { x: xFixedScale(fixedTicks[index]), y: -28 }
                    ]}
                  />
                ))
              }
            </g>

            {!!showThreshold && !!xThreshold && xThreshold.value != null &&
              <LineThresholdVertical
                lineWidth={1}
                showCircle={false}
                label={
                  <>
                    {
                      xThreshold.label &&
                      <StyledLabel x={xThreshold.value > 75 ? 0 : 7} dy={0}>
                        {xThreshold.label}
                      </StyledLabel>
                    }
                    {
                      xThreshold.label2 &&
                      <StyledLabel x={xThreshold.value > 75 ? 0 : 7} dy={xThreshold.label ? 16 : 0}>
                        {xThreshold.label2} 
                        {/* TODO: doe dit net als stackedLinedlabelBarDouble */}
                      </StyledLabel>
                    }
                  </>
                }
                lineHeight={66}
                id={`${id}-line-vertical-threshold`}
                y={74}
                labelPadding={[2, 3, 2, 3]}
                theme={{
                  lineColor: xTresholdLineColor,
                  backgroundColor: xTresholdBackgroundColor
                }}
                x={initiated ? xFixedScale(xThreshold.value) : 0}
                labelPos={[xThreshold.value > 75 ? 'left' : 'right', 'bottom']}
              />
            }
          </g>
        </Chart>
        <Tooltip
          top={tooltipPosition.y}
          left={tooltipPosition.x}
          visible={showTooltip && tooltipPosition.visible}
          theme={{
            transition: false
          }}
        >
          {() => {
            const {
              barModel
            } = tooltipPosition.data as StackedLinedLabeledBarDataModel;

            return (
              <TooltipTemplate
                tooltipOptions={tooltipOptions}
                values={
                  Object.keys(barModel).map((key: string) => {
                    const { primaryValue, secondaryValue } = barModel[key];
                    return (
                      {
                        label: key,
                        formattedValue: `${primaryValue.formattedValue} (${secondaryValue.formattedValue})`,
                        colors: [colorScale(key)]

                      }
                    )
                  })
                }
              />
            );
          }}
        </Tooltip>
        <Legend
          ref={legendElementRef}
          legendOptions={{ orientation: 'horizontal', ...legendOptions }}
          values={Object.keys(data.barModel).map((key) => {
            return {
              label: key,
              colors: [colorScale(key)]
            };
          })}
        />
      </StyledChartHolder>
      {/* {
        !!showHelp && helpId &&
        <StyledHelpButtonWrapper>
          <HelpTooltipButton
            helpId={helpId}
            anchorType={'iconButton'}
          />
        </StyledHelpButtonWrapper>
      } */}

    </StyledWrapper>
  )
};

export default StackedLinedLabeledBar;
