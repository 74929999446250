import { TransformFunction, TransformOptionsBase } from '../transform-utils';
import { calcNumberOfRows, calcNumberOfColumns, padLeftWithZeros } from '../transform-utils';
import { formatValue } from '../../../utils';
import * as allFormatters from '../../../utils/formatters';

interface RD {
  waarde: number;
  label_1: string;
  label_4: string;
  eenheid: string;
}

interface RawData {
  [key: string]: RD;
}

export interface BarChartCategoryTransformOptions extends TransformOptionsBase {
  /**
   * Don't include the bars with these labels (label_4) in the transformed data.
   */
  omitBars?: string | string[];
  /**
   * Indicate the primary bar by bar label (label_4).
   * When defined, the formattedPrimaryValue property of a group will be filled.
   */
  primaryBar?: string;
  /**
   * Indicate the secondary bar by bar label (label_4).
   * When defined, the formattedSecondaryValue property of a group will be filled.
   */
  secondaryBar?: string;
  /**
   * reverse the barorder of the transformeddata
   */
  reverse?: boolean;
}

export interface BarChartCategoryDataItem {
  label: string;
  rawValue: number;
  formattedValue: string;
}
export interface BarChartCategoryDataGroup {
  groupLabel: string;
  /**
   * Optional value will be shown to the right op the group
   */
  formattedPrimaryValue?: string;
  /**
   * Optional value will be shown to the right op the group
   */
  formattedSecondaryValue?: string;
  bars: BarChartCategoryDataItem[];
}
export interface BarChartCategoryDataModel {
  groups: BarChartCategoryDataGroup[];
}

const barChartCategoryTransformer: TransformFunction<BarChartCategoryDataModel, BarChartCategoryTransformOptions> = (
  rawData: RawData,
  onError,
  options
) => {

  const {
    reverse,
    primaryBar,
    secondaryBar,
    omitBars: originalOmitBars,
    formatters = {},
    formattersOptions = {}
  } = options || {} as BarChartCategoryTransformOptions;

  const omitBars = !!originalOmitBars ?
    Array.isArray(originalOmitBars) ? originalOmitBars : [originalOmitBars] :
    [];

  let result: BarChartCategoryDataModel = {
    groups: []
  };

  let guard = true;
  const keys = Object.keys(rawData);
  const numberOfRows = calcNumberOfRows(keys);
  const numberOfColumns = calcNumberOfColumns(keys);

  for (let rowNumber = 0; rowNumber < numberOfRows; rowNumber++) {
    let colObjects: RD[] = [];

    for (let colNumber = 0; colNumber < numberOfColumns; colNumber++) {
      // TODO: get colobjects util function?
      let row = padLeftWithZeros(rowNumber, 2);
      let col = padLeftWithZeros(colNumber, 2);

      let key = `val${row}_${col}`;
      const item = rawData[key];

      colObjects.push(item);
    }

    let group: BarChartCategoryDataGroup = {
      groupLabel: '',
      formattedPrimaryValue: '',
      formattedSecondaryValue: '',
      bars: []
    };

    colObjects.forEach(({ label_1, label_4, waarde, eenheid }) => {
      const mainValueFormatterName = formatters['mainValue'];
      const mainValueFormatterOptions = formattersOptions['mainValue'];
      const mainValueFormatter = (allFormatters as any)[mainValueFormatterName];

      const secondaryValueFormatterName = formatters['secondaryValue'];
      const secondaryValueFormatterOptions = formattersOptions['secondaryValue'];
      const secondaryValueFormatter = (allFormatters as any)[secondaryValueFormatterName];

      // TODO: het feit dat je een bar moet omitten..
      let omitBar = false;

      if (!group.groupLabel) {
        group.groupLabel = label_1;
      }

      if (!!label_4) {
        if (!group.formattedPrimaryValue && !!primaryBar && primaryBar.toLowerCase() === label_4.toLowerCase()) {
          group.formattedPrimaryValue = mainValueFormatter ? mainValueFormatter(waarde, mainValueFormatterOptions) : formatValue(waarde, eenheid as ValueUnit, mainValueFormatterOptions)
        }

        if (!group.formattedSecondaryValue && !!secondaryBar && secondaryBar.toLowerCase() === label_4.toLowerCase()) {
          group.formattedSecondaryValue = secondaryValueFormatter ? secondaryValueFormatter(waarde, secondaryValueFormatterOptions) : formatValue(waarde, eenheid as ValueUnit, secondaryValueFormatterOptions)
        }

        omitBar = !omitBars.some((label) => label.toLowerCase() === label_4.toLowerCase());
      }

      if (waarde != null && omitBar) {
        group.bars.push({
          rawValue: waarde,
          label: label_4,
          formattedValue: mainValueFormatter ? mainValueFormatter(waarde) : formatValue(waarde, eenheid as ValueUnit)
        });
      }
    });

    if (group.bars.length !== 0) {
      guard = false;

      if(!!reverse) {
        group.bars.reverse();
      }

      result.groups.push(group);
    }
  }

  if (guard) {
    onError('nodata');
    return null;
  }
  
  return result;
};

export default barChartCategoryTransformer;