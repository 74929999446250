import { scaleBand } from 'd3-scale';
import { scales } from 'ppd-library/charts/chartUtils';
import { BarHorizontalPointed } from 'ppd-library/charts/organisms/BarHorizontalPointed';
import {
  ValueBlockSimple,
  ValueBlockSimpleProps,
} from 'ppd-library/components/molecules/ValueBlockSimple';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { ComponentBaseProps } from '../ComponentBaseProps';
import { ValuesGradeMeterDataModel } from './valuesGradeMeterTransformer';
import Chart from 'ppd-library/charts/Chart';
import { pxToRem } from '../../../utils/converters';

export interface ValuesGradeMeterThemeProps {
  /**
   * Color that is used when no or not enough barColors are supplied.
   * @default #2b4899
   */
  barColor?: string;
  /**
   * A color for every position that the mainValue can be compared to other bars that might have a label.
   * @default ['#2b4899','#D90000']
   */
  barColors?: string[];
}
export interface ValuesGradeMeterProps extends ComponentBaseProps<ValuesGradeMeterThemeProps, ValuesGradeMeterDataModel> {
  /**
   * @default false
   */
  showMainValueLabel?: boolean;
}

const StyledWrapper = styled.div`
  flex: 1;
  display: flex;
  position: relative;
  align-self: center;
` as React.ComponentType<React.HTMLProps<HTMLDivElement>>;

const StyledValueBlockSimpleWrapper = styled.div`
  margin-left: ${pxToRem(16)}; /* TODO: theme */
` as React.ComponentType<React.HTMLProps<HTMLDivElement>>;

const StyledValueBlockSimple = styled(({ marginTop, valueOpacity, ...valueBlockSimpleProps }) => <ValueBlockSimple {...valueBlockSimpleProps} />)`
  transition: opacity 1000ms 1000ms; /* TODO: theme */
  margin-top: ${({ marginTop }) => pxToRem(marginTop)};
  opacity: ${({ valueOpacity }) => valueOpacity};
  &&& {
    .MuiTypography-root {
   
    }
  }
` as React.ComponentType<{ marginTop: number; valueOpacity: number; } & ValueBlockSimpleProps>;

const ValuesGradeMeter = (props: ValuesGradeMeterProps) => {
  const {
    id,
    data,
    width: tileWidth = 300,
    showMainValueLabel = false,
    // height: chartHeight = 110,
    margins = { left: 0, right: 0, top: 0, bottom: 0 },
    // showHelp,
    // topValueHelpId,
    // bottomValueHelpId
    themeProperties
  } = props;

  const { mainValue, bars } = data;
  const { barColor = '#2b4899', barColors = ['#2b4899', '#D90000'] } = themeProperties || {} as ValuesGradeMeterThemeProps;

  const chartHeight = 130;
  const chartWidth = tileWidth / 2;

  const numberOfBars = bars.length;
  const maxXDomain = numberOfBars;
  const yScaleDomain = [...Array(numberOfBars)].map((_, index) => index);

  const xScale = scales.numericXScale(-maxXDomain, maxXDomain, chartWidth, margins.left, margins.right);
  const yScale = scaleBand<number>().domain(yScaleDomain).range([margins.top, chartHeight - margins.bottom]).padding(0.15); // TODO: scales.
  const barHeight = yScale.bandwidth();

  const [initiated, setInitiated] = useState(false);
  useEffect(() => {
    setInitiated(true);
  }, []);

  const valueOpacity = initiated ? 1 : 0;
  let mainValueBarFill = barColor;
  const explicitLabels = bars.filter(({ label }) => !!label);

  explicitLabels.forEach(({ rawValue }, index) => {
    if (rawValue < Math.floor(mainValue.rawValue)) {
      mainValueBarFill = barColors[index + 1] || barColor;
    }
  });

  return (
    <StyledWrapper>
      <Chart
        width={chartWidth}
        height={chartHeight}
        preserveAspectRatio={'none'}
      >
        {bars.map((bar, index) => {
          const { rawValue, formattedValue, label } = bar;
          const y = yScale(index) as number;
          const barWidth = xScale(index + 1);

            return (
              <BarHorizontalPointed
                x={0}
                y={y}
                width={barWidth}
                height={barHeight}
                id={`${id}-${formattedValue}`}
                key={`${id}-${formattedValue}`}
                animationDelay={100 * (numberOfBars - index)}
                label={!!label ? `${label}: ${formattedValue}` : undefined}
                fill={rawValue === Math.floor(mainValue.rawValue) ? mainValueBarFill : '#EEEEEE'}
                labelFontColor={rawValue === Math.floor(mainValue.rawValue) ? '#ffffff' : '#212121'}
              />
            );
        })}
      </Chart>
      <StyledValueBlockSimpleWrapper>
        <StyledValueBlockSimple
          // marginTop={-24 + (2 * barHeight)}
          marginTop={0}
          valueSize={'xlarge'}
          color={mainValueBarFill}
          valueOpacity={valueOpacity}
          value={mainValue.formattedValue}
          label={showMainValueLabel ? mainValue.label : undefined}
        />
      </StyledValueBlockSimpleWrapper>
    </StyledWrapper>
  )
};

export default ValuesGradeMeter;
