import { ScaleBand } from 'd3-scale';
import { Tooltip } from 'ppd-library/charts//organisms/Tooltip';
import { Bar } from 'ppd-library/charts/atoms/Bar';
import { Circle } from 'ppd-library/charts/atoms/Circle';
import { Line } from 'ppd-library/charts/atoms/Line';
import Chart from 'ppd-library/charts/Chart';
import { scales } from 'ppd-library/charts/chartUtils';
import { Area } from 'ppd-library/charts/molecules/Area';
import { LineConnected } from 'ppd-library/charts/molecules/LineConnected';
import { LineThresholdVertical } from 'ppd-library/charts/molecules/LineThreshold';
import { AxisBottom, AxisLeft } from 'ppd-library/charts/organisms/Axis';
import { HorizontalGridLines, VerticalGridLines } from 'ppd-library/charts/organisms/GridLines';
import React from 'react';
import styled from 'styled-components';

import muiTheme from '../../../constants/theme';
import * as allFormatters from '../../../utils/formatters';
import { ComponentBaseProps, Margins } from '../ComponentBaseProps';
import { Legend, useLegend } from '../legend-utils';
import { useTooltipPosition } from '../tooltip-utils';
import TooltipTemplate from '../tooltip-utils/TooltipTemplate';
import { LineChartDataModel, LineChartPointDataModel, LineChartLineDataModel } from './lineChartTransformer';


export interface LineChartThemeProps {
  colors?: string[];
  axisAnimation?: { duration: number; delay: number}

}
export interface LineChartProps extends ComponentBaseProps<LineChartThemeProps, LineChartDataModel> {
  /**
   * Set the y axis maximum to a fixed number.
   * yMax overrides yMinMax and fixedTicks.
   */
  yMax?: number;
  /**
   * Set the yMax when dense.
   */
  denseYMax?: number;
  /**
   * Set the y axis minimum to a fixed number.
   * yMin overrides yFixedTicks.
   */
  yMin?: number;
  /**
  * Set the yMax when dense.
  */
  denseYMin?: number;
  /**
  * Set the y axs maximum value to a minimum value, so it can go higher but not lower.
  */
  yMinMax?: number;
  /**
   * Set fixed numeric ticks on the y-axis.
   * yFixedTicks overrides yMinMax.
   */
  yFixedTicks?: number[];
  /**
   * Sets the y-axis minimum value based on the data
   * Will be default true if 'dense' is set to true
   * @default false
   */
  yMinCalculated?: boolean;
  /**
   * Sets the y-axis minimum value based on the data
   * Will be default true if 'dense' is set to true
   * @default false
   */
  yMaxCalculated?: boolean;
  // /**
  //  * Sets the y-axis minimum and maximum values based on the data
  //  * Will be default true if 'dense' is set to true
  //  * @default false
  //  */
  // yMinMaxCalculated?: boolean;
  /**
   * @default 10
   */
  yNumberOfTicks?: number;
  /**
   * Set a threshold in height pixels to show half the number of y ticks (5).
   * Will not be used when 'yNumberOfTicks' is explicitly set.
   */
  yHalfNumberOfTicks?: number;
  /**
   * Calculates the number of ticks if the difference in min/max is less than 8
   * @default 8+
   */
  calculateYNumberOfTicks?: boolean;
  /**
   * @default true
   */
  showThreshold?: boolean;
  /**
   * When true, will hide the y axis and compress the x axis.
   * When number, dense will be true when the width property < the supplied number in pixels.
   * @default false
   */
  dense?: boolean | number;
  /**
   * Margins specific for the 'dense' state
   * Will fallback to the default margins is not set
   */
  denseMargins?: Margins;
  /**
   * show area color when dense.
   * When true, shows an area color for all lines.
   * when a number, only show an area color for one line with column number (start at 1).
   * @default true
   */
  denseArea?: boolean | number;
  /**
   * show dense y labels (max value, min value and current value for each line)
   * @default true
   */
  denseYLabels?: boolean;
  /**
   * Merge the tooltips if there are more than one line and show it at the position of 'scaleY(maxValue)'.
   * @default false
   */
  mergeTooltip?: boolean;
  /**
   * Hide lines by line index
   * @default false
   */
  omittedLines?: boolean | number[];
  /**
   *  When passing on this value(s), the max and min values are calculated from the supplied line index
   */
  omittedLinesScopedYMinMaxValues?:  number[]
  /**
   * shows the horizontal zero line
   * @default false
   */
  showZeroLine?: boolean;
  /**
   * adds some space between the yAxis and the graph
   * @default 0
   */
  yScaleRightOffset?: number;
}

const StyledWrapper = styled.div`
  position: relative;
  /* flex: 1; */
`;

const StyledChartHolder = styled.div`
`;

const StyledZeroLineHolder = styled(({...divProps }) => <g {...divProps} />)`
  transition: transform 1000ms ease-in-out;
`as React.ComponentType<React.SVGProps<SVGElement>>;


const circleRadius = 3;

const LineChart = (props: LineChartProps) => {
  const {
    id,
    data,
    yMax,
    yMin,
    // help,
    // showHelp,
    yMinMax,
    denseYMax,
    denseYMin,
    yFixedTicks,
    tooltipOptions,
    yNumberOfTicks: originalYNumberOfTicks,
    width = 400,
    dense = false,
    formatters = {},
    denseArea = true,
    legendOptions = {},
    denseYLabels = true,
    showZeroLine = false,
    showThreshold = true,
    omittedLines = [],
    mergeTooltip = false,
    themeProperties,
    formattersOptions = {},
    yScaleRightOffset = 0,
    yHalfNumberOfTicks = 260,
    calculateYNumberOfTicks = false,
    height: originalHeight = 100,
    omittedLinesScopedYMinMaxValues,
    denseMargins = {} as Margins,
    margins: originalMargins = {} as Margins
  } = props;

  const denseXindent = 20;

  const isDense = dense === true || (typeof dense === 'number' && width < dense);
  let {
    yMinCalculated = isDense ? true : false,
    yMaxCalculated = isDense ? true : false
  } = props;

  const { left = 50, right = 5, top = 10, bottom = showThreshold ? 60 : 40 } = isDense ? { ...originalMargins, ...denseMargins } : originalMargins;
  let margins = { left, top, right, bottom };

  margins.bottom = isDense ? 30 + denseXindent : margins.bottom;

  const { lines, xThreshold } = data;
  const { colors = [], axisAnimation = { duration: 1000, delay: 1000} } = themeProperties || {} as LineChartThemeProps;
  const defaultColor = muiTheme.palette.primary.main;

  const yAxisTickFormatterName = formatters['yAxisTick'];
  const yAxisTickFormatter = (allFormatters as any)[yAxisTickFormatterName] || allFormatters.formatNumber;
  const yAxisTickFormatterOptions = formattersOptions['yAxisTick'];


  let graphLines = lines;
  if(omittedLinesScopedYMinMaxValues !== undefined && omittedLinesScopedYMinMaxValues.length > 0) {
    graphLines = [...graphLines].filter((_line: LineChartLineDataModel, index: number) => omittedLinesScopedYMinMaxValues.includes(index + 1))
  }

  const maxValues = graphLines.map(({ points }) => {
    let rawPointValues = points.map(({ rawValue }) => rawValue);
      // When zero line, add's 0 as max value in case of negative numbers
        showZeroLine && rawPointValues.push(0);
    return Math.max(...rawPointValues)
  });

  const minValues = graphLines.map(({ points }) => {
    let rawPointValues = points.map(({ rawValue }) => rawValue).filter((value: number) => value != null);
        showZeroLine && rawPointValues.push(0);
    return Math.min(...rawPointValues);
  });


  const rawMaxValue = Math.max(...maxValues);
  const rawMinValue = Math.min(...minValues);

  // yFixedTicks or calculate based on data with a minimum of 0
  let minValue = !!yFixedTicks ? Math.min(...yFixedTicks) : Math.min(...minValues) > 0 ? 0 : Math.min(...minValues);
  // yMin overrides yfixedTicks and calculated
  minValue = yMin != null ? yMin : minValue;
  // yMax when dense
  minValue = !!denseYMin && isDense ? denseYMin : minValue;
  // yMin calculate based on data
  minValue = yMinCalculated ? rawMinValue : minValue;

  // yMinMax or calculate based on data
  let maxValue = !!yMinMax && yMinMax > rawMaxValue ? yMinMax : rawMaxValue;
  // yFixedTicks overrides yMinMax and calculated
  maxValue = !!yFixedTicks ? Math.max(...yFixedTicks) : maxValue;
  // yMax overrides yMinMax, calculated and fixedTicks
  maxValue = yMax != null ? yMax : maxValue;
  // yMax when dense
  maxValue = !!denseYMax && isDense ? denseYMax : maxValue;
  // yMax calculate based on data
  maxValue = yMaxCalculated ? rawMaxValue : maxValue;

  const legendElementRef = React.createRef<HTMLDivElement>();
  const { legendHeight } = useLegend(originalHeight, legendElementRef);
  const height = originalHeight - legendHeight;

  // Scaling
  let yNumberOfTicks = originalYNumberOfTicks || 10;
  yNumberOfTicks = height < yHalfNumberOfTicks ? Math.ceil(yNumberOfTicks / 2) : yNumberOfTicks;

  // If the difference in min / max is less than 8, halve the number of ticks.
  // If the difference is 0 or less then always 1 tick
  if(calculateYNumberOfTicks) {
    const difference = Math.abs(minValue - maxValue);
    yNumberOfTicks = difference < 8 ? difference  <= 0 ? 1 : Math.floor(difference / 2) : yNumberOfTicks;
  }


  let fixedYAxisScale: ScaleBand<string> | undefined = undefined;
  const xTicks = lines[0].points.map((_, index) => `${index}`);
  const xScale = scales.stringXScale(xTicks, width, margins.left, margins.right, 0);
  const yScale = scales.numericYScale(minValue, maxValue, height, margins.bottom, margins.top).nice();

  if (!!yFixedTicks) {
    fixedYAxisScale = scales.stringYScale(yFixedTicks.map((tick) => `${tick}`).reverse(), height, margins.top, margins.bottom, 0);
  }

  const linesWithXYPoints = lines.map(({ points: dataPoints }) => {
    const xyPoints: { y: number, x: number, dataPoint: LineChartPointDataModel }[] = [];

    dataPoints.forEach(({ rawValue, ...rest }, pointIndex) => {
      if (rawValue != null) {
        xyPoints.push({
          dataPoint: { rawValue, ...rest },
          y: Math.floor(yScale(rawValue)),
          x: Math.floor(xScale(`${pointIndex}`) as number),
        });
      } else {
        xyPoints.push({
          y: Math.floor(yScale(0)),
          x: Math.floor(xScale(`${pointIndex}`) as number),
          dataPoint: { rawValue, ...rest }
        });
      }
    });

    return { xyPoints };
  });

  const checkLineVisiblity = (index: number) => {
    const indexArr = typeof omittedLines !== 'boolean' ? omittedLines : [];
    const lineIndexes = [...indexArr].includes(index + 1);

    return typeof omittedLines === 'boolean' ? omittedLines : lineIndexes;
  }

  const handleMouseEnter = function (_event: React.MouseEvent<SVGGElement, MouseEvent> | React.TouchEvent, x: number, index: number) {
    setTooltipPosition({ visible: true, x, y: 0, index, data: null });
  };

  const { tooltipPosition, setTooltipPosition } = useTooltipPosition();

  return (
    <StyledWrapper>
      <StyledChartHolder>
        <Chart
          width={width}
          height={height}
          preserveAspectRatio={'none'}
          onMouseLeave={() => setTooltipPosition({ ...tooltipPosition, visible: false })}
          onTouchEnd={() => setTooltipPosition({ ...tooltipPosition, visible: false })}
        >
          {!isDense &&
            <>
              <HorizontalGridLines
                x={margins.left}
                numberOfTicks={yNumberOfTicks}
                id={`${id}-horizontal-grid-lines`}
                scale={fixedYAxisScale ? fixedYAxisScale : yScale}
                tickValues={!!yFixedTicks ? yFixedTicks : undefined}
                lineLength={width - margins.left - margins.right - (xScale.bandwidth() / 2)}
              />
              <VerticalGridLines
                scale={xScale}
                //  offset={xScaleTickOffset}
                y={height - margins.bottom}
                id={`${id}-vertical-grid-lines`}
                lineLength={height - margins.top - margins.bottom}
              />
              {!!showZeroLine && <StyledZeroLineHolder transform={`translate(0, ${yScale(0) })`}>
                <Line
                  width={1}
                  id={`${id}-line-horizontal`}
                  color={'#909090'}
                  from={{ x: margins.left, y: 0}}
                  to={{ x: linesWithXYPoints[0].xyPoints[linesWithXYPoints[0].xyPoints.length - 1].x, y: 0 }}
                />
                </StyledZeroLineHolder>

                          }
            </>
          }
          {linesWithXYPoints.map(({ xyPoints }, lineIndex) => {
            const [fromPoint = { x: 0, y:0, dataPoint : {} as LineChartPointDataModel}, ...toPoints] = xyPoints.filter(({ dataPoint }) => dataPoint.rawValue != null);
            const areaPoints = [...toPoints];

            const hasToPoints = toPoints.length > 0;
            if (hasToPoints) {
              // draw past the last circle
              areaPoints.push({ ...toPoints[toPoints.length - 1], x: toPoints[toPoints.length - 1].x + circleRadius });
              // draw to the x axis
              areaPoints.push({ ...toPoints[toPoints.length - 1], x: toPoints[toPoints.length - 1].x + circleRadius, y: yScale(minValue > 0 ? minValue : 0) });
              // draw to the bottom left, past the first circle
              areaPoints.push({ ...toPoints[toPoints.length - 1], x: (xScale(xTicks[0]) as number) - circleRadius, y: yScale(minValue > 0 ? minValue : 0) });
            }
            const allRawValues: number[] = xyPoints.map((point) => point.dataPoint.rawValue);
            const lastIndex = xyPoints.reduceRight((prev, xyPoint, index) => {
              if (prev > -1) return prev;
              if (xyPoint.dataPoint.rawValue !== null) return index;
              return -1;
            }, -1);

            return (
              <React.Fragment key={`${id}-line-${lineIndex}`}>
                {isDense && (denseArea === true || denseArea === lineIndex + 1) && hasToPoints &&
                  <>
                    <defs>
                      <linearGradient id={`lineshadow-${id}-${lineIndex}`} x2={'0'} y2={'1'}>
                        <stop offset={'0%'} stopColor={colors[lineIndex] || defaultColor} stopOpacity={'0.1'} />
                        <stop offset={'50%'} stopColor={colors[lineIndex] || defaultColor} stopOpacity={'0'} />
                      </linearGradient>
                    </defs>

                    <Area
                      toPoints={areaPoints}
                      id={`${id}-shadow-area`}
                      fill={checkLineVisiblity(lineIndex) ? 'transparent' : `url(#lineshadow-${id}-${lineIndex})`}
                      fromPoint={{ ...fromPoint, x: fromPoint.x - circleRadius }}
                    />
                  </>
                }
                <LineConnected
                  width={checkLineVisiblity(lineIndex) ? 0 : 1}
                  toPoints={toPoints}
                  fromPoint={fromPoint}
                  id={`${id}-line-${lineIndex}`}
                  color={colors[lineIndex] || defaultColor}
                />
                {xyPoints.map(({ x, y, dataPoint }, pointIndex) => {
                  const lowestValueFirstOccurenceIndex = allRawValues.indexOf(rawMinValue);
                  const heighestValueLastOccurenceIndex = allRawValues.lastIndexOf(rawMaxValue);
                  const showLowestCircle = isDense && lowestValueFirstOccurenceIndex === pointIndex;
                  const showHeighestCircle = isDense && heighestValueLastOccurenceIndex === pointIndex;
                  const hasRawValue = dataPoint.rawValue != null;
                  const tooltipVisible = tooltipPosition.visible && tooltipPosition.index === pointIndex && hasRawValue;

                  const showLastValueLabel = isDense && lastIndex === pointIndex;
                  const showMaximumValueLabel = isDense && yMaxCalculated && heighestValueLastOccurenceIndex === pointIndex;
                  const showMinimumValueLabel = isDense && yMinCalculated && lowestValueFirstOccurenceIndex === pointIndex && rawMaxValue !== rawMinValue;

                  return (
                    <g
                      key={`${id}-${lineIndex}-${dataPoint.xLabel}-${pointIndex}`}
                      onMouseEnter={(event) => handleMouseEnter(event, x, pointIndex)}
                      onTouchStart={(event) => handleMouseEnter(event, x, pointIndex)}
                    >
                      {
                        (showLowestCircle || showHeighestCircle || tooltipVisible || showLastValueLabel || (!isDense && hasRawValue)) &&
                        <Circle
                          cx={x}
                          cy={y}
                          strokeWidth={1}
                          radius={checkLineVisiblity(lineIndex) ? 0 : circleRadius}
                          id={`${lineIndex}-${dataPoint.xLabel}`}
                          strokeColor={colors[lineIndex] || defaultColor}
                          key={`line-${lineIndex}-${dataPoint.xLabel}-${pointIndex}`}
                          backgroundColor={
                            ((tooltipPosition.visible && tooltipPosition.index === pointIndex) || ((!!isDense && lastIndex === pointIndex))) ?
                              colors[lineIndex] || defaultColor : '#ffffff'
                          }
                        />
                      }
                      <Bar
                        y={margins.top}
                        id={`${id}-voronoi`}
                        fill={'transparent'}
                        width={xScale.step()}
                        x={x - xScale.step() / 2}
                        className={'mouseover-hidden-voronoi'}
                        height={height - margins.top - margins.bottom}
                      />
                      {denseYLabels &&
                        <>
                          {/* TODO: vervangen door dense yaxis min en max label moeten ook kunnen worden aangepast door yMin en yMax i.c.m. yMinMaxCalculated, dit wordt gedaan buiten de loop (zie hieronder) */}
                          {/* eerst nog niet vervangen omdat het nog goed getest moet worden overal */}
                          {showMaximumValueLabel && <text style={{ textAnchor: 'end', fontSize: '0.8rem' }} x={xyPoints[0].x - 10} y={y + 6}>{dataPoint.formattedValue}</text>}
                          {showMinimumValueLabel && <text style={{ textAnchor: 'end', fontSize: '0.8rem' }} x={xyPoints[0].x - 10} y={y + 4}>{dataPoint.formattedValue}</text>}
                          {showLastValueLabel &&
                            <text
                              x={x + 10}
                              y={y + 4}
                              style={
                                {
                                
                                  fill: dense === true ? '#2b4899' : undefined,
                                  fontSize: dense === true ? '2.5rem' : '0.8rem',
                                }
                              }
                            >
                              {dataPoint.formattedValue}
                            </text>
                          }
                        </>
                      }
                    </g>
                  )
                })}
              </React.Fragment>
            );
          }
          )}
          {/* fixed dense min value label, TODO: vervangen door dense yaxis */}
          {isDense && !yMinCalculated &&
            <text
              style={{ 
                textAnchor: 'end',
                 fontSize: '0.8rem'
                }}
              x={margins.left - 10}
              // TODO: fixedYTicks en fixedYScale voor cm
              y={yScale(minValue)}>
              {!yAxisTickFormatter && minValue}
              {!!yAxisTickFormatter && yAxisTickFormatter(minValue, yAxisTickFormatterOptions)}
            </text>
          }
          {/* fixed denxe max value label, TODO: vervangen door dense yaxis */}
          {isDense && !yMaxCalculated &&
            <text
              style={{ 
                textAnchor: 'end',
                 fontSize: '0.8rem'
                }
              }
              x={margins.left - 10}
              // TODO: fixedYTicks en fixedYScale voor cm
              y={yScale(maxValue)}>
              {!yAxisTickFormatter && maxValue}
              {!!yAxisTickFormatter && yAxisTickFormatter(maxValue, yAxisTickFormatterOptions)}
            </text>
          }
          {/* TODO: dit doen ipv maximumlabel en minimumlabel berekeningen. let wel op bij cm labels */}
          {/* {isDense && denseYLabels &&
            <AxisLeft
              x={margins.left}
              numberOfTicks={2}
              id={`${id}-axis-left`}
              tickValues={[minValue, maxValue]}
              scale={!!fixedYAxisScale ? fixedYAxisScale : yScale}
              tickFormat={(value) => {
                // TODO: voor dense speciale formatter in opties want je moet je b.v. wel de comma achter percentage tonen
                if (!!yAxisTickFormatter) {
                  return yAxisTickFormatter(value, yAxisTickFormatterOptions);
                }

                return value;
              }}
            />
          } */}
          {!isDense &&
            <AxisLeft
              x={margins.left - yScaleRightOffset}
              id={`${id}-axis-left`}
              numberOfTicks={yNumberOfTicks}
              scale={!!fixedYAxisScale ? fixedYAxisScale : yScale}
              theme={{
                tickAnimation: axisAnimation
              }}
              tickFormat={(value) => {
                // if (isDense) {
                //   value = yScale.ticks().length - 1 === i || i === 0 ? value : '';
                // }
                if (!!yAxisTickFormatter) {
                  return yAxisTickFormatter(value, yAxisTickFormatterOptions);
                }

                return value;
              }}
            />
          }

          {!isDense && !!showThreshold &&
            xThreshold.map(({ label, pointIndex }) => {
              return (
                <LineThresholdVertical
                  lineWidth={1}
                  showCircle={false}
                  labelPadding={[2, 4, 2, 4]}
                  labelPos={['left', 'bottom']}
                  lineHeight={margins.bottom - 14} // TODO: waar komt die 14 vandaan?
                  y={height - margins.bottom + 14} // TODO: waar komt die 14 vandaan?
                  labelDominantBaseline={'auto'} // IE fix
                  label={<tspan dy={13}>{label}</tspan>}
                  id={`${id}-line-vertical-threshold-${label}`}
                  key={`${id}-line-vertical-threshold-${label}`}
                  x={xScale(`${pointIndex}`) as number + xScale.bandwidth() / 2}
                />
              );
            })
          }
          {!isDense &&
            <AxisBottom
              scale={xScale}
              y={-margins.bottom}
              chartHeight={height}
              id={`${id}-axis-bottom`}
              theme={{
                tickAnimation: axisAnimation
              }}
              tickFormat={(pointIndex) => {
                const index = parseInt(pointIndex as string);
                const point = lines[0].points[index];
                const { xLabel } = point;
                const { shortXLabel = xLabel } = point;

                return shortXLabel;
              }}
            />
          }
          {isDense &&
            <AxisBottom
              scale={xScale}
              hideLine={false}
              chartHeight={height}
              id={`${id}-axis-bottom`}
              tickOffset={xScale.bandwidth()}
              y={-margins.bottom + (isDense ? denseXindent : 0)}
              hideTickLine={(_value, index) => {
                const threshold = xThreshold.find(({ pointIndex: xThresholdPointIndex }) => index - 1 === xThresholdPointIndex)
                if (!!threshold && showThreshold) {
                  return false;
                }
                return true;
              }}
              theme={{ colors: { line: '#EEEEEE' }, tickAnimation: { duration: 500, delay: 300 } }}
              tickFormat={(pointIndex) => {
                const threshold = xThreshold.find(({ pointIndex: xThresholdPointIndex }) => pointIndex === `${xThresholdPointIndex}`)
                if (!!threshold && showThreshold) {
                  return threshold.label;
                }
                return ''; //TODO: what if we don't have a threshold?
              }}
            />
          }
          {tooltipPosition.visible &&
            <Line
              dashed
              width={1}
              color={'#212121'}
              id={`mouse-over-line-${tooltipPosition.index}`}
              to={{ y: margins.top - (isDense ? denseXindent : 0), x: tooltipPosition.x }}
              from={{ y: (height - margins.bottom) + (isDense ? denseXindent : 0), x: tooltipPosition.x }}
            />
          }
        </Chart>
        {mergeTooltip &&
          <Tooltip
            top={yScale(maxValue)}
            left={tooltipPosition.x}
            visible={tooltipPosition.visible}
          >
            {() => {
              const { title, values } = linesWithXYPoints.reduce((previous, { xyPoints }, lineIndex) => {
                const { dataPoint } = xyPoints[tooltipPosition.index];
                const { label, xLabel, rawValue, formattedValue } = dataPoint;

                return {
                  title: xLabel,
                  values: [
                    ...previous.values,
                    {
                      label,
                      rawValue,
                      colors: [colors[lineIndex] || defaultColor],
                      formattedValue: rawValue !== null ? formattedValue : 'n.v.t.', // TODO: hardcoded n.v.t.
                    }
                  ]
                };
              }, { title: '', values: [] as { label: string, formattedValue: string, colors: string[] }[] });

              return (
                <TooltipTemplate
                  title={title}
                  values={values}
                  tooltipOptions={tooltipOptions}
                />
              );
            }
            }
          </Tooltip>
        }
        {!mergeTooltip &&
          linesWithXYPoints.map(({ xyPoints }, lineIndex) => {
            const { x, y, dataPoint } = xyPoints[tooltipPosition.index];
            const { label, xLabel, rawValue, formattedValue } = dataPoint;

            return (
              <Tooltip
                top={y}
                left={x}
                key={`line-tooltip-${lineIndex}`} // NOTE: important that the key does not change per line
                visible={tooltipPosition.visible}
                theme={{
                  opacity: dataPoint.rawValue == null ? 0 : 1
                }}
              >
                {() =>
                  <TooltipTemplate
                    title={`${xLabel}`}
                    tooltipOptions={tooltipOptions}
                    values={[{
                      label,
                      rawValue,
                      formattedValue,
                      colors: [colors[lineIndex] || defaultColor]
                    }]}
                  />
                }
              </Tooltip>
            )
          })
        }

        {!isDense &&
          <Legend
            ref={legendElementRef}
            legendOptions={{
              ...legendOptions
            }}
            values={
              lines.map(({ points }, lineIndex) => {
                const { label } = points[0];
                return {
                  label,
                  colors: [colors[lineIndex] || defaultColor]
                };
              })
            }
          />
        }
      </StyledChartHolder>
      {/* {
        !!showHelp && helpId &&
        <StyledHelpButtonWrapper>
          <HelpTooltipButton
            helpId={helpId}
            anchorType={'iconButton'}
          />
        </StyledHelpButtonWrapper>
      } */}
    </StyledWrapper>
  )
};

export default LineChart;
