import MuiButton, { ButtonProps as MuiButtonProps } from '@material-ui/core/Button';
import MuiAddIcon from '@material-ui/icons/Add';
import MuiFeedbackIcon from '@material-ui/icons/Feedback';
import MuiGetAppIcon from '@material-ui/icons/GetApp';
import useTheme from '@material-ui/styles/useTheme';
import * as H from 'history';
import {
  TreeDataItem,
  useCheckBoxTreeHandlers,
} from 'ppd-library/components/organisms/CheckBoxTree';
import React, { createRef, ReactNode, useEffect, useRef, useState } from 'react';
import { ItemCallback } from 'react-grid-layout';
import styled from 'styled-components';

import { ViewTypes } from '../@types/ppd-api.enums';
import { MediaDevices } from '../App';
import { GridBreakpoint, GridLayout } from '../components/layout/GridLayout';
import { PageHeader } from '../components/layout/PageHeader';
import { CloneActionButton } from '../components/tiles/action-buttons/CloneActionButton';
import { DeleteActionButton } from '../components/tiles/action-buttons/DeleteActionButton';
import { MenuActionButton } from '../components/tiles/action-buttons/MenuActionButton';
import { Container } from '../components/tiles/Container';
import { ContainerErrorBoundary } from '../components/tiles/ContainerErrorBoundary';
import { TileTreePopOver } from '../components/TileTreePopOver';
import { saveElement } from '../utils';
import { httpDelete, httpGet, httpPut } from '../utils/http';
import {
  EditActionButton,
  EditDoneActionButton,
  SaveAsNewTemplateActionButton,
} from './dashboardpage/components/action-buttons';
import { StyledDashboardPageWrapper } from './dashboardpage/components/StyledDashboardPageWrapper';
import { StyledGridTileWrapper } from './dashboardpage/components/StyledGridTileWrapper';
import TemplateDisabledPlaceholder from './dashboardpage/components/TemplateDisabledPlaceholder';
import TemplateDoesNotExistPlaceholder from './dashboardpage/components/TemplateDoesNotExistPlaceholder';
import {
  columnsPerBreakpoint,
  containerBreakpoints,
  createAllTileDimensions,
  createGridLayouts,
  createTileDimensions,
  handleTileResize,
  rowHeight,
  syncCurrentLayout,
  syncLayout,
} from './dashboardpage/grid-utils';
import {
  useCurrentBreakpointChangedEffect,
  useDashboardPageRefs,
  useDashboardPageState,
} from './dashboardpage/hooks';
import { DashboardPageBaseProps } from './dashboardpage/interfaces';
import { getQueryParams } from './dashboardpage/utils';
import { copyAndPostTemplateTile, generateAndPostCustomTemplateLayoutItems } from './pageActions';
import { asyncTryCatch } from '../utils/errorHandling';

// eslint-disable-next-line
const { API_URL, DATA_URL, SHOW_IDS, USE_MOCK_DATA } = ppd.appConfig;

interface CustomDashboardPageProps extends DashboardPageBaseProps {
  history: H.History;
  helpActive: boolean; // TODO: redux
  template?: API_GET.Template;
  mediaDevices: MediaDevices; // TODO: redux
  onThemeInfoClick: (helpId: number) => void;
  onDuplicateDashboard: (templateId: number) => void;
  onUpdateTemplate: (templateId: number, template: API_PUT.Template) => void;
}

const StyledMuiButton = styled(({ innerRef, ...divProps }) => <MuiButton ref={innerRef} {...divProps} />)`
  &&{
    margin-top: 2rem;
    background-color: #2b4899;
  }
`as React.ComponentType<{ innerRef: React.RefObject<ReactNode>; } & MuiButtonProps>;

const excludedTemplates = [] as string[];
const excludeTemplate = (template: API_GET.Template) => {
  // TODO: kan misschien ook id === 1 gebruiken ipv name?
  const { name, isOverview } = template;
  const lower = name.toLowerCase();

  return !!isOverview || excludedTemplates.some((excluded) => lower === excluded);
};

let resizeTimeout: number;
let resizeStopTimeout: number;
let widthChangeTimeout: number;
let layoutChangedTimeout: number;
const CustomDashboardPage = (props: CustomDashboardPageProps) => {
  const {
    history,
    // account,
    menuOpen,
    helpActive,
    mediaDevices,
    menuOpenWidth,
    menuOpenSpeed,
    onLoadingChange,
    onUpdateTemplate,
    onDuplicateDashboard,
    onDashboardEditEnabledChange,
    template = {} as API_GET.Template,
  } = props;

  const urlQueryParamValues = getQueryParams(history.location);
  const showDevIds = SHOW_IDS || urlQueryParamValues['show_ids'] === 'true';
  const editModeActive = urlQueryParamValues['editmode'] === 'true';
  const isMobile = mediaDevices.mobile;

  const {
    enabled,
    id: templateId,
    name: themeName,
    path: themePath,
    placeholderText
  } = template;

  const theme = useTheme<any>();
  let [templateDetail, setTemplateDetail] = useState<API_GET.TemplateDetail | null>(null);
  const { templateTiles = [] } = templateDetail || {} as API_GET.TemplateDetail;

  const [resetGridTrigger, setResetGridTrigger] = useState(false);
  const [isTilePopOverOpen, setIsTilePopOverOpen] = useState(false);
  const [tilePopOverInputValue, setTilePopOverInputValue] = useState('');
  const stopGridLayoutChangeRef = useRef(true);
  const stopGridLayoutWidthChangeRef = useRef(false);
  const templateLayoutsRef = useRef<API_GET.TemplateLayout[]>([]);
  const AddNewTileButtonRef = useRef<React.RefObject<HTMLDivElement>>(null);

  const {
    checkedIds: tilePopOverCheckedIds,
    expandedIds: tilePopOverExpandedIds,
    handleNodeExpandChange: handleTilePopOverNEC,
  } = useCheckBoxTreeHandlers([], []);

  const {
    isLoading, setIsLoading,
    editEnabled, setEditEnabled,
    tileDimensions, setTileDimensions,
    currentBreakpoint, setCurrentBreakpoint
  } = useDashboardPageState();

  const {
    gridLayoutsRef,
    gridTileWrappersRef
  } = useDashboardPageRefs();

  const handleResizeStop: ItemCallback = (layout, _oldItem, newItem, _placeholder, _event, _element) => {
    stopGridLayoutChangeRef.current = false;

    clearTimeout(resizeTimeout);
    clearTimeout(resizeStopTimeout);

    return setTimeout(() => {
      const newDimensions = createTileDimensions(newItem, tileDimensions, gridTileWrappersRef);
      syncLayout(layout, currentBreakpoint, gridLayoutsRef, templateLayoutsRef);

      setTileDimensions(newDimensions);
    }, 50);
  }

  const handleResize: ItemCallback = (_layout, _oldItem, newItem, _placeholder, _event, _element) => {
    resizeTimeout = handleTileResize(newItem, resizeTimeout, tileDimensions, gridTileWrappersRef, setTileDimensions);
  };

  const handleBreakpointChange = (newBreakPoint: string) => {
    stopGridLayoutWidthChangeRef.current = true;

    syncLayout(gridLayoutsRef.current[newBreakPoint], newBreakPoint as GridBreakpoint, gridLayoutsRef, templateLayoutsRef);
    setCurrentBreakpoint(newBreakPoint as GridBreakpoint);
    console.log(newBreakPoint);
  };

  const handleEditLayoutClick = () => {
    onDashboardEditEnabledChange(true); // TODO: gebruik redux
    setEditEnabled(true);
  };

  const handleEditLayoutDoneClick = () => {
    onDashboardEditEnabledChange(false);
    setEditEnabled(false);
  };

  const handleWidthChange = () => {
    clearTimeout(widthChangeTimeout);
    // TODO: wanneer de breakpoint veranderd, gaat deze ook af maar wordt de oude breakpoint meegegeven in de debounce..
    // daardoor wordt de currentbreakpoint weer teruggezet naar de oude terwijl de layouts wel goed blijven.
    // dit zorgt er bijvoorbeeld voor bij het putten van de layouts, dat de verkeerde items worden ge-update, verkeerde layout wordt gepakt omdat currentbreakpoint wordt gebruikt
    // daarom nu als quickfix een flag wanneer een breakpoint veranderd.. 
    if(stopGridLayoutWidthChangeRef.current) {
      stopGridLayoutWidthChangeRef.current = false;
    } else {
      widthChangeTimeout = setTimeout(() => {
        syncCurrentLayout(currentBreakpoint, gridLayoutsRef, templateLayoutsRef);
         const currentLayout = gridLayoutsRef.current[currentBreakpoint];
         const newDimensions = createAllTileDimensions(currentLayout, tileDimensions, gridTileWrappersRef);
         setTileDimensions(newDimensions);
      }, 250);
    }
  };


  // TODO: page of tile util
  const handleDownloadToImageClick = (element: HTMLElement, tileTitle: string) => {
    saveElement(element, tileTitle, false);
  };

  useEffect(() => {
    const getTemplateDetail = async () => {
      if (!!templateId) {
        setIsLoading(true);

        if (!!templateDetail) {
          // only needed when we are in an existing custom template
          setResetGridTrigger(true);
        }

        const templateDetailP = httpGet<API_GET.TemplateDetail>(`${API_URL}/templates/${templateId}`);
        const layoutsP = httpGet<API_GET.TemplateLayout[]>(`${API_URL}/templates/${templateId}/layouts`);

        const [localTemplateDetail, layouts] = await Promise.all([templateDetailP, layoutsP]);
        const gridLayouts = createGridLayouts(layouts);
        gridLayoutsRef.current = gridLayouts;
        templateLayoutsRef.current = layouts;

        setTemplateDetail(localTemplateDetail);
        setIsLoading(false);
      } else {
        setTemplateDetail(null);
        setIsLoading(false);
      }

      // Sets the page to top on every template path change.
      window.scrollTo(0, 0);
    };

    getTemplateDetail();

    // NOTE: We DO NOT want to add currentBreakPoint as a dependency here (don't need to fetch templateDetail on breakpoint change)
    // eslint-disable-next-line
  }, [templateId]);

  useEffect(() => {
    if (resetGridTrigger) {
      setResetGridTrigger(false);
    }
    // eslint-disable-next-line
  }, [templateDetail]);

  useCurrentBreakpointChangedEffect(
    layoutChangedTimeout, currentBreakpoint, templateDetail,
    gridLayoutsRef, tileDimensions, gridTileWrappersRef, setTileDimensions,
    (timeout) => {
      layoutChangedTimeout = timeout
    }
  );

  useEffect(() => {
    return () => {
      clearTimeout(resizeTimeout);
      clearTimeout(resizeStopTimeout);
      clearTimeout(layoutChangedTimeout);
    };
  }, []);

  useEffect(() => {
    if (editModeActive) {
      setEditEnabled(true);
    }
    // eslint-disable-next-line
  }, [editModeActive]);

  // TODO: set page loading prop via redux
  useEffect(() => {
    onLoadingChange(isLoading);
    // eslint-disable-next-line
  }, [isLoading]);

  const handleTilePopOverOpen = () => {
    setIsTilePopOverOpen(true);
  }
  const handleTilePopOverClose = () => {
    setIsTilePopOverOpen(false);
  };

  const handleTilePopOverCleanNodeClick = async (item: TreeDataItem) => {
    handleTilePopOverClose();
    onLoadingChange(true);
    const { templateTile } = item.extraTreeItemData;
    await cloneTemplateTile(templateTile);
    onLoadingChange(false);
  };

  const handleCloneClick = async (originalTemplateTile: API_GET.TemplateTileDetail) => {
    onLoadingChange(true);
    await cloneTemplateTile(originalTemplateTile);
    onLoadingChange(false);
  };

  const cloneTemplateTile = async (orginalTemplateTile: API_GET.TemplateTileDetail) => {
    const templateTilePostResult = await asyncTryCatch(copyAndPostTemplateTile(
      orginalTemplateTile as API_GET.TemplateTileDetail,
      templateId
    ));

    if (templateTilePostResult.isError) {
      // TODO: show notification
      throw new Error(templateTilePostResult.value as any);
    }

    const createdTemplateTile = templateTilePostResult.value as API_GET.TemplateTileDetail;

    const templateLayouts = templateLayoutsRef.current;
    const layoutItemsPostResult = await asyncTryCatch(generateAndPostCustomTemplateLayoutItems(templateLayouts, createdTemplateTile.id));

    if (layoutItemsPostResult.isError) {
      // TODO: show notification
      // TODO: delete templateTile
      throw new Error(layoutItemsPostResult.value as any);
    }

    const updatedLayouts = layoutItemsPostResult.value as API_GET.TemplateLayout[];
    templateLayoutsRef.current = updatedLayouts;

    const newGridLayouts = createGridLayouts(templateLayoutsRef.current);
    gridLayoutsRef.current = newGridLayouts;

    const newTemplateDetail = {
      ...templateDetail as API_GET.TemplateDetail,
      templateTiles: [...templateTiles, createdTemplateTile]
    };
    setTemplateDetail(newTemplateDetail);
  }

  const handleTileDragStop: ItemCallback = () => {
    stopGridLayoutChangeRef.current = false;
  };

  /**
   * NOTE!: this also fires AFTER a tile has been added or deleted, and BEFORE a breakpoint or grid width changes.
   * but we do not need to save the layout when this happens, 
   * so we use a flag stopGridLayoutChangeRef.current that is set to false when resizing or drag / dropping
   */
  const handleLayoutChange = (newLayout: ReactGridLayout.Layout[], _allLayouts: ReactGridLayout.Layouts) => {
    if (!stopGridLayoutChangeRef.current) {
      // tile width, height or position has changed so we need to save the layout

      const updatedTemplateLayoutIndex = syncLayout(newLayout, currentBreakpoint,gridLayoutsRef, templateLayoutsRef);
      const updatedTemplateLayout = templateLayoutsRef.current[updatedTemplateLayoutIndex];

      httpPut<API_PUT.TemplateLayoutItem[], API_GET.TemplateLayoutItem[]>(`${API_URL}/layoutitems_bulk`, updatedTemplateLayout.items);
      // .then((updatedLayoutItem) => { })
      // .catch((error) => { })

      // resizeStop and dropEvent will set reset stopGridLayoutChangeRef.current
      stopGridLayoutChangeRef.current = true;
    }
  };

  const handleDeleteClick = async (templateTileId: number) => {
    if (editEnabled) {
      onLoadingChange(true);
      stopGridLayoutChangeRef.current = false;
      try {
        await httpDelete(`${API_URL}/templatetiles/${templateTileId}`);

        const newTemplateTiles = (templateDetail as API_GET.TemplateDetail).templateTiles.filter(({ id }) => id !== templateTileId);
        const newTemplateDetail = {
          ...templateDetail as API_GET.TemplateDetail,
          templateTiles: newTemplateTiles
        };
        setTemplateDetail(newTemplateDetail);
        // TODO: mischien delete notificatie tonen dmv snackbar
      } catch (e) {
        console.log(e);
      } finally {
        onLoadingChange(false);
      }
    }
  };

  const handleTileViewTypeChange = (templateTileId: number, viewTypeId: number) => {
    if (editEnabled) {
      httpPut<API_PUT.TemplateTile, API_GET.TemplateTile>(`${API_URL}/templatetiles/${templateTileId}`, {
        selectedViewTypeId: viewTypeId
      })
      // .then((updatedTemplateTile) => {})
      // .catch((error: string) => {})

      const keys = Object.keys(ViewTypes);
      const viewTypeName = keys.find((key) => ViewTypes[key as API.ViewTypeName] === viewTypeId) as API.ViewTypeName;

      const templateTileIndex = templateTiles.findIndex((tt) => tt.id === templateTileId);
      let updatedTemplateTile = { ...templateTiles[templateTileIndex] };
      updatedTemplateTile.selectedViewType = { name: viewTypeName, id: viewTypeId };
      const updatedTemplateTiles = [...templateTiles];
      updatedTemplateTiles[templateTileIndex] = updatedTemplateTile;

      const newTemplateDetail = {
        ...templateDetail as API_GET.TemplateDetail,
        templateTiles: updatedTemplateTiles
      };
      setTemplateDetail(newTemplateDetail);
    }
  };

  const handleTileTabChange = (templateTileId: number, tabId: number) => {
    if (editEnabled) {
      httpPut<API_PUT.TemplateTile, API_GET.TemplateTile>(`${API_URL}/templatetiles/${templateTileId}`, {
        selectedTabId: tabId
      })
      // .then((updatedTemplateTile) => {})
      // .catch((error: string) => {})

      const templateTileIndex = templateTiles.findIndex((tt) => tt.id === templateTileId);
      let updatedTemplateTile = { ...templateTiles[templateTileIndex] };
      updatedTemplateTile.selectedTabId = tabId;
      const updatedTemplateTiles = [...templateTiles];
      updatedTemplateTiles[templateTileIndex] = updatedTemplateTile;

      const newTemplateDetail = {
        ...templateDetail as API_GET.TemplateDetail,
        templateTiles: updatedTemplateTiles
      };
      setTemplateDetail(newTemplateDetail);
    }
  };

  const handleTileDataKeyTypeClick = (templateTileId: number, dataKeyType: API.DataKeyType) => {
    if (editEnabled) {
      httpPut<API_PUT.TemplateTile, API_GET.TemplateTile>(`${API_URL}/templatetiles/${templateTileId}`, {
        selectedDataKeyTypeId: dataKeyType.id
      })
      // .then((updatedTemplateTile) => {})
      // .catch((error: string) => {})

      const templateTileIndex = templateTiles.findIndex((tt) => tt.id === templateTileId);
      let updatedTemplateTile = { ...templateTiles[templateTileIndex] };
      updatedTemplateTile.selectedDataKeyType = dataKeyType;
      const updatedTemplateTiles = [...templateTiles];
      updatedTemplateTiles[templateTileIndex] = updatedTemplateTile;

      const newTemplateDetail = {
        ...templateDetail as API_GET.TemplateDetail,
        templateTiles: updatedTemplateTiles
      };
      setTemplateDetail(newTemplateDetail);
    }
  };

  const handleTileUnitCodeClick = (templateTileId: number, { name, code }: { name:string, code: string }) => {
    if (editEnabled) {
      onLoadingChange(true);

      const updateLocalTemplateDetail = (organisationUnit: API.OrganisationUnit) => {
        const templateTileIndex = templateTiles.findIndex((tt) => tt.id === templateTileId);
        let updatedTemplateTile = { ...templateTiles[templateTileIndex] };
        updatedTemplateTile.organisationUnit = organisationUnit;
        const updatedTemplateTiles = [...templateTiles];
        updatedTemplateTiles[templateTileIndex] = updatedTemplateTile;

        const newTemplateDetail = {
          ...templateDetail as API_GET.TemplateDetail,
          templateTiles: updatedTemplateTiles
        };
        setTemplateDetail(newTemplateDetail);
      };
      updateLocalTemplateDetail({ name, code } as API.OrganisationUnit);

      const promise = httpPut<API_PUT.TemplateTile, API_GET.TemplateTile>(`${API_URL}/templatetiles/${templateTileId}`, {
        organisationUnitCode: code
      })
      // promise.then((resultTT) => { // NOTE: dit is overbodig?
      //   updateLocalTemplateDetail(resultTT.organisationUnit as API.OrganisationUnit);
      // })
      promise.finally(() => {
        onLoadingChange(false);
      });
    }
  };

  const handleGlobalSelectedUnitChange = async (item: { code: string, name: string }) => {
    const { code, name } = item;

    setTemplateDetail({
      ...templateDetail as API_GET.TemplateDetail,
      organisationUnit: { id: -1, code, name, level: -1 }
    });

    if (editEnabled) {
      onUpdateTemplate((templateDetail as API_GET.TemplateDetail).id, { organisationUnitCode: code });
    }
  };

  const hideGlobalUnitSelection = !templateDetail || !templateDetail.organisationUnit;

  return (
    <>
      {showDevIds &&
        <div style={{
          right: '32px',
          zIndex: 9999999,
          position: 'fixed',
          marginTop: '-24px',
        }}>
          {currentBreakpoint}
        </div>
      }
      <PageHeader
        menuOpen={menuOpen}
        showHelp={helpActive}
        mediaDevices={mediaDevices}
        menuOpenSpeed={menuOpenSpeed}
        menuOpenWidth={menuOpenWidth}
        hideGlobalUnitSelection={hideGlobalUnitSelection}
        onSelectedOeChange={handleGlobalSelectedUnitChange}
        initialUnitCode={
          !hideGlobalUnitSelection ?
            ((templateDetail as API_GET.TemplateDetail).organisationUnit as API.OrganisationUnit).code
            : undefined
        }
        breadCrumbItems={
          [
            { iconName: '', text: 'Dashboards', path: '/dashboards/overzicht' },
            { iconName: 'add_to_queue', text: themeName, path: `/dashboards/${themePath}` }
          ]
        }
        actionButtons={
          <>
            {!editEnabled &&
              <EditActionButton
                disabled={menuOpen}
                isMobile={isMobile}
                onClick={handleEditLayoutClick}
              />
            }
            {editEnabled &&
              <SaveAsNewTemplateActionButton
                isMobile={isMobile}
                onClick={() => onDuplicateDashboard((templateDetail as API_GET.TemplateDetail).id)}
              />
            }
            {editEnabled &&
              <EditDoneActionButton
                color={'primary'}
                isMobile={isMobile}
                onClick={handleEditLayoutDoneClick}
              />
            }
          </>
        }
      />
      <StyledDashboardPageWrapper
        isMobile={isMobile}
        menuOpen={menuOpen}
        menuOpenSpeed={menuOpenSpeed}
        menuOpenWidth={menuOpenWidth}
        className={'dashboardPageWrapper'}
        style={{ height: editEnabled && templateTiles.length === 0 ? `${document.documentElement.scrollHeight - 210}px` : 'auto' }}
      >
        <TemplateDoesNotExistPlaceholder
          isLoading={isLoading}
          templateDetail={templateDetail}
          placeholderText={placeholderText}
          placeholderColor={theme.palette.secondary.light}
        />
        <TemplateDisabledPlaceholder
          isLoading={isLoading}
          templateEnabled={enabled}
          templateDetail={templateDetail}
          placeholderText={placeholderText}
          placeholderColor={theme.palette.secondary.light}
        />
        {!!templateDetail && enabled && !placeholderText && !resetGridTrigger &&
          <GridLayout
            rowHeight={rowHeight}
            onResize={handleResize}
            isEditable={editEnabled}
            breakpoint={currentBreakpoint}
            onDragStop={handleTileDragStop}
            onResizeStop={handleResizeStop}
            layouts={gridLayoutsRef.current}
            onWidthChange={handleWidthChange}
            breakpoints={containerBreakpoints}
            onLayoutChange={handleLayoutChange}
            onBreakpointChange={handleBreakpointChange}
            columnsPerBreakpoint={columnsPerBreakpoint}
          >
            {templateTiles.map((templateTile: API_GET.TemplateTileDetail) => {
              const {
                tile,
                tileId,
                selectedTabId,
                selectedViewType,
                organisationUnit: originalOrganisationUnit,
                id: templateTileId,
                selectedDataKeyType,
                enabled: templateTileEnabled,
                placeholderText: templateTilePlaceholderText,
              } = templateTile;

              const organisationUnit = originalOrganisationUnit || { code: 'NP00000', name: 'Business Data Challengers' } as API.OrganisationUnit;

              const {
                title,
                // TODO: ook gebruiken, niet alleen die van templateTile
                //  enabled: tileEnabled,
                //  placeholderText: tilePlaceholderText,
              } = tile;

              const { width = undefined, height = undefined } = tileDimensions[`${templateTileId}`] || {};
              // TODO: enabledTemplateTiles in plaats alle templateTiles?
              // NOTE: this need to be done here, inside the loop, because tiles can be removed and added to the filter.
              if (!gridTileWrappersRef.current[`${templateTileId}`]) {
                gridTileWrappersRef.current[`${templateTileId}`] = createRef();
              }

              return (
                <StyledGridTileWrapper
                  // NOTE: the grid uses the react key to bind to the layout
                  editMode={editEnabled}
                  key={`${templateTileId}`}
                  innerRef={gridTileWrappersRef.current[`${templateTileId}`]}
                >
                  {templateTileEnabled === true &&
                    <ContainerErrorBoundary
                      key={`${templateTileId}`}
                      containerId={templateTileId}
                      placeholderText={templateTilePlaceholderText}
                    >
                      {showDevIds &&
                        <div style={{ position: 'absolute' }}>{templateTileId} - {tileId}</div>
                      }
                      <Container
                        tile={tile}
                        editable={true}
                        dataUrl={DATA_URL}
                        isCleanTile={false}
                        id={templateTileId}
                        showIds={showDevIds}
                        showHelp={helpActive}
                        editMode={editEnabled}
                        tileWidth={width as number}
                        useMockData={!!USE_MOCK_DATA}
                        tileHeight={height as number}
                        initialTabId={selectedTabId}
                        initialViewType={selectedViewType}
                        selectedDataKeyType={selectedDataKeyType}
                        showOrganisationUnitSelection={hideGlobalUnitSelection}
                        onTabChange={(tabId) => handleTileTabChange(templateTileId, tabId)}
                        onViewTypeChange={(viewTypeId) => handleTileViewTypeChange(templateTileId, viewTypeId)}
                        onDataKeyTypeClick={(dataKeyType) => handleTileDataKeyTypeClick(templateTileId, dataKeyType)}
                        onOrganisationUnitClick={(organisationUnit) => handleTileUnitCodeClick(templateTileId, organisationUnit)}
                        selectedOrganisationUnit={!hideGlobalUnitSelection ? (templateDetail as API_GET.TemplateDetail).organisationUnit as API.OrganisationUnit : organisationUnit}
                        actionButton={
                          editEnabled ?
                            <>
                              <DeleteActionButton
                                label={'Tegel verwijderen?'}
                                onDeleteClick={() => handleDeleteClick(templateTileId)}
                              />
                               <CloneActionButton
                                onCloneClick={() => handleCloneClick(templateTile)}
                              />
                            </>
                            :
                            <MenuActionButton
                              // TODO: deze items lostrekken en herbruikbaar maken?
                              items={[
                                {
                                  id: '3',
                                  iconSvg: <MuiGetAppIcon />,
                                  label: `Download afbeelding`,
                                  onClick: () => handleDownloadToImageClick(gridTileWrappersRef.current[`${templateTileId}`].current as HTMLDivElement, title),
                                },
                                {
                                  id: '4',
                                  onClick: () => {},
                                  label: `Feedback op deze tegel`,
                                  iconSvg: <MuiFeedbackIcon />,
                                },
                              ]}
                            />
                        }
                      />
                    </ContainerErrorBoundary>
                  }
                </StyledGridTileWrapper>
              );
            })}
          </GridLayout>
        }
        {!!editEnabled &&
          <StyledMuiButton
            innerRef={AddNewTileButtonRef}
            startIcon={<MuiAddIcon />}
            variant={'contained'}
            color={'primary'}
            onClick={handleTilePopOverOpen}>
            Tegel toevoegen
          </StyledMuiButton>
        }
        <TileTreePopOver
          preLoad={true}
          maxFilterLevel={2}
          tabsVisible={false}
          tilesCheckable={false}
          showDevIds={showDevIds}
          templatesCheckable={false}
          isOpen={isTilePopOverOpen}
          headerText={'Tegels selecteren'}
          onClose={handleTilePopOverClose}
          excludeTemplate={excludeTemplate}
          inputValue={tilePopOverInputValue}
          checkedIds={tilePopOverCheckedIds}
          expandedIds={tilePopOverExpandedIds}
          placeholder={'Zoeken op tegel titel'}
          onClear={() => setTilePopOverInputValue('')}
          onCleanNodeClick={handleTilePopOverCleanNodeClick}
          theme={{
            notExpandableStyle: 'none',
            headerBackground: '#e0e0e0',
            labelStyles: [
              {
                nodeLevel: 1, 
                labelStyle: {
                  main: { color: theme.palette.primary.main },
                  hover: { textDecoration: 'underline', color: theme.palette.secondary.main }
                }
              }
            ]
          }
          }
          onNodeExpandChange={handleTilePopOverNEC}
          onInputChange={(value) => setTilePopOverInputValue(value)}
          onNodeCheckedChange={() => { }}
          onMultipleNodeCheckedChange={() => { }}
          anchorElement={window.document.body}
        />
      </StyledDashboardPageWrapper>
    </>
  );
};

export default CustomDashboardPage;