export function save(key: string, value: string | any[]) {
  if (!canUseLocalStorage()) {
    return false;
  }

  try {
      let data = JSON.stringify(value);

      localStorage.setItem(key, data);
  } catch (e) {
      console.info(e);
      return false;
  }

  return true;
}

export function get<T>(key: string) : T | null {
  if (!canUseLocalStorage()) {
      return null;
  }

  let result: T | null = null;

  try {
      const stored = localStorage.getItem(key);
      result = JSON.parse(stored as string);
  } catch (e) {
      console.info(e);
      result = null;
  }

  return result;
}

export function clearStorage() {
  if (!canUseLocalStorage()) {
      return false;
  }

  try {
      localStorage.clear();
  } catch (e) {
      console.info(e);
      return false;
  }

  return true;
}

export function canUseLocalStorage() {
  let ls;
  try {
    ls = window.localStorage;
    ls.setItem('test', 'test');
    ls.getItem('test');
    ls.removeItem('test');

    return true;
  } catch (e) {
    return false;
  }
}