import MuiIconButton from '@material-ui/core/IconButton';
import MuiFileCopyIcon from '@material-ui/icons/FileCopy';
import React from 'react';

export interface CloneActionButtonProps {
  onCloneClick: () => void;
   /**
   * @default secondary
   */
  color?: 'primary' | 'default' | 'secondary';
}

const CloneActionButton = (props: CloneActionButtonProps) => {
  const {
    onCloneClick,
    color = 'secondary'
  } = props;

  return (
    <MuiIconButton
      color={color}
      title={'Dupliceren'}
      onClick={onCloneClick}
    >
      <MuiFileCopyIcon />
    </MuiIconButton>
  );
};

export default CloneActionButton;