import * as allFormatters from '../../../utils/formatters';
import { TransformFunction, TransformOptionsBase } from '../transform-utils';
import { ParetoChartRawData, ParetoChartItem } from '../ParetoChart/paretoChartTransformer'
import { groupObjectBy } from '../../../utils/objects';

interface RD {
  waarde: number;
  label_1: string;
  label_4: string;
  eenheid: string;
}
export interface ParetoChartGroupedRowTransformOptions extends TransformOptionsBase {
/**
 * Set the key to use for bar grouping.
 * @default label_4
*/
  groupKey?: 'label_1' | 'label_2' | 'label_3' | 'label_4' ;
/**
 * Set the key to use for bar item grouping
 * @default label_1
*/
  barItemGroupKey?: 'label_1' | 'label_2' | 'label_3' | 'label_4' ;
/**
 * Creates group total value
 * @default false
*/
  showGroupTotal?: boolean;
}
export interface ParetoChartGroupedRowDataModel {
  rowData: ParetoRowsModel[];
}
export interface ParetoRowsModel {
  bars: ParetoChartItem[];
  group: {
    label: string;
    value: number;
    formattedValue: string
  } | null;
}

const paretoRowsChartTransformer: TransformFunction<ParetoChartGroupedRowDataModel, ParetoChartGroupedRowTransformOptions> = (
  rawData: ParetoChartRawData,
  onError,
  options
) => {
  const keys = Object.keys(rawData);
  const guard = !keys.some((key) => !!rawData[key] && (rawData[key] as any).waarde !== null);

  if (guard) {
    onError('nodata');
    return null;
  }

  const {
    formatters = {},
    formattersOptions = {},
    groupKey = 'label_1',
    barItemGroupKey = 'label_2',
    showGroupTotal = false,
  } = options || {} as ParetoChartGroupedRowTransformOptions;

  let result: ParetoRowsModel[] = [];

  const primaryValueFormatterName = formatters['primaryValue'];
  const primaryValueFormatter = (allFormatters as any)[primaryValueFormatterName];
  const primaryValueFormatterOptions = formattersOptions['primaryValue'];

  const secondaryValueFormatterName = formatters['secondaryValue'];
  const secondaryValueFormatter = (allFormatters as any)[secondaryValueFormatterName];
  const secondaryValueFormatterOptions = formattersOptions['secondaryValue'];

  const labelFormatterName: string = formatters['label'];
  const labelFormatter = (allFormatters as any)[labelFormatterName];
  const labelFormatterOptions = formattersOptions['label'];

  let groupDataModel: { 
    label: string;
    value: number;
    formattedValue: string
  } | null = null;

  const groupedItems =
    groupObjectBy(keys.map((key: string) => rawData[key]), groupKey)
    .map((a: RD[]) => groupObjectBy(a, barItemGroupKey));

  result = groupedItems.map((row: any) => {
    let groupTotal: number = 0;
    let groupLabel: string = '';
    let groupUnit: string = '';

    let bars = row.map(([secondaryValues, primaryValues]: any, index: number) => {
      const { label_2: primaryDimensionLabel, waarde: primaryWaarde, eenheid: primaryEenheid } = primaryValues;
      const { waarde: secondaryWaarde, eenheid: secondaryEenheid, label_1: secondaryDimensionLabel } = secondaryValues;

      const barItem = {
        primaryValue: {
          rawValue: primaryWaarde,
          label: labelFormatter ? labelFormatter(secondaryDimensionLabel as string, labelFormatterOptions) : secondaryDimensionLabel,
          formattedValue: primaryValueFormatter ?
            primaryValueFormatter(primaryWaarde, primaryValueFormatterOptions) :
            allFormatters.formatValue(primaryWaarde, primaryEenheid as ValueUnit, primaryValueFormatterOptions)
        },
        secondaryValue: {
          rawValue: secondaryWaarde,
          label: labelFormatter ? labelFormatter(primaryDimensionLabel as string, labelFormatterOptions) : primaryDimensionLabel,
          formattedValue: secondaryValueFormatter ?
            primaryValueFormatter(secondaryWaarde, secondaryValueFormatterOptions) :
            allFormatters.formatValue(secondaryWaarde, secondaryEenheid as ValueUnit, secondaryValueFormatterOptions)
        }
      };

      groupTotal += primaryWaarde;
      groupLabel = labelFormatter ? labelFormatter(secondaryDimensionLabel as string, labelFormatterOptions) : secondaryDimensionLabel;
      groupUnit = primaryEenheid;

      if(showGroupTotal && index === 0) {
        groupDataModel = {
          label: labelFormatter ? labelFormatter(groupLabel as string, labelFormatterOptions) : groupLabel,
          value: groupTotal,
          formattedValue: primaryValueFormatter ?
            primaryValueFormatter(groupTotal, primaryValueFormatterOptions) :
            allFormatters.formatValue(groupTotal, groupUnit as ValueUnit, primaryValueFormatterOptions)
        }
      }

      return showGroupTotal && index === 0 ? null : barItem 
    });

    return {
      group: groupDataModel,
      bars: bars.filter((bar:ParetoChartItem) => bar)
    }
  });

  return {
    rowData: result
  };

};

export default paretoRowsChartTransformer;