import React from 'react';
import styled from 'styled-components';
import muiTheme from '../../../constants/theme';
import MuiDivider, { DividerProps as MuiDividerProps } from '@material-ui/core/Divider';
import { StyledLegendColor, createLegendBackground } from '../legend-utils/Legend';
import { TooltipOptions } from '../ComponentBaseProps';
import * as tooltipTemplates from '../tooltip-utils/tooltipTemplates';
import { CustomTooltipTemplate } from '../tooltip-utils/tooltipTemplates';
import * as allFormatters from '../../../utils/formatters';

export interface TooltipTemplateProps {
  // NOTE: tooltipOptions originate from view json config.
  tooltipOptions?: TooltipOptions;
  title?: string;
  subtitle?: string;
  values: {
    label: string;
    rawValue?: number;
    formattedValue: string;
    colors: string | string[];
  }[];
}

export const StyledMuiDivider = styled(MuiDivider)`
  && {
    margin: 0.4rem 0;
  }
  `as React.ComponentType<MuiDividerProps>;

export const StyledWrapper = styled.div`
  display: flex;
  font-size: 0.8rem;
  flex-direction: column;
`;

export const StyledValueWrapper = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap ;
`;

export const StyledTitle = styled.strong`
  white-space: nowrap;
`;

export const StyledSubtitle = styled.span`
  color: ${() => muiTheme.palette.secondary.main};
`;

export const StyledLabel = styled.span`
`;


export const StyledValue = styled.strong`
`;

export const addColonSpace = (str: string) => {
  return <>{str}:&nbsp;</>;
};

export const createTitle = (originalTitle: string = '', optionsTitle?: string, titleFormat?: 'prepend' | 'append' | 'replace' | 'hide') => {
  if (titleFormat === 'hide') {
    return undefined;
  }

  let title = originalTitle;

  if (!!optionsTitle) {
    switch (titleFormat) {
      case 'replace':
        title = optionsTitle;
        break;
      case 'prepend':
        title = `${optionsTitle} ${originalTitle} `.trim();
        break;
      case 'append':
        title = `${originalTitle} ${optionsTitle} `.trim();
        break;
      default:
        title = originalTitle;
    }
  }

  return title;
};

const TooltipTemplate = (props: TooltipTemplateProps) => {
  const {
    values,
    tooltipOptions,
    title: originalTitle,
    subtitle: originalSubtitle,
  } = props;

  const {
    templateName,
    hideColors = false,
    title: optionsTitle,
    titleFormat = 'prepend',
    subtitle: optionsSubtitle,
    subTitleFormat = 'replace',
    formatters = {},
    formattersOptions = {}
  } = tooltipOptions || {} as TooltipOptions;

  const title = createTitle(originalTitle, optionsTitle, titleFormat);
  const subtitle = createTitle(originalSubtitle, optionsSubtitle, subTitleFormat);

  if (!!templateName) {
    const customTemplate = (tooltipTemplates as any)[templateName] as CustomTooltipTemplate;

    if (!!customTemplate) {
      return customTemplate({ ...props, title, subtitle });
    }
  }

  return (
    <StyledWrapper>
      {!!title && <StyledTitle>{title}</StyledTitle>}
      {!!subtitle && <StyledSubtitle>{subtitle}</StyledSubtitle>}
      {(!!title || !!subtitle) && <StyledMuiDivider />}
      {values.map(({ label, rawValue, formattedValue, colors: originalColors }, index) => {
        const colors = !!originalColors ?
        Array.isArray(originalColors) ? originalColors : [originalColors] :
        [];

        const formatterName = formatters[`${index + 1}`] || formatters['value'];
        const formatter = (allFormatters as any)[formatterName];
        const formatterOptions = formattersOptions[`${index + 1}`];

        const value = !!formatter ? formatter(rawValue, formatterOptions) : formattedValue;

        return (
          <StyledValueWrapper key={`${label}-${index}`}>
            {colors.length > 0 && !hideColors &&
              <StyledLegendColor legendBackground={createLegendBackground(colors)} />
            }
            <StyledLabel>{label ? addColonSpace(label) : ''}</StyledLabel>
            <StyledValue>{value}</StyledValue>
          </StyledValueWrapper>
        );
      })}
    </StyledWrapper>
  );
};

export default TooltipTemplate;