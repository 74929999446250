import { scaleBand, scaleLinear, scaleOrdinal } from 'd3-scale';

import { BarHorizontal } from 'ppd-library/charts/molecules/BarHorizontal';
import { BarHorizontalGroup } from 'ppd-library/charts/organisms/BarHorizontalGroup';
import React, { useEffect, useRef, useState, createRef } from 'react';
import styled from 'styled-components';

import { ComponentBaseProps } from '../ComponentBaseProps';
import {
  BarChartInBarLabelingMainDataModel,
} from './barChartInBarLabelingMainTransformer';
import { ContentRow } from '../ContentRow';
import { ValueBlockSimple } from 'ppd-library/components/molecules/ValueBlockSimple';
import MuiTypography, { TypographyProps as MuiTypographyProps } from '@material-ui/core/Typography';
import { pxToRem } from '../../../utils/converters';

export interface BarChartInBarLabelingMainThemeProps {
  /**
   * Colors of the bars.
   * Array should have the same length as the number of bars in the data.
   * @default ['#2b4899', ...]
   */
  barColors?: string[];
  /**
   * Color of all the labels.
   * Will take priority below labelColors and labelGradients
   * @default #212121
   */
  labelColor?: string;
  /**
   * Color of all the labels.
   * Array should have the same length as the number of bars in the data.
   * Will take priority below labelGradients and above labelColor.
   * @default undefined
   */
  labelColors?: string[];
  /**
   * // TODO: back-end geeft nu een array van strings ipv array van arrays, b.v. ["[#ffffff, #000000]", "[#ffffff, #000000]", "[#ffffff, #000000]"]
 * Gradient colors of all the labels.
 * Outerarray should have the same length as the number of bars in the data.
 * InnerArray should have precisely 2 string values.
 * Will take priority above labelColors and labelColor.
 * example [['#ffffff', '#000000'], ['#ffffff', '#000000'], ['#ffffff', '#000000']]
 * @default undefined
 */
  labelGradients?: string[];
  fontSize?: string;
  /**
   * label left/right margin
   * @default 10
   */
  textIndent?: number;
  /**
   * Help icon placement
   * @default false
   */
  valueIcon?: string;
}
export interface BarChartInBarLabelingMainProps extends ComponentBaseProps<BarChartInBarLabelingMainThemeProps, BarChartInBarLabelingMainDataModel> {
  chartLabel?: string;
}

const StyledWrapper = styled.div`
  position: relative;
  flex: 1;
`;

const StyledChartHolder = styled.div`

`;

const StyledLabel = styled(MuiTypography)`
  && {
    color: #2b4899;
    font-size: ${pxToRem(14)};
    align-self: flex-start;
    margin-bottom: ${pxToRem(8)};
  }
` as React.ComponentType<MuiTypographyProps>;

const StyledText = styled.text`
  && {
    transition: transform 1000ms;
  }
`;

const TextAnimated = React.forwardRef((props: React.SVGProps<SVGTextElement>, ref: any) => {
  return <StyledText {...props} ref={ref} />;
});

const getTextX = (barWidth: number, textWidth: number, textIndent: number) => {
  const xposition = (barWidth - textIndent) <= textWidth
    ? textIndent
    : (barWidth - textWidth) - textIndent;
  return xposition;
}

const getTextGradient = (textWidth: number, barWidth: number, textIndent: number) => {
  const singleXScale = scaleLinear().domain([0, textWidth]).range([0, 100]);
  const stopValue = barWidth - textIndent;

  return `${singleXScale(stopValue)}%`;
}

//TODO: 
// - chartheight berekenen door height van mainvalue af te trekken van volledige height.
// - in grafieken moeten mui caption typography benaderen.
// - Misschien boxedtekst gebruiken of een tweede text component maken die ook gradients kan.
const BarChartInBarLabelingMain = (props: BarChartInBarLabelingMainProps) => {
  const {
    id = 'noId',
    data,
    // help,
    // showHelp,
    // height,
    chartLabel,
    width = 360,
    hideLabel= {},
    themeProperties
  } = props;

  const { mainValue, barValues: realBarValues } = data;
  const [barValues, setBarValues] = useState(() => realBarValues.map(({ rawValue, ...rest }) => ({ rawValue: 0, ...rest })));
  // NOTE: this sets each bar to 21px fixed height
  const chartHeight = barValues.length * 21;
  // const { height = barValues.length * 21 } = props;

  const {
    labelColor,
    textIndent = 10,
    labelColors = [],
    fontSize = pxToRem(11),
    labelGradients: originalLabelGradients,
    valueIcon
  } = themeProperties || {} as BarChartInBarLabelingMainThemeProps;
  let { barColors = [] } = themeProperties || {} as BarChartInBarLabelingMainThemeProps; 

  let labelGradients = !originalLabelGradients ? [] :
  originalLabelGradients.map((item) => {
    const array = item.replace('[', '').replace(']', '').split(',');
    return array as [string, string];
  });

  if (barColors.length === 0) {
    barColors = barValues.map(() => '#2b4899');
  }

  if (labelGradients.length === 0) {
    if (labelColors.length !== 0) {
      labelGradients = labelColors.map<[string, string]>((color) => ([color, color]));
    } else if (!!labelColor) {
      const color: string = labelColor;
      labelGradients = barValues.map<[string, string]>(() => ([color, color]));
    } else {
      labelGradients = barValues.map<[string, string]>(() => (['#ffffff', '#212121']));
    }
  }

  // TODO: waarom gebruiken we een bargroup?
  const barHorizontalGroupData = barValues.reduce((previous, { label, rawValue }) => {
    return {
      ...previous,
      [label]: rawValue
    };
  }, { key: id || '' });

  const keys = barValues.map(({ label }) => label);
  const maxValue = Math.max(...realBarValues.map(({ rawValue }) => rawValue));

  const singleYScale = scaleBand().domain(keys).range([0, chartHeight]);
  const singleXScale = scaleLinear().domain([0, maxValue]).range([0, width]);
  const barColorScale = () => scaleOrdinal<string, string>()
    .domain(keys)
    .range(barColors);

  const textColorScale = scaleOrdinal<string, [string, string]>()
    .domain(keys)
    .range(labelGradients);

  const [textRefDimensions, setTextRefDimensions] = useState(barValues.map(() => ({ height: chartHeight / barValues.length, width: 0 })));
  const elementsRef = useRef(barValues.map(() => createRef<SVGTextElement>()));

  useEffect(() => {
    const dimensions = elementsRef.current.map((ref) => {
      const { height, width } = (ref.current as SVGTextElement).getBoundingClientRect();

      return { height, width };
    });

    setTextRefDimensions(dimensions);
    setBarValues(realBarValues);
  }, [realBarValues]);

  return (
    <StyledWrapper>
      <ContentRow>
        <ValueBlockSimple
          valueVariant={'h2'}
          valueSize={'medium'}
          value={mainValue.formattedValue}
          label={!hideLabel['mainValue'] ? mainValue.label : ''}
          iconOptions={valueIcon ? { iconName: valueIcon, fontSize: 'inherit' } : undefined}
        // afterValueContent={
        //   <>
        //     {!!showHelp &&
        //       <HelpTooltipButton
        //         id={tileId}
        //         anchorType={'iconButton'}
        //       />
        //     }
        //   </>
        // }
        />
      </ContentRow>
      <ContentRow>
        <div>
          {!!chartLabel &&
            <StyledLabel variant={'h5'}>
              {chartLabel}
            </StyledLabel>
          }
          <StyledChartHolder>
            <svg
              width={width}
              height={chartHeight}
              preserveAspectRatio={'none'}
            >
              <BarHorizontalGroup
                y={0}
                keys={keys}
                yScale={singleYScale}
                xScale={singleXScale}
                colorScale={barColorScale()}
                data={barHorizontalGroupData}
                id={`${id}-bar-horizontal-group`}
              >
                {(group) => (
                  <>
                    {group.map((barProps, i) => {
                      let label = (barValues[i].label || '').replace(/\s/g, '-');
                      const gradientId = `gradientText-${id}-${label}-${i}`;

                      return (
                        <React.Fragment key={`${barProps.id}`}>
                          <g>
                            <BarHorizontal
                              x={0}
                              y={barProps.y}
                              fill={barProps.fill}
                              id={`${barProps.id}-bar`}
                              width={barProps.width}
                              height={barProps.height}
                            />
                            <TextAnimated
                              ref={elementsRef.current[i]}
                              // TODO: hoe omgaan met font-sizes, moet altijd hetzelfde zijn voor elke chart?
                              fontSize={fontSize}
                              fill={`url(#${gradientId})`}
                              y={(barProps.y + barProps.height / 2) + (textRefDimensions[i].height / 2.8)}
                              transform={`translate(${getTextX(barProps.width, textRefDimensions[i].width, textIndent)}, 0)`}
                            >
                              {`${barValues[i].formattedValue}`} {`${barValues[i].label}`}
                            </TextAnimated>
                          </g>
                          <defs>
                            <linearGradient id={gradientId}>
                              <stop offset={getTextGradient(textRefDimensions[i].width, barProps.width, textIndent)} stopColor={textColorScale(barValues[i].label)[0]} />
                              <stop stopColor={textColorScale(barValues[i].label)[1]} />
                            </linearGradient>
                          </defs>
                        </React.Fragment>
                      );
                    })}
                  </>
                )}
              </BarHorizontalGroup>
            </svg>
          </StyledChartHolder>
        </div>
      </ContentRow>
    </StyledWrapper>
  )
};

export default BarChartInBarLabelingMain;
