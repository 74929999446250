import MuiLinearProgress from '@material-ui/core/LinearProgress';
import { TilePlaceholder } from 'ppd-library/components/organisms/Tile';
import React, { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';

import muiTheme from '../../../constants/theme';
import { TransformErrorType } from '../../content/transform-utils';

export interface LoadingBoundaryProps {
  isLoading: boolean;
  children: () => ReactNode;
  placeholderText: string | null;
  error: TransformErrorType | null;
}

const StyledProgressWrapper = styled.div`
    position: absolute;
    left: -${muiTheme.spacing(2)}px;
    top: -${muiTheme.spacing(2)}px;
    width: ${() => `calc(100% + ${muiTheme.spacing(4)}px)`};

/* Note!: if header has custom padding 6px in globals.ts
    left: -12px;
    top: -12px;
    width: ${() => `calc(100% + 24px)`};
*/
`;

const StyledPlaceholder = styled(TilePlaceholder)`
  flex: 1;
  align-self: center;
  justify-self: center;
  .emblem{
    width: auto;
    height: fit-content;
  }
`;

const StyledLoadingPlaceholder = styled.div`
  position: absolute;
  display:flex;
  flex: 1;
  width: 100%;
  height: 100%;
  z-index: 100000;
  text-align: center;
  text-align: center;
  align-self: center;
  align-items: center;
  justify-self: center;
  justify-content: center;
  background-color: white;
`;

// TODO: deze doet te veel: loading bar en errors en placeholderText
const LoadingBoundary = (props: LoadingBoundaryProps) => {
  const {
    error,
    children,
    isLoading,
    placeholderText
  } = props;

  const [initiated, setInitiated] = useState(true);

  useEffect(() => {
    if (isLoading === false && initiated === true) {
      setInitiated(false);
    }
  }, [isLoading, initiated]);

  return (
    <>
      {isLoading && !placeholderText &&
        <StyledProgressWrapper>
          <MuiLinearProgress />
        </StyledProgressWrapper>
      }

      {/** 
        * StyledLoadingPlaceholder will be displayed on first init of the container 
        * and hidden forever after isLoading === false for the first time.
        * Will act as an overlay and overlap / hide all other content.
        */
      }
      {initiated && !placeholderText && <StyledLoadingPlaceholder>Aan het laden...</StyledLoadingPlaceholder>}
      {!error && !placeholderText && children()}
      {(error || placeholderText) &&
        <StyledPlaceholder
          message={
            !!placeholderText ? placeholderText :
              error === 'error' ? 'Fout bij het laden van de tegel content' :
                error === 'nodata' ? 'Geen data voor de geselecteerde eenheid' :
                  error === 'onlyzeroes' ? 'De data bevat alleen 0 waardes voor de geselecteerde eenheid' :
                    'Fout bij het laden van de tegel content'
          }
          theme={
            {
              palette: {
                secondary: {
                  main: muiTheme.palette.secondary.light
                }
              }
            }
          }
        />
      }
    </>
  );
};

export default LoadingBoundary;