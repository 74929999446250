import { TransformFunction, TransformOptionsBase } from '../transform-utils';
import { calcNumberOfColumns } from '../transform-utils';
import * as allFormatters from '../../../utils/formatters';

interface RD {
  waarde: number;
  label_1: string;
  label_2?: string;
  label_3?: string;
  label_4: string;
  eenheid: string;
}

export interface BarChartRawData {
  [key: string]: RD;
}

export interface BarChartXThreshold {
  label: string;
  barKey: string;
  barIndex: number;
}

export interface BarChartTransformOptions extends TransformOptionsBase {
  reverse?: boolean;
  /**
   * Set the key to use for the y axis.
   * @default label_1
   */
  yLabelKey?: 'label_1' | 'label_2' | 'label_3';
}

export interface BarChartDataModel {
  key: string;
  label: string;
  valueKey: string;
  rawValue: number;
  formattedValue: string;
  secondaryValues?: BarChartDataModel;
}

export interface BarChartBarDataModel {
  barsModel: BarChartDataModel [];
}

const barChartTransformer: TransformFunction<BarChartBarDataModel, BarChartTransformOptions> = (
  rawData: BarChartRawData,
  onError,
  options
) => {

  const keys = Object.keys(rawData);
  const guard = !keys.some((key) => rawData[key].waarde !== null);

  if (guard) {
    onError('nodata');
    return null;
  }

  const {
    reverse = false,
    formatters = {},
    formattersOptions = {},
    yLabelKey = 'label_1',
  } = options || {} as BarChartTransformOptions;

  const valueFormatterName = formatters['value'];
  const valueFormatter = (allFormatters as any)[valueFormatterName];
  const valueFormatterOptions = formattersOptions['value'];

  const numberOfColumns = calcNumberOfColumns(keys);

  let chartModel: BarChartDataModel[] = [];

  chartModel = keys.map((key: string, i) => {
    const item = rawData[key];
    const { label_1, label_4, waarde, eenheid } = item;
    let chartItemObjectKey: string = '';

    const formattedValue = valueFormatter ?
      valueFormatter(waarde, valueFormatterOptions) :
      allFormatters.formatValue(waarde, eenheid as ValueUnit, valueFormatterOptions);

    chartItemObjectKey = `${i}_${item[yLabelKey]}`;

    return {
      key: chartItemObjectKey,
      label: label_4,
      formattedValue,
      valueKey: label_1,
      rawValue: waarde,
    } as BarChartDataModel;
  });

  if(numberOfColumns > 1) {
    // if the data is delivered in 2 columns, an extra property "secondaryValues" will contain the secondary columns values
    let tempModel: BarChartDataModel = { key: '', label: '',formattedValue:'', valueKey: '', rawValue: 0};
    const modelWithSecondaryValues: BarChartDataModel[] = [];

    chartModel.forEach((model: BarChartDataModel, index: number) => {
      if(index % 2 === 0) { tempModel = model }
      if(index % 2 === 1) {
        modelWithSecondaryValues.push({...tempModel, secondaryValues: model })
        tempModel = { key: '', label: '', formattedValue:'', valueKey: '', rawValue: 0 };
      }
    });

    chartModel = modelWithSecondaryValues;
  }

  let barCharDataModel: BarChartDataModel[] = [];
    barCharDataModel = reverse ? chartModel.reverse() : chartModel;

    return { barsModel: barCharDataModel };
};

export default barChartTransformer;