import Chart from 'ppd-library/charts/Chart';
import { scales } from 'ppd-library/charts/chartUtils';
import { LineConnected } from 'ppd-library/charts/molecules/LineConnected';
import { AxisBottom as HorizontalAxis } from 'ppd-library/charts/organisms/Axis';
import { BarHorizontalStacked } from 'ppd-library/charts/organisms/BarHorizontalStacked';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { reduceObjectByKey } from '../../../utils';
import { HelpTooltipButton } from '../../help/HelpTooltipButton';
import { ComponentBaseProps, Margins } from '../ComponentBaseProps';
import { StackedLinedLabeledBarDoubleDataModel, DoubleStackedLinedLabeledBarsDataModel, BarDataModel } from './stackedLinedLabeledBarDoubleTransformer';

import { Tooltip } from 'ppd-library/charts//organisms/Tooltip';
import { getSVGMousePosition, useTooltipPosition, TooltipTemplate } from '../tooltip-utils';
import { Legend, useLegend } from '../legend-utils';
import { LineThresholdVertical } from 'ppd-library/charts/molecules/LineThreshold/LineThresholdVertical';

export interface StackedLinedLabeledBarDoubleThemeProps {
  barColors?: string[];
  lineColor?: string;
  topBarAxisColors?: [string, string];
  bottomBarAxisColor?: string;
  bottomBarHeadingSize?: string;
  bottomBarHeadingColor?: string;
}
interface StackedLinedLabeledBarDoubleProps extends ComponentBaseProps<StackedLinedLabeledBarDoubleThemeProps, StackedLinedLabeledBarDoubleDataModel> {
  topBarHeight?: number;
  topBarYPosition?: number;
  bottomBarLabel?: string;
  bottomBarHeight?: number;
  bottomBarYPosition?: number;
  /**
   * @default true
   */
  doubleRowHeight?: boolean;
  showTooltip?: boolean;
  showThreshold?: boolean;
  threshold?: {
    label?: string;
    rawValue: number;
    formattedValue?: string;
  };
}

const StyledWrapper = styled.div`
  position: relative;
  flex: 1;
`;

const StyledChartHeading = styled.text<{ fill: string, fontSize: string }>`
  fill: ${({ fill }) => fill};
  font-size: ${({ fontSize }) => fontSize};
  font-weight: bold;
`;

const StyledLabel = styled.tspan`
  fill: #2b4899;
  font-size: 0.8rem;
`;

// TODO: rename
const StackedLinedLabeledBarDouble = (props: StackedLinedLabeledBarDoubleProps) => {
  const {
    id,
    data,
    help,
    showHelp,
    threshold,
    tooltipOptions,
    bottomBarLabel,
    width = 400,
    topBarHeight = 24,
    legendOptions = {},
    showTooltip = true,
    showThreshold = true,
    topBarYPosition = 10,
    bottomBarHeight = 14,
    themeProperties,
    bottomBarYPosition = 170,
    height: originalHeight = 200,
    margins: originalMargins = {} as Margins,
  } = props;

  const {
    barColors = ['#00315C', '#2b4899', '#80A3C1', '#DFE8F0'],
    lineColor = '#636363',
    topBarAxisColors = ['#212121', '#0072D6'],
    bottomBarAxisColor = '#212121',
    bottomBarHeadingSize = '0.85rem',
    bottomBarHeadingColor = '#212121'

  } = themeProperties || {} as StackedLinedLabeledBarDoubleThemeProps;

  const { left = 0, right = 0, top = 10, bottom = 0 } = originalMargins;
  const margins = { left, top, right, bottom };

  const { topBarModel, bottomBarModel } = data;

  const topBarKeys = Object.keys(topBarModel);
  const topBarRawValues = topBarKeys.map((key) => topBarModel[key].primaryValue.rawValue);
  const topBarMaxValue = topBarRawValues.reduce((a: number, b: number) => a + b);

  const topBarKeyValuePairs: { [key: string]: number } = {};
  topBarKeys.forEach((key: string) => topBarKeyValuePairs[key] = topBarModel[key].primaryValue.rawValue);

  const bottomBarKeys = Object.keys(bottomBarModel);
  const bottomBarRawValues = bottomBarKeys.map((key) => bottomBarModel[key].primaryValue.rawValue);
  const bottomBarMaxValue = bottomBarRawValues.reduce((a: number, b: number) => a + b);

  const bottomBarKeyValuePairs: { [key: string]: number } = {};
  bottomBarKeys.forEach((key: string) => bottomBarKeyValuePairs[key] = bottomBarModel[key].primaryValue.rawValue);


  const colorScaleValues: { [key: string]: string } = {};
  topBarKeys.map((key: string, index: number) => colorScaleValues[key] = barColors[index] ? barColors[index] : '#000000');

  const xScaleTop = scales.numericXScale(0, topBarMaxValue, width, margins.left, margins.right);
  const xScaleBottom = scales.numericXScale(0, bottomBarMaxValue, width, margins.left, margins.right);
  const colorScale = scales.colorScale(colorScaleValues);


  const handleMouseMove = (event: React.MouseEvent<Element, MouseEvent>, index: number, data: DoubleStackedLinedLabeledBarsDataModel) => {
    const { x, y } = getSVGMousePosition(event as React.MouseEvent<SVGElement, MouseEvent>);
    setTooltipPosition({ visible: true, x, y, index, data });
  };

  const handleMouseLeave = () => {
    setTooltipPosition({ ...tooltipPosition, visible: false });
  };

  const legendElementRef = React.createRef<HTMLDivElement>();
  const { legendHeight } = useLegend(originalHeight, legendElementRef);
  const height = originalHeight - legendHeight;
  const { tooltipPosition, setTooltipPosition } = useTooltipPosition<DoubleStackedLinedLabeledBarsDataModel>();

  const [initiated, setInitiated] = useState(false);
  useEffect(() => {
    setInitiated(true);
  }, []);

  // TODO: Make 1 function with generics instead of any
  // DoubleStackedLinedLabeledTopBarDataModel
  // DoubleStackedLinedLabeledSecondBarDataModel
  function getBarData(initiated: boolean, barModel: any) {
    const dataObj: any = {};
    const barKeys = Object.keys(barModel);

    barKeys.forEach((key: string) => dataObj[key] = initiated
      ? barModel[key].primaryValue
        ? barModel[key].primaryValue.rawValue
        : barModel[key].rawValue
      : 0
    );
    return dataObj;
  }

  const getFixedPosition = (index: number) => 100 / topBarKeys.length * index;
  const fixedTicks = topBarKeys.map((_key, index) => getFixedPosition(index));
  const xFixedScale = scales.numericXScale(0, 100, width, margins.left, margins.right);

  return (
    <StyledWrapper>
      <Chart
        width={width}
        height={height}
        preserveAspectRatio={'none'}
      >
        <g transform={`translate(0, ${topBarYPosition})`} id={`${id}-doublestacked-lined-labeledbar-top`}>
          <HorizontalAxis
            y={0}
            scale={xFixedScale}
            id={`${id}-horizontal-axis-topbar-1`}
            tickValues={fixedTicks}
            tickTextAnchor={'start'}
            chartHeight={0}
            tickFormat={(_, index) => `${topBarModel[topBarKeys[index]].primaryValue.formattedValue}`}
            theme={{ colors: { tick: topBarAxisColors[0] } }}
          />
          <HorizontalAxis
            scale={xFixedScale}
            id={`${id}-horizontal-axis-topbar-2`}
            tickValues={fixedTicks}
            tickTextAnchor={'start'}
            chartHeight={0}
            y={16}
            tickFormat={(_, index) => {
              const secondaryValue = topBarModel[topBarKeys[index]].secondaryValue;
              return secondaryValue ? secondaryValue.formattedValue : ''
            }}
            theme={{ colors: { tick: topBarAxisColors[1] } }}
          />
          <BarHorizontalStacked
            y={72}
            xScale={xScaleTop}
            height={topBarHeight}
            colorScale={colorScale}
            keys={topBarKeys}
            id={`${id}-bar-horizontal-stacked-top`}
            data={{
              key: `${id}-bar-horizontal-stacked-top`,
              ...getBarData(initiated, topBarModel)
            }}
            mouseMove={(ev) => handleMouseMove(ev, 0, data.topBarModel)}
            mouseLeave={handleMouseLeave}
          />
          <g transform={'translate(1, 70)'}>
            {
              topBarKeys.map((key, index) => (
                <LineConnected
                  key={key}
                  id={`poly-${key}-top`}
                  animationDelay={1000}
                  color={lineColor}
                  fromPoint={{ x: xScaleTop(reduceObjectByKey(topBarKeyValuePairs, topBarKeys, key, false)), y: 0 }}
                  toPoints={[
                    { x: xScaleTop(reduceObjectByKey(topBarKeyValuePairs, topBarKeys, key, false)), y: -6 },
                    { x: xFixedScale(fixedTicks[index]), y: -28 }
                  ]}
                />
              ))
            }
          </g>
          {showThreshold && !!threshold && threshold.rawValue != null &&
            <LineThresholdVertical
              labelDominantBaseline={0}
              y={74}
              lineWidth={1}
              lineHeight={66}
              showCircle={false}
              id={`${id}-line-vertical-threshold`}
              labelPadding={[2, 3, 2, 3]}
              x={initiated ? xFixedScale(threshold.rawValue) : 0}
              labelPos={[threshold.rawValue > 75 ? 'left' : 'right', 'bottom']}
              theme={{
                lineColor: 'orange', // TODO: palette secondary?
                backgroundColor: 'transparent'
              }}
              label={
                <>
                  {
                    !!threshold.label &&
                    <StyledLabel x={threshold.rawValue > 75 ? 0 : 7} dy={10}>
                      {threshold.label}
                    </StyledLabel>
                  }
                  {
                    !!threshold.formattedValue &&
                    <StyledLabel x={threshold.rawValue > 75 ? 0 : 7} dy={!!threshold.label ? 16 : 10}>
                      {threshold.formattedValue}
                    </StyledLabel>
                  }
                </>
              }
            />
          }
        </g>

        <g transform={`translate(0, ${bottomBarYPosition})`} id={`${id}-doublestacked-lined-labeledbar-bottom`}>
          <StyledChartHeading fill={bottomBarHeadingColor} fontSize={bottomBarHeadingSize} y={-8}>
            {bottomBarLabel}
          </StyledChartHeading>
          <HorizontalAxis
            y={0}
            // y={bottomBarModel[bottomBarKeys[0]].secondaryValue ? 0 : 20}
            scale={xFixedScale}
            id={`${id}-horizontal-axis-bottombar-1`}
            tickValues={fixedTicks}
            tickTextAnchor={'start'}
            chartHeight={0}
            tickFormat={(_, index) => `${bottomBarModel[bottomBarKeys[index]].primaryValue.formattedValue}`}
            theme={{ colors: { tick: bottomBarAxisColor } }}
          />
          {/* <HorizontalAxis
            scale={xFixedScale}
            id={`${id}-horizontal-axis-bottombar-2`}
            tickValues={fixedTicks}
            tickTextAnchor={'start'}
            chartHeight={0}
            y={16}
            tickFormat={(_, index) => {

              const secondaryValue = bottomBarModel[bottomBarKeys[index]].secondaryValue;
              return secondaryValue ? secondaryValue.formattedValue : ''
            }}
            theme={{ colors: { tick: topBarAxisColors[1] } }}
          /> */}
          <BarHorizontalStacked
            y={72}
            xScale={xScaleBottom}
            height={bottomBarHeight}
            colorScale={colorScale}
            keys={bottomBarKeys}
            id={`${id}-bar-horizontal-stacked-bottom`}
            data={{
              key: `${id}-bar-horizontal-stacked-bottom`,
              ...getBarData(initiated, bottomBarModel)
            }}
          // mouseMove={(ev) => handleMouseMove(ev, 0, data.bottomBarModel as any)}
          // mouseLeave={handleMouseLeave}
          />
          <g transform={'translate(1, 74)'}> {
            bottomBarKeys.map((key, index) => (
              <LineConnected
                key={key}
                id={`poly-${key}-bottom`}
                animationDelay={1000}
                color={lineColor}
                fromPoint={{ x: xScaleBottom(reduceObjectByKey(bottomBarKeyValuePairs, bottomBarKeys, key, false)), y: 0 }}
                toPoints={[
                  { x: xScaleBottom(reduceObjectByKey(bottomBarKeyValuePairs, bottomBarKeys, key, false)), y: -6 },
                  { x: xFixedScale(fixedTicks[index]), y: -28 }
                ]}
              />))
          }
          </g>
        </g>
      </Chart>
      <Tooltip
        top={tooltipPosition.y}
        left={tooltipPosition.x}
        visible={showTooltip && tooltipPosition.visible}
        theme={{
          transition: false
        }}
      >
        {() => {
          const barModel = tooltipPosition.data as DoubleStackedLinedLabeledBarsDataModel;

          return (
            <TooltipTemplate
              tooltipOptions={tooltipOptions}
              values={
                Object.keys(barModel).map((key: string) => {
                  const item = barModel[key] as { primaryValue: BarDataModel, secondaryValue: BarDataModel };
                  const { primaryValue } = item;
                  const { secondaryValue } = item;
                  const formattedValue = secondaryValue ? `${primaryValue.formattedValue} (${secondaryValue.formattedValue})` : primaryValue.formattedValue
                  return (
                    {
                      label: key,
                      formattedValue,
                      colors: [colorScale(key)]
                    }
                  )
                })
              }
            />
          );
        }}
      </Tooltip>
      <Legend
        ref={legendElementRef}
        legendOptions={{ orientation: 'horizontal', ...legendOptions }}
        values={Object.keys(data.topBarModel).map((key) => {
          return {
            label: key,
            colors: [colorScale(key)]
          };
        })}
        afterLegendContent={
          <>
            {!!showHelp && !!help && help.some(({ position }) => position === 99) &&
              <HelpTooltipButton
                anchorType={'iconButton'}
                helpId={(help.find(({ position }) => position === 99) as { helpId: number }).helpId}
              />

            }
          </>
        }
      />
    </StyledWrapper>
  )
};

export default StackedLinedLabeledBarDouble;
