import { TransformFunction, TransformOptionsBase } from '../transform-utils';
import { calcNumberOfRows, calcNumberOfColumns, padLeftWithZeros } from '../transform-utils';
import * as allFormatters from '../../../utils/formatters';

interface RD {
  waarde: number;
  label_1: string;
  label_2?: string;
  label_3?: string;
  label_4: string;
  eenheid: string;
}

export interface LineChartRawData {
  [key: string]: RD;
}

export interface LineChartTransformOptions extends TransformOptionsBase {
  reverse?: boolean;
  /**
   * Set the key to use for the x axis.
   * @default label_1
   */
  xLabelKey?: 'label_1' | 'label_2' | 'label_3';

  /**
   * key to use for defining xthresholds
   * @default label_1
   */
  xThresholdKey?: 'label_1' | 'label_2' | 'label_3';
  /**
  * Set the key to use as label in the mouse-over
  * @default label_4
  */
  valueLabelKeys?: ('label_1' | 'label_2' | 'label_3' | 'label_4')[];
  /**
   * Append the threshold value to the xLabel (if there is a threshold).
   * For example xLabel is 'Augustus' and threshold is '2020', xLabel will be Augustus 2020
   * @default true
   */
  appendThresholdToXLabel?: boolean;
}

export interface LineChartPointDataModel {
  label: string;
  xLabel: string;
  rawValue: number;
  shortXLabel?: string;
  formattedValue: string;
}

export interface LineChartLineDataModel {
  points: LineChartPointDataModel[];
}

export interface LineChartXThreshold {
  label: string;
  pointIndex: number;
}

export interface LineChartDataModel {
  lines: LineChartLineDataModel[];
  xThreshold: LineChartXThreshold[];
}

const lineChartTransformer: TransformFunction<LineChartDataModel, LineChartTransformOptions> = (
  rawData: LineChartRawData,
  onError,
  options
) => {

  const result: LineChartDataModel = {
    lines: [],
    xThreshold: []
  };

  const keys = Object.keys(rawData);
  const guard = !keys.some((key) => rawData[key].waarde !== null);

  if (guard) {
    onError('nodata');
    return null;
  }

  const {
    reverse = false,
    formatters = {},
    xLabelKey = 'label_2',
    xThresholdKey = 'label_1',
    valueLabelKeys = ['label_4'] as ('label_1' | 'label_2' | 'label_3' | 'label_4')[],
    appendThresholdToXLabel = true,
    formattersOptions = {}
  } = options || {} as LineChartTransformOptions;


  const numberOfLines = calcNumberOfColumns(keys);
  const numberOfPointsPerLine = calcNumberOfRows(keys);

  for (let lineIndex = 0; lineIndex < numberOfLines; lineIndex++) {

    let xThresholdLabel: string | undefined;
    let rowObjects: RD[] = [];
    for (let pointIndex = 0; pointIndex < numberOfPointsPerLine; pointIndex++) {

      let row = padLeftWithZeros(pointIndex, 2);
      let col = padLeftWithZeros(lineIndex, 2);

      let key = `val${row}_${col}`;
      const item = rawData[key];

      rowObjects.push(item);
    }

    const line: LineChartLineDataModel = {
      points: []
    };

    rowObjects.forEach(({ waarde, eenheid, ...labels }, pointIndex) => {
      const valueFormatterName = formatters['value'];
      const valueFormatterOptions = formattersOptions['value'];
      const valueFormatter = (allFormatters as any)[valueFormatterName];
      const labelFormatterName: string = formatters['label'];
      const labelFormatterOptions = formattersOptions['label'];
      const labelFormatter = (allFormatters as any)[labelFormatterName];

      const xLabelFormatterName: string = formatters['xLabel'];
      const xLabelFormatterOptions = formattersOptions['xLabel'];
      const xLabelFormatter = (allFormatters as any)[xLabelFormatterName];
      const shortXLabelFormatterName: string = formatters['shortXLabel'];
      const shortXLabelFormatterOptions = formattersOptions['shortXLabel'];
      const shortXLabelFormatter = (allFormatters as any)[shortXLabelFormatterName];

      let xLabel = xLabelFormatter ? xLabelFormatter(labels[xLabelKey] as string, xLabelFormatterOptions) : labels[xLabelKey] as string;
      if (!!labels[xThresholdKey] && appendThresholdToXLabel) {
        xLabel = `${xLabel} ${labels[xThresholdKey]}`;
      }

      const rawLabel = valueLabelKeys.reduce((previous, key) => `${previous} ${labels[key]}`, '');
      const point = {
        xLabel,
        rawValue: waarde,
        label: labelFormatter ? labelFormatter(rawLabel, labelFormatterOptions) : rawLabel,
        formattedValue: valueFormatter ? valueFormatter(waarde, valueFormatterOptions) : allFormatters.formatValue(waarde, eenheid as ValueUnit, valueFormatterOptions),
        shortXLabel: xLabelFormatter ? shortXLabelFormatter(labels[xLabelKey] as string, shortXLabelFormatterOptions) : labels[xLabelKey] as string,
      };

      reverse ? line.points.unshift(point) : line.points.push(point);

      if (lineIndex === 0 && !!xThresholdLabel && !!labels[xThresholdKey] && labels[xThresholdKey] !== xThresholdLabel) {
        result.xThreshold.push({
          pointIndex: reverse ? (rowObjects.length - 1) - pointIndex : pointIndex,
          label: xThresholdLabel
        });
      }

      xThresholdLabel = labels[xThresholdKey];
    });

    result.lines.push(line);
  }
  return result;
};

export default lineChartTransformer;