import { scaleOrdinal } from 'd3';
import { Line } from 'ppd-library/charts//atoms/Line';
import Chart from 'ppd-library/charts/Chart';
import { scales } from 'ppd-library/charts/chartUtils';
import { BarVertical } from 'ppd-library/charts/molecules/BarVertical';
import { LineThresholdVertical } from 'ppd-library/charts/molecules/LineThreshold';
import { AxisBottom, AxisLeft } from 'ppd-library/charts/organisms/Axis';
import { HorizontalGridLines } from 'ppd-library/charts/organisms/GridLines';
import { Tooltip } from 'ppd-library/charts/organisms/Tooltip';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import * as allFormatters from '../../../utils/formatters';
import { ComponentBaseProps, Margins } from '../ComponentBaseProps';
import { Legend, useLegend } from '../legend-utils';
import { getSVGMousePosition, TooltipTemplate, useTooltipPosition } from '../tooltip-utils';
import {
  ColumnChartDeviationDataModel,
  ColumnChartDeviationGroupModel,
} from './columnChartDeviationListTransformer';

export interface StackedColorProp {
  [key: string]: string;
}
export interface BarColors {
  positive: string;
  negative: string;
  neutral?: string;
}

export interface ColumnChartDeviationThemeProps {
  fixedLabelColor?: { [key: string] : string }
  barColors?: BarColors;
  zeroLineColor?: string;
  zeroLineWidth?: number;
}
export interface ColumnChartDeviationProps extends ComponentBaseProps<ColumnChartDeviationThemeProps, ColumnChartDeviationDataModel> {
  showTooltip?: boolean;
  showThreshold?: boolean;
  /**
   * @default 10
   */
  yNumberOfTicks?: number;
  /**
   * Set a threshold in height pixels to show half the number of y ticks (5).
   * Will not be used when 'yNumberOfTicks' is explicitly set.
   */
  yHalfNumberOfTicks?: number;
    /**
   * Set the y axus maximum value to a minimum value, so it can go higher but not lower.
   */
  yMinMax?: number;
}

const StyledWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const StyledChartHolder = styled.div``;

const ColumnChartDeviation = (props: ColumnChartDeviationProps) => {
  const {
    id,
    data,
    yMinMax,
    // help,
    // showHelp,
    tooltipOptions,
    yNumberOfTicks: originalYNumberOfTicks,
    width = 500,
    formatters = {},
    showTooltip = true,
    legendOptions = {},
    showThreshold = true,
    themeProperties,
    formattersOptions = {},
    yHalfNumberOfTicks = 260,
    height: originalHeight = 450,
    margins: originalMargins = {} as Margins
  } = props;

  const [initiated, setInitiated] = useState(false);
  const { left = 50, right = 5, top = 10, bottom = showThreshold ? 50 : 30 } = originalMargins;
  let margins = { left, top, right, bottom };

  const {
    zeroLineColor = '#000000',
    zeroLineWidth = 1,
    fixedLabelColor,
    barColors = { positive: '#2b4899', negative: 'orange', neutral: 'gray' },
  } = themeProperties || {} as ColumnChartDeviationThemeProps;

  // TODO: isGroupModel wegwerken (of altijd een 'group' gebruiken of twee components van maken)
  const { groups, xThreshold, isGroupModel = false } = data;

  const ticks =
    //isGroupModel ?  groups.map((items: ColumnChartDeviationGroupModel) => (items as any)[0].key) :
    groups.map((group: ColumnChartDeviationGroupModel) => group.key)

  const getMinMaxValueFromData = () => {
    const minMaxValue: number[] = [];

    groups.forEach((group) => {
      group.valueKeys.forEach((key) => {
        minMaxValue.push(group.rawValues[key]);
      });
    });

    const minimalValue = Math.min(...minMaxValue);
    const maximalValue = Math.max(...minMaxValue);

    return {
      maxValue: maximalValue < 0 ? 0 : maximalValue,
      minValue: minimalValue > 0 ? 0 : minimalValue
    };
  }
  const legendElementRef = React.createRef<HTMLDivElement>();
  const { legendHeight } = useLegend(originalHeight, legendElementRef);
  const height = !!legendOptions && legendOptions.hideLegend !== true ? originalHeight - legendHeight : originalHeight;

  let yNumberOfTicks = originalYNumberOfTicks || 10;
  yNumberOfTicks = height < yHalfNumberOfTicks ? Math.ceil(yNumberOfTicks / 2) : yNumberOfTicks;

  const yAxisTickFormatterName = formatters['yAxisTick'];
  const yAxisTickFormatter = (allFormatters as any)[yAxisTickFormatterName] || allFormatters.formatNumber;
  const yAxisTickFormatterOptions = formattersOptions['yAxisTick'];

  const { minValue, maxValue } = getMinMaxValueFromData();

  const maxYValue = !!yMinMax && yMinMax > maxValue ? yMinMax : maxValue;

  const xScale = scales.stringXScale(ticks, width, margins.left, margins.right, 0.1);
  const yScale = scales.numericYScale(minValue, maxYValue, height, margins.bottom, margins.top).nice();
  const xScaleTickOffset = xScale.bandwidth() / 2;

  const createEmptyColumnData = (column: ColumnChartDeviationGroupModel) => {
    let rawValues = {} as { [key: string]: number };
    column.valueKeys.forEach((key) => rawValues[key] = 0);

    const emptyColumnData = { ...column, rawValues };

    return emptyColumnData;
  };

  const handleMouseMove = (event: React.MouseEvent<Element, MouseEvent>, index: number, data: ColumnChartDeviationGroupModel) => {
    const { x, y } = getSVGMousePosition(event as React.MouseEvent<SVGElement, MouseEvent>);

    setTooltipPosition({ visible: true, x, y, index, data });
  };

  const handleMouseLeave = () => {
    setTooltipPosition({ ...tooltipPosition, visible: false });
  };

  const { tooltipPosition, setTooltipPosition } = useTooltipPosition<ColumnChartDeviationGroupModel>();

  const colorNames = Object.keys(barColors);
  const colors = colorNames.map((key) => (barColors as any)[key]);
  const colorScale = scaleOrdinal<string, string>()
    .domain([...colorNames])
    .range([...colors]);

  useEffect(() => {
    setInitiated(true);
  }, []);

  return (
    <StyledWrapper>
      <StyledChartHolder>
        <Chart height={height}>
          <HorizontalGridLines
            scale={yScale}
            x={margins.left}
            numberOfTicks={yNumberOfTicks}
            id={`${id}-horizontal-grid-lines`}
            lineLength={width - margins.left - margins.right}
          />

          {!!isGroupModel && groups.map((group, columnIndex) => {
            // TODO: dit moet gewoon hetzelfde werken als remainderGroup
            return (
              <g
                key={columnIndex}
                onMouseMove={(ev) => handleMouseMove(ev, columnIndex, groups[columnIndex])}
                onMouseLeave={handleMouseLeave}
              >
                {
                  group.valueKeys.map((key, index: number) => {
                    const column = initiated ? group : createEmptyColumnData(group);
                    const { rawValues } = column;
                    const value = rawValues[key];
                    return (
                      <BarVertical
                        x={xScale(group.key) as number}
                        id={`${id}-column-chart-deviation-${key}`}
                        key={`${id}-column-chart-deviation-${columnIndex}-${index}`}
                        width={xScale.bandwidth()}
                        height={value < 0 ? (yScale(value) - yScale(0)) : (yScale(0) - yScale(value))}
                        y={value < 0 ? yScale(0) : yScale(value)}
                        fill={colorScale(fixedLabelColor && Object.keys(fixedLabelColor)[0] === key ? fixedLabelColor[key]  : value < 0 ? 'negative' : 'positive')}
                      />
                    )
                  })
                }
              </g>
            )
          })}

          {!isGroupModel && groups.map((columnData, columnIndex) => {
            const column = initiated ? columnData : createEmptyColumnData(columnData);
            const { key, valueKeys, rawValues } = column;
            const value = rawValues[valueKeys[0]];

            return (
              <g
                key={key}
                onMouseMove={(ev) => handleMouseMove(ev, columnIndex, groups[columnIndex])}
                onMouseLeave={handleMouseLeave}
              >
                <BarVertical
                  x={xScale(key) as number}
                  id={`${id}-column-chart-deviation-${key}`}
                  key={key}
                  width={xScale.bandwidth()}
                  height={value < 0 ? (yScale(value) - yScale(0)) : (yScale(0) - yScale(value))}
                  y={value < 0 ? yScale(0) : yScale(value)}
                  fill={colorScale(value < 0 ? 'negative' : 'positive')}
                />
              </g>
            )
          })}

          <AxisLeft
            scale={yScale}
            x={margins.left}
            id={`${id}-axis-left`}
            numberOfTicks={yNumberOfTicks}
            tickFormat={(value) => {
              // if (isDense) {
              //   value = yScale.ticks().length - 1 === i || i === 0 ? value : '';
              // }
              if (!!yAxisTickFormatter) {
                return yAxisTickFormatter(value, yAxisTickFormatterOptions);
              }

              return value;
            }}
          />
          <AxisBottom
            scale={xScale}
            id={`${id}-axis-bottom`}
            y={-margins.bottom}
            chartHeight={height}
            tickOffset={xScaleTickOffset}
            tickFormat={(_value, i) => {
              return `${groups[i].shortXLabel}`

            }}
          />

          {!!showThreshold && !!xThreshold &&
            <LineThresholdVertical
              lineWidth={1}
              showCircle={false}
              lineHeight={margins.bottom}
              y={height - margins.bottom}
              labelPadding={[2, 4, 2, 4]}
              theme={{ textSize: '0.8rem' }}
              id={`${id}-line-horizontal-threshold`}
              x={xScale(xThreshold.groupKey) || margins.left}
              labelPos={['left', 'bottom']}
              labelDominantBaseline={'auto'} // IE fix
              label={<tspan dy={11}>{xThreshold.label}</tspan>}
            />

          }


          {/* Only show the zero line when the values exist of positive and negative numbers */}
          {(maxValue > 0 && minValue < 0) &&
            <>
              <Line
                width={5}
                id={`${id}-zero-line-spacer`}
                color={'#fff'}
                from={{ x: (margins.left), y: yScale(0) }}
                to={{ x: width - margins.right, y: yScale(0) }}
              />
              <Line
                width={zeroLineWidth}
                id={`${id}-zero-line`}
                color={zeroLineColor}
                from={{ x: (margins.left), y: yScale(0) }}
                to={{ x: width - margins.right, y: yScale(0) }}
              />
            </>
          }

        </Chart>
        <Tooltip
          top={tooltipPosition.y}
          left={tooltipPosition.x}
          visible={showTooltip && tooltipPosition.visible}
          theme={{
            transition: false
          }}
        >
          {() => {
            const {
              xLabel,
              valueKeys,
              formattedValues
            } = tooltipPosition.data as ColumnChartDeviationGroupModel;

            return (
              <TooltipTemplate
                title={xLabel}
                tooltipOptions={tooltipOptions}
                values={
                  valueKeys.map((key, index: number) => {

                    return {
                      label: key,
                      formattedValue: formattedValues[key],
                      colors: index === 0 ? [barColors['neutral']] as string[] : [barColors['positive'], barColors['negative']] as string[]
                      // colors: isGroupModel ? index === 0 ?
                      //     [barColors['positive'], barColors['negative']] :
                      //     [barColors['neutral']] :
                      //   [barColors['positive']] 
                    }
                  })
                }
              />
            );
          }}
        </Tooltip>
        <Legend
          ref={legendElementRef}
          legendOptions={{ orientation: 'horizontal', ...legendOptions }}
          values={data.groups[0].valueKeys.map((key) => {
            return {
              label: key,
              colors: [colorScale(key)]
            };
          })}
        />

      </StyledChartHolder>
    </StyledWrapper>
  )
};

export default ColumnChartDeviation;
