import * as allFormatters from '../../../utils/formatters';
import { TransformFunction, TransformOptionsBase, padLeftWithZeros } from '../transform-utils';

interface RD {
  waarde: number | null;
  // long label
  label_1: string;
  // short label
  label_2: string;
  label_4: string;
  eenheid: string;
}

interface RawData {
  // NOTE: max one column: valxx_00
  [key: string]: RD;
}

export interface BubbleMapValueModel {
  key: string;
  label: string;
  valueLabel: string;
  shortLabel?: string;
  formattedValue: string;
  rawValue: number | string;
}

export interface BubbleMapDataModel {
  values: BubbleMapValueModel[];
  dynamicThreshold?: BubbleMapValueModel;
}

export interface BubbleMapTransformOptions extends TransformOptionsBase {
  column?: number;
  /**
   * Rownumber of the data to use as dynamic threshold.
   * Will most likely be row 0 --> NP
   */
  dynamicThreshold?: number;
}

const bubbleMapTransformer: TransformFunction<BubbleMapDataModel, BubbleMapTransformOptions> = (
  rawData: RawData,
  onError,
  options
) => {

  let result: BubbleMapValueModel[] = [];
  const {
    dynamicThreshold,
    column = 1,
    formatters = {},
    formattersOptions = {}
  } = options || {} as BubbleMapTransformOptions;
  const valueFormatterName = formatters['value'];
  const valueFormatterOptions = formattersOptions['value'];
  const valueFormatter = (allFormatters as any)[valueFormatterName];

  //NOTE: only one column will be transformed
  const keys = Object.keys(rawData).filter((key) => key.endsWith(padLeftWithZeros(column - 1, 2)));
  const guard = !keys.some((key) => rawData[key].waarde !== null);

  if (guard) {
    onError('nodata');
    return null;
  }

  keys.forEach((key) => {
    const item = rawData[key];
    if (item && item.waarde !== null) {
      const { waarde, label_1, label_4, label_2, eenheid } = item;

      result.push({
        key: label_2,
        label: label_1,
        rawValue: waarde,
        valueLabel: label_4,
        shortLabel: label_2 ? label_2.substring(0, label_2.length - 5) : '?',
        formattedValue: valueFormatter ? valueFormatter(waarde, valueFormatterOptions) : allFormatters.formatValue(waarde, eenheid as ValueUnit, valueFormatterOptions)
      });
    }
  });

  // TODO: de volgorde van de key's is om 1 of andere reden belangrijk bij het switchen van kaart, anders gaan de bubble animaties niet goed
  const sorted = result.sort((a, b) => {
    return a.key < b.key ? -1 : 1;
  });

  const npIndex = sorted.findIndex(({ key }) => key === 'NP00000');
  if(npIndex !== -1) {
    const [np] = sorted.splice(npIndex, 1);
    sorted.unshift(np);
  }

  return {
    values: sorted,
    // TODO: moet dit niet voor het sorteren gebeuren?
    dynamicThreshold: dynamicThreshold != null ? sorted[dynamicThreshold] : undefined
  }
};

export default bubbleMapTransformer;