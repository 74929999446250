
/**
 * Calculate how many columns for the table are in the dataset.
 * Note: this function assumes the data is NOT sorted and assumes the format valxx_xx (valROW_COLUMN).
 * @param valKeys contains only the val keys in format valxx_xx
 */
export function calcNumberOfColumns(valKeys: string[]) {
  let colNumbers = valKeys.map((key) => {
    let rowKey = key.substr(key.indexOf('_') + 1);
    return parseInt(rowKey);
  });

  return Math.max(...colNumbers) + 1;
}

/**
 * Calculate how many rows for the table are in the dataset.
 * Note: this function assumes the data is NOT sorted and assumes the format valxx_xx (valROW_COLUMN).
 * @param valKeys contains only the val keys in format valxx_xx
 */
export function calcNumberOfRows(valKeys: string[]) {
  let rowNumbers = valKeys.map((key) => {
    let rowKey = key.substring('val'.length, key.indexOf('_'));
    return parseInt(rowKey);
  });

  return Math.max(...rowNumbers) + 1;
}

/**
 * Add leading zero's to a number and return as string.
 */
export function padLeftWithZeros(numberToPad: number, width: number) {
  return ('0'.repeat(width) + `${numberToPad}`).slice(`${numberToPad}`.length);
}