import React from 'react';
import styled from 'styled-components';
import { HelpTooltipButton } from './HelpTooltipButton';
import { PopperPlacementType as MuiPopperPlacementType } from '@material-ui/core/Popper';
import MuiTypography from '@material-ui/core/Typography';
// import { ParsedHelpContent } from './ParsedHelpContent';

const StyledSyntaxError = styled.strong`
  color: #2b4899;
`;

/**
 * Convert al content from helpdata to react elements.
 */
export const parseContent = (helpData: API.HelpData) => {
  return (helpData.paragraphs.map((paragraph, i) => (
    <React.Fragment key={`paragraph-${i}`}>
      {!!paragraph.title && <MuiTypography variant={'h3'}>{paragraph.title}</MuiTypography>}
      <p style={{ marginTop: !!paragraph.title ? '0' : undefined }} dangerouslySetInnerHTML={{ __html: paragraph.content }}>
        {/* TODO: alleen de 'help' tag nog parsen */}
        {/* <ParsedHelpContent unparsedContent={paragraph.content} /> */}
      </p>
    </React.Fragment>
  )));
};

// <help id="idvanhelp" type="link|icon|iconButton|button" text="anchorText" placement="top|right|bottom|left" />
export const parseHelp = (tag: string) => {
  if (!tag.includes('id="')) { return (<span><StyledSyntaxError>Syntax error:</StyledSyntaxError> help does not contain an id attr. </span>); }
  if (!tag.includes('type="')) { return (<span><StyledSyntaxError>Syntax error:</StyledSyntaxError> help does not contain a type attr. </span>); }
  if (!tag.includes('text="')) { return (<span><StyledSyntaxError>Syntax error:</StyledSyntaxError> help does not contain a text attr. </span>); }

  const idStart = tag.indexOf('id="');
  const idEnd = tag.indexOf('"', idStart + 'id="'.length);
  const idString = tag.substring(idStart + 'id="'.length, idEnd);
  const id = parseInt(idString);

  if (idEnd === -1) {
    return (<span><StyledSyntaxError>Syntax error:</StyledSyntaxError> help 'id' not correctly closed.</span>);
  } else if (isNaN(id)) {
    return (<span><StyledSyntaxError>Syntax error:</StyledSyntaxError> help 'id' is not a number.</span>);
  }

  const typeStart = tag.indexOf('type="');
  const typeEnd = tag.indexOf('"', typeStart + 'type="'.length);
  const type = tag.substring(typeStart + 'type="'.length, typeEnd);

  if (typeEnd === -1) {
    return (<span><StyledSyntaxError>Syntax error:</StyledSyntaxError> help 'type' not correctly closed.</span>);
  }

  if (type !== 'link' && type !== 'iconButton' && type !== 'icon' && type !== 'button') {
    return (<span><StyledSyntaxError>Syntax error:</StyledSyntaxError> help 'type' {type} does not exist.</span>);
  }

  const textStart = tag.indexOf('text="');
  const textEnd = tag.indexOf('"', textStart + 'text="'.length);
  const text = tag.substring(textStart + 'text="'.length, textEnd);

  if (textEnd === -1) {
    return (<span><StyledSyntaxError>Syntax error:</StyledSyntaxError> help 'text' not correctly closed.</span>);
  }

  const placementStart = tag.indexOf('placement="');
  const placementEnd = placementStart !== -1 ? tag.indexOf('"', placementStart + 'placement="'.length) : 0;
  const placement = placementStart !== -1 ? tag.substring(placementStart + 'placement="'.length, placementEnd) as MuiPopperPlacementType : undefined;

  if (placementStart !== -1 && placementEnd === -1) {
    return (<span><StyledSyntaxError>Syntax error:</StyledSyntaxError> help 'placement' not correctly closed.</span>);
  }

  return (
    <HelpTooltipButton
      helpId={id}
      anchorType={type}
      anchorText={text}
      placement={placement}
    />
  );
};