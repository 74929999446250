import { TreeDataItem } from 'ppd-library/components/organisms/CheckBoxTree';
import {
  SearchableCheckBoxTreePopOver,
  SearchableCheckBoxTreePopOverProps,
} from 'ppd-library/components/organisms/SearchableCheckBoxTreePopOver';
import React, { useEffect, useState, useRef } from 'react';
import { httpGet } from '../../utils/http';
import { convertTemplateToTreeDataItem, convertTileToTreeDataItem, convertTabToTreeDataItem, convertViewToTreeDataItem, TileTreeExtraTreeItemData } from './TileTreePopOverUtils';

// eslint-disable-next-line
const { API_URL } = ppd.appConfig;

export interface TileTreePopOverProps extends Omit<SearchableCheckBoxTreePopOverProps, 'treeData'> {
  /**
   * if the top level of the tree (the templates / thema's) are checkable or not.
   * @default false
   */
  templatesCheckable?: boolean;
  /**
   * If the second level  of the tree (the tiles) are checkable or not.
   * @default false
   */
  tilesCheckable?: boolean;
  /**
    * If the third level of the tree (the tabs) are checkable or not.
    * @default false
    */
  tabsCheckable?: boolean;
  /**
  * If the third level of the tree (the tabs) are visible or not.
  * @default true
  */
  tabsVisible?: boolean;
  /**
   * Dev only.
   * Add the id of the template, tile, tab or view to the printed label.
   * @default false
   */
  showDevIds?: boolean;
  /**
   * Fetches the tile data as soon as the component initiates when true, else it will wait until the pop-up is opened before fetching all tiles.
   * @default false
   */
  preLoad?: boolean;
  /**
   * Gets called for each tile in the tree when defined.
   * Should return true when the tile should be excluded from the tree, otherwise false.
   */
  excludeTile?: (tile: API_GET.Tile) => boolean;
  /**
   * Gets called for each view in the tree when defined.
   * Should return true when the view should be excluded from the tree, otherwise false.
   */
  excludeView?: (view: API_GET.View) => boolean;
  /**
  * Gets called for each template in the tree when defined.
  * Should return true when the template should be excluded from the tree, otherwise false.
  */
  excludeTemplate?: (view: API_GET.Template) => boolean;
}

const minInputValue = 3;

// TODO: deze zijn al een keer opgehaald voor het menu, dit gaan we opslaan in global reducer.
const getActiveAdminTemplates = async () => {
  const adminTemplates = await httpGet<API_GET.Template[]>(`${API_URL}/templates/static`);
  return adminTemplates.filter(({ enabled, placeholderText }) => enabled && !placeholderText);
};

const getActiveAdminTemplateTiles = async (includeTabsAndViews: boolean) => {
  const tilesUrl = includeTabsAndViews ? `${API_URL}/tiles_detail` : `${API_URL}/tiles`;
  const allTilesP = httpGet<API_GET.TileDetail[]>(tilesUrl);
  const allStaticTemplateTilesP = httpGet<API_GET.TemplateTile[]>(`${API_URL}/templatetiles/static`);

  const [allTiles, allStaticTemplateTiles] = await Promise.all([allTilesP, allStaticTemplateTilesP]);

  const result = [] as API_GET.TemplateTileDetail[];

  for (let i = 0; i < allStaticTemplateTiles.length; i++) {
    const staticTemplateTile = allStaticTemplateTiles[i];
    const tile = allTiles.find(({ id }) => staticTemplateTile.tileId === id);

    if (!!tile) {
      const { enabled: ttEnabled, placeholderText: ttPlaceholderText } = staticTemplateTile;
      const { enabled: tEnabled, placeholderText: tPlaceholderText } = tile;

      if (ttEnabled && tEnabled && !ttPlaceholderText && !tPlaceholderText) {
        result.push({
          ...staticTemplateTile,
          tile
        });
      }
    }
  }

  return result;
};

const convert = <T extends {}>(converterFn: (itemToConvert: T) => TreeDataItem, itemToConvert: T) => {
  const treeDataItem = converterFn(itemToConvert);
  treeDataItem.childNodes = [];

  return treeDataItem;
};

const pushToParent = (child: TreeDataItem, parent: TreeDataItem) => {
  (parent.childNodes as TreeDataItem[]).push(child);
};

const addToUncheckableIds = (item: TreeDataItem, itemIsCheckable: boolean, uncheckableIds: string[]) => {
  if (!itemIsCheckable) {
    uncheckableIds.push(item.id);
  }
};

/**
 * @return converted item
 */
const convertAndPushToParentAndAddUncheckable = <T extends {}>(
  converterFn: (itemToConvert: T) => TreeDataItem, itemToConvert: T,
  itemIsCheckable: boolean,
  uncheckableIds: string[],
  parent?: TreeDataItem
) => {
  const treeDataItem = convert(converterFn, itemToConvert);

  if (!!parent) {
    pushToParent(treeDataItem, parent);
  }

  addToUncheckableIds(treeDataItem, itemIsCheckable, uncheckableIds);

  return treeDataItem;
};

const filter = <T extends {
  enabled: boolean;
  placeholderText: string | null;
}>(items: T[] = [], extraFilterFn: (item: T) => boolean = () => true) => {
  const filtered = items.filter((item) => extraFilterFn(item) && item.enabled && !item.placeholderText);

  return filtered;
};

/**
 * // TODO: this should be cached somewhere 
 * @param templatesCheckable 
 * @param tilesCheckable 
 * @param tabsCheckable 
 * @param uncheckableIds is passed around to be filled with ids that need to be uncheckable
 * @param showDevIds 
 */
const getAndConvertTreeData = async (
  templatesCheckable: boolean,
  tilesCheckable: boolean,
  tabsCheckable: boolean,
  uncheckableIds: string[],
  tabsVisible: boolean,
  excludeTile: (tile: API_GET.Tile) => boolean,
  excludeView: (view: API_GET.View) => boolean,
  excludeTemplate: (view: API_GET.Template) => boolean,
  showDevIds = false,
) => {
  const treeDataItems: TreeDataItem[] = [];

  const templates = await getActiveAdminTemplates();
  const allTemplateTiles = await getActiveAdminTemplateTiles(tabsVisible);

  let sortedTemplates = templates.sort((a, b) => a.name < b.name ? -1 : 1);

  for (let i = 0; i < sortedTemplates.length; i++) {
    const template = sortedTemplates[i];
    if (excludeTemplate(template)) {
      continue;
    }

    let templateTreeDataItem = convertAndPushToParentAndAddUncheckable(convertTemplateToTreeDataItem, template, templatesCheckable, uncheckableIds);
    templateTreeDataItem.label = showDevIds ? `${templateTreeDataItem.label} (${template.id})` : templateTreeDataItem.label;
    templateTreeDataItem.level = 0;
    templateTreeDataItem.extraTreeItemData = {
      type: 'template',
      template
    } as TileTreeExtraTreeItemData;

    const templateTiles = filter(allTemplateTiles, (templateTile) => template.id === templateTile.templateId && !excludeTile(templateTile.tile));
    const sortedTemplateTiles = templateTiles.sort((a, b) => a.tile.title < b.tile.title ? -1 : 1);
    sortedTemplateTiles.forEach((templateTile) => {
      // NOTE: wait with pushing to parent and uncheckable list until we are certain the tile has children (end of foreach).
      // let tileTreeDataItem = convertAndPushToParentAndAddUncheckable(convertTileToTreeDataItem, templateTile, tilesCheckable, uncheckableIds, templateTreeDataItem);
      let tileTreeDataItem = convert(convertTileToTreeDataItem, templateTile);
      tileTreeDataItem.label = showDevIds ? `${tileTreeDataItem.label} (${templateTile.id} - ${templateTile.tileId})` : tileTreeDataItem.label;
      tileTreeDataItem.level = 1;
      tileTreeDataItem.extraTreeItemData = {
        type: 'tile',
        template,
        templateTile
      } as TileTreeExtraTreeItemData;

      if (tabsVisible) { // TODO: wat als tabs niet visible maar views wel?
        const tileTabs = filter(templateTile.tile.tabs, ({ views: tabViews }) => tabViews.some((tabView) => !excludeView(tabView)));
        const sortedTileTabs = tileTabs.sort((a, b) => (a.title || '') < (b.title || '') ? -1 : 1);

        if (sortedTileTabs.length === 1 && !sortedTileTabs[0].title) {
          const tileTab = sortedTileTabs[0];
          const { views: tabViews } = tileTab;
          const activeTabViews = filter(tabViews, (tabView) => !excludeView(tabView));

          activeTabViews.forEach((tabView) => {
            let viewTreeDataItem = convertAndPushToParentAndAddUncheckable(convertViewToTreeDataItem, tabView, true, uncheckableIds, tileTreeDataItem);
            viewTreeDataItem.label = showDevIds ? `${viewTreeDataItem.label} (${tabView.id})` : viewTreeDataItem.label;
            viewTreeDataItem.level = 2;
            viewTreeDataItem.extraTreeItemData = {
              type: 'view',
              template,
              templateTile,
              tileTab,
              tabView
            } as TileTreeExtraTreeItemData;
          });
        } else {
          sortedTileTabs.forEach((tileTab) => {
            const tabTreeDataItem = convertAndPushToParentAndAddUncheckable(convertTabToTreeDataItem, tileTab, tabsCheckable, uncheckableIds, tileTreeDataItem);
            tabTreeDataItem.label = showDevIds ? `${tabTreeDataItem.label} (${tileTab.id})` : tabTreeDataItem.label;
            tabTreeDataItem.level = 3;
            tabTreeDataItem.extraTreeItemData = {
              type: 'tab',
              template,
              templateTile,
              tileTab
            } as TileTreeExtraTreeItemData;

            const tabViews = filter(tileTab.views, (tabView) => !excludeView(tabView));
            tabViews.forEach((tabView) => {
              let viewTreeDataItem = convertAndPushToParentAndAddUncheckable(convertViewToTreeDataItem, tabView, true, uncheckableIds, tabTreeDataItem);
              viewTreeDataItem.label = showDevIds ? `${viewTreeDataItem.label} (${tabView.id})` : viewTreeDataItem.label;
              viewTreeDataItem.level = 4;
              viewTreeDataItem.extraTreeItemData = {
                type: 'view',
                template,
                templateTile,
                tileTab,
                tabView
              } as TileTreeExtraTreeItemData;
            });
          });
        }
      }

      // TODO: hoe werkt deze if? waarom moet een templatetile perse childnodes hebben? geldt alleen als je b.v. alleen views kan aanvinken b.v.
      if ((tileTreeDataItem.childNodes && tileTreeDataItem.childNodes.length > 0) || !tabsVisible) {
        pushToParent(tileTreeDataItem, templateTreeDataItem);
        addToUncheckableIds(tileTreeDataItem, tilesCheckable, uncheckableIds);
      }
    });

    treeDataItems.push(templateTreeDataItem);
  }

  return treeDataItems;
};

/**
 * Component fetches its own unit data and converts it to a tree.
 */
const TileTreePopOver = (props: TileTreePopOverProps) => {
  const {
    isOpen,
    preLoad = false,
    showDevIds = false,
    tabsVisible = true,
    tabsCheckable = false,
    tilesCheckable = false,
    templatesCheckable = false,
    excludeTile = (_tile: API_GET.Tile) => false,
    excludeView = (_view: API_GET.View) => false,
    excludeTemplate = (_view: API_GET.Template) => false,
    ...searchableCheckBoxTreeProps
  } = props;

  const tilePopOverDisabledIdsRef = useRef<string[]>([]);
  const tilePopOverUncheckableIdsRef = useRef<string[]>([]);
  const [treeData, setTreeData] = useState<TreeDataItem[]>([]);

  const initialize = async () => {
    // only do this once
    if (!treeData || treeData.length === 0) {
      let result = await getAndConvertTreeData(
        templatesCheckable,
        tilesCheckable,
        tabsCheckable,
        tilePopOverUncheckableIdsRef.current,
        tabsVisible,
        excludeTile,
        excludeView,
        excludeTemplate,
        showDevIds
      );

      setTreeData(result);
    }
  };

  useEffect(() => {
    if (preLoad) {
      initialize();
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    // only do this when the popover is opened.
    if (!preLoad && isOpen) {
      initialize();
    }
    //eslint-disable-next-line
  }, [isOpen]);

  return (
    <SearchableCheckBoxTreePopOver
      {...searchableCheckBoxTreeProps}
      isOpen={isOpen}
      treeData={treeData}
      minLengthInputValue={minInputValue}
      disabledIds={tilePopOverDisabledIdsRef.current}
      uncheckableIds={tilePopOverUncheckableIdsRef.current}

    />
  );

};

export default TileTreePopOver;