import { Modal, ModalHeader } from 'ppd-library/components/organisms/Modal';
import React, { useEffect, useState } from 'react';

import { httpGet } from '../../../utils/http';
import { parseContent } from '../../help/parsers';
import styled from 'styled-components';

// eslint-disable-next-line
const { API_URL } = ppd.appConfig;

//TODO: baseModal props interface maken en util functies lostrekken
export interface HelpModalProps {
  open: boolean;
  onClose: () => void;
  helpId: number | null;
  onLoadingChange: (loading: boolean) => void;
}

const StyledContent = styled.div`
  font-size: 0.875rem;
  table {
    text-align: left;
    border-collapse: collapse;

    & tbody {
      vertical-align: top;
    }

    td {
      padding: 2px 4px;
      border: 1px solid;
    }
  }
`;

const HelpModal = (props: HelpModalProps) => {
  const {
    open,
    helpId,
    onClose,
    // TODO: redux
    onLoadingChange
  } = props;

  const [helpData, setHelpData] = useState<API_GET.HelpData | null>(null);

  useEffect(() => {
    const getHelpData = async () => {
      const result = await httpGet<API_GET.HelpData>(`${API_URL}/help/${helpId}`);

      setHelpData(result);
      onLoadingChange(false);
    };

    if (helpId !== null) {
      getHelpData();
      onLoadingChange(true);
    }
  // eslint-disable-next-line
  }, [helpId]);

  const handleOnCancel = () => {
    onClose();
  }

  return (
    <>
      {!!helpData && helpId === helpData.id &&
        <Modal
          open={open}
          header={

            <ModalHeader
              onCancel={handleOnCancel}
              title={!!helpData && !helpData.paragraphs ? 'Niet gevonden' : helpData.title as string}
            />
          }
          content={
            <StyledContent>
              {!!helpData && !helpData.paragraphs &&
                <p>Geen help gevonden met id '{helpId}'</p>
              }
              {!!helpData && !!helpData.paragraphs &&
                parseContent(helpData)
              }
            </StyledContent>
          }
        />
      }
    </>
  );
}

export default HelpModal;