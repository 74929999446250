import { ValueBlockSimple } from 'ppd-library/components/molecules/ValueBlockSimple';
import React from 'react';

import { HelpTooltipButton } from '../../help/HelpTooltipButton';
import { ComponentBaseProps } from '../ComponentBaseProps';
import { SingleValueDataModel } from './singleValueTransformer';
import { ContentRow, ContentRowProps } from '../ContentRow';
import styled from 'styled-components';

export interface SingleValueThemeProps {
  icon?: string;
  /**
   * @default xlarge
   */
  valueSize?: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge' | number;
}
export interface SingleValueProps extends ComponentBaseProps<SingleValueThemeProps, SingleValueDataModel> {

}

const StyledContentRow = styled(({ contentWidth, contentHeight, ...contentRowProps }: any) => <ContentRow {...contentRowProps} />)`
  && {
    flex: 1;
    align-self: center;
    justify-self: center;
    justify-content: center;
  }
  ` as React.ComponentType<ContentRowProps>;

const SingleValue = (props: SingleValueProps) => {
  const {
    // width,
    // height,
    data,
    showHelp,
    help,
    hideLabel = false,
    themeProperties,
  } = props;

  const { label, formattedValue } = data;
  const { icon: valueIcon, valueSize = 'large' } = themeProperties || {} as SingleValueThemeProps;

  const helpData = !!help ? help.find(({ position }) => position === 1) : undefined;

  return (
    <StyledContentRow>
      <ValueBlockSimple
        label={hideLabel ? '' : label}
        valueVariant={'h1'}
        valueSize={valueSize}
        value={formattedValue}
        iconOptions={valueIcon ? { iconName: valueIcon } : undefined}
        afterValueContent={
          <>
            {!!showHelp && !!helpData &&
              <HelpTooltipButton
                helpId={helpData.helpId}
                anchorType={'iconButton'}
              />
            }
          </>
        }
      />
    </StyledContentRow>
  )
};

export default SingleValue;
