import { TransformFunction, TransformOptionsBase } from '../transform-utils';

export interface SimpleTextBlockDataModel {}
export interface SimpleTextBlockTransformOptions extends TransformOptionsBase {}

export const simpleTextBlockTransformer: TransformFunction<SimpleTextBlockDataModel, SimpleTextBlockTransformOptions> = (
) => {
  return {}
}

export default simpleTextBlockTransformer;