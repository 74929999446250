import { Tooltip } from 'ppd-library/charts/organisms/Tooltip';
import { scales } from 'ppd-library/charts/chartUtils';
import { AxisLeft, AxisBottom } from 'ppd-library/charts/organisms/Axis';
import { VerticalGridLines } from 'ppd-library/charts/organisms/GridLines';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { Line } from 'ppd-library/charts/atoms/Line';
import Chart from 'ppd-library/charts/Chart';
import { ComponentBaseProps } from '../ComponentBaseProps';
import { BarChartDataModel, BarChartBarDataModel } from './barChartTransformer';
import * as allFormatters from '../../../utils/formatters';
import { BarHorizontal } from 'ppd-library/charts/molecules/BarHorizontal';

import { getSVGMousePosition, useTooltipPosition, TooltipTemplate } from '../tooltip-utils';
import { Label } from 'ppd-library/charts/atoms/Label';

export interface StackedColorProp {
  [key: string]: string;
}
export interface StackedValuesProp {
  [key: string]: string;
}
export interface BarColorsProp {
  [key: string]: string;
}
export interface BarChartThemeProps {
  barColors?: BarColorsProp;
  zeroLineColor?: string;
  zeroLineWidth?: number;
}
export interface BarChartProps extends ComponentBaseProps<BarChartThemeProps, BarChartBarDataModel> {
  showTooltip?: boolean;
  barsPadding?: number;
  showValueLabel?: boolean;
  yAxisLabelText?: string;
  xAxisLabelText?: string;
  xNumberOfTicks?: number,
}
const StyledWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const StyledChartHolder = styled.div``;

const BarChart = (props: BarChartProps) => {
  const {
    id,
    data,
    // help,
    // showHelp,
    width = 500,
    height = 450,
    formatters = {},
    showTooltip = true,
   // = true,
    tooltipOptions,
    themeProperties,
    formattersOptions = {},
    showValueLabel = false,
    barsPadding = 0.1,
    yAxisLabelText,
    xAxisLabelText,
    xNumberOfTicks = 10
  } = props;
  const { margins = { left: 100, right: 10, top: 0, bottom: 20 } } = props;

  const {
    barColors = { default: '#2b4899' },
    zeroLineColor = '#2b4899',
    zeroLineWidth = 1,
  } = themeProperties || { } as BarChartThemeProps;

  const {barsModel} = data;
  const ticks = barsModel.map((bar) => bar.valueKey);

  const yScaleFormatterName = formatters['yScaleTick'];
  const yScaleFormatter = (allFormatters as any)[yScaleFormatterName];
  const yScaleFormatterOptions = formattersOptions['yScaleTick'];

  const xScaleFormatterName = formatters['xScaleTick'];
  const xScaleFormatter = (allFormatters as any)[xScaleFormatterName];
  const xScaleFormatterOptions = formattersOptions['xScaleTick'];


  const getMaxValueFromData = (barsModel: BarChartDataModel[]): number => {
    const acummulatedRawValues: number[] = [];
    barsModel.forEach((bar) => {
      let rawItemValues: number[] = [];

      rawItemValues.push(bar.rawValue);

      acummulatedRawValues.push(rawItemValues.reduce((prev, curr) => prev + curr, 0));
      rawItemValues = [];
    });

    const maximumValue = Math.max(...acummulatedRawValues);

    return maximumValue;
  }
  const maxValue = getMaxValueFromData(barsModel);
  // TODO: waar slaan die parameters op in de xScale?
  const xScale = scales.numericXScale(0, maxValue, width - (margins.right) , 0, margins.left).nice();
  const yScale = scales.stringYScale(ticks, height, margins.top, margins.bottom, barsPadding);
  const yScaleTickOffset = yScale.bandwidth() / 2;
  const colorScale = scales.colorScale(barColors);

  let tooltipLeaveTimeoutId = 0;
  const handleMouseMove = (event: React.MouseEvent<Element, MouseEvent>, index: number, data: BarChartDataModel) => {
    clearTimeout(tooltipLeaveTimeoutId);
    const { x, y } = getSVGMousePosition(event as React.MouseEvent<SVGElement, MouseEvent>);
    setTooltipPosition({ visible: true, x, y, index, data });
  };

  const handleMouseLeave = () => {
    clearTimeout(tooltipLeaveTimeoutId);
    tooltipLeaveTimeoutId = setTimeout(() => {
      setTooltipPosition({ ...tooltipPosition, visible: false });
    }, 250);
  };

  const { tooltipPosition, setTooltipPosition } = useTooltipPosition<BarChartDataModel>();
  const [initiated, setInitiated] = useState(false);

  useEffect(() => {
    setInitiated(true);
  }, []);

  return (
    <StyledWrapper>
      <StyledChartHolder>
        <Chart height={height} width={width}>
          <g transform={`translate(${margins.left}, ${height})`}>
            <VerticalGridLines
              id={`${id}-vertical-grid-lines`}
              scale={xScale}
              y={-(margins.bottom + margins.top )}
              numberOfTicks={xNumberOfTicks}
              lineLength={height - ( margins.bottom + margins.top + yScaleTickOffset ) }
            />
          <Line
            width={zeroLineWidth}
            id={`${id}-line-vertical`}
            color={zeroLineColor}
            from={{ x: (xScale(0)), y: -margins.bottom }}
            to={{ x: xScale(0), y: - (height) }}
            dashed={true}
          />
          </g>
          <g transform={`translate(${margins.left}, 0)`}>
            {barsModel.map((barData, barIndex) => {
              const { key, rawValue, valueKey } = barData;
              return (
                <g
                  key={key}
                  onMouseMove={(ev) => handleMouseMove(ev, barIndex, barData)}
                  onMouseLeave={handleMouseLeave}
                >
                  <BarHorizontal
                    key={`${id}-${barData.key}`}
                    fill={colorScale(valueKey)}
                    width={initiated ? xScale(rawValue) : 0 }
                    x={0}
                    y={yScale(barData.valueKey) as number}
                    id={`${id}-bar-vertical-${barIndex}`}
                    height={yScale.bandwidth()}
                  />
                  {
                    !!showValueLabel && <Label
                      yText={0}
                      dominantBaseLine={0}
                      fontSize={'0.75rem'}
                      color={'#2b4899'}
                      fill={'transparent'}
                      x={initiated ? xScale(rawValue) : 0}
                      y={yScale(barData.valueKey) as number + yScale.bandwidth() / 2}
                      text={barData.formattedValue}
                      animate={true} />
                  }
                </g>
                )
              }
          )}
        </g>
        <AxisLeft
          scale={yScale}
          x={margins.left}
          id={`${id}-axis-left`}
          tickOffset={yScaleTickOffset}
          tickFormat={(value) => {
            if(!!yScaleFormatter){
              return yScaleFormatter(value, yScaleFormatterOptions);
            }
            return value;
          }}
        />
        <g transform={`translate(${margins.left}, ${-margins.bottom})`}>
          <AxisBottom
            scale={xScale}
            id={`${id}-axis-bottom`}
            y={0}
            chartHeight={height}
            numberOfTicks={xNumberOfTicks}
            tickFormat={(value) => {
              if(!!xScaleFormatter){
                return xScaleFormatter(value, xScaleFormatterOptions);
              }
              return value;
            }}
          />
        </g>
        {yAxisLabelText &&
          <Label
            yText={5}
            dominantBaseLine={0}
            fontSize={'0.75rem'}
            color={'#000'}
            fill={'#fff'}
            x={0}
            y={0}
            text={yAxisLabelText}
            animate={true} />
        }
         {xAxisLabelText &&
          <Label
            yText={5}
            dominantBaseLine={0}
            fontSize={'0.75rem'}
            color={'#000'}
            fill={'#fff'}
            x={width - (margins.right * 2.5)}
            y={height - 11}
            text={xAxisLabelText}
            animate={true} />
        }
      </Chart>
      <Tooltip
          top={tooltipPosition.y}
          left={tooltipPosition.x}
          visible={showTooltip && tooltipPosition.visible}
          theme={{
            transition: false
          }}
        >
          {() => {
            const {
              label,
              valueKey,
              secondaryValues,
              formattedValue
            } = tooltipPosition.data as BarChartDataModel;

            let tooltipValues = {
              label,
              formattedValue,
              colors: [colorScale(valueKey)]
            }

            if(secondaryValues) {
              tooltipValues = {
                label: valueKey,
                formattedValue: `${secondaryValues.formattedValue} (${formattedValue})`,
                colors: [colorScale(valueKey)]
              }
            }
            return (
              <TooltipTemplate
                title={valueKey}
                tooltipOptions={tooltipOptions}
                values={[tooltipValues]}
              />
            );
          }}
        </Tooltip>
      </StyledChartHolder>
    </StyledWrapper>
  )
};

export default BarChart;