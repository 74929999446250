import { Line } from 'ppd-library/charts/atoms/Line';
import Chart from 'ppd-library/charts/Chart';
import { scales } from 'ppd-library/charts/chartUtils';
import { BarHorizontal } from 'ppd-library/charts/molecules/BarHorizontal';
import { AxisBottom, AxisLeft } from 'ppd-library/charts/organisms/Axis';
import { VerticalGridLines } from 'ppd-library/charts/organisms/GridLines';
import { Tooltip } from 'ppd-library/charts/organisms/Tooltip';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import * as allFormatters from '../../../utils/formatters';
import { HelpTooltipButton } from '../../help/HelpTooltipButton';
import { ComponentBaseProps } from '../ComponentBaseProps';
import { Legend, useLegend } from '../legend-utils';
import { getSVGMousePosition, TooltipTemplate, useTooltipPosition } from '../tooltip-utils';
import {
  BarChartDivergingBarDataModel,
  BarChartDivergingDataModel,
  BarChartDivergingModel,
} from './barChartDivergingTransformer';

export interface StackedColorProp {
  [key: string]: string;
}
export interface StackedValuesProp {
  [key: string]: string;
}
export interface BarColorsProp {
  [key: string]: string;
}
export interface BarChartDivergingThemeProps {
  barColors?: BarColorsProp;
  gridColor?: string;
  zeroLineColor?: string;
  zeroLineWidth?: number;
}
export interface BarChartDivergingProps extends ComponentBaseProps<BarChartDivergingThemeProps, BarChartDivergingBarDataModel> {
  showTooltip?: boolean;
  barsPadding?: number;
  xNumberOfTicks?: number;
  multiLine?: boolean;
  multiLineTickWidth?: number;
  multiLineTickHeight?: number;
  multiLineTickAlignment?: 'left' | 'right';
}
const StyledWrapper = styled.div`
  position: relative;
  width: 100%;
`;
const StyledZeroLineHolder = styled.g`
    transition: transform ease-in-out 500ms;
`;

const StyledChartHolder = styled.div``;

const BarChartDiverging = (props: BarChartDivergingProps) => {
  const {
    id,
    data,
    help,
    showHelp,
    width = 500,
    height: originalHeight = 450,
    formatters = {},
    showTooltip = true,
    tooltipOptions,
    themeProperties,
    formattersOptions = {},
    legendOptions = {},
    barsPadding = 0.1,
    multiLine,
    multiLineTickWidth,
    multiLineTickHeight,
    multiLineTickAlignment
  } = props;
  const { margins = { left: 100, right: 10, top: 0, bottom: 20 }, xNumberOfTicks = 10 } = props;

  const {
    gridColor = '#bfd1e1',
    barColors = { leftBar: '#D90000', rightBar: '#2b4899' },
    zeroLineColor = '#2b4899',
    zeroLineWidth = 1,
  } = themeProperties || { } as BarChartDivergingThemeProps;

  const { barsModel } = data;
  const ticks = barsModel.map((bar) => bar.valueKey);

  const yScaleFormatterName = formatters['yScaleTick'];
  const yScaleFormatter = (allFormatters as any)[yScaleFormatterName];
  const yScaleFormatterOptions = formattersOptions['yScaleTick'];

  const xAxisFormatterName = formatters['xAxisTick'];
  const xAxisFormatter = (allFormatters as any)[xAxisFormatterName];
  const xAxisFormatterOptions = formattersOptions['xAxisTick'];

  const minValue = Math.max(...barsModel.map(({ primaryValues }) => (primaryValues as BarChartDivergingModel).rawValue));
  const maxValue = Math.max(...barsModel.map(({ secondaryValues }) => (secondaryValues as BarChartDivergingModel).rawValue));

  const xScale = scales.numericXScale(-minValue, maxValue, width - (margins.right), 0, margins.left).nice();
  const yScale = scales.stringYScale(ticks, originalHeight, margins.top, margins.bottom, barsPadding);
  const yScaleTickOffset = yScale.bandwidth() / 2;
  const colorScale = scales.colorScale(barColors);

  let tooltipLeaveTimeoutId = 0;
  const handleMouseMove = (event: React.MouseEvent<Element, MouseEvent>, index: number, data: BarChartDivergingDataModel) => {
    clearTimeout(tooltipLeaveTimeoutId);
    const { x, y } = getSVGMousePosition(event as React.MouseEvent<SVGElement, MouseEvent>);
    setTooltipPosition({ visible: true, x, y, index, data });
  };

  const handleMouseLeave = () => {
    clearTimeout(tooltipLeaveTimeoutId);
    tooltipLeaveTimeoutId = setTimeout(() => {
      setTooltipPosition({ ...tooltipPosition, visible: false });
    }, 250);
  };

  const legendElementRef = React.createRef<HTMLDivElement>();
  const { legendHeight } = useLegend(originalHeight, legendElementRef);
  const height = originalHeight - legendHeight;

  const { tooltipPosition, setTooltipPosition } = useTooltipPosition<BarChartDivergingDataModel>();
  const [initiated, setInitiated] = useState(false);

  useEffect(() => {
    setInitiated(true);
  }, []);

  return (
    <StyledWrapper>
      <StyledChartHolder>
        <Chart height={height} width={width}>
          <g transform={`translate(${margins.left}, ${height})`}>
            <VerticalGridLines
              numberOfTicks={xNumberOfTicks}
              id={`${id}-vertical-grid-lines`}
              scale={xScale}
              y={-(margins.bottom + margins.top)}
              lineLength={height - (margins.bottom + margins.top + yScaleTickOffset)}
              theme={{ color: gridColor }}
            />
            <StyledZeroLineHolder transform={`translate(${xScale(0)}, ${0})`}>
              <Line
                width={zeroLineWidth}
                id={`${id}-line-vertical`}
                color={zeroLineColor}
                from={{ x: 0, y: -margins.bottom }}
                to={{ x: 0, y: - (height) }}
                dashed={true}
              />
            </StyledZeroLineHolder>
          </g>
          <g transform={`translate(${margins.left}, 0)`}>
            {barsModel.map((barData, barIndex) => {
              const { key, primaryValues, secondaryValues } = barData;
              const { rawValue: primaryRawValue, label: primaryLabel } = primaryValues as BarChartDivergingModel;
              const { rawValue: secondaryRawValue, label: secondaryLabel } = secondaryValues as BarChartDivergingModel;
              return (
                <g
                  key={key}
                  onMouseMove={(ev) => handleMouseMove(ev, barIndex, barData)}
                  onMouseLeave={handleMouseLeave}
                >
                  <BarHorizontal
                    key={`${id}-${barData.key}-left`}
                    fill={colorScale(primaryLabel)}
                    width={initiated ? xScale(0) - xScale(-primaryRawValue) : 0}
                    x={xScale(-primaryRawValue)}
                    y={yScale(barData.valueKey) as number}
                    id={`${id}-bar-vertical-${barIndex}-left`}
                    height={yScale.bandwidth()}
                  />
                  <BarHorizontal
                    key={`${id}-${barData.key}-right`}
                    fill={colorScale(secondaryLabel)}
                    width={initiated ? xScale(secondaryRawValue) - xScale(0) : 0}
                    x={xScale(0)}
                    y={yScale(barData.valueKey) as number}
                    id={`${id}-bar-vertical-${barIndex}-right`}
                    height={yScale.bandwidth()}
                  />
                </g>
              )
            }
            )}
          </g>
          <AxisLeft
            scale={yScale}
            x={multiLine ? 0 : margins.left}
            id={`${id}-axis-left`}
            tickOffset={yScaleTickOffset}
            tickFormat={(value) => {
              if (!!yScaleFormatter) {
                return yScaleFormatter(value, yScaleFormatterOptions);
              }
              return value;
            }}
            multiLine={multiLine}
            multiLineTickWidth={multiLineTickWidth}
            multiLineTickHeight={multiLineTickHeight}
            multiLineTickAlignment={multiLineTickAlignment}
          />
          <g transform={`translate(${margins.left}, ${-margins.bottom})`}>
            <AxisBottom
              numberOfTicks={xNumberOfTicks}
              scale={xScale}
              id={`${id}-axis-bottom`}
              y={0}
              chartHeight={height}
              tickFormat={(value) => {
                if (!!xAxisFormatter) {
                  return xAxisFormatter(value, xAxisFormatterOptions);
                }
                return value;
              }}
            />
          </g>

        </Chart>

        <Tooltip
          top={tooltipPosition.y}
          left={tooltipPosition.x}
          calculateOffsetPosition={'both'}
          visible={showTooltip && tooltipPosition.visible}
          theme={{
            transition: false
          }}
        >
          {() => {
            const {
              rawValue,
              label,
              valueKey,
              formattedValue,
              primaryValues,
              secondaryValues
            } = tooltipPosition.data as any;

            const { rawValue: primaryRawValue, label: primaryLabel, formattedValue: primaryFormattedValue } = primaryValues;
            const { rawValue: secondaryRawValue, label: secondaryLabel, formattedValue: secondaryFormattedValue } = secondaryValues;

            return (
              <TooltipTemplate
                title={valueKey}
                tooltipOptions={tooltipOptions}
                values={[{
                  label: label,
                  rawValue: rawValue as number,
                  formattedValue: formattedValue,
                  colors: []
                },
                {
                  label: primaryLabel,
                  rawValue: primaryRawValue as number,
                  formattedValue: primaryFormattedValue,
                  colors: []
                },
                {
                  label: secondaryLabel,
                  rawValue: secondaryRawValue as number,
                  formattedValue: secondaryFormattedValue,
                  colors: []
                }]}
              />
            );
          }}
        </Tooltip>
        <Legend
          ref={legendElementRef}
          legendOptions={{ orientation: 'horizontal', ...legendOptions }}
          values={Object.keys(barsModel).map((key) => {
            return {
              label: key,
              colors: [colorScale(key)]
            };
          })}
          beforeLegendContent={
            <>
              {!!showHelp && !!help && help.some(({ position }) => position === 98) &&
                <HelpTooltipButton
                  anchorType={'iconButton'}
                  helpId={(help.find(({ position }) => position === 98) as { helpId: number }).helpId}
                />
              }
            </>
          }
          afterLegendContent={
            <>
              {!!showHelp && !!help && help.some(({ position }) => position === 99) &&
                <HelpTooltipButton
                  anchorType={'iconButton'}
                  helpId={(help.find(({ position }) => position === 99) as { helpId: number }).helpId}
                />
              }
            </>
          }
        />
      </StyledChartHolder>
    </StyledWrapper>
  )
};

export default BarChartDiverging;