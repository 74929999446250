export interface NumberFormatterOptions {
  /**
   * @default 0 (no decimals) or 1 for percentage
   */
  numberOfDecimals?: number;
  /**
   * @default NL-nl
   */
  locale?: string;

  [key: string]: any;
};

export interface PercentageFormatterOptions extends NumberFormatterOptions {

};

export const formatNumber = (value: number | undefined, options?: NumberFormatterOptions) => {
  const localValue = !!value ? value : 0;
  const { locale = 'nl-NL', numberOfDecimals = 0 } = options || {};


  // NOTE!: custom solution if toLocaleString is not sufficient
  // const parts = value.toString().split(".");
  // parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // parts.join(".");
  const result = localValue.toLocaleString(locale, {
    minimumFractionDigits: numberOfDecimals,
    maximumFractionDigits: numberOfDecimals
  });

  return result;
};

export const formatPercentage = (value: number, options?: NumberFormatterOptions) => {
  const { numberOfDecimals } = options || { numberOfDecimals: 1 };
  const formatted = formatNumber(value, { numberOfDecimals });

  return `${formatted}%`;
};

export const formatFTE = (value: number, options?: NumberFormatterOptions) => {
  const { numberOfDecimals } = options || { numberOfDecimals: 0 };
  const formatted = formatNumber(value, { numberOfDecimals });

  return `${formatted} fte`;
};

export const formatCurrency = (value: number, options?: NumberFormatterOptions) => {
  const { numberOfDecimals } = options || { numberOfDecimals: 0 };
  const formatted = formatNumber(value, { numberOfDecimals });

  return `€${formatted}`;
};

export const formatSquareMeters = (value: number, options?: NumberFormatterOptions) => {
  const { numberOfDecimals } = options || { numberOfDecimals: 0 };
  const formatted = formatNumber(value, { numberOfDecimals });

  return `${formatted} m2`;
};

export const formatHours = (value: number, options?: NumberFormatterOptions) => {
  const { numberOfDecimals } = options || { numberOfDecimals: 1 };
  const formatted = formatNumber(value, { numberOfDecimals });

  return `${formatted}`;
};

export const months = ['Januari', 'Februari', 'Maart', 'April', 'Mei', 'Juni', 'Juli', 'Augustus', 'September', 'Oktober', 'November', 'December'];
export const quarters = ['Kwartaal 1', 'Kwartaal 2', 'Kwartaal 3', 'Kwartaal 4'];
/**
 * supply any positive number to convert to a month name.
 * The number must be 'one index based', so 1 === Januari and 12 === December.
 * Also, 13 === Januari and 24 === December and so on.
 * @returns if value is lower than one an empty string is returned.
 */
export const formatMonth = (value: number | string) => {
  let num: number = value as number;
  if (typeof value === 'string') {
    num = parseInt(value);
  }

  if (isNaN(num) || num <= 0 || num >= 13) {
    console.error(`Value for formatMonth must not be lower than 1 or higher than 12, actual value: ${value}`);
    return '';
  }

  // TODO: % 12 is niet nodig?
  const month = months[num - 1 % 12];

  return month;
};

export const formatShortMonth = (value: number | string) => {
  const month = formatMonth(value);
  const m = !!month ? month[0] : '';

  return m;
};

export const formatQuarter = (value: number | string) => {
  let num: number = value as number;
  if (typeof value === 'string') {
    num = parseInt(value);
  }

  if (isNaN(num) || num <= 0 || num >= 5) {
    console.error(`Value for formatQuarter must not be lower than 1 or higher than 4, actual value: ${value}`);
    return '';
  }
// TODO: % 4 is niet nodig?
  const quarter = quarters[num - 1 % 4];

  return quarter;
};

export const formatShortQuarter = (value: number | string) => {
  const quarter = formatQuarter(value);
  const q = !!quarter ? `Q${value}` : '';

  return q;
};

export const formatPeriod = (value: number | string) => {
  let num: number = value as number;
  if (typeof value === 'string') {
    num = parseInt(value);
  }

  if (isNaN(num)) {
    console.error(`Value for formatPeriod must be a number, actual value: ${value}`);
    return '';
  }

  return `Periode ${num}`;
};

export const formatAbsoluteNumber = (value: number | string,  options?: NumberFormatterOptions) => {
  const { numberOfDecimals } = options || { numberOfDecimals: 0 };
  let number: number = value as number;

  if(typeof value === 'string') {
    number = parseInt(value);
  }

  const formatted = formatNumber(Math.abs(number), { numberOfDecimals });

  return `${formatted}`;
};


// TODO: use this formatter everywhere?
export const defaultGrades = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
/**
 * TODO: this should be done on a central place for all
 * @param value 
 * @param options 
 */
export const formatGrade = (value: number, options: NumberFormatterOptions = {}) => {
  const { grades = defaultGrades } = options;
  const index = Math.floor(value);

  return grades[index - 1] || '';
};

export const formatValue = (value: number | string, valueUnit: ValueUnit, options?: NumberFormatterOptions) => {
  switch (valueUnit) {
    case 'aantal':
      return formatNumber(value as number, options);
    case 'percentage':
      return formatPercentage(value as number, options);
    case 'fte':
      return formatFTE(value as number, options);
    case 'bedrag':
      return formatCurrency(value as number, options);
    case 'm2':
      return formatSquareMeters(value as number, options);
    case 'uren':
      return formatHours(value as number, options);
    case 'gradering':
      return formatGrade(value as number, options);
    default:
      return `${value}`;
  }
};
 