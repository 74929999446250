import { formatValue } from '../../../utils';
import { TransformFunction, TransformOptionsBase } from '../transform-utils';
import * as allFormatters from '../../../utils/formatters';

interface RD {
  waarde: number;
  label_4: string;
  eenheid: string;
}

export interface SingleValueRawData {
  val00_00: RD;
}

export interface SingleValueTransformOptions extends TransformOptionsBase {
}

export interface SingleValueDataModel {
  label: string;
  rawValue: number | null;
  /**
   * Formatted value with optional trend values
   */
  formattedValue: string;
  // trend?: { // TODO: should come from a generic trend/sparkline transformer
  //   value: number;
  //   type: 'default' | 'first' | 'highest' | 'lowest' | 'last';
  // }[];

}

const singleValueTransformer: TransformFunction<SingleValueDataModel, SingleValueTransformOptions> = (
  rawData: SingleValueRawData,
  onError,
  options
) => {
  const { val00_00 } = rawData;
  const { waarde, label_4, eenheid } = val00_00;
  const { formatters = {} } = options || {} as SingleValueTransformOptions;

  if (waarde == null) {
    onError('nodata');
    return null;
  }

  const valueFormatterName = formatters['value'];
  const valueFormatter = (allFormatters as any)[valueFormatterName];

  const labelFormatterName = formatters['label'];
  const labelFormatter = (allFormatters as any)[labelFormatterName];

  return {
    rawValue: waarde,
    label: labelFormatter ? labelFormatter(label_4) : label_4,
    formattedValue: valueFormatter ? valueFormatter(waarde) : formatValue(waarde, eenheid as ValueUnit)
  };
};

export default singleValueTransformer;