import { TransformFunction, TransformOptionsBase } from '../transform-utils';
import { calcNumberOfRows, padLeftWithZeros } from '../transform-utils';
import * as allFormatters from '../../../utils/formatters';

interface RD {
  waarde: number;
  label_1: string;
  label_4: string;
  eenheid: string;
}

export interface TimeLineDumbbellRawData {
  [key: string]: RD;
}

export interface TimeLineDumbbellTransformOptions extends TransformOptionsBase {
  reverse?: boolean;
}


export interface TimeLineDumbbellItemDataModel {
  key: string;
  label: string;
  rawValue: number;
  formattedValue: string;
}

export interface TimeLineDumbbellDataModel {
  primaryValues: TimeLineDumbbellItemDataModel;
  secondaryValues: TimeLineDumbbellItemDataModel;
}


export interface TimeLineDumbbellChartDataModel {
  chartModel: TimeLineDumbbellDataModel[];
}

const timeLineDumbbellTransformer: TransformFunction<TimeLineDumbbellChartDataModel, TimeLineDumbbellTransformOptions> = (
  rawData: TimeLineDumbbellRawData,
  onError,
  options
) => {

  const keys = Object.keys(rawData);
  const guard = !keys.some((key) => rawData[key].waarde !== null);

  if (guard) {
    onError('nodata');
    return null;
  }

  const {
    reverse = false,
    formatters = {},
    formattersOptions = {},
  } = options || {} as TimeLineDumbbellTransformOptions;



  const primaryValueFormatterName = formatters['primaryValue'];
  const primaryValueFormatter = (allFormatters as any)[primaryValueFormatterName];
  const primaryValueFormatterOptions = formattersOptions['primaryValue'];

  const secondaryValueFormatterName = formatters['secondaryValue'];
  const secondaryValueFormatter = (allFormatters as any)[secondaryValueFormatterName];
  const secondaryValueFormatterOptions = formattersOptions['secondaryValue'];

  const numberOfRows = calcNumberOfRows(keys);
  const numberOfColumns = 2; // calcNumberOfColumns(keys);

  let chartModelArr: TimeLineDumbbellDataModel[] = [];
  let dataModel: TimeLineDumbbellDataModel[] = [];

  for (let rowNumber = 0; rowNumber < numberOfRows; rowNumber++) {
    let model: TimeLineDumbbellDataModel = {
      primaryValues: { key: '', label: '', rawValue: 0, formattedValue: ''  },
      secondaryValues: {key: '', label: '', rawValue: 0, formattedValue: '' }
    };

    for (let colNumber = 0; colNumber < numberOfColumns; colNumber++) {
      let row = padLeftWithZeros(rowNumber, 2);
      let col = padLeftWithZeros(colNumber, 2);

        let key = `val${row}_${col}`;
        const item:RD = rawData[key];
        const { waarde, label_1, label_4, eenheid } = item;

        if(colNumber === 0) {
          model.primaryValues = {
            key: `primary_${rowNumber}_${waarde}_${label_1}_${label_4}`,
            label: label_1,
            rawValue: waarde,
            formattedValue: primaryValueFormatter ?
            primaryValueFormatter(waarde, primaryValueFormatterOptions) :
            allFormatters.formatValue(waarde, eenheid as ValueUnit, primaryValueFormatterOptions)
          }
        }
        else {
          model.secondaryValues = {
            key: `secondary_${rowNumber}_${waarde}_${label_1}_${label_4}`,
            label: label_4,
            rawValue: waarde,
            formattedValue: secondaryValueFormatter ?
            secondaryValueFormatter(waarde, secondaryValueFormatterOptions) :
            allFormatters.formatValue(waarde, eenheid as ValueUnit, secondaryValueFormatterOptions)
          }
        }
      }

    chartModelArr.push(model);
  }

  dataModel = reverse ? chartModelArr.reverse() : chartModelArr;

  return { chartModel: dataModel};
};

export default timeLineDumbbellTransformer;