import MuiListItemIcon, { ListItemIconProps as MuiListItemIconProps } from '@material-ui/core/ListItemIcon';
import useTheme from '@material-ui/styles/useTheme';
import { ListItem } from 'ppd-library/components/molecules/ListItem';
import { SearchableListItem } from 'ppd-library/components/organisms/SearchableList';
import { SearchableListBox } from 'ppd-library/components/organisms/SearchableListBox';
import React, { useEffect, useState, useRef } from 'react';
import MuiExpandLessIcon from '@material-ui/icons/ExpandLess';
import MuiChevronLeft from '@material-ui/icons/ChevronLeft';
import styled from 'styled-components';
import { httpGetNavigationData, flattenNavigationData, findNavigationDataTreeItem, flattenNavigationDataTreeItem } from '../../utils';

// eslint-disable-next-line
const { DATA_URL } = ppd.appConfig;

export interface SearchableUnitListBoxProps {
  initialUnitCode: string;
  // initialItem: SearchableListItem;
  /**
   * called AFTER selected item has changed
   */
  onSelectedItemChanged?: (newItem: SearchableListItem) => void;
}

const StyledMuiListItemIcon = styled(MuiListItemIcon)`
&& {
  min-width: auto;
}
` as React.ComponentType<MuiListItemIconProps>;

const minInputValue = 3;
const npItem = { OE: 'Business Data Challengers', CD: 'NP00000', 'no parent': undefined };

// TODO: navigationData and flattenedNavigationData in redux?
const SearchableUnitListBox = (props: SearchableUnitListBoxProps) => {
  const {
    initialUnitCode,
    onSelectedItemChanged = () => { }
  } = props;

  const theme = useTheme<any>();
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [selectedItem, setSelectedItem] = useState(null as SearchableListItem | null);

  const rawNavigationData = useRef(null as NavigationData | null);
  const flattenedNavigationData = useRef([] as SearchableListItem[]);
  const currentNavigationDataTreeItem = useRef(null as NavigationDataTreeItem | null);

  const handleClearClick = () => setInputValue('');
  const handleInputChange = (val: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(val.target.value);
  }

  const handleListItemClick = (item: SearchableListItem) => {
    setIsOpen(false);
    setSelectedItem(item);
    onSelectedItemChanged(item);
  };

  const handleClose = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  }

  const handleChipClick = () => {
    currentNavigationDataTreeItem.current = findNavigationDataTreeItem((selectedItem as SearchableListItem).key, rawNavigationData.current as NavigationDataTreeItem);
    setInputValue('');
    setIsOpen(!isOpen);
  };

  const handleParentListItemClick = (treeItem: NavigationDataTreeItem) => {
    const item = { label: treeItem.OE, key: treeItem.CD } as SearchableListItem;
    setIsOpen(false);
    setSelectedItem(item);
    onSelectedItemChanged(item);
  };

  useEffect(() => {
    const getNavigationData = async () => {
      const navData = await httpGetNavigationData(DATA_URL);
      const flatData = await flattenNavigationData(navData);

      rawNavigationData.current = navData;
      flattenedNavigationData.current = flatData;
      currentNavigationDataTreeItem.current = findNavigationDataTreeItem(initialUnitCode, navData);

      if(!!currentNavigationDataTreeItem.current){
        let { OE: label, CD: key} = currentNavigationDataTreeItem.current || {} as NavigationDataTreeItem;
        setSelectedItem({ label, key } as SearchableListItem);
      } else {
        let { OE: label, CD: key} = npItem;
        const newItem = { label, key };
        setSelectedItem(newItem);
        onSelectedItemChanged(newItem);
      }
    };

    getNavigationData();
    //eslint-disable-next-line
  }, []);

  let searchListItems = inputValue.length >= minInputValue ?
    flattenedNavigationData.current :
    flattenNavigationDataTreeItem(currentNavigationDataTreeItem.current as NavigationDataTreeItem);

    // TODO: moet dit in de utils gebeuren?
    searchListItems = searchListItems.sort((a, b) => a['label'] < b['label'] ? -1 : 1);

  const parentCode = !!currentNavigationDataTreeItem.current ? currentNavigationDataTreeItem.current.CD_P : undefined;
  const parentTreeItem = findNavigationDataTreeItem(parentCode as string, rawNavigationData.current as NavigationData);

  return (
    <SearchableListBox
      isOpen={isOpen}
      onClose={handleClose}
      data={searchListItems}
      inputValue={inputValue}
      onClear={handleClearClick}
      onChipClick={handleChipClick}
      onInputChange={handleInputChange}
      minLengthInputValue={minInputValue}
      onListItemClick={handleListItemClick}
      chipLabel={!!selectedItem ? selectedItem.label : ''}
      noResultText={'geen onderliggende afdelingen'}
      parentItem={
        !!parentTreeItem ? <>
          {parentTreeItem.CD !== npItem.CD &&
            <ListItem
              button={true}
              onListItemClick={() => handleParentListItemClick(npItem as any)} // NavigationDataTreeItem
              endElement={
                <StyledMuiListItemIcon title={'Terug naar Business Data Challengers'}>
                  <MuiChevronLeft />
                </StyledMuiListItemIcon>
              }
            >
              {npItem.OE}
            </ListItem>
          }
          <ListItem
            button={true}
            onListItemClick={() => handleParentListItemClick(parentTreeItem)}
            endElement={
              <StyledMuiListItemIcon title={'Omhoog navigeren'}>
                <MuiExpandLessIcon />
              </StyledMuiListItemIcon>
            }
          >
            {parentTreeItem.OE}
          </ListItem>
        </>
          : null
      }
      placeholder={'Zoeken op afdeling'}
      theme={{
        headerIcon: 'primary',
        headerText: 'textSecondary',
        chipBackground: 'primary',
        headerBackground: theme.palette.colors.lightBlue['100'],
        searchResultBackground: theme.palette.colors.white.main
      }}
    />
  );
};

export default SearchableUnitListBox;