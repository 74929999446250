/**
 * Flatten a single tree item.
 * @return the first child level or an empty array.
 */
export function flattenNavigationDataTreeItem(treeItem: NavigationDataTreeItem) {
  const flattenedChildItems: { label: string, key: string; srt: string; }[] = [];
  for (let key in treeItem) {
    if ((typeof treeItem[key]) === 'object') {
      const childItem = treeItem[key] as NavigationDataTreeItem;
      const label = childItem.OE as string;
      const childKey = childItem.CD as string;
      const srt = childItem.SRT as string;
      // let name =  `${flatObject.OE} (${flatObject.CD})`;
      // let originalName = flatObject.OE;
      flattenedChildItems.push({
        srt,
        label,
        key: childKey,
        // code: flatObject.CD,
        // codeP: flatObject.CD_P,
        // name,
        // originalName
      });
    }
  }

  return flattenedChildItems;
}

const flattenedNavigationCache = [] as { label: string, key: string; srt: string; }[];
/**
 * Flatten all navigation data
 * TODO: deze gaat ervan uit dat er nog een 'productiedatum' props in de weg zit..
 */
export function flattenNavigationData(rawNavigationData: NavigationData): { label: string, key: string; srt: string; }[] {
  if (flattenedNavigationCache.length > 0) {
    return flattenedNavigationCache;
  }

  // TODO: kunnen we die recursive meenemen ipv hardcoden?
  const flattenedNavigationData = [] as { label: string, key: string; srt: string; }[];

  const flatten = function (treeItem: NavigationDataTreeItem) {
    let currentTreeItem: NavigationDataTreeItem = {};

    for (let key in treeItem) {
      // TODO: Deze if zorgt er voor dat productiedatum geskipped wordt en de rest gewoon goed gaat, maar is wel verwarrend
      if ((typeof treeItem[key]) === 'object') {
        const childItem = flatten(treeItem[key] as NavigationDataTreeItem);
        const srt = childItem.SRT as string;
        const label = childItem.OE as string;
        const childKey = childItem.CD as string;
        flattenedNavigationData.push({
          srt,
          label,
          key: childKey
        });
      } else {
        currentTreeItem[key] = treeItem[key];
      }
    }

    return currentTreeItem;
  };

  flatten(rawNavigationData as NavigationData);

  flattenedNavigationCache.push(...flattenedNavigationData);
  return flattenedNavigationData;
};


let navigationTreeItemsCache = {} as { [key: string]: NavigationDataTreeItem };
/**
 * @param parentItem parent tree item to search in.
 * @param key key (unitCode) to search for. 
 */
export function findNavigationDataTreeItem(unitCode: string, parentItem: NavigationDataTreeItem): NavigationDataTreeItem | null {
  if (typeof parentItem !== 'object' || !unitCode) {
    return null;
  }

  if (!!navigationTreeItemsCache[unitCode]) {
    return navigationTreeItemsCache[unitCode];
  }

  let keys = Object.keys(parentItem);
  for (let i = 0; i < keys.length; i++) {
    let key = keys[i];

    if (key === unitCode) {
      return parentItem[key] as NavigationDataTreeItem;
    }
    else {
      let deeper = findNavigationDataTreeItem(unitCode, parentItem[key] as NavigationDataTreeItem);
      if (deeper !== null) return deeper;
    }
  }

  return null;
}

export function getParentUnitCode(unitCode: string, navigationData: NavigationData) {
  const letters = unitCode.substr(0, 2);
  const rootUnitGuess = `${letters}00000`;

  if (unitCode === "NP00000") {
    return null;
  } else if (unitCode === rootUnitGuess) {
    // This will be true for lvl 2 unit like AD00000 and DH00000
    return 'NP00000';
  }

  // Note: cannot just use the rootUnitGuess branch,
  // because the two letter rule is not true for all parent  --> children in navigationData
  const unitNavData = findNavigationDataTreeItem(unitCode, navigationData) || {};

  return unitNavData.CD_P;
}

/**
 * Get all the parent unit codes from the navigation data hierarchy.
 * @param unitCode
 * @param {any} navigationData Navigation data object
 * @returns array of parentunitcodes ordered by hierarchy. First item is always NP00000, Last item is always unitCode
 */
export function getParentUnitCodes(unitCode: string, navigationData: any) {

  const parentCodes = [];
  let parentCode = getParentUnitCode(unitCode, navigationData);

  while (parentCode) {
    parentCodes.unshift(parentCode);
    parentCode = getParentUnitCode(parentCode, navigationData);
  }

  return [...parentCodes, unitCode];
}