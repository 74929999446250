import { formatValue } from '../../../utils';
import { TransformFunction, TransformOptionsBase } from '../transform-utils';
import { calcNumberOfColumns, calcNumberOfRows, padLeftWithZeros } from '../transform-utils';
import * as allFormatters from '../../../utils/formatters';
interface RD {
  waarde: number;
  label_1?: string;
  label_2?: string;
  label_3?: string;
  label_4: string;
  eenheid: string;
}

type RDLabelKey = 'label_1' | 'label_2' | 'label_3' | 'label_4';

interface SimpleTableRawData {
  [key: string]: RD;
}

export interface SimpleTableCellData {
  label: string;
  rawValue: number | string | null;
  formattedValue: string;
}

export interface SimpleTableDataModel {
  rows: SimpleTableCellData[][];
}

export interface SimpleTableTransformOptions extends TransformOptionsBase {
  /**
   * @default label_4
   */
  labelKey?: RDLabelKey;
  /**
   * If the rows should be reversed.
   */
  reverse?: boolean;
  /**
   * Properties from rawData (key) to convert to a column. The value will be used as header for the column.
   */
  convertToColumnKeys?: { [key in RDLabelKey]?: string } | { [key in RDLabelKey]?: string }[];
  /**
   * Add tekst to the end of a header by column index.
   */
  appendHeader?: { [key: string]: string };
    /**
   * Add tekst at the begin of a header by column index.
   */
  prependHeader?: { [key: string]: string };
}

export const simpleTableTransformer: TransformFunction<SimpleTableDataModel, SimpleTableTransformOptions> = (
  rawData: SimpleTableRawData,
  onError,
  options
) => {

  const keys = Object.keys(rawData);
  const guard = !keys.some((key) => rawData[key].waarde !== null);

  if (guard) {
    onError('nodata');
    return null;
  }

  const {
    appendHeader,
    prependHeader,
    convertToColumnKeys,
    formatters = {},
    reverse = false,
    labelKey = 'label_4',
    formattersOptions = {},
  } = options || {} as SimpleTableTransformOptions;

  const numberOfRows = calcNumberOfRows(keys);
  const numberOfColumns = calcNumberOfColumns(keys);

  const rows: SimpleTableCellData[][] = [];

  for (let rowNumber = 0; rowNumber < numberOfRows; rowNumber++) {

    let colObjects: RD[] = [];
    for (let colNumber = 0; colNumber < numberOfColumns; colNumber++) {

      let row = padLeftWithZeros(rowNumber, 2);
      let col = padLeftWithZeros(colNumber, 2);

      let key = `val${row}_${col}`;
      const item = rawData[key];

      colObjects.push(item);
    }

    const row: SimpleTableCellData[] = colObjects.map(({ waarde, eenheid, ...labels }, i) => {
      // TODO: de formatters gaan wel op 1 index maar de row en theme props e.d. op 0 index...
      // Dus formatters ook op 0 index doen
      const valueFormatterName = formatters[`${i + 1}`];
      const valueFormatterOptions = formattersOptions[`${i + 1}`];
      const valueFormatter = (allFormatters as any)[valueFormatterName];
      let label = labels[labelKey] as string; // || labels['label_4'];

      if(!!prependHeader && !!prependHeader[`${i}`]) {
        label = `${prependHeader[`${i}`]} ${label}`;
      }

      if(!!appendHeader && !!appendHeader[`${i}`]) {
        label = `${label} ${appendHeader[`${i}`]}`;
      }

      return {
        label,
        rawValue: waarde,
        formattedValue: valueFormatter ? valueFormatter(waarde, valueFormatterOptions) : formatValue(waarde, eenheid as ValueUnit, valueFormatterOptions)
      };
    });

    if (!!convertToColumnKeys && colObjects.length > 0) {
      const convert = (columnKeys: string[], convertOptions: any) => { // convertOptions: { [key in RDLabelKey]?: string }
        for (let keyNumber = 0; keyNumber < columnKeys.length; keyNumber++) {
          const columnKey = columnKeys[keyNumber];

          const value = (colObjects[0] as any)[columnKey];
          const columnValueFormatterName = formatters[convertOptions[columnKey] as string];
          const columnValueFormatterOptions = formattersOptions[convertOptions[columnKey] as string];
          const columnValueFormatter = (allFormatters as any)[columnValueFormatterName];

          row.unshift({
            label: convertOptions[columnKey] as string,
            rawValue: value || null,
            formattedValue: columnValueFormatter ? columnValueFormatter(value as string, columnValueFormatterOptions) : formatValue(value as string, 'tekst' as ValueUnit, columnValueFormatterOptions)
          });
        };
      };

      if (Array.isArray(convertToColumnKeys)) {
        for (let i = 0; i < convertToColumnKeys.length; i++) {
          const columnKeys = Object.keys(convertToColumnKeys[i]) as RDLabelKey[];
          convert(columnKeys, convertToColumnKeys[i]);
        }
      } else {
        const columnKeys = Object.keys(convertToColumnKeys) as RDLabelKey[];
        convert(columnKeys, convertToColumnKeys);
      }
    }

    if (row.some((col) => col.rawValue != null)) {
      rows.push(row);
    }
  }

  let result = reverse ? rows.reverse() : rows;

  return {
    rows: result
  };
}

export default simpleTableTransformer;