import React from 'react';

import {
  addColonSpace,
  StyledLabel,
  StyledMuiDivider,
  StyledSubtitle,
  StyledTitle,
  StyledValue,
  StyledValueWrapper,
  StyledWrapper,
  TooltipTemplateProps,
} from './TooltipTemplate';
import { formatNumber } from '../../../utils/formatters';
import { StyledLegendColor, createLegendBackground } from '../legend-utils/Legend';
import { TooltipOptions } from '../ComponentBaseProps';

export type CustomTooltipTemplate = (props: TooltipTemplateProps) => JSX.Element;

export const cmLabelTooltip: CustomTooltipTemplate = (props) => {
  const {
    title,
    values,
    subtitle,
    tooltipOptions
  } = props;

  const {
    hideColors = false
  } = tooltipOptions || {} as TooltipOptions;

  return (
    <StyledWrapper>
      {!!title && <StyledTitle>{title}</StyledTitle>}
      {!!subtitle && <StyledSubtitle>{subtitle}</StyledSubtitle>}
      {(!!title || !!subtitle) && <StyledMuiDivider />}
      {values.map(({ label, rawValue, formattedValue, colors: originalColors }, index) => {

        const colors = !!originalColors ?
          Array.isArray(originalColors) ? originalColors : [originalColors] :
          [];

        return (
          <div key={`${label}-${index}`}>
            <StyledValueWrapper>
              {colors.length > 0 && !hideColors &&
                <StyledLegendColor legendBackground={createLegendBackground(colors)} />
              }
              <StyledLabel>{!!label ? addColonSpace(label) : ''}</StyledLabel>
              <StyledValue>{formattedValue} ({formatNumber(rawValue, { numberOfDecimals: 1 })})</StyledValue>
            </StyledValueWrapper>
          </div>
        );
      })}
    </StyledWrapper>
  );
};

/**
 * Combines the first two values in one line and discards the rest.
 * For example { label: 'label1', formattedValue: 1000 } and { label: 'iamignored', formattedValue: '30%' }
 * results in 'label1: 1000 (30%)'
 * @param props
 */
export const combinedMainValueTooltip: CustomTooltipTemplate = (props) => {
  const {
    title,
    values,
    subtitle,
    tooltipOptions
  } = props;

  const {
    hideColors = false
  } = tooltipOptions || {} as TooltipOptions;

  const originalColors = values[0].colors;
  const colors = !!originalColors ?
    Array.isArray(originalColors) ? originalColors : [originalColors] :
    [];
  const { label, formattedValue: formattedFirstValue } = values[0] || { } as { label: string, formattedValue: string };
  const { formattedValue: formattedSecondValue } = values[1] || { } as { label: string, formattedValue: string };

  return (
    <StyledWrapper>
      {!!title && <StyledTitle>{title}</StyledTitle>}
      {!!subtitle && <StyledSubtitle>{subtitle}</StyledSubtitle>}
      {(!!title || !!subtitle) && <StyledMuiDivider />}
      <div>
        <StyledValueWrapper>
          {colors.length > 0 && !hideColors &&
            <StyledLegendColor legendBackground={createLegendBackground(colors)} />
          }
          <StyledLabel>{!!label ? addColonSpace(label) : ''}</StyledLabel>
          <StyledValue>{formattedFirstValue} {!!formattedSecondValue && <>({formattedSecondValue})</>}</StyledValue>
        </StyledValueWrapper>
      </div>
    </StyledWrapper>
  );
};