import { useState } from 'react';

export interface TooltipPosition<DataType> {
  y: number;
  x: number;
  index: number;
  data: DataType | null;
  visible: boolean;
}

export const useTooltipPosition = <DataType>() => {
  const [tooltipPosition, setTooltipPosition] = useState(
    {
      y: 0,
      x: 0,
      index: 0,
      data: null,
      visible: false
    } as TooltipPosition<DataType>
  );

  return { tooltipPosition, setTooltipPosition };
}

export const getSVGMousePosition = (event: React.MouseEvent<SVGElement, MouseEvent> | React.TouchEvent<TouchEvent>) => {
  // TODO: this is copied from vx, turn into a chart util or something
  const svgElement = (event.target as SVGElement).ownerSVGElement as SVGSVGElement;
  // TODO: correcte event type 
  let { clientX, clientY } = event.type === 'touchstart' ? (event as any).touches[0] : event;

  // TODO: dit lijkt niet goed te gaan met viewbox...
  // Hoe moet je de tooltip berekenen als de width en height anders zijn dan de viewbox?
  let point = svgElement.createSVGPoint();
  point.x = clientX;
  point.y = clientY;

  let ctm = svgElement.getScreenCTM() as DOMMatrix;
  let inverse = ctm.inverse();
  point = point.matrixTransform(inverse);

  return { x: point.x, y: point.y };
};