import React from 'react';
import styled from 'styled-components';
import MuiTypography from '@material-ui/core/Typography';

export interface LoginErrorMessageProps {
  error: Error;
}

const StyledLoginErrorWrapper = styled.div`
    width: 480px;
    margin: 0 auto;
    text-align: center;
`;

const LoginErrorMessage = (_props: LoginErrorMessageProps) => {

  return (
    <StyledLoginErrorWrapper>
    
      <MuiTypography
        variant={'body1'}
        color={'primary'}
      >
        Er is iets mis gegaan bij het ophalen van accountgegevens of de server kan niet bereikt worden.
      </MuiTypography>
    </StyledLoginErrorWrapper>
  );
};

export default LoginErrorMessage;