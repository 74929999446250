import { TransformFunction, TransformOptionsBase } from '../transform-utils';
import { calcNumberOfRows, calcNumberOfColumns, padLeftWithZeros } from '../transform-utils';
import { formatValue } from '../../../utils';
import * as allFormatters from '../../../utils/formatters';

interface RD {
  waarde: number;
  label_1: string;
  label_4: string;
  eenheid: string;
}

interface RawData {
  [key: string]: RD;
}

export interface BarChartCategorySingleTransformOptions extends TransformOptionsBase {
  /**
   * Indicates the column that will be used to draw the bars (starts at 1).
   * @default 1
   */
  mainValueColumn?: number;

  reverse?: boolean;
}

export interface BarChartCategorySingleItemModel {
  label: string;
  rawValue: number;
  formattedValue: string;
}
export interface BarChartCategorySingleGroupModel {
  groupKey: string;
  groupLabel: string;
  columns: BarChartCategorySingleItemModel[];
  bar: {
    label: string;
    rawValue: number;
    formattedValue: string;
    /**
     * Zero indexed
     */
    correspondingColumn: number;
  };
}
export interface BarChartCategorySingleDataModel {
  groups: BarChartCategorySingleGroupModel[];
}

const barChartCategorySingleTransformer: TransformFunction<BarChartCategorySingleDataModel, BarChartCategorySingleTransformOptions> = (
  originalRawData: RawData | RawData[],
  onError,
  options
) => {

  let rawData = originalRawData as RawData;
  
  // All this to merge two data objects...
  // if (Array.isArray(originalRawData)) {
  //   // set rawData to the first entree
  //   rawData = originalRawData[0];
    
  //   // add all other entrees by appending a new row
  //   for (let i = 1; i < originalRawData.length; i++) {
  //     // count the datarows we already have
  //     const rawDataKeys = Object.keys(rawData);
  //     const currentNumberOfRows = calcNumberOfRows(rawDataKeys);

  //     // get the new datachunk and calculate rows and cols
  //     const rawDataChunk = originalRawData[i];
  //     const rawDataChunkKeys = Object.keys(rawDataChunk);
  //     const rawDataChunkRows = calcNumberOfRows(rawDataChunkKeys);
  //     const rawDataChunkColumns = calcNumberOfColumns(rawDataChunkKeys);

  //     // iterate the chunk items and append them to the rawdata with a new key
  //     for (let rowNumber = 0; rowNumber < rawDataChunkRows; rowNumber++) {
  //       for (let colNumber = 0; colNumber < rawDataChunkColumns; colNumber++) {
  //         let row = padLeftWithZeros(rowNumber, 2);
  //         let col = padLeftWithZeros(colNumber, 2);

  //         const rawDataChunkKey = `val${row}_${col}`;
  //         const rawDataChunkItem = rawDataChunk[rawDataChunkKey];

  //         const newRowNumber = rowNumber + currentNumberOfRows;
  //         const newRow = padLeftWithZeros(newRowNumber, 2);
  //         const newKey = `val${newRow}_${col}`;

  //         rawData[newKey] = rawDataChunkItem;
  //       }
  //     }
  //   }
  // }

  const keys = Object.keys(rawData);
  const guard = !keys.some((key) => rawData[key].waarde !== null);

  if (guard) {
    onError('nodata');
    return null;
  }

  const {
    reverse = false,
    formatters = {},
    mainValueColumn = 1,
    formattersOptions = {}
  } = options || {} as BarChartCategorySingleTransformOptions;

  let groupModels: BarChartCategorySingleGroupModel[] = [];
  const numberOfRows = calcNumberOfRows(keys);
  const numberOfColumns = calcNumberOfColumns(keys);
  
  for (let rowNumber = 0; rowNumber < numberOfRows; rowNumber++) {
    let groupModel: BarChartCategorySingleGroupModel = groupModels[rowNumber];

    for (let colNumber = 0; colNumber < numberOfColumns; colNumber++) {
      let row = padLeftWithZeros(rowNumber, 2);
      let col = padLeftWithZeros(colNumber, 2);

      const key = `val${row}_${col}`;
      const rawDataItem = rawData[key];
      const { waarde, label_1, label_4, eenheid } = rawDataItem;

      const valueFormatterName = formatters[`${colNumber + 1}`];
      const valueFormatterOptions = formattersOptions[`${colNumber + 1}`];
      const valueFormatter = (allFormatters as any)[valueFormatterName];

      if (!groupModel) {
        groupModel = {
          groupKey: label_1.split(' ').join(''),
          groupLabel: label_1,
          columns: [],
          bar: { rawValue: 0, label: '', formattedValue: '', correspondingColumn: 0 }
        };
      }

      groupModel.columns.push(
        {
          label: label_4,
          rawValue: waarde,
          formattedValue: valueFormatter ? valueFormatter(waarde, valueFormatterOptions) : formatValue(waarde, eenheid as ValueUnit, valueFormatterOptions)
        }
      );

      if (colNumber + 1 === mainValueColumn) {
        groupModel.bar = {
          label: label_4,
          rawValue: waarde,
          correspondingColumn: colNumber,
          formattedValue: valueFormatter ? valueFormatter(waarde, valueFormatterOptions) : formatValue(waarde, eenheid as ValueUnit, valueFormatterOptions),
        };
      }
    }
    groupModels.push(groupModel);
  }

  if (groupModels.length === 0) {
    onError('nodata');
    return null;
  }

  return {
    groups: reverse ? groupModels.reverse() : groupModels
  };
};

export default barChartCategorySingleTransformer;