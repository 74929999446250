import React, { useState, useRef } from 'react';

import MuiFab, { FabProps as MuiFabProps } from '@material-ui/core/Fab';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import MenuItem, { MenuItemProps } from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import MuiEditIcon from '@material-ui/icons/Edit';

import styled from 'styled-components';
import { UnitTreePopOverProps } from '../../components/UnitTreePopOver';
import { TileTreePopOverProps } from '../../components/TileTreePopOver';
import FiguurSpeechBubble from './layout/speechBubble';

interface UnitCompareFabProps {
  isDisabled: boolean;
  onOpenUnitTreePopOver: () => void;
  onClearAll: () => void;
  unitTreePopOver: React.ReactElement<UnitTreePopOverProps>;
  onOpenTileTreePopOver: () => void;
  tileTreePopOver: React.ReactElement<TileTreePopOverProps>;
  speechBubbleText: string[] | null;
}

const StyledMenu = styled(Menu)`
  `as React.ComponentType<MenuProps>;

const StyledMenuItem = styled(MenuItem)`
  `as React.ComponentType<MenuItemProps>;

const StyledMuiIconButton = styled(MuiFab)`
  && {
   background: #0072D6;
   box-shadow: none;
    position: fixed;
    right: 8.95rem;
    margin-top: 5.8rem;
    z-index: 1;
    &:hover {
      background: #0072D6;
    }
}
`as React.ComponentType<MuiFabProps>;


const StyledFiguurSpeechBubbleHolder = styled.div`
  && {
    position: fixed;
    right: 9.1rem;
    margin-top: 6.3rem;
    z-index: 1;
    width: 600px;
  }
`as React.ComponentType<MuiFabProps>;

const StyledFiguurSpeechText = styled.div`
  && {
    position: absolute;
    left: 168px;
    width: 252px;
    font-size: 18px;
    top: 170px;
    color: #fff;
  }
`as React.ComponentType<MuiFabProps>;


const UnitCompareFab = (props: UnitCompareFabProps) => {
  const {
    isDisabled,
    unitTreePopOver, onOpenUnitTreePopOver,
    tileTreePopOver, onOpenTileTreePopOver,
    speechBubbleText,
    onClearAll
   } = props;

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const fabElementRef = useRef<HTMLButtonElement>(null);

  const handleMenuClose = () => {
    setIsMenuOpen(false);
  };

  const handleFabClick = (_event: React.MouseEvent<HTMLElement>) => {
    setIsMenuOpen(true);
  };

  const handleUnitMenuItemClick = () => {
    setIsMenuOpen(false);
    onOpenUnitTreePopOver();
  };

  const handleTileMenuItemClick = () => {
    setIsMenuOpen(false);
    onOpenTileTreePopOver();
  };

  const handleHandleClearAll = () => {
    setIsMenuOpen(false);
    onClearAll();
  };

  return (
    <>
      { !!speechBubbleText &&
        <StyledFiguurSpeechBubbleHolder>
          <StyledFiguurSpeechText>{ speechBubbleText.map((text, index) => <p key={`${text}-${index}`}>{text}</p>) }</StyledFiguurSpeechText>
          <FiguurSpeechBubble />
        </StyledFiguurSpeechBubbleHolder> 
      }

      <StyledMuiIconButton
        size={'small'}
        color={'primary'}
        title={'Aanpassen'}
        ref={fabElementRef}
        disabled={isDisabled}
        onClick={handleFabClick}
        aria-label={'Aanpassen'}
        className={'unitCompareEditFab'}
      >
        <MuiEditIcon fontSize={'small'} />
      </StyledMuiIconButton>

      <StyledMenu
        keepMounted
        open={isMenuOpen}
        onClose={handleMenuClose}
        anchorEl={fabElementRef.current}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <StyledMenuItem onClick={handleUnitMenuItemClick}>
          <ListItemIcon>
            <SendIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Afdelingen selecteren" />
        </StyledMenuItem>
        <StyledMenuItem onClick={handleTileMenuItemClick}>
          <ListItemIcon>
            <DraftsIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Tegels selecteren" />
        </StyledMenuItem>
        <StyledMenuItem onClick={handleHandleClearAll}>
          <ListItemIcon>
            <InboxIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Verwijder alles" />
        </StyledMenuItem>
      </StyledMenu>
        {unitTreePopOver}
        {tileTreePopOver}
    </>
  );
}

export default UnitCompareFab;