import { useEffect, useRef, useState } from 'react';

import { GridBreakpoint, GridLayouts } from '../../components/layout/GridLayout';
import { LoggingService } from '../../LoggingService';
import { calculateInitialBreakpoint, createAllTileDimensions } from './grid-utils';

/**
 * hook that returns the following state that every dashboardpage needs:
 * isLoading: if the dashboardpage is busy loading. can be used to display placeholder tiles for example.
 * editEnabled: if the dashboardpage is in editmode
 * currentBreakpoint: the current (grid) breakpoint the screen/grid is in.
 * tileDimensions: the width and height of all the tiles that are currently visible, using 'templateTileId' as key.
 */
export const useDashboardPageState = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [editEnabled, setEditEnabled] = useState(false);
  const [currentBreakpoint, setCurrentBreakpoint] = useState<GridBreakpoint>(calculateInitialBreakpoint);
  const [tileDimensions, setTileDimensions] = useState<{ [key: string]: { width: number; height: number } }>({});

  return {
    isLoading, setIsLoading,
    editEnabled, setEditEnabled,
    currentBreakpoint, setCurrentBreakpoint,
    tileDimensions, setTileDimensions
  };
};

/**
 * hook that returns the following refs that every dashboardpage needs:
 * gridLayoutsRef: holds all the layouts of the current template. These layouts represent the persisted state of the layouts (saved to the database).
 * gridTileWrappersRef: holds the container/wrapper elements of all the tiles, using 'templateTileId' as key.
 */
export const useDashboardPageRefs = () => {
  // NOTE: het maakt niet uit als er een tegel verwijderd wordt voor de refs? 
  // en als er 1 toegevoegd wordt, wordt er in de render een ref aangemaakt.
  const gridLayoutsRef = useRef({} as GridLayouts);
  const gridTileWrappersRef = useRef<{ [key: string]: React.RefObject<HTMLDivElement> }>({});

  return {
    gridLayoutsRef,
    gridTileWrappersRef
  };
};

/**
 * This hook will create and set new tile dimensions when the breakpoint changes.
 * Note!: Only fires when templateDetail is defined and gridLayoutsRef.current[currentBreakpoint] exists.
 * // TODO: refactor, dit kan beter
 * @param layoutChangedTimeout a timeout handle/id that needs to be cleared.
 * @param currentBreakpoint string that represents a breakpoint (xs, sm, md, lg, xlg, etc.)
 * @param templateDetail the current / new templateDetail
 * @param gridLayoutsRef holds all layouts for the current template.
 * @param currentDimensions object that contains the current dimensions of ALL visible tiles.
 * @param gridTileWrappersRef holds the container/wrapper elements of all the tiles, using 'templateTileId' as key.
 * @param setTileDimensions the setter used to update tileDimensions.
 * @param onTimeoutChanged will be called with a new layoutChangedTimeout handle/id. The dimensions will be set when the timeout fires.
 */
export const useCurrentBreakpointChangedEffect = (
  layoutChangedTimeout: number,
  currentBreakpoint: GridBreakpoint,
  templateDetail: API_GET.TemplateDetail | null,
  gridLayoutsRef: React.MutableRefObject<GridLayouts>,
  currentDimensions: { [key: string]: { width: number; height: number } },
  gridTileWrappersRef: React.MutableRefObject<{ [key: string]: React.RefObject<HTMLDivElement> }>,
  setTileDimensions: React.Dispatch<React.SetStateAction<{ [key: string]: { width: number; height: number; } }>>,
  onTimeoutChanged: (layoutChangedTimeout: number) => void
) => {

  useEffect(() => {
    clearTimeout(layoutChangedTimeout);

    if (!!templateDetail) {
      const currentLayout = gridLayoutsRef.current[currentBreakpoint];

      if (!!currentLayout) {
        const newTimeout = setTimeout(() => {
          const newDimensions = createAllTileDimensions(currentLayout, currentDimensions, gridTileWrappersRef);
          setTileDimensions(newDimensions);
          // NOTE: this means the tileContent needs to wait below milliseconds before it is displayed? else a flickering will occur.
        }, 50);

        onTimeoutChanged(newTimeout);
      }
    }

    return () => clearTimeout(layoutChangedTimeout);
    // NOTE: We DO NOT want to add tileDimensions as a dependency here (infinit loop)
    // eslint-disable-next-line
  }, [templateDetail, currentBreakpoint]);
}

export const useDashboardPageLoggingEffect = (themeName: string, unitCode: string, accountId: number) => {

  useEffect(() => {
    LoggingService.logUsage({
      deviceType: 'v2',
      kpi: themeName,
      type: 'appInit',
      unitCode: unitCode,
      userGuid: `${accountId}`
    });

    // eslint-disable-next-line
  }, []);
}