import React from 'react';

const FiguurSpeechBubble = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 869.786 597.236">
      <g id="Group_1821" data-name="Group 1821" transform="translate(-12134 -9238.764)">
        <g id="Group_1820" data-name="Group 1820" transform="translate(-3842 5228)">
          <rect id="Rectangle_1093" data-name="Rectangle 1093" width="679" height="402" transform="translate(15976 4206)" fill="#2b4899" />
          <path id="Polygon_1" data-name="Polygon 1" d="M68.5,0,137,282H0Z" transform="translate(16748.912 4010.764) rotate(45)" fill="#2b4899" />
        </g>
      </g>
    </svg>
  );
}
export default FiguurSpeechBubble;
