import React from 'react';
import {
  ValueBlockSimple,
  ValueBlockSimpleProps,
} from 'ppd-library/components/molecules/ValueBlockSimple';
import styled from 'styled-components';
import { pxToRem } from '../../../utils/converters';

import { HelpTooltipButton } from '../../help/HelpTooltipButton';
import { ComponentBaseProps } from '../ComponentBaseProps';
import { ContentRow } from '../ContentRow';
import { BarSlideMainDataModel } from './barSlideMainTransformer';
import { BarSlide, BarSlideThemeProps } from '../BarSlide';

interface BarSlideMainThemeProps extends BarSlideThemeProps {

}
interface BarSlideMainProps extends ComponentBaseProps<BarSlideMainThemeProps, BarSlideMainDataModel> {
}


const StyledSecondValue = styled(({ ...valueBlockSimpleProps }) => <ValueBlockSimple {...valueBlockSimpleProps} />)`
  align-self: flex-end;
  margin-left: ${pxToRem(16)};
` as React.ComponentType<ValueBlockSimpleProps>;


const BarSlideMain = (props: BarSlideMainProps) => {
  const {
    data,
    help,
    showHelp,
    hideLabel = {},
    themeProperties
  } = props;

  const {
    mainValue,
    firstValue,
    secondValue,
    secondaryValue,
  } = data;

  const { colors = ['gray', '#002647'] } = themeProperties || {} as BarSlideMainThemeProps;
  const { thresholdColor = colors[0] } = themeProperties || {} as BarSlideMainThemeProps;
  let thresholdCrossed: 'higher' | 'equal' | 'lower' = 'lower';

  if (firstValue.rawValue === secondValue.rawValue) {
    thresholdCrossed = 'equal';
  }
  else if (firstValue.rawValue > secondValue.rawValue) {
    thresholdCrossed = 'higher';
  }


  // TODO: ... dit is wel erg specifiek voor 1 tegel fsb?
  let svLabel = !!secondaryValue ? secondaryValue.label as string : '';
  if (!!secondaryValue && typeof secondaryValue.label !== 'string') {
    switch (thresholdCrossed) {
      case 'lower':
        svLabel = secondaryValue.label[0];
        break;
      case 'equal':
        svLabel = secondaryValue.label[1];
        break;
      case 'higher':
        svLabel = !!secondaryValue.label[2] ? secondaryValue.label[2] : secondaryValue.label[1]
    };
  }

  const helpData = !!help ? help.find(({ position }) => position === 1) : undefined;

  return (
    <BarSlide
      {...props}
      renderAbove={
        <ContentRow>
          <ValueBlockSimple
            // labelWidth={5.2}
            valueSize={'large'}
            valueVariant={'h2'}
            labelPosition={'top'}
            label={!hideLabel['mainValue'] ? mainValue.label : ''}
            value={mainValue.formattedValue}
            color={thresholdCrossed === 'higher' ? thresholdColor : undefined}
            afterValueContent={
              <>
                {!!showHelp && !!helpData &&
                  <HelpTooltipButton
                    helpId={helpData.helpId}
                    anchorType={'iconButton'}
                  />
                }
              </>
            }
          />
          {!!secondaryValue &&
            <StyledSecondValue
              // labelWidth={5.2}
              label={svLabel}
              valueVariant={'h2'}
              valueSize={'xsmall'}
              labelPosition={'top'}
              value={secondaryValue.formattedValue}
              color={thresholdCrossed === 'higher' ? thresholdColor : undefined}
            />
          }
        </ContentRow>
      }
    />

  )
};

export default BarSlideMain;
