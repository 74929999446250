import React from 'react'
import styled from 'styled-components';

export interface ContentRowProps {
  double?: boolean;
  center?: boolean;
  className?: string;
  children?: React.ReactNode;
}
// TODO: moet dit wel in rem?

const StyledRow = styled(({ double, center, ...divProps }) => <div {...divProps} />)`
  display: flex;
  align-items: center;
  min-height: ${({double}) => double ? 6.25 * 2: 6.25}rem;
  justify-content: ${({center}) => center ? 'center' : null};
  &:after {
    content: '';
    min-height: inherit;
    font-size: 0;
  }
` as React.ComponentType<{ center: boolean, double: boolean } & React.HTMLProps<HTMLDivElement>>;



/**
 * TODO: is this the way to align the tile contents?
 */
const Row = (props: ContentRowProps) => {
  const { 
    children,
    className,
    center = false,
    double = false,
    } = props;
  return (
    <>
      <StyledRow center={center} double={double} className={className}>
        {children}
      </StyledRow>
    </>
  )
}

export default Row;
