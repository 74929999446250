import { TransformFunction, TransformOptionsBase } from '../transform-utils';
import * as allFormatters from '../../../utils/formatters';

interface RD {
  waarde: number;
  label_4: string;
  eenheid: string;
}

export interface ValueRowsRawData {
  [key: string]: RD;
}

export interface ValueRowsTransformOptions extends TransformOptionsBase {
}

export interface ValueRowsItemDataModel {
  key: string;
  label: string;
  rawValue: number;
  formattedValue: string;
}

export interface ValueRowsDataModel {
  model: ValueRowsItemDataModel[];
}

const valueRowsTransformer: TransformFunction<ValueRowsDataModel, ValueRowsTransformOptions> = (
  rawData: ValueRowsRawData,
  onError,
  options
) => {

  const keys = Object.keys(rawData);
  const guard = !keys.some((key) => rawData[key].waarde !== null);

  if (guard) {
    onError('nodata');
    return null;
  }

  const {
    formatters = {},
    formattersOptions = {}
   } = options || {} as ValueRowsTransformOptions;

  const valueFormatterName = formatters['value'];
  const valueFormatter = (allFormatters as any)[valueFormatterName];
  const valueFormatterOptions = formattersOptions['value'];

  let valueRowsModel: ValueRowsItemDataModel[] = [];

  valueRowsModel = keys.map((key: string) => {
    const item = rawData[key];
    const { label_4, waarde, eenheid } = item;
    let rowItemObjectKey:string = '';

    const formattedValue = valueFormatter ?
    valueFormatter(waarde, valueFormatterOptions) :
    allFormatters.formatValue(waarde, eenheid as ValueUnit, valueFormatterOptions);

    rowItemObjectKey = `${waarde}_${label_4}`;

    return {
      key: rowItemObjectKey,
      label: label_4,
      formattedValue: formattedValue,
      rawValue: waarde,
      } as ValueRowsItemDataModel;
});

  return {
    model: valueRowsModel
  };

};

export default valueRowsTransformer;