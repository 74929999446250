import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
// https://github.com/facebook/create-react-app/blob/master/packages/react-app-polyfill/README.md

import MuiCssBaseLine from '@material-ui/core/CssBaseline';
import MuiThemeProvider from '@material-ui/styles/ThemeProvider';
import React from 'react';
import ReactDOM from 'react-dom';
import { createGlobalStyle } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import { theme } from './constants';

// TODO: overbodig?
const GlobalStylez = createGlobalStyle`

  html {
    box-sizing: border-box;
    font-size: 16px;
  }

  *, *::before, *::after {
    box-sizing: inherit;
  }
`;

const Root = () => {
  return (
    <MuiThemeProvider theme={theme}>
      <MuiCssBaseLine />
      <GlobalStylez />
      {/* <React.StrictMode> */}
      <BrowserRouter>
        <App />
      </BrowserRouter>
      {/* </React.StrictMode> */}
    </MuiThemeProvider>
  )
};

ReactDOM.render(<Root />, document.getElementById('root'));
