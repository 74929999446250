import html2canvas from 'html2canvas';

/**
 * @param {HTMLElement} selectedElement Current selected element
 * @param {string} imageName String to use as image name
 *
 */
export const saveElement = (selectedElement: HTMLElement, imageName: string, toClipboard?: boolean) => {

  if (!(selectedElement instanceof Element)) {
    // TODO: via de notificationBar / redux
    alert('Er is iets fout gegaan bij het downloaden. Probeer het nog eens.')
    return;
  }

  const tSpanStylingArr: string[] = [];
  // Politie sans wordt niet in de svg opgepakt. Een system font werkt wel en gekozen voor arial.
  // Atijd beter dan times new roman :-)
  const svg = selectedElement.getElementsByTagName('svg');
  for (let i = 0; i < svg.length; i++) {

    const tspans = svg[i].getElementsByTagName('tspan');
    if (tspans[0]) {
      // Door alle tspans heen itereren
      for (let j = 0; j < tspans.length; j++) {
        const tspan = tspans[j];
        const tspanStyling = tspan.getAttribute('style');
        // vervangen van de politie font door Arial waar die inline is toegevoegd
        const newStyling = tspanStyling
          ? tspanStyling.replace(/regular/gi, 'font-family: Arial').replace(/bold/gi, 'font-family: Arial; font-weight: bold')
          : ' ';

        // Opslaan in een array voor later gebruik/terug te zetten
        tSpanStylingArr.push(tspanStyling as string);
        tspan.setAttribute('style', `${newStyling} !important`);
      }
    }
    // Overal vervangen van de politie font door Arial
    svg[i].style.fontFamily = '"Arial"';
  }

  html2canvas(selectedElement as HTMLElement, {
    scrollY: -window.scrollY || -window.pageYOffset
  }).then((canvas) => {
    const tileImage = canvas.toDataURL();
    /* tslint:disable-next-line */

    for (let i = 0; i < svg.length; i++) {
      if (svg[i].getElementsByTagName('tspan')[0]) {
        const tspans = svg[i].getElementsByTagName('tspan');
        for (let j = 0; j < tspans.length; j++) {
          const tspan = tspans[j];

          // Terug terug te zetten van de originele styling
          tspan.setAttribute('style', `${tSpanStylingArr[j]}`);
        }
      }
      svg[i].style.fontFamily = 'inherit';
    }
    if (toClipboard) {
      copyToClipboard(tileImage);
    }
    else {
      downloadURI(tileImage, `${imageName}.png`);
    }

  });
}

function downloadURI(uri: string, name: string) {
  // Create a link dom node
  const link = document.createElement('a');

  // Style the link to make it invisble
  link.style.position = 'fixed';
  link.style.left = '-1000px';
  // Give the link a image path and name
  link.download = name;
  link.href = uri;

  // append the link to the  dom
  document.body.appendChild(link);
  // trigger a click event to start downloading the image
  link.click();
  // Cleanup the appended link
  document.body.removeChild(link);
}

// Huh? createElement, createRange, getSelection zijn synchrone functies?
// en de calls naar deze functie zijn ook niet asynchroon?
function copyToClipboard(uri: string) {

  const img = document.createElement('img');
  img.src = uri;
  img.style.position = 'fixed';
  img.style.right = '-1000px';
  document.body.appendChild(img);

  const range = document.createRange();
  range.setStartBefore(img);
  range.setEndAfter(img);
  range.selectNode(img);

  const selection = window.getSelection();
  // sel.addRange(range);
  selection.addRange(range);

  document.execCommand('Copy');

  selection.removeAllRanges();
  selection.empty();

  document.body.removeChild(img);
}