import { Label, LabelProps } from 'ppd-library/charts/atoms/Label';
import Chart from 'ppd-library/charts/Chart';
import { scales } from 'ppd-library/charts/chartUtils';
import { BarVertical } from 'ppd-library/charts/molecules/BarVertical';
import React, { createRef, RefObject, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import muiTheme from '../../../constants/theme';
import { ComponentBaseProps, Margins } from '../ComponentBaseProps';
import { ValuesRelativeDataModel } from './valuesRelativeTransformer';
import { HelpTooltipButton, HelpTooltipButtonProps } from '../../help/HelpTooltipButton';

export interface ValuesRelativeThemeProps {
  /**
   * Color that is used when no or not enough barColors are supplied.
   * @default #2b4899
   */
  barColor?: string;
  /**
   * A color for every bar.
   * @default ['#2b4899','orange','gray']
   */
  barColors?: string[];
}
export interface ValuesRelativeProps extends ComponentBaseProps<ValuesRelativeThemeProps, ValuesRelativeDataModel> {

}

const StyledWrapper = styled.div`
  
` as React.ComponentType<React.HTMLProps<HTMLDivElement>>;

const StyledBarLabel = styled(({ opacity, ...labelProps }) => <Label {...labelProps} />)`
  opacity: ${({ opacity }) => opacity};
  transition: opacity 1000ms 100ms;
` as React.ComponentType<{ opacity: number; } & LabelProps & { innerRef: RefObject<SVGRectElement>; }>;

// TODO: util functie maken van het centreren van een label op deze manier.
const getLabelX = (barX: number, barWidth: number, textWidth: number) => {
  if (barWidth >= textWidth) {
    // text should center on bar
    const difference = barWidth - textWidth;
    return barX + (difference / 2);
  } else {
    // bar should 'center' on text (only text will move though)
    const difference = textWidth - barWidth;
    return barX - (difference / 2);
  }
};

const StyledHelpTooltipButton = styled(({ top, left, ...helpTooltipButtonProps }) => <HelpTooltipButton {...helpTooltipButtonProps} />)`
  && { 
    position: absolute;
    left: ${({ left }) => left}px;
    top: ${({ top }) => top}px;
  }
`as React.ComponentType<{ top: number; left: number; } & HelpTooltipButtonProps>;

const ValuesRelative = (props: ValuesRelativeProps) => {
  const {
    id,
    data,
    help,
    showHelp,
    width = 300,
    height = 300,
    margins: originalMargins = {} as Margins,
    themeProperties
  } = props;

  const { bars } = data;

  // padding between bars and text
  const textPadding = 4;
  const { left = 0, right = 0, top = 32, bottom = 32 } = originalMargins;
  let margins = { left, top: top + textPadding, right, bottom: bottom + textPadding };

  const {
    barColor = '#2b4899',
    barColors = bars.length === 3 ? ['#2b4899', 'orange', 'gray'] : bars.map(() => barColor)
  } = themeProperties || {} as ValuesRelativeThemeProps;

  const labels = bars.map(({ primaryValue }) => primaryValue.label);
  const colorsByKey = labels.reduce((previous, currentLabel, i) => {
    return {
      ...previous,
      [currentLabel]: barColors[i]
    };
  }, {});
  const colorScale = scales.colorScale(colorsByKey);

  const maxValue = Math.max(...bars.map(({ primaryValue }) => primaryValue.rawValue));
  const xScale = scales.stringXScale(labels, width, margins.left, margins.right, 0.5);
  const yScale = scales.numericYScale(0, maxValue, height, margins.bottom, margins.top);

  const primaryLabelsRef = useRef(bars.map(() => createRef<SVGRectElement>()));
 
  const barLabelsRef = useRef(bars.map(() => createRef<SVGRectElement>()));

  const [labelDimensions, setLabelDimensions] = useState(bars.map(() => {
    return {
      primaryLabelHeight: 0, primaryLabelWidth: 0,
      barLabelHeight: 0, barLabelWidth: 0
    };
  }));

  const [initiated, setInitiated] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      const primaryDimensions = primaryLabelsRef.current.map((ref) => {
        const { height: primaryLabelHeight, width: primaryLabelWidth } = (ref.current as SVGRectElement).getBoundingClientRect();
        return { primaryLabelHeight, primaryLabelWidth };
      });

      const barDimensions = barLabelsRef.current.map((ref) => {
        const { height: barLabelHeight, width: barLabelWidth } = (ref.current as SVGRectElement).getBoundingClientRect();
        return { barLabelHeight, barLabelWidth };
      });

      const labelDimensions = primaryDimensions.map((primary, index) => {
        return {
          ...primary,
        
          ...barDimensions[index]
        }
      });

      setLabelDimensions(labelDimensions);
      setInitiated(true);
    }, 20); // delay needed else width are not properly set yet.

    return () => clearTimeout(timeout);
  }, [bars, width]);

  return (
    <StyledWrapper>
      {!!showHelp && !!help && help.map(({ position, helpId }, i) => {
        const bar = bars[position - 1];
        const barLabel = !!bar ? bar.primaryValue.label : null;

        return (
          <React.Fragment key={`${position}-${helpId}-${i}`}>
            {!!barLabel &&
              <StyledHelpTooltipButton
                helpId={helpId}
                top={yScale(0) - 32}
                anchorType={'iconButton'}
                placement={'bottom-start'}
                left={xScale(barLabel) as number - 32}
              />
            }
          </React.Fragment>
        );
      })}
      <Chart
        width={width}
        height={height}
        preserveAspectRatio={'none'}
        id={`${id}-chart-values-relative`}
      >
        {bars.map(({ primaryValue }, i) => {
          const { rawValue, formattedValue: primaryFormattedValue, label } = primaryValue;
          
          return (
            <React.Fragment key={`${id}-${i}`}>
              <Label
                dominantBaseLine={0}
                yText={10}
                padding={0}
                animate={true}
                transform={'y'}
                fill={'transparent'}
                text={[primaryFormattedValue]}
                color={muiTheme.palette.primary.main}
                innerRef={primaryLabelsRef.current[i]}
                y={initiated ? yScale(rawValue) - margins.top : yScale(0) - margins.top}
                x={initiated ? getLabelX(xScale(label) as number, xScale.bandwidth(), labelDimensions[i].primaryLabelWidth) : xScale(label) as number}
              />
            
              <BarVertical
                fill={colorScale(label)}
                width={xScale.bandwidth()}
                x={xScale(label) as number}
                id={`${id}-bar-vertical-${i}`}
                y={initiated ? yScale(rawValue) : yScale(0)}
                height={initiated ? yScale(0) - yScale(rawValue) : 0}
              />
              <StyledBarLabel
                dominantBaseLine={0}
                padding={0}
                animate={false}
                fill={'transparent'}
                y={yScale(0) + textPadding}
                opacity={initiated ? 1 : 0}
                innerRef={barLabelsRef.current[i]}
                color={muiTheme.palette.primary.main}
                text={
                  label.split(' ').map((str, j) => (
                    <tspan
                      x={0}
                      dy={j === 0 ? 14 : 14 * j}
                      key={`${id}-bar-label-${i}-${j}`}
                    >
                      {str}
                    </tspan>
                  ))
                }
                x={getLabelX(xScale(label) as number, xScale.bandwidth(), labelDimensions[i].barLabelWidth || xScale.bandwidth())}
              />
            </React.Fragment>
          );
        }
        )}
      </Chart>
    </StyledWrapper>
  )
};

export default ValuesRelative;
