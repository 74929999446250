// TODO: kijken of dit mooier kan
import '../../../../node_modules/react-grid-layout/css/styles.css';
import '../../../../node_modules/react-resizable/css/styles.css';

import React, { ReactNode, useState } from 'react';
import { ItemCallback, Layouts, Responsive, WidthProvider, Layout } from 'react-grid-layout';
import styled from 'styled-components';

export interface GridLayoutBreakpoints {
  xlg2: number;
  xlg1: number;
  xlg: number;
  lg: number;
  md: number;
  sm: number;
  xs: number;
  // base: number;
  [key: string]: number;
}

export interface GridLayouts extends Layouts {
  xlg2: Layout[];
  xlg1: Layout[];
  xlg: Layout[];
  lg: Layout[];
  md: Layout[];
  sm: Layout[];
  xs: Layout[];
}

// TODO: API.TemplateLayoutSize
export type GridBreakpoint = 'xlg2' | 'xlg1' | 'xlg' | 'lg' | 'md' | 'sm' | 'xs'; // | 'base';

export interface GridLayoutProps {
  layouts: Layouts;
  isEditable: boolean;
  children: ReactNode;
  onWidthChange: () => void;
  onResizeStop: ItemCallback;
  /**
   * The currently active breakpoint
   */
  breakpoint: GridBreakpoint;
  breakpoints: GridLayoutBreakpoints;
  columnsPerBreakpoint: GridLayoutBreakpoints;
  onBreakpointChange: (newBreakpoint: string, numberOfCols: number) => void;
  onDragStop?: ItemCallback;
  rowHeight?: number;
  onResize?: ItemCallback;
  onLayoutChange?: (currentLayout: Layout[], allLayouts: Layouts) => void;
}

const ResponsiveGridLayout = WidthProvider(Responsive);

// TODO: calculate
const StyledGrid = styled.div`
   position: fixed;
   /* // top: 114px; */
    /* margin-top: -6px; */
    /* left: 131px;
    right: 61px; */
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: 0; 
`;

const StyledBackgroundColumn = styled(({ left, ...divProps }) => <div {...divProps} />)`
    opacity: 0.4;
    height: 100%;
    position: absolute;
    display: inline-block;
    left: ${({ left }) => left}%;
    border-left: 1px dashed #909090; /** TODO: theme? */
    border-right: 1px dashed #909090;
` as React.ComponentType<{ left: number; } & React.HTMLProps<HTMLDivElement>>;


const GridLayout = (props: GridLayoutProps) => {
  const {
    layouts,
    children,
    onResize,
    isEditable,
    breakpoint,
    breakpoints,
    onResizeStop,
    onWidthChange,
    onBreakpointChange,
    columnsPerBreakpoint,
    rowHeight = 60,
    onDragStop = () => {},
    onLayoutChange = () => { }
  } = props;
  const [currentNumberOfColumns, setCurrentNumberOfColumns] = useState(() => columnsPerBreakpoint[breakpoint]);

  const handleWidthChange = (
    _containerWidth: number,
    _margin: [number, number],
    numberOfCols: number,
    _containerPadding: [number, number]
  ) => {
    onWidthChange();
    setCurrentNumberOfColumns(numberOfCols);
  }

  return (
    <>
      {isEditable &&
        <StyledGrid>
          {
            [...Array(currentNumberOfColumns + 1)].map((_, i) => (
              <StyledBackgroundColumn key={i} left={100 / currentNumberOfColumns * i} />
            ))
          }
        </StyledGrid>
      }
      {
        <ResponsiveGridLayout
          margin={[24, 24]} // Note: default is [10, 10] and the vertical margin is multiplied by number of rows.
          layouts={layouts}
          onResize={onResize}
          rowHeight={rowHeight}
          onDragStop={onDragStop} // NOTE: onDrop doesnt seem to fire with our current grid and react version.
          useCSSTransforms={true}
          // verticalCompact={false} // NOTE: deprecated
          // preventCollision={true}
          preventCollision={false}
          isDraggable={isEditable}
          isResizable={isEditable}
          className={'gridLayout'}
          measureBeforeMount={true} // NOTE: does this affect performance?
          containerPadding={[6, 0]}
          breakpoints={breakpoints}
          cols={columnsPerBreakpoint}
          onResizeStop={onResizeStop}
          onLayoutChange={onLayoutChange}
          onWidthChange={handleWidthChange}
          onBreakpointChange={onBreakpointChange}
        >
          {children}
        </ResponsiveGridLayout>
      }

    </>
  );
};

export default GridLayout;