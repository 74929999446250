
/**
 * Reduce an object key/value pair to a number.
 * Add the values of each property in keys until you hit 'untilKey'.
 * @param untilKey stop adding/reducing when you hit this key in the keys array.
 * @param keys The property names of the object to add up
 * @param data The object to reduce
 * @param includeUntilKey Include the value of the property corresponding to untilKey. Default true.
 */
export const reduceObjectByKey = (
    data: { [key: string]: number },
    keys: string[],
    untilKey: string,
    includeUntilKey = true
  ) => {
    let stop = false;
    const value = keys.reduce((previous, k) => {
      if(k === untilKey && !includeUntilKey){
        stop = true;
      }

      return stop ? previous : previous + data[k];
    }, 0);

    return value;
  }


/**
 * Group an array of objects by key
 * @param collection Array  of objects.
 * @param property key to group with
 */
export const groupObjectBy = (collection: any[], property: string) =>  {
    let i = 0, val, index,
        values = [], result = [];
    for (; i < collection.length; i++) {
        val = collection[i][property];
        index = values.indexOf(val);
        if (index > -1)
            result[index].push(collection[i]);
        else {
            values.push(val);
            result.push([collection[i]]);
        }
    }
    return result;
}