import MuiLinearProgress, {
  LinearProgressProps as MuiLinearProgressProps,
} from '@material-ui/core/LinearProgress';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { useEffect, useRef, useState } from 'react';
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router-dom';
import styled from 'styled-components';

import { MainMenu } from './components/layout/MainMenu';
import { ToolBar } from './components/layout/ToolBar';
import {
  CreateNewCustomDashboardModal as ModalCreateDashboard,
  CreateNewCustomDashboardModalOptions,
  CustomDashboardsOverviewModal as ModalCustomDashboardOverview,
} from './components/modals/CustomDashboardModal';
import { HelpModal } from './components/modals/HelpModal';
import { NotificationBar, Severity } from './components/NotificationBar';
import { unitcomparePage } from './constants';
import LoginErrorMessage from './LoginErrorMessage';
import CustomDashboardOverviewPage from './pages/CustomDashboardOverviewPage';
import CustomDashboardPage from './pages/CustomDashboardPage';
import DashboardPage from './pages/DashboardPage';
import {
  copyAndPostLayouts,
  copyAndPostTemplateTile,
  copyAndPostTemplateTiles,
  generateAndPostCustomTemplateLayoutItems,
} from './pages/pageActions';
import {
  customDashboardOverviewPagePath,
  customDashboardPagePath,
  dashboardPagePath,
  eigenOverzichtTemplate as customDashboardOverviewTemplate,
  unitcomparePath,
} from './pages/pageConstants';
import UnitComparePage from './pages/UnitComparePage';
import { asyncTryCatch } from './utils/errorHandling';
import {
  httpGet,
  httpGetAllStaticTemplates,
  httpPost,
  httpPut,
} from './utils/http';
import { get as getFromLocal, save as saveToLocal, save, get } from './utils/localStorage';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MapPage from './pages/mapPage';

// eslint-disable-next-line
const { API_URL, HASH } = ppd.appConfig;

type PathParamsType = {
  templatePath: string;
}

interface AppProps extends RouteComponentProps<PathParamsType> {

}

export interface MediaDevices {
  mobile: boolean;
  mobilePhone: boolean;
  mobileTablet: boolean;
  desktop: boolean;
  tabletLandscape: boolean;
}

const StyledContentContainer = styled(({ menuOpen, isMobile, ...divProps }) => <div {...divProps} />)`
  position: relative;
  background-color: #FAFAFA;
  padding-top: 7rem;
  padding-left:${({ isMobile }) => isMobile ? `0.5rem` : '8.5rem'}; /* menu width (72px) + padding (64px) */
  padding-right:${({ isMobile }) => isMobile ? `0.5rem` : '4rem'};
  padding-bottom: 6rem;
  min-height: 100vh;
  overflow-x: ${({ menuOpen }) => menuOpen ? 'hidden' : 'auto'};
`as React.ComponentType<{ menuOpen: boolean; isMobile: boolean } & React.HTMLProps<HTMLDivElement>>;

const StyledMuiLinearProgress = styled(MuiLinearProgress)`
  && {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 3px;
    z-index: 10001;
    background-color: transparent;
/** TODO: muitheme */
    .MuiLinearProgress-bar {
      background-color: #FFAA00;
    }
  }
` as React.ComponentType<MuiLinearProgressProps>;

// TODO: redux
function iOS() {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

function App(props: AppProps) {
  const { history } = props;

  const [go, setGo] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [login, setLogin] = useState<API_GET.Login | null>(null);
  const [loginError, setLoginError] = useState<Error | null>(null);
  const [account, setAccount] = useState<API_GET.Account | null>(null);
  const [staticTemplates, setStaticTemplates] = useState<API_GET.Template[]>([]);
  const [accountTemplates, setAccountTemplates] = useState<API_GET.Template[]>([]);

  const [menuOpen, setMenuOpen] = useState(false);
  const [menuHover, setMenuHover] = useState(false);
  const [helpActive, setHelpActive] = useState(false);
  const [dashBoardEditEnabled, setDashBoardEditEnabled] = useState(false);
  const [selectedMenuItemPath, setSelectedMenuItemPath] = useState<string>();
  const [selectedTemplate, setSelectedTemplate] = useState<API_GET.Template>();
  const [themeHelp, setThemeHelp] = useState<{ id: number | null; active: boolean; }>({ id: null, active: false });

  const [notificationBar, setNotificationBar] = useState<{
    visible: boolean;
    message?: string;
    severity?: string;
    confirm?: boolean;
    confirmCancelText?: string;
    confirmApproveText?: string;
    confirmCallback?: () => void;
  }>({ visible: false });

  const [modalAddToDashboardTemplateTile, setModalAddToDashboardTemplateTile] = useState<API_GET.TemplateTileDetail | null>(null);
  const [modalCreateDashboardActive, setModalCreateCustomDashboardActive] = useState<boolean>(false);
  const modalCreateDashboardDataRef = useRef<{ template: API_GET.TemplateDetail; layouts: API_GET.TemplateLayout[], oeCode: string } | number | null>(null);
  const hasInitialCustomDashboards = useRef<boolean>(false);

  const menuOpenWidth: number = 334;  // px
  const menuClosedWidth: number = 84;  // px
  const menuOpenSpeed: number = 300;  // ms

  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up('lg'));
  const isMobilePhone = !useMediaQuery(theme.breakpoints.up('sm'));

  const mediaDevices: MediaDevices = {
    mobile: isMobile,
    mobilePhone: isMobilePhone,
    mobileTablet: !isMobilePhone && isMobile,
    tabletLandscape: useMediaQuery(theme.breakpoints.up('md')) && !!isMobile,
    desktop: !isMobile,
  }

  useEffect(() => {
    const loginSSO = async () => {
      try {
        const loginP = httpGet<API_GET.Login>(`${API_URL}/login`);
        const result = await loginP;

        setLogin(result);
      } catch (error) {
        setIsLoading(false);
        setLoginError(new Error(error));
        console.log(`SSO error: ${error}`);
        // TODO: doorgaan zonder account inlog zodat een gebruiker wel gewoon de app kan gebruiken?
      }
    };

    loginSSO();
  }, [go]);

  // Opslaan van een timestamp om later te vergelijken.
  // Bij een refresh moet de laatst bezochte thema weergeopend worden.
  // Gebruik gemaakt van de unload listener. Bij gebuik van de useEffect return wordt ook bij het loaden afgevuurd.
  useEffect(() => {
    const ww = get<string>('daas-demo');

    if(ww && hashCode(ww) === 1247882019) {
      setGo(true);
    }

    window.addEventListener('unload', () => {
      const unixTimestamp = Math.floor(Date.now() / 1000);
      saveToLocal('PDB-REFRESH-TIMER', `${unixTimestamp}`);
    });
  }, []);

  useEffect(() => {
    const getData = async () => {
      const { id: accountId } = login || {} as API_GET.Login;

      const accountP = httpGet<API_GET.Account>(`${API_URL}/accounts/${accountId}`);
      const accountTemplatesP = httpGet<API_GET.Template[]>(`${API_URL}/accounts/${accountId}/templates`);
      const staticTemplatesP = httpGetAllStaticTemplates(API_URL);

      const [account, aTemplates, sTemplates] = await Promise.all([accountP, accountTemplatesP, staticTemplatesP]);

      setCustomHomePage(account.customHomepageId, aTemplates, sTemplates);
      hasInitialCustomDashboards.current = aTemplates.length > 0;

      // TODO: reducer
      setAccount(account);
      setAccountTemplates(aTemplates);
      setStaticTemplates(sTemplates);
    };

    if (!!login) {
    
      getData();
    }

    //eslint-disable-next-line
  }, [login]);

  useEffect(() => {
    if (!!selectedTemplate) {
      const { isCustom, path } = selectedTemplate;
      let selectedItemPath = `/${path}`;

      if (isCustom) {
        selectedItemPath = `/dashboards/${path}`;
      } else {
        selectedItemPath = `/kpis/${path}`;
      }

      setSelectedMenuItemPath(selectedItemPath);
    }
  }, [selectedTemplate]);

  const setCustomHomePage = (customHomepageId: number | null, aTemplates: API_GET.Template[], sTemplates: API_GET.Template[]) => {
    const localStoredRefreshTimeStamp = getFromLocal('PDB-REFRESH-TIMER');
    const unixTimestamp = Math.floor(Date.now() / 1000);

    if (!localStoredRefreshTimeStamp || (unixTimestamp > (parseInt(localStoredRefreshTimeStamp as string) + 5))) {
      const landingTemplateId = customHomepageId === null ? 1 : customHomepageId;
      const landingTemplate = [...aTemplates, ...sTemplates].find(({ id }) => id === landingTemplateId) || { isCustom: false, path: '/' };
      const { isCustom, path } = landingTemplate;
      const templatePath = `/${isCustom ? 'dashboards' : 'kpis'}/${path}`;

      history.push(templatePath);
    }
  }

  const handleMenuClick = () => {
    setMenuHover(!menuHover);
  };

  const handleItemClick = () => {
    if (isMobilePhone) setMenuOpen(false);
  };

  const handleThemeInfoClick = (id: number) => {
    setThemeHelp({
      id,
      active: true
    })
  };

  const closeNotificationBar = () => {
    setNotificationBar({ visible: false })
  };

  const handleNotificationBarClose = () => {
    closeNotificationBar();
  }

  // NOTE! should work with a queue or check which component is changing to avoid conflicts
  // TODO: redux
  const handleLoadingChange = (loading: boolean) => {
    setIsLoading(loading);
  };

  const handleUnitCompareClearAll = (onConfirm: () => void) => {
    setNotificationBar({
      visible: true,
      severity: 'warning',
      message: 'Weet je zeker dat je alles wilt verwijderen?',
      confirm: true,
      confirmApproveText: 'Verwijderen',
      confirmCallback: () => {
        closeNotificationBar();
        onConfirm();
      }
    });
  };

  // TODO: het posten en afhandelen mag niet in app, verplaatsen naar modal?
  const handleModalAddToDashboardClick = async (templateId: number) => {
    setIsLoading(true);

    try {
      // TODO: beter de layoutitems aan de back-end generen?
      const layoutsPromise = httpGet<API_GET.TemplateLayout[]>(`${API_URL}/templates/${templateId}/layouts`);

      const template = accountTemplates.find(({ id }) => id === templateId) as API_GET.Template;
      if (!!template.organisationUnit) {
        (modalAddToDashboardTemplateTile as API_GET.TemplateTileDetail).organisationUnit = null;
      }

      const createdTemplateTile = await copyAndPostTemplateTile(
        modalAddToDashboardTemplateTile as API_GET.TemplateTileDetail,
        templateId
      );

      const templateLayouts = await layoutsPromise;

      await generateAndPostCustomTemplateLayoutItems(templateLayouts, createdTemplateTile.id);

    } catch (err) {
      //TODO: show notification
      console.log(err);
    }

    setModalAddToDashboardTemplateTile(null);
    setIsLoading(false);
  };

  const handleModalAddToDashboardClose = () => {
    setModalAddToDashboardTemplateTile(null);
  };

  const handleModalCreateDashboardClose = () => {
    modalCreateDashboardDataRef.current = null;
    setModalCreateCustomDashboardActive(false);
  };

  const handleCopyDashboard = (template: API_GET.TemplateDetail, layouts: API_GET.TemplateLayout[], oeCode: string) => {
    modalCreateDashboardDataRef.current = { template, layouts, oeCode };
    setModalCreateCustomDashboardActive(true);
  };

  const saveNewTemplate = async (dashboardName: string, organisationUnitPerTile: boolean) => {
    const layoutSizes: API.TemplateLayoutSize[] = ['xlg2', 'xlg1', 'xlg', 'lg', 'md', 'sm', 'xs'];
    const template: API_POST.Template = {
      // path: null,
      // helpId: null,
      enabled: true,
      isCustom: true,
      // shortName: null,
      isOverview: false,
      cleanTiles: false,
      // placeholderText: null,
      iconName: 'add_to_queue',
      name: `${dashboardName}`,
      createdById: (account as API_GET.Account).id,
      organisationUnitCode: !organisationUnitPerTile ? 'NP00000' : undefined,
    };

    // TODO: delete template if layout post fails?
    // TODO: create layouts on server?
    const templatePostResult = await asyncTryCatch(httpPost<API_POST.Template, API_GET.Template>(`${API_URL}/templates_custom`, template));
    if (templatePostResult.isError) {
      // TODO: show notification
      throw new Error(templatePostResult.value as any);
    } else {
      const createdTemplate = templatePostResult.value as API_GET.Template;

      // It is a new template, so only need to create empty layouts
      const emptyLayouts = layoutSizes.map((size) => {
        return {
          templateId: createdTemplate.id,
          layoutSize: size,
          items: []
        } as API_POST.TemplateLayout;
      });

      // TODO: create layouts on server
      const layoutPostResult = await asyncTryCatch(httpPost<API_POST.TemplateLayout[], API_GET.TemplateLayout[]>(`${API_URL}/layouts_bulk`, emptyLayouts));

      if (layoutPostResult.isError) {
        // TODO: show notification
        // TODO: delete template
        throw new Error(layoutPostResult.value as any);
      }

      return createdTemplate;
    }
  }

  // TODO: misschien gewoon duplicate gebruiken?
  const saveCopyTemplate = async (
    dashboardName: string,
    templateDetail: API_GET.TemplateDetail,
    layouts: API_GET.TemplateLayout[],
    oeCode: string,
    organisationUnitPerTile: boolean) => {
    const { templateTiles } = templateDetail;

    const template: API_POST.Template = {
      // path: null,
      // helpId: null,
      enabled: true,
      isCustom: true,
      // shortName: null,
      isOverview: false,
      cleanTiles: false,
      // placeholderText: null,
      iconName: 'add_to_queue',
      name: `${dashboardName}`,
      createdById: (account as API_GET.Account).id,
      organisationUnitCode: !organisationUnitPerTile ? oeCode : undefined,
    };

    // TODO: delete template if layout post fails?
    // TODO: create layouts on server?
    const templatePostResult = await asyncTryCatch(httpPost<API_POST.Template, API_GET.Template>(`${API_URL}/templates_custom`, template));
    if (templatePostResult.isError) {
      // TODO: show notification
      throw new Error(templatePostResult.value as any);
    } else {
      const createdTemplate = templatePostResult.value as API_GET.Template;
      const templateTilePostsResult = await asyncTryCatch(
        copyAndPostTemplateTiles(
          templateTiles,
          createdTemplate.id,
          organisationUnitPerTile ? oeCode : undefined
        )
      );

      if (templateTilePostsResult.isError) {
        // TODO: show notification
        // TODO: delete template
        throw new Error(templateTilePostsResult.value as any);
      } else {
        const createdTemplateTiles = templateTilePostsResult.value as { existingTemplateTile: API_GET.TemplateTileDetail, createdTemplateTile: API_GET.TemplateTileDetail }[];
        const templateTileOldNewMapping = createdTemplateTiles.map(({ existingTemplateTile, createdTemplateTile }) => {
          return { oldId: existingTemplateTile.id, newId: createdTemplateTile.id };
        });

        const layoutsPostResult = await asyncTryCatch(copyAndPostLayouts(layouts, createdTemplate.id, templateTileOldNewMapping));
        if (layoutsPostResult.isError) {
          // TODO: show notification
          // TODO: delete template
          // TODO: delete templateTiles
          throw new Error(layoutsPostResult.value as any);
        }
      }

      return createdTemplate;
    }
  };

  const saveDuplicateTemplate = async (dashboardName: string, templateId: number, organisationUnitPerTile: boolean) => {
    const templatePostResult = await asyncTryCatch(
      httpPost<API_PUT.Template, API_GET.TemplateDetail>(`${API_URL}/templates/duplicate/${templateId}`, {
        name: dashboardName,
        organisationUnitCode: organisationUnitPerTile ? null : 'NP00000'
      })
    );

    if (templatePostResult.isError) {
      // TODO: show notification
      throw new Error(templatePostResult.value as any);
    } else {
      const createdTemplate = templatePostResult.value as API_GET.TemplateDetail;

      return createdTemplate;
    }
  };

  // TODO: toon succes of fail boodschap.
  // TODO: het saven mag ook door de modal gedaan worden, de modal of de modal content mag ook met de redux store connecten?
  // TODO: het posten en afhandelen mag niet in app, verplaatsen naar page wanneer de notificationService is gemaakt en redux er in zit
  const handleModalCreateDashboardOk = async (dashboardName: string, options: CreateNewCustomDashboardModalOptions) => {
    setIsLoading(true);

    let createdTemplate: API_GET.Template | undefined = undefined;
    if (!modalCreateDashboardDataRef.current) {
      try {
        createdTemplate = await saveNewTemplate(dashboardName, options.organisationUnitPerTile);
      } catch (e) {
        console.log(e);
        // TODO: En nu?
      }
    } else if (typeof modalCreateDashboardDataRef.current === 'number') {
      // duplicate current dashboard as saved in db
      const templateId = modalCreateDashboardDataRef.current;
      try {
        createdTemplate = await saveDuplicateTemplate(dashboardName, templateId, options.organisationUnitPerTile);
      } catch (e) {
        console.log(e);
        // TODO: En nu?
      }
    } else {
      // copy current dashboard as is on client
      const { template: templateDetail, layouts, oeCode } = modalCreateDashboardDataRef.current;
      try {
        createdTemplate = await saveCopyTemplate(dashboardName, templateDetail, layouts, oeCode, options.organisationUnitPerTile);
      } catch (e) {
        console.log(e);
        // TODO: En nu?
      }
    }

    modalCreateDashboardDataRef.current = null;
    setModalCreateCustomDashboardActive(false);

    if (!!createdTemplate) {
      setAccountTemplates([...accountTemplates, createdTemplate]);
      history.push(`/dashboards/${createdTemplate.path}`);
    }

    setIsLoading(false);
  }

  const handleDeleteAccountTemplate = (templateId: number) => {
    const newTemplates = accountTemplates.filter(({ id }) => id !== templateId);
    setAccountTemplates(newTemplates);
  };

  const handleAddTemplate = () => {
    setModalCreateCustomDashboardActive(true);
  }

  // TODO: wanneer we een modal service hebben roep je gewoon de juiste functie aan die de juiste modal toont.
  //  Het antwoord en de props van de modal worden teruggeven in een promise. dit geld voor alle modals.
  const handleDuplicateCustomDashboard = (templateId: number) => {
    modalCreateDashboardDataRef.current = templateId;
    setModalCreateCustomDashboardActive(true);
  };

  const handleAddToCustomDashboard = (templateTile: API_GET.TemplateTileDetail, unitCode: string) => {
    const clone = { ...templateTile, organisationUnit: { code: unitCode } } as API_GET.TemplateTileDetail;
    setModalAddToDashboardTemplateTile(clone);
  }

  const handleCustomDashboardTitleChange = async (template: API_GET.Template) => {
    // TODO: redux
    const { id: updatedTemplateId } = template;
    const filteredAccountTemplates = accountTemplates.filter(({ id }) => id !== updatedTemplateId);

    setAccountTemplates([...filteredAccountTemplates, template]);
  }

  const handleUpdateCustomTemplate = async (templateId: number, template: API_PUT.Template) => {
    const updatedTemplateResult = await asyncTryCatch(httpPut<API_PUT.Template, API_GET.Template>(`${API_URL}/templates/${templateId}`, template));

    if (updatedTemplateResult.isError) {
      // TODO: show notification, we could not save template
      console.log(updatedTemplateResult.value);
    } else {
      const updatedTemplate = updatedTemplateResult.value as API_GET.Template;
      const index = accountTemplates.findIndex((template) => template.id === templateId);

      const clone = [...accountTemplates];
      clone[index] = updatedTemplate;

      setAccountTemplates(clone);
    }
  }

  const wachtwoordRef = useRef('');
  const handleWachtwoordInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.trim();

    wachtwoordRef.current = inputValue;
  };

  const hashCode = (str: string) => {
    var hash = 0, i, chr;
    if (str.length === 0) return hash;
    for (i = 0; i < str.length; i++) {
      chr   = str.charCodeAt(i);
      hash  = ((hash << 5) - hash) + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  };

  const handleGoClick = () => {

    if(hashCode(wachtwoordRef.current) === HASH) {
      save('daas-demo', wachtwoordRef.current);
      setGo(true);
    }
  };

  const handleTextFieldKeyDown = (e: React.KeyboardEvent) => {
    const enterKeyCode = 13;
    if(e.which === enterKeyCode) {
      handleGoClick();
    }
  };

  if (!go) {
    return (
      <div>
        <TextField
          required={true}
          autoFocus={true}
          label={'Wachtwoord'}
          type={'password'}
          onKeyDown={(e:React.KeyboardEvent) => handleTextFieldKeyDown(e) }
          onChange={(ev: React.ChangeEvent<HTMLInputElement>) => handleWachtwoordInputChange(ev)}
        />
        <Button
          color={'primary'}
          variant={'contained'}
          onClick={handleGoClick}
        >
          OK
          </Button>
      </div>
    );
  }

  return (
    <>
      {!!isLoading &&
        <StyledMuiLinearProgress />
      }
      {!!loginError &&
        <LoginErrorMessage error={loginError} />
      }
      {!!account && // TODO: something with react.suspense?
        <>
          <MainMenu
            hover={menuHover}
            mediaDevices={mediaDevices}
            open={menuHover}
            onItemClick={handleItemClick}
            onMenuClick={handleMenuClick}
            menuOpenSpeed={menuOpenSpeed}
            menuOpenWidth={menuOpenWidth}
            menuClosedWidth={menuClosedWidth}
            staticTemplates={staticTemplates}
            onAddTemplate={handleAddTemplate}
            customTemplates={accountTemplates}
            selectedItemPath={selectedMenuItemPath}
            dashBoardEditEnabled={dashBoardEditEnabled}
          />
          <HelpModal
            helpId={themeHelp.id}
            open={themeHelp.active}
            onLoadingChange={handleLoadingChange}
            onClose={() => setThemeHelp({ ...themeHelp, active: false })}
          />
          <ToolBar
            menuOpen={menuOpen}
            helpActive={helpActive}
            mediaDevices={mediaDevices}
            menuOpenWidth={menuOpenWidth}
            menuOpenSpeed={menuOpenSpeed}
            displayName={account.fullName}
            onHelpClick={() => {
              setHelpActive(!helpActive);
            }}
            onAccountClick={() => {
             
            }}
            onNotificationsClick={() => {
              alert('Notificatie functionaliteit binnenkort beschikbaar');
            }}
            onMenuButtonClick={handleMenuClick}
            onOnboardingClick={() => { }}
            dashBoardEditEnabled={dashBoardEditEnabled}
          />
          <NotificationBar
            open={notificationBar.visible}
            onClose={handleNotificationBarClose}
            message={notificationBar.message}
            severity={notificationBar.severity as Severity}
            confirm={notificationBar.confirm}
            confirmApproveButtonText={notificationBar.confirmApproveText}
            confirmCallback={notificationBar.confirmCallback}
          />
          <StyledContentContainer menuOpen={menuOpen} isMobile={isMobile}>
            <Switch>
              <Route exact path={unitcomparePath}
                render={(props) => {
                  const { history } = props;
                  setSelectedMenuItemPath(`/${unitcomparePage.path}`);

                  return (
                    <UnitComparePage
                      history={history}
                      account={account}
                      isMobile={isMobile}
                      helpActive={helpActive}
                      staticTemplates={staticTemplates}
                      isTablet={mediaDevices.mobileTablet}
                      onLoadingChange={handleLoadingChange}
                      onClearAll={handleUnitCompareClearAll}
                      isIos={iOS()}
                    />
                  );
                }} />
              <Route
                path={dashboardPagePath}
                render={(props) => {
                  const { match, history } = props;
                  const { templatePath } = match.params;

                  if (staticTemplates.length === 0) {
                    handleLoadingChange(false);
                    return null;
                  }

                  const template = staticTemplates.find(({ path }) => path === templatePath);
                  setSelectedTemplate(template);

                  return (
                    <DashboardPage
                      account={account}
                      history={history}
                      menuOpen={menuOpen}
                      template={template}
                      helpActive={helpActive}
                      mediaDevices={mediaDevices}
                      menuOpenWidth={menuOpenWidth}
                      menuOpenSpeed={menuOpenSpeed}
                      onCopyDashboard={handleCopyDashboard}
                      onLoadingChange={handleLoadingChange}
                      onThemeInfoClick={handleThemeInfoClick}
                      hasCustomTemplates={accountTemplates.length > 0}
                      onAddToCustomDashboard={handleAddToCustomDashboard}
                      onDashboardEditEnabledChange={setDashBoardEditEnabled}
                    />
                  );
                }}
              />

              <Route
                path={customDashboardOverviewPagePath}
                render={(props) => {
                  const { history } = props;

                  if (accountTemplates.length === 0) {
                    //TODO: show a placeholder or 404
                    // return null;
                  }
                  setSelectedTemplate(customDashboardOverviewTemplate);
                  setSelectedMenuItemPath(customDashboardOverviewPagePath);

                  return (
                    <CustomDashboardOverviewPage
                      account={account}
                      history={history}
                      menuOpen={menuOpen}
                      mediaDevices={mediaDevices}
                      menuOpenWidth={menuOpenWidth}
                      menuOpenSpeed={menuOpenSpeed}
                      customTemplates={accountTemplates}
                      onLoadingChange={handleLoadingChange}
                      onDeleteTemplate={handleDeleteAccountTemplate}
                      onTitleChange={handleCustomDashboardTitleChange}
                      onDuplicateDashboard={handleDuplicateCustomDashboard}
                      onDashboardEditEnabledChange={setDashBoardEditEnabled}
                    />
                  );
                }}
              />

              <Route
                path={customDashboardPagePath}
                render={(props) => {
                  const { match, history } = props;
                  const { templatePath } = match.params;

                  if (accountTemplates.length === 0) {
                    //TODO: show a placeholder or 404
                    // return null;
                  }

                  const template = accountTemplates.find(({ path }) => path === templatePath);
                  setSelectedTemplate(template);

                  return (
                    <CustomDashboardPage
                      account={account}
                      history={history}
                      menuOpen={menuOpen}
                      template={template}
                      helpActive={helpActive}
                      mediaDevices={mediaDevices}
                      menuOpenWidth={menuOpenWidth}
                      menuOpenSpeed={menuOpenSpeed}
                      onLoadingChange={handleLoadingChange}
                      onThemeInfoClick={handleThemeInfoClick}
                      onUpdateTemplate={handleUpdateCustomTemplate}
                      onDuplicateDashboard={handleDuplicateCustomDashboard}
                      onDashboardEditEnabledChange={setDashBoardEditEnabled}
                    />
                  );
                }}
              />

              <Route
                exact
                path={'/map'}
                render={(_props) => {
                  setSelectedTemplate(undefined);

                  return (
                    <MapPage
                    onLoadingChange={handleLoadingChange}
                    />
                  );
                }}
              />

              <Redirect exact from={'/'} to={'/kpis/overzicht'} />
              <Redirect exact from={'/dashboards'} to={'/dashboards/overzicht'} />
              <Redirect exact from={'/kpis'} to={'/kpis/overzicht'} />

              <Redirect from={'*'} to={'/kpis/overzicht'} />
            </Switch>
          </StyledContentContainer>
        </>
      }
      <ModalCustomDashboardOverview
        templates={accountTemplates}
        onClose={handleModalAddToDashboardClose}
        isOpen={!!modalAddToDashboardTemplateTile}
        onTemplateClick={handleModalAddToDashboardClick}
      />

      <ModalCreateDashboard
        isOpen={modalCreateDashboardActive}
        onOk={handleModalCreateDashboardOk}
        onClose={handleModalCreateDashboardClose}
      />
    </>
  );
}

export default withRouter(App);