import { ValueBlockSimple } from 'ppd-library/components/molecules/ValueBlockSimple';
import React, { useRef, createRef, useEffect, useState } from 'react';

import { ComponentBaseProps } from '../ComponentBaseProps';
import { ValueRowsDataModel, ValueRowsItemDataModel } from './valueRowsTransformer';
// import { ContentRow, ContentRowProps } from '../ContentRow';
import styled from 'styled-components';

import VuurwapenSVGIcon, { VuurwapenSVGIconProps } from '../../layout/icons/VuurwapenSVGIcon';
import SpecialistischSVGIcon, { SpecialistischSVGIconProps }  from '../../layout/icons/SpecialistischSVGIcon';


export interface ValueRowIcon {
  [key: string]: string;
}

export interface ValueRowsThemeProps {
  icons?: ValueRowIcon;
}

export interface ValueRowsProps extends ComponentBaseProps<ValueRowsThemeProps, ValueRowsDataModel> { }

const StyledWrapper = styled(({ height, ...divProps }) => <div { ...divProps } />)`
&& {
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  height: ${({ height }) => `${height / 16}rem`};
}
` as React.ComponentType<{ height: number; } & React.HTMLProps<HTMLDivElement>>;

const StyledContentRow =  styled.div`
  && {
    flex: 1;
    align-self: center;
    justify-self: center;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.5rem
  }
` as React.ComponentType<React.HTMLProps<HTMLDivElement>>;

  const StyledContentItem = styled(({ width, ...divProps }) => <div { ...divProps } />)`
  && {
    width: ${({ width }) => `${width / 16}rem`};
  }
  ` as React.ComponentType<{ width: number; } & React.HTMLProps<HTMLDivElement>>;

  const StyledVuurwapenSVGIcon = styled(VuurwapenSVGIcon)`
    &&& {
      width: 40px;
      height: 40px;
    }
  `as React.ComponentType<VuurwapenSVGIconProps>;

  const StyledSpecialistischSVGIcon = styled(SpecialistischSVGIcon)`
      &&& {
      width: 40px;
      height: 40px;
    }
` as React.ComponentType<SpecialistischSVGIconProps>;

  // TODO: Use other Police icons or implement them into the 'Police material icon' collection.
  const getAlternativeIcon = (alternativeIcon: string): React.ReactNode | undefined => {
    const icon = alternativeIcon.replace(/\s/g, '').toLocaleLowerCase();
    const defaultFill = '#2b4899';

    // TODO: Deze icons moeten uit de fonts komen, heb je ook geen geneuzel met de width en height
  switch (icon) {
      case 'vuurwapen':
        return <StyledVuurwapenSVGIcon fill={defaultFill} />;
      case 'specialistisch': 
        return <StyledSpecialistischSVGIcon fill={defaultFill} />;
      default:
        return undefined;
    }
  };


const getRowIcon = (themeIconsObj: ValueRowIcon, label: string) => {
  const icon = themeIconsObj[label];
  const alternativeIcon = getAlternativeIcon(icon);

  return !!alternativeIcon
    ? { iconName: icon, alternative: alternativeIcon }
    : icon
      ? { iconName: icon }
      : undefined;
};

const ValueRows = (props: ValueRowsProps) => {
  const {
    id,
    width = 0,
    height = 0,
    data,
    // showHelp, << @ Tile level?
    // helpId,
    hideLabel = false,
    themeProperties
  } = props;

  const { model } = data;
  const { icons = {} } = themeProperties || {} as ValueRowsThemeProps;

const elementsRef = useRef(model.map(() => createRef<HTMLDivElement>()));
const [maxWidth, setMaxWidth ] = useState<number>((width as number) / 2);

useEffect(() => {
  const elementWidths = elementsRef.current.map((ref) => {
    const { width } = (ref.current as HTMLDivElement).getBoundingClientRect();
      return width;
  });

  const maxElementWidth = Math.ceil(Math.max(...elementWidths));

  setMaxWidth(maxElementWidth);
}, [model, width]);

  return (
    <StyledWrapper id={`${id}-values-row`} height={height}>
      {
        model.map((item: ValueRowsItemDataModel, index: number) => {
          const { key, label, formattedValue } = item;
          return (
            <StyledContentRow key={key}>
              <StyledContentItem width={maxWidth}>
                <span ref={elementsRef.current[index]} style={{display: 'inline-block'}}>
                  <ValueBlockSimple
                    valueVariant={'h4'}
                    value={formattedValue}
                    label={hideLabel ? '' : label}
                    iconOptions={getRowIcon(icons, label)}
                  />
              </span>
              </StyledContentItem>
            </StyledContentRow>
          )
        })
      }
    </StyledWrapper>
  )
};

export default ValueRows;
