import Box from '@material-ui/core/Box';
import MuiButton from '@material-ui/core/Button';
import MuiDialogActions, {
  DialogActionsProps as MuiDialogActionsProps,
} from '@material-ui/core/DialogActions';
import MuiDialogContent, {
  DialogContentProps as MuiDialogContentProps,
} from '@material-ui/core/DialogContent';
import MuiFormControl from '@material-ui/core/FormControl';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';
import MuiRadio from '@material-ui/core/Radio';
import MuiRadioGroup from '@material-ui/core/RadioGroup';
import MuiTextField from '@material-ui/core/TextField';
import { Modal, ModalHeader } from 'ppd-library/components/organisms/Modal';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import MuiFormLabel from '@material-ui/core/FormLabel';

export interface CreateNewCustomDashboardModalOptions {
  organisationUnitPerTile: boolean;
}
export interface CreateNewCustomDashboardModalProps {
  isOpen: boolean;
  onClose: () => void;
  disableEnterSubmit?: boolean;
  onOk: (name: string, options: CreateNewCustomDashboardModalOptions) => void;
}

const StyledMuiDialogContent = styled(MuiDialogContent)`
&& {
  min-width: 500px;
  padding: 1rem 1rem 5rem;
}
` as React.ComponentType<MuiDialogContentProps>;

const StyledMuiDialogActions = styled(MuiDialogActions)`
&& {
  padding-right: 1rem;
}
` as React.ComponentType<MuiDialogActionsProps>;

const StyledTextFieldHolder = styled.div` 
  && {
    width: 75%;
  }
`;

const defaultOptions: CreateNewCustomDashboardModalOptions = {
  organisationUnitPerTile: false
};

const CreateNewCustomDashboardModal = (props: CreateNewCustomDashboardModalProps) => {
  const {
    onOk,
    onClose,
    isOpen = false,
    disableEnterSubmit = false
  } = props;

  const [options, setOptions] = useState<CreateNewCustomDashboardModalOptions>(defaultOptions);

  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [errorText, setNewDashboardNameErrorText] = useState('');
  const newDashboardInputValue = useRef<string>('');
  const isValidInput = useRef<boolean>(true);

  // Dashboard naam mag alleen uit cijfers, letters, spaties en '-' bestaan.
  const checkPermittedCharacters = (inputValue: string) => {
    if (inputValue.length > 255) {
      setNewDashboardNameErrorText(`Maximaal 255 tekens (${inputValue.length})`);
      isValidInput.current = false;
    }
    else {
      setNewDashboardNameErrorText('');
      isValidInput.current = true;
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.trim();

    newDashboardInputValue.current = inputValue;
    checkPermittedCharacters(inputValue);
  };

  const handleCreateNewDashboard = () => {
    const inputValue = newDashboardInputValue.current;

    // Niets ingevoerd
    if (!inputValue) {
      setNewDashboardNameErrorText('Geef een naam op voor het dashboard');
    }
    // Als de naam valide is, maak een nieuw dashboard aan.
    else if (isValidInput.current) {
      setButtonDisabled(true);
      onOk(inputValue, options);
    }
  };

  const handleTextFieldKeyDown = (e: React.KeyboardEvent) => {
    const enterKeyCode = 13;
    if((e.which === enterKeyCode) && !disableEnterSubmit) {
      handleCreateNewDashboard();
    }
  };

  const handleDashboardClose = () => {
    onClose();
  };

  const resetModal = () => {
    newDashboardInputValue.current = '';
    setNewDashboardNameErrorText('');
    setOptions(defaultOptions);
    setButtonDisabled(false);
  }

  const handlePerTileRadioChange = (_event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    const perTile = value === 'true';
    setOptions({
      ...options,
      organisationUnitPerTile: perTile
    })
  };

  useEffect(() => {
    if(!isOpen) {
      resetModal();
    }
  }, [isOpen]);

  return (
    <Modal
      open={isOpen}
      header={
        <ModalHeader title={'Dashboard aanmaken'} onCancel={handleDashboardClose} />
      }
      content={
        <StyledMuiDialogContent>
          <StyledTextFieldHolder>
            <MuiTextField
              required={true}
              autoFocus={true}
              fullWidth={true}
              helperText={errorText}
              label={'Dashboard naam'}
              error={errorText !== ''}
              onKeyDown={(e:React.KeyboardEvent) => handleTextFieldKeyDown(e) }
              onChange={(ev: React.ChangeEvent<HTMLInputElement>) => handleInputChange(ev)}
            />
          </StyledTextFieldHolder>

          <Box mt={'2rem'} ></Box>

          <MuiFormControl component='fieldset'>
            <MuiFormLabel component={'legend'}>Selecteer in dit dashboard de afdeling</MuiFormLabel>
            <MuiRadioGroup
              name={'organisationUnitPerTile'}
              onChange={handlePerTileRadioChange}
              aria-label={'organisationUnitPerTile'}
              value={`${options.organisationUnitPerTile}`}
            >
              <MuiFormControlLabel
                value={'false'}
                control={<MuiRadio color={'primary'} />}
                label={'Per dashboard'}
              />
              <MuiFormControlLabel
                value={'true'}
                control={<MuiRadio color={'primary'} />}
                label={'Per tegel'}
              />
            </MuiRadioGroup>
          </MuiFormControl>
        </StyledMuiDialogContent>
      }
      footer={
        <StyledMuiDialogActions>
          <MuiButton onClick={handleDashboardClose}>
            Annuleren
          </MuiButton>
          <MuiButton 
           color={'primary'}
           variant={'contained'}
           disabled={buttonDisabled}
           onClick={handleCreateNewDashboard}
            >
            Aanmaken
          </MuiButton>
        </StyledMuiDialogActions>
      }
    />
  );
};

export default CreateNewCustomDashboardModal;