import React from 'react';
import styled from 'styled-components';

import { HelpTooltipButton } from '../HelpTooltipButton';

export interface GeneralHelpTooltipButtonProps {
  /**
   * Pass the showHelp property that a component receives.
   */
  showHelp?: boolean;
  /**
   * Pass the help property that a component receives and this component will try and find 'position: 0'.
   */
  help?: { position: number, helpId: number; }[];
}

const StyledGeneralHelpButtonWrapper = styled.div`
  z-index: 2;
  position:absolute;
  right: 0;
  top: 0;
`;

/**
 * Convenience component that positions the 'general help' button on the top right of a component.
 * @param props 
 */
const GeneralHelpTooltipButton = (props: GeneralHelpTooltipButtonProps) => {

  const { showHelp, help } = props;
  const helpGeneral = help ? help.find(({ position }) => position === 0) : null;

  return (
    <>
      {!!showHelp && !!helpGeneral &&
        <StyledGeneralHelpButtonWrapper>
          <HelpTooltipButton
            anchorType={'iconButton'}
            helpId={helpGeneral.helpId}
          />
        </StyledGeneralHelpButtonWrapper>
      }
    </>
  );
};

export default GeneralHelpTooltipButton;