import { TransformFunction, TransformOptionsBase } from '../transform-utils';
import * as allFormatters from '../../../utils/formatters';

interface RD {
  waarde: number;
  label_4: string;
  eenheid: string;
}

export interface DoubleStackedLabeledBarRawData {
  val00_00: RD;
  val00_01: RD;
  val00_02: RD;
}

export interface DoubleStackedLabeledBarDataModel {
  primaryValue: {
    label: string;
    rawValue: number;
    formattedValue: string;
  }
  secondaryValue: {
    label: string;
    rawValue: number;
    formattedValue: string;
  }
  thresholdValue: {
    label: string;
    rawValue: number;
    formattedValue: string;
  }
}

export interface DoubleStackedLabeledBarTransformOptions extends TransformOptionsBase {
}

const doubleStackedLabeledBarTransformer: TransformFunction<DoubleStackedLabeledBarDataModel, DoubleStackedLabeledBarTransformOptions> = (
  rawData: DoubleStackedLabeledBarRawData,
  onError,
  options
) => {

  const { val00_00, val00_01, val00_02 } = rawData;
  const { formatters = {}, formattersOptions = {} } = options || {} as DoubleStackedLabeledBarTransformOptions;

  let result: DoubleStackedLabeledBarDataModel = {
    primaryValue: { label: '', rawValue: 0, formattedValue: '' },
    secondaryValue: { label: '', rawValue: 0, formattedValue: ''},
    thresholdValue: { label: '', rawValue: 0, formattedValue: ''}
  };
  let guard = true;

  if (val00_00.waarde != null) {
    guard = false;
    const { label_4, waarde, eenheid } = val00_00;
    const primaryValueFormatterName = formatters['primaryValue'];
    const primaryValueFormatterOptions = formattersOptions['primaryValue'];
    const primaryValueFormatter = (allFormatters as any)[primaryValueFormatterName];

    result.primaryValue.label = label_4;
    result.primaryValue.rawValue = waarde;
    result.primaryValue.formattedValue = primaryValueFormatter ? primaryValueFormatter(waarde, primaryValueFormatterOptions) : allFormatters.formatValue(waarde, eenheid as ValueUnit, primaryValueFormatterOptions);
  }

  if (val00_01.waarde != null) {
    guard = false;
    const { waarde, label_4, eenheid } = val00_01;
    const secondaryValueFormatterName = formatters['secondaryValue'];
    const secondaryValueFormatterOptions = formattersOptions['secondaryValue'];
    const secondaryValueFormatter = (allFormatters as any)[secondaryValueFormatterName];

    result.secondaryValue.label = label_4;
    result.secondaryValue.rawValue = waarde;

    result.secondaryValue.formattedValue = secondaryValueFormatter ? secondaryValueFormatter(waarde, secondaryValueFormatterOptions) : allFormatters.formatValue(waarde, eenheid as ValueUnit, secondaryValueFormatterOptions);
  }

  if (val00_02.waarde != null) {
    guard = false;
    const { waarde, label_4, eenheid } = val00_02;
    const thresholdValueFormatterName = formatters['thresholdValue'];
    const thresholdValueFormatterOptions = formattersOptions['thresholdValue'];
    const thresholdValueFormatter = (allFormatters as any)[thresholdValueFormatterName];

    result.thresholdValue.label = label_4;
    result.thresholdValue.rawValue = waarde;
    result.thresholdValue.formattedValue = thresholdValueFormatter ? thresholdValueFormatter(waarde, thresholdValueFormatterOptions) : allFormatters.formatValue(waarde, eenheid as ValueUnit, thresholdValueFormatterOptions);
  }

  if (guard) {
    onError('nodata');
    return null;
  }


  return result;
};

export default doubleStackedLabeledBarTransformer;