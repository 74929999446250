import React, { useEffect, useState, useCallback } from 'react';
import { Bar } from 'ppd-library/charts/atoms/Bar';
import Chart from 'ppd-library/charts/Chart';
import { scales } from 'ppd-library/charts/chartUtils';
import { BarHorizontal } from 'ppd-library/charts/molecules/BarHorizontal';
import { LineThresholdVertical } from 'ppd-library/charts/molecules/LineThreshold';
import styled from 'styled-components';

import { HelpTooltipButton, HelpTooltipButtonProps } from '../../help/HelpTooltipButton';
import { ComponentBaseProps } from '../ComponentBaseProps';
import { createLabel, defaultChartLabelStyle } from '../componentUtils';
import { ContentRow } from '../ContentRow';
import { DoubleSlideBarsDataModel } from './doubleSlideBarsTransformer';
import { BarHorizontalStacked } from 'ppd-library/charts/organisms/BarHorizontalStacked';

interface DoubleSlideBarsThemeProps {
  gutterSize?: number;
  barColors?: string[];
  topBarHeight?: number;
  /**
   * @default barColors[0]
   */
  thresholdColor?: string;
  labelTextColor?: string;
  bottomBarHeight?: number;
}
export interface DoubleSlideBarsProps extends ComponentBaseProps<DoubleSlideBarsThemeProps, DoubleSlideBarsDataModel> {
  /**
   * Show the line at the end of the bars. 
   * Threshold in doubleslidebars is more of a 'target'/'norm' and used to indicate that the bars need to fill up than an actual threshold.
   * @default true
   */
  showThreshold?: boolean;
  /**
   * @default false
   */
  showInBarLabels?: boolean;
  /**
   * @default false
   */
  mediumValueStyle?: boolean;
  /**
   * @default true
   */
  showTrack?: boolean;
  /**
   * The minumum value that should be used to calculate the track length.
   * For example if a bar should to fill to 100%, minMaxValue = 100.
   */
  minMaxValue?: number;
  /**
   * use the bottom bar as threshold value for the top bar
   * @default false
   */
  bottomBarIsThreshold?: boolean;
}

const StyledWrapper = styled.div`
  flex: 1;
  position: relative;
`;

const StyledChartHolder = styled.div``;

const StyledSuffixTSpan = styled.tspan`
 font-size: 1.5rem;
`;

interface ValueLabelProps {
  label: string;
  formattedValue: string;
  hideLabel?: boolean | 'description';
  fontSize: string;
  fontFamily?: string;
  color: string;
  y?: number;
}

interface TextAnimatedProps {
  children?: string;
  x?: number;
  y?: number;
  id?: string;
  fill?: string;
  width?: number;
  height?: number;
  // cssClass?: string;
  transform?: any;
  fontSize?: string
};

const MainValueLabel = (props: ValueLabelProps) => {
  const { label, hideLabel = false, formattedValue, fontSize, y, color } = props;
  let description = hideLabel === 'description' ? '' : `${label}`;

  let split = [formattedValue];
  if (formattedValue.indexOf(' ') !== -1) {
    split = formattedValue.split(' ');
  }

  return (
    <text y={0}>
      {hideLabel !== true &&
        <>
          <tspan
            x={0}
            style={{ ...defaultChartLabelStyle, fill: color }}
            y={`-${y}`}
          >
            {description}
          </tspan>
          <tspan
            x={0}
            y={0}
            style={{
              fill: color,
              fontSize: fontSize,
             
            }}>
            {split.map((splitValue, index) =>
              <React.Fragment key={index}>
                {index === 0 && splitValue}
                {index !== 0 && <StyledSuffixTSpan> {splitValue}</StyledSuffixTSpan>}
              </React.Fragment>)
            }
          </tspan>
        </>
      }
    </text>
  );
}

const SecondaryValueLabel = (props: ValueLabelProps) => {
  const { label, hideLabel = false, formattedValue, fontSize, y = 0, color, fontFamily } = props;
  let description = hideLabel === 'description' ? '' : `${label}: `;
  return (
    <text style={defaultChartLabelStyle} y={y}>
      {hideLabel !== true &&
        <>
          <tspan
            style={{ ...defaultChartLabelStyle, fill: color }}
          >
            {description}
          </tspan>
          <tspan
            style={{
              fill: color,
              fontSize: fontSize,
              fontFamily: fontFamily
            }}>
            {formattedValue}
          </tspan>
        </>
      }
    </text>
  );
}

const StyledText = styled.text`
  && {
    transition: transform 1000ms;
  }
`;
const TextAnimated = React.forwardRef((props: TextAnimatedProps, ref: any) => {
  return <StyledText {...props} ref={ref} />;
});

const StyledHelpTooltipButton = styled(({ top, left, ...helpTooltipButtonProps }) => <HelpTooltipButton {...helpTooltipButtonProps} />)`
  && { 
    position: absolute;
    left: ${({ left }) => left}px;
    margin-top: ${({ top }) => top}px;
  }
`as React.ComponentType<{ top: number; left: number; } & HelpTooltipButtonProps>;

const getTextGradient = (textIndent: number, textWidth: number, barWidth: number) => {
  const singleXScale = scales.numericXScale(0, textWidth, 100, 0, 0);

  return `${singleXScale(barWidth - textIndent)}%`;
};

const getStackedData = (keys: string[], barRawValue: number, thresholdValue: number) => {
  const aboveThreshold = barRawValue > thresholdValue;
  const remainingValue = aboveThreshold ? barRawValue - thresholdValue : 0;
  const result = {
    [keys[0]]: aboveThreshold ? (barRawValue - remainingValue) : barRawValue,
    [keys[1]]: aboveThreshold ? remainingValue : 0
  }

  return result;
};


const topBarKey = 'topBar';
const negativeKey = 'negative';
// const bottomBarKey = 'bottomBar';

const DoubleSlideBars = (props: DoubleSlideBarsProps) => {
  const {
    id,
    data,
    help,
    width = 400,
    height = 200,
    hideLabel = {},
    minMaxValue = 0,
    showTrack = true,
    showHelp = false,
    showThreshold = true,
    themeProperties,
    showInBarLabels = false,
    mediumValueStyle = false,
    bottomBarIsThreshold = false,
    //  margins = { left: 0, right: 0, top: 0, bottom: 60 },
  } = props;

  const {
    gutterSize = 2,
    topBarHeight = 24,
    bottomBarHeight = showInBarLabels ? 24 : 16,
    labelTextColor = '#636363',
    barColors = ['#2b4899', 'orange']
  } = themeProperties || {} as DoubleSlideBarsThemeProps;

  const { thresholdColor = barColors[0] } = themeProperties || {} as DoubleSlideBarsThemeProps;;
  const colorScaleTopBar = scales.colorScale({ topBar: barColors[0], negative: thresholdColor });

  const inBarFontSize = '0.8rem';
  const textIndent = 10;
  const { mainValue, secondaryValue, thresholdValue } = data;

  const maxValue = Math.max(minMaxValue, mainValue.rawValue, secondaryValue.rawValue, showThreshold ? thresholdValue.rawValue : 0);
  const xScale = scales.numericXScale(0, maxValue, width, 0, 0);

  const refObj = { width: 0, height: 0 } as ClientRect;
  const [label1Ref, setLabel1Ref] = useState(refObj);
  const [label2Ref, setLabel2Ref] = useState(refObj);

  const labelRef1 = useCallback(node => {
    if (node !== null) {
      setLabel1Ref(node.getBoundingClientRect());
    }
  }, []);

  const labelRef2 = useCallback(node => {
    if (node !== null) {
      setLabel2Ref(node.getBoundingClientRect());
    }
  }, []);

  const [initiated, setInitiated] = useState(false);

  useEffect(() => {
    setInitiated(true);
  }, []);

  return (
    <StyledWrapper>
      <ContentRow>
        <StyledChartHolder>
          {!!showHelp && !!help && help.some(({ position }) => position === 1) &&
            <StyledHelpTooltipButton
              left={0}
              top={-14}
              anchorType={'iconButton'}
              placement={'bottom-start'}
              helpId={(help.find(({ position }) => position === 1) as { helpId: number }).helpId}
            />
          }
          {!!showHelp && !!help && help.some(({ position }) => position === 2) &&
            <StyledHelpTooltipButton
              left={0}
              top={145}
              anchorType={'iconButton'}
              placement={'bottom-start'}
              helpId={(help.find(({ position }) => position === 2) as { helpId: number }).helpId}
            />
          }
          <Chart
            width={width}
            height={height}
            preserveAspectRatio={'none'}
          >
            {showTrack &&
              <g transform={`translate(0, 80)`}>
                <Bar
                  x={0}
                  y={0}
                  width={width}
                  fill={'#EEEEEE'}
                  height={topBarHeight}
                  id={`${id}-main-track`}
                />
                <Bar
                  x={0}
                  width={width}
                  fill={'#EEEEEE'}
                  height={bottomBarHeight}
                  id={`${id}-secondary-track`}
                  y={topBarHeight + gutterSize}
                />
              </g>
            }
            {showThreshold &&
              <LineThresholdVertical
                y={40}
                x={width - 1}
                lineHeight={120}
                showCircle={false}
                labelPos={['left', 'top']}
                id={`${id}-threshold-line`}
                labelPosCorrection={[0, -8]}
                label={createLabel('thresholdValue', thresholdValue, hideLabel)}
                theme={{
                  lineColor: '#636363',
                  textSize: '0.8rem',
                  textColor: labelTextColor,
                  backgroundColor: 'transparent'
                }}
              />
            }
            <g transform={`translate(0, 80)`}>
              <g>
                <BarHorizontalStacked
                  keys={[topBarKey, negativeKey]}
                  xScale={xScale}
                  height={topBarHeight}
                  colorScale={colorScaleTopBar}
                  y={0}
                  id={`${id}-bar-horizontal-stacked-top`}
                  data={{
                    key: 'main-bar',
                    ...getStackedData([topBarKey, negativeKey],
                      initiated ? mainValue.rawValue : 0,
                      initiated ? secondaryValue.rawValue : 0
                    )
                  }}
                />
                {showInBarLabels &&
                  <TextAnimated
                    ref={labelRef1}
                    fontSize={inBarFontSize}
                    fill={`url(#${id}-gradientText1)`}
                    transform={`translate(${textIndent}, 0)`}
                    y={topBarHeight - (label1Ref.height / 2)}
                  >
                    {mainValue.label}
                  </TextAnimated>
                }
              </g>
              <g>
                <BarHorizontal
                  x={0}
                  fill={barColors[1]}
                  height={bottomBarHeight}
                  id={`${id}-secondary-bar`}
                  y={topBarHeight + gutterSize}
                  width={initiated ? xScale(secondaryValue.rawValue) : 0}
                />
                {showInBarLabels &&
                  <TextAnimated
                    fontSize={inBarFontSize}
                    transform={`translate(${textIndent}, ${bottomBarHeight})`}
                    fill={`url(#${id}-gradientText2)`}
                    ref={labelRef2}
                    y={bottomBarHeight + gutterSize - (label2Ref.height / 2)}
                  >
                    {secondaryValue.label}
                  </TextAnimated>
                }
              </g>
            </g>

            <g transform={`translate(0, 70)`}>
              <MainValueLabel
                label={mainValue.label}
                hideLabel={hideLabel['mainValue']}
                formattedValue={mainValue.formattedValue}
                fontSize={mediumValueStyle ? '1.5rem' : '2.5rem'}
                y={mediumValueStyle ? 24 : 40}
                color={bottomBarIsThreshold && mainValue.rawValue > secondaryValue.rawValue ? thresholdColor : '#2b4899'}
              />
              <SecondaryValueLabel
                label={secondaryValue.label}
                hideLabel={hideLabel['secondaryValue']}
                formattedValue={secondaryValue.formattedValue}
                fontSize={mediumValueStyle ? '1.5rem' : '0.8rem'}
                
                color={mediumValueStyle ? '#2b4899' : '#636363'} // TODO: palette
                y={bottomBarHeight + (mediumValueStyle ? 63 : 53)}
              />
            </g>

            {!!showInBarLabels &&
              <defs>
                <linearGradient id={`${id}-gradientText1`}>
                  <stop offset={getTextGradient(textIndent, label1Ref.width, xScale(mainValue.rawValue))} stopColor="white" />
                  <stop stopColor="black" />
                </linearGradient>
                <linearGradient id={`${id}-gradientText2`}>
                  <stop offset={getTextGradient(textIndent, label2Ref.width, xScale(secondaryValue.rawValue))} stopColor="white" />
                  <stop stopColor="black" />
                </linearGradient>
              </defs>
            }
          </Chart>
 
        </StyledChartHolder>
      </ContentRow>
    </StyledWrapper>
  )
};

export default DoubleSlideBars;
