import { TilePlaceholder } from 'ppd-library/components/organisms/Tile';
import React, { Component } from 'react';
import styled from 'styled-components';

import muiTheme from '../../../constants/theme';

interface ContentErrorBoundaryProps {
  contentId: string | number;
  /**
   * when this key changes, the boundary will set reset and try to reload the content (and catch error if it occurs).
   */
  resetKey: string | number;
  placeholderText?: string;
}

interface ContentErrorBoundaryState {
  resetKey: string | number;
  hasError: boolean;
}

const StyledPlaceholder = styled(TilePlaceholder)`
  flex: 1;
  align-self: center;
  justify-self: center;
`;

/**
 * Errors caught by the error boundary mostly likely originate in the content component.
 */
class ContentErrorBoundary extends Component<ContentErrorBoundaryProps, ContentErrorBoundaryState> {
  constructor(props: ContentErrorBoundaryProps) {
    super(props);

    const { resetKey, placeholderText = null } = props;
    this.state = { resetKey, hasError: !!placeholderText };
  }

  static getDerivedStateFromError(_error: any) {
    return { hasError: true };
  }

  static getDerivedStateFromProps(nextProps: ContentErrorBoundaryProps, prevState: ContentErrorBoundaryState) {
    let newState = {...prevState};

    if(nextProps.resetKey !== prevState.resetKey) {
      newState.resetKey = nextProps.resetKey;
      newState.hasError = false;
    }

    return newState;
  }

  componentDidCatch(_error: any, _errorInfo: any) {

    // You can also log the error to an error reporting service
    //logErrorToMyService(error, errorInfo);
  }

  render() {
    const { contentId, placeholderText = '' } = this.props;
    const message = !!placeholderText ? placeholderText :
      `Fout bij het laden van de tegel content id ${contentId}`;

    if (this.state.hasError) {

      return (
        <StyledPlaceholder
          message={message}
          theme={
            {
              palette: {
                secondary: {
                  main: muiTheme.palette.secondary.light
                }
              }
            }
          }
        />
      )
    };

    return this.props.children;
  }
}

export default ContentErrorBoundary;