import { Icon as MuiIcon } from '@material-ui/core';
import { IconProps as MuiIconProps } from '@material-ui/core/Icon';
import MuiIconButton from '@material-ui/core/IconButton';
import MuiTableCell, { TableCellProps as MuiTableCellProps } from '@material-ui/core/TableCell';
import MuiTableRow, { TableRowProps as MuiTableRowProps } from '@material-ui/core/TableRow';
import MuiKeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import MuiKeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import React, { RefObject, useState } from 'react';
import styled from 'styled-components';

import UnitCompareRowControl, { UnitCompareRowControlProps } from './UnitCompareRowControl';
import { defaultViewTypeIcons } from '../../../components/tiles/utils';

export interface UnitCompareDataRow {
  /**
   * this is the first column of the row, usually containing the descriptive label of the values.
   */
  labelColumn: { label: string; };
  /**
   * key is the oe code.
   */
  [key: string]: { label?: string; rawValue?: number; formattedValue?: string; valueUnit?: string;}
}
export interface UnitCompareRowProps extends UnitCompareRowControlProps {
  id: number;
  isOddRow: boolean;
  themeName: string;
  tileLabel: string;
  columns: API_GET.UnitCompareColumn[];
  dataRows: UnitCompareDataRow[];
  refTable: RefObject<HTMLElement>;
  refContainer: RefObject<HTMLElement>;
  tabLabel?: string;
  viewType?: string;
  themeIconName?: string;
  stickyTitleRows?: boolean;
  isIos:boolean;
}

const StyledMuiTableRow = styled(({ isOddRow, ...tableRowProps }) => <MuiTableRow {...tableRowProps} />)`
  && {
    background: ${({ isOddRow }) => isOddRow ? '#f0f8ff' : '#fff'};
    .unitCompareRowControl {
      visibility: hidden;
    }
    &:hover {
      cursor: pointer;
      user-select: none;
      .unitCompareRowControl {
        visibility: visible;
      }
    }
    .MuiTableCell-root {
      &:after {
        display: none;
      }
    }
  }
`as React.ComponentType<{ isOddRow: boolean; } & MuiTableRowProps>;


const StyledMuiTableTitleRowCell = styled(({ isOddRow, isStickyRow, initial, isIos, ...tablCellProps }) => <MuiTableCell {...tablCellProps} />)`
  && {
    background: ${({ isOddRow }) => isOddRow ? '#f0f8ff' : '#fff'};
    top: ${({ isStickyRow, initial, isIos }) => isStickyRow ? (initial || isIos) ? 0 : '57px' : null};
    z-index: ${({ isStickyRow }) => isStickyRow ? '1000' : 0};
    border-right: 1px solid #ccc;
    padding: 0 1rem;
    left: 0;
    position: sticky;
    border-bottom: 0;

    // De border van onderliggende TD is soms zichtbaar na het scrollen . Dit maskeert het.
    &:after {
      content : '';
      height: 3px;
      width: 100%;
      left: 0;
      bottom: -1px;
      background: ${({ isOddRow }) => isOddRow ? '#f0f8ff' : '#fff'};
      position: absolute;
    }
  }
`as React.ComponentType<{ isOddRow?: boolean, isStickyRow?: boolean, initial?: boolean, isIos?: boolean; } & MuiTableCellProps>;

const StyledMuiTableCell = styled(({ isFirstCell, isTopRowCell, xScrollPossible, highLite, isOddRow, ...tableRowProps }) => <MuiTableCell {...tableRowProps} />)`
  && {
    padding: 0.625rem 1rem;
    border-bottom:  1px solid #ccc;
    border-right: 1px solid #ccc;
    border-top: ${({ isTopRowCell }) => isTopRowCell ? '1px solid #ccc' : null};
    //border-left: ${({ isFirstCell }) => isFirstCell ? '1px solid #ccc' : null};
    cursor: ${({ xScrollPossible }) => xScrollPossible ? 'move' : null};
    background-color: ${({ highLite }) => highLite ? '#f0f8ff' : null};
    position: ${({ highLite }) => highLite ? 'sticky' : null};
    left: ${({ highLite }) => highLite ? '301px' : null};
    z-index: ${({ highLite }) => highLite ? '2' : null};
    &:last-child { 
      border : 0;
      background: ${({ isOddRow }) => isOddRow ? '#f0f8ff' : '#fff'};
    }
  }
`as React.ComponentType<{ isFirstCell?: boolean, isTopRowCell?: boolean, xScrollPossible?: boolean, highLite?: boolean; isOddRow?: boolean; } & MuiTableCellProps>;

// const StyledThemeIcon = styled(MuiIcon)`
//   margin: 0 0.3rem -0.2rem;
//   display: inline-block;
//   &&{
//     font-size: 1rem;
//   }
// ` as React.ComponentType<MuiIconProps>;

const StyledViewIconWrapper = styled.span`
  margin-left: 0.25rem;
  && {
    .MuiSvgIcon-root {
      width: 1rem;
      height: 1rem;
    }
  }
` as React.ComponentType<MuiIconProps>;

const StyledViewIcon = styled(MuiIcon)`
  margin-bottom: -0.2rem;
  display: inline-block;
  &&{
    font-size: 1rem;
  }
` as React.ComponentType<MuiIconProps>;


const TileRowWrapper = styled.div`
  && {
    display: inline-flex;
    /* transform: translateY(2px); */
  }
`;

const TileTitle = styled.span`
  && {
    display: inline-block;
  }
`;

const CellOverflowBlock = styled.div`
  && {
    display: inline-block;
    position: sticky;
    left: 16px;
  }
`;

const TitleRowContent = styled.div`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const getRefWidth = (ref: RefObject<HTMLElement>) => (ref as RefObject<any>).current.getBoundingClientRect().width;

const UnitCompareRow = (props: UnitCompareRowProps) => {

  const [open, setOpen] = useState(true);
  const {
    id,
    isIos,
    columns,
    dataRows,
    refTable,
    isOddRow,
    viewType,
    tabLabel,
    tileLabel,
    refContainer,
    themeName,
    // themeIconName,
    stickyTitleRows = true,
    ...rowControlProps
  } = props;

  const containerWidth = getRefWidth(refContainer);
  const tableWidth = getRefWidth(refTable);
  const defaultViewTypeIcon = defaultViewTypeIcons.find(({ type }) => type === viewType);

  return (
    <>
      <StyledMuiTableRow isOddRow={false} onClick={() => setOpen(!open)}>
        <StyledMuiTableTitleRowCell isIos={isIos} initial={columns.length < 1} isOddRow={isOddRow} isStickyRow={stickyTitleRows} colSpan={100} >
          <TitleRowContent>
            <CellOverflowBlock>
              <MuiIconButton
                size={'small'}
                color={'primary'}
                aria-label={'expand row'}
                onClick={() => setOpen(!open)}
              >
                {open ? <MuiKeyboardArrowUpIcon /> : <MuiKeyboardArrowDownIcon />}
              </MuiIconButton>
              <TileRowWrapper>
                {/* {!!themeIconName &&
                  <StyledThemeIcon title={themeIconName} fontSize={'small'}>
                    {themeIconName}
                  </StyledThemeIcon>
                } */}
                <TileTitle>{themeName} - {tileLabel} {tabLabel ? `- ${tabLabel}` : ''}</TileTitle>
              <StyledViewIconWrapper>
                  {!!defaultViewTypeIcon && !!defaultViewTypeIcon.iconSvg &&
                    <>
                      {defaultViewTypeIcon.iconSvg}
                    </>
                  }
                  {!!defaultViewTypeIcon && !defaultViewTypeIcon.iconSvg &&
                    <StyledViewIcon title={defaultViewTypeIcon.tooltip} fontSize={'small'}>
                      {defaultViewTypeIcon.iconLigature}
                    </StyledViewIcon>
                  }
              </StyledViewIconWrapper>
              </TileRowWrapper>
            </CellOverflowBlock>
            <UnitCompareRowControl
              {...rowControlProps}
            />
          </TitleRowContent>
        </StyledMuiTableTitleRowCell>
      </StyledMuiTableRow>
      {open &&
        <>
          {
            dataRows.map(({ labelColumn, ...unitColumns }, rowIndex) => {
              return (
                <MuiTableRow key={`${id}-${labelColumn.label}`}>
                  <StyledMuiTableTitleRowCell isOddRow={isOddRow}>
                    {labelColumn.label}
                  </StyledMuiTableTitleRowCell>
                  {columns.map((column, cellIndex) => {
                    const { organisationUnit } = column;
                    const { code: oeCode } = organisationUnit; 
                    const cellData = unitColumns[oeCode];

                    return (
                      <StyledMuiTableCell
                        key={`${id}-${labelColumn.label}-${oeCode}`}
                        xScrollPossible={containerWidth < tableWidth}
                        isTopRowCell={rowIndex === 0}
                        isFirstCell={cellIndex === 0}
                        highLite={column.isPinned}
                        align={'right'}>
                        {!!cellData && cellData.formattedValue}
                        {/* {!cellData && '-'} */}
                      </StyledMuiTableCell>
                    );
                  })}
                  {/* the last cell makes sure that the columns have fixed widths */}
                  <StyledMuiTableCell isOddRow={isOddRow}></StyledMuiTableCell>
                </MuiTableRow>
              )
            }
            )}
          {dataRows.map(({ labelColumn }, index) => {
            return (
              dataRows.length === index + 1 &&
              <StyledMuiTableRow isOddRow={isOddRow} key={`${id}-${labelColumn.label}-lastRow`}>
                <MuiTableCell colSpan={100} style={{ height: '20px' }}>
                </MuiTableCell>
              </StyledMuiTableRow>
            )
          }
          )}
        </>
      }
    </>
  );
}

export default UnitCompareRow;