import { TilePlaceholder } from 'ppd-library/components/organisms/Tile';
import React from 'react';

export interface TemplateDisabledPlaceholderProps {
  isLoading: boolean;
  placeholderColor: string;
  templateEnabled: boolean;
  placeholderText: string | null;
  templateDetail: API_GET.TemplateDetail | null;
}

const TemplateDisabledPlaceholder = (props: TemplateDisabledPlaceholderProps) => {
  const {
    isLoading,
    templateDetail,
    templateEnabled,
    placeholderText,
    placeholderColor,
  } = props;

  return (
    <>
    {!!templateDetail && !isLoading && (!templateEnabled || !!placeholderText) &&
          <TilePlaceholder
            message={placeholderText || 'Deze dashboard pagina is uitgeschakeld.'}
            theme={{ palette: { secondary: { main: placeholderColor } } }}
          />
        }
    </>
  );
};

export default TemplateDisabledPlaceholder;