import MuiDivider, { DividerProps as MuiDividerProps } from '@material-ui/core/Divider';
import MuiTypography, { TypographyProps as MuiTypographyProps } from '@material-ui/core/Typography';
import { scaleLinear } from 'd3';
// import { Chart } from 'ppd-library/charts';
import { BarHorizontal } from 'ppd-library/charts/molecules/BarHorizontal';
import { Tooltip } from 'ppd-library/charts/organisms/Tooltip';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { BarChartCategoryDataModel, BarChartCategoryDataGroup } from './barChartCategoryTransformer';
import { ComponentBaseProps } from '../ComponentBaseProps';
import { Chart } from 'ppd-library/charts';
import { pxToRem } from '../../../utils/converters'

import { getSVGMousePosition, useTooltipPosition, TooltipTemplate } from '../tooltip-utils';
import { Legend } from '../legend-utils';

export interface BarChartCategoryThemeProps {
  offsetRight?: number;
  barHeight?: number;
  barColors?: string | string[];
}

export interface BarChartCategoryProps extends ComponentBaseProps<BarChartCategoryThemeProps, BarChartCategoryDataModel> {
  showTooltip?: boolean;
  /**
   * Minimum height per row. 
   * A row is defined als its chart and labels minus the divider.
   */
  minRowHeight?: number;
}

const StyledMuiTypography = styled(({ lineHeight, ...muiTypographyProps }) => <MuiTypography {...muiTypographyProps} />)`
  && {
    margin-left: ${pxToRem(8)};
    line-height: ${({ lineHeight }) => pxToRem(lineHeight)};
  }
  ` as React.ComponentType<{ lineHeight: number } & MuiTypographyProps>;

const StyledContentWrapper = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  justify-content: space-between;
` as React.ComponentType<React.HTMLProps<HTMLDivElement>>;

const StyledWrapper = styled.div`
  position: relative;
`;

const StyledValueWrapper = styled(({ height, ...divProps }) => <div {...divProps} />)`
  display: flex;
  align-self: flex-end;
  height: ${({ height }) => pxToRem(height)};
`as React.ComponentType<{ height: number; } & React.HTMLProps<HTMLDivElement>>;

const dividerHeight = 14;
const StyledMuiDivider = styled(MuiDivider)`
  && {
    margin: ${pxToRem(dividerHeight)} 0;
  }
  ` as React.ComponentType<MuiDividerProps>;

const StyledHeader = styled.header`
  display: flex;
  margin-bottom: ${pxToRem(4.8)};
  justify-content: space-between;
`;

const createEmptyGroupData = (group: BarChartCategoryDataGroup) => {
  return {
    ...group,
    bars: group.bars.map((bar) => ({
      ...bar,
      rawValue: 0
    }))
  };
}

const BarChartCategory = (props: BarChartCategoryProps) => {
  const {
    // id,
    data,
    // help,
    // showHelp,
    tooltipOptions,
    width = 400,
    showTooltip = true,
    legendOptions = {},
    themeProperties
  } = props;

  const {
    barHeight = 16,
    offsetRight = 100,
    barColors: originalBarColors
  } = themeProperties || { } as BarChartCategoryThemeProps;
 
  const barColors = !!originalBarColors ?
    Array.isArray(originalBarColors) ? originalBarColors : [originalBarColors] :
    ['gray', '#2b4899'];

  const { groups } = data;

  const handleMouseMove = (event: React.MouseEvent<Element, MouseEvent>, index: number, data: BarChartCategoryDataGroup) => {
    const { x, y } = getSVGMousePosition(event as React.MouseEvent<SVGElement, MouseEvent>);
    setTooltipPosition({ visible: true, x, y, index, data });
  };

  const legendElementRef = React.createRef<HTMLDivElement>();
 
  const handleMouseLeave = () => {
    setTooltipPosition({ ...tooltipPosition, visible: false });
  };

  const { tooltipPosition, setTooltipPosition } = useTooltipPosition<BarChartCategoryDataGroup>();

  // Get heighest value
  const maxValues = data.groups.map((group) => {
    const barValues = group.bars.map(({ rawValue }) => rawValue);

    return Math.max(...barValues);
  });

  const maxValue = Math.max(...maxValues);

  const chartWidth = width - offsetRight;
  const xScale = scaleLinear().domain([0, maxValue]).range([0, chartWidth]);

  const [initiated, setInitiated] = useState(false);

  useEffect(() => {
    const timeOutId = setTimeout(() => setInitiated(true), 0);

    return () => clearTimeout(timeOutId);
  }, []);

  return (
    <>
      <div style={{ height: '100%', overflow: 'auto' }}>
        {
          groups.map((originalGroup, i: number) => {
            const group = initiated ? originalGroup : createEmptyGroupData(originalGroup);
            const {
              bars,
              groupLabel,
              formattedPrimaryValue,
              formattedSecondaryValue
            } = group;
            const key = `${groupLabel}`;

            return (
              <StyledWrapper  key={key}>
                <StyledHeader>
                  <MuiTypography
                    noWrap
                    variant={'caption'}
                    color={'secondary'}
                  >
                    {groupLabel}
                  </MuiTypography>
                </StyledHeader>
                <StyledContentWrapper>
                  <Chart
                    width={chartWidth}
                    height={barHeight * 2 + 2} // TODO: dit gaat uit van altijd 2 bars..
                  >
                    <g
                      onMouseMove={(ev) => handleMouseMove(ev, i, originalGroup)}
                      onMouseLeave={handleMouseLeave}
                    >
                      {
                        bars.map(({ label, rawValue }, j) =>
                          <BarHorizontal
                            x={0}
                            height={barHeight}
                            fill={barColors[j]}
                            width={xScale(rawValue)}
                            y={barHeight * j + (2 * j)}
                            id={`${groupLabel}-${label}`}
                            key={`${groupLabel}-${label}`}
                          />
                        )
                      }
                    </g>
                  </Chart>
                  <StyledValueWrapper height={barHeight}>
                    {!!formattedPrimaryValue &&
                      <StyledMuiTypography
                        color={'primary'}
                        component={'span'}
                        variant={'caption'}
                        lineHeight={barHeight}
                      >
                        {formattedPrimaryValue}
                      </StyledMuiTypography>
                    }
                    {!!formattedSecondaryValue &&
                      <StyledMuiTypography
                        component={'span'}
                        variant={'caption'}
                        lineHeight={barHeight}
                        color={'textSecondary'}
                      >
                        {formattedSecondaryValue}
                      </StyledMuiTypography>
                    }
                  </StyledValueWrapper>
                </StyledContentWrapper>
                {
                  i < groups.length && <StyledMuiDivider />
                }
                <Tooltip
                  top={tooltipPosition.y}
                  left={tooltipPosition.x}
                  calculateOffsetPosition={'horizontal'}
                  visible={showTooltip && tooltipPosition.visible && tooltipPosition.index === i}
                  theme={{
                    transition: false
                  }}
                >
                  {() => {
                    const {
                      groupLabel: label,
                      bars
                    } = tooltipPosition.data as BarChartCategoryDataGroup;

                    return (
                      <TooltipTemplate
                        title={label}
                        tooltipOptions={tooltipOptions}
                        values={
                          bars.map((item, i) => {
                            return {
                              label: item.label,
                              formattedValue: item.formattedValue,
                              colors: [barColors[i]]
                            }
                          })
                        }
                      />
                    );
                  }}
                </Tooltip>
              </StyledWrapper>
            )
          })
        }
        <Legend
          ref={legendElementRef}
          legendOptions={{
            orientation: 'horizontal',
            ...legendOptions
          }}
          values={groups[0].bars.map(({ label }, i) => {
            return {
              label,
              colors: [barColors[i]]
            };

          })}
        />
      </div>
    </>
  )
};

export default BarChartCategory;
