/**
 * Create a label based on if the key is present in hideLabelKeys or hideLabelDescriptionKeys.
 * @param dataKey The key dat goes with the data (example: mainValue)
 * @param data The data object that has at least a label and formattedValue.
 * @param hideLabelConfig Object of keys that should be hidden completely.
 */
export function createLabel(
  dataKey: string,
  data: { label: string; formattedValue: string; },
  hideLabelConfig: { [key: string]: boolean | string } = { }
) {
  if (hideLabelConfig[dataKey]) {
    const config = hideLabelConfig[dataKey];

    if(config === true) {
      return '';
    } else {
      return data.formattedValue;
    }
  }

  return `${data.label}: ${data.formattedValue}`;
}

// TODO: create a label component that can be used for every chart
export const defaultChartLabelStyle = {
  fontSize: '0.8rem', fill: '#636363'
};
