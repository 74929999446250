import { TransformFunction, TransformOptionsBase } from '../transform-utils';
import * as allFormatters from '../../../utils/formatters';

interface RD {
  waarde: number;
  label_1: string;
  label_2?: string;
  label_3?: string;
  label_4: string;
  eenheid: string;
}

export interface ColumnChartDeviationRawData {
  [key: string]: RD;
}

export interface ColumnChartDeviationGroupXThreshold {
  label: string;
  groupKey: string;
  groupIndex: number;
}

export interface ColumnChartDeviationTransformOptions extends TransformOptionsBase {
  reverse?: boolean;
  /**
   * Set the key to use for the x axis.
   * @default label_1
   */
  xLabelKey?: 'label_1' | 'label_2' | 'label_3';
  /**
   * Append the threshold value to the xLabel (if there is a threshold).
   * For example xLabel is 'Augustus' and threshold is '2020', xLabel will be Augustus 2020
   * @default true
   */
  appendThresholdToXLabel?: boolean;
}


export interface ColumnChartDeviationGroupModel {
  key: string;
  label: string;
  xLabel: string;
  /**
   * Keys that correspond to the keys in rawValues and formattedValues.
   */
  valueKeys: string[];
  shortXLabel?: string;
  rawValues: { [key: string]: number };
  formattedValues: { [key: string]: string };
}

export interface ColumnChartDeviationDataModel {
  groups: ColumnChartDeviationGroupModel[];
  xThreshold?: ColumnChartDeviationGroupXThreshold;
  isGroupModel?: boolean;
}

const columnChartDeviationListTransformer: TransformFunction<ColumnChartDeviationDataModel, ColumnChartDeviationTransformOptions> = (
  rawData: ColumnChartDeviationRawData,
  onError,
  options
) => {

  const keys = Object.keys(rawData);
  const guard = !keys.some((key) => rawData[key].waarde !== null);

  if (guard) {
    onError('nodata');
    return null;
  }

  const {
    reverse = false,
    formatters = {},
    xLabelKey = 'label_2',
    formattersOptions = {},
    appendThresholdToXLabel = true
  } = options || {} as ColumnChartDeviationTransformOptions;

  const valueFormatterName = formatters['value'];
  const valueFormatter = (allFormatters as any)[valueFormatterName];
  const valueFormatterOptions = formattersOptions['value'];

  const xLabelFormatterName: string = formatters['xLabel'];
  const xLabelFormatter = (allFormatters as any)[xLabelFormatterName];
  const xLabelFormatterOptions = formattersOptions['xLabel'];

  const shortXLabelFormatterName: string = formatters['shortXLabel'];
  const shortXLabelFormatter = (allFormatters as any)[shortXLabelFormatterName];
  const shortXFormatterOptions = formattersOptions['shortXLabel'];

  const groupModels: ColumnChartDeviationGroupModel[] = [];

  keys.forEach((rawDataKey: string) => {
    const rawDataItem = rawData[rawDataKey];
    const { label_1, label_4, waarde, eenheid } = rawDataItem;
    const groupKey: string = `${rawDataItem[xLabelKey]}`;

    let xLabel = xLabelFormatter ? xLabelFormatter(groupKey as string, xLabelFormatterOptions) : groupKey as string;
      if (!!label_1 && appendThresholdToXLabel) {
        xLabel = `${xLabel} ${label_1}`;
      }

    groupModels.push({
      key: `${groupKey}-${label_4}`,
      // TODO: huh?
      label: label_4,
      // label: rawData[Object.keys(rawData)[waarde < 0 ? 0 : 1]].label_4,
      xLabel: xLabel,
      shortXLabel: shortXLabelFormatter ? shortXLabelFormatter(groupKey as string, shortXFormatterOptions) : groupKey as string,
      valueKeys: [label_4],
      rawValues: { [label_4]: waarde },
      formattedValues: {
        [label_4]: valueFormatter ?
          valueFormatter(waarde, valueFormatterOptions) :
          allFormatters.formatValue(waarde, eenheid as ValueUnit, valueFormatterOptions)
      }
    });
  });

  let columnCharDataModel: ColumnChartDeviationGroupModel[] = [];
  columnCharDataModel = reverse ? groupModels.reverse() : groupModels;

  return { groups: columnCharDataModel };
};

export default columnChartDeviationListTransformer;