import { formatValue, formatGrade, formatNumber, formatQuarter, formatMonth, formatPeriod } from '../../utils/formatters';
import { TransformFunction, TransformOptionsBase, calcNumberOfColumns, calcNumberOfRows, padLeftWithZeros } from '../../components/content/transform-utils';
import { } from '../../utils/formatters';

interface RD {
  waarde: number;
  label_1?: string;
  label_2?: string;
  label_3?: string;
  label_4: string;
  eenheid: string;
}

// type RDLabelKey = 'label_1' | 'label_2' | 'label_3' | 'label_4';

interface UnitCompareRawData {
  [key: string]: RD;
}

export interface UnitCompareCellData {
  label: string;
  rawValue: number; // | string | null;
  formattedValue: string;
}


export interface UnitCompareDataModel {
  column: UnitCompareCellData[];
}

export interface UnitCompareTransformOptions extends TransformOptionsBase {
  /**
   * @default label_4
   */
  // labelKey?: RDLabelKey;
}

const isANumber = (str: string = 'not a number') => {
  // parsing an empty string will result in 0
  if (str === '') {
    return false;
  }

  // parse the string to a number and compare it to itself.
  // will return false if string is NaN
  //eslint-disable-next-line
  return +str === +str;
};

/**
 * Labels (label_4) that equal or contain (case insensitive) any of the words in excludedLabels are added to the transformed result.
 */
export const excludedLabels = ['landelijk', 'norm'];

/**
 * transformer that transforms the data to one column, flattening all de dimensions (label_1, label_2, label_3).
 * Note: This transformer does not use the options property.
 */
const unitCompareTransformer: TransformFunction<UnitCompareDataModel, UnitCompareTransformOptions> = (
  rawData: UnitCompareRawData,
  onError,
  // options
) => {

  const keys = Object.keys(rawData);
  const guard = !keys.some((key) => rawData[key].waarde !== null);

  if (guard) {
    onError('nodata');
    return null;
  }

  // const {
  //   // formatters = {},
  //   // reverse = false,
  //   // labelKey = 'label_4',
  //   // formattersOptions,
  // } = options || {} as UnitCompareTransformOptions;

  const numberOfRows = calcNumberOfRows(keys);
  const numberOfColumns = calcNumberOfColumns(keys);

  const column: UnitCompareCellData[] = [];

  //val00 or val00_00
  const firstItem = rawData[keys[0]];
  const allLabel4Equal = keys.every((key) => rawData[key].label_4 === firstItem.label_4);

  const isProbablyTrend = isANumber(firstItem.label_1) && isANumber(firstItem.label_2);
  const areProbablyPeriods = isProbablyTrend && keys.some((key) => +(rawData[key].label_2 as string) > 12);
  const areProbablyMonths = isProbablyTrend && !areProbablyPeriods && keys.some((key) => +(rawData[key].label_2 as string) > 11);
  const areProbablyQuarters = isProbablyTrend && keys.every((key) => +(rawData[key].label_2 as string) < 5);

  for (let rowNumber = 0; rowNumber < numberOfRows; rowNumber++) {

    for (let colNumber = 0; colNumber < numberOfColumns; colNumber++) {

      let row = padLeftWithZeros(rowNumber, 2);
      let col = padLeftWithZeros(colNumber, 2);

      let key = `val${row}_${col}`;
      const item = rawData[key];

      const { waarde, eenheid, label_1, label_2, label_3, label_4 } = item;

      const firstDimension = label_1 || '';
      let secondDimension = label_2 || '';
      const thirdDimension = label_3 || '';
      const label = label_4;

      if (areProbablyQuarters) {
        secondDimension = formatQuarter(secondDimension);
      } else if (areProbablyMonths) {
        secondDimension = formatMonth(secondDimension);
      } else if (areProbablyPeriods) {
        secondDimension = formatPeriod(secondDimension);
      }

      let labels = [firstDimension, secondDimension, thirdDimension];
      if (numberOfRows === 1 || !allLabel4Equal) {
        labels.push(label);
      }

      labels = labels.filter((label) => !!label);

      if (excludedLabels.every((excl) => label_4.toLowerCase().indexOf(excl) === -1)) {
        let cell = {
          label: labels.join(' '), //item[labelKey] as string,
          rawValue: waarde,
          formattedValue: formatValue(waarde, eenheid as ValueUnit),
          valueUnit: eenheid
        };

        // TODO: dit is een oplossing om de decimalen te tonen bij 'bvhper fte', maar is geen goede structurele oplossing?
        if(eenheid === 'aantal' && waarde < 1 && waarde > -1) {
          cell.formattedValue = formatNumber(waarde, { numberOfDecimals: 2 });
        }

        // TODO: dit is een oplossing om de decimalen te tonen bij 'hic ratio', maar is geen goede structurele oplossing?
        if(eenheid === 'aantal' && label_4.indexOf('ratio') !== -1) {
          cell.formattedValue = formatNumber(waarde, { numberOfDecimals: 1 });
        }

        if (eenheid === 'gradering') {
          const grade = formatGrade(waarde);
          const score = formatNumber(waarde, { numberOfDecimals: 1 });
          cell.formattedValue = `${grade} (${score})`;
        }

        column.push(cell);
      }
    }
  }

  return { column };
}

export default unitCompareTransformer;