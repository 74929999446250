import { SearchableListItem } from 'ppd-library/components/organisms/SearchableList';
import React, { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';

import { BreadCrumbs, BreadCrumbItem } from '.';
import { MediaDevices } from '../../../App';
import muiTheme from '../../../constants/theme';
import { throttle } from '../../../utils';
import { pxToRem } from '../../../utils/converters';
import { SearchableUnitListBox } from '../../SearchableUnitListBox';

export interface PageHeaderProps {
  // TODO: bijna alle props moeten naar redux?
  /**
   * 
   */
  breadCrumbItems: BreadCrumbItem[];
  /**
   * Buttons that appear on the right side of the header.
   */
  actionButtons?: ReactNode;
  onSelectedOeChange?: (item: { name: string, code: string }) => void;
  menuOpen: boolean;
  showHelp: boolean;
  menuOpenSpeed: number;
  menuOpenWidth: number;
  mediaDevices: MediaDevices;
  initialUnitCode?: string;
  /**
   *
   * @default false
   */
  hideGlobalUnitSelection?: boolean;
  onThemeInfoClick?: (helpId: number) => void;
}

const StyledHeader = styled(({ isMobile, menuOpenWidth, menuOpen, menuOpenSpeed, scrolled, ...headerProps }) => <header {...headerProps} />)`
  top: 3.3rem;
  left:  0;
  right:  0;
  padding-left: ${({ isMobile }) => isMobile ? ' 0.5rem' : ' 8.5rem'};
  padding-right: ${({ isMobile }) => isMobile ? ' 0.5rem' : ' 4rem'};
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: stretch;
  background: #FAFAFA;
  z-index: ${muiTheme.zIndex.appBar - 1};
  transition: ${({ menuOpenSpeed }) => `transform ${menuOpenSpeed}ms ease-in-out, box-shadow ${menuOpenSpeed}ms ease-in-out`};
  transform: ${({ menuOpenWidth, menuOpen, isMobile }) => !isMobile && `translateX(${menuOpen ? pxToRem(menuOpenWidth - 100) : 0})`};
  box-shadow:  ${({ scrolled }) => `0 0px 11px rgba(182,182,182, ${scrolled ? 0.7 : 0} );`};
`as React.ComponentType<{ isMobile: boolean; menuOpenWidth: number; menuOpen: boolean; menuOpenSpeed: number; scrolled: boolean; } & React.HTMLProps<HTMLDivElement>>;


const StyledHeaderContent = styled(({ isMobilePhone, ...divProps }) => <div {...divProps} />)`
    flex: 1;
    display: flex;
    justify-content: space-between;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    height: ${({ isMobilePhone }) => isMobilePhone ? '4rem' : '3.5rem'};
  `as React.ComponentType<{ isMobilePhone: boolean } & React.HTMLProps<HTMLDivElement>>;


const StyledUnitSearchHolder = styled(({ isMobilePhone, ...divProps }) => <div {...divProps} />)`
    margin-left: 1rem;
    max-height: 32px;
    position: ${({ isMobilePhone }) => isMobilePhone && 'absolute'};
    top: ${({ isMobilePhone }) => isMobilePhone && '1.5rem'};
    left: ${({ isMobilePhone }) => isMobilePhone && '-0.2rem'};
  `as React.ComponentType<{ isMobilePhone: boolean } & React.HTMLProps<HTMLDivElement>>;

const StyledHeaderContentSection = styled.div`
  && {
    display: flex;
    align-items: center;
  }
`;

/**
 * TODO: er zit een rare ruimte onder de chip, waar komt die vandaan?
 * TODO: dit is eigenlijk 'dashboardpageheader'
 */
const PageHeader = (props: PageHeaderProps) => {
  const {
    mediaDevices, // TODO: dit zou een global moeten zijn zodat je niet overal mediadevices hoeft mee te geven
    actionButtons,
    menuOpenSpeed,
    menuOpenWidth,
    menuOpen,
    showHelp,
    breadCrumbItems,
    initialUnitCode = 'NP00000',
    onThemeInfoClick = () => {},
    onSelectedOeChange = () => {},
    hideGlobalUnitSelection = false,
  } = props;

  const { mobile:isMobile, mobilePhone : isMobilePhone } = mediaDevices;
  const [scrolled, setScrolled] = useState(false);
 
  const handleSelectedItemChanged = (newItem: SearchableListItem) => {
    // TODO: temp solution
    const { key, label } = newItem;
    onSelectedOeChange({ name: label, code: key });
    // globalDispatch({ type: 'UPDATE_GLOBAL_OE', payload: { code: newItem.key, name: newItem.label } });
  }

  const handleScroll = throttle(() => {
    const scrollTop = window.pageYOffset || document.body.scrollTop;
    setScrolled(scrollTop > 1);
  }, 200);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TODO: on mobile (< 600px), wrap in hide on scroll
  return (
    <StyledHeader
      menuOpenSpeed={menuOpenSpeed}
      menuOpenWidth={menuOpenWidth}
      menuOpen={menuOpen}
      isMobile={isMobile}
      scrolled={scrolled}
      className={'pageHeader'}
    >
      <StyledHeaderContent isMobilePhone={mediaDevices.mobilePhone}>
        <StyledHeaderContentSection>
          <BreadCrumbs
            showHelp={showHelp}
            items={breadCrumbItems}
            className={'unitSearchLabel'}
            onThemeInfoClick={onThemeInfoClick}
            isMobilePhone={mediaDevices.mobilePhone}
          />

          {!hideGlobalUnitSelection &&
            <StyledUnitSearchHolder isMobilePhone={mediaDevices.mobilePhone} className={'unitSearchHolder'}>
              <SearchableUnitListBox
                initialUnitCode={initialUnitCode}
                onSelectedItemChanged={handleSelectedItemChanged}
              />
            </StyledUnitSearchHolder>
          }
        </StyledHeaderContentSection>
        <StyledHeaderContentSection className={'actionButtonsHolder'}>
          {!!actionButtons && !isMobilePhone && actionButtons}
        </StyledHeaderContentSection>
      </StyledHeaderContent>
    </StyledHeader>
  );
}

export default PageHeader;