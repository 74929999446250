import { TransformFunction, TransformOptionsBase, calcNumberOfRows, padLeftWithZeros } from '../transform-utils';
import * as allFormatters from '../../../utils/formatters';

interface RD {
  waarde: number;
  label_1: string;
  label_2?: string;
  label_3?: string;
  label_4: string;
  eenheid: string;
}

export interface DistributionChartRawData {
  [key: string]: RD;
}

export interface DistributionChartTransformOptions extends TransformOptionsBase {
  reverse?: boolean;
}

export interface DistributionChartDataModel {
  key: string;
  label: string;
  valueKey: string;
  rawValue: number;
  formattedValue: string;
}

export interface DistributionChartItem {
  groupLabel: string;
  key: string;
  primaryValue: {
    label: string;
    rawValue: number;
    formattedValue: string;
  };
  secondaryValue: {
    label: string;
    rawValue: number;
    formattedValue: string;
  };
}

export interface DistributionChartBarDataModel {
  groupsModel: DistributionChartItem[];
}

const distributionChartTransformer: TransformFunction<DistributionChartBarDataModel, DistributionChartTransformOptions> = (
  rawData: DistributionChartRawData,
  onError,
  options
) => {

  const keys = Object.keys(rawData);
  const guard = !keys.some((key) => rawData[key].waarde !== null);

  if (guard) {
    onError('nodata');
    return null;
  }

  const {
    reverse = false,
    formatters = {},
    formattersOptions = {}
  } = options || {} as DistributionChartTransformOptions;

  const numberOfRows = calcNumberOfRows(keys);

  const primaryValueFormatterName = formatters['primaryValue'];
  const primaryValueFormatter = (allFormatters as any)[primaryValueFormatterName];
  const primaryValueFormatterOptions = formattersOptions['primaryValue'];

  const secondaryValueFormatterName = formatters['secondaryValue'];
  const secondaryValueFormatter = (allFormatters as any)[secondaryValueFormatterName];
  const secondaryValueFormatterOptions = formattersOptions['secondaryValue'];

  const labelFormatterName: string = formatters['label'];
  const labelFormatter = (allFormatters as any)[labelFormatterName];
  const labelFormatterOptions = formattersOptions['label'];

  let groupItems: DistributionChartItem[] = [];

  for (let rowNumber = 0; rowNumber < numberOfRows; rowNumber++) {
    const row = padLeftWithZeros(rowNumber, 2);
    const primaryKey = `val${row}_00`;
    const secondaryKey = `val${row}_01`;
    const rawPrimaryItem = rawData[primaryKey] as RD;
    const rawSecondaryItem = rawData[secondaryKey] as RD;

    const { label_1: groupLabel, label_4: primaryLabel, waarde: primaryWaarde, eenheid: primaryEenheid } = rawPrimaryItem;
    const { label_4: secondaryLabel, waarde: secondaryWaarde, eenheid: secondaryEenheid  } = rawSecondaryItem;

    const groupItem = {
      groupLabel: groupLabel,
      key: `${groupLabel}-${row}`,
      primaryValue: {
        rawValue: primaryWaarde,
        label: primaryLabel,
        formattedValue: primaryValueFormatter ?
          primaryValueFormatter(primaryWaarde, primaryValueFormatterOptions) :
          allFormatters.formatValue(primaryWaarde, primaryEenheid as ValueUnit, primaryValueFormatterOptions)
      },
      secondaryValue: {
        rawValue: secondaryWaarde,
        label: labelFormatter ? labelFormatter(secondaryLabel as string, labelFormatterOptions) : secondaryLabel,
        formattedValue: secondaryValueFormatter ?
          primaryValueFormatter(secondaryWaarde, secondaryValueFormatterOptions) :
          allFormatters.formatValue(secondaryWaarde, secondaryEenheid as ValueUnit, secondaryValueFormatterOptions)
      }
    };
    groupItems.push(groupItem);
  }

  return { groupsModel: reverse ? groupItems.reverse() : groupItems };

};

export default distributionChartTransformer;