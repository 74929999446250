import MuiAddToQueueIcon from '@material-ui/icons/AddToQueue';
import MuiFeedbackIcon from '@material-ui/icons/Feedback';
import MuiGetAppIcon from '@material-ui/icons/GetApp';
import useTheme from '@material-ui/styles/useTheme';
import * as H from 'history';
import React, { createRef, useEffect, useRef, useState } from 'react';
import { ItemCallback, Layout } from 'react-grid-layout';

import { ViewTypes } from '../@types/ppd-api.enums';
import { MediaDevices } from '../App';
import { GridBreakpoint, GridLayout } from '../components/layout/GridLayout';
import { BreadCrumbItem, PageHeader } from '../components/layout/PageHeader';
import { MenuActionButton } from '../components/tiles/action-buttons/MenuActionButton';
import { Container } from '../components/tiles/Container';
import { ContainerErrorBoundary } from '../components/tiles/ContainerErrorBoundary';
import { LoggingService } from '../LoggingService';
import { saveElement } from '../utils';
import { httpGet } from '../utils/http';
import {
  EditActionButton,
  EditDoneActionButton,
  SaveAsNewTemplateActionButton,
} from './dashboardpage/components/action-buttons';
import { StyledDashboardPageWrapper } from './dashboardpage/components/StyledDashboardPageWrapper';
import { StyledGridTileWrapper } from './dashboardpage/components/StyledGridTileWrapper';
import TemplateDisabledPlaceholder from './dashboardpage/components/TemplateDisabledPlaceholder';
import TemplateDoesNotExistPlaceholder from './dashboardpage/components/TemplateDoesNotExistPlaceholder';
import {
  columnsPerBreakpoint,
  containerBreakpoints,
  createAllTileDimensions,
  createGridLayouts,
  createTileDimensions,
  handleTileResize,
  rowHeight,
  syncCurrentLayout,
  syncLayout,
} from './dashboardpage/grid-utils';
import {
  useCurrentBreakpointChangedEffect,
  useDashboardPageLoggingEffect,
  useDashboardPageRefs,
  useDashboardPageState,
} from './dashboardpage/hooks';
import { DashboardPageBaseProps } from './dashboardpage/interfaces';
import { createLinkedTemplateTiles, getQueryParams } from './dashboardpage/utils';

// eslint-disable-next-line
const { API_URL, DATA_URL, SHOW_IDS, USE_MOCK_DATA } = ppd.appConfig;

interface DashboardPageProps extends DashboardPageBaseProps {
  history: H.History;
  helpActive: boolean; // TODO: redux
  template?: API_GET.Template;
  mediaDevices: MediaDevices; // TODO: redux
  hasCustomTemplates: boolean; // TODO: redux en/of via account?
  onThemeInfoClick: (helpId: number) => void;
  onAddToCustomDashboard: (templateTile: API_GET.TemplateTileDetail, unitCode: string) => void;
  onCopyDashboard: (template: API_GET.TemplateDetail, layouts: API_GET.TemplateLayout[], oeCode: string) => void;
}

let resizeTimeout: number;
let resizeStopTimeout: number;
let widthChangeTimeout: number;
let layoutChangedTimeout: number;
const DashboardPage = (props: DashboardPageProps) => {
  const {
    history,
    account,
    menuOpen,
    helpActive,
    mediaDevices,
    menuOpenWidth,
    menuOpenSpeed,
    onLoadingChange,
    onCopyDashboard,
    onThemeInfoClick,
    onDashboardEditEnabledChange,
    hasCustomTemplates,
    onAddToCustomDashboard,
    template = {},
  } = props;

  const urlQueryParamValues = getQueryParams(history.location);
  const showDevIds = SHOW_IDS || urlQueryParamValues['show_ids'] === 'true';
  const isMobile = mediaDevices.mobile;

  const {
    id: templateId,
    name: themeName,
    path: themePath,
    enabled,
    iconName,
    cleanTiles,
    placeholderText,
    helpId
  } = template as API_GET.Template;

  const theme = useTheme<any>();
  const [templateDetail, setTemplateDetail] = useState<API_GET.TemplateDetail | null>(null);
  const { templateTiles, isCustom } = templateDetail || {} as API_GET.TemplateDetail;
  const stopGridLayoutWidthChangeRef = useRef(false);
  const templateLayoutsRef = useRef<API_GET.TemplateLayout[]>([]);

  // TODO: set account in redux global state?
  const { 
    defaultSelectedOrganisationUnit,
    id: accountId 
  } = account;

  const { code: defaultSelectedOrganisationUnitCode } = defaultSelectedOrganisationUnit || {
     level: 1, 
     code: "NP00000",
     name: "Business Data Challengers",
} as API.OrganisationUnit;

  // TODO: selectedGlobalUnitCode moet in redux zodat het onthouden blijft tijdens de sessie, 
  // anders gaat hij elke keer weer terug naar default als je b.v. van vergelijken naar een thema gaat
  const [selectedGlobalUnitCode, setSelectedGlobalUnitCode] = useState(defaultSelectedOrganisationUnitCode);

  const {
    isLoading, setIsLoading,
    editEnabled, setEditEnabled,
    tileDimensions, setTileDimensions,
    currentBreakpoint, setCurrentBreakpoint,
  } = useDashboardPageState();

  const {
    gridLayoutsRef,
    gridTileWrappersRef
  } = useDashboardPageRefs();

  const handleResizeStop: ItemCallback = (layout, _oldItem, newItem, _placeholder, _event, _element) => {
    clearTimeout(resizeTimeout);
    clearTimeout(resizeStopTimeout);

    return setTimeout(() => {
      const newDimensions = createTileDimensions(newItem, tileDimensions, gridTileWrappersRef);
      syncLayout(layout, currentBreakpoint, gridLayoutsRef, templateLayoutsRef);

      setTileDimensions(newDimensions);
    }, 50);
  }

  const handleResize: ItemCallback = (_layout, _oldItem, newItem, _placeholder, _event, _element) => {
    resizeTimeout = handleTileResize(newItem, resizeTimeout, tileDimensions, gridTileWrappersRef, setTileDimensions);
  };

  const handleTileDragStop: ItemCallback = (layout: Layout[]) => {
    syncLayout(layout, currentBreakpoint, gridLayoutsRef, templateLayoutsRef);
  }

  const handleBreakpointChange = (newBreakPoint: string) => {
    stopGridLayoutWidthChangeRef.current = true;

    syncLayout(gridLayoutsRef.current[newBreakPoint], newBreakPoint as GridBreakpoint, gridLayoutsRef, templateLayoutsRef);
    setCurrentBreakpoint(newBreakPoint as GridBreakpoint);
  };

  const handleSelectedUnitChange = (item: { code: string, name: string }) => {
    setSelectedGlobalUnitCode(item.code);
  };

  const handleEditLayoutClick = () => {
    onDashboardEditEnabledChange(true); // TODO: gebruik redux
    setEditEnabled(true);
  };

  const handleSaveAsNewTemplateClick = () => {
    let cloneTemplate: API_GET.TemplateDetail = {
      ...templateDetail as API_GET.TemplateDetail,
      templateTiles: [...(templateDetail as API_GET.TemplateDetail).templateTiles]
    };

    let cloneLayouts: API_GET.TemplateLayout[] = templateLayoutsRef.current.map((templateLayout) => {
      return {
        ...templateLayout,
        items: [...templateLayout.items]
      };
    });

    //NOTE!: Because of linkedTiles and enabledForOELevel, we need to filter out the tiles that should not be copied.
    cloneTemplate.templateTiles = cloneTemplate.templateTiles.filter((templateTile) => {
      const { id, tile } = templateTile;
      const { enabledForOELevel, linkedTileId } = tile;

      if (
        !enabledForOELevel ||
        (enabledForOELevel === 1 && selectedGlobalUnitCode === 'NP00000') ||
        (enabledForOELevel > 1 && selectedGlobalUnitCode !== 'NP00000')
      ) {
        return true;
      }

      if(!!enabledForOELevel && !!linkedTileId) {
        // NOTE!: we assume that the linkedTile should be visible, because the current templateTile is not
        const linkedTemplateTile = cloneTemplate.templateTiles.find(({ tileId }) => tileId === linkedTileId);

        if (!!linkedTemplateTile) {
          cloneLayouts.forEach((layout) => {
            layout.items.forEach((item) => {
              if (item.templateTileId === id) {
                item.templateTileId = linkedTemplateTile.id;
              }
            });
          });
        }
      }

      return false;
    });

    onCopyDashboard(cloneTemplate, cloneLayouts, selectedGlobalUnitCode);
    onDashboardEditEnabledChange(true); // TODO: gebruik redux
  }

  const handleEditLayoutDoneClick = () => {
    onDashboardEditEnabledChange(false);
    setEditEnabled(false);
  };

  const handleWidthChange = () => {
    clearTimeout(widthChangeTimeout);
    // TODO: wanneer de breakpoint veranderd, gaat deze ook af maar wordt de oude breakpoint meegegeven in de debounce..
    // daardoor wordt de currentbreakpoint weer teruggezet naar de oude terwijl de layouts wel goed blijven.
    // dit zorgt er bijvoorbeeld voor bij het putten van de layouts, dat de verkeerde items worden ge-update, verkeerde layout wordt gepakt omdat currentbreakpoint wordt gebruikt
    // daarom nu als quickfix een flag wanneer een breakpoint veranderd.. 
    if(stopGridLayoutWidthChangeRef.current) {
      stopGridLayoutWidthChangeRef.current = false;
    } else {
      widthChangeTimeout = setTimeout(() => {
        syncCurrentLayout(currentBreakpoint, gridLayoutsRef, templateLayoutsRef);
         const currentLayout = gridLayoutsRef.current[currentBreakpoint];
         const newDimensions = createAllTileDimensions(currentLayout, tileDimensions, gridTileWrappersRef);
         setTileDimensions(newDimensions);
      }, 250);
    }
  };

  // TODO: page of tile util
   const handleDownloadToImageClick = (element: HTMLElement, tileTitle: string) => {
    saveElement(element, tileTitle, false);
  };

  const handleTileDataKeyTypeClick = (templateTileId: number, dataKeyType: API.DataKeyType) => {
      const templateTileIndex = templateTiles.findIndex((tt) => tt.id === templateTileId);
      let updatedTemplateTile = { ...templateTiles[templateTileIndex] };
      updatedTemplateTile.selectedDataKeyType = dataKeyType;
      const updatedTemplateTiles = [...templateTiles];
      updatedTemplateTiles[templateTileIndex] = updatedTemplateTile;

// TODO: wat doen deze handleclicks met de performance? de render wordt meerdere keren aangeroepen voor alle tegels..
      const newTemplateDetail = {
        ...templateDetail as API_GET.TemplateDetail,
        templateTiles: updatedTemplateTiles
      };
      setTemplateDetail(newTemplateDetail);
  };

  const handleTileTabChange = (templateTileId: number, tabId: number) => {
      const templateTileIndex = templateTiles.findIndex((tt) => tt.id === templateTileId);
      let updatedTemplateTile = { ...templateTiles[templateTileIndex] };
      updatedTemplateTile.selectedTabId = tabId;
      const updatedTemplateTiles = [...templateTiles];
      updatedTemplateTiles[templateTileIndex] = updatedTemplateTile;


// TODO: wat doen deze handleclicks met de performance? de render wordt meerdere keren aangeroepen voor alle tegels..
      const newTemplateDetail = {
        ...templateDetail as API_GET.TemplateDetail,
        templateTiles: updatedTemplateTiles
      };
      
      setTemplateDetail(newTemplateDetail);
  };

  const handleTileViewTypeChange = (templateTileId: number, viewTypeId: number) => {
    const viewTypeName = Object.keys(ViewTypes)
      .find((key) => ViewTypes[key as API.ViewTypeName] === viewTypeId) as API.ViewTypeName;
    const viewType: API.ViewType = {
      id: viewTypeId,
      name: viewTypeName
    };

      const templateTileIndex = templateTiles.findIndex((tt) => tt.id === templateTileId);
      let updatedTemplateTile = { ...templateTiles[templateTileIndex] };
      updatedTemplateTile.selectedViewType = viewType;
      const updatedTemplateTiles = [...templateTiles];
      updatedTemplateTiles[templateTileIndex] = updatedTemplateTile;

// TODO: wat doen deze handleclicks met de performance? de render wordt meerdere keren aangeroepen voor alle tegels..
      const newTemplateDetail = {
        ...templateDetail as API_GET.TemplateDetail,
        templateTiles: updatedTemplateTiles
      };

      setTemplateDetail(newTemplateDetail);
  };

  useEffect(() => {
    const getTemplateDetail = async () => {
      if (!!templateId) {
        setIsLoading(true);
        const templateDetailP = httpGet<API_GET.TemplateDetail>(`${API_URL}/templates/${templateId}`);
        const layoutsP = httpGet<API_GET.TemplateLayout[]>(`${API_URL}/templates/${templateId}/layouts`);

        const [templateDetail, layouts] = await Promise.all([templateDetailP, layoutsP]);
        const gridLayouts = createGridLayouts(layouts);
        gridLayoutsRef.current = gridLayouts;
        templateLayoutsRef.current = layouts;

        setTemplateDetail(templateDetail);
        setIsLoading(false);
      } else {
        setTemplateDetail(null);
        setIsLoading(false);
      }

      // Sets the page to top on every template path change.
      window.scrollTo(0, 0);
    };

    getTemplateDetail();
    if (!!templateId) {
      LoggingService.logUsage({
        deviceType: 'v2',
        kpi: themeName,
        type: 'kpiChange',
        unitCode: selectedGlobalUnitCode,
        userGuid: `${account.id}`
      });
    }
    // NOTE: We DO NOT want to add currentBreakPoint as a dependency here (don't need to fetch templateDetail on breakpoint change)
    // eslint-disable-next-line
  }, [templateId]);

  useCurrentBreakpointChangedEffect(
    layoutChangedTimeout, currentBreakpoint, templateDetail,
    gridLayoutsRef, tileDimensions, gridTileWrappersRef, setTileDimensions,
    (timeout) => {
      layoutChangedTimeout = timeout
    }
  );

  useEffect(() => {
    return () => {
      clearTimeout(resizeTimeout);
      clearTimeout(resizeStopTimeout);
      clearTimeout(widthChangeTimeout);
      clearTimeout(layoutChangedTimeout);
    };
  }, []);

  // TODO: set page loading prop via redux
  useEffect(() => {
    onLoadingChange(isLoading);
    // eslint-disable-next-line
  }, [isLoading]);

  useDashboardPageLoggingEffect(themeName, selectedGlobalUnitCode, accountId);

  const linkedTemplateTiles = createLinkedTemplateTiles(templateTiles);

  let breadCrumbsItems: BreadCrumbItem[] = [
    { iconName: 'dashboard', text: `Kpi's`, path: themeName === `Kpi's` ? null : '/overzicht' }
  ];

  if(themeName !== `Kpi's`) {
    breadCrumbsItems.push(
      { iconName: iconName, text: themeName, path: `/kpis/${themePath}`, themeHelpId: helpId }
    );
  }


  return (
    <>
     {showDevIds &&
        <div style={{
          right: '32px',
          zIndex: 9999999,
          position: 'fixed',
          marginTop: '-24px',
        }}>
          {currentBreakpoint}
        </div>
      }
      <PageHeader
        menuOpen={menuOpen}
        showHelp={helpActive}
        mediaDevices={mediaDevices}
        menuOpenSpeed={menuOpenSpeed}
        menuOpenWidth={menuOpenWidth}
        breadCrumbItems={breadCrumbsItems}
        onThemeInfoClick={onThemeInfoClick}
        initialUnitCode={selectedGlobalUnitCode}
        onSelectedOeChange={handleSelectedUnitChange}
        actionButtons={
          <>
            {!(template as API_GET.Template).isOverview && !editEnabled &&
              <EditActionButton
                disabled={menuOpen}
                isMobile={isMobile}
                onClick={handleEditLayoutClick}
              />
            }
             {editEnabled &&
            <SaveAsNewTemplateActionButton
              isMobile={isMobile}
              onClick={handleSaveAsNewTemplateClick}
            />
            }
            {editEnabled &&
            <EditDoneActionButton
              color={'primary'}
              isMobile={isMobile}
              onClick={handleEditLayoutDoneClick}
            />
            }
          </>
        } />
      <StyledDashboardPageWrapper
        isMobile={isMobile}
        menuOpen={menuOpen}
        menuOpenSpeed={menuOpenSpeed}
        menuOpenWidth={menuOpenWidth}
        className={'dashboardPageWrapper'}
      >
        <TemplateDoesNotExistPlaceholder
          isLoading={isLoading}
          templateDetail={templateDetail}
          placeholderText={placeholderText}
          placeholderColor={theme.palette.secondary.light}
        />
        <TemplateDisabledPlaceholder
          isLoading={isLoading}
          templateEnabled={enabled}
          templateDetail={templateDetail}
          placeholderText={placeholderText}
          placeholderColor={theme.palette.secondary.light}
        />
        {!!templateDetail && enabled && !placeholderText &&
          <GridLayout
            rowHeight={rowHeight}
            onResize={handleResize}
            isEditable={editEnabled}
            breakpoint={currentBreakpoint}
            onDragStop={handleTileDragStop}
            onResizeStop={handleResizeStop}
            layouts={gridLayoutsRef.current}
            onWidthChange={handleWidthChange}
            breakpoints={containerBreakpoints}
            onBreakpointChange={handleBreakpointChange}
            columnsPerBreakpoint={columnsPerBreakpoint}
          >

            {linkedTemplateTiles
              .map((templateTiles) => {
                const { id: templateTileId } = templateTiles[0];

                // TODO: enabledTemplateTiles in plaats alle templateTiles?
                // NOTE: this need to be done here, inside the loop, because tiles can be removed and added to the filter.
                if (!gridTileWrappersRef.current[`${templateTileId}`]) {
                  gridTileWrappersRef.current[`${templateTileId}`] = createRef();
                }

                const { width = undefined, height = undefined } = tileDimensions[`${templateTileId}`] || {};

                return (
                  <StyledGridTileWrapper
                    // NOTE: the grid uses the react key to bind to the layout
                    editMode={editEnabled}
                    key={`${templateTileId}`}
                    innerRef={gridTileWrappersRef.current[`${templateTileId}`]}
                  >
                    {
                      templateTiles.map((templateTile: API_GET.TemplateTileDetail) => {
                        const {
                          path,
                          tile,
                          tileId,
                          selectedViewType,
                          id: templateTileId,
                          selectedDataKeyType,
                          enabled: templateTileEnabled,
                          placeholderText: templateTilePlaceholderText,
                        } = templateTile;

                        const {
                          title,
                          enabledForOELevel,
                          // TODO: ook gebruiken, niet alleen die van templateTile
                          //  enabled: tileEnabled,
                          //  placeholderText: tilePlaceholderText,
                        } = tile;

                        return (
                          // show linkedTiles[0] or linkedTiles[1] depending on enabledForOELevel and selectedGlobalUnitCode
                          (templateTileEnabled === true &&
                            (!enabledForOELevel || 
                              (enabledForOELevel === 1 && selectedGlobalUnitCode === 'NP00000') ||
                              (enabledForOELevel > 1 && selectedGlobalUnitCode !== 'NP00000')
                            )
                          ) &&
                          <ContainerErrorBoundary
                            key={`${templateTileId}`}
                            containerId={templateTileId}
                            placeholderText={templateTilePlaceholderText}
                          >
                            {showDevIds &&
                              <div style={{ position: 'absolute' }}>{templateTileId} - {tileId}</div>
                            }
                            <Container
                              tile={tile}
                              dataUrl={DATA_URL}
                              id={templateTileId}
                              editable={isCustom}
                              showIds={showDevIds}
                              showHelp={helpActive}
                              editMode={editEnabled}
                              isCleanTile={cleanTiles}
                              tileWidth={width as number}
                              useMockData={!!USE_MOCK_DATA}
                              tileHeight={height as number}
                              initialViewType={selectedViewType}
                              selectedDataKeyType={selectedDataKeyType}
                              onTabChange={(tabId) => handleTileTabChange(templateTileId, tabId)}
                              selectedOrganisationUnit={{ code: selectedGlobalUnitCode } as API.OrganisationUnit}
                              onViewTypeChange={(viewTypeId) => handleTileViewTypeChange(templateTileId, viewTypeId)}
                              onDataKeyTypeClick={(dataKeyType) => handleTileDataKeyTypeClick(templateTileId, dataKeyType)}
                              onContentClick={cleanTiles && !editEnabled ? () => history.push(`/kpis/${path}`) : undefined}
                              actionButton={
                                <MenuActionButton
                                  // TODO: deze items lostrekken en herbruikbaar maken?
                                  items={[
                                    {
                                      id: '4',
                                      hidden: !hasCustomTemplates || !!(template as API_GET.Template).isOverview,
                                      iconSvg: <MuiAddToQueueIcon />,
                                      label: `Toevoegen aan dashboard`,
                                      onClick: () => onAddToCustomDashboard(templateTile, selectedGlobalUnitCode),
                                    },
                                    {
                                      id: '5',
                                      iconSvg:  <MuiGetAppIcon />,
                                      label: `Download afbeelding`,
                                      onClick: () => handleDownloadToImageClick(gridTileWrappersRef.current[`${templateTileId}`].current as HTMLDivElement, title),
                                    },
                                    {
                                      id: '6',
                                      onClick: () => {},
                                      label: `Feedback op deze tegel`,
                                      iconSvg: <MuiFeedbackIcon />,
                                    },
                                  ]}
                                />
                              }
                            />
                          </ContainerErrorBoundary>
                        )
                      })
                    }
                  </StyledGridTileWrapper>
                );
              })}
          </GridLayout>
        }
      </StyledDashboardPageWrapper>
    </>
  );
};

export default DashboardPage;