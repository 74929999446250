import MuiGetApp from '@material-ui/icons/GetApp';
import MuiFeedbackIcon from '@material-ui/icons/Feedback';
import { TileHeader } from 'ppd-library/components/organisms/Tile';
import React from 'react';
import styled from 'styled-components';
import { MenuActionButton } from '../../../components/tiles/action-buttons/MenuActionButton';
import { TileRow } from '../../UnitComparePage';
interface UnitCompareTileHeaderProps {
  isMobile: boolean;
  tileRows: TileRow[];
  onExportToExcelClick: () => void;
}

const StyledTileHeader = styled(TileHeader)`
  && {
   display: none;
  }
`;

const UnitCompareTileHeader = (props: UnitCompareTileHeaderProps) => {

  const {
    onExportToExcelClick,
    tileRows = []
  } = props;

  return (
    <StyledTileHeader
      title={''}
      actionButton={
        <MenuActionButton
          items={[{
            id: '1',
            iconSvg: <MuiGetApp />,
            disabled: tileRows.length < 1,
            label: `Exporteer naar Excel`,
            onClick: onExportToExcelClick
          },
          {
            id: '2',
            iconSvg: <MuiFeedbackIcon />,
            label: `Feedback op deze tegel`,
            onClick: () => {}
          }
          ]}
        />
      }
    />
  );
}

export default UnitCompareTileHeader;