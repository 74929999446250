import { httpPost } from "../utils/http";
import {
  columnsPerBreakpoint
} from './dashboardpage/grid-utils';

// eslint-disable-next-line
const { API_URL } = ppd.appConfig;

// TODO: move these to redux (actions)

export const copyTemplateTile = (templateTileToCopy: API_GET.TemplateTileDetail, templateId: number, oeCode?: string) => {
  const {
    tile,
    selectedTabId,
    organisationUnit,
    selectedViewType,
    selectedDataKeyType,
  } = templateTileToCopy;

  const { id: tileId } = tile;
  const { id: selectedViewTypeId } = selectedViewType;
  const { id: selectedDataKeyTypeId } = selectedDataKeyType;
  const organisationUnitCode = !!oeCode ? oeCode : !!organisationUnit ? organisationUnit.code : null;

  const newTemplateTile: API_POST.TemplateTile = {
    tileId,
    templateId,
    selectedViewTypeId,
    organisationUnitCode,
    selectedDataKeyTypeId,
    path: null,
    enabled: true,
    placeholderText: null,
    selectedTabId: selectedTabId || undefined,
  };

  return newTemplateTile;
};

/**
 * @param templateTileToCopy The templateTile that needs to be copied
 * @param templateId The id of the template where the templateTile needs to be copied to
 * @param oeCode optionally overwrite the organisation unit of template tile.
 */
export const copyAndPostTemplateTile = (templateTileToCopy: API_GET.TemplateTileDetail, templateId: number, oeCode?: string) => {
  const newTemplateTile = copyTemplateTile(templateTileToCopy, templateId, oeCode);

  return httpPost<API_POST.TemplateTile, API_GET.TemplateTileDetail>(`${API_URL}/templatetiles`, newTemplateTile);
};

/**
 * Copy multiple templateTiles to another template with templateId.
 * @param templateTiles The templateTiles that need to be copied
 * @param templateId The id of the template where the templateTile needs to be copied to
 * @param oeCode optional property to set the oeCode for all tiles
 * @returns promise that contains an array of already existing templatetile and the newly created (copy) templateTile.
 */
export const copyAndPostTemplateTiles = (templateTiles: API_GET.TemplateTileDetail[], templateId: number, oeCode?: string) => {
  const copies = templateTiles.map((existingTemplateTile) => {
    return copyTemplateTile(
      existingTemplateTile,
      templateId,
      oeCode
    );
  });

  return httpPost<API_POST.TemplateTile[], API_GET.TemplateTileDetail[]>(`${API_URL}/templatetiles_bulk`, copies)
    .then((result) => {
      const mapping: { existingTemplateTile: API_GET.TemplateTileDetail; createdTemplateTile: API_GET.TemplateTileDetail; }[] = [];
      // IMPORTANT NOTE!: we assume here that the order of the returning array is the same as the one we send
      // if this is not the case, the mapping is corrupted
      // TODO: kunnen vergelijken op title en tileid en andere properties om de mapping iets robuuster te maken,
      // maar in theorie kunnen er dubbele tegels in zitten of te weinig.
      templateTiles.forEach((existingTemplateTile, index) => {
        if(!!result[index]) {
          mapping.push({
            existingTemplateTile,
            createdTemplateTile: result[index]
          });
        }
      });

      return mapping;
    });
};

/**
 * // TODO: misschien moet dit aan de back-end gebeuren?
 * Generate a new layoutItem for a newly made/posted templateTile.
 * The layoutItem will use default width and height based on the templateLayout.layoutSize
 * and will try to calculate x and y based on the last tile in the layout.
 * @param templateLayout the layout to generate a layoutitem for
 * @param templateTileId the templateTile to generate a layoutitem for
 */
export const generateCustomTemplateLayoutItem = (templateLayout: API_GET.TemplateLayout, templateTileId: number) => {
  const {
    id: layoutId,
    layoutSize,
    items: layoutItems
  } = templateLayout;

  const lastTile = layoutItems.reduce((previous, current) => {
    const { y: previousY, x: previousX } = previous;
    const { y: currentY, x: currentX } = current;

    if (currentY > previousY || (currentY === previousY && currentX > previousX)) {
      return current;
    }

    return previous;
  }, layoutItems[0] || { x: 0, y: 0, h: 0, w: 0 } as API_GET.TemplateLayoutItem);

  // NOTE!: make sure that this default declaration is never posted
  let newTemplateLayoutItem: API_POST.TemplateLayoutItem = {
    layoutId,
    templateTileId,
    x: 0, y: 0, h: 0, w: 0,
  };
  const maxCols = columnsPerBreakpoint[layoutSize as API.TemplateLayoutSize];
  switch (layoutSize as API.TemplateLayoutSize) {
    case 'xlg2':
    case 'xlg1':
    case 'xlg':
      const xlgH = 6;
      const xlgW = columnsPerBreakpoint.xlg / 4;
      const xlgNewRow = (lastTile.x + lastTile.w + xlgW) > maxCols;
      const xlgY = xlgNewRow ? lastTile.y + lastTile.h : lastTile.y;
      const xlgX = xlgNewRow ? 0 : lastTile.x + lastTile.w;
      newTemplateLayoutItem = {
        x: xlgX,
        y: xlgY,
        h: xlgH,
        w: xlgW,
        layoutId,
        templateTileId,
      };
      break;
    case 'lg':
      const lgW = columnsPerBreakpoint.lg / 3;
      const lgH = 6;
      const lgNewRow = (lastTile.x + lastTile.w + lgW) > maxCols;
      const lgY = lgNewRow ? lastTile.y + lastTile.h : lastTile.y;
      const lgX = lgNewRow ? 0 : lastTile.x + lastTile.w;
      newTemplateLayoutItem = {
        x: lgX,
        y: lgY,
        h: lgH,
        w: lgW,
        layoutId,
        templateTileId,
      };
      break;
    case 'md':
      const mW = columnsPerBreakpoint.md / 3;
      const mH = 6;
      const mNewRow = (lastTile.x + lastTile.w + mW) > maxCols;
      const mY = mNewRow ? lastTile.y + lastTile.h : lastTile.y;
      const mX = mNewRow ? 0 : lastTile.x + lastTile.w;
      newTemplateLayoutItem = {
        x: mX,
        y: mY,
        h: mH,
        w: mW,
        layoutId,
        templateTileId
      };
      break;
    case 'sm':
      const smW = columnsPerBreakpoint.sm / 2;
      const smH = 6;
      const smNewRow = (lastTile.x + lastTile.w + smW) > maxCols;
      const smY = smNewRow ? lastTile.y + lastTile.h : lastTile.y;
      const smX = smNewRow ? 0 : lastTile.x + lastTile.w;
      newTemplateLayoutItem = {
        x: smX,
        y: smY,
        h: smH,
        w: smW,
        layoutId,
        templateTileId
      };
      break;
    case 'xs':
    default:
      const xsW = columnsPerBreakpoint.xs;
      const xsH = 6;
      const xsNewRow = (lastTile.x + lastTile.w + xsW) > maxCols;
      const xsY = xsNewRow ? lastTile.y + lastTile.h : lastTile.y;
      const xsX = xsNewRow ? 0 : lastTile.x + lastTile.w;
      newTemplateLayoutItem = {
        x: xsX,
        y: xsY,
        h: xsH,
        w: xsW,
        layoutId,
        templateTileId
      };
      break;
  };

  return newTemplateLayoutItem;
}

/**
 * Generate new layout item per templateLayout(Size) for a newly made/posted templateTile.
 * The layout items will use default width and height based on the layoutSize
 * and will try to calculate x and y based on the last tile in the templateLayout.
 * @param templateLayouts An item will be generated per supplied templateLayout
 * @param templateTileId the templateTile to generate layoutitems for
 */
export const generateCustomTemplateLayoutItems = (templateLayouts: API_GET.TemplateLayout[], templateTileId: number) => {
  const layoutSizes = templateLayouts.map(({ layoutSize }) => layoutSize);
  const layoutItems = layoutSizes.map((layoutSize) => {
    const templateLayout = templateLayouts.find((tl) => tl.layoutSize === layoutSize) as API_GET.TemplateLayout;

    return generateCustomTemplateLayoutItem(templateLayout, templateTileId);
  });

  return layoutItems;
};

/** 
 * Generate and save/post a new layout item per templateLayout(Size) for a newly made/posted templateTile.
 * The layout items will use default width and height based on the layoutSize
 * and will try to calculate x and y based on the last tile in the templateLayout.
 * @param templateLayouts An item will be generated per supplied templateLayout
 * @param templateTileId the templateTile to generate layoutitems for
 * @returns a promise with an array of cloned/updated templateLayouts, with all the layoutitems added to the corresponding layout
 */
export const generateAndPostCustomTemplateLayoutItems = (templateLayouts: API_GET.TemplateLayout[], templateTileId: number) => {
  const newLayoutItems = generateCustomTemplateLayoutItems(templateLayouts, templateTileId);

  return httpPost<API_POST.TemplateLayoutItem[], API_GET.TemplateLayoutItem[]>(`${API_URL}/layoutitems_bulk`, newLayoutItems)
    .then((createdTemplateLayoutItems) => {
      const clonedTemplateLayouts: API_GET.TemplateLayout[] = templateLayouts.map((templateLayout) => {
        return {
          ...templateLayout,
          items: templateLayout.items.map((item) => { return { ...item }; })
        };
      });

      createdTemplateLayoutItems.forEach((createdTemplateLayoutItem, index) => {
        // IMPORTANT NOTE!: we assume here that the order of the returning array is the same as the one we send
        // if this is not the case, the mapping is corrupted
        const { layoutId } = newLayoutItems[index];
        const templateLayoutIndex = clonedTemplateLayouts.findIndex(({ id }) => id === layoutId) as number;
        const cloneTemplateLayout = clonedTemplateLayouts[templateLayoutIndex];

        cloneTemplateLayout.items.push(createdTemplateLayoutItem);
      });

      return clonedTemplateLayouts;
    });
};

/**
 * Copy the layouts and all their items, change the layout templateId an change all templateIds in the items based on the mapping of existing/old id --> new id
 * @param layouts Then layouts to clone, edit and post
 * @param templateId the id of the new template
 * @param templateTileIdMapping a mapping of the existing template tile ids in the layout.items that need to be changed to new ids
 */
export const copyAndPostLayouts = (layouts: API_GET.TemplateLayout[], templateId: number, templateTileIdMapping: { oldId: number; newId: number; }[]) => {
  const newLayouts: API_POST.TemplateLayout[] = layouts.map((layout) => {
    const { layoutSize, items } = layout;
    const newLayout: API_POST.TemplateLayout = {
      items: [],
      layoutSize,
      templateId
    };

    items.forEach((existingLayoutItem) => {
      const { id, templateTileId, ...rest } = existingLayoutItem;
      const mappingIndex = templateTileIdMapping.findIndex(({ oldId: existingId }) => existingId === templateTileId);

      if (mappingIndex !== -1) {
        const { newId } = templateTileIdMapping[mappingIndex];

        newLayout.items.push({
          templateTileId: newId,
          ...rest
        });
      }
    });

    return newLayout;
  });

  return httpPost<API_POST.TemplateLayout[], API_GET.TemplateLayout[]>(`${API_URL}/layouts_bulk`, newLayouts);
};