import MuiIconButton from '@material-ui/core/IconButton';
import React, { useRef, useState, ReactNode } from 'react';
import MuiMoreVertIcon from '@material-ui/icons/MoreVert';

import { Menu, MenuItem } from '../MenuActionButton/menu';

export interface MenuActionButtonProps {
  items: MenuItem[];
  title?: ReactNode;
  onButtonClick?: () => void;
}

const MenuActionButton = (props: MenuActionButtonProps) => {
  const {
    items,
    title,
    onButtonClick = () => { },
  } = props;

  const [menuOpen, setMenuOpen] = useState(false);
  const elementRef = useRef<HTMLButtonElement>(null);

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  const handleButtonClick = () => {
    setMenuOpen(!menuOpen);
    onButtonClick();
  };

  const handleItemClick = (_id: string) => {
    setMenuOpen(false);
  };

  return (
    <>
      <MuiIconButton
        ref={elementRef}
        title={'acties'}
        color={'primary'}
        onClick={handleButtonClick}
      >
        <MuiMoreVertIcon />
      </MuiIconButton>
      <Menu
        title={title}
        items={items}
        isOpen={menuOpen}
        onClose={handleMenuClose}
        onItemClick={handleItemClick}
        anchorElement={elementRef.current}
      />
    </>
  );
};

export default MenuActionButton;