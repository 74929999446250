import React, { ReactNode } from 'react';
import styled from 'styled-components';

// TODO: should we use public folder to fetch svg?...
const resizeSVG = `PHN2ZyBpZD0icmVzaXplIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHRpdGxlPnJlc2l6ZTwvdGl0bGU+PHBhdGggZD0iTTE5LjUxLDMuMDgsMy4wOCwxOS41MWEyLjA3LDIuMDcsMCwwLDAsLjUxLjksMi4wNywyLjA3LDAsMCwwLC45LjUxTDIwLjkzLDQuNDlBMiwyLDAsMCwwLDE5LjUxLDMuMDhaTTE5LDIxYTIsMiwwLDAsMCwyLTJWMTdsLTQsNFpNOS4yOSwyMWgyLjgzTDIxLDEyLjEyVjkuMjlaIi8+PC9zdmc+`;
export const StyledGridTileWrapper = styled(({ innerRef, editMode, ...divProps }) => <div ref={innerRef} {...divProps} />)`
  &&&{
    touch-action: initial !important;
    transition: all 0ms;
    /* opacity: ${({ editMode }) => editMode ? 0.75 : null}; */
    cursor: ${({ editMode }) => editMode ? 'move' : null};

    .react-resizable-handle {
      background-image: url(data:image/svg+xml;base64,${resizeSVG});
      ::after {
        content: none;
      }
    }
  }
` as React.ComponentType<{ editMode: boolean; innerRef: ReactNode; } & React.HTMLProps<HTMLDivElement>>;

// export default StyledGridTileWrapper;