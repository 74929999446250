import React, { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';

export type BackgroundSize = 'contain' | 'cover';
export type ContentType = 'path' | 'content';
export interface ContentCoverProps {
  content: ReactElement;
  contentType?: ContentType;
  backgroundSize?: BackgroundSize;
  altText?: string;
}

const StyledContainer = styled(({ contentType, ...divProps }) => <div {...divProps} />)`
  flex: 1;
  align-self: center;
  justify-self: center;
  text-align: center;
  height: ${({ contentType }) => contentType === 'content' ? null : ' 100%'};
  > *:first-child {
    width: auto;
    height: ${({ contentType }) => contentType === 'content' ? 'fit-content' : null};
  }
` as React.ComponentType<{ contentType: ContentType; } & React.HTMLProps<HTMLDivElement>>;

const StyledImageContainer = styled(({ backgroundSize, imagePath, ...divProps }) => <div {...divProps} />)`
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-image: ${({ imagePath }) => `url(${imagePath})`};
  background-size: ${({ backgroundSize }) => backgroundSize};
` as React.ComponentType<{ backgroundSize: BackgroundSize; imagePath: string; } & React.HTMLProps<HTMLDivElement>>;

const ContentCover = (props: ContentCoverProps) => {
  const {
    content = <div />,
    contentType = 'content',
    backgroundSize = 'cover',
    altText = ''
  } = props;

  const [processedContent, setProcessedContent] = useState<{ content: string | React.ReactNode; contentType?: ContentType; backgroundSize?: BackgroundSize }>
    ({ content, contentType: contentType, backgroundSize: backgroundSize });

  useEffect(() => {
    setProcessedContent({ content, contentType, backgroundSize })
    // eslint-disable-next-line
  }, [content]);

  return (
    <StyledContainer className={'contentCover'} title={altText} contentType={processedContent.contentType as ContentType}>
      {processedContent.contentType === 'path' &&
        <StyledImageContainer backgroundSize={backgroundSize} imagePath={processedContent.content as string} />
      }
      {processedContent.contentType === 'content' && content}
    </StyledContainer>
  )
}

export default ContentCover;
