import { scales } from 'ppd-library/charts/chartUtils';
import { LineThresholdVertical } from 'ppd-library/charts/molecules/LineThreshold';
import { BarHorizontalStacked } from 'ppd-library/charts/organisms/BarHorizontalStacked';
import React, { useState, useEffect, ReactNode } from 'react';
import styled from 'styled-components';

// // import { HelpTooltipButton } from '../../help/HelpTooltipButton';
import { ComponentBaseProps, Margins } from '../ComponentBaseProps';
import { ContentRow } from '../ContentRow';
import { BarSlideDataModel } from './barSlideTransformer';
import Chart from 'ppd-library/charts/Chart';

export interface BarSlideThemeProps {
  colors?: string[];
  barHeight?: number;
  lineColor?: string;
  labelTextColor?: string;
  thresholdColor?: string;
}
export interface BarSlideProps extends ComponentBaseProps<BarSlideThemeProps, BarSlideDataModel> {
  renderAbove?: ReactNode;
  topMargin?: string;
  cumulative?: boolean;
}

const StyledWrapper = styled.div`
  position: relative;
  flex: 1;
`;

const StyledChartHolder = styled(({ topMargin, ...divProps }) => <div {...divProps} />)`
  margin-top: ${({ topMargin }) => topMargin};
` as React.ComponentType<{ topMargin: string; } & React.HTMLProps<HTMLDivElement>>;

const BarSlide = (props: BarSlideProps) => {
  const {
    id,
    data,
    // helpId,
    renderAbove,
    width = 400,
    // height = 200,
    // showHelp = false,
    cumulative = false,
    margins: originalMargins = {} as Margins,
    themeProperties,
    topMargin = '0'
  } = props;

  const {
    barHeight = 21,
    lineColor = '#636363',
    labelTextColor = '#212121',
    colors = ['gray', '#002647'],
  } = themeProperties || {} as BarSlideThemeProps;
  const { thresholdColor = colors[0] } = themeProperties || {} as BarSlideThemeProps;

  // TODO: vaste hoogte of height - 100 doen? 100px is de hoogte van de contentrow boven de chart...
  const chartHeight = 100;
  const { left = 0, right = 0, top = 0, bottom = 60 } = originalMargins;
  let margins = { left, top, right, bottom };
  const { firstValue, secondValue } = data;

  const [values, setValues] = useState({
    [firstValue.label]: { bar: 0, threshold: 0, color: colors[0] },
    [secondValue.label]: { bar: 0, threshold: 0, color: colors[1] }
  });

  const keys = [firstValue.label, secondValue.label];
  const totalBarValue = cumulative? Math.abs(firstValue.rawValue) + Math.abs(secondValue.rawValue) : Math.max(firstValue.rawValue, secondValue.rawValue);
  const xScale = scales.numericXScale(0, totalBarValue, width, margins.left, margins.right);

  const colorScale = scales.colorScale(
    {
      [firstValue.label]: values[firstValue.label].color,
      [secondValue.label]: values[secondValue.label].color
    }
  );

  useEffect(() => {
    // TODO: net zo simpel als andere oplossing doen met 'initiated'
    // TODO: we need timeout to force animation the first time.. but why?
    const timeout = setTimeout(() => {
      if (cumulative) {
        setValues({
          [firstValue.label]: {
            bar: Math.abs(firstValue.rawValue),
            threshold: Math.abs(firstValue.rawValue),
            color: colors[0]

          },
          [secondValue.label]: {
            bar: Math.abs(firstValue.rawValue) + Math.abs(secondValue.rawValue),
            threshold: Math.abs(firstValue.rawValue) + Math.abs(secondValue.rawValue),
            color: colors[1]
          }
        })
       }
      else {
        setValues({
          [firstValue.label]: {
            bar:
              firstValue.rawValue > secondValue.rawValue ?
                Math.abs(secondValue.rawValue) :
                Math.abs(firstValue.rawValue),
            threshold: Math.abs(firstValue.rawValue),
            color: colors[0]

          },
          [secondValue.label]: {
            bar: secondValue.rawValue > firstValue.rawValue ?
              Math.abs(secondValue.rawValue) - Math.abs(firstValue.rawValue) :
              Math.abs(firstValue.rawValue) - Math.abs(secondValue.rawValue),
            threshold: Math.abs(secondValue.rawValue),
            color: firstValue.rawValue > secondValue.rawValue ? thresholdColor : colors[1]
          }
        })
      }
    }, 0);

    return () => clearTimeout(timeout);
  }, [firstValue, secondValue, colors, thresholdColor, cumulative]);

  return (
    <StyledWrapper>
      {!!renderAbove && renderAbove}
      <ContentRow>
        <StyledChartHolder topMargin={topMargin}>
          <Chart
            width={width}
            height={chartHeight}
            preserveAspectRatio={'none'}
          >
            <LineThresholdVertical
              labelDominantBaseline={0}
              showCircle={false}
              labelPos={[xScale(values[firstValue.label].threshold) > width / 2 ? 'left' : 'right', 'top']}
              lineHeight={barHeight * 3}
              labelPadding={[3, 14, 3, 3]}
              x={xScale(values[firstValue.label].threshold) -0.5}
              y={chartHeight - (barHeight * 2) - margins.bottom}
              label={
                <tspan>
                  <tspan x={10} dy={12} style={{ fontSize: '0.8rem' }}>{firstValue.label}</tspan>
                  <tspan x={10} dy={16} style={{ fontSize: '0.8rem' }}>{firstValue.formattedValue}</tspan>
                </tspan>
              }
              theme={{
                lineColor: lineColor,
                textColor: labelTextColor,
                textSize: '0.8rem',
                backgroundColor: 'transparent'
              }}
            />
            <LineThresholdVertical
              showCircle={false}
              lineHeight={barHeight * 2.5}
              labelPos={[xScale(values[secondValue.label].threshold) > width / 2 ? 'left' : 'right', 'bottom']}
              labelPadding={[3, 14, 3, 3]}
              labelDominantBaseline={'auto'}
              y={chartHeight + barHeight - 74}
              x={xScale(values[secondValue.label].threshold) -0.5}
              label={
                <tspan>
                  <tspan x={10} dy={12} style={{ fontSize: '0.8rem' }}>{secondValue.label}</tspan>
                  <tspan x={10} dy={16} style={{ fontSize: '0.8rem' }}>{secondValue.formattedValue}</tspan>
                </tspan>
              }
              // label={[secondValue.label, secondValue.formattedValue]}
              theme={{
                lineColor: lineColor,
                textColor: labelTextColor,
                textSize: '0.8rem',
                backgroundColor: 'transparent'
              }}
            />
            <BarHorizontalStacked
              keys={keys}
              xScale={xScale}
              height={barHeight}
              colorScale={colorScale}
              y={chartHeight - margins.bottom}
              id={`${id}-bar-horizontal-stacked`}
              data={{
                key: `${firstValue.label}-${secondValue.label}`,
                [firstValue.label]: values[firstValue.label].bar,
                [secondValue.label]: values[secondValue.label].bar
              }}
            />
          </Chart>
        </StyledChartHolder>
        {/* {
                    !!showHelp && helpId &&
                    <StyledHelpButtonWrapper>
                        <HelpTooltipButton
                            id={helpId}
                            anchorType={'iconButton'}
                        />
                    </StyledHelpButtonWrapper>
                } */}
      </ContentRow>
    </StyledWrapper>
  )
};

export default BarSlide;
