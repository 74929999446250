import { TransformFunction, TransformOptionsBase } from '../transform-utils';
import * as allFormatters from '../../../utils/formatters';

interface RD {
  waarde: number;
  label_1: string;
  label_2?: string;
  label_3?: string;
  label_4: string;
  eenheid: string;
}

export interface ColumnChartStacked100PercentRawData {
  [key: string]: RD;
}

export interface ColumnChartStacked100PercentXThreshold {
  label: string;
  columnKey: string;
  columnIndex: number;
}

export interface ColumnChartStacked100PercentTransformOptions extends TransformOptionsBase {
  reverse?: boolean;
  /**
   * Set the key to use for the x axis.
   * @default label_2
   */
  xLabelKey?: 'label_1' | 'label_2' | 'label_3';
  /**
   * Set the key to use for the label.
   * @default label_1
   */
  labelKey?: 'label_1' | 'label_2' | 'label_3';

  remainingValueLabel?: string;
  /**
   * Append the threshold value to the xLabel (if there is a threshold).
   * For example xLabel is 'Augustus' and threshold is '2020', xLabel will be Augustus 2020
   * @default true
  */
  appendThresholdToXLabel?: boolean;
}


export interface ColumnChartStacked100PercentDataModel {
  key: string;
  label: string;
  xLabel: string;
  /**
   * Keys that correspond to the keys in rawValues and formattedValues.
   */
  valueKeys: string[];
  shortXLabel?: string;
  rawValues: { [key: string]: number };
  formattedValues: { [key: string]: string };
}

export interface ColumnChartStacked100PercentColumnDataModel {
  columnsModel: ColumnChartStacked100PercentDataModel[];
  xThreshold?: ColumnChartStacked100PercentXThreshold[];
}

const findXThreshold = (columns: ColumnChartStacked100PercentDataModel[]): ColumnChartStacked100PercentXThreshold[] => {
  let previousLabel = '';
  const xTresholdArr: ColumnChartStacked100PercentXThreshold[] = [];

  columns.forEach((column, index) => {
    if (column.label !== previousLabel && index !== 0) {
      xTresholdArr.push({
        columnIndex: index,
        columnKey: column.key,
        label: column.label
      })
    }
    previousLabel = column.label;

  });

  return xTresholdArr;
}

const columnChartRemainder100PercentTransformer: TransformFunction<ColumnChartStacked100PercentColumnDataModel, ColumnChartStacked100PercentTransformOptions> = (
  rawData: ColumnChartStacked100PercentRawData,
  onError,
  options
) => {

  const keys = Object.keys(rawData);
  const guard = !keys.some((key) => rawData[key].waarde !== null);

  if (guard) {
    onError('nodata');
    return null;
  }

  const {
    reverse = false,
    formatters = {},
    formattersOptions = {},
    xLabelKey = 'label_2',
    labelKey = 'label_1',
    remainingValueLabel = ' ',
    appendThresholdToXLabel = true
  } = options || {} as ColumnChartStacked100PercentTransformOptions;

  const valueFormatterName = formatters['value'];
  const valueFormatter = (allFormatters as any)[valueFormatterName];
  const valueFormatterOptions = formattersOptions['value'];

  const xLabelFormatterName: string = formatters['xLabel'];
  const xLabelFormatter = (allFormatters as any)[xLabelFormatterName];
  const xLabelFormatterOptions = formattersOptions['xLabel'];

  const shortXLabelFormatterName: string = formatters['shortXLabel'];
  const shortXLabelFormatter = (allFormatters as any)[shortXLabelFormatterName];
  const shortXFormatterOptions = formattersOptions['shortXLabel'];

  const chartModel: ColumnChartStacked100PercentDataModel[] = [];

  keys.forEach((rawDataKey: string, index: number) => {
    const rawDataItem = rawData[rawDataKey];
    const { label_1, label_4, waarde, eenheid } = rawDataItem;
    const remainingValue = 100 - waarde;

    let xLabel = xLabelFormatter ? xLabelFormatter(rawDataItem[xLabelKey] as string, xLabelFormatterOptions) : rawDataItem[xLabelKey] as string;
    if (!!label_1 && appendThresholdToXLabel) {
      xLabel = `${xLabel} ${label_1}`;
    }

    chartModel.push({
      key: `${rawDataItem[xLabelKey]}-${index}`,
      label: rawDataItem[labelKey] as string,
      xLabel: xLabel,
      shortXLabel: xLabelFormatter ? shortXLabelFormatter(rawDataItem[xLabelKey] as string, shortXFormatterOptions) : rawDataItem[xLabelKey] as string,
      valueKeys: [label_4, remainingValueLabel],
      rawValues: {
        [label_4]: waarde,
        [remainingValueLabel]: remainingValue
      },
      formattedValues: {
        [label_4]: valueFormatter ?
          valueFormatter(waarde, valueFormatterOptions) :
          allFormatters.formatValue(waarde, eenheid as ValueUnit, valueFormatterOptions),
        [remainingValueLabel]: valueFormatter ?
          valueFormatter(remainingValue, valueFormatterOptions) :
          allFormatters.formatValue(remainingValue, eenheid as ValueUnit, valueFormatterOptions)
      }
    });
  });

  let columnCharDataModel: ColumnChartStacked100PercentDataModel[] = [];
  columnCharDataModel = reverse ? chartModel.reverse() : chartModel;

  const xThreshold = findXThreshold(columnCharDataModel);

  return { columnsModel: columnCharDataModel, xThreshold };
};

export default columnChartRemainder100PercentTransformer;