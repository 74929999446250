export const bubbleMapDefaultPoints = [
    {
      key: 'AD00000',
      x: 230,
      y: 240
    },
    {
      key: 'DH00000',
      x: 170,
      y: 280
    },
    {
      key: 'OB00000',
      x: 310,
      y: 400
    },
    {
      key: 'ON00000',
      x: 390,
      y: 250
    },
    {
      key: 'LB00000',
      x: 350,
      y: 500
    },
    {
      key: 'ZB00000',
      x: 120,
      y: 420
    },
    {
      key: 'MD00000',
      x: 280,
      y: 290
    },
    {
      key: 'RT00000',
      x: 190,
      y: 340
    },
    {
      key: 'NH00000',
      x: 220,
      y: 150
    },
    {
      key: 'NN00000',
      x: 400,
      y: 100
    }
  ];