import * as H from 'history';

// TODO: gewoon in global store zetten zodat het voor elke layout component beschikbaar is?
const getQueryParams = (location: H.Location) => {
  // TODO: gebruik new urlqueryparams gebruiken? 
  let urlQueryParamValues = {} as { [key: string]: string };
  const urlQueryParams = location.search.split('&');

  urlQueryParams.forEach((keyValuePair) => {
    const splitted = keyValuePair.split('=');
    const key = splitted[0].startsWith('?') ? splitted[0].substring(1).toLowerCase() : splitted[0].toLowerCase();
    const value = splitted[1];

    urlQueryParamValues[key] = value;
    if (value == null) {
      urlQueryParamValues[key] = 'true';
    }
  });

  return urlQueryParamValues;
};

const createLinkedTemplateTiles = (templateTiles: API_GET.TemplateTileDetail[]) => {
  const linkedTemplateTiles = [] as API_GET.TemplateTileDetail[][];

  if (!!templateTiles) {
    // TODO: nu kun je dus alleen een linkedtileid op level 1 hebben (enabledForOELevel === 1)?
    templateTiles
      .filter(({ enabled, tile }) => enabled === true && (!tile.enabledForOELevel || tile.enabledForOELevel === 1))
      .forEach((templateTile) => {
        const { tile } = templateTile;
        const tiles = [] as API_GET.TemplateTileDetail[];
        tiles.push(templateTile);

        if (!!tile.linkedTileId) {
          const linkedTile = templateTiles.find(({ tileId }) => tileId === tile.linkedTileId);

          if (!!linkedTile) {
            tiles.push(linkedTile);
          }
        }

        linkedTemplateTiles.push(tiles);
      });
  }

  return linkedTemplateTiles;
};

export { getQueryParams, createLinkedTemplateTiles };