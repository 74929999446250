import { Tooltip } from 'ppd-library/charts/organisms/Tooltip';
import { scales } from 'ppd-library/charts/chartUtils';
import { AxisLeft, AxisBottom } from 'ppd-library/charts/organisms/Axis';
import { HorizontalGridLines } from 'ppd-library/charts/organisms/GridLines';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { HelpTooltipButton } from '../../help/HelpTooltipButton';
import Chart from 'ppd-library/charts/Chart';
import { ComponentBaseProps } from '../ComponentBaseProps';
import { ColumnChartDataModel, ColumnChartColumnDataModel } from './columnChartTransformer';
import { BarVertical } from 'ppd-library/charts/molecules/BarVertical';
import { LineThresholdVertical } from 'ppd-library/charts/molecules/LineThreshold';
import * as allFormatters from '../../../utils/formatters';

import { getSVGMousePosition, useTooltipPosition, TooltipTemplate } from '../tooltip-utils';
import { Legend, useLegend } from '../legend-utils';

export interface StackedColorProp {
  [key: string]: string;
}
export interface StackedValuesProp {
  [key: string]: string;
}
export interface BarColorsProp {
  [key: string]: string;
}
export interface ColumnChartThemeProps {
  barColors?: BarColorsProp;
  gridColor?: string;
}
export interface ColumnChartProps extends ComponentBaseProps<ColumnChartThemeProps, ColumnChartColumnDataModel> {
  showTooltip?: boolean;
  showThreshold?: boolean;
  barsPadding?: number;
}
const StyledWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const StyledChartHolder = styled.div``;


const ColumnChart = (props: ColumnChartProps) => {
  const {
    id,
    data,
    help,
    showHelp,
    tooltipOptions,

    width = 500,
    height: originalHeight = 450,
    formatters = {},
    legendOptions = {},
    showTooltip = true,
    showThreshold = true,
    themeProperties,
    formattersOptions = {},
    barsPadding = 0.1
  } = props;
  const { margins = { left: 50, right: 0, top: 10, bottom: showThreshold ? 50 : 30 } } = props;

  const [initiated, setInitiated] = useState(false);

  const {
    gridColor = '#bfd1e1',
    barColors = { default: '#2b4899' },
  } = themeProperties || { } as ColumnChartThemeProps;

  const { columnsModel, xThreshold } = data;
  const ticks = columnsModel.map((item) => item.key);

  const yAxisTickFormatterName = formatters['yAxisTick'];
  const yAxisTickFormatter = (allFormatters as any)[yAxisTickFormatterName] || allFormatters.formatNumber;
  const yAxisTickFormatterOptions = formattersOptions['yAxisTick'];


  const getMaxValueFromData = (columnsModel: ColumnChartDataModel[]): number => {
    const acummulatedRawValues: number[] = [];
    columnsModel.forEach((item) => {
      let rawItemValues: number[] = [];

      rawItemValues.push(item.rawValue);

      acummulatedRawValues.push(rawItemValues.reduce((prev, curr) => prev + curr, 0));
      rawItemValues = [];
    });

    const maximumValue = Math.max(...acummulatedRawValues);

    return maximumValue;
  }

  const maxValue = getMaxValueFromData(columnsModel);

  const legendElementRef = React.createRef<HTMLDivElement>();
  const { legendHeight } = useLegend(originalHeight, legendElementRef);
  const height = originalHeight - legendHeight;

  const xScale = scales.stringXScale(ticks, width, margins.left, margins.right, barsPadding);
  const yScale = scales.numericYScale(0, maxValue, height, margins.bottom, margins.top).nice();
  const xScaleTickOffset = xScale.bandwidth() / 2;
  const colorScale = scales.colorScale(barColors);

  let tooltipLeaveTimeoutId = 0;
  const handleMouseMove = (event: React.MouseEvent<Element, MouseEvent>, index: number, data: ColumnChartDataModel) => {
    clearTimeout(tooltipLeaveTimeoutId);
    const { x, y } = getSVGMousePosition(event as React.MouseEvent<SVGElement, MouseEvent>);
    setTooltipPosition({ visible: true, x, y, index, data });
  };

  const handleMouseLeave = () => {
    clearTimeout(tooltipLeaveTimeoutId);
    tooltipLeaveTimeoutId = setTimeout(() => {
      setTooltipPosition({ ...tooltipPosition, visible: false });
    }, 250);
  };

  const { tooltipPosition, setTooltipPosition } = useTooltipPosition<ColumnChartDataModel>();

  useEffect(() => {
    setInitiated(true);
  }, []);


  return (
    <StyledWrapper>
      <StyledChartHolder>
        <Chart height={height} width={width}>
          <HorizontalGridLines
            id={`${id}-horizontal-grid-lines`}
            scale={yScale}
            theme={{ color: gridColor }}
            x={margins.left}
            lineLength={width - margins.left - margins.right}
          />

          {columnsModel.map((columnData, columnIndex) => {
            const { key, rawValue, valueKey } = columnData;
            
            return (
              <g
                key={key}
                onMouseMove={(ev) => handleMouseMove(ev, columnIndex, columnData)}
                onMouseLeave={handleMouseLeave}
              >
                <BarVertical
                  fill={colorScale(valueKey)}
                  key={`${id}-${key}`}
                  width={xScale.bandwidth()}
                  x={xScale(key) as number}
                  id={`${id}-bar-vertical-${columnIndex}`}
                  y={initiated ? yScale(rawValue) : yScale(0)}
                  height={initiated ? yScale(0) - yScale(rawValue) : 0}
                />
              </g>
            )
          }
          )}
          {!!showThreshold && !!xThreshold &&
            xThreshold.map((item) => {
              return (
                <LineThresholdVertical
                  lineWidth={1}
                  animate={false}
                  showCircle={false}
                  label={item.label}
                  key={`${id}-${item.label}`}
                  labelPadding={[2, 4, 2, 4]}
                  labelPos={['left', 'bottom']}
                  theme={{ textSize: '0.8rem' }}
                  lineHeight={margins.bottom - 20}
                  y={height - (margins.bottom) + 10}
                  x={initiated ? xScale(item.columnKey) : 0}
                  id={`${id}-line-vertical-threshold-${item.label}`}
                />
              );
            })
          }
          <AxisLeft
            scale={yScale}
            x={margins.left}
            id={`${id}-axis-left`}
            tickFormat={(value) => {
              if (!!yAxisTickFormatter) {
                return yAxisTickFormatter(value, yAxisTickFormatterOptions);
              }
              return value;
            }}
          />
          <AxisBottom
            scale={xScale}
            id={`${id}-axis-bottom`}
            y={-margins.bottom}
            chartHeight={height}
            tickOffset={xScaleTickOffset}
            tickFormat={(_value, i) => `${columnsModel[i].shortXLabel}`}
          />
        </Chart>
        <Tooltip
          top={tooltipPosition.y}
          left={tooltipPosition.x}
          visible={showTooltip && tooltipPosition.visible}
          theme={{
            transition: false
          }}
        >
          {() => {
            const {
              label,
              xLabel,
              valueKey,
              formattedValue
            } = tooltipPosition.data as ColumnChartDataModel;

            return (
              <TooltipTemplate
                title={`${xLabel}`}
                tooltipOptions={tooltipOptions}
                values={[{
                  label,
                  formattedValue,
                  colors: [colorScale(valueKey)]
                }]}
              />
            );
          }}
        </Tooltip>
        <Legend
        ref={legendElementRef}
        legendOptions={{orientation: 'horizontal', ...legendOptions}}
        afterLegendContent={
          <>
            {!!showHelp && !!help && help.some(({ position }) => position === 99) &&
              <HelpTooltipButton
                anchorType={'iconButton'}
                helpId={(help.find(({ position }) => position === 99) as { helpId: number }).helpId}
              />

            }
          </>
        }
      />
      </StyledChartHolder>
    </StyledWrapper>
  )
};

export default ColumnChart;