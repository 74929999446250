export const ViewTypes = {
  tileview: 1 as API.IViewTypes.tileview,
  labelview: 2 as API.IViewTypes.labelview,
  mapview: 3 as API.IViewTypes.mapview,
  percentageview : 4 as API.IViewTypes.percentageview,
  tableview : 5 as API.IViewTypes.tableview,
  textview : 6 as API.IViewTypes.textview,
  chartview : 7 as API.IViewTypes.chartview,
  valutaview : 8 as API.IViewTypes.valutaview,
  bartrendview : 9 as API.IViewTypes.bartrendview,
  trendview : 10 as API.IViewTypes.bartrendview
};

export const DataKeyTypes = {
  oe: 1 as API.IDataKeyTypes.oe,
  workarea: 2 as API.IDataKeyTypes.workarea
};

