import { httpPost } from './utils/http';
import { canUseLocalStorage } from './utils';

export interface LoggingConfig {
  /** Usage log interval milliseconds */
  interval: number;
  API_URL: string;
  CALL_TIMEOUT: number;
}

export interface UsageLog {
  kpi: string;
  deviceType: 'v2';
  unitCode: string;
  type: 'appInit' | 'kpiChange';

  /** Optional type specific properties */
  typeContent?: any;
  /** Datetime of the client. will be autofilled/overwritten by LoggingService. */
  localDateTime?: string;
}
export interface SaveUsageLog extends UsageLog {
  // userGuid is not stored on the server side, but used for calculating if it is a unique user and feature click this date.
  userGuid: string;
}

export interface GetUsageLog extends UsageLog {
  serverDateTime: string;
  // boolean that indicates if it is a unique feature click per user this date, according to serverDateTime.
  isUniqueClickThisDay: boolean;
  // boolean that indicates if it is a unique user this date, according to serverDateTime.
  isUniqueUserThisDay: boolean;
  // boolean that indicates if it is a unique feature click per user this week, according to serverDateTime.
  isUniqueClickThisWeek: boolean;
  // boolean that indicates if it is a unique user this week, according to serverDateTime.
  isUniqueUserThisWeek: boolean;
  // boolean that indicates if it is a unique feature click per user this month, according to serverDateTime.
  isUniqueClickThisMonth: boolean;
  // boolean that indicates if it is a unique user this month, according to serverDateTime.
  isUniqueUserThisMonth: boolean;
}

/**
 * Collects all data and logs user interaction with the app to the back-end of v1.
 */
class LoggingServiceClass {
  private static MAX_ERROR_COUNT = 5;
  // usageLogCache and timeOut will be null until startSendingUsageLog is called
  private timeOut: number = -1;
  private usageLogCache: SaveUsageLog[] | null = null;
  private usageLogErrorCount = 0;
  private uniqueGuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';

  private config: LoggingConfig = {} as LoggingConfig;

  logUsage(log: SaveUsageLog) {
    if (this.usageLogCache) {
      log.localDateTime = new Date().toUTCString();
      log.userGuid = this.uniqueGuid;
      this.usageLogCache.push(log);
    }
  }

  // TODO: copied from https://www.w3resource.com/javascript-exercises/javascript-math-exercise-23.php
  createUUID() {
    let dt = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      // eslint-disable-next-line
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
  }

  /**
   * start sending logs to the back-end
   * @param interval 
   */
  startSendingUsageLog(config: LoggingConfig) {
    if (canUseLocalStorage()) {
      let guid = localStorage.getItem('loggingGuid');
      if (!guid) {
        guid = this.createUUID();
        localStorage.setItem('loggingGuid', guid);
        this.uniqueGuid = guid;
      }
      this.uniqueGuid = guid;
    } else {
      const newGuid = this.createUUID();
      this.uniqueGuid = newGuid;
    }

    this.config = config;
    this.usageLogCache = [];
    this.timeOut = setTimeout(() => this.send(), this.config.interval);
    console.info('LoggingService has started sending usage logs.');
  }

  /**
   * stop sending logs to the back-end.
   * Note! this will clear any locally cached logs that have not been send to the back-end yet.
   * @param flush send remaining logs before clearing local cache.
   */
  stopSendingUsageLog(flush = false) {
    clearTimeout(this.timeOut as number);

    if (flush) {
      this.send(false);
    }

    this.usageLogCache = null;
    console.info('LoggingService has stopped sending usage logs.');
  }

  private send(renewTimeOut = true) {
    if (renewTimeOut) {
      clearTimeout(this.timeOut as number);
    }

    const length = (this.usageLogCache as []).length;
    if (length > 0) {

      httpPost(`${this.config.API_URL}/logging`, this.usageLogCache, {
        withCredentials: true
      }).then((_response) => {
        // Remove only the items that have been send, because one or more loggings could have been added while we were sending to the back-end.
        this.usageLogErrorCount = 0;
        (this.usageLogCache as []).splice(0, length);
        if (renewTimeOut) {
          this.timeOut = setTimeout(() => this.send(), this.config.interval);
        }
      }).catch((_error) => {
        // O o, what now? try again later or stop after MAX_ERROR_COUNT...
        if (this.usageLogErrorCount > LoggingServiceClass.MAX_ERROR_COUNT) {
          this.stopSendingUsageLog(false);
        } else {
          this.usageLogErrorCount++;
          if (renewTimeOut) {
            this.timeOut = setTimeout(() => this.send(), this.config.interval);
          }
        }
      });
    } else if (renewTimeOut) {
      this.timeOut = setTimeout(() => this.send(), this.config.interval);
    }
  }
}

/**
 * Export an instance of the DataTransformationServiceClass and expose it as a 'singleton'.
 */
export const LoggingService = new LoggingServiceClass();
