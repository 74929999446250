import { Layout } from 'react-grid-layout';

import {
  GridBreakpoint,
  GridLayoutBreakpoints,
  GridLayouts,
} from '../../components/layout/GridLayout';
import muiTheme from '../../constants/theme';

const viewportBreakpoints: GridLayoutBreakpoints = {
  xlg2: 2560, xlg1: 1910, xlg: 1600, lg: 1280, md: 960, sm: 600, xs: 480//, base: 0
};

const columnsPerBreakpoint: GridLayoutBreakpoints = {
  xlg2: 40, xlg1: 30, xlg: 24, lg: 12, md: 12, sm: 8, xs: 1 //, base: 1
};

const rowHeight = 60;


/**
* Horizontal margins differ for Mobile / Desktop.
* NOTE: Since the user is viewing the app on Mobile or Desktop, this is sufficient.
* During development, a refresh of the page is important if the switch is made between mobile and desktop
*/
let isSmallerThanLg = window.innerWidth < muiTheme.breakpoints.width('lg');
const leftMargin = muiTheme.spacing(isSmallerThanLg ? 0 : 9) + muiTheme.spacing(isSmallerThanLg ? 1 : 8);  /* menu width (72px) + padding (64px) */
const rightMargin = muiTheme.spacing(isSmallerThanLg ? 1 : 8) + 15; /* padding (64px) + scrollbar width*/
let containerBreakpoints = {} as GridLayoutBreakpoints;

// Need to substract left and right margins/padding because  react-grid-layout expects breakpoints based on the parent container, not the whole screen
Object.keys(viewportBreakpoints).forEach((bp) => {
  const vpBreakpoint = viewportBreakpoints[bp];
  containerBreakpoints[bp] = vpBreakpoint > 0 ? vpBreakpoint - (leftMargin + rightMargin) - 1 : 0
});

const getContainerWidth = () => {
  // NOTE: window.innerWidth could include/exclude the vertical scrollbar depending on the browser
  const viewportWidth = window.innerWidth;
  const containerWidth = viewportWidth - (leftMargin + rightMargin);

  return containerWidth;
};

/**
* Calculate the initial breakpoint on component init / 'componentMount'.
* NOTE: would be nice if react-grid-layout would supply this 'onInit' but no bueno...
*/
const calculateInitialBreakpoint = () => {
  const containerWidth = getContainerWidth();

  let breakpoint: GridBreakpoint = 'xs';
  Object.keys(containerBreakpoints).sort((a, b) => {
    //NOTE: sorting makes sure this function keeps working if breakpoints are added / removed
    const bpA = containerBreakpoints[a];
    const bpB = containerBreakpoints[b];
    return bpA < bpB ? -1 : 1;
  }).forEach((key) => {
    const containerBreakpoint = containerBreakpoints[key];

    if (containerWidth >= containerBreakpoint) {
      breakpoint = key as GridBreakpoint;
    }
  });

  return breakpoint;
};


const getTileDimensions = (ref: React.RefObject<HTMLDivElement>) => {
  if (!!ref.current) {
    const rect = ref.current.getBoundingClientRect();

    return {
      width: rect.width,
      height: rect.height
    };
  }

  return {
    width: 0,
    height: 0
  };
}

const createGridLayouts = (templateLayouts: API_GET.TemplateLayout[]) => {
  const gridLayouts = templateLayouts.reduce((previous: GridLayouts, layout: API_GET.TemplateLayout) => {
    const { layoutSize, items } = layout;
    return {
      ...previous,
      [layoutSize]: items.map(({ templateTileId, ...restProps }) => ({ i: `${templateTileId}`, ...restProps }))
    };
  }, {} as GridLayouts);

  return gridLayouts;
};

/**
 * Update all items of a template layout based on (new) grid layout items
 * @param gridLayout The grid layout items that contain the values to copy to currentTemplateLayout
 * @param currentTemplateLayout The current state of the templateLayout
 */
const createUpdatedTemplateLayout = (gridLayout: Layout[], currentTemplateLayout: API_GET.TemplateLayout) => {
  const newItems = gridLayout.map((layoutItem) => {
    const { i, x, y, w, h } = layoutItem;
    const currentTemplateLayoutItem = currentTemplateLayout.items.find(({ templateTileId }) => `${templateTileId}` === i) as API_GET.TemplateLayoutItem;

    return { ...currentTemplateLayoutItem, x, y, w, h } as API_GET.TemplateLayoutItem;
  });

  return { ...currentTemplateLayout, items: newItems } as API_GET.TemplateLayout;
}

/**
  * returns a new tileContentDimensions object with updated dimensions for a single layout.
  * // TODO: properties aanpassen, hebt alleen juiste div element nodig ipv layout en gridtilewrappersref 
  * @param layout that contains the key (templateTileId / i) of the tile for which to create dimensions
  * @param currentDimensions object that contains the current dimensions of ALL visible tiles
  * @param gridTileWrappersRef holds the container/wrapper elements of all the tiles, using 'templateTileId' as key.
  */
const createTileDimensions = (
  layout: Layout,
  currentDimensions: { [key: string]: { width: number; height: number } },
  gridTileWrappersRef: React.MutableRefObject<{ [key: string]: React.RefObject<HTMLDivElement> }>
) => {
  let dimensions = { width: 0, height: 0 };
  const key = layout.i;
  const ref = gridTileWrappersRef.current[key];

  if (!!ref) {
    dimensions = getTileDimensions(ref);
  }

  const clone = { ...currentDimensions, [key]: dimensions };

  return clone;
}

/**
  * returns a new tileContentDimensions object with updated dimensions for a single layout.
  * // TODO: properties aanpassen, hebt alleen juiste div element nodig ipv layout en gridtilewrappersref
  * @param layout that contains the key (templateTileId / i) of the tile for which to create dimensions
  * @param currentDimensions object that contains the current dimensions of ALL visible tiles
  * @param gridTileWrappersRef holds the container/wrapper elements of all the tiles, using 'templateTileId' as key.
  */
const createAllTileDimensions = (
  layouts: Layout[],
  currentDimensions: { [key: string]: { width: number; height: number } },
  gridTileWrappersRef: React.MutableRefObject<{ [key: string]: React.RefObject<HTMLDivElement> }>
) => {
  const newDimensions = layouts.reduce((dimensions, layout) => {
    return createTileDimensions(layout, dimensions, gridTileWrappersRef);
  }, currentDimensions);

  return newDimensions;
};

/**
 * Take a (current) breakpoint,
 * find the current active layout,
 * update the correct templateLayout ref,
 * generate new gridLayouts and update the gridLayoutsRef
 * @param breakpoint 
 * @param gridLayoutsRef 
 * @param templateLayoutsRef 
 * @returns the updated templateLayout index that can be used to retreive it from templateLayoutsRef
 */
const syncCurrentLayout = (
  breakpoint: GridBreakpoint,
  gridLayoutsRef: React.MutableRefObject<GridLayouts>,
  templateLayoutsRef: React.MutableRefObject<API_GET.TemplateLayout[]>
) => {
  const layout = gridLayoutsRef.current[breakpoint];
  return syncLayout(layout, breakpoint, gridLayoutsRef, templateLayoutsRef);
};

/**
 * Take a (updated) layout and (current) breakpoint,
 * update the correct templateLayout ref,
 * generate new gridLayouts and update the gridLayoutsRef
 * @param layout
 * @param breakpoint
 * @param gridLayoutsRef
 * @param templateLayoutsRef
 * @returns the updated templateLayout index that can be used to retreive it from templateLayoutsRef 
 */
const syncLayout = (
  layout: Layout[],
  breakpoint: GridBreakpoint,
  gridLayoutsRef: React.MutableRefObject<GridLayouts>,
  templateLayoutsRef: React.MutableRefObject<API_GET.TemplateLayout[]>
  ) => {
  
  const currentTemplateLayoutIndex = templateLayoutsRef.current.findIndex(({ layoutSize }) => layoutSize === breakpoint);
  const currentTemplateLayout = templateLayoutsRef.current[currentTemplateLayoutIndex] as API_GET.TemplateLayout;
  const updatedTemplateLayout = createUpdatedTemplateLayout(layout, currentTemplateLayout);

  templateLayoutsRef.current[currentTemplateLayoutIndex] = updatedTemplateLayout;
  const newGridLayouts = createGridLayouts(templateLayoutsRef.current);
  gridLayoutsRef.current = newGridLayouts;

  console.log(templateLayoutsRef.current[currentTemplateLayoutIndex]);

  return currentTemplateLayoutIndex;
};

/**
 * Should only be called inside a resize handler function bound to a GridLayout onResize event.
 * // TODO: refactor, dit kan anders
 * @param newLayoutItem The grid layout item / tile that has been resized.
 * @param resizeTimeout a timeout handle/id that needs to be cleared.
 * @param tileDimensions the width and height of all the tiles that are currently visible, using 'templateTileId' as key. Will be updated.
 * @param gridTileWrappersRef holds the container/wrapper elements of all the tiles, using 'templateTileId' as key.
 * @param setTileDimensions the setter used to update tileDimensions.
 * @returns a new resizeTimeout handle/id
 */
const handleTileResize = (
  newLayoutItem: Layout,
  resizeTimeout: number,
  tileDimensions: { [key: string]: { width: number; height: number; } },
  gridTileWrappersRef: React.MutableRefObject<{ [key: string]: React.RefObject<HTMLDivElement> }>,
  setTileDimensions: React.Dispatch<React.SetStateAction<{ [key: string]: { width: number; height: number; } }>>
) => {
  clearTimeout(resizeTimeout);
  return setTimeout(() => {
    const newDimensions = createTileDimensions(newLayoutItem, tileDimensions, gridTileWrappersRef);
    setTileDimensions(newDimensions);
  }, 10);
};

export {
  // consts
  viewportBreakpoints, columnsPerBreakpoint, rowHeight, containerBreakpoints,
  // function
  getContainerWidth, calculateInitialBreakpoint, getTileDimensions,
  createGridLayouts, createTileDimensions,
  handleTileResize,
  createUpdatedTemplateLayout, createAllTileDimensions,
  syncLayout, syncCurrentLayout
};