import React, { useEffect, useState } from 'react';

import MuiTable from '@material-ui/core/Table';
import MuiTableBody from '@material-ui/core/TableBody';
import MuiTableHead from '@material-ui/core/TableHead';
import MuiTableRow from '@material-ui/core/TableRow';
import MuiTableCell, { TableCellProps as MuiTableCellProps } from '@material-ui/core/TableCell';
import muiTheme from '../../../constants/theme';

import MuiTypography, { TypographyProps as MuiTypographyProps } from '@material-ui/core/Typography';
import { scaleLinear } from 'd3';
import { Chart } from 'ppd-library/charts';
import { BarHorizontal } from 'ppd-library/charts/molecules/BarHorizontal';
import { Tooltip } from 'ppd-library/charts/organisms/Tooltip';
import styled from 'styled-components';

import { getSVGMousePosition, useTooltipPosition, TooltipTemplate } from '../tooltip-utils';
import { HelpTooltipButton } from '../../help/HelpTooltipButton';
import { BarChartCategorySingleDataModel, BarChartCategorySingleGroupModel } from './barChartCategorySingleTransformer';
import { ComponentBaseProps } from '../ComponentBaseProps';
import { pxToRem } from '../../../utils/converters';

export interface BarChartCategorySingleThemeProps {
  offsetLeft?: number;
  offsetRight?: number;
  barHeight?: number;
  /**
   * @default #2b4899
   */
  barColor?: string;
  /**
   * Color by bar index (key is index).
   * Every bar index not supplied will fall back on barColor.
   */
  barColors?: { [key: string]: string };
  /**
   * Hide the header of column after the bar, zero indexed.
   * Counting INCLUDES the mainValueColumn, even if it is hidden.
   */
  hideColumnHeaders?: number | number[];
  /**
   * Set a column after the bar to primary color. Zero indexed.
   * Counting INCLUDES the mainValueColumn, even if it is hidden.
   */
  primaryColorColumns?: number | number[];
}

export interface BarChartCategorySingleProps extends ComponentBaseProps<BarChartCategorySingleThemeProps, BarChartCategorySingleDataModel> {
  showTooltip?: boolean;
  /**
   * Show the column that is also used to draw the bar
   * @default true
   */
  showMainValueColumn?: boolean;
  /**
   * @default true
   */
  showMainValueColumnHeader?: boolean;
  /**
   * Show the value of the bar underneath the groupLabel
   * @default false
   */
  showBarValue?: boolean;
  /**
   * If all the the values of the bars can be added up to 100%,
   * this should be true.
   * @default false
   */
  absoluteBars?: boolean;
}
const StyledMuiTypography = styled(MuiTypography)`
  && {
    margin-left: ${pxToRem(8)}
  }
  ` as React.ComponentType<MuiTypographyProps>;

const StyledWrapper = styled.div`
  position: relative;
`;

const StyledLabelHolder = styled.div<{ width: number }>`
  width: ${({ width }) => pxToRem(width)};
  display: inline-block;
`;

const StyledMuiTable = styled(({ width, ...muiTableProps }) => <MuiTable {...muiTableProps} />)`
  && {
    width: ${({ width }) => pxToRem(width)};
  }
  ` as React.ComponentType<{ width: number; } & MuiTableCellProps>;


const createEmptyGroupData = (group: BarChartCategorySingleGroupModel) => {
  return {
    ...group,
    bar: { ...group.bar, rawValue: 0 }
  };
}

const tableHeaderleftPadding = muiTheme.spacing(1);
const StyledMuiTableHeader = styled(MuiTableCell)`
  && {
    padding-left: ${tableHeaderleftPadding}px;
    padding-right: 0;
    position: relative
  }
` as React.ComponentType<MuiTableCellProps>;

const StyledMuiTableCell = styled(MuiTableCell)`
  && {
    padding-left: 0;
    padding-right: 0;
    position: relative
  }
` as React.ComponentType<MuiTableCellProps>;

const StyledHelpTooltipButton = styled(HelpTooltipButton)`
  && { 
    position: absolute;
    left:0;
    margin-top: -4px;
  }
`;
const StyledHeaderHelpTooltipButton = styled(HelpTooltipButton)`
  && { 
    position: absolute;
    left: -24px;
    margin-top: -3px;
  }
`;


// TODO: rename?
const BarChartCategorySingle = (props: BarChartCategorySingleProps) => {
  const {
    id,
    data,
    help,
    showHelp,
    tooltipOptions,
    width = 400,
    showTooltip = true,
    showBarValue = false,
    absoluteBars = false,
    showMainValueColumn = true,
    showMainValueColumnHeader = true,
    themeProperties
  } = props;

  const height = 20;
  const {
    barColors = {},
    barHeight = 12,
    offsetLeft = 200,
    offsetRight = 230,
    barColor = '#2b4899',
    hideColumnHeaders: originalHideColumnHeaders,
    primaryColorColumns: originalPrimaryColorColumns,
  } = themeProperties || {} as BarChartCategorySingleThemeProps;

  const hideColumnHeaders = !!originalHideColumnHeaders ?
    Array.isArray(originalHideColumnHeaders) ? originalHideColumnHeaders : [originalHideColumnHeaders] :
    [];

  const primaryColorColumns = !!originalPrimaryColorColumns ?
    Array.isArray(originalPrimaryColorColumns) ? originalPrimaryColorColumns : [originalPrimaryColorColumns] :
    [];

  const { groups } = data;
  const mainValueColumn = groups[0].bar.correspondingColumn;
  const [initiated, setInitiated] = useState(false);

  const handleMouseMove = (event: React.MouseEvent<Element, MouseEvent>, index: number, data: BarChartCategorySingleGroupModel) => {
    const { x, y } = getSVGMousePosition(event as React.MouseEvent<SVGElement, MouseEvent>);
    setTooltipPosition({ visible: true, x, y, index, data });
  };

  const handleMouseLeave = () => {
    setTooltipPosition({ ...tooltipPosition, visible: false });
  };

  const { tooltipPosition, setTooltipPosition } = useTooltipPosition<BarChartCategorySingleGroupModel>();

  const getMaxValueFromData = () => {
    const allRawValues = groups.map((group) => group.bar.rawValue);

    return Math.max(...allRawValues);
  };
  const rawMaxValue = absoluteBars ? 100 : getMaxValueFromData();

  const [chartWidth, setChartWidth] = useState(0);
  const xScale = scaleLinear().domain([0, rawMaxValue]).range([0, chartWidth]);

  useEffect(() => {
    setTimeout(() => {
      setInitiated(true);
    }, 0);

    setChartWidth(width - (offsetLeft + offsetRight));
  }, [width, offsetLeft, offsetRight]);

  return (
    <>
      <div style={{ height: '100%', overflowX: 'hidden'}}>
        <StyledWrapper>
          <StyledMuiTable width={width}>
            <MuiTableHead>
              <MuiTableRow>
                <StyledMuiTableHeader colSpan={3}></StyledMuiTableHeader>
                {groups[0].columns.map(({ label }, columnIndex) => {
                  const showColumn = showMainValueColumn || columnIndex !== mainValueColumn;
                  let showColumnHeader = showMainValueColumnHeader || columnIndex !== mainValueColumn;
                  showColumnHeader = showColumnHeader && !hideColumnHeaders.some((ci) => ci === columnIndex);
                  return showColumn ?
                    (<StyledMuiTableHeader align={'right'} key={`${id}-${label}-${columnIndex}-header`}>
                      {!!showHelp && !!help && columnIndex === 1 && help.some(({ position }) => position === -1) &&
                        <StyledHeaderHelpTooltipButton
                          anchorType={'iconButton'}
                          helpId={(help.find(({ position }) => position === -1) as { helpId: number }).helpId}
                        />
                      }
                      {showColumnHeader ? label : ''}
                    </StyledMuiTableHeader>)
                    : null
                })}
              </MuiTableRow>
            </MuiTableHead>

            <MuiTableBody>
              {
                groups.map((originalGroup, i: number) => {
                  const group = initiated ? originalGroup : createEmptyGroupData(originalGroup);
                  const {
                    bar,
                    columns,
                    groupKey,
                    groupLabel,
                  } = group;

                  return (
                    <MuiTableRow key={`row-${groupKey}`}>
                      <StyledMuiTableCell align={'right'}>
                        <StyledLabelHolder width={offsetLeft}>
                          {!!showHelp && !!help && help.some(({ position }) => position === i + 1) &&
                            <StyledHelpTooltipButton
                              anchorType={'iconButton'}
                              helpId={(help.find(({ position }) => position === i + 1) as { helpId: number }).helpId}
                            />
                          }
                          <MuiTypography
                            variant={'body2'}
                            component={'span'}
                            color={'secondary'}
                          >
                            {groupLabel}
                          </MuiTypography>
                          {showBarValue &&
                            <MuiTypography
                              variant={'body2'}
                              color={'primary'}
                            >
                              {bar.formattedValue}
                            </MuiTypography>
                          }
                        </StyledLabelHolder>
                      </StyledMuiTableCell>
                      <StyledMuiTableCell>
                        {chartWidth > 0 &&
                          <Chart
                            height={height}
                            width={chartWidth}
                            preserveAspectRatio={'none'}
                          >
                            <g
                              onMouseLeave={handleMouseLeave}
                              transform={`translate(10, ${0})`}
                              onMouseMove={(ev: any) => handleMouseMove(ev, i, groups[i])}
                            >
                              {
                                <BarHorizontal
                                  x={0}
                                  height={barHeight}
                                  y={(barHeight / 2) * 1}
                                  id={`${id}-${groupKey}-${'bar'}`}
                                  key={`${id}-${groupKey}-${'bar'}`}
                                  fill={barColors[`${i}`] || barColor}
                                  width={initiated ? xScale(bar.rawValue) : 0}
                                />
                              }
                            </g>
                          </Chart>
                        }
                        <Tooltip
                          top={tooltipPosition.y}
                          left={tooltipPosition.x}
                          calculateOffsetPosition={false}
                          visible={showTooltip && tooltipPosition.visible && tooltipPosition.index === i}
                          theme={{
                            transition: false
                          }}
                        >
                          {() => {
                            const {
                              columns,
                              groupLabel
                            } = tooltipPosition.data as BarChartCategorySingleGroupModel;

                            return (
                              <TooltipTemplate
                                title={groupLabel}
                                tooltipOptions={{ ...tooltipOptions, hideColors: true }}
                                values={
                                  columns.map(({ label, formattedValue }) => {
                                    return {
                                      label,
                                      formattedValue,
                                      colors: ['transparent']
                                    };
                                  })
                                }
                              />
                            );
                          }}
                        </Tooltip>
                      </StyledMuiTableCell>
                      <StyledMuiTableCell>
                        {/** TODO: waarom hebben we een lege kolom nodig? */}
                      </StyledMuiTableCell>

                      {columns.map(({ label, formattedValue }, columnIndex) =>
                        (showMainValueColumn || columnIndex !== mainValueColumn) &&
                        <StyledMuiTableCell align={'right'} key={`${id}-${label}-${columnIndex}`}>
                          <StyledMuiTypography
                            component={'span'}
                            variant={'body2'}
                            color={
                              bar.correspondingColumn === columnIndex || primaryColorColumns.some((ci) => ci === columnIndex) ?
                                'primary' : 'textSecondary'
                            }
                          >
                            {formattedValue}
                          </StyledMuiTypography>
                        </StyledMuiTableCell>
                      )}
                    </MuiTableRow>
                  );
                })
              }
            </MuiTableBody>
          </StyledMuiTable>
        </StyledWrapper>
      </div>
    </>
  )
};

export default BarChartCategorySingle;
