import { TransformFunction, TransformOptionsBase } from '../transform-utils';
import * as allFormatters from '../../../utils/formatters';

interface RD {
  waarde: number;
  label_1: string;
  label_2?: string;
  label_3?: string;
  label_4: string;
  eenheid: string;
}

export interface ColumnChartRemainderListRawData {
  [key: string]: RD;
}

export interface ColumnChartRemainderListXThreshold {
  label: string;
  groupKey: string;
  groupIndex: number;
}

export interface ColumnChartRemainderListTransformOptions extends TransformOptionsBase {
  reverse?: boolean;
  /**
   * Set the key to use for the x axis.
   * @default label_1
   */
  xLabelKey?: 'label_1' | 'label_2' | 'label_3';
    /**
  * Append the threshold value to the xLabel (if there is a threshold).
  * For example xLabel is 'Augustus' and threshold is '2020', xLabel will be Augustus 2020
  * @default false
  */
 appendThresholdToXLabel?: boolean;
}

export interface ColumnChartRemainderGroupModel {
  key: string;
  label: string;
  xLabel: string;
  /**
   * Keys that correspond to the keys in rawValues and formattedValues.
   */
  valueKeys: string[];
  shortXLabel?: string;
  rawValues: { [key: string]: number };
  formattedValues: { [key: string]: string };
}

export interface ColumnChartRemainderDataModel {
  groups: ColumnChartRemainderGroupModel[];
  xThreshold?: ColumnChartRemainderListXThreshold;
}

const findXThreshold = (xThresholdKeys: string[], groupModels: ColumnChartRemainderGroupModel[] ) => {

  let xThreshold: ColumnChartRemainderListXThreshold | undefined = undefined;
  let currentThresholdLabel = '';
  xThresholdKeys.forEach((xThresholdKey, index) => {

    if (currentThresholdLabel !== xThresholdKey) {
      currentThresholdLabel = xThresholdKey;
      xThreshold = {
        groupIndex: index,
        groupKey: groupModels[index].key,
        label: currentThresholdLabel
      };
    }
  });

  return xThreshold;
};


const columnChartRemainderListTransformer: TransformFunction<ColumnChartRemainderDataModel, ColumnChartRemainderListTransformOptions> = (
  rawData: ColumnChartRemainderListRawData,
  onError,
  options
) => {

  const keys = Object.keys(rawData);
  const guard = !keys.some((key) => rawData[key].waarde !== null);

  if (guard) {
    onError('nodata');
    return null;
  }

  const {
    reverse = false,
    formatters = {},
    formattersOptions = {},
    xLabelKey = 'label_2',
    appendThresholdToXLabel = false
  } = options || {} as ColumnChartRemainderListTransformOptions;

  const valueFormatterName = formatters['value'];
  const valueFormatter = (allFormatters as any)[valueFormatterName];
  const valueFormatterOptions = formattersOptions['value'];

  const xLabelFormatterName: string = formatters['xLabel'];
  const xLabelFormatter = (allFormatters as any)[xLabelFormatterName];
  const xLabelFormatterOptions = formattersOptions['xLabel'];

  const shortXLabelFormatterName: string = formatters['shortXLabel'];
  const shortXLabelFormatter = (allFormatters as any)[shortXLabelFormatterName];
  const shortXFormatterOptions = formattersOptions['shortXLabel'];

  const labelFormatterName: string = formatters['label'];
  const labelFormatter = (allFormatters as any)[labelFormatterName];
  const labelFormatterOptions = formattersOptions['label'];

  let groupModels: ColumnChartRemainderGroupModel[] = [];
  const xThresholdKeys:string[] = [];

  keys.forEach((rawDataKey: string) => {
    const rawDataItem = rawData[rawDataKey];
    const { label_1, label_4, waarde, eenheid } = rawDataItem;

    const groupKey = `${rawDataItem[xLabelKey]}`;

    // Try to find the existing groupModel by xLabelKey
    const groupIndex = groupModels.findIndex((item) => item.key === groupKey);

    let xLabel = xLabelFormatter ? xLabelFormatter(rawDataItem[xLabelKey] as string, xLabelFormatterOptions) : rawDataItem[xLabelKey] as string;
    if (!!label_1 && appendThresholdToXLabel) {
      xLabel = `${xLabel} ${label_1}`;
    }

    // If it does not exist, create it
    if (groupIndex < 0) {
      
      groupModels.push({
        key: groupKey,
        label: labelFormatter ? labelFormatter(label_4, labelFormatterOptions) : label_4,
        xLabel: xLabel,
        shortXLabel: xLabelFormatter ? shortXLabelFormatter(rawDataItem[xLabelKey] as string, shortXFormatterOptions) : rawDataItem[xLabelKey] as string,
        // the keys that are used in rawValues and formattedValues
        valueKeys: [label_1],
        // This will for example result in: { "2019": 1234, "2020": 4567 }
        rawValues: {
          [label_1]: waarde
        },
        // This will for example result in: { "2019": 1.234, "2020": 4.567 }
        formattedValues: {
          [label_1]: valueFormatter ?
            valueFormatter(waarde, valueFormatterOptions) :
            allFormatters.formatValue(waarde, eenheid as ValueUnit, valueFormatterOptions)
        }
      });

      reverse ? xThresholdKeys.unshift(label_1) : xThresholdKeys.push(label_1);
    }
    // else add rawDataItem value and formatted value to the groupModel
    else {
      const groupModel = groupModels[groupIndex];
      groupModel.rawValues[label_1] = waarde;
      groupModel.formattedValues[label_1] = valueFormatter ?
        valueFormatter(waarde, valueFormatterOptions) :
        allFormatters.formatValue(waarde, eenheid as ValueUnit, valueFormatterOptions);

       //NOTE: order is important in valueKeys.
       reverse ? groupModel.valueKeys.unshift(label_1) : groupModel.valueKeys.push(label_1);
    }
  });

  groupModels = reverse ? groupModels.reverse() : groupModels;
  let xThreshold = findXThreshold(xThresholdKeys, groupModels);

  return { groups: groupModels, xThreshold };
};

export default columnChartRemainderListTransformer;
