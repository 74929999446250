
import { TransformFunction, TransformOptionsBase } from './transform-utils';
import { BarChart, barChartTransformer } from './BarChart';
import { BarChartCategory, barChartCategoryTransformer } from './BarChartCategory';
import { BarChartDiverging, barChartDivergingTransformer } from './BarChartDiverging';
import { BarChartCategorySingle, barChartCategorySingleTransformer } from './BarChartCategorySingle';
import { BarChartInBarLabelingMain, barChartInBarLabelingMainTransformer} from './BarChartInBarLabelingMain';
import { BarSlideMain, barSlideMainTransformer } from './BarSlideMain';
import { BarSlide, barSlideTransformer } from './BarSlide';
import { BubbleMap, bubbleMapTransformer } from './BubbleMap';
import { LineChart, lineChartTransformer, LineChartAggregated, lineChartAggregatedTransformer } from './LineChart';
import { SimpleTable, simpleTableTransformer } from './SimpleTable';
import { SingleValue, singleValueTransformer } from './SingleValue';
import { ThreeValues, threeValuesTransformer } from './ThreeValues';
import { TwoValues, twoValuesTransformer } from './TwoValues';
import { DoubleSlideBars, doubleSlideBarsTransformer } from './DoubleSlideBars';
import { DoubleSlideBarsRemainder, doubleSlideBarsRemainderTransformer } from './DoubleSlideBarsRemainder';
import { DoubleStackedLabeledBar, doubleStackedLabeledBarTransformer } from './DoubleStackedLabeledBar';
import { ColumnChartRemainder, columnChartRemainderTransformer } from './ColumnChartRemainder';
import { ColumnChartRemainderGroup, columnChartRemainderGroupTransformer, columnChartRemainderListTransformer } from './ColumnChartRemainderGroup';
import { ColumnChartDiverging, columnChartDivergingTransformer } from './ColumnChartDiverging';
import { TableLeveled, tableLeveledTransformer } from './TableLeveled';
import { ColumnChartDeviation, columnChartDeviationListTransformer, columnChartDeviationGroupTransformer } from './ColumnChartDeviation';
import { ColumnChart, columnChartTransformer } from './ColumnChart';
import { ColumnChartStacked, columnChartStackedTransformer, columnChartStacked100PercentTransformer } from './ColumnChartStacked';
import { ValuesGradeMeter, valuesGradeMeterTransformer } from './ValuesGradeMeter';
import { DoubleStackedLabeledBarMain, doubleStackedLabeledBarMainTransformer } from './DoubleStackedLabeledBarMain';
import { StackedLinedLabeledBar, stackedLinedLabeledBarTransformer } from './StackedLinedLabeledBar';
import { StackedLinedLabeledBarDouble, stackedLinedLabeledBarDoubleTransformer } from './StackedLinedLabeledBarDouble';
import { ValuesRelative, valuesRelativeTransformer } from './ValuesRelative';
import { TimeLineDumbbell, timeLineDumbbellTransformer } from './TimeLineDumbbell';
import { BarChartWaterfall, barChartWaterfallTransformer } from './BarChartWaterfall';
import { WaffleChart, waffleChartTransformer } from './WaffleChart';
import { ValueRows, valueRowsTransformer } from './ValueRows';
import { ParetoChart, paretoChartTransformer } from './ParetoChart';
import { ParetoChartGroupedRow, paretoChartGroupedRowTransformer } from './ParetoChartGroupedRow';
import { DistributionChart, distributionChartTransformer } from './DistributionChart';
import { SimpleTextBlock, simpleTextBlockTransformer } from './SimpleTextBlock';
import { ContentCover, contentCoverTransformer } from './ContentCover';



// TODO: dit moet uiteindelijk lazy loaded worden en dan heb je geen mapping meer nodig
// map component.name (found in view) to a component and the corresponding transformer

export interface MappedContent {
  ContentComponent: React.ComponentType<any>; // TODO: is dit basecomponentprops?
  transformer: TransformFunction<any, TransformOptionsBase>; // TODO: kloppen deze type params?
  unitCompareTransformer?: TransformFunction<any, TransformOptionsBase>; // TODO: kloppen deze type params?
}

interface ContentMapping {
  [key: string]: MappedContent;
}

// export interface MappedContent<TComponentProps, TTransformedData, TTransformerOptions> {
//   ContentComponent: React.ComponentType<TComponentProps>;
//   transformer: TransformFunction<TTransformedData, TTransformerOptions>;
// }

// interface ContentMapping<TData, TOptions> {
//   [key: string]: MappedContent<TData, TOptions>;
// }

// NOTE: the keys/names of this mapping are also used in the UnitComparePage and UnitCompareSettings
const mapping: ContentMapping = {
  'singleValue': {
    ContentComponent: SingleValue,
    transformer: singleValueTransformer
  },
  'twoValues': {
    ContentComponent: TwoValues,
    transformer: twoValuesTransformer
  },
  'threeValues': {
    ContentComponent: ThreeValues,
    transformer: threeValuesTransformer
  },
  'lineChart': {
    ContentComponent: LineChart,
    transformer: lineChartTransformer
  },
  'lineChartAggregated': {
    ContentComponent: LineChartAggregated,
    transformer: lineChartAggregatedTransformer
  },
  'barSlide': {
    ContentComponent: BarSlide,
    transformer: barSlideTransformer
  },
  'barSlideMain': {
    ContentComponent: BarSlideMain,
    transformer: barSlideMainTransformer
  },
  'barChart': {
    ContentComponent: BarChart,
    transformer: barChartTransformer
  },
  // 'barChartInBarLabeling': {
  //   ContentComponent: HorizontalLabeledBars,
  //   transformer: horizontalLabeledBarsTransformer
  // },
  'barChartInBarLabelingMain': {
    ContentComponent: BarChartInBarLabelingMain,
    transformer: barChartInBarLabelingMainTransformer
  },
  'barChartCategory': {
    ContentComponent: BarChartCategory,
    transformer: barChartCategoryTransformer
  },
  'barChartCategorySingle': {
    ContentComponent: BarChartCategorySingle,
    transformer: barChartCategorySingleTransformer
  },
  'barChartDiverging': {
    ContentComponent: BarChartDiverging,
    transformer: barChartDivergingTransformer
  },
  'bubbleMap': {
    ContentComponent: BubbleMap,
    transformer: bubbleMapTransformer
  },
  'simpleTable': {
    ContentComponent: SimpleTable,
    transformer: simpleTableTransformer
  },
  'doubleSlideBars': {
    ContentComponent: DoubleSlideBars,
    transformer: doubleSlideBarsTransformer
  },
  'doubleSlideBarsRemainder': {
    ContentComponent: DoubleSlideBarsRemainder,
    transformer: doubleSlideBarsRemainderTransformer
  },
  'doubleStackedLabeledBar': {
    ContentComponent: DoubleStackedLabeledBar,
    transformer: doubleStackedLabeledBarTransformer
  },
  'doubleStackedLabeledBarMain': {
    ContentComponent: DoubleStackedLabeledBarMain,
    transformer: doubleStackedLabeledBarMainTransformer
  },
  'columnChart': {
    ContentComponent: ColumnChart,
    transformer: columnChartTransformer
  },
  'columnChartStacked': {
    ContentComponent: ColumnChartStacked,
    transformer: columnChartStackedTransformer
  },
  'columnChartRemainder': {
    ContentComponent: ColumnChartRemainder,
    transformer: columnChartRemainderTransformer
  },
  'columnChartStacked100%': {
    ContentComponent: ColumnChartStacked,
    transformer: columnChartStacked100PercentTransformer
  },
  'columnChartRemainderGroup': { // Note: the data is grouped for this variant
    ContentComponent: ColumnChartRemainderGroup,
    transformer: columnChartRemainderGroupTransformer
  },
  'columnChartRemainderGroupList': { // Note: the raw data is a list for this variant
    ContentComponent: ColumnChartRemainderGroup,
    transformer: columnChartRemainderListTransformer
  },
  'columnChartDiverging': {
    ContentComponent: ColumnChartDiverging,
    transformer: columnChartDivergingTransformer
  },
  'columnChartDeviationList': {
    ContentComponent: ColumnChartDeviation,
    transformer: columnChartDeviationListTransformer
  },
  'columnChartDeviationGroup': {
    ContentComponent: ColumnChartDeviation,
    transformer: columnChartDeviationGroupTransformer
  },

  'tableLeveled': {
    ContentComponent: TableLeveled,
    transformer: tableLeveledTransformer
  },
  'valuesGradeMeter': {
    ContentComponent: ValuesGradeMeter,
    transformer: valuesGradeMeterTransformer
  },
  'stackedLinedLabeledBar': {
    ContentComponent: StackedLinedLabeledBar,
    transformer: stackedLinedLabeledBarTransformer
  },
  'stackedLinedLabeledBarDouble': {
    ContentComponent: StackedLinedLabeledBarDouble,
    transformer: stackedLinedLabeledBarDoubleTransformer
  },
  'valuesRelative': {
    ContentComponent: ValuesRelative,
    transformer: valuesRelativeTransformer,
    unitCompareTransformer: simpleTableTransformer
  },
  'timeLineDumbbell': {
    ContentComponent: TimeLineDumbbell,
    transformer: timeLineDumbbellTransformer
  },
  'barChartWaterfall': {
    ContentComponent: BarChartWaterfall,
    transformer: barChartWaterfallTransformer
  },
  'waffleChart': {
    ContentComponent: WaffleChart,
    transformer: waffleChartTransformer
  },
  'valueRows': {
    ContentComponent: ValueRows,
    transformer: valueRowsTransformer
  },
  'paretoChart': {
    ContentComponent: ParetoChart,
    transformer: paretoChartTransformer
  },
  'paretoChartGroupedRow': {
    ContentComponent: ParetoChartGroupedRow,
    transformer: paretoChartGroupedRowTransformer
  },
  'distributionChart': {
    ContentComponent: DistributionChart,
    transformer: distributionChartTransformer
  },
  'simpleTextBlock': {
    ContentComponent: SimpleTextBlock,
    transformer: simpleTextBlockTransformer
  },
  'contentCover': {
    ContentComponent: ContentCover,
    transformer: contentCoverTransformer
  }
};

export default mapping;