import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@material-ui/core/AppBar';
import MuiButton, { ButtonProps as MuiButtonProps } from '@material-ui/core/Button';
import MuiIconButton, { IconButtonProps as MuiIconButtonProps } from '@material-ui/core/IconButton';
import { PaletteColor } from '@material-ui/core/styles/createPalette';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import MuiToolbar, { ToolbarProps as MuiToolbarProps } from '@material-ui/core/Toolbar';
import MuiTypography, { TypographyProps as MuiTypographyProps } from '@material-ui/core/Typography';
import MuiAccountCircleIcon from '@material-ui/icons/AccountCircle';
import MuiHelpIcon from '@material-ui/icons/Help';
import MuiHelpIconMobile from '@material-ui/icons/HelpRounded';
import MuiMenuIconRounded from '@material-ui/icons/MenuRounded';
import MuiNotificationsIcon from '@material-ui/icons/Notifications';
import MuiNotificationsIconMobile from '@material-ui/icons/NotificationsRounded';
import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import styled from 'styled-components';

import { MediaDevices } from '../../../App';
import muiTheme from '../../../constants/theme';
import palette from '../../../constants/theme/palette';
import { pxToRem } from '../../../utils/converters';

// import MuiSlide from '@material-ui/core/Slide';
// import useScrollTrigger from '@material-ui/core/useScrollTrigger';
export interface ToolBarProps {
  displayName: string;
  helpActive: boolean;
  // settingsActive: boolean;
  // notificationsActive: boolean;
  menuOpen: boolean;
  menuOpenWidth: number;
  menuOpenSpeed: number;
  mediaDevices: MediaDevices;
  dashBoardEditEnabled: boolean;
  /**
   * Fired when a user clicks on the user/profile/settings iconbutton.
   */
  onAccountClick: () => void;
  /**
   * Fired when a user clicks on the help iconbutton.
   */
  onHelpClick: () => void;
  /**
   * Fired when a user clicks on the notifications iconbutton.
   */
  onNotificationsClick: () => void;
  /**
   * Fired when a user clicks on the menu button iconbutton. (Button visibility mobile only)
   */
  onMenuButtonClick: () => void;
  /**
   * Fired when a user clicks on the introduction iconbutton. (Button visibility mobile only)
   * This will start the PPD Onboarding wizzard
   */
  onOnboardingClick: () => void;
}

const StyledMuiAppBar = styled(MuiAppBar)`
  && {
    background-color: #FAFAFA;
  }
` as React.ComponentType<MuiAppBarProps>;

const StyledMuiToolbar = styled(({ isMobile, ...muiButtonProps }) => <MuiToolbar {...muiButtonProps} />)`
  && {
    display: flex;
    justify-content: space-between;
    padding-left: 0;
    padding-right: ${({ isMobile }) => !!isMobile ? '0' : `0.75rem`}; ;
  }
` as React.ComponentType<{ isMobile: boolean; } & MuiToolbarProps>;


const StyledMuiTitle = styled(({ isMobile, menuOpen, menuOpenWidth, menuOpenSpeed, ...muiTypoProps }) => <MuiTypography {...muiTypoProps} />)`
  && {
    display: flex;
    justify-content: space-between;
    position: ${({ isMobile }) => !!isMobile && 'absolute'};
    left: ${({ isMobile }) => !!isMobile && '4rem'};
    margin-left: ${({ isMobile }) => !!isMobile ? '0' : `8.5rem`};
    transition: ${({ menuOpenSpeed, isMobile }) => !isMobile && `transform ${menuOpenSpeed}ms ease-in-out;`};
    transform: ${({ menuOpen, menuOpenWidth, isMobile }) => !isMobile && `translateX(${menuOpen ? pxToRem(menuOpenWidth - 100) : 0})`};
  }
`as React.ComponentType<{ isMobile: boolean; menuOpen: boolean; menuOpenWidth: number; menuOpenSpeed: number; } & MuiTypographyProps>;


const StyledNavLink = styled(NavLink)`
  display: flex;
  && {
    font-size: 1.25rem;
    color: ${(palette.primary as PaletteColor).contrastText};
    text-decoration: none;
    align-items: flex-start;
  }
` as React.ComponentType<NavLinkProps>

const StyledMuiButton = styled(({ active, ...muiButtonProps }) => <MuiButton {...muiButtonProps} />)`
  && {
    text-transform: initial;
    color: ${({ active }) => !!active ? palette.colors.black['800'] : '#2b4899'};
  }
` as React.ComponentType<{ active?: boolean } & MuiButtonProps>;


const StyledMuiHelpIcon = styled(({ active, ...muiIconProps }) => <MuiHelpIcon {...muiIconProps} />)`
  color: ${({ active }) => active ? palette.colors.gold.main : null};
` as React.ComponentType<{ active: boolean; } & SvgIconProps>;

const StyledMuiHelpIconButton = styled(({ active, ...muiIconProps }) => <MuiIconButton {...muiIconProps} />)`
  &&& {
    color: ${({ active }) => active ? palette.colors.gold.main : '#2b4899'};
  }
` as React.ComponentType<{ active: boolean; } & SvgIconProps>;

const StyledMuiIconButton = styled(MuiIconButton)`
&&& {
  color:  #2b4899;
  }
`as React.ComponentType<MuiIconButtonProps>;

const StyledMuiMenuButton = styled(MuiIconButton)`
  &&& {
    color:  #2b4899;
    margin-left: ${muiTheme.spacing(1) / 16}rem;
    }
`as React.ComponentType<MuiIconButtonProps>;


const StyledAppLogo = styled(({ color, isMobilePhone, ...spanProps }) => <span {...spanProps} />)`
  display: inline-block;
  margin-right:  ${({ isMobilePhone }) => `${pxToRem(isMobilePhone ? 4 : 10)}`};
  color: ${({ color }) => `${color}`};
 
  font-size: ${({ isMobilePhone }) => `${pxToRem(isMobilePhone ? 19 : 30)}`}; /* TODO: fontsize 19?? */
  font-weight: 600;
`as React.ComponentType<{ color: string; isMobilePhone: boolean } & React.HTMLProps<HTMLSpanElement>>;

const StyledButtonsWrapper = styled.div``;

const ToolBar = (props: ToolBarProps) => {
  const {
    helpActive,
    // settingsActive,
    // notificationsActive,
    menuOpen,
    displayName,
    menuOpenWidth,
    menuOpenSpeed,
    mediaDevices,
    onHelpClick,
    onMenuButtonClick
  } = props;

  const isMobile = mediaDevices.mobile;
  const isMobilePhone = mediaDevices.mobilePhone;
  // const isNoMobilePhone = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) > 700;

  // TODO: on mobile (< 600px), wrap in hide on scroll
  return (
    // <HideOnScroll>
    <StyledMuiAppBar elevation={0}>
      <StyledMuiToolbar isMobile={isMobile}>
        {!!isMobile &&
          <StyledMuiMenuButton
            aria-label={'Menu'}
            onClick={onMenuButtonClick}
          >
            <MuiMenuIconRounded />
          </StyledMuiMenuButton>
        }
        <StyledMuiTitle
          noWrap
          variant='h5'
          menuOpen={menuOpen}
          isMobile={isMobile}
          title={'Dashboard demo'}
          menuOpenWidth={menuOpenWidth}
          menuOpenSpeed={menuOpenSpeed}
        >
          <StyledNavLink to={`/kpis/overzicht`}>
            {/* TODO: kleiner dan 600px --> PPD ? */}
            <StyledAppLogo color={'#2b4899'} isMobilePhone={isMobilePhone}>Dashboarding as a Service (demo)</StyledAppLogo>
          </StyledNavLink>
        </StyledMuiTitle>
        <StyledButtonsWrapper>
          {!isMobile &&
            <StyledMuiButton
              active={helpActive}
              onClick={onHelpClick}
              startIcon={<StyledMuiHelpIcon active={helpActive} />}
              aria-label={'Help'}
            >
              Help
            </StyledMuiButton>
          }
          {!!isMobile &&
            <StyledMuiHelpIconButton
              aria-label={'Help'}
              active={helpActive}
              onClick={onHelpClick}
            >
              <MuiHelpIconMobile />
            </StyledMuiHelpIconButton>
          }

          {!isMobile &&
            <StyledMuiButton
              startIcon={<MuiNotificationsIcon />}
              aria-label={'Notificaties'}
            >
              Notificaties
          </StyledMuiButton>
          }
          {!!isMobile &&
            <StyledMuiIconButton
              aria-label={'Notificaties'}
            >
              <MuiNotificationsIconMobile />
            </StyledMuiIconButton>
          }
          <StyledMuiButton
            aria-label={'Account'}
            startIcon={<MuiAccountCircleIcon />}
          >
            {displayName}
          </StyledMuiButton>
        </StyledButtonsWrapper>
      </StyledMuiToolbar>
    </StyledMuiAppBar>
    // </HideOnScroll>
  );
}

export default ToolBar;