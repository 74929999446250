// import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import { TypographyOptions } from '@material-ui/core/styles/createTypography';
import boldEot from './fonts/bold.eot';
import boldTtf from './fonts/bold.ttf';
import boldWoff from './fonts/bold.woff';
import regularEot from './fonts/regular.eot';
import regularTtf from './fonts/regular.ttf';
import regularWoff from './fonts/regular.woff';
import iconEot from './fonts/icons.eot';
import iconTtf from './fonts/icons.ttf';
import iconWoff from './fonts/icons.woff';
import palette from './palette';
import { PaletteColor } from '@material-ui/core/styles/createPalette';

export const fonts = {
  regular: {
    fontFamily: 'regular',
    src: `
        url(${regularEot}),
        url(${regularWoff}) format('woff'),
        url(${regularTtf}) format('truetype');
        `,
    fontWeight: 'normal',
    fontStyle: 'normal'
  },
  bold: {
    fontFamily: 'bold',
    src: `
        url(${boldEot}),
        url(${boldWoff}) format('woff'),
        url(${boldTtf}) format('truetype');
        `,
    fontWeight: 400,
    fontStyle: 'normal'
  },
  icon: {
    fontFamily: 'icons',
    src: `
      url(${iconEot}), 
      url(${iconWoff}) format('woff'),
      url(${iconTtf}) format('truetype');
      `,

    speak: 'none',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontVariant: 'normal',
    textTransform: 'none',
    lineHeight: 1,
    /* Enable Ligatures ================ */
    letterSpacing: 0,
    fontFeatureSettings: '"liga"', /* Support for IE. */
    fontVariantLigatures: 'discretionary-ligatures',

    /* Better Font Rendering =========== */
    webkitFontSmoothing: 'antialiased',
    mozOsxFontSmoothing: 'grayscale'
  }
};

// WITH POLITIE SANS, ALSO SEE GLOBALS '@font-face'
const typography: TypographyOptions & { fonts: { regular: any; bold: any; icon: any; } } = {
  fonts,
  fontFamily: '"regular"',
  htmlFontSize: 16,
  fontSize: 16,
  h1: {
    lineHeight: 1.4,
    color: (palette.primary as PaletteColor).main,
    fontSize: '6rem',
    fontWeight: 600,
    fontFamily: '"bold"'
  },
  h2: {
    color: (palette.secondary as PaletteColor).dark,
    fontFamily: '"bold"',
    fontWeight: 600,
    fontSize: '1rem',
    lineHeight: 1.4
  },
  h3: {
    fontFamily: '"regular"',
    fontSize: '1.25em',
    lineHeight: 1.4,
    fontWeight: 400,
    color: (palette.primary as PaletteColor).main
  },
  h4: {
    fontWeight: 400,
    color: (palette.primary as PaletteColor).main,
    lineHeight: 1.4,
    fontSize: '3em',
    fontFamily: '"bold"',
  },
  // h5: { 
  //   fontFamily: '"bold"'
  // },
  h6: {
    fontSize: '1.25rem',
    color: '#0C2497',
    fontWeight: 400,
    fontFamily: '"bold"',
  },
  subtitle1: {
    fontFamily: '"regular"',
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: 1.4,
    letterSpacing: '0.00938em',
    color: (palette.secondary as PaletteColor).light,
  },
  caption: {
    fontFamily: '"regular"',
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: 1.04,
    color: (palette.secondary as PaletteColor).light,
  },
  button: {
    fontFamily: '"regular"',
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: 1.75,
    textTransform: 'uppercase',
    color: (palette.primary as PaletteColor).main,
  }
};

export default typography;