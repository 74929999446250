import MuiEqualizerIcon from '@material-ui/icons/Equalizer';
import MuiListIcon from '@material-ui/icons/List';
import MuiSubjectIcon from '@material-ui/icons/Subject';
import MuiTimeLineIcon from '@material-ui/icons/Timeline';
import MuiMapIcon from '@material-ui/icons/Map';
import MuiAssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import React, { ReactElement } from 'react';

import PercentageSVGIcon from '../../content/PercentageSVGIcon';

export const defaultViewTypeIcons: {
  type: string;
  tooltip: string;
  iconLigature?: string;
  iconSvg?: ReactElement;
}[] = [
    {
      type: 'tileview',
      iconLigature: 'zoekenopnummer', // TODO: svg
      tooltip: 'Aantallen'
    },
    {
      type: 'mapview',
      iconSvg: <MuiMapIcon />,
      tooltip: 'Kaartweergave'
    },
    {
      type: 'chartview',
      iconSvg: <MuiEqualizerIcon />,
      tooltip: 'Grafiekweergave'
    },
    {
      type: 'tableview',
      iconSvg: <MuiListIcon />,
      tooltip: 'Tabelweergave'
    },
    {
      type: 'trendview',
      iconSvg: <MuiTimeLineIcon />,
      tooltip: 'Lijntrend'
    },
    {
      type: 'valutaview',
      iconLigature: 'euro_symbol', // TODO: svg
      tooltip: 'Bedragen'
    },
    {
      type: 'bartrendview',
      iconSvg: <MuiAssessmentOutlinedIcon />,
      tooltip: 'Bartrend'
    },
    {
      type: 'percentageview',
      iconSvg: <PercentageSVGIcon />,
      tooltip: 'Percentages'
    },
    {
      type: 'textview',
      iconSvg: <MuiSubjectIcon />,
      tooltip: 'Tekstweergave'
    }
  ];