export default () => {return null;};
// import { Tooltip } from 'ppd-library/charts//organisms/Tooltip';
// import { scales } from 'ppd-library/charts/chartUtils';
// import { AxisLeft, AxisBottom } from 'ppd-library/charts/organisms/Axis';
// import { HorizontalGridLines } from 'ppd-library/charts/organisms/GridLines';
// import { scaleOrdinal } from 'd3';
// import React, { useState, useEffect } from 'react';
// import styled from 'styled-components';

// import Chart from 'ppd-library/charts/Chart';
// // import { HelpTooltipButton } from '../../help/HelpTooltipButton';
// import { ComponentBaseProps } from '../ComponentBaseProps';
// import { ColumnChartDivergingDataModel, ColumnChartRemainderColumnDataModel } from './columnChartDivergingTransformer';
// import { Line } from 'ppd-library/charts//atoms/Line';
// import { BarVerticalStacked } from 'ppd-library/charts/organisms/BarVerticalStacked';
// import { LineThresholdVertical } from 'ppd-library/charts/molecules/LineThreshold';
// import { pxToRem } from '../../../utils/converters';

// export interface StackedColorProp {
//   [key: string]: string;
// }

// // import Chart from 'ppd-library/charts/Chart';
// export interface ColumnChartDivergingThemeProps {
//   colors?: string[];
//   zeroLineColor?: string;
//   zeroLineWidth?: number;
// }
// export interface ColumnChartDivergingProps extends ComponentBaseProps<ColumnChartDivergingThemeProps, ColumnChartRemainderColumnDataModel> {
//   showTooltip?: boolean;
//   showThreshold?: boolean;
// }

// const StyledWrapper = styled.div`
//   position: relative;
//   width: 100%;
// `;

// const StyledChartHolder = styled.div``;

// const StyledHelpButtonWrapper = styled.div`
//   position:absolute;
//   bottom: 0;
//   left: 0.4rem;
// `;

// const StyledLegendColor = styled.span<{ localTheme: { background: string } }>`
//   display: inline-block;
//   width: ${pxToRem(12)};;
//   height: ${pxToRem(12)};
//   margin-right: ${pxToRem(4)};
//   background: ${({ localTheme }) => localTheme.background};
// `;

// // TODO: deze is oud en loopt achter / wordt niet meer gebruikt
// const ColumnChartDiverging = (props: ColumnChartDivergingProps) => {
//   const {
//     id,
//     data,
//     helpId,
//     width = 500,
//     height = 450,
//     showHelp = false,
//     showTooltip = true,
//     showThreshold = true,
//     themeProperties
//   } = props;

//   const { margins = { left: 50, right: 0, top: 10, bottom: showThreshold ? 50 : 30 } } = props;

//   const [initiated, setInitiated] = useState(false);

//   const {
//     zeroLineColor = '#fff',
//     zeroLineWidth = 1,
//     colors = ['#2b4899', 'orange'],
//   } = themeProperties;

//   const {columnsModel, xThreshold} = data;
//   const ticks = columnsModel.map((item) => item.key);

//   const getMaxValueFromData = () => {
//     const minMaxValue: number[] = [];
//     columnsModel.forEach((item) => {
//       item.valueKeys.forEach((key, index) =>  {
//         minMaxValue.push(index === 0 ? item.rawValues[key] : -item.rawValues[key]);
//       });
//     });
//       return {
//         maxValue: Math.max(...minMaxValue),
//         minValue: Math.min(...minMaxValue)
//       };
//   }
//   const { maxValue, minValue } = getMaxValueFromData();

//   const xScale = scales.stringXScale(ticks, width, margins.left, margins.right, 0.1);
//   const yScale = scales.numericYScale(minValue, maxValue, height, margins.bottom, margins.top).nice();
//   const xScaleTickOffset = xScale.bandwidth() / 2;

//   const createEmptyColumnData = (column: ColumnChartDivergingDataModel) => {
//     let rawValues = {} as { [key: string]: number };
//     column.valueKeys.forEach((key) => rawValues[key] = 0);

//     const emptyColumnData = { ...column, rawValues };
//     return emptyColumnData;
//   };


//   const handleMouseMove = function (event: any, columnData: ColumnChartDivergingDataModel, showTooltip: boolean ) {

//     if(!showTooltip) return;

//     // TODO: this is copied from vx, turn into a chart util or something
//     const svgElement = event.target.ownerSVGElement;
//     let { clientX, clientY } = event;

//     // TODO: dit lijkt niet goed te gaan met viewbox...
//     // Hoe moet je de tooltip berekenen als de width en height anders zijn dan de viewbox?
//     let point = svgElement.createSVGPoint();
//         point.x = clientX;
//         point.y = clientY;

//     let ctm = svgElement.getScreenCTM() as DOMMatrix;
//     let inverse = ctm.inverse();
//         point = point.matrixTransform(inverse);

//     setTooltipPosition({ visible: true, left: point.x, top: point.y, data: columnData });
//   };
//   const createTooltip = ( groupData: ColumnChartDivergingDataModel, colors: string[]) => {
//     const { xLabel: title, formattedValues, label, valueKeys } = groupData;
//     const content = valueKeys.map((key, index) => {
//        return (
//         <div key={key}>
//           <StyledLegendColor localTheme={{ background: colors[index] }} />
//           <span>{ key }: </span>
//           <strong>{ formattedValues[key] }</strong>
//         </div>
//        )
//     });
//   return  <><strong style={{display: 'block', marginBottom: '0.3rem'}}>{ title } { label }</strong> { content }</>
//   }

//   const [tooltipPosition, setTooltipPosition] = useState({ visible: false, left: 0, top: 0, data: {} as ColumnChartDivergingDataModel });

//   useEffect(() => {
//     setInitiated(true);
//   }, []);

//   return (
//     <StyledWrapper>
//       <StyledChartHolder>
//         <Chart height={height}>
//           <HorizontalGridLines
//             scale={yScale}
//             x={margins.left}
//             id={`${id}-horizontal-grid-lines`}
//             lineLength={width - margins.left - margins.right}
//           />

//           {columnsModel.map((columnData, i) => {
//             const column = initiated ? columnData : createEmptyColumnData(columnData);
//             const { key, valueKeys, rawValues } = column;
//             const colorScale = scaleOrdinal<string, string>()
//               .domain([...valueKeys])
//               .range([...colors]);

//               return (
//                 <BarVerticalStacked
//                   key={i}
//                   x={xScale(column.key) as number}
//                   width={xScale.bandwidth()}
//                   id={`${id}-column-chart-diverging`}
//                   yScale={yScale}
//                   keys={valueKeys}
//                   colorScale={colorScale}
//                   data={{
//                     key: key,
//                     [valueKeys[0]]: rawValues[valueKeys[0]],
//                     [valueKeys[1]]: -rawValues[valueKeys[1]]
//                   }}
//                   mouseMove={(ev: any) => handleMouseMove(ev, column, showTooltip)}
//                   mouseLeave={() => showTooltip && setTooltipPosition({ ...tooltipPosition, visible: false })}
//                 />
//               )
//             }
//          )}
//           {!!showThreshold && !!xThreshold &&
//             xThreshold.map((item) => {
//               return <LineThresholdVertical
//                 key={`${id}-${item.label}`}
//                 lineWidth={1}
//                 showCircle={false}
//                 label={item.label}
//                 lineHeight={margins.bottom - 20}
//                 id={`${id}-line-vertical-threshold-${item.label}`}
//                 y={height - (margins.bottom ) +10}
//                 labelPadding={[2, 4, 2, 4]}
//                 theme={{ textSize: '0.8rem' }}
//                 x={xScale(item.columnKey)}
//                 labelPos={['left', 'bottom']}
//               />
//             })
//           }
//           <AxisLeft
//             scale={yScale}
//             x={margins.left}
//             id={`${id}-axis-left`}
//             tickFormat={(value) => `${Math.abs(value as number)}`}
//           />
//           <AxisBottom
//             scale={xScale}
//             id={`${id}-axis-bottom`}
//             y={-margins.bottom}
//             chartHeight={height}
//             tickOffset={xScaleTickOffset}
//             tickFormat={(_value, i) => `${columnsModel[i].shortXLabel}`}
//           />
//           <Line
//             width={zeroLineWidth}
//             id={`${id}-line-vertical`}
//             color={zeroLineColor}
//             from={{ x: (margins.left), y: yScale(0)  }}
//             to={{ x: width - margins.right, y: yScale(0) }}
//           />

//       </Chart>
//       {!!tooltipPosition.visible &&
//           <Tooltip
//             top={tooltipPosition.top}
//             left={tooltipPosition.left}
//             theme={{ transition: false }}
//           >
//             {!!tooltipPosition.data && createTooltip(tooltipPosition.data, colors)}
//             {/* TODO: template utils */}
//           </Tooltip>
//         }
//       </StyledChartHolder>
//       {
//         !!showHelp && helpId &&
//         <StyledHelpButtonWrapper>
//           <HelpTooltipButton
//             id={helpId}
//             anchorType={'iconButton'}
//           />
//         </StyledHelpButtonWrapper>
//       }
//     </StyledWrapper>
//   )
// };




// export default ColumnChartDiverging;
