import MuiList from '@material-ui/core/List';
import MuiListItem from '@material-ui/core/ListItem';
import MuiListItemIcon from '@material-ui/core/ListItemIcon';
import MuiListItemText from '@material-ui/core/ListItemText';
import MuiAddToQueueIcon from '@material-ui/icons/AddToQueue';
import MuiHomeIcon from '@material-ui/icons/Home';
import { Modal, ModalHeader } from 'ppd-library/components/organisms/Modal';
import React from 'react';
import styled from 'styled-components';

export interface CustomDashboardsOverviewModalProps {
  isOpen: boolean;
  onClose: () => void;
  templates: API_GET.Template[];
  onTemplateClick: (dashboardId: number) => void;
  // TODO: redux, homepageid komt uit account
  homePageId?: number;
}

const CustomDashboardsOverviewModal = (props: CustomDashboardsOverviewModalProps) => {
  const {
    onClose,
    templates,
    homePageId,
    onTemplateClick,
    isOpen = false
  } = props;

  const StyledContentContainer = styled.div`
    width: 600px;
  `;

  return (
    <Modal
      open={isOpen}
      header={<ModalHeader title={`Toevoegen aan dashboard`} onCancel={onClose} />}
      content={
        <StyledContentContainer>
          <MuiList dense={true}>
            {templates.map((template) => (
              <MuiListItem
                button
                key={`template-${template.id}`}
                onClick={() => onTemplateClick(template.id)}
              >
                <MuiListItemIcon>
                  <MuiAddToQueueIcon color={'primary'} />
                </MuiListItemIcon>
                <MuiListItemText primary={template.name} />

                {!!homePageId && homePageId === template.id &&
                  <MuiHomeIcon titleAccess={'Huidige landingspagina'} color={'secondary'} />
                }
              </MuiListItem>
            ))
            }
          </MuiList>
        </StyledContentContainer>}
    />
  );
};

export default CustomDashboardsOverviewModal;