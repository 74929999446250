import MuiIcon from '@material-ui/core/Icon';
import { TreeDataItem } from 'ppd-library/components/organisms/CheckBoxTree';
import React from 'react';

import { defaultViewTypeIcons } from '../tiles/utils';
import styled from 'styled-components';

export interface TileTreeExtraTreeItemData {
  /*
  * 
  */
  type: 'template' | 'tile' | 'tab' | 'view';
  /**
   * will be assigned when object is a template, tile, tab or view
   */
  template?: API_GET.TemplateDetail;
  /**
  * will be assigned when object is a tile, tab or view
  */
  templateTile?: API_GET.TemplateTileDetail;
  /**
  * will be assigned when object is a tab or view
  */
  tileTab?: API_GET.TabDetail;
  /**
  * will be assigned when object is a view
  */
  tabView?: API_GET.View;
}

export const convertTemplateToTreeDataItem = (template: API_GET.Template) => {
  return {
    // id by itself is not unique in a tree with templates, tiles, tabs and views
    id: `template-${template.id}`,
    label: template.name
  } as TreeDataItem;
};

export const convertTemplatesToTreeDataItems = (templates: API_GET.Template[]) => {
  const dataItems = templates.map(convertTemplateToTreeDataItem);

  return dataItems;
};

export const convertTileToTreeDataItem = (templateTile: API_GET.TemplateTileDetail) => {
  return {
    // id by itself is not unique in a tree with templates, tiles, tabs and views
    id: `tile-${templateTile.tileId}`,
    label: templateTile.tile.title
  } as TreeDataItem;
};

export const convertTilesToTreeDataItems = (tiles: API_GET.TemplateTileDetail[]) => {
  const dataItems = tiles.map(convertTileToTreeDataItem);

  return dataItems;
};

export const convertTabToTreeDataItem = (tab: API_GET.TabDetail) => {
  return {
    // id by itself is not unique in a tree with templates, tiles, tabs and views
    id: `tab-${tab.id}`,
    label: tab.title
  } as TreeDataItem;
};


export const convertTabsToTreeDataItems = (tabs: API_GET.TabDetail[]) => {
  const dataItems = tabs.map(convertTabToTreeDataItem);

  return dataItems;
};

const StyledTreeDataItemIconWrapper = styled.span`
  margin-right: 0.125rem;
`;

export const convertViewToTreeDataItem = (view: API_GET.View) => {
  let treeDataItem = {
    label: '',
    // id by itself is not unique in a tree with templates, tiles, tabs and views
    id: `view-${view.id}`,
  } as TreeDataItem;

  const defaultViewTypeIcon = defaultViewTypeIcons.find(({ type }) => type === view.viewType.name);

  if (defaultViewTypeIcon) {
    treeDataItem.label = defaultViewTypeIcon.tooltip;
    treeDataItem.labelIcon = (
      <StyledTreeDataItemIconWrapper>
        {!!defaultViewTypeIcon.iconSvg &&
          <>
            {defaultViewTypeIcon.iconSvg}
          </>
        }
        {!defaultViewTypeIcon.iconSvg &&
          <MuiIcon>{defaultViewTypeIcon.iconLigature}</MuiIcon>
        }
      </StyledTreeDataItemIconWrapper>
    );
  } else {
    // NOTE!: use the label as back-up if there is no label
    treeDataItem.label = view.viewType.name;
  }

  return treeDataItem;
};

export const convertViewsToTreeDataItems = (views: API_GET.View[]) => {
  const dataItems = views.map(convertViewToTreeDataItem);

  return dataItems;
};