import MuiTable, { TableProps as MuiTableProps } from '@material-ui/core/Table';
import MuiTableBody from '@material-ui/core/TableBody';
import MuiTableCell, { TableCellProps as MuiTableCellProps } from '@material-ui/core/TableCell';
import MuiTableHead from '@material-ui/core/TableHead';
import MuiTableRow, { TableRowProps as MuiTableRowProps } from '@material-ui/core/TableRow';
import React from 'react';
import styled from 'styled-components';

import muiTheme from '../../../constants/theme';
import { ComponentBaseProps } from '../ComponentBaseProps';
import { SimpleTableCellData, SimpleTableDataModel } from './simpleTableTransformer';
import { HelpTooltipButton } from '../../help/HelpTooltipButton';

export interface SimpleTableRowThemeProps {
  /**
   * Make a body row stand out by emphasizing it.
   * @default false
   */
  emphasize?: boolean;
}

export interface SimpleTableColumnThemeProps {
  /**
   * @default false
   */
  whiteSpaceNoWrap?: boolean;
}
export interface SimpleTableThemeProps {
  /**
   * If true, the last row of the table will be styled as a total of the rows above.
   * @default false
   */
  showTotalRow?: boolean;

  rowThemeProperties?: {
    // key is body row number
    [key: string]: SimpleTableRowThemeProps;
  };

  columnThemeProperties?: {
    // key is column number
    [key: string]: SimpleTableColumnThemeProps;
  }
}
export interface SimpleTableProps extends ComponentBaseProps<SimpleTableThemeProps, SimpleTableDataModel> {
  footnote?: string;
  groupMainHeaders?: boolean;
  groupedHeaders?: { columns: number; value: string; emphasize?: boolean }[];
}

const StyledWrapper = styled.div`
  flex: 1;
  height: 100%;
  overflow-x: hidden;
  position: relative;
` as React.ComponentType<React.HTMLProps<HTMLDivElement>>;

const StyledMuiTable = styled(({ helpActive, ...muiTableProps }) => <MuiTable {...muiTableProps} />)`
  margin-top: ${({ helpActive }) => helpActive ? '32px' : null};
` as React.ComponentType<{ helpActive?: boolean; } & MuiTableProps>;

const StyledMuiTableHeader = styled(({ emphasize, borderLeft, ...muiTableCellProps }) => <MuiTableCell {...muiTableCellProps} />)`
  && {
    border-bottom: ${({ emphasize }) => !!emphasize ? `3px solid ${muiTheme.palette.secondary.light}` : null};
    /* TODO: waar komt die E0E0E0 vandaan? mui regelt dat ergens */
    border-left: ${({ borderLeft }) => !!borderLeft ? `1px solid #E0E0E0` : null};
  }
` as React.ComponentType<{ emphasize?: boolean; borderLeft?: boolean; } & MuiTableCellProps>;

const StyledMuiTableBodyRow = styled(({ emphasize, ...muiTableRowProps }) => <MuiTableRow {...muiTableRowProps} />)`
  && {
    font-style: ${({ emphasize }) => !!emphasize ? 'italic' : null};
  }
` as React.ComponentType<{ emphasize?: boolean; } & MuiTableRowProps>;

const StyledMuiTableCell = styled(({ isTotalRow, borderLeft, noWrap, ...muiTableCellProps }) => <MuiTableCell {...muiTableCellProps} />)`
  && {
    white-space: ${({ noWrap }) => !!noWrap ? 'nowrap' : null};
    border-bottom: ${({ isTotalRow }) => isTotalRow ? 0 : null};
    border-top: ${({ isTotalRow }) => !!isTotalRow ? `3px solid ${muiTheme.palette.secondary.light}` : null};
    /* TODO: waar komt die E0E0E0 vandaan? mui regelt dat ergens */
    border-left: ${({ borderLeft }) => !!borderLeft ? `1px solid #E0E0E0` : null};
  }
` as React.ComponentType<{ isTotalRow?: boolean; borderLeft?: boolean; noWrap?: boolean; } & MuiTableCellProps>;

const StyledFootNote = styled.div`
  padding: 1rem 0;
  text-align: right;
` as React.ComponentType<React.HTMLProps<HTMLDivElement>>;

const StyledHeaderHelpTooltipButton = styled(HelpTooltipButton)`
  && { 
    position: absolute;
    margin-left: -32px;
  }
`;
const StyledGroupedHeaderHelpTooltipButton = styled(HelpTooltipButton)`
  && { 
    position: absolute;
  }
`;
const StyledHelpTooltipButton = styled(HelpTooltipButton)`
  && { 
  position: absolute;
  margin-top: -5px;
  }
`;


const isNumericColumn = (rows: SimpleTableCellData[][], colIndex: number) => {
  return rows.some((row) => row[colIndex] && !isNaN(row[colIndex].rawValue as number));
}

const createGroupedMainHeaders = (headers: string[]) => {
  const groupedMainHeaders = headers.reduce((previousResult, currentHeader, startColumnIndex) => {
    const previousHeader = previousResult[previousResult.length - 1];
    const newResult = [...previousResult];

    // when we have a previous header that is the same as current header
    if (!!previousHeader && previousHeader.label === currentHeader) {
      // merge headers and show a border on the left
      newResult[previousResult.length - 1].colspan++;
      newResult[previousResult.length - 1].borderLeft = true;
    } // keep merging until we find a new header    
    else {
      // also show a border on the left, when we find a new header and the previous header has been merged
      const borderLeft = !!previousHeader && previousHeader.colspan > 1;
      newResult.push({ label: currentHeader, colspan: 1, startColumnIndex, borderLeft });
    }

    return newResult;
  }, [] as { label: string; colspan: number; startColumnIndex: number; borderLeft: boolean; }[]);

  return groupedMainHeaders;
}

const SimpleTable = (props: SimpleTableProps) => {
  const {
    /*width, 
    height,*/
    data,
    help,
    showHelp,
    footnote,
    groupedHeaders,
    themeProperties,
    groupMainHeaders = false
  } = props;

  const {
    showTotalRow = false,
    rowThemeProperties = {},
    columnThemeProperties = {}
  } = themeProperties || {} as SimpleTableThemeProps;

  const { rows } = data;
  const headers = rows[0] ? rows[0].map(({ label }) => label) : [];

  const mainHeaders = groupMainHeaders ? createGroupedMainHeaders(headers) :
    headers.map((header, index) => ({ label: header, colspan: 1, startColumnIndex: index, borderLeft: false }));

  return (
    <StyledWrapper>
      <StyledMuiTable helpActive={showHelp} size={'small'}>
        {headers.length > 0 && headers.some((header) => !!header) &&
          <MuiTableHead>
            {!!groupedHeaders &&
              <MuiTableRow>
                {
                  groupedHeaders.map((header, i) => {
                    return (
                      <StyledMuiTableHeader
                        align={'center'}
                        colSpan={header.columns}
                        key={`${header.value}-${i}`}
                        emphasize={header.emphasize}
                      >
                        {header.value}
                        {!!showHelp && !!help && help.some(({ position }) => position === i + 100) &&
                        <StyledGroupedHeaderHelpTooltipButton
                          anchorType={'iconButton'}
                          helpId={(help.find(({ position }) => position === i + 100) as { helpId: number }).helpId}
                        />
                      }
                      </StyledMuiTableHeader>
                    );
                  })
                }
              </MuiTableRow>
            }
            <MuiTableRow>
              {
                mainHeaders.map((header, index) => {
                  const { colspan, borderLeft } = header;
                  const align: 'inherit' | 'center' | 'right' = isNumericColumn(rows, index) ? 'right' : 'inherit';

                  return (
                    <StyledMuiTableHeader
                      align={align}
                      colSpan={colspan}
                      borderLeft={borderLeft}
                      key={`${header.label}-${index}`}
                    >
                      {!!showHelp && !!help && help.some(({ position }) => position === index + 1) &&
                        <StyledHeaderHelpTooltipButton
                          anchorType={'iconButton'}
                          helpId={(help.find(({ position }) => position === index + 1) as { helpId: number }).helpId}
                        />
                      }
                      {header.label}
                    </StyledMuiTableHeader>
                  );
                })
              }
            </MuiTableRow>
          </MuiTableHead>
        }
        <MuiTableBody>
          {
            rows.map((row: SimpleTableCellData[], rowIndex) => (
              <StyledMuiTableBodyRow
                key={`row-${row[0].label || row[0].formattedValue}-${rowIndex}`}
                emphasize={!!rowThemeProperties[`${rowIndex}`] ? rowThemeProperties[`${rowIndex}`].emphasize : false}
              >
                {row.map(({ label, rawValue, formattedValue }, columnIndex) => {
                  const isLastRow = rows.length - 1 === rowIndex;
                  const borderLeft = mainHeaders.some(({ startColumnIndex, borderLeft }) => startColumnIndex === columnIndex && borderLeft);

                  return (
                    <StyledMuiTableCell
                      borderLeft={borderLeft}
                      isTotalRow={showTotalRow && isLastRow}
                      align={!isNaN(rawValue as number) ? 'right' : 'inherit'}
                      key={`row-${label}-${rowIndex}-column-${formattedValue}-${columnIndex}`}
                      noWrap={!!columnThemeProperties[`${columnIndex}`] ? columnThemeProperties[`${columnIndex}`].whiteSpaceNoWrap : false}
                    >
                      {formattedValue}
                      {!!showHelp && !!help && help.some(({ position }) => (position === rowIndex + 21 && columnIndex === 0)) &&
                        <StyledHelpTooltipButton
                          anchorType={'iconButton'}
                          helpId={(help.find(({ position }) => position === rowIndex + 21) as { helpId: number }).helpId}
                        />
                      }
                    </StyledMuiTableCell>
                  );
                })}
              </StyledMuiTableBodyRow>
            ))
          }
        </MuiTableBody>
      </StyledMuiTable>
      {
        footnote && <StyledFootNote>{footnote}</StyledFootNote>
      }
    </StyledWrapper>
  )
}

export default SimpleTable;