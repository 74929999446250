// import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import { PaletteOptions, PaletteColor } from '@material-ui/core/styles/createPalette';

const colors = {
  blue: {
    main: '#2b4899',
    50: '#DFE8F0',
    100: 'gray',
    200: '#80A3C1',
    300: 'orange',
    400: '#266295',
    500: '#2b4899',
    600: '#003D71',
    700: '#00315C',
    800: '#002647',
    900: '#00182E'
  },
  lightBlue: {
    main: '#2b4899',
    100: '#2b4899',
    300: '#4D9CE2',
    700: '#005FB3',
    900: '#2b4899'
  },
  white: { main: '#FFFFFF' },
  // gold: { main: '#FFAA00', dark: '#BE965A', light: '#FFE7B4' },
  gold: { main: '#03DAC6', dark: '#018786', light: '#03DAC6' },
  black: {
    main: '#212121',
    10: '#F2F2F2',
    50: '#E4E4E4',
    100: 'gray',
    200: '#909090',
    300: '#636363',
    400: '#414141',
    500: '#212121',
    600: '#1C1C1C',
    700: '#161616',
    800: '#111111',
    900: '#080808'
  },
  red: {
    main: '#D90000',
    100: '#F4B3B3',
    300: '#E44D4D',
    700: '#BA0000',
    900: '#8A0000'
  },
  amber: {
    main: '#FFAA00',
    100: '#FFE6B3',
    300: '#FFC44D',
    700: '#D99100',
    900: '#A87000'
  },
  green: {
    main: '#008000',
    100: '#B3D9B3',
    300: '#4DA64D',
    700: '#005C00',
    900: '#003B00'
  }
};

export type CustomPaletteOptions = { colors: any; positive: PaletteColor; negative: PaletteColor };
const pallete: PaletteOptions & CustomPaletteOptions = {
  colors,
  primary: {
    main: colors.blue.main,
    light: colors.blue['50'],
    dark: colors.blue['600'],
    contrastText: '#FFFFFF'
  },
  secondary: {
    main: colors.black.main,
    light: colors.black['300'],
    dark: colors.black['600'],
    contrastText: '#FFFFFF'
  },
  positive: {
    main: colors.green.main,
    light: colors.green['300'],
    dark: colors.green['700'],
    contrastText: '#FFFFFF'
  },
  error: {
    main: colors.red.main,
    light: colors.red['300'],
    dark: colors.red['700'],
    contrastText: '#FFFFFF'
  },
  negative: {
    main: colors.red.main,
    light: colors.red['300'],
    dark: colors.red['700'],
    contrastText: '#FFFFFF'
  }
};

export default pallete;