import { scaleOrdinal } from 'd3';
import { Tooltip } from 'ppd-library/charts/organisms/Tooltip';
import { scales } from 'ppd-library/charts/chartUtils';
import {
  LineThresholdHorizontal,
  LineThresholdVertical,
} from 'ppd-library/charts/molecules/LineThreshold';
import { AxisBottom, AxisLeft } from 'ppd-library/charts/organisms/Axis';
import { BarVerticalStacked } from 'ppd-library/charts/organisms/BarVerticalStacked';
import { HorizontalGridLines } from 'ppd-library/charts/organisms/GridLines';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { ComponentBaseProps, Margins } from '../ComponentBaseProps';
import {
  ColumnChartRemainderDataModel,
  ColumnChartRemainderModel,
} from './columnChartRemainderTransformer';
import { Chart } from 'ppd-library/charts';
import { getSVGMousePosition, useTooltipPosition, TooltipTemplate } from '../tooltip-utils';
import * as allFormatters from '../../../utils/formatters';

interface StackedDataModel {
  key: string;
  [key: string]: string | number;
}

export interface ColumnChartRemainderThemeProps {
  colors?: string[];
  stackedBarColors?: string[];
}
export interface ColumnChartRemainderProps extends ComponentBaseProps<ColumnChartRemainderThemeProps, ColumnChartRemainderDataModel> {
  showTooltip?: boolean;
  showThreshold?: boolean;
  /**
  * Set the y axus maximum value to a minimum value, so it can go higher but not lower.
  */
  yMinMax?: number;
  /**
   * * When true, will hide the y axis an show the bars as an area.
   * When number, dense will be true when the width property < the supplied number in pixels.
   */
  dense?: boolean | number;
  /**
   * @default 10
   */
  yNumberOfTicks?: number;
  /**
   * Set a threshold in height pixels to show half the number of y ticks (5).
   * Will not be used when 'yNumberOfTicks' is explicitly set.
   */
  yHalfNumberOfTicks?: number;
}

const StyledWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const StyledChartHolder = styled.div``;

const createEmptyGroupData = (group: ColumnChartRemainderModel) => {
  let rawValues = {} as { [key: string]: number };
  group.valueKeys.forEach((key) => rawValues[key] = 0);
  const emptyGroupData = { ...group, rawValues };

  return emptyGroupData;
};

const ColumnChartRemainder = (props: ColumnChartRemainderProps) => {
  const {
    id,
    data,
    // help,
    // showHelp,
    yMinMax,
    tooltipOptions,
    yNumberOfTicks: originalYNumberOfTicks,
    width = 500,
    height = 450,
    dense = false,
    formatters = {},
    showTooltip = true,
    showThreshold = true,
    themeProperties,
    formattersOptions = {},
    yHalfNumberOfTicks = 260,
    margins: originalMargins = {} as Margins
  } = props;

  const isDense = dense === true || (typeof dense === 'number' && width < dense);
  const { left = 50, right = showThreshold ? 65 : 0, top = 10, bottom = showThreshold ? 50 : 30 } = originalMargins;
  let margins = { left, top, right, bottom };
  margins.left = isDense ? 10 : margins.left;
  margins.right = isDense ? 10 : margins.right;

  const {
    stackedBarColors = ['#2b4899', '#4d9ce2'],
  } = themeProperties || { } as ColumnChartRemainderThemeProps;

  const { columns, xThreshold, yThreshold } = data;
  const verticalStackKeys = ['value', 'remainder'];

  const ticks = columns.map((column) => column.key);

  const yAxisTickFormatterName = formatters['yAxisTick'];
  const yAxisTickFormatter = (allFormatters as any)[yAxisTickFormatterName] || allFormatters.formatNumber;
  const yAxisTickFormatterOptions = formattersOptions['yAxisTick'];

  const getMaxValueFromData = () => {
    const allRawValues = columns.reduce((previousResult, currentGroup) => {
      const currentGroupRawValues = currentGroup.valueKeys.map((key) => currentGroup.rawValues[key]);
      return [...previousResult, ...currentGroupRawValues];
    }, [] as number[]);

    return Math.max(...allRawValues);
  };
  const rawMaxValue = getMaxValueFromData();
  // yMinMax or calculate based on data
  const maxValue = !!yMinMax && yMinMax > rawMaxValue ? yMinMax : rawMaxValue;

  // Scaling
  let yNumberOfTicks = originalYNumberOfTicks || 10;
  yNumberOfTicks = height < yHalfNumberOfTicks ? Math.ceil(yNumberOfTicks / 2) : yNumberOfTicks;

  const xScale = scales.stringXScale(ticks, width, margins.left, margins.right);
  const yScale = scales.numericYScale(0, maxValue, height, margins.bottom, margins.top).nice();

  const createStackedData = (column: ColumnChartRemainderModel): StackedDataModel => {
    const { valueKeys, rawValues } = column;
    const model: StackedDataModel = {
      key: '', // TODO: key
      value: 0,
      remainder: 0
    };

    // NOTE: we assume one bar / two stack
    const currentValue = rawValues[valueKeys[0]] as number;
    const { rawValue: yThresholdValue } = yThreshold || { rawValue: 0 };

    const hasRemainingValue = currentValue > yThresholdValue;

    model.value = hasRemainingValue ? currentValue - (currentValue - yThresholdValue) : currentValue;
    model.remainder = hasRemainingValue ? currentValue - yThresholdValue : 0;

    return model;
  }

  const stackedBarColorScale = scaleOrdinal<string, string>().domain(verticalStackKeys).range(stackedBarColors);

  let tooltipLeaveTimeoutId = 0;
  const handleMouseMove = (event: React.MouseEvent<Element, MouseEvent>, index: number, data: ColumnChartRemainderModel) => {
    clearTimeout(tooltipLeaveTimeoutId);
    const { x, y } = getSVGMousePosition(event as React.MouseEvent<SVGElement, MouseEvent>);
    setTooltipPosition({ visible: true, x, y, index, data });
  };

  const handleMouseLeave = () => {
    clearTimeout(tooltipLeaveTimeoutId);
    tooltipLeaveTimeoutId = setTimeout(() => {
      setTooltipPosition({ ...tooltipPosition, visible: false });
    }, 250);
  };

  const { tooltipPosition, setTooltipPosition } = useTooltipPosition<ColumnChartRemainderModel>();

  const xScaleTickOffset = xScale.bandwidth() / 2;
  // const [tooltipPosition, setTooltipPosition] = useState({ visible: false, left: 0, top: 0, data: {} as ColumnChartRemainderModel });
  const [initiated, setInitiated] = useState(false);

  useEffect(() => {
    setInitiated(true);
  }, []);

  return (
    <StyledWrapper>
      <StyledChartHolder>
        <Chart
          width={width}
          height={height}
        >
          <HorizontalGridLines
            scale={yScale}
            numberOfTicks={yNumberOfTicks}
            id={`${id}-horizontal-grid-lines`}
            x={margins.left + (xScale.paddingOuter() * xScale.bandwidth())}
            lineLength={width - margins.left - margins.right - (xScale.paddingOuter() * 2 * xScale.bandwidth())}
          />
          {
            columns.map((originalColumn, columnIndex) => {
              const column = initiated ? originalColumn : createEmptyGroupData(originalColumn);

              return (
                <BarVerticalStacked
                  yScale={yScale}
                  key={column.key}
                  keys={verticalStackKeys}
                  width={xScale.bandwidth()}
                  x={xScale(column.key) as number}
                  data={createStackedData(column)}
                  colorScale={stackedBarColorScale}
                  id={`${id}-bar-vertical-stacked-${columnIndex}`}
                  mouseMove={(ev) => handleMouseMove(ev, columnIndex, column)}
                  mouseLeave={handleMouseLeave}
                />
              );
            })
          }
          {!!showThreshold && !!yThreshold &&
            <LineThresholdHorizontal
              labelDominantBaseline={0}
              yText={12}
              x={margins.left}
              showCircle={false}
              labelPadding={[2, 4, 2, 4]}
              labelPos={['right', 'bottom']}
              maxWidth={width - margins.left}
              y={yScale(yThreshold.rawValue)}
              id={`${id}-line-horizontal-threshold`}
              label={isDense ? yThreshold.formattedValue : `${yThreshold.label}: ${yThreshold.formattedValue}`}
              theme={{
                lineColor: '#212121',
                textSize: '0.8rem'
              }}
            />
          }
          {!isDense && !!showThreshold && !!xThreshold &&
            xThreshold.map((item) => {
              return (
                <LineThresholdVertical
                  lineWidth={1}
                  showCircle={false}
                  label={item.label}
                  x={xScale(item.columnKey)}
                  labelPadding={[2, 4, 2, 4]}
                  key={`${id}-${item.label}`}
                  theme={{ textSize: '0.8rem' }}
                  lineHeight={margins.bottom}
                  y={height - (margins.bottom)}
                  id={`${id}-line-vertical-threshold-${item.label}`}
                  labelPos={['left', 'bottom']}
                />
              );
            })
          }
          {!isDense &&
            <AxisLeft
              scale={yScale}
              x={margins.left}
              id={`${id}-axis-left`}
              numberOfTicks={yNumberOfTicks}
              tickFormat={(value) => {
                if (!!yAxisTickFormatter) {
                  return yAxisTickFormatter(value, yAxisTickFormatterOptions);
                }

                return value;
              }}
            />
          }
          {!isDense &&
            <AxisBottom
              scale={xScale}
              y={-margins.bottom}
              chartHeight={height}
              id={`${id}-axis-bottom`}
              tickOffset={xScaleTickOffset}
              tickFormat={(_value, i) => `${columns[i].shortXLabel}`}
            />
          }
          {isDense &&
            <AxisBottom
              scale={xScale}
              hideLine={false}
              y={-margins.bottom + 2}
              chartHeight={height}
              id={`${id}-axis-bottom`}
              tickOffset={xScale.bandwidth()}
              hideTickLine={(_value, index) => {
                if (!!xThreshold) {
                  const threshold = xThreshold.find(({ columnIndex: xTresholdColumnIndex }) => index - 1 === xTresholdColumnIndex)
                  if (!!threshold && showThreshold) {
                    return false;
                  }
                }
                return true;
              }}
              theme={{ colors: { line: '#EEEEEE' }, tickAnimation: { duration: 500, delay: 300 } }}
              tickFormat={(_value, barIndex) => {
                if (!!xThreshold) {
                  const threshold = xThreshold.find(({ columnIndex: xTresholdColumnIndex }) => barIndex - 1 === xTresholdColumnIndex)
                  if (!!threshold && showThreshold) {
                    return threshold.label;
                  }
                }
                return ''; //TODO: what if we don't have a threshold?
              }}
            />
          }
        </Chart>
        <Tooltip
          top={tooltipPosition.y}
          left={tooltipPosition.x}
          visible={showTooltip && tooltipPosition.visible}
          theme={{
            transition: false
          }}
        >
          {() => {
            const {
              label,
              xLabel,
              valueKeys,
              formattedValues
            } = tooltipPosition.data as ColumnChartRemainderModel;
            return (
              <TooltipTemplate
                title={xLabel}
                tooltipOptions={tooltipOptions}
                values={
                  valueKeys.map((key) => {
                    return {
                      label: label,
                      formattedValue: formattedValues[key],
                      colors: stackedBarColors
                    }
                  })
                }
              />
            );
          }}
        </Tooltip>
      </StyledChartHolder>
    </StyledWrapper>
  )
};
export default ColumnChartRemainder;