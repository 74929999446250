import { TransformFunction } from '../transform-utils';
import lineChartTransformer, { LineChartDataModel, LineChartRawData, LineChartTransformOptions, LineChartPointDataModel } from './lineChartTransformer';

export interface LineChartAggregatedTransformOptions extends LineChartTransformOptions {
  useRawDataIndex?: number;
  useRawDataMinMaxValueIndex?: number;
}

export interface LineChartAggregatedDataModel {
  transformerMin: number; //  "yMinCalculated" moet op false staan
  transformerMax: number; //  "yMaxCalculated"  moet op false staan
  data: LineChartDataModel | null;
}

const lineChartAggregatedTransformer: TransformFunction<LineChartAggregatedDataModel, LineChartAggregatedTransformOptions> = (
  rawData: LineChartRawData[],
  onError,
  options
) => {

  const { useRawDataIndex = 0, useRawDataMinMaxValueIndex = 0, ...lineChartOptions } = options || {};
  const allRawDataValues: number[] = [];
  let data = null;

  // TODO: mischien later de data uit meerdere lijnen?
  for (let lineDataIndex = 0; lineDataIndex < rawData.length; lineDataIndex++) {
    // TODO: is het echt nodig om alle sets te transformeren?
    const lineData = lineChartTransformer(rawData[lineDataIndex], onError, lineChartOptions);

    if (lineDataIndex === useRawDataIndex) ( data = lineData );

    const rawDataValues: number[] = (lineData as LineChartDataModel)
      .lines[useRawDataMinMaxValueIndex]
      .points.map((line: LineChartPointDataModel) => line.rawValue);

      allRawDataValues.push(...rawDataValues);
  }

  const result: LineChartAggregatedDataModel = {
    transformerMin: Math.min(...allRawDataValues),
    transformerMax: Math.max(...allRawDataValues),
    data
  }

  return result;
};

export default lineChartAggregatedTransformer;