import { TransformFunction, TransformOptionsBase, calcNumberOfRows, padLeftWithZeros } from '../transform-utils';
import * as allFormatters from '../../../utils/formatters';

interface RD {
  waarde: number;
  label_1: string;
  label_4: string;
  eenheid: string;
}
export interface StackedLinedLabeledBarXPropsThreshold {
  label: string;
  label2: string;
  xLabelKey: string;
}

export interface StackedLinedLabeledBarRawData {
  [key: string]: RD;
}

export interface BarDataModel {
  label?: string;
  rawValue: number;
  formattedValue: string;
}

export interface StackedLinedLabeledBarXThreshold {
  value: number | null;
  label: string;
  label2: string;
}

export interface StackedLinedLabeledBarDataModel {
  barModel: StackedBarDataModel;
  xThreshold?: StackedLinedLabeledBarXThreshold;
}

export interface StackedBarDataModel {
  [key: string] : {
    primaryValue: BarDataModel;
    secondaryValue: BarDataModel;
  }
}

export interface DoubleStackedLinedLabeledSecondBarDataModel {
  [key: string] : BarDataModel
}

export interface StackedLinedLabeledBarTransformOptions extends TransformOptionsBase {
  xThreshold?: StackedLinedLabeledBarXPropsThreshold;
}

const stackedLinedLabeledBarTransformer: TransformFunction<StackedLinedLabeledBarDataModel, StackedLinedLabeledBarTransformOptions> = (
  rawData: StackedLinedLabeledBarRawData,
  onError,
  options
) => {
  const keys = Object.keys(rawData);
  const guard = !keys.some((key) => rawData[key].waarde !== null);

  if (guard) {
    onError('nodata');
    return null;
  }
//   yThreshold = { label: 'norm', rawValue: 0.0, formattedValue: '0.0' }
  const {
    formatters = {},
    formattersOptions = {},
    xThreshold
  } = options || {} as StackedLinedLabeledBarTransformOptions;

  const valueFormatterName = formatters['value'];
  const valueFormatter = (allFormatters as any)[valueFormatterName];
  const valueFormatterOptions = formattersOptions['value'];

  const numberOfRows = calcNumberOfRows(keys);
  const result: StackedBarDataModel = {};
  let xThresholdModel: StackedLinedLabeledBarXThreshold = { value: null, label: '', label2: ''};  for (let rowNumber = 0; rowNumber < numberOfRows; rowNumber++) {
    const row = padLeftWithZeros(rowNumber, 2);

    const key00 = rawData[`val${row}_00`];
    const { label_1: dimension, waarde: primaryValue, eenheid: primaryValueUnit } = key00;
    const key01 = rawData[`val${row}_01`];
    const { waarde: secondaryValue, eenheid: secondaryValueUnit } = key01;

    result[dimension] = {
      primaryValue: {
        label: dimension,
        rawValue: primaryValue,
        formattedValue: valueFormatter ? valueFormatter(primaryValue, valueFormatterOptions) : allFormatters.formatValue(primaryValue, primaryValueUnit as ValueUnit, valueFormatterOptions)
      },
      secondaryValue: {
        rawValue: secondaryValue,
        formattedValue: valueFormatter ? valueFormatter(key01.waarde, valueFormatterOptions) : allFormatters.formatValue(secondaryValue, secondaryValueUnit as ValueUnit, valueFormatterOptions)
      }
    }
  }


if(xThreshold) {
  let valueCount: number = 0;
  let xValue: number = 0;

  for (let rowNumber = 0; rowNumber < numberOfRows; rowNumber++) {
    const row = padLeftWithZeros(rowNumber, 2);
    const key00 = rawData[`val${row}_00`];

    if(key00.label_1 === xThreshold.xLabelKey) {
      xValue = valueCount;
    }

    valueCount = valueCount + key00.waarde;
  }

  xThresholdModel = {
    value: xValue,
    label: xThreshold.label,
    label2: xThreshold.label2
  }
}

  return { barModel: result, xThreshold: xThresholdModel };

};

export default stackedLinedLabeledBarTransformer;