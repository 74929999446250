import { TreeDataItem } from "ppd-library/components/organisms/CheckBoxTree";

function getChildren(parent: { [key: string]: any }) {
  //Note!: Een key / oecode is altijd 6 characters
  const childKeys = Object.keys(parent).filter((x) => x.length === 7);

  let childItems: TreeDataItem[] = childKeys.map((key) => {
    return {
      srt: parent[key].SRT,
      id: parent[key].CD,
      label: parent[key].OE,
      childNodes: getChildren(parent[key])
    };
  });

  childItems = childItems.sort((a, b) => (a as any)['label'] < (b as any)['label'] ? -1 : 1);

  return childItems;
};

export function convertNavigationDataToTreeDataItems(navData: any) {
  let treeData: TreeDataItem[] = [];

  if (!!navData) {
    const childKeys = Object.keys(navData).filter((key) => key.length === 7);
    childKeys.forEach((x) => {
      treeData.push({
        srt: navData[x].SRT,
        id: navData[x].CD,
        label: navData[x].OE,
        childNodes: getChildren(navData[x])
      });
    });

    treeData = treeData.sort((a, b) => (a as any)['label'] < (b as any)['label'] ? -1 : 1);
  }



  return treeData;
}