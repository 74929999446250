import { TransformFunction, TransformOptionsBase } from '../transform-utils';
import * as allFormatters from '../../../utils/formatters';

interface RD {
  waarde: number;
  label_1: string;
  label_2?: string;
  label_3?: string;
  label_4: string;
  eenheid: string;
}

export interface ColumnChartRawData {
  [key: string]: RD;
}

export interface ColumnChartXThreshold {
  label: string;
  columnKey: string;
  columnIndex: number;
}

export interface ColumnChartTransformOptions extends TransformOptionsBase {
  reverse?: boolean;
  /**
   * Set the key to use for the x axis.
   * @default label_1
   */
  xLabelKey?: 'label_1' | 'label_2' | 'label_3';
}


export interface ColumnChartDataModel {
  key: string;
  label: string;
  xLabel: string;
  valueKey: string;
  rawValue: number;
  formattedValue: string;
  shortXLabel?: string;
}

export interface ColumnChartColumnDataModel {
  columnsModel: ColumnChartDataModel[];
  xThreshold?: ColumnChartXThreshold[];
}


const findXThreshold = (xThresholdKeys: string[], columns: ColumnChartDataModel[]): ColumnChartXThreshold[] => {
  let currentThresholdLabel = xThresholdKeys[0];
  const xTresholdArr: ColumnChartXThreshold[] = [];

  xThresholdKeys.forEach((key, index) => {
    if (key !== currentThresholdLabel && index !== 0) {
      currentThresholdLabel = key;
      xTresholdArr.push({
        columnIndex: index,
        columnKey: columns[index].key,
        label: currentThresholdLabel
      })
    }
  });

  return xTresholdArr;
}

const columnChartTransformer: TransformFunction<ColumnChartColumnDataModel, ColumnChartTransformOptions> = (
  rawData: ColumnChartRawData,
  onError,
  options
) => {

  const keys = Object.keys(rawData);
  const guard = !keys.some((key) => rawData[key].waarde !== null);

  if (guard) {
    onError('nodata');
    return null;
  }

  const {
    reverse = false,
    formatters = {},
    formattersOptions = {},
    xLabelKey = 'label_2',
  } = options || {} as ColumnChartTransformOptions;

  const valueFormatterName = formatters['value'];
  const valueFormatter = (allFormatters as any)[valueFormatterName];
  const valueFormatterOptions = formattersOptions['value'];

  const xLabelFormatterName: string = formatters['xLabel'];
  const xLabelFormatter = (allFormatters as any)[xLabelFormatterName];
  const xLabelFormatterOptions = formattersOptions['xLabel'];

  const shortXLabelFormatterName: string = formatters['shortXLabel'];
  const shortXLabelFormatter = (allFormatters as any)[shortXLabelFormatterName];
  const shortXFormatterOptions = formattersOptions['shortXLabel'];

  let chartModel: ColumnChartDataModel[] = [];
  let xThresholdKeys: string[] = [];

   // TODO: temp prognose fix
   const date = new Date();
   const year = date.getFullYear();
   const month = date.getMonth() + 1;

  chartModel = keys.map((key: string) => {
    const item = rawData[key];
    const { label_1, label_2, label_4, waarde, eenheid } = item;

    const xLabel = xLabelFormatter ? xLabelFormatter(item[xLabelKey] as string, xLabelFormatterOptions) : item[xLabelKey] as string;
    const shortXLabel = xLabelFormatter ? shortXLabelFormatter(item[xLabelKey] as string, shortXFormatterOptions) : item[xLabelKey] as string;

    const formattedValue = valueFormatter ?
      valueFormatter(waarde, valueFormatterOptions) :
      allFormatters.formatValue(waarde, eenheid as ValueUnit, valueFormatterOptions);

    reverse ? xThresholdKeys.unshift(label_1) : xThresholdKeys.push(label_1);
    const chartItemObjectKey = `${label_1}_${item[xLabelKey]}_${label_4}`;

    // TODO: temp oplossing prognose
    const isInFuture = parseInt(label_1 as string) > year || (parseInt(label_1 as string) === year && parseInt(label_2 as string) > month)
    return {
      key: chartItemObjectKey,
      xLabel,
      shortXLabel,
      formattedValue,
      label: isInFuture ? 'Prognose' : label_4,
      rawValue: waarde,
      valueKey: isInFuture ? 'Prognose' : label_4,
    } as ColumnChartDataModel;
  });

  let columnCharDataModel: ColumnChartDataModel[] = [];
  columnCharDataModel = reverse ? chartModel.reverse() : chartModel;

  const xThreshold = findXThreshold(xThresholdKeys, columnCharDataModel);

  return { columnsModel: columnCharDataModel, xThreshold };
};

export default columnChartTransformer;