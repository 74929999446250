import { TransformFunction, TransformOptionsBase } from '../transform-utils';

export interface ContentCoverDataModel {}
export interface ContentCoverTransformOptions extends TransformOptionsBase {}

export const contentCoverTransformer: TransformFunction<ContentCoverDataModel, ContentCoverTransformOptions> = (
) => {
  return {}
}

export default contentCoverTransformer;