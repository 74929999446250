import React from 'react';
import MuiButton from '@material-ui/core/Button';
import MuiIconButton from '@material-ui/core/IconButton';
import MuiDoneIcon from '@material-ui/icons/Done';

export interface EditDoneActionButtonProps {
  isMobile: boolean;
  onClick: () => void;
  /**
   * @default primary
   */
  color?: 'primary' | 'secondary';
}

const EditDoneActionButton = (props: EditDoneActionButtonProps) => {
  const {
    onClick,
    isMobile,
    color = 'primary',
  } = props;

  return (
    <>
      {!isMobile &&
        <MuiButton
          color={color}
          onClick={onClick}
          startIcon={<MuiDoneIcon />}
          aria-label={'Aanpassen afsluiten'}
        >
           Aanpassen afsluiten 
        </MuiButton>
      }

      {!!isMobile &&
        <MuiIconButton
          color={color}
          onClick={onClick}
          aria-label={'Aanpassen afsluiten'}
        >
          <MuiDoneIcon />
        </MuiIconButton>
      }
    </>
  );
};

export default EditDoneActionButton;