import React, { useState } from 'react';
import styled from 'styled-components';
import MuiTextField, { TextFieldProps as MuiTextFieldProps } from '@material-ui/core/TextField';

export interface EditableTileTitleProps {
  /**
   * Text value of the the tile title.
   */
  value: string;
  /**
   * fires when the input field value changes
   */
  onChange?: (value: string) => void
  /**
   * fires when the input field looses focus
   */
  onBlur?: (value: string) => void
} 

const StyledMuiTextField = styled(MuiTextField)`
  && {
    .MuiOutlinedInput-input {
      padding: 0.4rem 0.8rem;
    }
    .Mui-error {
      margin-left: 0;
    }
  }
`as React.ComponentType<MuiTextFieldProps>;

const EditableTileTitle = (props: EditableTileTitleProps) => {
  const {
    value,
    onChange,
    onBlur
  } = props;

  const [inputValue, setInputValue] = useState<string>(value);
  const [newDashboardNameErrorText, setNewDashboardNameErrorText] = useState('');

  const checkPermittedCharacters = (inputValue: string) => {
    if (inputValue.length > 255) {
      setNewDashboardNameErrorText(`Maximaal 255 tekens (${inputValue.length})`);
      return false;
    }
    else if (inputValue.trim().length === 0) {
      setNewDashboardNameErrorText('Geef een naam op voor het dashboard');
      return false;
    }
    else {
      setNewDashboardNameErrorText('');
      return true;
    }
  }

  const handleOnChange = (value: string) => {
    const isPermitted = checkPermittedCharacters(value);
    if (isPermitted && onChange) {
      onChange(value);
    }
    setInputValue(value);
  }

  const handleOnBlur = (value: string) => {
    const isPermitted = checkPermittedCharacters(value);
    if (isPermitted && onBlur) {
      onBlur(value);
    }
  }

  return (
    <StyledMuiTextField
      value={inputValue}
      required={true}
      fullWidth={true}
      error={newDashboardNameErrorText !== ''}
      helperText={newDashboardNameErrorText}
      // variant={'outlined'}
      onChange={(e) => handleOnChange(e.target.value)}
      onBlur={(e) => handleOnBlur(e.target.value)}
    />
  );
};

export default EditableTileTitle;