import MuiButton from '@material-ui/core/Button';
import MuiIconButton from '@material-ui/core/IconButton';
import MuiFileCopyIcon from '@material-ui/icons/FileCopy';
import MuiFileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';

import React from 'react';

export interface SaveAsNewTemplateActionButtonProps {
  isMobile: boolean;
  onClick: () => void;
  /**
   * @default secondary
   */
  color?: 'primary' | 'secondary' | 'default';
}

const SaveAsNewTemplateActionButton = (props: SaveAsNewTemplateActionButtonProps) => {
  const {
    onClick,
    isMobile,
    color = 'secondary'
  } = props;

  return (
    <>
      {!isMobile &&
        <MuiButton
          color={color}
          onClick={onClick}
          startIcon={<MuiFileCopyIcon />}
          aria-label={'Als nieuw dashboard opslaan'}
        >
          Als nieuw dashboard opslaan
        </MuiButton>
      }

      {!!isMobile &&
        <MuiIconButton
          color={color}
          onClick={onClick}
          aria-label={'Als nieuw dashboard opslaan'}
        >
          <MuiFileCopyRoundedIcon />
        </MuiIconButton>
      }
    </>
  );
};

export default SaveAsNewTemplateActionButton;