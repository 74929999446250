import * as allFormatters from '../../../utils/formatters';
import { TransformFunction, TransformOptionsBase, calcNumberOfRows, padLeftWithZeros } from '../transform-utils';


interface RD {
  waarde: number;
  label_1: string;
  label_4: string;
  eenheid: string;
}

export interface ValuesRelativeRawData {
  val00_00: RD;
  val00_01?: RD; // secondary can be undefined
  [key: string]: RD | undefined;
}

export interface ValuesRelativeTransformOptions extends TransformOptionsBase {

}

export interface ValuesRelativeDataModel {
  bars: {
    primaryValue: {
      label: string;
      rawValue: number;
      formattedValue: string;
    };
    secondaryValue?: {
      rawValue: number;
      formattedValue: string;
    };
  }[];
}

const valuesRelativeTransformer: TransformFunction<ValuesRelativeDataModel, ValuesRelativeTransformOptions> = (
  rawData: ValuesRelativeRawData,
  onError,
  options
) => {
  const keys = Object.keys(rawData);
  const guard = !keys.some((key) => !!rawData[key] && (rawData[key] as any).waarde !== null);

  if (guard) {
    onError('nodata');
    return null;
  }

  const {
    formatters = {},
    formattersOptions = {}
  } = options || {} as ValuesRelativeTransformOptions;

  let result: ValuesRelativeDataModel = {
    bars: []
  };

  const primaryValueFormatterName = formatters['primaryValue'];
  const primaryValueFormatter = (allFormatters as any)[primaryValueFormatterName];
  const primaryValueFormatterOptions = formattersOptions['primaryValue'];

  const secondaryValueFormatterName = formatters['secondaryValue'];
  const secondaryValueFormatter = (allFormatters as any)[secondaryValueFormatterName];
  const secondaryValueFormatterOptions = formattersOptions['secondaryValue'];

  const labelFormatterName: string = formatters['label'];
  const labelFormatter = (allFormatters as any)[labelFormatterName];
  const labelFormatterOptions = formattersOptions['label'];

  const numberOfRows = calcNumberOfRows(keys);

  for (let rowNumber = 0; rowNumber < numberOfRows; rowNumber++) {
    const row = padLeftWithZeros(rowNumber, 2);
    const primaryKey = `val${row}_00`;
    const secondaryKey = `val${row}_01`;
    const rawPrimaryItem = rawData[primaryKey] as RD;
    const rawSecondaryItem = rawData[secondaryKey] as RD;
    const { /*label_4: primaryLabel4,*/ label_1: primaryDimensionLabel, waarde: primaryWaarde, eenheid: primaryEenheid } = rawPrimaryItem;
    const { waarde: secondaryWaarde, eenheid: secondaryEenheid } = rawSecondaryItem || {} as any;

    const barItem = {
      primaryValue: {
        rawValue: primaryWaarde,
        label: labelFormatter ? labelFormatter(primaryDimensionLabel as string, labelFormatterOptions) : primaryDimensionLabel,
        formattedValue: primaryValueFormatter ?
          primaryValueFormatter(primaryWaarde, primaryValueFormatterOptions) :
          allFormatters.formatValue(primaryWaarde, primaryEenheid as ValueUnit, primaryValueFormatterOptions)
      },
      secondaryValue: !!rawSecondaryItem ? {
        rawValue: secondaryWaarde,
        formattedValue: secondaryValueFormatter ?
          primaryValueFormatter(secondaryWaarde, secondaryValueFormatterOptions) :
          allFormatters.formatValue(secondaryWaarde, secondaryEenheid as ValueUnit, secondaryValueFormatterOptions)
      } : undefined
    };

    result.bars.push(barItem);
  }

  return result;
};

export default valuesRelativeTransformer;