import { NumberFormatterOptions } from "../../../utils";

export type TransformErrorType = 'nodata' | 'error' | 'onlyzeroes';
export interface TransformOptionsBase { formatters?: { [key: string]: string; }; formattersOptions?: { [key: string]: NumberFormatterOptions } };

// TODO: onerror mag geen null als type meekrijgen
export type TransformFunction<TransformedDataType, TransformOptionsType extends TransformOptionsBase> = (
  rawData: any,
  onError: (type: TransformErrorType | null) => void,
  options?: TransformOptionsType
) => TransformedDataType | null;

const transform = <TransformedDataType, TransformOptionsType>(
  transformFunction: TransformFunction<TransformedDataType, TransformOptionsType>,
  onError: (type: TransformErrorType | null) => void,
  rawData: any,
  transformOptions?: TransformOptionsType
) => {
  try {
    return transformFunction(rawData, onError, transformOptions);
  } catch (error) {
    onError('error');
    return null;
  }
};

export default transform;