
/**
 * Nice throttle function
 * https://gist.github.com/beaucharman/e46b8e4d03ef30480d7f4db5a78498ca#gistcomment-2935416
 * @param callback function to be executed.
 * @param wait time (ms) delay of function executing
 */

export const throttle = <T extends []> (
    callback: (..._: T) => void,
    wait: number
  ): (..._: T) => void => {
    const next = () => {
      timeout = clearTimeout(timeout as any) as undefined;
      callback(...lastArgs);
    };

    let timeout: NodeJS.Timeout | number | undefined;
    let lastArgs: T;

    return (...args: T) => {
      lastArgs = args;

      if (timeout === void 0) {
        timeout = setTimeout(next, wait);
      }
    };
  };

/**
 * A function that emits a side effect and does not return anything.
 */
export type Procedure = (...args: any[]) => void;

export type Options = {
  isImmediate: boolean,
}

export function debounce<F extends Procedure>(
  func: F,
  waitMilliseconds = 50,
  options: Options = {
    isImmediate: false
  },
): (this: ThisParameterType<F>, ...args: Parameters<F>) => void {
  let timeoutId: ReturnType<typeof setTimeout> | undefined;

  return function(this: ThisParameterType<F>, ...args: Parameters<F>) {
    const context = this;

    const doLater = function() {
      timeoutId = undefined;
      if (!options.isImmediate) {
        func.apply(context, args);
      }
    }

    const shouldCallNow = options.isImmediate && timeoutId === undefined;

    if (timeoutId !== undefined) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(doLater, waitMilliseconds);

    if (shouldCallNow) {
      func.apply(context, args);
    }
  }
}