import { Tile, TilePlaceholder, TileProps } from 'ppd-library/components/organisms/Tile';
import React, { Component } from 'react';
import styled from 'styled-components';

import muiTheme from '../../../constants/theme';

interface ContainerErrorBoundaryProps {
  containerId: number;
  placeholderText: string | null;
}

interface ContainerErrorBoundaryState {
  hasError: boolean;
}

const StyledTile = styled(Tile)`
  height: 100%;
  display: flex;
  flex-direction: column;
` as React.ComponentType<TileProps>;

const StyledPlaceholder = styled(TilePlaceholder)`
  flex: 1;
  align-self: center;
  justify-self: center;
`;

// TODO: tijdelijke? oplossing om globale fouten in de container op te vangen.
/**
 * Errors caught by the error boundary mostly likely originate in the JSON structure (not data) of the tile.
 */
class ContainerErrorBoundary extends Component<ContainerErrorBoundaryProps, ContainerErrorBoundaryState> {
  constructor(props: ContainerErrorBoundaryProps) {
    super(props);

    const { placeholderText = null } = props;
    this.state = { hasError: !!placeholderText };
  }

  static getDerivedStateFromError(_error: any) {
    return { hasError: true };
  }

  componentDidCatch(_error: any, _errorInfo: any) {
    // You can also log the error to an error reporting service
    //logErrorToMyService(error, errorInfo);
  }

  render() {
    const { containerId, placeholderText = '' } = this.props;
    const message = !!placeholderText ? placeholderText :
      `Fout bij het laden van de tegel container id ${containerId}`;

    if (this.state.hasError) {

      return (
        <StyledTile
          content={
            <StyledPlaceholder
              message={message}
              theme={
                {
                  palette: {
                    secondary: {
                      main: muiTheme.palette.secondary.light
                    }
                  }
                }
              }
            />
          }
        />
      )
    };

    return this.props.children;

  }
}

export default ContainerErrorBoundary;