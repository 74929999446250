import { Icon as MuiIcon } from '@material-ui/core';
import MuiBreadcrumbs, { BreadcrumbsProps as MuiBreadcrumbsProps } from '@material-ui/core/Breadcrumbs';
import { IconProps as MuiIconProps } from '@material-ui/core/Icon';
import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import styled from 'styled-components';

export interface BreadCrumbItem {
  iconName?: string;
  text: string;
  path: string | null;
  themeHelpId?: number | null;
}

export interface BreadCrumbsProps {
  className?: string;
  isMobilePhone?: boolean;
  items: BreadCrumbItem[];
  showHelp?: boolean;
  onThemeInfoClick?: (helpId: number) => void;
}

const StyledNavLink = styled(NavLink)`
  &&{
    font-size: 0.875rem;
    color: #2b4899;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
` as React.ComponentType<NavLinkProps>;

const StyledMuiBreadcrumbs = styled(MuiBreadcrumbs)`
  &&&{
    font-size: 0.875rem;
    line-height: 1.2rem;
    color: #0083CD;
  }
` as React.ComponentType<MuiBreadcrumbsProps>;

const BreadCrumbsHolder = styled(({ isMobilePhone, ...spanProps }) => <span {...spanProps} />)`
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    position: ${({ isMobilePhone }) => isMobilePhone && 'absolute'};
    top: ${({ isMobilePhone }) => isMobilePhone && 0};
    left: ${({ isMobilePhone }) => isMobilePhone && '1.4rem'};
  `as React.ComponentType<{ isMobilePhone: boolean } & React.HTMLProps<HTMLSpanElement>>;


const StyledPageIcon = styled(MuiIcon)`
  margin-right: 0.3rem;
  &&{
    font-size: 1rem;
  }
` as React.ComponentType<MuiIconProps>;


const StyledPageIconHolder = styled.span`
  &&{
    font-size: 0.875rem;
    color: #2b4899;
    display: flex;
    align-items: center;
  }
` as React.ComponentType<MuiIconProps>;

const BreadCrumbs = (props: BreadCrumbsProps) => {

  const { isMobilePhone = false, className = '', items } = props;

  return (
    <BreadCrumbsHolder isMobilePhone={isMobilePhone} className={className}>
      <StyledMuiBreadcrumbs separator={'›'} aria-label={'breadcrumb'}>
        {items.map(({ iconName, text, path }, index) =>
          <StyledPageIconHolder key={`${text || ''}-${index}`}>
            {!!iconName &&
              <StyledPageIcon
                title={text}
                fontSize={'small'}
              >
                {iconName}
              </StyledPageIcon>
            }

            {index === items.length - 1 && text}

            {index !== items.length - 1 && !path && text}

            {index !== items.length - 1 && !!path &&
              <StyledNavLink
                exact
                to={path as string}
                className={'MuiLink-root'}
              >
                {text}
              </StyledNavLink>
            }
          </StyledPageIconHolder>
        )}
      </StyledMuiBreadcrumbs>
    </BreadCrumbsHolder>
  );
}

export default BreadCrumbs;