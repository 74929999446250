import MuiDivider from '@material-ui/core/Divider';
import { ValueBlockSimple, ValueBlockSimpleProps } from 'ppd-library/components/molecules/ValueBlockSimple';
import React from 'react';
import styled from 'styled-components';

import { ComponentBaseProps } from '../ComponentBaseProps';
import { TwoValuesDataModel } from './twoValuesTransformer';
import { HelpTooltipButton } from '../../help/HelpTooltipButton';

export interface TwoValuesThemeProps {
  topValueIcon?: string;
  bottomValueIcon?: string;
  topValueColor?: string;
  bottomValueColor?: string;
}
export interface TwoValuesProps extends ComponentBaseProps<TwoValuesThemeProps, TwoValuesDataModel> {
  /**
   * @default vertical
   */
  orientation?: 'horizontal' | 'vertical';
  /**
   * if a value is secondary, 
   * the value will be displayed smaller
   * and the separator line will be removed.
   */
  isSecondary?: 'top' | 'bottom';
  // topValueHelpId?: string;
  // bottomValueHelpId?: string;
}


const StyledWrapper = styled(({ orientation, ...divProps }) => <div {...divProps} />)`
&& {
  flex: 1;
  display: flex;
  align-content: flex-start;
  flex-direction: ${({ orientation }) => orientation === 'horizontal' ? 'row' : 'column'};
}
` as React.ComponentType<{ orientation: 'horizontal' | 'vertical' } & React.HTMLProps<HTMLDivElement>>;

const StyledTopValueBlockSimple = styled(ValueBlockSimple)`
  flex-grow: 1;
` as React.ComponentType<ValueBlockSimpleProps>;

const StyledBottomValueBlockSimple = styled(ValueBlockSimple)`
  flex-grow: 1;
` as React.ComponentType<ValueBlockSimpleProps>;

const TwoValues = (props: TwoValuesProps) => {
  const {
    // width,
    // height,
    data,
    help,
    showHelp,
    isSecondary,
    orientation = 'vertical',
    themeProperties
  } = props;

  const { topValue, bottomValue } = data;
  const { topValueIcon, bottomValueIcon, topValueColor, bottomValueColor } = themeProperties || {} as TwoValuesThemeProps;
  const { label: topValueLabel, formattedValue: topFormattedValue } = topValue;
  const { label: bottomValueLabel, formattedValue: bottomFormattedValue } = bottomValue;

  const topValueSize =
    isSecondary === 'bottom' ? 'large' :
      isSecondary === 'top' ? 'small' :
        'medium';

  const bottomValueSize =
    isSecondary === 'top' ? 'large' :
      isSecondary === 'bottom' ? 'small' :
        'medium';

  return (
    <StyledWrapper orientation={orientation}>
      <StyledTopValueBlockSimple
        color={topValueColor}
        label={topValueLabel}
        valueSize={topValueSize}
        valueVariant={'h2'}
        value={topFormattedValue}
        iconOptions={topValueIcon ? { iconName: topValueIcon } : undefined}
        afterValueContent={
          <>
            {!!showHelp && !!help && help.some(({ position }) => position === 1) &&
              <HelpTooltipButton
                anchorType={'iconButton'}
                placement={'bottom-start'}
                helpId={(help.find(({ position }) => position === 1) as { helpId: number }).helpId}
              />
            }
          </>
        }
      />

      <div style={{
        flexGrow: 1,
        padding: orientation === 'vertical' ? '1rem 0' : '0 1rem'
      }}
      >
        {!isSecondary &&
          <MuiDivider
            orientation={orientation === 'vertical' ? 'horizontal' : 'vertical'}
          />
        }
      </div>

      <StyledBottomValueBlockSimple
        color={bottomValueColor}
        label={bottomValueLabel}
        valueSize={bottomValueSize}
        value={bottomFormattedValue}
        valueVariant={'h2'}
        iconOptions={bottomValueIcon ? { iconName: bottomValueIcon } : undefined}
        afterValueContent={
          <>
            {!!showHelp && !!help && help.some(({ position }) => position === 2) &&
              <HelpTooltipButton
                anchorType={'iconButton'}
                placement={'bottom-start'}
                helpId={(help.find(({ position }) => position === 2) as { helpId: number }).helpId}
              />
            }
          </>
        }
      />
    </StyledWrapper>
  )
};

export default TwoValues;
