import MuiDivider from '@material-ui/core/Divider';
import MuiDrawer, { DrawerProps as MuiDrawerProps } from '@material-ui/core/Drawer';
import MuiFloatingActionButton from '@material-ui/core/Fab';
import MuiIcon from '@material-ui/core/Icon';
import MuiIconButton, { IconButtonProps as MuiIconButtonProps } from '@material-ui/core/IconButton';
import MuiList from '@material-ui/core/List';
import MuiListItem, { ListItemProps as MuiListItemProps } from '@material-ui/core/ListItem';
import MuiListItemIcon from '@material-ui/core/ListItemIcon';
import MuiListItemText, { ListItemTextProps as MuiListItemTextProps } from '@material-ui/core/ListItemText';
import { PaletteColor } from '@material-ui/core/styles/createPalette';
import MuiAddIcon from '@material-ui/icons/Add';
import React, { forwardRef } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import styled from 'styled-components';

import { MediaDevices } from '../../../App';
import { unitcomparePage } from '../../../constants';
import muiTheme from '../../../constants/theme';
import palette from '../../../constants/theme/palette';


export interface MainMenuProps {
  open: boolean;
  hover: boolean;
  menuOpenWidth: number;
  menuClosedWidth: number;
  menuOpenSpeed: number;
  selectedItemPath?: string;
  dashBoardEditEnabled: boolean;
  mediaDevices: MediaDevices;
  staticTemplates: API_GET.Template[];
  customTemplates: API_GET.Template[];
  onItemClick?: (templateId: number, templatePath: string) => void;
  /**
 * Fired when a user clicks on the open/close menu iconbutton.
 */
  onMenuClick: () => void;
  /**
 * Fired when a user hovers over the menu
 */
  onMenuEnter?: () => void;
  /**
  * Fired when a user leaves the menu
  */
  onMenuLeave?: () => void;
  /**
   * Fired when FAB is clicked
  */
  onAddTemplate: () => void;
}

const StyledMuiDrawer = styled(({ isMobile, menuOpen, menuOpenWidth, menuClosedWidth, menuOpenSpeed, ...drawerProps }) => <MuiDrawer {...drawerProps} />)`
  && {
    .MuiDrawer-paper {
      transition: ${({ menuOpenSpeed }) => `box-shadow 0ms, width ${menuOpenSpeed}ms ease-in-out, transform ${menuOpenSpeed}ms ease-in-out;`};
      /* seems like removing the shadow has some sort of a delay without this */
      width: ${({ menuOpen, menuOpenWidth, menuClosedWidth }) => menuOpen ? menuOpenWidth : menuClosedWidth}px;
      /* Note!: This should be the same as the height of the toolbar */
      padding-right: ${({ menuOpen }) => menuOpen ? muiTheme.spacing(1) : 0}px;
      overflow-y: ${({ menuOpen }) => menuOpen ? 'auto' : 'hidden'};
      background-color: #2b4899;

      /* Mobile only */
      left: ${({ isMobile, menuClosedWidth }) => isMobile ? `-${menuClosedWidth / 16}rem` : 0};
      transform: ${({ menuOpen, menuClosedWidth, isMobile }) => isMobile && menuOpen ? `translateX(${menuClosedWidth / 16}rem)` : `translateX(0)`};
    }
  }
` as React.ComponentType<{ isMobile: boolean, menuOpen: boolean, menuOpenWidth: number; menuOpenSpeed: number; menuClosedWidth: number; } & MuiDrawerProps>;

const StyledMenuItemOpen = styled(({ indent, ...menuItemProps }) => <MenuItemOpen {...menuItemProps} />)`
  &&& {
    height: 42px;
    border-top-right-radius: 21px;
    border-bottom-right-radius: 21px;
    margin: ${muiTheme.spacing(1)}px 0;
    // Voorlopig ff de indent uit ivm duidelijke verspringing
    // padding-left: ${({ indent }) => indent ? muiTheme.spacing(3.25) : muiTheme.spacing(3.25)}px;
    padding-left: ${muiTheme.spacing(3.25)}px;
    background: ${({ selected }) => selected ? '#081A5D' : null};
    animation:  ${({ indent }) => indent ? 'slideIn 0.3s ease-in-out forwards' : null};
    @keyframes slideIn{
      from{
       padding-left: ${muiTheme.spacing(3.25)}px;
      }
      to{
        padding-left: ${muiTheme.spacing(4.25)}px;
      }
    }
    &:hover{
      background: #a5aac7;
    };
    .MuiIcon-root {
        color:  ${palette.colors.white.main}
      // color: ${({ selected }) => selected ? (palette.primary as PaletteColor).main : (palette.secondary as PaletteColor).main};
    }
  }
` as React.ComponentType<{ indent?: boolean; } & MenuItemProps>;

const StyledMenuItemClosed = styled(({ ...menuItemProps }) => <MenuItemClosed {...menuItemProps} />)`
  &&& {
    height: 42px;
    margin: ${muiTheme.spacing(1)}px 0;
    padding-left: ${muiTheme.spacing(1.8)}px;
    background-color: transparent;

    .MuiIconButton-root {
      color:  ${palette.colors.white.main};
      background-color: ${({ selected }) => selected ? '#081A5D' : null};
    }
  }
` as React.ComponentType<MenuItemProps>;

const StyledMuiDivider = styled(({ menuOpen, bottomSpacing, ...dividerProps }) => <MuiDivider {...dividerProps} />)`
  && {
    opacity: 0.38;
    background-color: #fff;
    margin-bottom: ${({ bottomSpacing }) => bottomSpacing ? `${muiTheme.spacing(bottomSpacing)}px` : null};
    margin-left: ${({ menuOpen }) => menuOpen ? muiTheme.spacing(2) : muiTheme.spacing(1)}px;
    margin-right: ${({ menuOpen }) => menuOpen ? muiTheme.spacing(2) : muiTheme.spacing(1)}px;
  }
`;

const StyledMuiIconButton = styled(({ isDisabled, ...iconButtonProps }) => <MuiIconButton {...iconButtonProps} />)`
  && {
    opacity: ${({ isDisabled }) => isDisabled ? 0.35 : 1};
  }
`as React.ComponentType<{ isDisabled: boolean; } & MuiIconButtonProps>;


const StyledMuiFABOpen = styled(MuiFloatingActionButton)`
  white-space: nowrap;
  overflow: hidden;
  text-align: right;
  &&& {
    .MuiFab-label {
      align-items: center;
      line-height: initial;
    }
    .MuiSvgIcon-root {
      margin-left: -0.25rem;
      margin-right: 0.75rem;
    }
    .MuiIconButton-root {
      color:  ${palette.colors.white.main};
      padding-top: 4px;
    }
  }
`;

const StyledMuiFABClosed = styled(MuiFloatingActionButton)`
  &&& {
    width: 48px;
    height: 48px;

    .MuiIconButton-root {
      color:  ${palette.colors.white.main};
    }
  }
`;

const StyledMuiFABListItem = styled(MuiListItem)`
  &&& {
    padding-left: ${muiTheme.spacing(1.8)}px;
    padding-right: 0;
    min-width: 100%;

    .MuiIconButton-root {
      color:  ${palette.colors.white.main};
    }
  }
` as React.ComponentType<MuiListItemProps>;

const StyledMuiMenuItemOpenText = styled(MuiListItemText)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #fff;
` as React.ComponentType<MuiListItemTextProps>;

interface MenuItemProps {
  id: number;
  to: string;
  path: string;
  text: string;
  iconName: string;
  selected: boolean;
  disabled?: boolean;
  className?: string;
  onItemClick: (itemId: number, path: string) => void;
}

const MenuItemOpen = (props: MenuItemProps) => {
  const {
    id,
    to, // = '/dashboard/formatie-bezetting',
    path,
    text,
    iconName,
    selected,
    className,
    onItemClick,
    disabled = false
  } = props;

  // TODO: kan alweer makkelijker : https://material-ui.com/guides/composition/#link
  const menuItemRouterLink = React.useMemo(() =>
    forwardRef((props: NavLinkProps, ref: React.Ref<HTMLAnchorElement>) => <NavLink exact {...props} innerRef={ref} to={to} />),
    [to]
  );

  return (
    <MuiListItem
      button
      color={'primary'}
      disabled={disabled}
      selected={selected}
      className={className}
      onClick={() => onItemClick(id, path)}
      component={menuItemRouterLink as any} //TODO: Why are you complaining TypeScript..
    >
      <MuiListItemIcon>
        <MuiIcon title={text}>{iconName}</MuiIcon>
      </MuiListItemIcon>
      {/* TODO: could use mui typography with nowrap instead */}
      <StyledMuiMenuItemOpenText primary={text} />
    </MuiListItem>
  );
};

const MenuItemClosed = (props: MenuItemProps) => {
  const {
    id,
    to,
    text,
    path,
    iconName,
    // selected,
    className,
    onItemClick,
    disabled = false
  } = props;

  const menuItemRouterLink = React.useMemo(() =>
    forwardRef((props: NavLinkProps, ref: React.Ref<HTMLAnchorElement>) => <NavLink exact {...props} innerRef={ref} to={to} />),
    [to]
  );

  return (
    <MuiListItem
      disabled={disabled}
      className={className}
      component={menuItemRouterLink as any} //TODO: Why are you complaining TypeScript..
    >
      <MuiIconButton
        title={text}
        onClick={() => onItemClick(id, path)}
      >
        <MuiIcon>{iconName}</MuiIcon>
      </MuiIconButton>
    </MuiListItem>
  );
};

/**
 *
 */
const MainMenu = (props: MainMenuProps) => {
  const {
    open,
    hover,
    onMenuClick,
    mediaDevices,
    menuOpenWidth,
    menuClosedWidth,
    menuOpenSpeed,
    selectedItemPath,
    onAddTemplate,
    dashBoardEditEnabled,
    staticTemplates: originalStaticTemplates = [],
    customTemplates: originalCustomTemplates = [],
    onItemClick = () => { },
  } = props;

  const isMobile = mediaDevices.mobile;
  const isMobilePhone = mediaDevices.mobilePhone;


  // TODO: sort from the outside?
  const customTemplates = originalCustomTemplates
    // sort custom by id (order of creation)
    .sort((a, b) => a.id < b.id ? -1 : 1);

  const staticTemplates = originalStaticTemplates
    .sort((a, b) => {
      // overview always first
      if (a.isOverview === true) return -1;
      if (b.isOverview === true) return 1;
      // else sort by name
      return a.name < b.name ? -1 : 1;
    });

  const handleMouseEnter = () => {
    
  }

  const handleMouseLeave = () => {
   
  };

  return (
    <StyledMuiDrawer
      menuOpen={open}
      variant={'permanent'}
      isMobile={isMobile}
      menuOpenWidth={menuOpenWidth}
      menuClosedWidth={menuClosedWidth}
      menuOpenSpeed={menuOpenSpeed}
      PaperProps={{ elevation: open ? 10 : 0 }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      id={'OnboardingClick'}
    >
      <MuiList>
        <StyledMuiFABListItem>
          <StyledMuiIconButton
            disabled={dashBoardEditEnabled}
            isDisabled={dashBoardEditEnabled}
            color={'inherit'}
            aria-label={'Menu'}
            onClick={onMenuClick}
            // TODO: omdraaien van de  is voelt als een hack en moet anders?
            onMouseEnter={handleMouseLeave}
            onMouseLeave={handleMouseEnter}
          >
            {!!open && !hover ? <MuiIcon>close</MuiIcon> : <MuiIcon>menu</MuiIcon>}
          </StyledMuiIconButton>
        </StyledMuiFABListItem>
        {!isMobilePhone &&
          <StyledMuiFABListItem
            onClick={onAddTemplate}
            // TODO: omdraaien van de  is voelt als een hack en moet anders?
            onMouseEnter={!open ? handleMouseLeave : () => { }}
            onMouseLeave={open ? () => { } : handleMouseEnter}
          >
            {!!open &&
              <StyledMuiFABOpen variant={'extended'} >
                <MuiAddIcon />
                Nieuw dashboard
                  </StyledMuiFABOpen>
            }
            {!open &&
              <StyledMuiFABClosed
                title={'Nieuw dashboard'}
              >
                <MuiAddIcon />
              </StyledMuiFABClosed>
            }
          </StyledMuiFABListItem>
        }
      </MuiList>
      {customTemplates.length > 0 &&
        <React.Fragment>
          {!!open &&
            <span>
              <StyledMenuItemOpen
                id={-1}
                path={'overzicht'}
                text={'Mijn dashboards'}
                onItemClick={onItemClick}
                iconName={'queue_play_next'}
                to={`/dashboards/overzicht`}
                selected={(selectedItemPath || '').includes('/dashboards/')}
              />
            </span>
          }
          {!open &&
            <StyledMenuItemClosed
              id={-1}
              text={'Mijn dashboards'}
              path={'overzicht'}
              onItemClick={onItemClick}
              iconName={'queue_play_next'}
              to={`/dashboards/overzicht`}
              selected={(selectedItemPath || '').includes('/dashboards/')}
            />
          }
        </React.Fragment>
      }

      <StyledMuiDivider />

      {!!open &&
        <StyledMenuItemOpen
          id={unitcomparePage.id}
          onItemClick={onItemClick}
          path={unitcomparePage.path}
          text={unitcomparePage.name}
          to={`/${unitcomparePage.path}`}
          iconName={unitcomparePage.iconName}
          selected={`/${unitcomparePage.path}` === selectedItemPath}
        />
      }
      {!open &&
        <StyledMenuItemClosed
          id={unitcomparePage.id}
          onItemClick={onItemClick}
          path={unitcomparePage.path}
          text={unitcomparePage.name}
          to={`/${unitcomparePage.path}`}
          iconName={unitcomparePage.iconName}
          selected={`/${unitcomparePage.path}` === selectedItemPath}
        />
      }
      {!!open &&
        <StyledMenuItemOpen
          id={-2}
          onItemClick={onItemClick}
          path={'map'}
          text={'Kaart demo'}
          to={`/map`}
          iconName={'map'}
          selected={`/map` === selectedItemPath}
        />
      }
      {!open &&
        <StyledMenuItemClosed
          id={-2}
          onItemClick={onItemClick}
          path={'map'}
          text={'Kaart demo'}
          to={`/map`}
          iconName={'map'}
          selected={`/map` === selectedItemPath}
        />
      }
      <StyledMuiDivider bottomSpacing={2} />
      {staticTemplates
        .filter(({ enabled }) => enabled !== false)
        .map(({ iconName, id, path, name, shortName }, i) =>
          (<React.Fragment key={id}>
            {!!open &&
              <StyledMenuItemOpen
                id={id}
                path={path}
                indent={i > 0} // TODO: is dit een probleem?
                iconName={iconName}
                to={`/kpis/${path}`}
                onItemClick={onItemClick}
                text={!!shortName ? shortName : name}
                selected={`/kpis/${path}` === selectedItemPath}
              />
            }
            {!open &&
              <StyledMenuItemClosed
                id={id}
                text={name}
                path={path}
                iconName={iconName}
                to={`/kpis/${path}`}
                onItemClick={onItemClick}
                selected={`/kpis/${path}` === selectedItemPath}
              />
            }
          </React.Fragment>
          )
        )}
    </StyledMuiDrawer>
  );
}

export default MainMenu;