import MuiAddToQueueIcon from '@material-ui/icons/AddToQueue';
import useTheme from '@material-ui/styles/useTheme';
import * as H from 'history';
import { TilePlaceholder } from 'ppd-library/components/organisms/Tile';
import React, { createRef, useEffect, useRef, useState } from 'react';

import { MediaDevices } from '../App';
import { GridBreakpoint, GridLayout } from '../components/layout/GridLayout';
import { PageHeader } from '../components/layout/PageHeader';
import DeleteActionButton from '../components/tiles/action-buttons/DeleteActionButton/DeleteActionButton';
import { Container } from '../components/tiles/Container';
import { httpDelete, httpPut } from '../utils/http';
import { EditActionButton, EditDoneActionButton } from './dashboardpage/components/action-buttons';
import { StyledDashboardPageWrapper } from './dashboardpage/components/StyledDashboardPageWrapper';
import { StyledGridTileWrapper } from './dashboardpage/components/StyledGridTileWrapper';
import {
  columnsPerBreakpoint,
  containerBreakpoints,
  createGridLayouts,
  rowHeight,
} from './dashboardpage/grid-utils';
import { useDashboardPageRefs, useDashboardPageState } from './dashboardpage/hooks';
import { DashboardPageBaseProps } from './dashboardpage/interfaces';
import { getQueryParams } from './dashboardpage/utils';
import { CloneActionButton } from '../components/tiles/action-buttons/CloneActionButton';

// eslint-disable-next-line
const { API_URL, DATA_URL, SHOW_IDS } = ppd.appConfig;

interface CustomDashboardOverviewPageProps extends DashboardPageBaseProps {
  history: H.History;
  mediaDevices: MediaDevices; // TODO: redux
  customTemplates: API_GET.Template[]; // TODO: redux
  onDeleteTemplate: (templateId: number) => void;
  onDuplicateDashboard: (templateId: number) => void;
  onTitleChange: (template: API_GET.Template) => void;
}

const createFakeTemplateTile = (id: number, title: string, path: string) => {
  const fakeTile: API_GET.TemplateTileDetail = {
    id: id,
    path: path,
    tileId: -1,
    enabled: true,
    templateId: id,
    placeholderText: null,
    organisationUnit: null,
    selectedDataKeyType: { id: 1, name: 'oe' },
    selectedViewType: { id: 6, name: 'textview' },
    selectedTabId: null,
    tile: {
      id: -1,
      title: title,
      enabled: true,
      subtitle: null,
      themeHelpId: -1,
      tileHelpId: null,
      linkedTileId: null,
      placeholderText: null,
      detailReport: undefined,
      enabledForOELevel: null,
      actuality: 'Niet van toepassing',
      availableDataKeyTypes: [{ id: 1, name: 'oe' }],
      tabs: [
        {
          id: -1,
          title: null,
          enabled: true,
          placeholderText: null,
          views: [
            {
              id: -1,
              enabled: true,
              description: '',
              viewHelpItems: [],
              placeholderText: null,
              viewType: { id: 6, name: 'textview' },
              dataKeys: [{ id: -1, value: 'blank', type: { id: 1, name: 'oe' } }],
              component: {
                id: -1, name: 'contentCover', properties: { content: <MuiAddToQueueIcon style={{ fill: '#E4E4E4' }} />, altText: 'Ga naar dit  dashboard' }
                // component: { id: -1, name: 'contentCover', properties: { content: 'https://cdn.pixabay.com/photo/2013/04/01/03/45/cat-98359_960_720.jpg', contentType: 'path', backgroundSize: 'contain', altText: 'Ga naar dit  dashboard'}
              }
            }
          ]
        }
      ]
    }
  };

  return fakeTile;
};

// TODO: dit moet generieker op basis van de breakpoints?
const createFakeLayouts = (templateTiles: API_GET.TemplateTileDetail[]) => {
  const xsTileWidth = 1;
  const xsTileHeight = 4;
  const xs: API_GET.TemplateLayout = {
    id: -1,
    templateId: -1,
    layoutSize: 'xs',
    items: templateTiles.map(({ id }, index) => {
      return { id, templateTileId: id, x: 0, y: index * xsTileHeight, w: xsTileWidth, h: xsTileHeight };
    })
  };

  const maxSmCols = 8;
  const smTileWidth = 4;
  const smTileHeight = 4;
  let smCol = 0;
  let smRow = 0;
  const sm: API_GET.TemplateLayout = {
    id: -2,
    templateId: -1,
    layoutSize: 'sm',
    items: templateTiles.map(({ id }) => {

      if (smCol >= maxSmCols) {
        smCol = 0;
        smRow = smRow + smTileHeight;
      }

      const item = { id, templateTileId: id, x: smCol, y: smRow, w: smTileWidth, h: smTileHeight };

      smCol = smCol + smTileWidth;
      return item;
    })
  };

  const maxMdCols = 12;
  const mdTileWidth = 4;
  const mdTileHeight = 4;
  let mdCol = 0;
  let mdRow = 0;
  const md: API_GET.TemplateLayout = {
    id: -3,
    templateId: -1,
    layoutSize: 'md',
    items: templateTiles.map(({ id }) => {

      if (mdCol >= maxMdCols) {
        mdCol = 0;
        mdRow = mdRow + mdTileHeight;
      }

      const item = { id, templateTileId: id, x: mdCol, y: mdRow, w: mdTileWidth, h: mdTileHeight };

      mdCol = mdCol + mdTileWidth;
      return item;
    })
  };

  const lg: API_GET.TemplateLayout = { ...md, id: -4, layoutSize: 'lg' };

  const maxXlgCols = 24;
  const xlgTileWidth = 6;
  const xlgTileHeight = 5;
  let xlgCol = 0;
  let xlgRow = 0;
  const xlg: API_GET.TemplateLayout = {
    id: -5,
    templateId: -1,
    layoutSize: 'xlg',
    items: templateTiles.map(({ id }) => {

      if (xlgCol >= maxXlgCols) {
        xlgCol = 0;
        xlgRow = xlgRow + xlgTileHeight;
      }

      const item = { id, templateTileId: id, x: xlgCol, y: xlgRow, w: xlgTileWidth, h: xlgTileHeight };

      xlgCol = xlgCol + xlgTileWidth;
      return item;
    })
  };

  const xlg1: API_GET.TemplateLayout = { ...xlg, id: -6, layoutSize: 'xlg1' };
  const xlg2: API_GET.TemplateLayout = { ...xlg, id: -7, layoutSize: 'xlg2' };

  return [xs, sm, md, lg, xlg, xlg1, xlg2];
};

const CustomDashboardOverviewPage = (props: CustomDashboardOverviewPageProps) => {
  const {
    history,
    // account,
    menuOpen,
    mediaDevices,
    menuOpenWidth,
    menuOpenSpeed,
    onTitleChange,
    onLoadingChange,
    onDeleteTemplate,
    onDuplicateDashboard,
    onDashboardEditEnabledChange,
    customTemplates = []
  } = props;

  const urlQueryParamValues = getQueryParams(history.location);
  const showDevIds = SHOW_IDS || urlQueryParamValues['show_ids'] === 'true';
  const isMobile = mediaDevices.mobile;

  const [templateTiles, setTemplateTiles] = useState<API_GET.TemplateTileDetail[]>([]);
  const stopGridLayoutWidthChangeRef = useRef(false);

  const theme = useTheme<any>();
  // const { id: accountId } = account;

  const {
    editEnabled, setEditEnabled,
    // tileDimensions,
    currentBreakpoint, setCurrentBreakpoint,
  } = useDashboardPageState();

  const {
    gridLayoutsRef,
    gridTileWrappersRef
  } = useDashboardPageRefs();

  const handleBreakpointChange = (newBreakPoint: string) => {
    stopGridLayoutWidthChangeRef.current = true;

    setCurrentBreakpoint(newBreakPoint as GridBreakpoint);

    console.log(newBreakPoint);
  };

  const handleEditLayoutClick = () => {
    onDashboardEditEnabledChange(true); // TODO: gebruik redux
    setEditEnabled(true);
  };

  const handleEditLayoutDoneClick = () => {
    onDashboardEditEnabledChange(false);
    setEditEnabled(false);
  };

  const handleDeleteClick = async (templateId: number) => {
    if (editEnabled) {
      onLoadingChange(true);
      try {
        await httpDelete(`${API_URL}/templates/${templateId}`);

        onDeleteTemplate(templateId);

        // TODO: delete notificatie tonen dmv snackbar
      } catch (e) {
        console.error(e);
      } finally {
        onLoadingChange(false);
      }
    }
  }

  const handleCloneClick = async (templateId: number) => {
    onLoadingChange(true);
    onDuplicateDashboard(templateId);
    onLoadingChange(false);
  };

  const handleTitleInputBlur = async (value: string, templateId: number) => {
    if (editEnabled) {
      try {
        const updatedTemplate = await httpPut<API_PUT.Template, API_GET.Template>(`${API_URL}/templates/${templateId}`, {
          name: value
        });
        const filteredCustomTemplates = customTemplates.filter(({id}) => id !== templateId);
        const updatedFakeTemplateTiles = [...filteredCustomTemplates, updatedTemplate].map(({ id, name, path }) => createFakeTemplateTile(id, name, path));

        setTemplateTiles(updatedFakeTemplateTiles);
        onTitleChange(updatedTemplate);

        // TODO: update notificatie tonen dmv snackbar
      }
      catch (e) {
        console.error(e);
      }
    }
  }

  useEffect(() => {

    onLoadingChange(true);
    const fakeTemplateTiles = customTemplates.map(({ id, name, path }) => createFakeTemplateTile(id, name, path))
    const layouts = createFakeLayouts(fakeTemplateTiles);

    const gridLayouts = createGridLayouts(layouts);
    gridLayoutsRef.current = gridLayouts;

    setTemplateTiles(fakeTemplateTiles);

    // Scrolls the page to top.
    window.scrollTo(0, 0);
    onLoadingChange(false);
    // eslint-disable-next-line
  }, [customTemplates]);


  return (
    <>
     {showDevIds &&
        <div style={{
          right: '32px',
          zIndex: 9999999,
          position: 'fixed',
          marginTop: '-24px',
        }}>
          {currentBreakpoint}
        </div>
      }
      {customTemplates.length === 0 &&
        <TilePlaceholder
          message={'Er zijn (nog) geen eigen dashboards aangemaakt'}
          theme={{ palette: { secondary: { main: theme.palette.secondary.light } } }}
        />
      }
      {customTemplates.length > 0 &&
        <>
          <PageHeader
            showHelp={false}
            menuOpen={menuOpen}
            mediaDevices={mediaDevices}
            menuOpenSpeed={menuOpenSpeed}
            menuOpenWidth={menuOpenWidth}
            hideGlobalUnitSelection={true}
            breadCrumbItems={
              [{ iconName: '', text: `Dashboards`, path: null },
              { iconName: 'dashboard', text: 'Overzicht', path: '/dashboards/overzicht' }
              ]
            }
            actionButtons={
              <>
                {!editEnabled &&
                  <EditActionButton
                    disabled={menuOpen}
                    isMobile={isMobile}
                    onClick={handleEditLayoutClick}
                    label={'Dashboard overzicht aanpassen'}
                  />
                }
                {editEnabled &&
                  <EditDoneActionButton
                    isMobile={isMobile}
                    onClick={handleEditLayoutDoneClick}
                  />
                }
              </>
            }
          />
          <StyledDashboardPageWrapper
            isMobile={isMobile}
            menuOpen={menuOpen}
            menuOpenSpeed={menuOpenSpeed}
            menuOpenWidth={menuOpenWidth}
            className={'dashboardPageWrapper'}
          >
            <GridLayout
              isEditable={false} // NOTE!: gridlayout mag (nog) niet aangepast worden door gebruiker.
              onResize={() => { }}
              rowHeight={rowHeight}
              onResizeStop={() => { }}
              onWidthChange={() => { }}
              breakpoint={currentBreakpoint}
              layouts={gridLayoutsRef.current} // TODO: generate
              breakpoints={containerBreakpoints}
              onBreakpointChange={handleBreakpointChange}
              columnsPerBreakpoint={columnsPerBreakpoint}
            >
              {templateTiles.map((templateTile) => {

                const {
                  tile,
                  path,
                  templateId,
                  // tileId,
                  // id: templateTileId,
                  selectedDataKeyType
                } = templateTile;

                // TODO: tiledimensions zijn altijd hetzelfde 
                // TODO: dit buiten render plaatsen
                if (!gridTileWrappersRef.current[`${templateId}`]) {
                  gridTileWrappersRef.current[`${templateId}`] = createRef();
                }
                // TODO: dit buiten render plaatsen
                const { width = undefined, height = undefined } = {} as any;//tileDimensions[`${templateId}`] || {};

                return (
                  <StyledGridTileWrapper
                    // NOTE: the grid uses the react key to bind to the layout
                    editMode={false}
                    key={`${templateId}`}
                    innerRef={gridTileWrappersRef.current[`${templateId}`]}
                  >
                    <>
                      {showDevIds &&
                        <div style={{ position: 'absolute' }}>templateId: {templateId}</div>
                      }

                      <Container
                        tile={tile}
                        editable={false}
                        showHelp={false}
                        dataUrl={DATA_URL}
                        isCleanTile={true}
                        useMockData={false}
                        id={templateId}
                        showIds={showDevIds}
                        editMode={editEnabled}
                        tileWidth={width as number}
                        tileHeight={height as number}
                        selectedDataKeyType={selectedDataKeyType}
                        initialViewType={{ name: 'textview', id: 6 }}
                        selectedOrganisationUnit={{ code: 'NP00000', name: 'Business Data Challengers' } as API.OrganisationUnit}
                        onContentClick={!editEnabled ? () => history.push(`/dashboards/${path}`) : undefined}
                        actionButton={
                          editEnabled ?
                          <>
                            <DeleteActionButton
                              label={'Dashboard verwijderen?'}
                              onDeleteClick={() => handleDeleteClick(templateId)}
                            />
                             <CloneActionButton
                                onCloneClick={() => handleCloneClick(templateId)}
                              />
                            </>
                            : null
                        }
                        titleEditable={editEnabled}
                        onTitleChange={(value: string) => handleTitleInputBlur(value, templateId)}
                      />
                    </>
                  </StyledGridTileWrapper>
                );
              })}
            </GridLayout>
          </StyledDashboardPageWrapper>
        </>
      }
    </>
  );
};

export default CustomDashboardOverviewPage;