import React, { useState, useEffect, useRef } from 'react';
import GoogleMapReact from 'google-map-react';
import styled from 'styled-components';
import MarkerClusterer from '@googlemaps/markerclustererplus';
import MuiButton from '@material-ui/core/Button';
import { render } from 'react-dom';

// eslint-disable-next-line
const { BASE_URL, MAP_KEY } = ppd.appConfig;

export interface MapPageProps {
  onLoadingChange: (loading: boolean) => void; // TODO: redux
}

interface Location {
  address: string;
  lat: number;
  lng: number;
}

interface ZonnePanelenFeature {
  id: number;
  type: string;
  geometry: {
    type: string;
    coordinates: [number, number];
  }
  properties: {
    Functie: string;
    Gebruiksdoel: string;
    VAL19: number;
    VAL20: number;
    Panelen_2019: number;
    Panelen_2020: number;
  }
}

interface ZonnePanelenGeoJSON {
  features: ZonnePanelenFeature[];
}

const StyledMapPage = styled.div`

.google-map {
  width: 100%;
  height: 600px;
}

.hiddenCluster {
  display: none;
}
`;


// const coordinatenThuis: Location = {
//   address: 'Zuilen 137, Groningen',
//   lat: 53.23734846205459,
//   lng: 6.56308888501684
// };

const coordinatenAmsterdam: Location = {
  address: 'Amsterdam',
  lat: 52.3707881781729,
  lng: 4.903272363138098
}

const MapPage = (props: MapPageProps) => {
  const {
    onLoadingChange
  } = props;

  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState({ zonnePanelen: false, stadsDelen: false });
  const [zonnepanelenGeoJson, setZonnepanelenGeoJson] = useState<ZonnePanelenGeoJSON | null>(null);
  const [stadsdelenGeoJson, setStadsdelenGeoJson] = useState<any | null>(null);

  // const mapRef = useRef<any | null>(null);
  const [map, setMap] = useState<any | null>(null);
  const mapsRef = useRef<any | null>(null);
  const markersRef = useRef<any | null>(null);
  const infoWindowRef = useRef<any | null>(null);
  const mapElementRef = useRef<Element | null>(null);
  const stadsDelenFeaturesRef = useRef<any | null>(null);
  const markerClustererRef = useRef<MarkerClusterer | null>(null);
  useMapControl(
    map,
    mapsRef.current ?
      mapsRef.current.ControlPosition.TOP_CENTER : '',
    <StyledResetControlButton onClick={() => { map.setZoom(11); }} />
  );

  useEffect(() => {
    const getZonnePanelenData = async () => {
      const response = await fetch(
        `${BASE_URL}/assets/zonnepanelen.json`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',

          },
          credentials: 'omit'
        }
      );

      const zon: ZonnePanelenGeoJSON = await response.json();

      setZonnepanelenGeoJson(zon);
    };

    const getStadsdelenData = async () => {
      const response = await fetch(
        `${BASE_URL}/assets/stadsdelen.json`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',

          },
          credentials: 'omit'
        }
      );

      const stads: any = await response.json();

      setStadsdelenGeoJson(stads);
    };

    getZonnePanelenData();
    getStadsdelenData();
  }, []);

  useEffect(() => {

    if (!!stadsdelenGeoJson && isMapLoaded) {

      // const map = mapRef.current;
      const maps = mapsRef.current;
      const infoWindow = infoWindowRef.current;
      const features = map.data.addGeoJson(stadsdelenGeoJson);

      stadsDelenFeaturesRef.current = features;

      features.forEach((feature: any) => {
        var bounds = new maps.LatLngBounds();

        feature.getGeometry().getArray().forEach(function (path: any) {

          path.getArray().forEach(function (latLng: any) { bounds.extend(latLng); })

        });
        feature.setProperty('bounds', bounds);

        feature.setProperty('featureNum', features.length - 1);
        feature.setProperty('name', feature.getProperty('Stadsdeel'));
        feature.setProperty('data', { markerCount: 0, Panelen_2019: 0, Panelen_2020: 0 })
      });

      map.data.setStyle((feature: any) => {
        let color = '#666';

        if (!!feature.getProperty('color')) {
          color = feature.getProperty('color');
        }

        return {
          fillColor: color
        };
      });

      map.data.addListener('click', function (event: any) {
        if (!!event.feature.getProperty('Stadsdeel') && map.getZoom() === 11) {
          // const bounds = event.feature.getProperty('bounds');
          const boundCenter = event.feature.getProperty('bounds').getCenter();

          map.setCenter(boundCenter);
          map.setZoom(13);
          // NOTE!: need to call twice because of bug in combination with 'restriction'
          map.setCenter(boundCenter);
          // map.fitBounds(bounds); // should call this one if working with buurt --> wijk --> straat
        }
      });

      map.data.addListener('mouseover', function (event: any) {
        if (!!event.feature.getProperty('Stadsdeel')) {
          const boundCenter = event.feature.getProperty('bounds').getCenter();

          if (map.getZoom() === 11) {
            const name = event.feature.getProperty('Stadsdeel');
            const data = event.feature.getProperty('data');
            const { markerCount, Panelen_2019, Panelen_2020 } = data;

            const myHTML = `
            <h3>${name}</h3>
            <ul style='list-style: none; padding-left: 0;'>
              <li>Gebouwen met panelen: ${markerCount}</li>
              <li>Panelen 2019 toegevoegd: ${Panelen_2019}</li>
              <li>Panelen 2020 toegevoegd: ${Panelen_2020}</li>
            </ul>
            `;

            infoWindow.setContent(`<div style='width:200px; text-align: center;'>${myHTML}</div>`);

            infoWindow.setPosition(boundCenter);
            infoWindow.setOptions({ pixelOffset: new maps.Size(0, -30) });
            infoWindow.open(map);

            map.data.revertStyle();
            map.data.overrideStyle(event.feature, { fillColor: '#111' });
          }
        }
      });

      map.data.addListener('mouseout', function (event: any) {
        map.data.revertStyle();
        if (!!event.feature.getProperty('Stadsdeel')) {
          event.feature.setProperty('isHovering', true);
        }
      });

      setIsDataLoaded((currentValue) => ({ ...currentValue, stadsDelen: true }));
    }

    // eslint-disable-next-line
  }, [stadsdelenGeoJson, isMapLoaded]);

  useEffect(() => {

    if (!!zonnepanelenGeoJson && isMapLoaded) {
      // const map = mapRef.current;
      const maps = mapsRef.current;
      const infoWindow = infoWindowRef.current;

      const markers = zonnepanelenGeoJson.features.map((feature) => {
        const [lng, lat] = feature.geometry.coordinates;

        const latLng = new maps.LatLng(lat, lng);

        const marker = new maps.Marker({ position: latLng, map, feature });
        marker.setVisible(false);

        marker.addListener('click', () => {
          infoWindow.setContent(`
          <p>Panelen toegevoegd 2019: ${feature.properties.Panelen_2019}</p>
          <p>Panelen toegevoegd 2020: ${feature.properties.Panelen_2020}</p>
          `);
          infoWindow.open(map, marker);

        });

        return marker;
      });

      const markerClusterer =
        new MarkerClusterer(map, markers, {
          imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
          gridSize: 50,
          minimumClusterSize: 2,
          averageCenter: true,
          maxZoom: 16,
          clusterClass: 'hiddenCluster'
          // ignoreHidden: true,
        });

      markersRef.current = markers;
      markerClustererRef.current = markerClusterer;

      setIsDataLoaded((currentValue) => ({ ...currentValue, zonnePanelen: true }));
    }

    // eslint-disable-next-line
  }, [zonnepanelenGeoJson, isMapLoaded]);

  useEffect(() => {
    if (!!stadsdelenGeoJson && !!zonnepanelenGeoJson && isMapLoaded) {
      onLoadingChange(false);
    }

    // eslint-disable-next-line
  }, [zonnepanelenGeoJson, stadsdelenGeoJson, isMapLoaded]);

  useEffect(() => {
    if (isDataLoaded.stadsDelen && isDataLoaded.zonnePanelen) {

      const zonneMarkers = markersRef.current;
      const stadsDelenFeatures = stadsDelenFeaturesRef.current;

      stadsDelenFeatures.forEach((stadsFeature: any) => {
        const bounds = stadsFeature.getProperty('bounds');
        let stadsFeatureData = { ...stadsFeature.getProperty('data') };

        zonneMarkers.forEach((marker: any) => {
          const markerFeature = marker.feature;
          // const [lng, lat] = marker.feature.geometry.coordinates;

          if (bounds.contains(marker.getPosition()) && !marker.isCounted) {
            // heel raar maar op 1 of andere manier vallen 216 markers in westpoort terwijl westpoort er geen hoort te hebben
            if (stadsFeature.getProperty('Stadsdeel') !== 'Westpoort') {
              stadsFeatureData.markerCount = stadsFeatureData.markerCount + 1;
              stadsFeatureData.Panelen_2019 = stadsFeatureData.Panelen_2019 + markerFeature.properties.Panelen_2019;
              stadsFeatureData.Panelen_2020 = stadsFeatureData.Panelen_2020 + markerFeature.properties.Panelen_2020;
              // om te voorkomen dat een marker dubbel geteld wordt..
              marker.isCounted = true;
            }
          }
        });

        stadsFeature.setProperty('data', stadsFeatureData);
      });
    }
  }, [isDataLoaded]);

  const handleApiLoaded = (map: any, maps: any, element: Element | null) => {
    // mapRef.current = map;
    mapsRef.current = maps;
    mapElementRef.current = element;
    setMap(map);

    const infoWindow = new maps.InfoWindow();

    infoWindowRef.current = infoWindow;

    maps.event.addListener(map, 'click', () => {
      infoWindow.close(map);
    });

    maps.event.addListener(map, 'zoom_changed', () => {
      const markers = markersRef.current;
      const markerClusterer = markerClustererRef.current as MarkerClusterer;
      infoWindow.close(map);
      map.data.revertStyle();

      const shouldMarkersBeVisible = map.getZoom() > 11;
      const areMarkersVisible = markers.some((marker: any) => marker.getVisible());

      if(shouldMarkersBeVisible && !areMarkersVisible) {
        // markerClusterer.addMarkers(markers);
       markerClusterer.setClusterClass('cluster');

        markers.forEach((marker: any) => {
          marker.setVisible(true);
        });
      }
      else if(!shouldMarkersBeVisible) {
        // markerClusterer.clearMarkers();
        markerClusterer.setClusterClass('hiddenCluster');

        markers.forEach((marker: any) => {
          marker.setVisible(false);
        });
      }
    });

    setIsMapLoaded(true);
  }

  return (
    <StyledMapPage>
      <div className='map'>
        <h3>Zonnepanelen Amsterdam</h3>
      <p>
        Op deze kaart worden de aantallen zonnepanelen getoond die in 2019 of 2020 zijn toegevoegd aan woonhuizen in Amsterdam.
      </p>
      <ul>
        <li>Klik op een stadsdeel om in te zoomen op dat stadsdeel</li>
        <li>Klik daarna op een 'cluster' icoontje om verder in te zoomen tot je niet meer verder kan zoomen</li>
        <li>In toekomstige versies kunnen dingen toegevoegd worden als
          <ul>
            <li>de buurten --> wijken --> straten op dezelfde manier inzoomen als de stadsdelen</li>
            <li>de stadsdelen --> buurten --> wijken --> straten kleuren op basis van aantallen (heatmap)</li>
            <li>de kaart tonen in een tegel op het dashboard</li>
          </ul>
        </li>
      </ul>
        <div className='google-map'>
          <GoogleMapReact
            defaultZoom={11}
            yesIWantToUseGoogleMapApiInternals
            defaultCenter={coordinatenAmsterdam}
            bootstrapURLKeys={{ key: MAP_KEY, libraries: ['geometry'] }}
            onGoogleApiLoaded={({ map, maps, ref }) => handleApiLoaded(map, maps, ref)}
            options={(maps) => {
              return {
                streetViewControl: false,
                scaleControl: true,
                fullscreenControl: true,
                styles: [{
                  featureType: 'poi.business',
                  elementType: 'labels',
                  stylers: [{
                    visibility: 'off'
                  }]
                }],
                gestureHandling: 'greedy',
                disableDoubleClickZoom: true,
                minZoom: 11,
                maxZoom: 20,
                restriction: {
                  // alleen amsterdam bekijken
                  latLngBounds: { "south": 52.24483982932366, "west": 4.396184655618538, "north": 52.496378415429675, "east": 5.4103600706576005 }
                },
                mapTypeControl: true,
                // mapTypeId: maps.MapTypeId.SATELLITE,
                mapTypeControlOptions: {
                  style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
                  position: maps.ControlPosition.TOP_LEFT,
                  mapTypeIds: [
                    maps.MapTypeId.ROADMAP,
                    maps.MapTypeId.SATELLITE
                  ]
                },

                rotateControl: false,
                zoomControl: true,
                clickableIcons: false
              }
            }}
          >

          </GoogleMapReact>
        </div>
      </div>
    </StyledMapPage>
  );
};

export const useMapControl = (
  map: google.maps.Map | null,
  controlPosition: google.maps.ControlPosition | null,
  children: React.ReactElement
) => {
  useEffect(() => {
    if (map && controlPosition) {
      render(
        <div ref={el => map.controls[controlPosition].push(el as HTMLElement)}>
          {children}
        </div>,
        document.createElement("div")
      );
    }
    return () => {
      if (map && controlPosition) {
        map.controls[controlPosition].clear();
      }
    };
    // eslint-disable-next-line
  }, [map, controlPosition]);
};

const StyledResetControlButton = styled((props) => <ResetControlButton {...props} />)`
&&& {
  margin-top: 16px;
  background-color: #fff;
  border-radius: 0;
}
`;

const ResetControlButton = (props: { onClick: () => void; className: string; }) => {
  const { onClick, className } = props;

  return (
    <MuiButton
      size={'small'}
      color={'primary'}
      onClick={onClick}
      variant={'outlined'}
      className={className}
      id={'reset-control-button'}
    >
      Reset
    </MuiButton>
  );
};

export default MapPage;