import { useState, useEffect } from 'react';

/**
 * Use this hook to calculate what the component height should be, adjusted for the tooltip height.
 * @param originalHeight The original height of the content component.
 * @param legendElementRef A ref object to a Legend element.
 */
export const useLegend = (originalHeight: number, legendElementRef: React.RefObject<HTMLDivElement>) => {
  
  const [legendHeight, setLegendHeight] = useState(0);

  useEffect(() => {
    if (!!legendElementRef.current) {
      const legendRect = (legendElementRef.current as HTMLDivElement).getBoundingClientRect();
      setLegendHeight(legendRect.height);
    }
  }, [originalHeight, legendElementRef]);

  return { legendHeight };
}