import { ComponentsProps } from '@material-ui/core/styles/props';
import { Overrides } from '@material-ui/core/styles/overrides';
import { fonts } from './typography';
import palette from './palette';
import { PaletteColor } from '@material-ui/core/styles/createPalette';

const props: ComponentsProps = {
  MuiButtonBase: {
    disableRipple: false
  },
  MuiCardActionArea: {
    disableRipple: true
  }
};

const overrides: Overrides = {
  MuiCssBaseline: {
    '@global': {
      '@font-face': [fonts.regular, fonts.bold, fonts.icon],
     // '@font-face': [fonts.icon],
      '.material-icons': {
        fontFamily: 'icons',
        lineHeight: 1,
        fontFeatureSettings: "'liga'" /* Support for IE. */
      },
      'strong': {
        fontFamily: '"bold"'
      }
    },
  },
  MuiCardContent: {
    root:{
      flexGrow: 1,
      display: 'flex',
      overflow: 'hidden',
      flexDirection: 'column',
      // padding: '12px'
    }
  },
  MuiTypography: {
    colorTextSecondary: {
      color: '#fff'
    }
  },
  MuiCardHeader: {
    root:{
      // fontSize: '20px',
      // color:  '#0C2497',
      // fontFamily: '"bold"',
     },
    content: {
      overflow: 'hidden'
    }
  },
  // MuiCardActions: {
  //   root:{
  //     padding: '6px'
  //    },
  // },
  MuiListItem: {
    root: {
      fontSize: '1rem',
      '&$selected': {
        color: (palette.primary as PaletteColor).main,
        backgroundColor: (palette.primary as PaletteColor).light,
      }
    },
  },
  MuiMenuItem: {
    root: {
      fontSize: '1rem'
    }
  },
  MuiIcon: {  
    root: {
      fontSize: '1.5rem',
      fontWeight: 400
    },
  },
  MuiSvgIcon: {
    root:{
      width: '1.5rem',
      height: '1.5rem'
    }
  },
  MuiIconButton: {  
    colorPrimary: {
      color: palette.colors.lightBlue.main,
      '&:hover': {
        backgroundColor: (palette.primary as PaletteColor).light
      }
    },
    colorSecondary: {
      color: (palette.secondary as PaletteColor).light,
      // '&:hover': {
      //   backgroundColor: (palette.secondary as PaletteColor).light
      // }
    }
  },
  MuiButton: {
    textPrimary: {
      fontWeight: 400,
      color: palette.colors.lightBlue.main,
      '&:hover': {
        backgroundColor: (palette.primary as PaletteColor).light
      }
    },
    textSecondary: {
      color: (palette.secondary as PaletteColor).light,
      // '&:hover': {
      //   backgroundColor: (palette.secondary as PaletteColor).light
      // }
    }
  },
  MuiListItemIcon: {
    root: { // TODO: color primary?
      color: (palette.secondary as PaletteColor).light
    }
  },
  MuiFab: {
    root: {
      backgroundColor: '#fff'
    }
  },
  MuiTooltip: {
   tooltip: {
    backgroundColor: palette.colors.amber[100],
    color: (palette.secondary as PaletteColor).main,
    fontSize: '0.750rem',
    fontFamily: 'regular',
    fontWeight: 400
   }
  },
  MuiChip: {
    root: {
      fontSize: '0.875rem',
      fontWeight: 400
    },
    colorPrimary: {
      color: '#fff',
      backgroundColor: palette.colors.lightBlue['100']
    },
    clickableColorPrimary: {
      '&:hover': {
        backgroundColor: palette.colors.lightBlue['100']
      }
    },
    deletableColorPrimary:{
      '&:focus': {
        backgroundColor: palette.colors.lightBlue['100']
      }
    },
    deleteIconColorPrimary: {
      color: '#fff',
      '&:hover': {
        color: (palette.secondary as PaletteColor).main
      }
    },
    //TODO: is dit misbruik maken van chip voor 'op werkgebied'?
    colorSecondary: {
      color: (palette.secondary as PaletteColor).contrastText,
      backgroundColor: (palette.primary as PaletteColor).main
    },
    clickableColorSecondary: {
      color: (palette.secondary as PaletteColor).contrastText,
      '&:hover': {
        backgroundColor: (palette.primary as PaletteColor).main
      }
    },
    deletableColorSecondary:{
      '&:focus': {
        backgroundColor: (palette.primary as PaletteColor).main
      }
    },
    deleteIconColorSecondary: {
      color: (palette.secondary as PaletteColor).contrastText,
      '&:hover': {
        color: (palette.secondary as PaletteColor).contrastText
      }
    },
  },
  MuiLinearProgress: {
    root:{
      height: 2
    }
  },
  MuiTableCell: {
    root: {
      fontSize: '0.875rem'
    },
    head: {
      fontWeight: 400,
      fontFamily: 'bold'
    }
  },
  MuiFormControlLabel: {
    label: {
      fontSize: '1rem'
    }
  }
};

export default { props, overrides };