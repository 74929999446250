import * as allFormatters from '../../../utils/formatters';
import { TransformFunction, TransformOptionsBase, calcNumberOfRows, padLeftWithZeros } from '../transform-utils';

interface RD {
  waarde: number;
  label_1: string;
  label_2: string;
  label_4: string;
  eenheid: string;
}

export interface ParetoChartRawData {
  val00_00: RD;
  val00_01: RD; 
  [key: string]: RD | undefined;
}
export interface ParetoChartItem {
  primaryValue: {
    label: string;
    rawValue: number;
    formattedValue: string;
  };
  secondaryValue: {
    label: string;
    rawValue: number;
    formattedValue: string;
  };
}
export interface ParetoChartDataModel {
  bars: ParetoChartItem[];
  group: { label: string; value: number; formattedValue: string }
}

export interface ParetoChartTransformOptions extends TransformOptionsBase {
}

const paretoChartTransformer: TransformFunction<ParetoChartDataModel, ParetoChartTransformOptions> = (
  rawData: ParetoChartRawData,
  onError,
  options
) => {
  const keys = Object.keys(rawData);
  const guard = !keys.some((key) => !!rawData[key] && (rawData[key] as any).waarde !== null);

  if (guard) {
    onError('nodata');
    return null;
  }

  const {
    formatters = {},
    formattersOptions = {}
  } = options || {} as ParetoChartTransformOptions;

  let result: ParetoChartDataModel = {
    bars: [],
    group: { label: '', value: 0, formattedValue: '' }
  };

  const primaryValueFormatterName = formatters['primaryValue'];
  const primaryValueFormatter = (allFormatters as any)[primaryValueFormatterName];
  const primaryValueFormatterOptions = formattersOptions['primaryValue'];

  const secondaryValueFormatterName = formatters['secondaryValue'];
  const secondaryValueFormatter = (allFormatters as any)[secondaryValueFormatterName];
  const secondaryValueFormatterOptions = formattersOptions['secondaryValue'];

  const labelFormatterName: string = formatters['label'];
  const labelFormatter = (allFormatters as any)[labelFormatterName];
  const labelFormatterOptions = formattersOptions['label'];

  const numberOfRows = calcNumberOfRows(keys);

  let barItems: ParetoChartItem[] = [];
  let groupTotalValue: number = 0;
  let formattedgroupTotalValue: string = ''
  let groupLabel: string = '';

  for (let rowNumber = 0; rowNumber < numberOfRows; rowNumber++) {
    const row = padLeftWithZeros(rowNumber, 2);
    const primaryKey = `val${row}_01`;
    const secondaryKey = `val${row}_00`;
    const rawPrimaryItem = rawData[primaryKey] as RD;
    const rawSecondaryItem = rawData[secondaryKey] as RD;
    const { label_2: primaryDimensionLabel, waarde: primaryWaarde, eenheid: primaryEenheid } = rawPrimaryItem;
    const { waarde: secondaryWaarde, eenheid: secondaryEenheid, label_1: secondaryDimensionLabel } = rawSecondaryItem;

    groupTotalValue += primaryWaarde;

    formattedgroupTotalValue = primaryValueFormatter ?
      primaryValueFormatter(groupTotalValue, primaryValueFormatterOptions) :
      allFormatters.formatValue(groupTotalValue, primaryEenheid as ValueUnit, primaryValueFormatterOptions)
    groupLabel = labelFormatter ? labelFormatter(secondaryDimensionLabel as string, labelFormatterOptions) : secondaryDimensionLabel;

    const barItem = {
      primaryValue: {
        rawValue: primaryWaarde,
        label: groupLabel,
        formattedValue: primaryValueFormatter ?
          primaryValueFormatter(primaryWaarde, primaryValueFormatterOptions) :
          allFormatters.formatValue(primaryWaarde, primaryEenheid as ValueUnit, primaryValueFormatterOptions)
      },
      secondaryValue: {
        rawValue: secondaryWaarde,
        label: labelFormatter ? labelFormatter(primaryDimensionLabel as string, labelFormatterOptions) : primaryDimensionLabel,
        formattedValue: secondaryValueFormatter ?
          primaryValueFormatter(secondaryWaarde, secondaryValueFormatterOptions) :
          allFormatters.formatValue(secondaryWaarde, secondaryEenheid as ValueUnit, secondaryValueFormatterOptions)
      }
    };

    barItems.push(barItem);
  }

  result.bars = barItems;

  result.group = {
    label: groupLabel,
    value: groupTotalValue,
    formattedValue: formattedgroupTotalValue
  }


  console.log({result});
  

  return result;
};

export default paretoChartTransformer;