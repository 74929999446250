import React from 'react';
import { LineChartDataModel } from './lineChartTransformer';
import { LineChart, LineChartProps} from '../LineChart';
import { LineChartAggregatedDataModel } from './lineChartAggregatedTransformer';

export interface LineChartAggregatedProps extends LineChartProps {
  data: LineChartAggregatedDataModel & LineChartDataModel;
}

const LineChartAggregated = (props: LineChartAggregatedProps) => {
const {
  data: aggregatedData,
  ...lineChartProps
} = props;

const { transformerMin, transformerMax, data } = aggregatedData;

return (
    <LineChart
      {...lineChartProps}
      yMin={transformerMin}
      yMax={transformerMax}
      data={data as LineChartDataModel}
      />
  );
};

export default LineChartAggregated;