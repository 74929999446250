import React, { useEffect, useState } from 'react';
import { Tooltip } from 'ppd-library/charts/organisms/Tooltip';
import { Label, LabelProps } from 'ppd-library/charts/atoms/Label';
import Chart from 'ppd-library/charts/Chart';
import { CircleAnimated } from 'ppd-library/charts/molecules/CircleAnimated';
import * as allFormatters from '../../../utils/formatters';

import { LineConnected } from 'ppd-library/charts/molecules/LineConnected';

import { scales } from 'ppd-library/charts/chartUtils';
import { BarVertical } from 'ppd-library/charts/molecules/BarVertical';
import styled from 'styled-components';
import { TooltipTemplate, useTooltipPosition, getSVGMousePosition } from '../tooltip-utils';

import useTheme from '@material-ui/styles/useTheme';
import { ComponentBaseProps } from '../ComponentBaseProps';
import { ParetoChartDataModel } from './paretoChartTransformer';

export interface ParetoChartThemeProps {
  /**
   * Color that is used when no or not enough barColors are supplied.
   * @default #2b4899
   */
  barColor?: string;
  /**
   * A color for every bar.
   * @default ['#2b4899','orange','gray']
   */
  barColors?: string[];
}
export interface ParetoChartProps extends ComponentBaseProps<ParetoChartThemeProps, ParetoChartDataModel> {
  showTooltip?: boolean;
}

const StyledWrapper = styled.div`` as React.ComponentType<React.HTMLProps<HTMLDivElement>>;

const StyledBarLabel = styled(({ opacity, ...labelProps }) => <Label {...labelProps} />)`
  opacity: ${({ opacity }) => opacity};
  text-anchor: middle;
  transition: opacity 1000ms 100ms;
` as React.ComponentType<{ opacity: number; } & LabelProps>;

const StyledPercentageLabel = styled(({ ...labelProps }) => <Label {...labelProps} />)`
  text-anchor: middle;
  transition: opacity 1000ms 100ms;
` as React.ComponentType<LabelProps>;


const ParetoChart = (props: ParetoChartProps) => {
  const {
    id,
    data,
    width = 300,
    height = 400,
    margins = { left: 0, right: 0, top: 20, bottom: 28 },
    // showHelp,
    showTooltip = true,
    themeProperties,
    formatters = {},
    formattersOptions = {}
  } = props;

  const { bars } = data;

  const {
    barColor = '#2b4899',
    barColors = bars.length === 3 ? ['#2b4899', 'orange', 'gray'] : bars.map(() => barColor)
  } = themeProperties || {} as ParetoChartThemeProps;

  const theme = useTheme<any>();

  const labels = bars.map(({ secondaryValue }) => secondaryValue.label);

  const colorsByKey = labels.reduce((previous, currentLabel, i) => {
    return {
      ...previous,
      [currentLabel]: barColors[i]
    };
  }, {});

  const colorScale = scales.colorScale(colorsByKey);

  const maxValue = 100// Math.max(...bars.map(({ primaryValue }) => primaryValue.rawValue));
  const xScale = scales.stringXScale(labels, width, margins.left, margins.right, 0.5);
  const yScale = scales.numericYScale(0, maxValue, height, margins.bottom, margins.top);

  const percentageLabelFormatterName = formatters['percentageLabel'];
  const percentageLabelFormatter = (allFormatters as any)[percentageLabelFormatterName];
  const percentageLabelFormatterOptions = formattersOptions['percentageLabel'];

  const [initiated, setInitiated] = useState(false);
  useEffect(() => {
    setInitiated(true);
  }, [bars, width]);

  let percentageSum: number = 0, pointsSum: number = 0;

  const toPoints = bars.map(({ secondaryValue }) => {
    const { rawValue, label } = secondaryValue || {} as { formattedValue: string, label: string };
    const x = xScale(label) as number + (xScale.bandwidth() / 2);
    const y = yScale(pointsSum += rawValue);
    return {
      x,
      y
    }
  });

  const fromPoints = toPoints.shift();

  let tooltipLeaveTimeoutId = 0;
  const handleMouseMove = (event: React.MouseEvent<Element, MouseEvent>, index: number, data: ParetoChartDataModel) => {
    clearTimeout(tooltipLeaveTimeoutId);
    const { x, y } = getSVGMousePosition(event as React.MouseEvent<SVGElement, MouseEvent>);
    setTooltipPosition({ visible: true, x, y, index, data });
  };

  const handleMouseLeave = () => {
    clearTimeout(tooltipLeaveTimeoutId);
    tooltipLeaveTimeoutId = setTimeout(() => {
      setTooltipPosition({ ...tooltipPosition, visible: false });
    }, 250);
  };

  const { tooltipPosition, setTooltipPosition } = useTooltipPosition<ParetoChartDataModel>();

  return (
    <StyledWrapper>
      <Chart
        width={width}
        height={height}
        preserveAspectRatio={'none'}
        id={`${id}-chart-pareto`}
      >
        <LineConnected
          width={1.5}
          id={`${id}-line-connected`}
          fromPoint={fromPoints as { x: number, y: number }}
          color={theme.palette.colors.black[100]}
          toPoints={toPoints}
          animate={'stroke'}
        />
        {bars.map(({ primaryValue, secondaryValue }, i) => {
          const { formattedValue: primaryFormattedValue } = primaryValue;
          const { rawValue, label } = secondaryValue || {} as { formattedValue: string, label: string };
          const yCircle = percentageSum += rawValue;
          const ylabel = percentageLabelFormatter ? percentageLabelFormatter(yCircle, percentageLabelFormatterOptions) : yCircle;

          return (
            <React.Fragment key={`${id}-${i}`}>
              {rawValue !== 0 &&
                <StyledPercentageLabel
                  dominantBaseLine={0}
                  padding={0}
                  animate={true}
                  transform={'y'}
                  fill={'transparent'}
                  text={ylabel}
                  color={theme.palette.secondary.light}
                  y={initiated ? yScale(yCircle) - margins.top /2  : yScale(yCircle + 10) - margins.top + 8}
                  x={initiated ? xScale(label) as number + (xScale.bandwidth() / 2) : xScale(label) as number}
                />
              }
              <BarVertical
                fill={colorScale(label)}
                width={xScale.bandwidth()}
                x={xScale(label) as number}
                id={`${id}-bar-vertical-${i}`}
                y={initiated ? yScale(rawValue) : yScale(0)}
                height={initiated ? yScale(0) - yScale(rawValue) : 0}
              />
              {rawValue !== 0 &&
                <rect
                  y={0}
                  fill={'transparent'}
                  width={xScale.bandwidth()}
                  x={xScale(label) as number}
                  height={initiated ? height - margins.bottom : 0}
                  onMouseMove={(ev) => handleMouseMove(ev, i, data)}
                  onMouseLeave={handleMouseLeave}
                />
              }
              <StyledBarLabel
                yText={0}
                dominantBaseLine={0}
                padding={0}
                y={yScale(-15)}
                animate={false}
                fill={'transparent'}
                opacity={initiated ? 1 : 0}
                color={theme.palette.primary.main}
                text={<tspan x={0} dy={6}> {primaryFormattedValue} </tspan>}
                x={xScale(label) as number + (xScale.bandwidth() / 2)}
              />
              {rawValue !== 0 &&
                <CircleAnimated
                  x={xScale(label) as number + (xScale.bandwidth() / 2)}
                  y={initiated ? yScale(yCircle) : yScale(0)}
                  strokeWidth={1.5}
                  radius={4}
                  id={`${id}-${i}`}
                  strokeColor={theme.palette.colors.black[100]}
                  //   key={`line-${lineIndex}-${dataPoint.xLabel}-${pointIndex}`}
                  backgroundColor={tooltipPosition.index === i ? theme.palette.colors.black[100] : '#fff'}
                />
              }
            </React.Fragment>
          );
        }
        )}
      </Chart>
      <Tooltip
        top={tooltipPosition.y}
        left={tooltipPosition.x}
        visible={showTooltip && tooltipPosition.visible}
        calculateOffsetPosition={'horizontal'}
        theme={{
          transition: false
        }}
      >
        {() => {
          const {
            group,
            bars
          } = tooltipPosition.data as ParetoChartDataModel;
          const { primaryValue, secondaryValue } = bars[tooltipPosition.index];
          const { label: groupLabel } = group;

          return (
            <TooltipTemplate
              title={`${groupLabel}`}
              values={[
                {
                  label: secondaryValue.label,
                  colors: [colorScale(secondaryValue.label)],
                  formattedValue: `${primaryValue.formattedValue} (${secondaryValue.formattedValue})`
                }
              ]}
            />
          );
        }}
      </Tooltip>
    </StyledWrapper>
  )
};

export default ParetoChart;