import { PopperPlacementType as MuiPopperPlacementType } from '@material-ui/core/Popper';
import MuiHelpIcon from '@material-ui/icons/HelpOutlineSharp';
import useTheme from '@material-ui/styles/useTheme';
import {
  TooltipButton,
  TooltipButtonAnchorType,
} from 'ppd-library/components/molecules/TooltipButton';
import React, { useState, useEffect } from 'react';

// eslint-disable-next-line
const { API_URL } = ppd.appConfig;

export interface HelpTooltipButtonProps {
  /**
   * help id to retreive the helptekst from the API
   */
  helpId: number;
  anchorType: TooltipButtonAnchorType;

  className?: string;
  anchorText?: string;
  placement?: MuiPopperPlacementType;
}

const HelpTooltipButton = (props: HelpTooltipButtonProps) => {
  const {
    helpId,
    placement,
    anchorType,
    anchorText,
    className = ''
  } = props;

  const theme = useTheme<any>();
  const [helpData, setHelpData] = useState<API_GET.HelpData | null>(null);

  const handleOpen = async () => {
   
  };

  useEffect(() => {
    if(!!helpData && helpId != null) {
      setHelpData(null);
    }
    //eslint-disable-next-line
  }, [helpId]);

  return (
    <TooltipButton
      onOpen={handleOpen}
      id={`help-${helpId}`}
      placement={placement}
      className={className}
      anchorType={anchorType}
      anchorText={anchorText}
      popperZindex={2}
      // popperZindex={muiTheme.zIndex.drawer + 2} //Note: toolbar is drawer + 1
      anchorColor={theme.palette.colors.gold.main}
      anchorIcon={<MuiHelpIcon fontSize={'small'} />}
      backgroundColor={theme.palette.colors.gold.light}
      content={
        <>
         Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </>
      }
    />
  );
}

export default HelpTooltipButton;