import { TransformFunction } from '../transform-utils';
import * as allFormatters from '../../../utils/formatters';
import barSlideTransformer, { BarSlideDataModel, BarSlideTransformOptions, BarSlideRawData } from '../BarSlide/barSlideTransformer';

interface RD {
  waarde: number;
  label_4: string;
  eenheid: string;
}

export interface BarSlideMainRawData {
  val00_00: RD;
  val00_01: RD;
  val00_02: RD;
  val00_03?: RD;
}

export interface BarSlideMainDataModel extends BarSlideDataModel {
  mainValue: {
    label: string;
    rawValue: number;
    formattedValue: string;
  };
  secondaryValue?: {
    label: string | string[];
    rawValue: number;
    formattedValue: string;
  };
}

export interface BarSlideMainTransformOptions extends BarSlideTransformOptions {
  mainValueLabel?: string;
  secondaryValue?: boolean;
  // TODO: kan dit niet beter via een formatter? dat je b.v. een array van formatters mee kan geven [abs, valuta]
  formatMainValueAbsolute?: boolean;
  secondaryValueAbsolute?: boolean;
  secondaryValueLabel?: string | string[];
}

const barSlideMainTransformer: TransformFunction<BarSlideMainDataModel, BarSlideMainTransformOptions> = (
  rawData: BarSlideMainRawData,
  onError,
  options
) => {

  const { val00_00, val00_01, val00_02, val00_03 } = rawData;
  const {
    secondaryValue,
    secondaryValueLabel,
    formatMainValueAbsolute,
    secondaryValueAbsolute,
    mainValueLabel,
    formatters = {}
  } = options || {} as BarSlideMainTransformOptions;

  let result: BarSlideMainDataModel = {
    secondaryValue: undefined,
    mainValue: { label: '', rawValue: 0, formattedValue: '' },
    firstValue: { label: '', rawValue: 0, formattedValue: '' },
    secondValue: { label: '', rawValue: 0, formattedValue: '' },
  };
  let guard = true;

  if (val00_00.waarde != null) {
    guard = false;
    const { label_4, waarde, eenheid } = val00_00;
    const mainValueFormatterName = formatters['mainValue'];
    const mainValueFormatter = (allFormatters as any)[mainValueFormatterName];

    result.mainValue.label = mainValueLabel ? mainValueLabel : label_4;
    result.mainValue.rawValue = waarde;

    let mainValue = !!formatMainValueAbsolute ? Math.abs(waarde) : waarde;
    result.mainValue.formattedValue = mainValueFormatter ? mainValueFormatter(mainValue) : allFormatters.formatValue(mainValue, eenheid as ValueUnit);
  }

  if (!!secondaryValue && val00_01.waarde != null) {
    const { label_4, waarde, eenheid } = val00_01;
    const secondaryValueFormatterName = formatters['secondaryValue'];
    const secondaryValueFormatter = (allFormatters as any)[secondaryValueFormatterName];
    const formattedSecondaryValue = secondaryValueAbsolute ? Math.abs(waarde) : waarde;

    let label = !!secondaryValueLabel ? secondaryValueLabel : label_4;

    result.secondaryValue = {} as { label: string; rawValue: number; formattedValue: string; };
    result.secondaryValue.label = label;
    result.secondaryValue.rawValue = waarde;

    result.secondaryValue.formattedValue = secondaryValueFormatter ? secondaryValueFormatter(formattedSecondaryValue) : allFormatters.formatValue(formattedSecondaryValue, eenheid as ValueUnit);
  }

  if (guard) {
    onError('onlyzeroes'); // is dit wel de correcte melding?
    return null;
  }

  const barSlideRawData: BarSlideRawData = secondaryValue ?
    { val00_00: val00_02, val00_01: val00_03 as RD } :
    { val00_00: val00_01, val00_01: val00_02 };
  const barSlideResult = barSlideTransformer(barSlideRawData, onError, options);

  if (!barSlideResult) {
    return null;
  } else {
    result = { ...result, ...barSlideResult };
  }



  return result;
};

export default barSlideMainTransformer;