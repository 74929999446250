import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

import globals from './theme/globals';
import palette from './theme/palette';
import typography from './theme/typography';
import spacing from './theme/spacing';
// import breakpoints from './theme/breakpoints'

const theme = createMuiTheme({
  palette,
  typography,
  spacing,
  ...globals
});

export default theme;