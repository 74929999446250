import * as allFormatters from '../../../utils/formatters';
import { TransformFunction, TransformOptionsBase } from '../transform-utils';


interface RD {
  waarde: number;
  label_4: string;
  eenheid: string;
}

export interface TwoValuesRawData {
  val00_00: RD;
  val00_01: RD;
}

export interface TwoValuesTransformOptions extends TransformOptionsBase {
}

export interface TwoValuesDataModel {
  /**
   * Formatted top value with optional trend values
   */
  topValue: {
    label: string;
    rawValue: number;
    formattedValue: string;
    // trend?: { // TODO: should come from a generic trend/sparkline transformer
    //   value: number;
    //   type: 'default' | 'first' | 'highest' | 'lowest' | 'last';
    // }[];
  };
  /**
   * Formatted bottom value with optional trend values
   */
  bottomValue: {
    label: string;
    rawValue: number;
    formattedValue: string;
    // trend?: { // TODO: should come from a generic trend/sparkline transformer
    //   value: number;
    //   type: 'default' | 'first' | 'highest' | 'lowest' | 'last';
    // }[];
  };
}

const twoValuesTransformer: TransformFunction<TwoValuesDataModel, TwoValuesTransformOptions> = (
  rawData: TwoValuesRawData,
  onError,
  options
) => {

  const { val00_00, val00_01 } = rawData;
  const { formatters = {}, formattersOptions = {} } = options || {} as TwoValuesTransformOptions;
  let result: TwoValuesDataModel = {
    bottomValue: { label: '', rawValue: 0, formattedValue: '-', },
    topValue: { label: '', rawValue: 0, formattedValue: '-', }
  };
  let guard = true;

  if (val00_00.waarde != null) {
    guard = false;
    const { waarde, label_4, eenheid } = val00_00;
    const topValueFormatterName = formatters['topValue'];
    const topValueFormatterOptions = formattersOptions['topValue'];
    const topValueFormatter = (allFormatters as any)[topValueFormatterName];

    result.topValue.label = label_4;
    result.topValue.rawValue = waarde;
    result.topValue.formattedValue = topValueFormatter ? topValueFormatter(waarde, topValueFormatterOptions) : allFormatters.formatValue(waarde, eenheid as ValueUnit, topValueFormatterOptions);
  }

  if (val00_01.waarde != null) {
    guard = false;
    const { waarde, label_4, eenheid } = val00_01;
    const bottomValueFormatterName = formatters['bottomValue'];
    const bottomValueFormatterOptions = formattersOptions['bottomValue'];
    const bottomValueFormatter = (allFormatters as any)[bottomValueFormatterName];

    result.bottomValue.label = label_4;
    result.bottomValue.rawValue = waarde;
    result.bottomValue.formattedValue = bottomValueFormatter ? bottomValueFormatter(waarde, bottomValueFormatterOptions) : allFormatters.formatValue(waarde, eenheid as ValueUnit, bottomValueFormatterOptions);
  }

  if (guard) {
    onError('nodata');
    return null;
  }

  return result;
};

export default twoValuesTransformer;