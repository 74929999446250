import MuiChip from '@material-ui/core/Chip';
import MuiTypography, { TypographyProps as MuiTypographyProps } from '@material-ui/core/Typography';
import MuiIconCheckBox from '@material-ui/icons/CheckBox';
import MuiIconCheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import { Tile, TileFooter, TileHeader, TileProps } from 'ppd-library/components/organisms/Tile';
import React, { ReactNode, RefObject, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import muiTheme from '../../../constants/theme';
import mapping, { MappedContent } from '../../content/mapping';
import { GeneralHelpTooltipButton } from '../../help/GeneralHelpTooltipButton';
import { HelpTooltipButton } from '../../help/HelpTooltipButton';
import { SearchableUnitListBox } from '../../SearchableUnitListBox';
import { ContentErrorBoundary } from '../ContentErrorBoundary';
import { LoadingBoundary } from '../LoadingBoundary';
import { defaultViewTypeIcons } from '../utils/';
import useDataManager from './useDataManager';
import { ViewTypes, DataKeyTypes } from '../../../@types/ppd-api.enums';
import { SearchableListItem } from 'ppd-library/components/organisms/SearchableList';
import  EditableTileTitle  from '../../../pages/dashboardpage/components/EditableTileTitle';

export interface ContainerProps {
  id: number;
  dataUrl: string;
  editable: boolean;
  editMode: boolean;
  showHelp: boolean;
  tileWidth: number;
  tileHeight: number;
  tile: API_GET.TileDetail;
  initialViewType: API.ViewType;
  selectedDataKeyType: API.DataKeyType;
  selectedOrganisationUnit: API.OrganisationUnit;
  // tileUrl: string; // of maakt een container deze zelf?
  isCleanTile: boolean;
  initialTabId?: number | null;
  /**
   * If the tile menu is visible or not
   * @default true
   */
  menuVisible?: boolean;
  /**
   *
   */
  onContentClick?: () => void;
  /**
   * Show all ids on the tabs and views. Only for development purposes.
   * @default false
   */
  showIds?: boolean;
  /**
   * @default false
  */
  useMockData?: boolean;
 /**
   * An action button placed in the top right corner, preferably a Mui IconButton.
   */
  actionButton?: ReactNode;
 /**
   *If true, the title is replaced with an input text field
   */
  titleEditable?: boolean;
  /**
   * Fired when the value in the editable title changes
   * titleEditable must be true
   */
  onTitleChange?: (value: string) => void;
  /**
   * Fired when the selected viewtype changes.
   * NOTE!: fires AFTER the viewtype has changed. selectedViewType is internal container state.
   */
  onViewTypeChange?: (newViewTypeId: number) => void;
    /**
   * Fired when the selected tab changes.
   * NOTE!: fires AFTER the tab has changed. selectedTabId is internal container state.
   */
  onTabChange?: (newTabId: number) => void;
  onDataKeyTypeClick?: (newDataKeyType: API.DataKeyType) => void;
  /**
   * @default false
   */
  showOrganisationUnitSelection?: boolean;
  onOrganisationUnitClick?: (organisationUnit: { name: string; code: string; }) => void;
}

const StyledContentContainer = styled(({ onClick, innerRef, ...divProps }) => <div ref={innerRef} onClick={onClick} {...divProps} />)`
  height: 100%;
  flex-grow: 1;
  display: flex;
  position: relative;
  flex-direction: column;
  // overflow: hidden;
  cursor: ${({ onClick }) => !!onClick ? 'pointer' : 'inherit'};
` as React.ComponentType<{ onClick?: () => void; innerRef: RefObject<HTMLDivElement>; } & React.HTMLProps<HTMLDivElement>>;

const StyledContentDescription = styled(({ innerRef, ...muiProps }) => <MuiTypography ref={innerRef} {...muiProps} />)`
  padding-bottom: 1rem;
` as React.ComponentType<{ innerRef: RefObject<HTMLDivElement>; } & MuiTypographyProps>;

const StyledContentComponentWrapper = styled(({ enableFlexGrow, height, helpActive, ...divProps }) => <div {...divProps} />)`
  display: flex;
  // flex-grow: ${({ enableFlexGrow }) => enableFlexGrow ? 1 : null};
  flex-grow: 1;
  position: relative;
  height: ${({ height }) => height}px;
` as React.ComponentType<{ enableFlexGrow: boolean; height: number; } & React.HTMLProps<HTMLDivElement>>;


const StyledTile = styled(Tile)`
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
` as React.ComponentType<TileProps>;

const StyledMuiChip = styled(MuiChip)`
  && {
   margin-right: 0.5rem;
   border-radius: 4px;
   background-color: ${() => muiTheme.palette.primary.main};
  }
`;

const StyledUnitCodeSubtitle = styled(MuiTypography)`
  && {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 1.5rem;
  }
` as React.ComponentType<MuiTypographyProps>;

/**
 * NOTE: This component depends on the parent (div) elements width and height, supplied via the tileWidth and tileHeight properties, to size the tile and content.
 */
const Container = (props: ContainerProps) => {
  const {
    id,
    tile,
    dataUrl,
    editable,
    editMode,
    showHelp,
    tileWidth,
    tileHeight,
    // TODO: iets anders verzinnen dan cleantile?
    isCleanTile,
    actionButton,
    onContentClick,
    initialViewType,
    selectedDataKeyType,
    selectedOrganisationUnit,
    showIds = false,
    initialTabId = null,
    useMockData = false,
    titleEditable = false,
    onTabChange = () => {},
    onTitleChange= () => {},
    onViewTypeChange = () => {},
    onDataKeyTypeClick = () => {},
    onOrganisationUnitClick = () => {},
    showOrganisationUnitSelection = false,
  } = props;

  const {
    subtitle,
    tileHelpId,
    tabs: tileTabs,
    title,
    availableDataKeyTypes,
  } = tile;

  const { name: initialViewTypeName } = initialViewType;
  const { code: selectedUnitCode } = selectedOrganisationUnit;
  const { name: selectedDataKeyTypeName = 'oe' } = selectedDataKeyType;

  let [selectedViewTypeName, setSelectedViewTypeName] = useState(initialViewTypeName);

  // Note: Possible undefined error. If tileTabs is undefined, something is wrong in the json settings. We need a minimum of one tab.
  const enabledTileTabs = tileTabs.filter(({ enabled }) => enabled);
  let tabItems = enabledTileTabs.map(({ title, id }) => ({ label: title || '', tabId: id }));
  const initialTabEnabled = !!initialTabId && enabledTileTabs.some(({ id }) => id === initialTabId);

  // Note: Possible undefined error. If enabledTileTabs[0] is undefined, something is wrong in the json settings. We need a minimum of one tab.
  const [selectedTabId, setSelectedTabId] = useState(initialTabEnabled ? initialTabId as number : enabledTileTabs[0].id);

  const selectedTab = enabledTileTabs.find(({ id }) => id === selectedTabId) as API_GET.TabDetail;
  let { views: tabViews, placeholderText: selectedTabPlaceholderText = null } = selectedTab;


  // TODO: tijdelijke oplossing voor pilot om kaart te hiden op lagere niveau's.
  if (tabViews.some(({ component }) => component.name === 'bubbleMap') && selectedUnitCode !== 'NP00000') {
    tabViews = tabViews.filter(({ component }) => component.name !== 'bubbleMap');
    // NOTE!: we dont want a re-render so we don't use set(?)
    selectedViewTypeName = tabViews[0].viewType.name;
  }

  const viewTypes = tabViews.map(({ viewType }) => {
    const { name: type } = viewType;
    const { iconLigature: defaultLigature, iconSvg, tooltip } = defaultViewTypeIcons.find(({ type: defaultType }) => type === defaultType) || { iconLigature: undefined, iconSvg: undefined, tooltip: undefined };
    return {
      type,
      iconSvg,
      tooltip,
      iconLigature: defaultLigature || 'extension' // if no defaultligature exists, show neutral icon that has nothing to do with the app (hopefully it will be noticed)
    };
  });

  let selectedView = tabViews.find(({ viewType }) => viewType.name === selectedViewTypeName) as API_GET.View;

  // Note: Possible undefined error. If selectedView is undefined, something is wrong in the (templateTile) json settings.
  let { description = '', viewHelpItems = [] } = selectedView;
  const help = viewHelpItems.length > 0 ? viewHelpItems : undefined;

  const {
    dataKeys,
    id: tabViewId,
    component,
    // enabled: selectedViewEnabled,
    placeholderText: selectedViewPlaceholderText = null
  } = selectedView;

  const { name: componentName, properties: componentProperties } = component;

  const { dataTransformProperties, ...contentComponentProps } = componentProperties;
  const mappedContent = mapping[componentName] as MappedContent;

  if (!mappedContent) {
    throw new Error(`Content component met naam '${componentName}' kan niet worden gevonden. Controleer de instellingen van de tegel.`);
  }

  const dataKey = selectedDataKeyTypeName === 'workarea' ?
    dataKeys.find(({ type }) => type.name === 'workarea') :
    dataKeys.find(({ type }) => type.name === 'oe');
  const dataKeyValue = !!dataKey ? dataKey.value : '';

  // dev
  if (showIds) {
    description = `${selectedView.id} - ${dataKeyValue} - ${componentName} ${description}`;
    tabItems = enabledTileTabs.map(({ title, id }) => ({ label: `${id} ${title || ''}`, tabId: id }));
  }
  // dev

  const { transformer, ContentComponent } = mappedContent;
  const { isLoading: dataIsLoading, error: dataError, transformedData } = useDataManager(
    selectedUnitCode,
    dataKeyValue,
    dataUrl,
    useMockData,
    transformer,
    dataTransformProperties
  );
  const [contentDimensions, setContentDimensions] = useState({ width: 0, height: 0 });
  const [activeContentComponent, setActiveContentComponent] = useState({
    id: tabViewId,
    help,
    description,
    isCleanTile,
    ContentComponent,
    contentComponentProps,
    data: transformedData,
    errorResetKey: `${tabViewId}-${selectedUnitCode}`,
  });

  const contentContainerRef = useRef<HTMLDivElement>(null);
  const contentDescriptionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!!transformedData) {
      setActiveContentComponent({
        id: tabViewId,
        help,
        isCleanTile,
        description,
        ContentComponent,
        contentComponentProps,
        data: transformedData,
        errorResetKey: `${tabViewId}-${selectedUnitCode}`,
      });
    }
    //eslint-disable-next-line
  }, [transformedData]);

  useEffect(() => {
    if (!!contentContainerRef.current) {
      const containerRect = (contentContainerRef.current as HTMLDivElement).getBoundingClientRect();
      let descriptionHeight = 0;

      if (!!activeContentComponent.description && !!contentDescriptionRef.current) {
        const descriptionRect = (contentDescriptionRef.current as HTMLDivElement).getBoundingClientRect();
        descriptionHeight = descriptionRect.height;
      }

      setContentDimensions({
        width: containerRect.width,
        height: containerRect.height - descriptionHeight
      });
    }
  }, [tileWidth, tileHeight, activeContentComponent.description]);

  const handleViewTypeClick = (viewTypeName: string) => {
    const viewTypeId = ViewTypes[viewTypeName as API.ViewTypeName];
    setSelectedViewTypeName(viewTypeName as API.ViewTypeName);
    onViewTypeChange(viewTypeId);
  }

  const handleDataKeyTypeClick = () => {
    const dataKeyTypeName = selectedDataKeyTypeName === 'workarea' ? 'oe' : 'workarea';
    const dataKeyTypeId = DataKeyTypes[dataKeyTypeName as API.DataKeyTypeName];
    const newDataKeyType: API.DataKeyType = { id:dataKeyTypeId, name: dataKeyTypeName };
    onDataKeyTypeClick(newDataKeyType);
  }

  const handleSelectedUnitCodeChange = (item: SearchableListItem) => {
    onOrganisationUnitClick({ code: item.key, name: item.label });
  };

  const handleTabChange = (tabId: number) => {
    setSelectedTabId(tabId);
    onTabChange(tabId);
  }

  const tileTitle = titleEditable ? <EditableTileTitle value={title} onBlur={(value) => onTitleChange(value)} /> : title;

  return (
    <StyledTile
      hideFooterDivider={true} // TODO: dit moet toch standaard, waarom dan een prop?
      onTabChange={handleTabChange}
      hideHeaderDivider={'showWithTabs'} // TODO: dit moet toch standaard, waarom dan een prop?
      initialSelectedTabId={selectedTabId}
      tabItems={tabItems.length > 1 || showIds ? tabItems : undefined}
      header={
        <TileHeader
          title={tileTitle}
          subtitle={subtitle}
          actionButton={actionButton}
          onTitleClick={onContentClick}
          subheader={
            <div style={{ display: 'flex', }}>
               {!editable &&
               (!subtitle && (availableDataKeyTypes.length === 1 || (selectedDataKeyTypeName === 'oe' && !editMode))) &&
                <span style={{ lineHeight: '1.5rem' }}>&nbsp;</span>
              }
              {!editMode && availableDataKeyTypes.some(({ name }) => name === 'workarea') &&
                selectedDataKeyTypeName === 'workarea' &&
                <StyledMuiChip
                  size={'small'}
                  clickable={false}
                  color={'secondary'}
                  label={'Op werkgebied'}
                />
              }
              {editMode && availableDataKeyTypes.some(({ name }) => name === 'workarea') &&
                <StyledMuiChip
                  // size={'small'}
                  color={'secondary'}
                  label={'Op werkgebied'}
                  onClick={handleDataKeyTypeClick}
                  onDelete={handleDataKeyTypeClick}
                  deleteIcon={selectedDataKeyTypeName === 'workarea' ?
                    <MuiIconCheckBox className={'MuiChip-deleteIconSmall'} /> :
                    <MuiIconCheckBoxOutlineBlank className={'MuiChip-deleteIconSmall'} />
                  }
                />
              }
              {showOrganisationUnitSelection &&
                <>
                  {editable && !editMode &&
                    <StyledUnitCodeSubtitle
                      variant={'subtitle1'}
                      title={`${selectedOrganisationUnit.name} (${selectedOrganisationUnit.code})`}
                    >
                      {selectedOrganisationUnit.name}
                    </StyledUnitCodeSubtitle>
                  }
                  {editable && editMode &&
                    <SearchableUnitListBox
                      initialUnitCode={selectedUnitCode}
                      onSelectedItemChanged={handleSelectedUnitCodeChange}
                    // initialItem={{ key: unitCode, label: oeName }} 
                    />
                  }
                </>
              }
            </div>
          }
          afterTitleContent={
            <>
              {showHelp && !!tileHelpId &&
                <HelpTooltipButton
                  helpId={tileHelpId}
                  placement={'bottom'}
                  anchorType={'iconButton'}
                />
              }
            </>
          }
        />
      }
      content={
        <StyledContentContainer onClick={onContentClick} innerRef={contentContainerRef}>
          <LoadingBoundary
            error={dataError || null}
            isLoading={dataIsLoading}
            placeholderText={selectedTabPlaceholderText ? selectedTabPlaceholderText : selectedViewPlaceholderText}
          >
            {() => {
              const {
                errorResetKey,
                id: contentId,
                help: contentHelp,
                data: contentData,
                ContentComponent: Component,
                contentComponentProps: props,
                description: contentDescription,
              } = activeContentComponent;

              return (
                <ContentErrorBoundary
                  contentId={contentId}
                  resetKey={errorResetKey}
                >
                  {contentDescription &&
                    <StyledContentDescription
                      component={'div'}
                      variant={'subtitle1'}
                      innerRef={contentDescriptionRef}
                    >
                      {contentDescription}
                    </StyledContentDescription>
                  }
                  <GeneralHelpTooltipButton help={help} showHelp={showHelp} />
                  <StyledContentComponentWrapper
                    height={contentDimensions.height}
                    enableFlexGrow={!contentDescription}
                  >
                    {!!contentData &&
                      <Component
                        {...props}
                        oeCode={selectedUnitCode}
                        data={contentData}
                        help={contentHelp}
                        showHelp={showHelp}
                        id={`template-tile-${id}`} // NOTE!: id needs to be unique but constant, else tabs will act like it is a new component.
                        width={contentDimensions.width}
                        height={contentDimensions.height}
                      // NOTE: important difference with and without key when using tabs with the same component.
                      // if no key, then same component instance is used. With key a different instance is initiated.
                      // key={`template-tile-${id}-tab-${selectedTabId}`} 
                      />
                    }

                  </StyledContentComponentWrapper>
                </ContentErrorBoundary>
              );
            }}
          </LoadingBoundary>
        </StyledContentContainer>
      }
      footer={
        !activeContentComponent.isCleanTile &&
        <TileFooter
          data-html2canvas-ignore
          views={viewTypes}
          onViewClick={handleViewTypeClick}
          selectedViewType={selectedViewTypeName}
          detailReportLinkLabel={null}
          onDetailReportClick={undefined}
        />
      }
    />
  );
};

export default Container;