import MuiListItemIcon from '@material-ui/core/ListItemIcon';
import MuiListItemSecondaryAction, {
  ListItemSecondaryActionProps as MuiListItemSecondaryActionProps,
} from '@material-ui/core/ListItemSecondaryAction';
import MuiMenu, { MenuProps as MuiMenuProps } from '@material-ui/core/Menu';
import MuiMenuItem, { MenuItemProps as MuiMenuItemProps } from '@material-ui/core/MenuItem';
import MuiTypography, { TypographyProps as MuiTypographyProps } from '@material-ui/core/Typography';
import MuiCheckIcon from '@material-ui/icons/Check';
import React from 'react';
import styled from 'styled-components';

export interface MenuItem {
  id: string;
  label: string;
  iconSvg?: React.ReactElement;
  onClick: (id: string) => void;
  /**
   * If the menu-item is checked or not.
   * @default undefined should mean the item cannot be checked.
   */
  checked?: boolean;
  /**
   * @default false
   */
  disabled?: boolean;
  /**
   * @default false
   */
  hidden?: boolean;
}

export interface MenuProps {
  isOpen: boolean;
  items: MenuItem[];
  anchorElement: HTMLElement | null;
  onClose?: () => void;
  title?: React.ReactNode;
  /**
   * Fires (also) when any item is clicked.
   */
  onItemClick?: (id: string) => void;
}

const StyledMuiMenuItem = styled(MuiMenuItem)`
  && {
    max-width: 350px;
  }
` as React.ComponentType<MuiMenuItemProps>;

const StyledMuiMenu = styled(MuiMenu)`
  && {
    /* max-width: 20rem; */
    /* max-height: 420px; */
  }
` as React.ComponentType<MuiMenuProps>;

const StyledMuiTypography = styled(MuiTypography)`
  && {
    margin-left: -1rem;
  }
` as React.ComponentType<MuiTypographyProps>;

const StyledMuiListItemSecondaryAction = styled(MuiListItemSecondaryAction)`
  && {
    pointer-events: none;
  }
` as React.ComponentType<MuiListItemSecondaryActionProps>;

const StyledMenuHeaderContainer = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  outline: none;
  padding-left: 1rem;
  white-space: nowrap;
  min-height: 2.25rem;
  padding-right: 1rem;
  border-bottom: 1px solid rgba(0,0,0,0.12);
  color: #2b4899;
`;

const Menu = (props: MenuProps) => {
  const {
    items,
    title,
    isOpen,
    onClose,
    anchorElement,
    onItemClick = () => { },
  } = props;

  const handleItemClick = (id: string, onClick: (id: string) => void) => {
    onItemClick(id);
    onClick(id);
  }

  return (
    <StyledMuiMenu
      open={isOpen}
      onClose={onClose}
      anchorEl={anchorElement}
    >
      {!!title &&
        <StyledMenuHeaderContainer>
          {title}
        </StyledMenuHeaderContainer>
      }
      {items.map(({ id, label, onClick, iconSvg, checked, disabled = false, hidden = false }) => {
        return (
          !hidden &&
          <StyledMuiMenuItem
            key={id}
            disabled={disabled}
            onClick={() => handleItemClick(id, onClick)}
          >
            {!!iconSvg &&
              <MuiListItemIcon>
                {iconSvg}
              </MuiListItemIcon>
            }

            <StyledMuiTypography variant={'inherit'} noWrap>
              {label}
            </StyledMuiTypography>
            <StyledMuiListItemSecondaryAction>
              {!!checked && <MuiCheckIcon />}
            </StyledMuiListItemSecondaryAction>
          </StyledMuiMenuItem>
        );
      })}
    </StyledMuiMenu>
  );
};

export default Menu;