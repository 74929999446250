import { TransformFunction, TransformOptionsBase, calcNumberOfColumns, padLeftWithZeros } from '../transform-utils';
import * as allFormatters from '../../../utils/formatters';
interface RD {
  waarde: number;
  label_4: string;
  eenheid: string;
}

export interface BarChartInBarLabelingMainRawData {
  val00_00: RD;
  [key: string]: RD;
}

export interface BarChartInBarLabelingMainDataModel {
  mainValue: {
    label: string;
    rawValue: number;
    formattedValue: string;
  };

  barValues: {
    label: string;
    rawValue: number;
    formattedValue: string;
  }[];
}

export interface BarChartInBarLabelingMainTransformOptions extends TransformOptionsBase {

}

const barChartInBarLabelingMainTransformer: TransformFunction<BarChartInBarLabelingMainDataModel, BarChartInBarLabelingMainTransformOptions> = (
  rawData: BarChartInBarLabelingMainRawData,
  onError,
  options
) => {

  const { val00_00, ...barData } = rawData;
  const { formatters = {} } = options || {} as BarChartInBarLabelingMainTransformOptions;

  let result: BarChartInBarLabelingMainDataModel = {
    mainValue: { label: '', rawValue: 0, formattedValue: '' },
    barValues: []
  };

  let guard = true;

  if (val00_00.waarde != null) {
    guard = false;
    const { waarde, label_4, eenheid } = val00_00;
    const mainValueFormatterName = formatters['mainValue'];
    const mainValueFormatter = (allFormatters as any)[mainValueFormatterName];

    result.mainValue.label = label_4;
    result.mainValue.rawValue = waarde;
    result.mainValue.formattedValue = mainValueFormatter ? mainValueFormatter(waarde) : allFormatters.formatValue(waarde, eenheid as ValueUnit);
  }

  const keys = Object.keys(barData);

  if (!guard && keys.length !== -1) {
    const barValueFormatterName = formatters['barValue'];
    const barValueFormatter = (allFormatters as any)[barValueFormatterName];
    const numberOfColumns = calcNumberOfColumns(keys);

    for (let colNumber = 1; colNumber < numberOfColumns; colNumber++) {

      let row = '00';
      let col = padLeftWithZeros(colNumber, 2);

      let key = `val${row}_${col}`;
      const { label_4, waarde, eenheid } = rawData[key];

      const bar = {
        label: label_4,
        rawValue: waarde,
        formattedValue: barValueFormatter ? barValueFormatter(waarde) : allFormatters.formatValue(waarde, eenheid as ValueUnit)
      };

      result.barValues.push(bar);
    }
  }

  if (guard) {
    onError('nodata');
    return null;
  }

  return result;
};

export default barChartInBarLabelingMainTransformer;