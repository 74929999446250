import React, { RefObject } from 'react';
import styled from 'styled-components';
import { LegendOptions } from '../ComponentBaseProps';

export interface LegendTemplateProps {
  className?: string;
  /**
  * NOTE: legendOptions originate from view json config.
  */
  legendOptions?: LegendOptions;
  /**
   * Values are optional because some components will only show hardcoded legend values (legendOptions).
   */
  values?: { label: string; colors: string | string[] }[];

  beforeLegendContent?: React.ReactNode;
  afterLegendContent?: React.ReactNode;
};

export const StyledLegendColor = styled(({ innerRef, roundedBorder, legendBackground, ...spanProps }) => <span {...spanProps} />)`
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 4px;
  background: ${({ legendBackground }) => legendBackground};
  border-radius: ${({ roundedBorder }) => !!roundedBorder ? '50%' : 0};
` as React.ComponentType<{ legendBackground: string; roundedBorder?: boolean; } & React.HTMLProps<HTMLSpanElement>>;

export const createLegendBackground = (colors: string[]) => {
  if (colors.length === 1) {
    return colors[0];
  }

  let gradient = colors.reduce((gradientStr, currentColor, i) => {
    let result = `${gradientStr}, ${currentColor} ${100 / colors.length * i}% ${100 / colors.length * (i + 1)}%`;
    if (i === colors.length - 1) {
      result = `${result})`;
    }

    return result;

  }, `linear-gradient(0deg `);

  return gradient;
};

const StyledWrapper = styled(({ orientation, innerRef, ...divProps }) => <div ref={innerRef} {...divProps} />)`
  display: flex;
  padding-top: 1rem;
  min-height: 2rem;
  font-size: 0.8rem;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: ${({ orientation }) => orientation === 'horizontal' ? 'row' : 'column'};
` as React.ComponentType<{ orientation: 'horizontal' | 'vertical'; innerRef: RefObject<HTMLDivElement>; } & React.HTMLProps<HTMLDivElement>>;

const StyledValueWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 12px;
`;

const StyledLabel = styled.span`
  
`;

const Legend = React.forwardRef((props: LegendTemplateProps, ref: React.Ref<HTMLDivElement>) => {
  const {
    className,
    legendOptions,
    values = [],
    beforeLegendContent,
    afterLegendContent
  } = props;

 

  const {
    columns,
    addValues,
    hideLegend,
    roundColors,
    overwriteValues,
    orientation = 'horizontal'
  } = legendOptions || {} as LegendOptions;

  const legendValues =
    !!overwriteValues ? overwriteValues :
      !!addValues ? values.concat(addValues) : values;

  if (!!hideLegend || legendValues.length <= 1) {
    return null;
  }

  let Columns = [] as JSX.Element[];

  if (!!columns) {
    const valuesPerColumn = Math.ceil(legendValues.length / columns);

     Columns = Array.from({ length: columns }, (_v, i) => i).map((columnIndex) => {
      const Values = [];

      for (let j = 0; j < valuesPerColumn; j++) {
        const valueIndex = columnIndex === 0 ? j : j + valuesPerColumn;
        const value = legendValues[valueIndex];

        if (!!value) {
          const { colors: originalColors, label } = value;
          const colors = !!originalColors ? 
          Array.isArray(originalColors) ? originalColors : [originalColors] :
          [];

          Values.push(
            <StyledValueWrapper key={`${label}-${columnIndex}-${j}`}>
              <StyledLegendColor roundedBorder={!!roundColors} legendBackground={createLegendBackground(colors)} />
              <StyledLabel>{label}</StyledLabel>
            </StyledValueWrapper>
          );
        }
      }

      return (
      <div key={`legend-column-${columnIndex}`}>
        {Values}
      </div>
      );
    });
  }

  return (
    <StyledWrapper innerRef={ref as React.RefObject<HTMLDivElement>} className={className} orientation={orientation}>
      {beforeLegendContent}
      {!!columns && Columns}
      {!columns && legendValues.map(({ colors: originalColors, label }, index) => {
        const colors = !!originalColors ?
          Array.isArray(originalColors) ? originalColors : [originalColors] :
          [];
        return (
          <StyledValueWrapper key={`${label}-${index}`}>
            <StyledLegendColor roundedBorder={!!roundColors} legendBackground={createLegendBackground(colors)} />
            <StyledLabel>{label}</StyledLabel>
          </StyledValueWrapper>
        );
      })}
      {afterLegendContent}
    </StyledWrapper>
  );
});

export default Legend;