import { ValueBlockSimple } from 'ppd-library/components/molecules/ValueBlockSimple';
import React from 'react';

import { ComponentBaseProps } from '../ComponentBaseProps';
import { ContentRow } from '../ContentRow';
import {
  DoubleStackedLabeledBar,
  DoubleStackedLabeledBarThemeProps,
} from '../DoubleStackedLabeledBar';
import { DoubleStackedLabeledBarMainDataModel } from './doubleStackedLabeledBarMainTransformer';
import { createLabel } from '../componentUtils';
import { HelpTooltipButton } from '../../help/HelpTooltipButton';

interface DoubleStackedLabeledBarMainThemeProps extends DoubleStackedLabeledBarThemeProps {

}
interface DoubleStackedLabeledBarProps extends ComponentBaseProps<DoubleStackedLabeledBarMainThemeProps, DoubleStackedLabeledBarMainDataModel> {

}

// TODO: hernoemen naar BarSlideStackedMain
const DoubleStackedLabeledBarMain = (props: DoubleStackedLabeledBarProps) => {
  const {
    data,
    help,
    showHelp,
    hideLabel,
    // themeProperties
  } = props;

  const { mainValue, primaryValue } = data;
  const helpData = !!help ? help.find(({ position }) => position === 1) : undefined;
  // const {} = themeProperties || {} as DoubleStackedLabeledBarMainThemeProps;

  return (
    <DoubleStackedLabeledBar
      {...props}
      doubleRowHeight={false}
      primaryLabel={createLabel('primaryValue', primaryValue, hideLabel)}
      renderAbove={
        <ContentRow>
          <ValueBlockSimple
            // labelWidth={5.2}
            valueSize={'large'}
            valueVariant={'h2'}
            labelPosition={'top'}
            label={mainValue.label}
            value={mainValue.formattedValue}
            afterValueContent={
              <>
                {!!showHelp && !!helpData &&
                  <HelpTooltipButton
                    helpId={helpData.helpId}
                    anchorType={'iconButton'}
                  />
                }
              </>
            }
          />
        </ContentRow>
      }
    />
  )
};

export default DoubleStackedLabeledBarMain;
