import MuiIconButton, { IconButtonProps as MuiIconButtonProps } from '@material-ui/core/IconButton';
import MuiTableCell, { TableCellProps as MuiTableCellProps } from '@material-ui/core/TableCell';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import styled from 'styled-components';
import PinnedSVGIcon from '../../../components/content/PinnedSVGIcon';


interface UnitCompareStickyHeaderCellProps {
  selected: boolean;
  isPinned: boolean;
  column: API_GET.UnitCompareColumn;
  /**
   * @default true
   */
  isEditable?: boolean;
  isDragging?: boolean;
  isDraggedOver?: boolean;
  onDrop: () => void;
  onDragEnd: () => void;
  onPinClick: () => void;
  onDragLeave: () => void;
  onDragStart: () => void;
  onMouseLeave: () => void;
  onMouseEnter: () => void;
  onDeleteClick: () => void;
  onDragOver: (e: React.DragEvent<HTMLElement>) => void;
}

const StyledMuiTableCell = styled(({ selected, draggable, isDragging, isDraggedOver, highLited, ...thProps }) => <MuiTableCell draggable={draggable} {...thProps} />)`
&& {
  vertical-align: top;
  line-height: inherit;
  padding-top: 9px;
  padding-right: 0.175rem;
  padding-bottom: 0;
  padding-left: 1.25rem;
  transition: background-color 300ms ease-in-out;
  position: ${({ highLited }) => highLited ? 'sticky' : null};
  left: ${({ highLited }) => highLited ? '300px' : null};
  z-index: ${({ highLited }) => highLited ? '5' : null};
  border: ${({ isDragging, highLited }) => highLited ? '1px solid #ccc' : isDragging ? '2px dashed #ccc' : '1px solid #fff'};
  border-bottom: ${({ highLited }) => highLited ? 0 : '1px solid #ccc'};
  background-color: ${({ selected, isDragging, highLited }) => highLited ? '#f0f8ff' : isDragging ? '#fff' : selected ? '#ededed' : '#fff'};
  cursor: ${({ draggable }) => draggable ? 'move' : null};
}
`as React.ComponentType<{ selected: boolean, isDragging?: boolean, isDraggedOver?: boolean; highLited: boolean; } & MuiTableCellProps>;

const StyledMuiTableCellTextBlock = styled(({ isDraggable, ...divProps }) => <div {...divProps} />)`
	font-family: 'regular';
	line-height: 0.9375rem;
	font-size: 0.75rem;
	padding-top: 0;
  padding-right: 0.1875rem;
	position: relative;
  cursor: ${({ isDraggable }) => isDraggable ? 'move' : null};
` as React.ComponentType<{ isDraggable: boolean; } & React.HTMLProps<HTMLElement>>;
;

const StyledMuiDeleteButton = styled(MuiIconButton)`
  &&& {
    position: absolute;
    right: -0.3125rem;
		top: 0.875rem;
		z-index: 2;
    .MuiSvgIcon-root {
      width: 1.3rem;
      height: 1.3rem;
    }
  }
` as React.ComponentType<MuiIconButtonProps>;

const StyledPinIconWrapper = styled(({ active, ...divProps }) => <div {...divProps} />)`
&& {
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.75rem;
  position: absolute;
  left: -10px;
  top: -6px;
  cursor: pointer;
  svg {
    width: 17px;
    height: 17px;
    fill:  ${({ active }) => !active ? '#fff' : '#000'};
    stroke:  ${({ active }) => !active ? '#000' : null};
  }
}
`as React.ComponentType<{ active: boolean; } & React.HTMLProps<HTMLDivElement>>;



const UnitCompareStickyHeaderCell = (props: UnitCompareStickyHeaderCellProps) => {
  const {
    column,
    onDrop,
    selected,
    isPinned,
    onDragEnd,
    isDragging,
    onDragOver,
    onPinClick,
    onDragLeave,
    onDragStart,
    onMouseLeave,
    onMouseEnter,
    isDraggedOver,
    onDeleteClick,
    isEditable = true
  } = props;

  const { organisationUnit } = column;
  const { code: oeCode, name: oeLabel } = organisationUnit; 

  return (
    <StyledMuiTableCell
      key={oeCode}
      onDrop={onDrop}
      selected={selected}
      highLited={isPinned}
      onDragEnd={onDragEnd}
      draggable={isEditable}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDragStart={onDragStart}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      isDraggedOver={isDraggedOver}
      isDragging={isEditable && isDragging}
    >
      {isEditable && selected &&
        <StyledMuiDeleteButton aria-label='delete' onClick={onDeleteClick}>
          <CloseIcon fontSize={'small'} />
        </StyledMuiDeleteButton>
      }
      {isEditable &&
        <StyledPinIconWrapper active={isPinned} onClick={onPinClick}>
          {(isPinned || selected) && <PinnedSVGIcon />}
        </StyledPinIconWrapper>
      }
      <StyledMuiTableCellTextBlock isDraggable={isEditable}>{oeLabel}</StyledMuiTableCellTextBlock>
    </StyledMuiTableCell>
  );
}

export default UnitCompareStickyHeaderCell;