import * as allFormatters from '../../../utils/formatters';
import { TransformFunction, TransformOptionsBase } from '../transform-utils';


interface RD {
  waarde: number;
  // label_1: string;
  label_4: string;
  eenheid: string;
}

export interface ValuesGradeMeterRawData {
  val00_00: RD;
  [key: string]: RD;
}

const defaultGrades = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

export interface ValuesGradeMeterTransformOptions extends TransformOptionsBase {
  /**
   * The total number of grades that should be shown.
   * I.E.: 1 = A, 2 = B etc.
   * @default 6
   */
  numberOfGrades?: number;
  /**
   * Translate a raw value to a grade, where rawValue - 1 === grades index.
   * I.E.: ['XXL', 'XL', 'L', 'M', 'S', 'XS']
   * where 1 === 'XXL', 2 === 'XL' etc.
   * @default A-Z
   */
  grades?: string[]
}

export interface ValuesGradeMeterDataModel {
  mainValue: {
    label: string;
    rawValue: number;
    formattedValue: string;
  };
  bars: {
    label?: string;
    rawValue: number;
    formattedValue: string;
  }[];
}

const valuesGradeMeterTransformer: TransformFunction<ValuesGradeMeterDataModel, ValuesGradeMeterTransformOptions> = (
  rawData: ValuesGradeMeterRawData,
  onError,
  options
) => {
  const { val00_00, ...restRawData } = rawData;
  const {
    formatters = {},
    numberOfGrades = 6,
    grades = defaultGrades,
    formattersOptions = {}
  } = options || {} as ValuesGradeMeterTransformOptions;

  let result: ValuesGradeMeterDataModel = {
    mainValue: {
      label: '',
      rawValue: 1,
      formattedValue: 'A'
    },
    bars: []
  };

  let guard = true;
  
  if (val00_00.waarde != null) {
    guard = false;
    const { waarde, label_4 } = val00_00;
    const mainValueFormatterName = formatters['mainValue'];
    const mainValueFormatterOptions = formattersOptions['mainValue'];
    const mainValueFormatter = (allFormatters as any)[mainValueFormatterName];

    result.mainValue.label = label_4;
    result.mainValue.rawValue = waarde;
    result.mainValue.formattedValue = mainValueFormatter ? mainValueFormatter(waarde, mainValueFormatterOptions) : allFormatters.formatGrade(waarde);
    
    for (let i = 0; i < numberOfGrades; i++) {
      result.bars.push({
        label: '',
        rawValue: i + 1,
        formattedValue: grades[i]
      });
    }

    Object.keys(restRawData).forEach((key) => {
      const rawItem = restRawData[key];
      const { waarde, label_4 } = rawItem;

     
      const barItem = result.bars.find(({ rawValue }) => rawValue === Math.floor(waarde)) as { label?: string };
      barItem.label = label_4;
    });
  }

  if (guard) {
    onError('nodata');
    return null;
  }

  return result;
};

export default valuesGradeMeterTransformer;