import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export interface VuurwapenSVGIconProps extends SvgIconProps {
  fill?: string;
}

const VuurwapenSVGIcon = (props: VuurwapenSVGIconProps) => {
  const { fill } = props;
  return (
    <SvgIcon fontSize={'inherit'} viewBox='0 0 59 36' {...props}>
      <path fill={fill} d='M24.1075401,20.0194273 L20,20.0194273 L21.2799451,15.1620795 C21.8733476,13.2799451 23.6189936,12 25.5925108,12 L32.0795187,12 C31.4456969,14.2224768 30.5277683,15.645354 30.3004829,16.0220152 C29.0732586,18.0570401 26.6750456,20.0194273 24.1075401,20.0194273 Z M54.9355609,1.31216342 L54.9355609,0.796670648 C54.9355609,0.35674443 54.5788165,7.10542736e-15 54.1388902,7.10542736e-15 L52.7699055,7.10542736e-15 C52.5449632,7.10542736e-15 52.3299792,0.0954833203 52.1794319,0.262432684 L51.2286992,1.31216342 L14.4582483,1.31216342 C12.2961076,1.31216342 9.71161429,1.86924709 8.99343913,4.15264575 C8.98172339,4.18896456 8.82590398,4.59725827 8.66481249,5.03777028 C8.31861223,5.98498824 7.58813554,6.71077864 6.99590464,7.17472213 C6.50442915,7.55958435 5.90223986,7.76636725 5.27779066,7.76636725 L2.49295812,7.76636725 L2.49295812,9.57820718 C2.49295812,10.6320384 3.0078651,11.1416733 3.0078651,11.1416733 C3.79457737,11.9477166 4.72890802,12.7291568 5.38440394,13.2446495 C6.28827365,13.9546237 6.2033345,14.655811 5.94090182,15.4044471 C5.94090182,15.4044471 0.619610519,29.2143813 0.319101664,30.4802675 C0.0180070222,31.7467395 0.0267938308,32.0320179 0.00101919223,33.6154008 C-0.0247554464,35.1987838 0.436259113,35.9029 1.62072091,35.9029 L12.8490908,35.9029 C13.9439271,35.9029 14.9175055,35.2860661 15.271321,34.2503942 L18.5558301,24.4589605 L24.4869259,24.4589605 C30.0882235,24.4589605 34.0914935,19.8253834 36.2553916,14.8280324 C36.9161596,13.3014709 37.0813516,13.1433083 37.50429,11.7971693 L55.6666234,11.7971693 C56.8587004,11.7971693 57.8539529,10.8886132 57.9617378,9.7012225 L58.5715423,3.204842 C58.6629251,2.19728794 57.870355,1.313335 56.8587004,1.31216342 L54.9355609,1.31216342 Z' />
    </SvgIcon>
  );
};

export default VuurwapenSVGIcon;  