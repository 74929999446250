import * as allFormatters from '../../../utils/formatters';
import { calcNumberOfRows, padLeftWithZeros, TransformFunction } from '../transform-utils';
import {
  ColumnChartRemainderDataModel,
  ColumnChartRemainderGroupModel,
  ColumnChartRemainderListRawData,
  ColumnChartRemainderListTransformOptions,
  ColumnChartRemainderListXThreshold
} from './columnChartRemainderListTransformer';

export interface ColumnChartRemainderGroupTransformOptions extends ColumnChartRemainderListTransformOptions {
  /**
   * Set the key to use as label in the mouse-over
   * @default label_4
   */
  valueLabelKey?: 'label_1' | 'label_2' | 'label_3' | 'label_4';
}


const findXThreshold = (xThresholdKeys: string[], groupModels: ColumnChartRemainderGroupModel[]) => {

  let xThreshold: ColumnChartRemainderListXThreshold | undefined = undefined;
  let currentThresholdLabel = '';
  xThresholdKeys.forEach((xThresholdKey, index) => {

    if (currentThresholdLabel !== xThresholdKey) {
      currentThresholdLabel = xThresholdKey;
      xThreshold = {
        groupIndex: index,
        groupKey: groupModels[index].key,
        label: currentThresholdLabel
      };
    }
  });

  return xThreshold;
};

const columnChartRemainderGroupTransformer: TransformFunction<ColumnChartRemainderDataModel, ColumnChartRemainderGroupTransformOptions> = (
  rawData: ColumnChartRemainderListRawData,
  onError,
  options
) => {

  const keys = Object.keys(rawData);
  const guard = !keys.some((key) => rawData[key].waarde !== null);

  if (guard) {
    onError('nodata');
    return null;
  }

  const {
    reverse = false,
    formatters = {},
    formattersOptions = {},
    xLabelKey = 'label_2',
    valueLabelKey = 'label_4',
    appendThresholdToXLabel = false
  } = options || {} as ColumnChartRemainderGroupTransformOptions;

  const valueFormatterName = formatters['value'];
  const valueFormatter = (allFormatters as any)[valueFormatterName];
  const valueFormatterOptions = formattersOptions['value'];

  const xLabelFormatterName: string = formatters['xLabel'];
  const xLabelFormatter = (allFormatters as any)[xLabelFormatterName];
  const xLabelFormatterOptions = formattersOptions['xLabel'];

  const shortXLabelFormatterName: string = formatters['shortXLabel'];
  const shortXLabelFormatter = (allFormatters as any)[shortXLabelFormatterName];
  const shortXFormatterOptions = formattersOptions['shortXLabel'];

  const labelFormatterName: string = formatters['label'];
  const labelFormatter = (allFormatters as any)[labelFormatterName];
  const labelFormatterOptions = formattersOptions['label'];

  const numberOfRows = calcNumberOfRows(keys);
  // currently always 2 columns
  const numberOfColumns = 2;
  let groupModels: ColumnChartRemainderGroupModel[] = [];
  const xThresholdKeys: string[] = [];

  for (let rowNumber = 0; rowNumber < numberOfRows; rowNumber++) {
    let groupModel: ColumnChartRemainderGroupModel = groupModels[rowNumber] || {};

    for (let colNumber = 0; colNumber < numberOfColumns; colNumber++) {
      let row = padLeftWithZeros(rowNumber, 2);
      let col = padLeftWithZeros(colNumber, 2);

      let key = `val${row}_${col}`;
      const rawDataItem = rawData[key];
      const { waarde, label_1, label_4, eenheid } = rawDataItem;

      let xLabel = xLabelFormatter ? xLabelFormatter(rawDataItem[xLabelKey] as string, xLabelFormatterOptions) : rawDataItem[xLabelKey] as string;
      if (!!label_1 && appendThresholdToXLabel) {
        xLabel = `${xLabel} ${label_1}`;
      }

      if (colNumber === 0) {
        groupModel.label = labelFormatter ? labelFormatter(label_4, labelFormatterOptions) : label_4;
        groupModel.xLabel = xLabel;
        groupModel.key = `${rawDataItem[xLabelKey]}-${rowNumber}`;
        groupModel.shortXLabel = xLabelFormatter ? shortXLabelFormatter(rawDataItem[xLabelKey] as string, shortXFormatterOptions) : rawDataItem[xLabelKey] as string;
        // the keys that are used in rawValues and formattedValues
        groupModel.valueKeys = [rawDataItem[valueLabelKey] as string];
        // This will for example result in: { "Nieuw": 1234, "Afgestoten": 4567 }
        groupModel.rawValues = {
          [rawDataItem[valueLabelKey] as string]: waarde
        };
        // This will for example result in: { "Nieuw": 1.234, "Afgestoten": 4.567 }
        groupModel.formattedValues = {
          [rawDataItem[valueLabelKey] as string]: valueFormatter ?
            valueFormatter(waarde, valueFormatterOptions) :
            allFormatters.formatValue(waarde, eenheid as ValueUnit, valueFormatterOptions)
        };

        reverse ? xThresholdKeys.unshift(label_1) : xThresholdKeys.push(label_1);
        // else add rawDataItem value and formatted value to the groupModel
      } else {

        groupModel.rawValues[rawDataItem[valueLabelKey] as string] = waarde;
        groupModel.formattedValues[rawDataItem[valueLabelKey] as string] = valueFormatter ? valueFormatter(waarde, valueFormatterOptions) : allFormatters.formatValue(waarde, eenheid as ValueUnit, valueFormatterOptions);

        groupModel.valueKeys.push(rawDataItem[valueLabelKey] as string);

        // NOTE: this should not be necessary unless labels are missing in the data, which should not happen.
        // groupModel.xLabel = xLabelFormatter ? xLabelFormatter(rawDataItem[xLabelKey] as string, xLabelFormatterOptions) : rawDataItem[xLabelKey] as string;
        // groupModel.shortXLabel = xLabelFormatter ? shortXLabelFormatter(rawDataItem[xLabelKey] as string, shortXFormatterOptions) : rawDataItem[xLabelKey] as string;
        // groupModel.label = labelFormatter ? labelFormatter(label_4, labelFormatterOptions) : label_4;
      }
    }

    groupModels[rowNumber] = groupModel;
  }

  groupModels = reverse ? groupModels.reverse() : groupModels;
  const xThreshold = findXThreshold(xThresholdKeys, groupModels);

  return { groups: groupModels, xThreshold };
};

export default columnChartRemainderGroupTransformer;
