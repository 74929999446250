import * as allFormatters from '../../../utils/formatters';
import {
  calcNumberOfRows,
  padLeftWithZeros,
  TransformFunction,
  TransformOptionsBase,
} from '../transform-utils';

interface RD {
  waarde: number;
  label_1: string;
  label_4: string;
  eenheid: string;
}

interface RawData {
  val00_00: RD;
  val00_01: RD;
  val00_02?: RD;
  [key: string]: RD | undefined;
}

export interface BarChartWaterfallTransformOptions extends TransformOptionsBase {
  lastValueFirst?: boolean;
}

export interface BarChartWaterfallDataItem {
  label: string;
  rawValue: number;
  formattedValue: string;
}
export interface BarChartWaterfallDataGroup {
  mainValue: BarChartWaterfallDataItem;
  secondaryValue?: BarChartWaterfallDataItem;
  tertiaryValue?: BarChartWaterfallDataItem;
}
export interface BarChartWaterfallDataModel {
  groups: BarChartWaterfallDataGroup[];
}

const barChartWaterfallTransformer: TransformFunction<BarChartWaterfallDataModel, BarChartWaterfallTransformOptions> = (
  rawData: RawData,
  onError,
  options
) => {

  const {
    formatters = {},
    lastValueFirst = false,
    formattersOptions = {}
  } = options || {} as BarChartWaterfallTransformOptions;
  let result: BarChartWaterfallDataModel = {
    groups: []
  };

  let guard = true;
  const keys = Object.keys(rawData);
  const numberOfRows = calcNumberOfRows(keys);

  for (let rowNumber = 0; rowNumber < numberOfRows; rowNumber++) {
    let row = padLeftWithZeros(rowNumber, 2);
    let rawMainValue = rawData[`val${row}_00`];
    let rawSecondaryValue = rawData[`val${row}_01`];
    let rawTertiaryValue = rawData[`val${row}_02`];

    let group = {} as BarChartWaterfallDataGroup;

    const mainValueFormatterName = formatters['mainValue'];
    const mainValueFormatterOptions = formattersOptions['mainValue'];
    const mainValueFormatter = (allFormatters as any)[mainValueFormatterName];

    const secondaryValueFormatterName = formatters['secondaryValue'];
    const secondaryValueFormatterOptions = formattersOptions['secondaryValue'];
    const secondaryValueFormatter = (allFormatters as any)[secondaryValueFormatterName];

    const tertiaryValueFormatterName = formatters['tertiaryValue'];
    const tertiaryValueFormatterOptions = formattersOptions['tertiaryValue'];
    const tertiaryValueFormatter = (allFormatters as any)[tertiaryValueFormatterName];

    if (!!rawMainValue) {
      const { label_1, waarde, eenheid } = rawMainValue;

      group.mainValue = {
        label: label_1,
        rawValue: waarde,
        formattedValue: mainValueFormatter ? mainValueFormatter(waarde, mainValueFormatterOptions) : allFormatters.formatValue(waarde, eenheid as ValueUnit, mainValueFormatterOptions)
      };
    }

    if (!!rawSecondaryValue) {
      const { label_4, waarde, eenheid } = rawSecondaryValue;

      group.secondaryValue = {
        label: label_4,
        rawValue: waarde,
        formattedValue: secondaryValueFormatter ? secondaryValueFormatter(waarde, secondaryValueFormatterOptions) : allFormatters.formatValue(waarde, eenheid as ValueUnit, secondaryValueFormatterOptions)
      };
    }

    if (!!rawTertiaryValue) {
      const { label_4, waarde, eenheid } = rawTertiaryValue;

      group.tertiaryValue = {
        label: label_4,
        rawValue: waarde,
        formattedValue: tertiaryValueFormatter ? tertiaryValueFormatter(waarde, tertiaryValueFormatterOptions) : allFormatters.formatValue(waarde, eenheid as ValueUnit, tertiaryValueFormatterOptions)
      };
    }

    if (!!group.mainValue) {
      guard = false;

      if (lastValueFirst && rowNumber === numberOfRows -1) {
        result.groups.unshift(group);
      } else {
        result.groups.push(group);
      }
    }
  }

  if (guard) {
    onError('nodata');
    return null;
  }

  return result;
};

export default barChartWaterfallTransformer;