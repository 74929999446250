import { ScaleBand, scaleOrdinal } from 'd3-scale';
import { Chart } from 'ppd-library/charts';
import { Tooltip } from 'ppd-library/charts//organisms/Tooltip';
import { scales } from 'ppd-library/charts/chartUtils';
import { BarVertical } from 'ppd-library/charts/molecules/BarVertical';
import { LineThresholdVertical } from 'ppd-library/charts/molecules/LineThreshold';
import { AxisBottom, AxisLeft } from 'ppd-library/charts/organisms/Axis';
import { BarVerticalGroup } from 'ppd-library/charts/organisms/BarVerticalGroup';
import { BarVerticalStacked } from 'ppd-library/charts/organisms/BarVerticalStacked';
import { HorizontalGridLines } from 'ppd-library/charts/organisms/GridLines';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import * as allFormatters from '../../../utils/formatters';
import { ComponentBaseProps, Margins } from '../ComponentBaseProps';
import { getSVGMousePosition, TooltipTemplate, useTooltipPosition } from '../tooltip-utils';
import {
  ColumnChartRemainderDataModel,
  ColumnChartRemainderGroupModel,
} from './columnChartRemainderListTransformer';
import { Legend, useLegend } from '../legend-utils';

interface StackedDataModel {
  key: string;
  [key: string]: string | number;
}

export interface ColumnChartRemainderGroupThemeProps {
  singleBarColor?: string;
  stackedBarColors?: string | string[];
}
export interface ColumnChartRemainderGroupProps extends ComponentBaseProps<ColumnChartRemainderGroupThemeProps, ColumnChartRemainderDataModel> {
  /**
   * When true, will hide the y axis.
   * When number, dense will be true when the width property < the supplied number in pixels.
   * @default false
   */
  dense?: boolean | number;
  /**
  * Set the y axis maximum to a fixed number.
  */
  yMax?: number;
  /**
   * Set the y axis minimum to a fixed number.
   */
  yMin?: number;
  /**
   * Set the y axus maximum value to a minimum value, so it can go higher but not lower.
   */
  yMinMax?: number;
  /**
   * Set fixed numeric ticks on the y-axis.
   */
  yFixedTicks?: number[];
  /**
   * Offset each tick by a number of pixels. Use 'center' to center the ticks between y grid lines.
   * Note!: center only works on fixed ticks at the moment.
   * @default 0
   */
  yTickOffset?: number | 'center';
  /**
   * @default 10
   */
  yNumberOfTicks?: number;
  /**
   * Set a threshold in height pixels to show half the number of y ticks (5).
   * Will not be used when 'yNumberOfTicks' is explicitly set.
   */
  yHalfNumberOfTicks?: number;
  showTooltip?: boolean;
  showThreshold?: boolean;
  offsetWidth?: { percentage: number, fixedXOffset?: boolean }[];
  /**
   * Merge the value label with the 'key' in the tooltip, 
   * for example label is 'Budget' and key is '2020' will be 'Budget 2020'.
   * When false, only 'key' will be used to form the label.
   * TODO: Zou eigenlijk default false moeten zijn?..
   * @default true
   */
  mergeTooltipLabel?: boolean;
}

const StyledWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const StyledChartHolder = styled.div`
`;

const createEmptyGroupData = (group: ColumnChartRemainderGroupModel) => {
  let rawValues = {} as { [key: string]: number };
  group.valueKeys.forEach((key) => rawValues[key] = 0);
  const emptyGroupData = { ...group, rawValues };

  return emptyGroupData;
};

const ColumnChartRemainderGroup = (props: ColumnChartRemainderGroupProps) => {
  const {
    id,
    data,
    yMax,
    yMin,
    // help,
    // showHelp,
    yMinMax,
    yFixedTicks,
    tooltipOptions,
    yNumberOfTicks: originalYNumberOfTicks,
    width = 500,
    dense = false,
    formatters = {},
    showTooltip = true,
    legendOptions = {},
    showThreshold = true,
    themeProperties,
    formattersOptions = {},
    yHalfNumberOfTicks = 260,
    mergeTooltipLabel = true,
    height: originalHeight = 450,
    yTickOffset: originalYTickOffset = 0,
    margins: originalMargins = {} as Margins,
    offsetWidth = [{ percentage: 125, fixedXOffset: true }, { percentage: 125 }]
  } = props;

  const isDense = dense === true || (typeof dense === 'number' && width < dense);
  const { left = 50, right = 5, top = 10, bottom = showThreshold ? 50 : 30 } = originalMargins;
  let margins = { left, top, right, bottom };
  margins.left = isDense ? 10 : margins.left;

  // Note: we assume a stack of 2 bars
  const verticalStackKeys = ['value', 'remainder'];

  const {
    singleBarColor = 'gray',
    stackedBarColors: originalStackedBarColors,
  } = themeProperties || {} as ColumnChartRemainderGroupThemeProps;

  const stackedBarColors = !!originalStackedBarColors ?
    Array.isArray(originalStackedBarColors) ? originalStackedBarColors : [originalStackedBarColors] :
    ['#2b4899', '#4d9ce2'];

  const { groups, xThreshold } = data;
  const ticks = groups.map((item) => item.key);

  const yAxisTickFormatterName = formatters['yAxisTick'];
  const yAxisTickFormatter = (allFormatters as any)[yAxisTickFormatterName] || allFormatters.formatNumber;
  const yAxisTickFormatterOptions = formattersOptions['yAxisTick'];

  const allRawValues = groups.reduce((previousResult, currentGroup) => {
    const currentGroupRawValues = currentGroup.valueKeys.map((key) => currentGroup.rawValues[key]);
    return [...previousResult, ...currentGroupRawValues];
  }, [] as number[]);

  const rawValuesMax = Math.max(...allRawValues);

  // yMinMax or calculate based on data
  let maxValue = !!yMinMax && yMinMax > rawValuesMax ? yMinMax : rawValuesMax;
  // yFixedTicks overrides yMinMax and calculated
  maxValue = !!yFixedTicks ? Math.max(...yFixedTicks) : maxValue;
  // yMax overrides yMinMax, calculated and fixedTicks
  maxValue = yMax != null ? yMax : maxValue;
  // yFixedTicks or calculate based on data with a minimum of 0
  let minValue = !!yFixedTicks ? Math.min(...yFixedTicks) : Math.min(...allRawValues) > 0 ? 0 : Math.min(...allRawValues);
  // yMin overrides fixedTicks and calculated
  minValue = yMin != null ? yMin : minValue;


  const legendElementRef = React.createRef<HTMLDivElement>();
  const { legendHeight } = useLegend(originalHeight, legendElementRef);
  const height = originalHeight - legendHeight;

  // Scaling
  let yNumberOfTicks = originalYNumberOfTicks || 10;
  yNumberOfTicks = height < yHalfNumberOfTicks ? Math.ceil(yNumberOfTicks / 2) : yNumberOfTicks;

  let yTickOffset = 0;
  let fixedYAxisScale: ScaleBand<string> | undefined = undefined;

  const x0Scale = scales.stringXScale(ticks, width, margins.left, margins.right);
  const yScale = scales.numericYScale(minValue, maxValue, height, margins.bottom, margins.top).nice();

  // to center labels underneath the bars
  const xScaleTickOffset = x0Scale.bandwidth() / 2;

  if (!!yFixedTicks) {
    fixedYAxisScale = scales.stringYScale(yFixedTicks.map((tick) => `${tick}`).reverse(), height, margins.top, margins.bottom, 0);

    const step = fixedYAxisScale.step();
    yTickOffset = originalYTickOffset === 'center' ? -(step / 2) : originalYTickOffset;
  }
  // /Scaling

  const createStackedData = (group: ColumnChartRemainderGroupModel) => {
    const { valueKeys: groupKeys, rawValues } = group;

    const model: StackedDataModel = {
      key: group.key,
      value: 0,
      remainder: 0
    };

    // Note: we assume a stack of 2 bars for now
    const currentValue = rawValues[groupKeys[1]] as number;
    const previousValue = rawValues[groupKeys[0]] as number;
    const hasRemainingValue = currentValue > previousValue;

    model.value = hasRemainingValue ? currentValue - (currentValue - previousValue) : currentValue;
    model.remainder = hasRemainingValue ? currentValue - previousValue : 0;

    return model;
  }

  // Note: empty placeholder groupColorScale
  const groupColorScale = scaleOrdinal<string, string>();
  const stackedBarColorScale = scaleOrdinal<string, string>().domain(verticalStackKeys).range(stackedBarColors);

  let tooltipLeaveTimeoutId = 0;
  const handleMouseMoveAndTouchStart = (event: React.MouseEvent<Element, MouseEvent> | React.TouchEvent, index: number, data: ColumnChartRemainderGroupModel) => {
    clearTimeout(tooltipLeaveTimeoutId);
    const { x, y } = getSVGMousePosition(event as React.MouseEvent<SVGElement, MouseEvent> | React.TouchEvent<TouchEvent>);
    setTooltipPosition({ visible: true, x, y, index, data });
  };

  const handleMouseLeaveAndTouchEnd = () => {
    clearTimeout(tooltipLeaveTimeoutId);
    tooltipLeaveTimeoutId = setTimeout(() => {
      setTooltipPosition({ ...tooltipPosition, visible: false });
    }, 250);
  };

  const { tooltipPosition, setTooltipPosition } = useTooltipPosition<ColumnChartRemainderGroupModel>();

  const [initiated, setInitiated] = useState(false);
  useEffect(() => {
    setInitiated(true);
  }, []);

  return (
    <StyledWrapper>
      <StyledChartHolder>
        <Chart
          width={width}
          height={height}
        >
          <HorizontalGridLines
            numberOfTicks={yNumberOfTicks}
            id={`${id}-horizontal-grid-lines`}
            scale={fixedYAxisScale ? fixedYAxisScale : yScale}
            tickValues={!!yFixedTicks ? yFixedTicks : undefined}
            x={margins.left + (x0Scale.paddingOuter() * x0Scale.bandwidth())}
            lineLength={width - margins.left - margins.right - (x0Scale.paddingOuter() * 2 * x0Scale.bandwidth())}
          />
          {
            groups.map((originalGroup, groupIndex) => {
              const group = initiated ? originalGroup : createEmptyGroupData(originalGroup);
              const valueKeys = group.valueKeys;
              const x1Scale = scales.stringX1Scale(valueKeys, x0Scale.bandwidth());

              return (
                <BarVerticalGroup
                  yScale={yScale}
                  xScale={x1Scale}
                  keys={valueKeys}
                  offsetWidth={offsetWidth}
                  colorScale={groupColorScale}
                  x={x0Scale(group.key) as number}
                  maxHeight={height - margins.bottom}
                  id={`${id}-bar-vertical-group-${group.key}`}
                  key={`${id}-bar-vertical-group-${group.key}`}
                  data={{ key: group.key, ...group.rawValues }}
                  mouseMove={(ev) => handleMouseMoveAndTouchStart(ev, groupIndex, group)}
                  touchStart={(ev) => handleMouseMoveAndTouchStart(ev, groupIndex, group)}
                  mouseLeave={handleMouseLeaveAndTouchEnd}
                  touchEnd={handleMouseLeaveAndTouchEnd}
                >
                  {([firstBar, secondBar]) => (
                    <>
                      <BarVertical
                        x={firstBar.x}
                        y={firstBar.y}
                        fill={singleBarColor}
                        width={firstBar.width}
                        height={firstBar.height}
                        id={`${id}-bar-vertical-group-${group.key}-bar-1`}
                      />
                      <BarVerticalStacked
                        yScale={yScale}
                        x={secondBar.x}
                        width={secondBar.width}
                        keys={verticalStackKeys}
                        data={createStackedData(group)}
                        colorScale={stackedBarColorScale}
                        id={`${id}-bar-vertical-group-${group.key}-bar-2`}
                      />
                    </>
                  )}
                </BarVerticalGroup>
              );
            })
          }
          {!!showThreshold && !!xThreshold &&
            <LineThresholdVertical
              lineWidth={1}
              showCircle={false}
              lineHeight={margins.bottom}
              y={height - margins.bottom}
              labelPadding={[2, 4, 2, 4]}
              labelPos={[x0Scale(xThreshold.groupKey) || margins.left < (width / 2) ? 'right' : 'left', 'bottom']}
              theme={{ textSize: '0.8rem' }}
              labelDominantBaseline={'auto'} // IE fix
              id={`${id}-line-horizontal-threshold`}
              x={x0Scale(xThreshold.groupKey) || margins.left}
              label={<tspan dy={13}>{xThreshold.label}</tspan>}
            />
          }
          {!isDense &&
            <AxisLeft
              x={margins.left}
              id={`${id}-axis-left`}
              tickOffset={yTickOffset}
              numberOfTicks={yNumberOfTicks}
              scale={!!fixedYAxisScale ? fixedYAxisScale : yScale}
              tickValues={!!yFixedTicks ? yFixedTicks : undefined}
              tickFormat={(value) => {
                if (!!yAxisTickFormatter) {
                  return yAxisTickFormatter(value, yAxisTickFormatterOptions);
                }

                return value;
              }}
            />
          }
          <AxisBottom
            scale={x0Scale}
            y={-margins.bottom}
            chartHeight={height}
            id={`${id}-axis-bottom`}
            tickOffset={xScaleTickOffset}
            tickFormat={(_value, i) => `${groups[i].shortXLabel}`}
          />
        </Chart>
        <Tooltip
          top={tooltipPosition.y}
          left={tooltipPosition.x}
          visible={showTooltip && tooltipPosition.visible}
          theme={{
            transition: false
          }}
        >
          {() => {
            const {
              label,
              xLabel,
              valueKeys,
              rawValues,
              formattedValues
            } = tooltipPosition.data as ColumnChartRemainderGroupModel;

            return (
              <TooltipTemplate
                title={xLabel}
                tooltipOptions={tooltipOptions}
                values={
                  valueKeys.map((key, index) => {
                    return {
                      // TODO: dit is een beetje dirty... doen via tooltipoptions of transformer?
                      label: mergeTooltipLabel ? `${label} ${key}` : key, 
                      rawValue: rawValues[key],
                      colors: index === 0 ? [singleBarColor] : stackedBarColors,
                      formattedValue: rawValues[key] !== null ? formattedValues[key] : 'n.v.t.', // TODO: hardcoded n.v.t.
                    }
                  })
                }
              />
            );
          }}
        </Tooltip>

        {!isDense &&
          <Legend
            ref={legendElementRef}
            legendOptions={{
              orientation: 'horizontal',
              ...legendOptions
            }}
            values={groups[0].valueKeys.map((key, index) => {
              return {
                label: key,
                colors: index === 0 ? [singleBarColor] : stackedBarColors
              };
            })}
          />
        }

      </StyledChartHolder>
    </StyledWrapper>
  )
};

export default ColumnChartRemainderGroup;

