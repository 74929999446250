import { TransformFunction, TransformOptionsBase } from '../transform-utils';
import * as allFormatters from '../../../utils/formatters';
import { calcNumberOfRows, padLeftWithZeros } from '../transform-utils';
interface RD {
  waarde: number;
  label_1: string;
  label_2?: string;
  label_3?: string;
  label_4: string;
  eenheid: string;
}
export interface WaffleChartRawData {
  [key: string]: RD;
}
export interface WaffleChartDataModel {
  label?: string;
  rawValue: number;
  formattedValue: string;
}
export interface StackedBarItemDataModel {
  [key: string]: {
    id: string;
    sequence: number;
    acummulatedValue: number;
    primaryValue: WaffleChartDataModel;
    secondaryValue: WaffleChartDataModel;
  }
}
export interface WaffleChartTransformOptions extends TransformOptionsBase {
  reverse?: boolean;
}
export interface WaffleChartModel {
  chartModel: StackedBarItemDataModel[];
  valuesModel: StackedBarItemDataModel;
}

const WaffleChartTransformer: TransformFunction<WaffleChartModel, WaffleChartTransformOptions> = (
  rawData: WaffleChartRawData,
  onError,
  options
) => {

  const keys = Object.keys(rawData);
  const guard = !keys.some((key) => rawData[key].waarde !== null);

  if (guard) {
    onError('nodata');
    return null;
  }

  const {
    formatters = {},
    formattersOptions = {},
    reverse = true,
  } = options || {} as WaffleChartTransformOptions;

  const valueFormatterName = formatters['value'];
  const valueFormatter = (allFormatters as any)[valueFormatterName];
  const valueFormatterOptions = formattersOptions['value'];

  const numberOfRows = calcNumberOfRows(keys);
  const iconDataArr: {}[] = [];
  let valuesModel: StackedBarItemDataModel = {};

  let uniqueLabel: string = '';
  let acummulatedValue: number = 0;
  let maxValueLabel: string = '';
  let maxVal:number = 0;

  // TODO: het moeten altijd 100 icoontjes zijn
  for (let rowNumber = 0; rowNumber < numberOfRows; rowNumber++) {
    const row = padLeftWithZeros(rowNumber, 2);

    const key00 = rawData[`val${row}_00`];
    const { label_1, waarde: primaryValue, eenheid: primaryValueUnit } = key00;
    const key01 = rawData[`val${row}_01`];
    const { waarde: secondaryValue, eenheid: secondaryValueUnit } = key01;

    if(primaryValue > maxVal ) {
      maxValueLabel = label_1;
    }
    maxVal = primaryValue;


    for (let valNumber = 0; valNumber < Math.ceil(primaryValue <= 0 ? 1 : primaryValue); valNumber++) {
      const id = `${label_1}-${primaryValue * valNumber}-${valNumber}`;

      iconDataArr.push({ [(label_1 as string).toLowerCase()]: { id, value: primaryValue  } });

      if (uniqueLabel !== (label_1 as string).toLowerCase()) {
        valuesModel[(label_1 as string).toLowerCase()] = {
          id: `${rowNumber}`,
          sequence: rowNumber,
          acummulatedValue: acummulatedValue = acummulatedValue + primaryValue,
          primaryValue: {
            label: label_1,
            rawValue: primaryValue,
            formattedValue: valueFormatter ? valueFormatter(primaryValue, valueFormatterOptions) : allFormatters.formatValue(primaryValue, primaryValueUnit as ValueUnit, valueFormatterOptions)
          },
          secondaryValue: {
            rawValue: secondaryValue,
            formattedValue: valueFormatter ? valueFormatter(key01.waarde, valueFormatterOptions) : allFormatters.formatValue(secondaryValue, secondaryValueUnit as ValueUnit, valueFormatterOptions)
          }
        };
        uniqueLabel = (label_1 as string).toLowerCase();
      }
    }
  }


  // When the 100 icons are exceeded, remove 1 of the most common icons
  // This is because percentages are sent with decimals
  if(iconDataArr.length > 100) {
    const index = iconDataArr.findIndex(obj => Object.keys(obj)[0] === maxValueLabel.toLowerCase());
    iconDataArr.splice(index, iconDataArr.length - 100);
  }

  return { chartModel: reverse ? iconDataArr.reverse() : iconDataArr, valuesModel };
};

export default WaffleChartTransformer;