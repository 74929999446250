import { TilePlaceholder } from 'ppd-library/components/organisms/Tile';
import React from 'react';

export interface TemplateDoesNotExistPlaceholderProps {
  isLoading: boolean;
  placeholderColor: string;
  placeholderText: string | null;
  templateDetail: API_GET.TemplateDetail | null;
}

const TemplateDoesNotExistPlaceholder = (props: TemplateDoesNotExistPlaceholderProps) => {
  const {
    isLoading,
    templateDetail,
    placeholderText,
    placeholderColor,
  } = props;

  return (
    <>
      {!templateDetail && !isLoading &&
        <TilePlaceholder
          message={placeholderText || 'Deze dashboard pagina bestaat niet of kan niet gevonden worden.'}
          theme={{ palette: { secondary: { main: placeholderColor } } }}
        />
      }
    </>
  );
};

export default TemplateDoesNotExistPlaceholder;