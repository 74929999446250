import { TransformFunction, TransformOptionsBase } from '../transform-utils';
import * as allFormatters from '../../../utils/formatters';

interface RD {
  waarde: number;
  label_4: string;
  eenheid: string;
}

export interface DoubleSlideBarsRawData {
  val00_00: RD;
  val00_01: RD;
  val00_02: RD;
}

export interface DoubleSlideBarsDataModel {
  mainValue: {
    label: string;
    rawValue: number;
    formattedValue: string;
  }
  secondaryValue: {
    label: string;
    rawValue: number;
    formattedValue: string;
  }
  thresholdValue: {
    label: string;
    rawValue: number;
    formattedValue: string;
  }
}

export interface DoubleSlideBarsTransformOptions extends TransformOptionsBase {
}

const doubleSlideBarsTransformer: TransformFunction<DoubleSlideBarsDataModel, DoubleSlideBarsTransformOptions> = (
  rawData: DoubleSlideBarsRawData,
  onError,
  options
) => {
  const { val00_00, val00_01, val00_02 } = rawData;
  const { formatters = {}, formattersOptions = {} } = options || {} as DoubleSlideBarsTransformOptions;

  let result: DoubleSlideBarsDataModel = {
    mainValue: { label: '', rawValue: 0, formattedValue: '' },
    secondaryValue: { label: '', rawValue: 0, formattedValue: ''},
    thresholdValue: { label: '', rawValue: 0, formattedValue: ''},
  };
  let guard = true;

  if (val00_00.waarde != null) {
    guard = false;
    const { label_4, waarde, eenheid } = val00_00;
    const mainValueFormatterName = formatters['mainValue'];
    const mainFormatterOptions = formattersOptions['mainValue'];
    const mainValueFormatter = (allFormatters as any)[mainValueFormatterName];

    result.mainValue.label = label_4;
    result.mainValue.rawValue = waarde;
    result.mainValue.formattedValue = mainValueFormatter ? mainValueFormatter(waarde, mainFormatterOptions) : allFormatters.formatValue(waarde, eenheid as ValueUnit, mainFormatterOptions);
  }

  if (val00_01.waarde != null) {
    guard = false;
    const { waarde, label_4, eenheid } = val00_01;
    const secondaryValueFormatterName = formatters['secondaryValue'];
    const secondaryFormatterOptions = formattersOptions['secondaryValue'];
    const secondaryValueFormatter = (allFormatters as any)[secondaryValueFormatterName];

    result.secondaryValue.label = label_4;
    result.secondaryValue.rawValue = waarde;

    result.secondaryValue.formattedValue = secondaryValueFormatter ? secondaryValueFormatter(waarde, secondaryFormatterOptions) : allFormatters.formatValue(waarde, eenheid as ValueUnit, secondaryFormatterOptions);
  }

  if (val00_02 && val00_02.waarde != null) {
    guard = false;
    const { waarde, label_4, eenheid } = val00_02;
    const thresholdValueFormatterName = formatters['thresholdValue'];
    const thresholdFormatterOptions = formattersOptions['thresholdValue'];
    const thresholdValueFormatter = (allFormatters as any)[thresholdValueFormatterName];

    result.thresholdValue.label = label_4;
    result.thresholdValue.rawValue = waarde;
    result.thresholdValue.formattedValue = thresholdValueFormatterName ? thresholdValueFormatter(waarde, thresholdFormatterOptions) : allFormatters.formatValue(waarde, eenheid as ValueUnit, thresholdFormatterOptions);
  }

  if (guard) {
    onError('nodata');
    return null;
  }

  return result;
};

export default doubleSlideBarsTransformer;