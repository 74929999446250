import { BackgroundImage } from 'ppd-library/charts/atoms/BackgroundImage';
import { CircleAnimated } from 'ppd-library/charts/molecules/CircleAnimated';
import Tooltip from 'ppd-library/charts/organisms/Tooltip/Tooltip';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { ComponentBaseProps } from '../ComponentBaseProps';
import { useLegend } from '../legend-utils';
import { getSVGMousePosition, TooltipTemplate, useTooltipPosition } from '../tooltip-utils';
import { bubbleMapDefaultPoints } from './bubbleMapDefaultPoints';
import { BubbleMapDataModel, BubbleMapValueModel } from './bubbleMapTransformer';
import mapSvg from './kaart_nl.svg';

export interface BubbleMapPoint {
  // must correspond to a key in the data (MapDataModel)
  key: string;
  x: number;
  y: number;
}

export interface BubbleMapThemeProps {
  /**
  * maximum radius of a point/circle in pixels
  * @default 40
  */
  maxPointRadius?: number;
  /**
   * minimum radius of a point/circle in pixels
   * @default 24
   */
  minPointRadius?: number;
  /**
   * settings for circles that have no known x and y (by key in points)
   */
  pointUnknown?: {
    /**
     * stroke width in pixels
     * @default 1
     */
    strokeWidth?: number;
    /**
     * @default #2b4899
     */
    strokeColor?: string;
    /**
     * @default #FFFFFF
     */
    backgroundColor?: string;
    /**
     * @default #202020
     */
    textColor?: string;
  },
  /**
   * settings for circles that do have known x and y (by key in points)
   */
  pointKnown?: {
    /**
    * stroke width in pixels
    * @default 0
    */
    strokeWidth?: number;
    /**
     * @default #2b4899
     */
    strokeColor?: string;
    /**
     * @default #2b4899
     */
    backgroundColor?: string;
    /**
     * @default #FFFFFF
     */
    textColor?: string;
  }
}

export interface BubbleMapProps extends ComponentBaseProps<BubbleMapThemeProps, BubbleMapDataModel> {
  points: BubbleMapPoint[];
  /**
   * If data contains a key that is not also in points,
   * the item will be plotted to the left side op the map.
   */
  data: BubbleMapDataModel;
  // TODO: showtooltip verplaatsen naar tooltipoptions?
  showTooltip?: boolean;

  threshold?: number | 'dynamic';
  /**
   * @default ['#2b4899','#D90000']
   */
  thresholdColors?: string[];
}

const StyledWrapper = styled.div`
  flex: 1;
  display: flex;
  position:relative;
  flex-direction: column;
` as React.ComponentType<React.HTMLProps<HTMLDivElement>>;

const StyledSvg = styled.svg`
  /* top: 0;
  left: 0;
  position: absolute; 
  display: inline-block; */
`;

const getRadius = (value: number, maxValue: number, minRadius: number, maxRadius: number) => {

  const valuePercentage = value / maxValue;
  const minRadiusPercentage = minRadius / maxRadius;
  let percentage = valuePercentage > minRadiusPercentage ? valuePercentage : minRadiusPercentage;
  percentage = percentage < 1 ? percentage : 1;

  const radius = maxRadius * percentage;

  return radius;
};

const BubbleMap = (props: BubbleMapProps) => {
  const {
    id,
    data,
    tooltipOptions,
    width = 620,
    height = 550,
    showTooltip = true,
    themeProperties,
    points = bubbleMapDefaultPoints,
  
  } = props;

  const { values } = data;

  
 

  const {
    pointKnown = {},
    minPointRadius = 24,
    maxPointRadius = 40,
  } = themeProperties || { } as BubbleMapThemeProps;
  const {
    strokeWidth: knownStrokeWidth = 0,
    textColor: knownTextColor = '#FFFFFF'
  } = pointKnown;

  const averageRadius = (maxPointRadius + minPointRadius) / 2;
  const circlePointsKnown = values.filter(({ key: dataKey }) => points.some(({ key: pointKey }) => dataKey === pointKey));
  const circlePointsUnknown = values.filter(({ key: dataKey }) => !points.some(({ key: pointKey }) => dataKey === pointKey));

  const numericRawValues = circlePointsKnown
    .filter(({ rawValue }) => typeof rawValue === 'number')
    .map(({ rawValue }) => rawValue as number);
  const numericMaxValue = Math.max(...numericRawValues);

  const [init, setInit] = useState(false);
  useEffect(() => setInit(true), [setInit]);

  const handleMouseMove = (event: React.MouseEvent<Element, MouseEvent>, index: number, data: BubbleMapValueModel) => {
    const { x, y } = getSVGMousePosition(event as React.MouseEvent<SVGElement, MouseEvent>);
    setTooltipPosition({ visible: true, x, y, index, data });
  };

  const handleMouseLeave = () => {
    setTooltipPosition({ ...tooltipPosition, visible: false });
  };

  const legendElementRef = React.createRef<HTMLDivElement>();
  const { legendHeight } = useLegend(height, legendElementRef);
  const { tooltipPosition, setTooltipPosition } = useTooltipPosition<BubbleMapValueModel>();

  return (
    <StyledWrapper>
      <StyledSvg
        width={width}
        height={height - legendHeight}
        viewBox={'0 0 630 550'}
      >
        <g transform={`translate(${circlePointsUnknown.length > 0 ? averageRadius * 2 : 0}, 0)`}>
          <BackgroundImage
            url={mapSvg}
            height={'100%'}
            width={'550'}
            id={`${id}-background-map`}
          />
          {circlePointsKnown.map((mapDataModel, i) => {
            const { key, formattedValue, rawValue } = mapDataModel;
            const { x, y } = points.find(({ key: pointKey }) => key === pointKey) as BubbleMapPoint;
            const radius = typeof rawValue === 'number' ?
              getRadius(rawValue as number, numericMaxValue, minPointRadius, maxPointRadius) :
              averageRadius;
            let textSize = radius / averageRadius * 100;
            textSize = textSize < 80 ? 80 : textSize;
            textSize = textSize > 150 ? 150 : textSize;

            return (
              <CircleAnimated
                x={x}
                y={y}
                id={key}
                key={key}
                text={formattedValue}
                textSize={`${textSize}%`}
                radius={init ? radius : 0}
                textColor={knownTextColor}
                strokeWidth={knownStrokeWidth}
                strokeColor={'#2b4899'}
                backgroundColor={'#2b4899'}
                onMouseMove={(ev) => handleMouseMove(ev, i, mapDataModel)}
                onMouseLeave={handleMouseLeave}
              />
            );
          })}
        </g>
      </StyledSvg>

      <Tooltip
        top={tooltipPosition.y}
        left={tooltipPosition.x}
        calculateOffsetPosition={false}
        visible={showTooltip && tooltipPosition.visible}
        theme={{
          transition: false
        }}
      >
        {() => {
          const {
            label,
            rawValue,
            valueLabel,
            formattedValue
          } = tooltipPosition.data as BubbleMapValueModel;

          return (

            <TooltipTemplate
              title={label}
              tooltipOptions={tooltipOptions}
              values={[{
                label: valueLabel,
                rawValue: rawValue as number,
                formattedValue: formattedValue,
                colors: []
              }]}
            />
          );
        }}
      </Tooltip>
    </StyledWrapper>
  )
}

export default BubbleMap;
