export interface HttpOptions {
  /**
   * @default false
   */
  // noCache?: boolean;
  /**
   * @default true
   */
  withCredentials?: boolean;
}

/**
 * 
 * @param url 
 * @param requestInit 
 * @param timeOut in milliseconds
 */
// async function fetchWithTimeout(url: string, requestInit: RequestInit, timeOut = 5000) {
//   let controller = !!AbortController ? new AbortController() : null;
//   let timeoutId = -1;

//   if(!!controller) {
//     const signal = (controller as AbortController).signal;

//     const fetchPromise = fetch(url, {signal, ... requestInit});
//     timeoutId = setTimeout(() => (controller as AbortController).abort(), timeOut);

//     fetchPromise.then(response => {
//       // completed request before timeout fired

//       // If you only wanted to timeout the request, not the response, add:
//       clearTimeout(timeoutId);
//     });
//   } 
//   else {
//     // Do it without abortcontroller
//   }
// }

export async function httpGet<TResult>(url: string, options: HttpOptions = {}) {
  const { withCredentials = true } = options;
  try {
    const response = await fetch(
      url, {
        cache: 'no-store',
        headers: {
          'Content-Type': 'application/json',
          'x-auth': '1247882019'
        },
        credentials: withCredentials ? 'include' : 'omit',
      }
    );

    if(!response.ok) { throw response; }
   
    const result = await response.json();

    return result as TResult;
  }
  catch (e) {
    if(e.text) {
      const message = await e.text();
      throw new Error(message);
    }

    throw e;
  }
}

export async function httpPut<TData, TResult>(url: string, data: TData, options: HttpOptions = {}) {
  const { withCredentials = true } = options;

  try {
    const response = await fetch(
      url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'x-auth': '1247882019'
        },
        credentials: withCredentials ? 'include' : 'omit',
        body: JSON.stringify(data)
      }
    );

    if(!response.ok) { throw response; }

    const result = await response.json();

    return result as TResult;
  }
  catch (e) {
    if(e.text) {
      const message = await e.text();
      throw new Error(message);
    }

    throw e;
  }
}

export async function httpPost<TData, TResult>(url: string, data: TData, options: HttpOptions = {}) {
  const { withCredentials = true } = options;

  try {
    const response = 
      await fetch(
        url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          'x-auth': '1247882019'
          },
          credentials: withCredentials ? 'include' : 'omit',
          body: JSON.stringify(data)
        }
      );

    if(!response.ok) { throw response; }

    try{
    const result = await response.json();

    return result as TResult;
    } catch(e) {
      // TODO: if we get here, the json is not well formatted / there is no json
      return {} as TResult;
    }
  }
  catch (e) {
    if(e.text) {
      const message = await e.text();
      throw new Error(message);
    }

    throw e;
  }
}


export async function httpDelete(url: string, options: HttpOptions = {}) {
  const { withCredentials = true } = options;

  try {
    // const res = 
    await fetch(
      url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'x-auth': '1247882019'
        },
        credentials: withCredentials ? 'include' : 'omit'
      }
    );

    // TODO: wat doen met de resultaten hier?
    return;
  }
  catch (e) {
    // TODO: moet hier nog meer gedaan?
    throw e;
  }
}

let tilesCache: Promise<API_GET.Tile[]> | null = null;
export const httpGetAllTiles = async (apiUrl: string) => {
  try {
    let tiles: API_GET.Tile[];
    let cachedPromise = tilesCache;

    if (!!cachedPromise) {
      tiles = await cachedPromise;
    } else {
      const newPromise = httpGet<API_GET.Tile[]>(`${apiUrl}/tiles`);
      tilesCache = newPromise;
      tiles = await newPromise;
    }

    return tiles;
  } catch (e) {
    // TODO: catch this somewhere
    console.error(e);
    throw new Error('Er is iets mis gegaan bij het ophalen van de alle tegels');
  }
};

let tilesDetailCache: Promise<API_GET.TileDetail[]> | null = null;
export const httpGetAllTilesDetail = async (apiUrl: string) => {
  try {
    let tilesDetail: API_GET.TileDetail[];
    let cachedPromise = tilesDetailCache;

    if (!!cachedPromise) {
      tilesDetail = await cachedPromise;
    } else {
      const newPromise = httpGet<API_GET.TileDetail[]>(`${apiUrl}/tiles_detail`);
      tilesDetailCache = newPromise;
      tilesDetail = await newPromise;
    }

    return tilesDetail;
  } catch (e) {
    // TODO: catch this somewhere
    console.error(e);
    throw new Error('Er is iets mis gegaan bij het ophalen van de alle tegel details');
  }
};

let staticTemplateTileDetailsCache: Promise<API_GET.TemplateTileDetail[]> | null = null;
export const httpGetAllStaticTemplateTilesDetail = async (apiUrl: string) => {
  try {
    let staticTemplateTilesDetail: API_GET.TemplateTileDetail[];
    let cachedPromise = staticTemplateTileDetailsCache;

    if (!!cachedPromise) {
      staticTemplateTilesDetail = await cachedPromise;
    } else {
      const newPromise = httpGet<API_GET.TemplateTileDetail[]>(`${apiUrl}/templatetiles/static`);
      staticTemplateTileDetailsCache = newPromise;
      staticTemplateTilesDetail = await newPromise;
    }

    return staticTemplateTilesDetail;
  } catch (e) {
    // TODO: catch this somewhere
    console.error(e);
    throw new Error('Er is iets mis gegaan bij het ophalen van de alle thema tegels');
  }
};

let staticTemplatesCache: Promise<API_GET.Template[]> | null = null;
export const httpGetAllStaticTemplates = async (apiUrl: string) => {
  try {
    let staticTemplates: API_GET.Template[];
    let cachedPromise = staticTemplatesCache;

    if (!!cachedPromise) {
      staticTemplates = await cachedPromise;
    } else {
      const newPromise = httpGet<API_GET.Template[]>(`${apiUrl}/templates/static`);
      staticTemplatesCache = newPromise;
      staticTemplates = await newPromise;
    }

    return staticTemplates;
  } catch (e) {
    // TODO: catch this somewhere
    console.error(e);
    throw new Error('Er is iets mis gegaan bij het ophalen van de alle kpis');
  }
};


// TODO: is this the way to go or should we use localstorage?
let oeNavigationDataCache: Promise<NavigationData> | null = null;

export const httpGetNavigationData = async (dataUrl: string) => {
  try {
    let navigationData: NavigationData;
    let cachedPromise = oeNavigationDataCache;

    if (!!cachedPromise) {
      navigationData = await cachedPromise;
    } else {
      const newPromise = httpGet<NavigationData>(`${dataUrl}/filter.json`);
      oeNavigationDataCache = newPromise;
      navigationData = await newPromise;
    }

    return navigationData;
  } catch (e) {
    // TODO: catch this somewhere
    console.error(e);
    throw new Error('Er is iets mis gegaan bij het ophalen van de oe navigatie data');
  }
};

// TODO: is this the way to go or should we use localstorage?
const oeDataCache: { code: string; promise: Promise<any>; }[] = [];

/**
 * TODO: dit is een action?
 * fetch the ${oeCode}_DB_KPI.json file and return the correct data by dataKey.
 */
export const httpGetTileData = async (dataUrl: string, oeCode: string, dataKey: string, useMockData: boolean = false) => {
  try {
    let oeData: any;
    let cached = oeDataCache.find(({ code }) => code === oeCode);
    let { promise: cachedPromise = null } = cached || {};

    if (!!cachedPromise) {
      oeData = await cachedPromise;
    } else {
      const newPromise = httpGet<any>(`${dataUrl}/${oeCode}_DB_KPI.json`);
      oeDataCache.push({ code: oeCode, promise: newPromise });
      oeData = await newPromise;
    }

    if (useMockData) {
      oeData = await mergeUnitMockData(oeData);
    }

    const parentKey = dataKey.includes('/') ? dataKey.split('/')[0] : 'ppd';
    const key = dataKey.includes('/') ? dataKey.split('/')[1] : dataKey;

    // NOTE: je moet elke keer weer de lowercase uitvoeren nu en dat kost performance, 
    // idealiter zou je dit doen zo gauw de promise terugkomt met de data en ook cachen.
    // let tileData: any = null;
    // if(oeData[parentKey]) {
    //   const lowerCaseObject = toCaseInsensitiveObject(oeData[parentKey]);
    //   tileData = lowerCaseObject[key.toLowerCase()];
    // }
    const tileData = oeData[parentKey] ? oeData[parentKey][key] : null;

    return tileData as { [key: string]: any };
  } catch (e) {
    // TODO: catch this somewhere
    console.error(`Error bij het ophalen van de data voor ${oeCode}, key ${dataKey}`, e);
    throw new Error(`Error bij het ophalen van de data voor ${oeCode}, key ${dataKey}`);
  }
};

let mockDataPromise: Promise<any>;
/**
*  DEV only function.
*  Merges the data ({OE_CODE}_DB_KPI.json) and data mock (XX00000_MOCK_KPI.json).
*  if an top level object is present in mock and real, mock will be used.
*/
const mergeUnitMockData = async (realData: any) => {

  function mergeMockRealObject(real: any, mock: any) {
    const merged: any = {};

    // check if mock, then use mock else use real
    Object.keys(real).forEach((key) => {
      let kReal = real[key];
      let kMock = mock[key];

      if (kMock) {
        merged[key] = kMock;
      } else {
        merged[key] = kReal;
      }
    });

    // make sure all the keys in 'mock' are copied, in case 'real' is missing some
    Object.keys(mock).forEach((key) => {
      let kMock = mock[key];

      merged[key] = kMock;
    });

    return merged;
  }

  function mergeMockReal(mockData: any) {
    let merged = realData;
    if (mockData && realData) {
      Object.keys(mockData).forEach((key) => {
        if (typeof mockData[key] === 'object') {
          merged[key] = mergeMockRealObject(realData[key] || {}, mockData[key]);
        }
      });
    } else {
      throw new Error('mockData and realData must both be defined to devmerge');
    }

    return merged;
  }

  if (!mockDataPromise) {
    mockDataPromise = httpGet(`/mock-api/data/XX00000_MOCK_KPI.json`);
  }
  const mockData = await mockDataPromise;

  return mergeMockReal(mockData);
}