import { Tooltip } from 'ppd-library/charts/organisms/Tooltip';
import { scales } from 'ppd-library/charts/chartUtils';
import { AxisLeft, AxisBottom } from 'ppd-library/charts/organisms/Axis';
import { HorizontalGridLines } from 'ppd-library/charts/organisms/GridLines';
import { scaleOrdinal } from 'd3';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import Chart from 'ppd-library/charts/Chart';
// import { HelpTooltipButton } from '../../help/HelpTooltipButton';
import { ComponentBaseProps, Margins } from '../ComponentBaseProps';
import { ColumnChartStackedDataModel, ColumnChartStackedColumnDataModel } from './columnChartStackedTransformer';
import { Line } from 'ppd-library/charts/atoms/Line';
import { BarVerticalStacked } from 'ppd-library/charts/organisms/BarVerticalStacked';
import { LineThresholdVertical } from 'ppd-library/charts/molecules/LineThreshold';
import * as allFormatters from '../../../utils/formatters';
import { TooltipTemplate, useTooltipPosition, getSVGMousePosition } from '../tooltip-utils';

import { Legend, useLegend } from '../legend-utils';

export interface StackedColorProp {
  [key: string]: string;
}
export interface StackedValuesProp {
  [key: string]: string;
}

export interface ColumnChartStackedThemeProps {
  stackedBarColors?: string[];
  zeroLineColor?: string;
  zeroLineWidth?: number;
}
export interface ColumnChartStackedProps extends ComponentBaseProps<ColumnChartStackedThemeProps, ColumnChartStackedColumnDataModel> {
  showTooltip?: boolean;
  showThreshold?: boolean;
  barsPadding?: number;
  /**
   * When true, will hide the y axis.
   * When number, dense will be true when the width property < the supplied number in pixels.
   * @default false
   */
  dense?: boolean | number;

  /**
   * @default 10
   */
  yNumberOfTicks?: number;
  /**
   * Set a threshold in height pixels to show half the number of y ticks (5).
   * Will not be used when 'yNumberOfTicks' is explicitly set.
   */
  yHalfNumberOfTicks?: number;
  /**
  * Set the y axis maximum to a fixed number.
  * yMax overrides yMinMax and fixedTicks.
  */
  yMax?: number;
}

const StyledWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const StyledChartHolder = styled.div``;


const ColumnChartStacked = (props: ColumnChartStackedProps) => {
  const {
    id,
    data,
    yMax,
    // help,
    yNumberOfTicks: originalYNumberOfTicks,
    width = 500,
    dense = false,
    height: originalHeight = 450,
    tooltipOptions,
    formatters = {},
    // showHelp = false,
    barsPadding = 0.1,
    showTooltip = true,
    showThreshold = true,
    legendOptions = {},
    themeProperties,
    formattersOptions = {},
    yHalfNumberOfTicks = 260,
    margins: originalMargins = {} as Margins
  } = props;

  const isDense = dense === true || (typeof dense === 'number' && width < dense);
  const { left = 50, right = 5, top = 10, bottom = showThreshold ? 50 : 30 } = originalMargins;
  let margins = { left, top, right, bottom };
  margins.left = isDense ? 10 : margins.left;

  const [initiated, setInitiated] = useState(false);

  const {
    zeroLineColor = 'transparent',
    zeroLineWidth = 1,
    stackedBarColors = ["#2b4899", "orange", "gray"]
  } = themeProperties || { } as ColumnChartStackedThemeProps;

  const { columnsModel, xThreshold } = data;
  const ticks = columnsModel.map((item) => item.key);

  const yAxisTickFormatterName = formatters['yAxisTick'];
  const yAxisTickFormatter = (allFormatters as any)[yAxisTickFormatterName] || allFormatters.formatNumber;
  const yAxisTickFormatterOptions = formattersOptions['yAxisTick'];

  const getMaxValueFromData = (columnsModel: ColumnChartStackedDataModel[]): number => {
    const acummulatedRawValues: number[] = [];

    columnsModel.forEach((item) => {
      let rawItemValues: number[] = [];

      Object.keys(item.rawValues).forEach((key: string) => rawItemValues.push(item.rawValues[key]));

      acummulatedRawValues.push(rawItemValues.reduce((prev, curr) => prev + curr, 0));
      rawItemValues = [];
    });

    const maximumValue = Math.max(...acummulatedRawValues);

    return maximumValue;
  }

  // yMinMax or calculate based on data
  let maxValue = getMaxValueFromData(columnsModel);
  // yMax overrides calculated
  maxValue = yMax != null ? yMax : maxValue;

  const colorDomain = columnsModel.reduce((previous, current) => {
    let result = [...previous];
    current.valueKeys.forEach((key, index) => {
      if (result[index] === null) {
        result[index] = key;
      }
    });
    return result;
  }, [] as string[]);

  const legendElementRef = React.createRef<HTMLDivElement>();
  const { legendHeight } = useLegend(originalHeight, legendElementRef);
  const height = originalHeight - legendHeight;

  let yNumberOfTicks = originalYNumberOfTicks || 10;
  yNumberOfTicks = height < yHalfNumberOfTicks ? Math.ceil(yNumberOfTicks / 2) : yNumberOfTicks;

  const xScale = scales.stringXScale(ticks, width, margins.left, margins.right, barsPadding);
  const yScale = scales.numericYScale(0, maxValue, height, margins.bottom, margins.top).nice();
  const xScaleTickOffset = xScale.bandwidth() / 2;
  const colorScale = scaleOrdinal<string, string>()
    .domain(colorDomain)
    .range(stackedBarColors);

  const createEmptyColumnData = (column: ColumnChartStackedDataModel) => {
    let rawValues = {} as { [key: string]: number };
    column.valueKeys.forEach((key) => rawValues[key] = 0);

    const emptyColumnData = { ...column, rawValues };
    return emptyColumnData;
  };


  let tooltipLeaveTimeoutId = 0;
  const handleMouseMove = (event: React.MouseEvent<Element, MouseEvent>, index: number, data: ColumnChartStackedDataModel) => {
    clearTimeout(tooltipLeaveTimeoutId);
    const { x, y } = getSVGMousePosition(event as React.MouseEvent<SVGElement, MouseEvent>);
    setTooltipPosition({ visible: true, x, y, index, data });
  };

  const handleMouseLeave = () => {
    clearTimeout(tooltipLeaveTimeoutId);
    tooltipLeaveTimeoutId = setTimeout(() => {
      setTooltipPosition({ ...tooltipPosition, visible: false });
    }, 250);
  };

  const { tooltipPosition, setTooltipPosition } = useTooltipPosition<ColumnChartStackedDataModel>();

  useEffect(() => {
    setInitiated(true);
  }, []);

  return (
    <StyledWrapper>
      <StyledChartHolder>
        <Chart height={height} width={width}>
          <HorizontalGridLines
            scale={yScale}
            x={margins.left}
            numberOfTicks={yNumberOfTicks}
            id={`${id}-horizontal-grid-lines`}
            lineLength={width - margins.left - margins.right}
          />

          {columnsModel.map((columnData, i) => {
            const column = initiated ? columnData : createEmptyColumnData(columnData);
            const { key, valueKeys, rawValues } = column;

            const barData: StackedValuesProp = {};
            valueKeys.forEach((key) => (barData[key] as any) = rawValues[key]);

            return (
              <BarVerticalStacked
                yScale={yScale}
                keys={valueKeys}
                key={`${key}-${i}`}
                colorScale={colorScale}
                id={`${id}-stack-${i}`}
                width={xScale.bandwidth()}
                data={{ key, ...barData }}
                x={xScale(column.key) as number}
                mouseMove={(ev) => handleMouseMove(ev, i, column)}
                mouseLeave={handleMouseLeave}
              />
            )
          }
          )}
          {!!showThreshold && !!xThreshold &&
            xThreshold.map((item) => {
              const { label, columnKey } = item;

              return (
                <LineThresholdVertical
                  lineWidth={1}
                  showCircle={false}
                  x={xScale(columnKey)}
                  key={`${id}-${label}`}
                  labelPadding={[2, 4, 2, 4]}
                  lineHeight={margins.bottom}
                  y={height - margins.bottom}
                  labelPos={['left', 'bottom']}
                  labelDominantBaseline={'auto'} // IE fix
                  theme={{ textSize: '0.8rem' }}
                  label={<tspan dy={13}>{label}</tspan>}
                  id={`${id}-line-vertical-threshold-${label}`}
                />
              );
            })
          }
          {!isDense &&
            <AxisLeft
              scale={yScale}
              x={margins.left}
              id={`${id}-axis-left`}
              numberOfTicks={yNumberOfTicks}
              tickFormat={(value) => {
                if (!!yAxisTickFormatter) {
                  return yAxisTickFormatter(value, yAxisTickFormatterOptions);
                }

                return value;
              }}
            />
          }
          <AxisBottom
            scale={xScale}
            id={`${id}-axis-bottom`}
            y={-margins.bottom}
            chartHeight={height}
            tickOffset={xScaleTickOffset}
            tickFormat={(_value, i) => `${columnsModel[i].shortXLabel}`}
          />
          <Line
            width={zeroLineWidth}
            id={`${id}-line-vertical`}
            color={zeroLineColor}
            from={{ x: (margins.left), y: yScale(0) }}
            to={{ x: width - margins.right, y: yScale(0) }}
          />

        </Chart>
        <Tooltip
          top={tooltipPosition.y}
          left={tooltipPosition.x}
          visible={showTooltip && tooltipPosition.visible}
          theme={{
            transition: false
          }}
        >
          {() => {
            const {
              xLabel,
              valueKeys,
              rawValues,
              formattedValues,
            } = tooltipPosition.data as ColumnChartStackedDataModel;

            return (
              <TooltipTemplate
                title={xLabel}
                tooltipOptions={tooltipOptions}
                values={
                  valueKeys.map((key) => {
                    return {
                      label: key,
                      colors: [colorScale(key)],
                      formattedValue: rawValues[key] !== null ? formattedValues[key] : 'n.v.t.', // TODO: hardcoded n.v.t.
                    }
                  })
                }
              />
            );
          }}
        </Tooltip>
        {!isDense &&
          <Legend
            ref={legendElementRef}
            legendOptions={{ orientation: 'horizontal', ...legendOptions }}
            values={columnsModel[0].valueKeys.map((key) => {
              return {
                label: key,
                colors: [colorScale(key)]
              };
            })}
          />
          }
      </StyledChartHolder>
      {/* {
        !!showHelp && helpId &&
        <StyledHelpButtonWrapper>
          <HelpTooltipButton
            helpId={helpId}
            anchorType={'iconButton'}
          />
        </StyledHelpButtonWrapper>
      } */}
    </StyledWrapper>
  )
};

export default ColumnChartStacked;