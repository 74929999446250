import MuiIconButton from '@material-ui/core/IconButton';
import MuiDeleteIcon from '@material-ui/icons/Delete';
import MuiNotInterestedIcon from '@material-ui/icons/NotInterested';
import React, { useRef, useState } from 'react';

import { Menu, MenuItem } from '../MenuActionButton/menu';

export interface DeleteActionButtonProps {
  label: string;
  onDeleteClick: () => void;
  onButtonClick?: () => void;
  /**
   * @default secondary
   */
  color?: 'primary' | 'secondary' | 'default';
}

const DeleteActionButton = (props: DeleteActionButtonProps) => {
  const {
    label,
    onDeleteClick,
    color = 'secondary',
    onButtonClick = () => {},
  } = props;

  const [menuOpen, setMenuOpen] = useState(false);
  const elementRef = useRef<HTMLButtonElement>(null);

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  const handleDeleteClick = () => {
    handleMenuClose();
    onDeleteClick();
  }

  const handleButtonClick = () => {
    setMenuOpen(!menuOpen);
    onButtonClick();
  }

  const items: MenuItem[] = [
    {
      id: '1',
      label: 'Nee',
      onClick: handleMenuClose,
      iconSvg: <MuiNotInterestedIcon />,
    },
    {
      id: '2',
      label: 'Ja',
      onClick: handleDeleteClick,
      iconSvg: <MuiDeleteIcon />,
    }
  ];

  return (
    <>
      <MuiIconButton
        color={color}
        ref={elementRef}
        title={'Verwijderen'}
        onClick={handleButtonClick}
      >
        <MuiDeleteIcon />
      </MuiIconButton>
      <Menu
        title={label}
        items={items}
        isOpen={menuOpen}
        onClose={handleMenuClose}
        anchorElement={elementRef.current}
      />
    </>
  );
};

export default DeleteActionButton;