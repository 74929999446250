import { Bar } from 'ppd-library/charts/atoms/Bar';
import { scales } from 'ppd-library/charts/chartUtils';
import { LineThresholdVertical } from 'ppd-library/charts/molecules/LineThreshold';
import { BarHorizontalStacked } from 'ppd-library/charts/organisms/BarHorizontalStacked';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { HelpTooltipButton, HelpTooltipButtonProps } from '../../help/HelpTooltipButton';
import { ComponentBaseProps } from '../ComponentBaseProps';
import { createLabel, defaultChartLabelStyle } from '../componentUtils';
import { ContentRow } from '../ContentRow';
import { DoubleSlideBarsRemainderDataModel } from './doubleSlideBarsRemainderTransformer';
import { Chart } from 'ppd-library/charts';

export interface BarColors {
  topBar: string;
  bottomBar: string;
  negative: string;
  [key: string]: string;
}

export interface DoubleSlideBarsRemainderThemeProps {
  trackColor?: string;
  labelTextColor?: string;
  thresholdColor?: string;
  topBarHeight?: number;
  bottomBarHeight?: number;
  gutterSize?: number;
  barColors?: BarColors;
  lineHeight?: number;
  lineYPosition?: number;
  lineColor?: string;
  showNegativeColor?: boolean;
  valueColors?: { mainValue: string; secondaryValue: string }
}
export interface DoubleSlideBarsRemainderProps extends ComponentBaseProps<DoubleSlideBarsRemainderThemeProps, DoubleSlideBarsRemainderDataModel> {
  showInBarLabels?: boolean;
  /**
   * True means that the secondary value is equally important.
   * TODO: rename to something else.
   */
  mediumValueStyle?: boolean;
  /**
  * @default true
  */
  showTrack?: boolean;
  /**
   * The minumum value that should be used to calculate the track length.
   * For example if a bar should to fill to 100%, minMaxValue = 100.
   */
  minMaxValue?: number;
}

interface MainValueLabelProps {
  label: string;
  formattedValue: string;
  hideLabel?: boolean | 'description';
  color: string;
  fontSize: string;
  fontFamily?: string;
  y?: number;
}
interface TextAnimatedProps {
  children?: string;
  x?: number;
  y?: number;
  id?: string;
  fill?: string;
  width?: number;
  height?: number;
  // cssClass?: string;
  transform?: any;
  fontSize?: string
}

const StyledWrapper = styled.div`
  position: relative;
  flex: 1;
`;

const StyledChartHolder = styled.div``;

const StyledSuffixTSpan = styled.tspan`
 font-size: 1.5rem;
`;

const MainValueLabel = (props: MainValueLabelProps) => {
  const { label, hideLabel = false, formattedValue, color, fontSize, y } = props;
  let description = hideLabel === 'description' ? '' : `${label}`;

  let split = [formattedValue];
  if (formattedValue.indexOf(' ') !== -1) {
    split = formattedValue.split(' ');
  }

  return (
    <text y={0}>
      {hideLabel !== true &&
        <>
          <tspan
            x={0}
            style={{ ...defaultChartLabelStyle, fill: color }}
            y={`-${y}`}
          >
            {description}
          </tspan>
          <tspan
            x={0}
            y={0}
            style={{
              fill: color,
              fontSize: fontSize,
             
            }}>
            {split.map((splitValue, index) =>
              <React.Fragment key={index}>
                {index === 0 && splitValue}
                {index !== 0 && <StyledSuffixTSpan> {splitValue}</StyledSuffixTSpan>}
              </React.Fragment>)
            }
          </tspan>
        </>
      }
    </text>
  );
}

const SecondaryValueLabel = (props: MainValueLabelProps) => {
  const { label, hideLabel = false, formattedValue, fontSize, fontFamily, y = 0, color } = props;
  let description = hideLabel === 'description' ? '' : `${label}: `;
  return (
    <text style={defaultChartLabelStyle} y={y}>
      {hideLabel !== true &&
        <>
          <tspan
            style={{ ...defaultChartLabelStyle, fill: color }}
          >
            {description}
          </tspan>
          <tspan
            style={{
              fill: color,
              fontSize: fontSize,
              fontFamily: fontFamily
            }}>
            {formattedValue}
          </tspan>
        </>
      }
    </text>
  );
}

const getStackedData = (keys: string[], barRawValue: number, thresholdValue: number) => {
  const aboveThreshold = barRawValue > thresholdValue;
  const remainingValue = aboveThreshold ? barRawValue - thresholdValue : 0;
  const result = {
    [keys[0]]: aboveThreshold ? (barRawValue - remainingValue) : barRawValue,
    [keys[1]]: aboveThreshold ? remainingValue : 0
  }

  return result;
}

const StyledText = styled.text`
  && {
    transition: transform 1000ms;
  }
`;

const StyledHelpTooltipButton = styled(({ top, left, ...helpTooltipButtonProps }) => <HelpTooltipButton {...helpTooltipButtonProps} />)`
  && { 
    position: absolute;
    left: ${({ left }) => left}px;
    margin-top: ${({ top }) => top}px;
  }
`as React.ComponentType<{ top: number; left: number; } & HelpTooltipButtonProps>;


const TextAnimated = React.forwardRef((props: TextAnimatedProps, ref: any) => {
  return <StyledText {...props} ref={ref} />;
});

const getTextGradient = (textIndent: number, textWidth: number, barWidth: number) => {
  const singleXScale = scales.numericXScale(0, textWidth, 100, 0, 0);

  return `${singleXScale(barWidth - textIndent)}%`;
}

const DoubleSlideBarsRemainder = (props: DoubleSlideBarsRemainderProps) => {
  const {
    id,
    data,
    help,
    showHelp,
    width = 400,
    height = 200,
    minMaxValue = 0,
    showTrack = true,
    //  margins = { left: 0, right: 0, top: 0, bottom: 60 },
    hideLabel = {},
    showInBarLabels = false,
    mediumValueStyle = false,
    themeProperties
  } = props;

  const {
    gutterSize = 2,
    lineHeight = 128,
    lineYPosition = 6,
    topBarHeight = 24,
    bottomBarHeight = 24,
    lineColor = '#6E6E6E',
    trackColor = '#EEEEEE',
    showNegativeColor = true,
    labelTextColor = '#636363',
    barColors = { topBar: '#2b4899', bottomBar: 'orange', negative: '#D90000' },
    valueColors = { mainValue: '#2b4899', secondaryValue: '#636363' }
  } = themeProperties || {} as DoubleSlideBarsRemainderThemeProps;

  const topBarKey = 'topBar';
  const negativeKey = 'negative';
  const bottomBarKey = 'bottomBar';
  const inBarLabelIndent = 10;
  const inBarFontSize = '0.8rem';

  const { mainValue, secondaryValue, thresholdValue } = data;
  const maxValue = Math.max(minMaxValue, mainValue.rawValue, secondaryValue.rawValue, thresholdValue.rawValue);

  const xScale = scales.numericXScale(0, maxValue, width, 0, 0);
  const { topBar, bottomBar, negative } = barColors;
  const topBarColorScale = scales.colorScale({ topBar, negative: showNegativeColor ? negative : topBar });
  const bottomBarColorScale = scales.colorScale({ bottomBar, negative: showNegativeColor ? negative : bottomBar });

  const refObj = { width: 0, height: 0 } as ClientRect;
  const [label1Ref, setLabel1Ref] = useState(refObj);
  const [label2Ref, setLabel2Ref] = useState(refObj);

  const labelRef1 = useCallback(node => {
    if (node !== null) {
      setLabel1Ref(node.getBoundingClientRect());
    }
  }, []);

  const labelRef2 = useCallback(node => {
    if (node !== null) {
      setLabel2Ref(node.getBoundingClientRect());
    }
  }, []);

  const [initiated, setInitiated] = useState(false);

  useEffect(() => {
    setInitiated(true);
  }, []);

  return (
    <StyledWrapper>
      <ContentRow>
        <StyledChartHolder>
          {!!showHelp && !!help && help.some(({ position }) => position === 1) &&
            <StyledHelpTooltipButton
              left={0}
              top={-10}
              anchorType={'iconButton'}
              placement={'bottom-start'}
              helpId={(help.find(({ position }) => position === 1) as { helpId: number }).helpId}
            />
          }
          {!!showHelp && !!help && help.some(({ position }) => position === 2) &&
            <StyledHelpTooltipButton
              top={32}
              anchorType={'iconButton'}
              placement={'bottom-start'}
              helpId={(help.find(({ position }) => position === 2) as { helpId: number }).helpId}
              left={xScale(thresholdValue.rawValue) > width * 0.75 ? xScale(thresholdValue.rawValue) - 32 : xScale(thresholdValue.rawValue) - 1}
            />
          }
          {!!showHelp && !!help && help.some(({ position }) => position === 3) &&
            <StyledHelpTooltipButton
              top={20}
              anchorType={'iconButton'}
              placement={'bottom-start'}
              helpId={(help.find(({ position }) => position === 3) as { helpId: number }).helpId}
              left={96}
            />
          }
            {!!showHelp && !!help && help.some(({ position }) => position === 4) &&
            <StyledHelpTooltipButton
              top={156}
              anchorType={'iconButton'}
              placement={'bottom-start'}
              helpId={(help.find(({ position }) => position === 4) as { helpId: number }).helpId}
              left={96}
            />
          }
          <Chart
            width={width}
            height={height}
            preserveAspectRatio={'none'}
          >
            {showTrack &&
              <g transform={`translate(0, 80)`}>
                <Bar
                  x={0}
                  y={0}
                  width={width}
                  fill={trackColor}
                  height={topBarHeight}
                  id={`${id}-main-track`}
                />
                <Bar
                  x={0}
                  width={width}
                  fill={trackColor}
                  height={bottomBarHeight}
                  id={`${id}-secondary-track`}
                  y={topBarHeight + gutterSize}
                />
              </g>
            }
            <g transform={`translate(0, 80)`}>
              <g>
                <BarHorizontalStacked
                  keys={[topBarKey, negativeKey]}
                  xScale={xScale}
                  height={topBarHeight}
                  colorScale={topBarColorScale}
                  y={0}
                  id={`${id}-bar-horizontal-stacked-top`}
                  data={{
                    key: topBarKey,
                    ...getStackedData([topBarKey, negativeKey],
                      initiated ? mainValue.rawValue : 0,
                      initiated ? thresholdValue.rawValue : 0
                    )
                  }}
                />
                {showInBarLabels &&
                  <TextAnimated
                    fontSize={inBarFontSize}
                    transform={`translate(${inBarLabelIndent}, 0)`}
                    fill={`url(#${id}-gradientText1)`}
                    ref={labelRef1}
                    y={topBarHeight - (label1Ref.height / 2)}
                  >
                    {mainValue.label}
                  </TextAnimated>
                }
              </g>
              <g>
                <BarHorizontalStacked
                  keys={[bottomBarKey, negativeKey]}
                  xScale={xScale}
                  height={bottomBarHeight}
                  colorScale={bottomBarColorScale}
                  y={topBarHeight + gutterSize}
                  id={`${id}-bar-horizontal-stacked-bottom`}
                  data={{
                    key: bottomBarKey,
                    ...getStackedData([bottomBarKey, negativeKey],
                      initiated ? secondaryValue.rawValue : 0,
                      initiated ? thresholdValue.rawValue : 0
                    )
                  }}
                />
                {showInBarLabels &&
                  <TextAnimated
                    fontSize={inBarFontSize}
                    transform={`translate(${inBarLabelIndent}, ${bottomBarHeight})`}
                    fill={`url(#${id}-gradientText2)`}
                    ref={labelRef2}
                    y={bottomBarHeight + gutterSize - (label2Ref.height / 2)}
                  >
                    {secondaryValue.label}
                  </TextAnimated>
                }
              </g>
            </g>
            <LineThresholdVertical
              labelDominantBaseline={0}
              yText={16}
              y={lineYPosition}
              showCircle={false}
              lineHeight={lineHeight}
              id={`${id}-thresholdLine`}
              x={initiated ? xScale(thresholdValue.rawValue) - 1 : 0}
              label={createLabel('thresholdValue', thresholdValue, hideLabel)}
              labelPos={[xScale(thresholdValue.rawValue) > width * 0.75 ? 'left' : 'right', 'top']}
              labelPosCorrection={[0, -8]}
              theme={{
                textColor: labelTextColor,
                textSize: '0.8rem',
                backgroundColor: 'transparent',
                lineColor: lineColor
              }}
            />
            <g transform={`translate(0, 70)`}>
              <MainValueLabel
                label={mainValue.label}
                hideLabel={hideLabel['mainValue']}
                formattedValue={mainValue.formattedValue}
                fontSize={mediumValueStyle ? '1.5rem' : '2.5rem'}
                y={mediumValueStyle ? 24 : 40} // Safari can't handle rem unit here
                color={mainValue.rawValue > thresholdValue.rawValue ? topBarColorScale(negativeKey) : valueColors.mainValue}
              />
              <SecondaryValueLabel
                label={secondaryValue.label}
                hideLabel={hideLabel['secondaryValue']}
                formattedValue={secondaryValue.formattedValue}
                y={bottomBarHeight + (mediumValueStyle ? 63 : 53)}
                fontSize={mediumValueStyle ? '1.5rem' : '0.8rem'}
               
                color={
                  secondaryValue.rawValue > thresholdValue.rawValue ?
                  showNegativeColor ? bottomBarColorScale(negativeKey) : valueColors.secondaryValue
                  : mediumValueStyle ? valueColors.mainValue : valueColors.secondaryValue
                }
              />
            </g>
            {!!showInBarLabels &&
              <defs>
                <linearGradient id={`${id}-gradientText1`}>
                  <stop offset={getTextGradient(inBarLabelIndent, label1Ref.width, xScale(mainValue.rawValue))} stopColor="white" />
                  <stop stopColor="black" />
                </linearGradient>
                <linearGradient id={`${id}-gradientText2`}>
                  <stop offset={getTextGradient(inBarLabelIndent, label2Ref.width, xScale(secondaryValue.rawValue))} stopColor="white" />
                  <stop stopColor="black" />
                </linearGradient>
              </defs>
            }
          </Chart>
        </StyledChartHolder>
        {/* {
          !!showHelp && helpId &&
          <StyledHelpButtonWrapper>
            <HelpTooltipButton
              helpId={helpId}
              anchorType={'iconButton'}
            />
          </StyledHelpButtonWrapper>
        } */}
      </ContentRow>
    </StyledWrapper>
  )
};

export default DoubleSlideBarsRemainder;