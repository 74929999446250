import MuiIcon, { IconProps as MuiIconProps } from '@material-ui/core/Icon';
import MuiTypography, { TypographyProps as MuiTypographyProps } from '@material-ui/core/Typography';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import muiTheme from '../../../constants/theme';
import { HelpTooltipButton } from '../../help/HelpTooltipButton';
import { ComponentBaseProps } from '../ComponentBaseProps';
import { StackedBarItemDataModel, WaffleChartModel } from './waffleChartTransformer';

// import { HelpTooltipButton } from '../../help/HelpTooltipButton';
const StyledIcon = styled(({ size, iconColor, ...muiIconProps }) => <MuiIcon {...muiIconProps} />)`
  && {
    transition: color 1s;
    display: block;
    color: ${({ iconColor }) => `${iconColor}`};
    font-size: ${({ size }) => `${size}`};
  }
  `as React.ComponentType<{ size: string; iconColor: string; } & MuiIconProps>;

export interface iconProp {
  icon: string;
  color: string;
  valueColor?: string;
  /**
   * Overwrite color
   */
  iconColor?: string;
}
export interface iconsProp {
  [key: string]: iconProp;
}
export interface WaffleChartThemeProps {
  icons?: iconsProp;

}
export interface WaffleChartProps extends ComponentBaseProps<WaffleChartThemeProps, WaffleChartModel> {
  showTooltip?: boolean;
  valuePositions: {
    singleValue?: string;
    doubleValueTop: string;
    doubleValueBottom: string;
  }
}

const StyledWrapper = styled(({ reverse, marginTop, ...divProps }) => <div {...divProps} />)`
&& {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: ${({ reverse }) => `${reverse ? 'row-reverse' : 'row'}`};
  justify-content: center;
  /* justify-content: space-between; */
  margin-top: ${({ marginTop }) => `${marginTop}px`};
}
` as React.ComponentType<{ reverse: boolean; marginTop?: number; } & React.HTMLProps<HTMLDivElement>>;


const StyledChartHolder = styled(({ width, height, margin, ...divProps }) => <div {...divProps} />)`
&& {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  position: relative;
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  margin-top: ${({ margin }) => `${margin}px`};
}
` as React.ComponentType<{ width: number, height: number, margin: number } & React.HTMLProps<HTMLDivElement>>;

const StyledMuiTypography = styled(({ textColor, ...muiTypographyProps }) => <MuiTypography {...muiTypographyProps} />)`
  && {
    color: ${({ textColor }) => textColor};
    font-size: 0.8rem;
    position:relative;
  }
  ` as React.ComponentType<{ textColor?: string; } & MuiTypographyProps>;

const StyledMainValue = styled(({ textColor, ...muiTypographyProps }) => <MuiTypography {...muiTypographyProps} />)`
  && {
    font-weight: 900;
    color: ${({ textColor }) => textColor};
    font-size: 2.5rem;
    font-weight: 500;
    line-height: 2.25rem;
  }
  ` as React.ComponentType<{ textColor?: string; } & MuiTypographyProps>;


const SideContainer = styled(({ w, ...divProps }) => <div {...divProps} />)`
  && {
     width: ${({ w }) => `${w}`}px;
  }
  ` as React.ComponentType<{ w: number; } & React.HTMLProps<HTMLDivElement>>;


const ValuesInnerContainer = styled(({ top, ...divProps }) => <div {...divProps} />)`
  && {
    transition: transform 1s;
    transform: ${({ top }) => `translate(0, ${top}px) `};
  }
  ` as React.ComponentType<{ top: number } & React.HTMLProps<HTMLDivElement>>;


const SingleValueContainer = styled(({ isRightColumn, heightUnit, textColor, ...divProps }) => <div {...divProps} />)`
  && {
    text-align: ${({ isRightColumn }) => `${isRightColumn ? 'left' : 'right'}`};
    padding: ${({ isRightColumn }) => `${isRightColumn ? `0 0 0 ${12 / 16}rem` : `0 ${12 / 16}rem 0 0`}`};
    height: ${({ heightUnit }) => `${heightUnit * 2 / 16}rem`};
    color: ${({ textColor }) => `${textColor}`};
    display: flex;
    flex-flow: column;
    justify-content: space-evenly;
  }
  ` as React.ComponentType<{ isRightColumn: boolean, heightUnit: number; textColor: string } & React.HTMLProps<HTMLDivElement>>;

const StyledTopValueContainer = styled(({ top, ...divProps }) => <div {...divProps} />)`
  && {
    white-space: nowrap;
    position:absolute;
    right: 0.2rem;
    top: ${({ top }) => `-${top / 16}rem`}; /* // TODO: waar komt / 16 vandaan? */
  }
  `as React.ComponentType<{ top: number } & React.HTMLProps<HTMLDivElement>>;


const StyledBottomValueContainer = styled(({ textColor, ...divProps }) => <div {...divProps} />)`
  &&& {
    position:absolute;
    right: 0.2rem;
    width: 100%;
    bottom: -0.6rem;
    color: ${({ textColor }) => textColor};
    .bottomValueInnerContainer {
      position: absolute;
      right: 0;
      text-align: right;
      color: inherit;
    }
    .mainBottomValueHolder {
      position: absolute;
      right: -0.8rem;
      top: -1.2rem;
      color: inherit;
    }
  }
  `as React.ComponentType<{ textColor: string } & React.HTMLProps<HTMLDivElement>>;

const StyledMainBottomValue = styled(({ ...muiTypographyProps }) => <MuiTypography {...muiTypographyProps} />)`
  && {
    position: absolute;
    left: 0;
    top:0;
    font-weight: 900;
    font-size: 2.5rem;
    font-weight: 500;
    color: inherit;
  }
  ` as React.ComponentType<MuiTypographyProps>;

const StyledHelpButtonWrapper = styled.div`
    position: absolute;
    top: -1rem;
    right: 0;
` as React.ComponentType<React.HTMLProps<HTMLDivElement>>;

const StyledHelpButtonWrapper0 = styled.div`
    position: absolute;
    top: -1.5rem;
    right: -1.2rem;
` as React.ComponentType<React.HTMLProps<HTMLDivElement>>;



const StyledCustomIcon = styled(({ color, unitSize, icon, ...divProps }) => <div {...divProps} />)`
  &&& {
    width: 100%;
    height: 100%;
    // width: ${({ unitSize }) => `${unitSize / 16}rem`}};
    // height: ${({ unitSize }) => `${unitSize / 16}rem`}};
    background-color: ${({ color }) => color};
    border-radius: ${({ icon }) => icon === 'circle' ? '50%' : null};
  }
  `as React.ComponentType<{ color: string, unitSize: number, icon: string } & React.HTMLProps<HTMLDivElement>>;

const StyledCustomIconHolder = styled(({ unitSize, isNativeIcon, ...divProps }) => <div {...divProps} />)`
  &&& {
    width: ${({ unitSize }) => `${unitSize / 16}rem`}};
    height: ${({ unitSize }) => `${unitSize / 16}rem`}};
    padding: ${({ isNativeIcon }) => isNativeIcon ? '1.8%' : null};
  }
  `as React.ComponentType<{ unitSize: number, isNativeIcon: boolean; } & React.HTMLProps<HTMLDivElement>>;

const WaffleChart = (props: WaffleChartProps) => {
  const {
    id,
    data,
    help,
    showHelp,
    width = 500,
    height = 500,
    // showTooltip = true,
    // tooltipOptions,
    margins = { top: -30, right: 0, bottom: 0, left: 0 },
    themeProperties,
    valuePositions

  } = props;
  const { icons: themeIcons = {} } = themeProperties || {} as WaffleChartThemeProps;
  const { chartModel, valuesModel } = data;
  const defaultColor = muiTheme.palette.primary.main;
  const defaultIcon = 'square';

  // Make all object keys to lowercase
  // eslint-disable-next-line
  // TODO: waarom moeten ze lowercase?
  const formattedIconKeys = useMemo(() => {
    const keys = Object.keys(themeIcons);

    return keys.reduce((previousResult, currentKey) => {
      let result = { ...previousResult };
      result[currentKey.toLowerCase()] = themeIcons[currentKey];

      return result;
    }, {} as { [key: string]: iconProp })
  }, [themeIcons]);

  const [initiated, setInitiated] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setInitiated(true);
    }, 20);

    return () => clearTimeout(timeout);
  }, [width, height, valuesModel]); // TODO: waarom deze deps?

  const columns: number = Object.keys(valuesModel).length;
  const hasThreeValues = columns > 2;

  const chartDimension = height > width ? width : height;

  const sideContainerWidth = 128;
  const iconContainerWidth = chartDimension - sideContainerWidth;
  const unitSize = iconContainerWidth / 10;
  const topValueObj = valuesModel[valuePositions.doubleValueTop.toLowerCase()];

  const topIconCount = [...chartModel].filter((item) => item[valuePositions.doubleValueTop.toLowerCase()]).length;
  const singleValueRightPosition = true; // hasThreeValues ? (topIconCount % 10 < 5) : false;

  const bottomValueObj = valuesModel[valuePositions.doubleValueBottom.toLowerCase()];

  const singleValuePosition = hasThreeValues ? (valuePositions.singleValue as string).toLowerCase() : null;
  const doubleValueTopPosition = valuePositions.doubleValueTop.toLowerCase();
  const doubleValueBottomPosition = valuePositions.doubleValueBottom.toLowerCase();

  const singleValueTheme = hasThreeValues ? formattedIconKeys[singleValuePosition as string] : 'transparent';
  const doubleValueTopTheme = formattedIconKeys[doubleValueTopPosition];
  const doubleValueBottomTheme = formattedIconKeys[doubleValueBottomPosition];

  const iconRowIndex = Math.ceil(topIconCount / 10);
  const singleValueYRow =
    iconRowIndex > 9 ? 9 :
      iconRowIndex < 2 ? 2 :
        iconRowIndex;
  // TODO: waar is die % 10 voor? 
  // const singleValueY = (unitSize * singleValueYRow) - (topIconCount % 10 === 0 ? -(unitSize / 2) :  unitSize / 2);
  const singleValueY = (unitSize * singleValueYRow) - (unitSize / 2);

  // Verkrijgen van de data key met het meest voorkomende waarde
  const heighestValue = Math.max(...Object.keys(valuesModel).map((key: string) => valuesModel[key].acummulatedValue));
  const leadingItemKey = Object.keys(valuesModel).filter((key: string) => valuesModel[key].acummulatedValue === heighestValue)[0];

  return (
    <StyledWrapper reverse={singleValueRightPosition} marginTop={margins.top} id={`${id}-waffleChart`}>
      {
        <SideContainer w={sideContainerWidth}>
          {hasThreeValues &&
            <ValuesInnerContainer
              top={
                initiated ? singleValueY : -200
              }
            >
              <SingleValueContainer
                heightUnit={unitSize}
                isRightColumn={singleValueRightPosition}
                textColor={(singleValueTheme as iconProp).color}
              >
                <StyledMuiTypography variant={'h5'} textColor={'inherit'}>
                  {!!showHelp && !!help && help.some(({ position }) => position === 2) &&
                    <StyledHelpButtonWrapper>
                      <HelpTooltipButton
                        anchorType={'iconButton'}
                        helpId={(help.find(({ position }) => position === 2) as { helpId: number }).helpId}
                      />
                    </StyledHelpButtonWrapper>
                  }
                  {
                    valuesModel[(valuePositions.singleValue as string).toLowerCase()].primaryValue.label
                  }
                </StyledMuiTypography>
                <StyledMainValue variant={'h2'} textColor={(singleValueTheme as iconProp).valueColor}>
                  {
                    valuesModel[(valuePositions.singleValue as string).toLowerCase()].primaryValue.formattedValue
                  }
                </StyledMainValue>
                <StyledMuiTypography textColor={'inherit'}>
                  {
                    valuesModel[(valuePositions.singleValue as string).toLowerCase()].secondaryValue.formattedValue
                  }
                </StyledMuiTypography>
              </SingleValueContainer>
            </ValuesInnerContainer>
          }
        </SideContainer>
      }
      <StyledChartHolder
        margin={unitSize}
        width={iconContainerWidth}
        height={iconContainerWidth}
      >
        <>
          <StyledTopValueContainer top={24}>
            <StyledMuiTypography variant={'h5'} textColor={(doubleValueTopTheme as iconProp).color}>
              {!!showHelp && !!help && help.some(({ position }) => position === 1) &&
                <StyledHelpButtonWrapper0>
                  <HelpTooltipButton
                    anchorType={'iconButton'}
                    helpId={(help.find(({ position }) => position === 1) as { helpId: number }).helpId}
                  />
                </StyledHelpButtonWrapper0>
              }
              {`${topValueObj.primaryValue.label} ${topValueObj.secondaryValue.formattedValue}`}
            </StyledMuiTypography>
          </StyledTopValueContainer>
          <StyledBottomValueContainer textColor={(doubleValueBottomTheme as iconProp).color}>
            <div className={'bottomValueInnerContainer'}>
              <StyledMuiTypography variant={'h5'}>
                {bottomValueObj.primaryValue.label}
              </StyledMuiTypography>
              <StyledMuiTypography variant={'h5'}>
                {bottomValueObj.secondaryValue.formattedValue}
              </StyledMuiTypography>
              <div className={'mainBottomValueHolder'}>
                <StyledMainBottomValue variant={'h2'}>
                  {!!showHelp && !!help && help.some(({ position }) => position === 3) &&
                    <StyledHelpButtonWrapper0>
                      <HelpTooltipButton
                        anchorType={'iconButton'}
                        helpId={(help.find(({ position }) => position === 3) as { helpId: number }).helpId}
                      />
                    </StyledHelpButtonWrapper0>
                  }
                  {bottomValueObj.primaryValue.formattedValue}
                </StyledMainBottomValue>
              </div>
            </div>
          </StyledBottomValueContainer>
          {
            chartModel.map((item: StackedBarItemDataModel) => {
              const itemKey = Object.keys(item)[0];
              if (Object.keys(themeIcons).length > 0) {

                // Als de waarde 0 is (dus geen icoon), wordt de andere kleur en icoon type toegewezen.
                const themeIcon = (item[itemKey] as any).value < 1 ? formattedIconKeys[leadingItemKey] : formattedIconKeys[itemKey];

                if (themeIcon) {
                  const { icon = defaultIcon, color = defaultColor, iconColor } = themeIcon;
                  const isNativeIcon = icon === 'square' || icon === 'circle';

                  return (
                    <StyledCustomIconHolder unitSize={unitSize} key={item[itemKey].id} isNativeIcon={isNativeIcon}>
                      {
                        !!isNativeIcon &&
                        <StyledCustomIcon
                          color={initiated ? !!iconColor ? iconColor : color : 'transparent'}
                          unitSize={unitSize}
                          icon={icon}
                        />
                      }
                      {
                        !isNativeIcon &&
                        <StyledIcon
                          size={`${unitSize / 16}rem`}
                          iconColor={initiated ? !!iconColor ? iconColor : color : 'transparent'}
                        >
                          {icon}
                        </StyledIcon>
                      }
                    </StyledCustomIconHolder>
                  )
                }
              }
              return null;
            })
          }
        </>
      </StyledChartHolder>
      {/* <SideContainer w={sideContainerWidth}></SideContainer> */}
    </StyledWrapper>
  )
};

export default WaffleChart;