import { theme } from '../constants';

export const pxToRem = (value: number) =>  {
  const { htmlFontSize } = theme.typography as any;

  // pxToRem returns a wrong rem value based on wrong htmlFontSize
  // theme.typography.pxToRem(value)

  return `${value / htmlFontSize}rem`;
};

