import { TransformOptionsBase, TransformFunction } from '../transform-utils';
import { formatValue } from '../../../utils';
import * as allFormatters from '../../../utils/formatters';


interface RD {
  waarde: number;
  label_4: string;
  eenheid: string;
}

export interface ThreeValuesRawData {
  val00_00?: RD;
  val00_01: RD;
  val00_02: RD;
}

export interface ThreeValuesDataModel {
  /**
   * FORMATTED secondary values
   */
  secondaryValues: [
    { label: string; rawValue: number; formattedValue: string; },
    { label: string; rawValue: number; formattedValue: string; }
  ];
  /**
  * FORMATTED primary (large) value
  */
  primaryValue: { label: string; rawValue: number; formattedValue: string; };
}

export interface ThreeValuesTransformOptions extends TransformOptionsBase {
  noMainValue?: boolean;
}

const threeValuesTransformer: TransformFunction<ThreeValuesDataModel, ThreeValuesTransformOptions> = (
  rawData: ThreeValuesRawData,
  onError,
  options
) => {

  const {
    formatters = {}, 
    noMainValue = false,
    formattersOptions = {}
  } = options || {} as ThreeValuesTransformOptions;

  let { val00_00, val00_01, val00_02 } = rawData;
  
  let result: ThreeValuesDataModel = {
    primaryValue: {rawValue: 0, formattedValue: '-', label: '-'},
    secondaryValues: [{ rawValue: 0, formattedValue: '-', label: '-' }, { formattedValue: '-', rawValue: 0, label: '-' }]
  };

  if(noMainValue) {
    val00_02 = val00_01;
    val00_01 = val00_00 as RD;
    val00_00 = undefined;
  }

  let guard = true;

  if (!!val00_00 && val00_00.waarde != null) {
    guard = false;

    const {waarde, eenheid, label_4} = val00_00;
    const mainValueFormatterName = formatters['mainValue'];
    const mainValueFormatOptions = formattersOptions['mainValue'];
    const mainValueValueFormatter = (allFormatters as any)[mainValueFormatterName];

    result.primaryValue = {
      label: label_4,
      rawValue: waarde,
      formattedValue: mainValueValueFormatter ? mainValueValueFormatter(waarde, mainValueFormatOptions) : formatValue(waarde, eenheid as ValueUnit, mainValueFormatOptions)
    };
  }

  if (val00_01.waarde != null) {
    guard = false;

    const {waarde, eenheid, label_4} = val00_01;
    const firstValueFormatterName = formatters['firstValue'];
    const firstValueFormatOptions = formattersOptions['firstValue'];
    const firstValueFormatter = (allFormatters as any)[firstValueFormatterName];

    result.secondaryValues[0].label = label_4;
    result.secondaryValues[0].rawValue = waarde;
    result.secondaryValues[0].formattedValue = firstValueFormatter ? firstValueFormatter(waarde, firstValueFormatOptions) : formatValue(waarde, eenheid as ValueUnit, firstValueFormatOptions)

  }

  if (val00_02.waarde != null) {
    guard = false;

    const {waarde, eenheid, label_4} = val00_02;
    const secondValueFormatterName = formatters['secondValue'];
    const secondValueFormatOptions = formattersOptions['secondValue'];
    const secondValueFormatter = (allFormatters as any)[secondValueFormatterName];

    result.secondaryValues[1].label = label_4;
    result.secondaryValues[1].rawValue = waarde;
    result.secondaryValues[1].formattedValue = secondValueFormatter ? secondValueFormatter(waarde, secondValueFormatOptions) : formatValue(waarde, eenheid as ValueUnit, secondValueFormatOptions)
  }

  if (guard) {
    onError('nodata');
    return null;
  }

  return result;
};

export default threeValuesTransformer;